import type { InputRef } from 'antd';
import { Form, Input, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Network from '../../../utils/network';
import { PaidHoursAllMonths, PaidHoursSummary } from '../../../utils/types/reportTypes';
import { showNotification } from '../../../utils/utils';
import './../../../styles/editableTable.css';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: any;
    handleSave: (userId: number, monthKey: keyof PaidHoursAllMonths, value: number) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;


    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        const value = record[dataIndex[0]] && record[dataIndex[0]][dataIndex[1]] && record[dataIndex[0]][dataIndex[1]][dataIndex[2]] ? record[dataIndex[0]][dataIndex[1]][dataIndex[2]] : 0;

        form.setFieldsValue({ [dataIndex[1]]: value });
        setEditing(!editing);
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            const monthKey = dataIndex[1] as keyof PaidHoursAllMonths;
            let hours = Number(values[monthKey])
            if (!hours || hours < 0) {
                hours = 0;
            }
            handleSave(record.user.id, monthKey, hours);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    const hasContract = (record as PaidHoursSummary) != null && (record as PaidHoursSummary).contractsPaidHoursSummary != null && (record as PaidHoursSummary).contractsPaidHoursSummary.length > 0;

    if (editable && hasContract) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex[1]}
            >
                <Input ref={inputRef} type='number' min={0} max={999} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0] & { year: number };


export type ColumnTypesCustom = Exclude<EditableTableProps['columns'], undefined>;
export type ColumnTypesEditable = (ColumnTypesCustom[number] & { editable?: boolean, dataIndex: any })[];


const EditableTable: React.FC<EditableTableProps> = (props) => {
    const [dataSource, setDataSource] = useState<any[]>(props.dataSource ? [...props.dataSource as any[]] : []);
    const intl = useIntl()

    const defaultColumns: ColumnTypesEditable = props.columns ? props.columns as ColumnTypesEditable : [];

    useEffect(() => {
        setDataSource(props.dataSource ? [...props.dataSource as any[]] : []);
    }, [props.dataSource])


    const handleSave = (userId: number, monthKey: keyof PaidHoursAllMonths, value: number) => {
        const newData = [...dataSource];
        const indexUser = newData.findIndex(item => userId === item.user.id);
        const itemUser = newData[indexUser];

        const indexContract = itemUser.contractsPaidHoursSummary.findIndex((c: any) => c.id === itemUser.selectedPaidHoursSummary.id);
        let itemContract = itemUser.contractsPaidHoursSummary[indexContract];
        itemContract = {
            ...itemContract,
            [monthKey]: {
                ...itemContract[monthKey],
                hours: value
            }
        };
        itemUser.contractsPaidHoursSummary.splice(indexContract, 1, itemContract)

        newData.splice(indexUser, 1, {
            ...itemUser,
            selectedPaidHoursSummary: itemContract
        });
        console.log(newData);

        Network.setPaidHours(userId, itemUser.selectedPaidHoursSummary.id, props.year, Number(monthKey.replaceAll("ph", "")), value ?? 0).then(
            () => {
                setDataSource(newData);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the value, please reload the page' }), "error");
            }
        )
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave
            }),
        };
    });

    return (
        <Table
            {...props}
            components={components}
            rowClassName={() => 'editable-row'}
            dataSource={dataSource}
            columns={columns as ColumnTypesCustom}
        />
    );
};

export default EditableTable;