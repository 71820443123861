import { DatePicker, InputNumber } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import getFormat from '../../../utils/Lang';
import { CodeDiscount } from '../../../utils/types/generalTypes';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

enum DataType { Title, Reduction, ExpiryDate }

interface Props {
    codeDiscount?: CodeDiscount;
    closeCodeDiscount: (codeDiscount?: CodeDiscount) => void;

}

interface State {
}

class CodeDiscountCreateEditModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        const { codeDiscount } = this.props;
        if (codeDiscount) {
            switch (type) {
                case DataType.Title:
                    codeDiscount.code = value.event.value;
                    break;
                case DataType.Reduction:
                    codeDiscount.amount = value;
                    break;
                case DataType.ExpiryDate:
                    codeDiscount.expiryAt = value;
                    break;
            }
        }

        // this.setState({ codeDiscount });
    }

    render() {
        const { codeDiscount } = this.props;
        return (
            <Modal
                title={<FormattedMessage defaultMessage={'Add a discount voucher'} />}
                visible={codeDiscount !== undefined}
                width={620}
                onCancel={() => this.props.closeCodeDiscount(undefined)}>
                <div className="crm-vouhcer-modal-section">
                    <p><FormattedMessage defaultMessage={'Title'} /></p>
                    <InputField
                        className="__crm-codeDiscount-modal-input"
                        value={codeDiscount?.code}
                        onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(DataType.Title, e)} />
                </div>
                <div className="crm-vouhcer-modal-section">
                    <p><FormattedMessage defaultMessage={'Discount'} /></p>
                    <div className="flex-row">
                        <InputNumber
                            className="__crm-codeDiscount-modal-input"
                            type="number"
                            min={0}
                            max={100}
                            value={codeDiscount?.amount}
                            onChange={n => this.dataChanged(DataType.Reduction, n)} />
                        <p style={{ marginLeft: '10px' }}>{"%"}</p>
                    </div>
                </div>
                <div className="crm-vouhcer-modal-section">
                    <p><FormattedMessage defaultMessage={'Expiration date'} /></p>
                    <DatePicker
                        className="__crm-codeDiscount-modal-input"
                        format={getFormat('DATE')}
                        value={codeDiscount?.expiryAt ? moment(codeDiscount?.expiryAt) : undefined}
                        onChange={d => this.dataChanged(DataType.ExpiryDate, d)} />
                </div>
            </Modal>
        )
    }
}

export default CodeDiscountCreateEditModal;