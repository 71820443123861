import { LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as Sentry from "@sentry/react";
import { Button, Spin } from 'antd';
import moment from 'moment';
import qs from "qs";
import React from 'react';
import Logo from '../../images/logo/logo_sunkhronos.png';
import '../../styles/resetPassword.css';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { alert, handleForgotPassword } from '../../utils/utils';
import InputField from '../common/fields/inputField';
import Password from '../common/fields/password';

type Props = RouterProps;

interface State {
    email: string;
    disableEmailEdit: boolean;
    password: string;
    passwordError: boolean;
    loading: boolean;
    checking: boolean;
    messageError: string | undefined;
    messageNewTokenSuccess: string | undefined;
    token: string | undefined;
}

/**
 * Page to reset the user password
 */
class ResetPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            passwordError: true,
            messageError: undefined,
            messageNewTokenSuccess: undefined,
            token: undefined,
            loading: false,
            checking: true,
            disableEmailEdit: false,
        };
    }

    componentDidMount() {
        //get token
        const search = this.props.location.search;
        const token = qs.parse(search, {
            ignoreQueryPrefix: true,
            comma: true
        }).id;

        if (!search || !token || !(typeof token === "string")) {
            this.goHome();
            return;
        }

        this.setState({ token });

        // check that the token correpond to an existing token
        Network.checkResetPasswordToken(token).then(
            response => {
                this.setState({ email: response.userEmail, checking: false, disableEmailEdit: true });
            },
            (error) => {
                let parsedError: any;
                try {
                    parsedError = JSON.parse(error.message);
                } catch (error) {
                    Sentry.captureException(error);
                    this.setState({ checking: false, messageError: "Il y a eu un problème pendant la vérification du processus de réinitialisation." });
                    return;
                }
                if (parsedError.message === "Token expired") {
                    this.setState({ messageError: "Ce lien a expiré. Merci de générer un nouveau lien si nécessaire.", email: parsedError.email, disableEmailEdit: true });
                } else if (parsedError.message === "Token already used") {
                    this.setState({ messageError: "Ce lien à déjà été utilisé. Merci de générer un nouveau lien si nécessaire.", email: parsedError.email, disableEmailEdit: true });
                } else if (parsedError.message === "Token not found" || parsedError.message === "Multiple tokens found") {
                    this.setState({ messageError: "Il y a eu un problème pendant la vérification du processus de réinitialisation." });
                } else {
                    this.setState({ messageError: "Il y a eu un problème pendant la vérification du processus de réinitialisation." });
                }
                this.setState({ checking: false });
            }

        );
    }

    /**
     * Return to home page
     */
    goHome = () => {
        this.props.history.push(`/${this.props.match.params.lang}`);
    }

    /**
     * Check if the save button must be disabled
     * @returns true if the save butto must be disabled, false otherwise
     */
    disableButton = () => !this.state.email || !this.state.email.includes('@') || !this.state.password || this.state.passwordError;

    /**
     * Reset the password with the new value
     */
    resetPassword = () => {
        this.setState({ loading: true });
        Network.resetPassword(this.state.email, this.state.password, this.state.token!).then(
            response => {
                this.setState({ loading: false });
                switch (response.success) {
                    case "ok":
                        alert("Votre mot de passe a correctement été modifié.", "success");
                        setTimeout(this.goHome, 1500);
                        break;
                    case "wrong email":
                        alert("L'email donné ne correspond à aucun utilisateur connu.", "error");
                        break;
                    case "expired":
                        alert("Le délai a expiré. Veuillez demander un nouvel e-mail de modification de mot de passe.", "error");
                        break;
                }
            },
            () => {
                this.setState({ loading: false });
                alert("Il y a eu un problème pendant la modification de votre mot de passe.", "warning")
            },
        );
    }


    handleForgotPassword = (): void => {
        this.setState({ loading: true });
        handleForgotPassword(this.state.email, () => {
            this.setState({ loading: false, messageNewTokenSuccess: "Un mail contenant des informations sur la réinitialisation de votre mot de passe vous a été envoyé." });
            setTimeout(this.goHome, 5000);
        }, () => {
            this.setState({ loading: false });
            alert("Il y a eu un problème pendant l'envoie du mail de réinitialisation.", "warning")
        });
    }

    render() {
        return (
            <div>
                <div className="login-background-image" />
                <div className="login-background" id="container">
                    <div className="login-parent">
                        <div className="login-content">
                            <div className="login-header">
                                <img src={Logo} className="login-logo" alt="Sunkhronos logo" />
                            </div>
                            <div className="login-body">
                                <p className="reset-password-title">{"Réinitialiser votre mot de passe"}</p>
                                {
                                    this.state.checking ?
                                        <Spin indicator={<LoadingOutlined spin />} size='large' style={{ marginBottom: '40px', marginTop: '30px' }} />
                                        :
                                        this.state.messageError !== undefined ?
                                            this.state.messageNewTokenSuccess !== undefined ?
                                                <p style={{ color: 'green', padding: '20px 20px 40px 20px', textAlign: 'center' }}>{this.state.messageNewTokenSuccess}</p>
                                                :
                                                <>
                                                    <p style={{ color: 'red', padding: '20px 20px 20px 20px', textAlign: 'center' }}>{this.state.messageError}</p>

                                                    <span className="login-footer-links" style={{ marginBottom: '40px' }}>
                                                        <Button type='primary' loading={this.state.loading} onClick={() => this.handleForgotPassword()}>{"Nouveau lien"}</Button>
                                                    </span>
                                                </>
                                            :
                                            <>
                                                <InputField
                                                    disabled={this.state.disableEmailEdit}
                                                    onFocus={(e) => e.currentTarget.removeAttribute("readonly")}
                                                    readOnly
                                                    className="reset-password-input"
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                    onPressEnter={this.disableButton() ? undefined : this.resetPassword}
                                                    value={this.state.email}
                                                    placeholder="E-mail"
                                                    addonAfter={<MailOutlined />} />

                                                <Password
                                                    setError={(value) => this.setState({ passwordError: value })}
                                                    placeholder={"Nouveau mot de passe"}
                                                    password={this.state.password}
                                                    style={{ width: "100%", paddingLeft: '20px', paddingRight: '20px' }}
                                                    onChangePassword={value => this.setState({ password: value })}
                                                    onPressEnter={this.resetPassword}
                                                    repeat
                                                />
                                                <Button
                                                    className="reset-password-submit"
                                                    onClick={this.resetPassword}
                                                    type="primary"
                                                    loading={this.state.loading}
                                                    disabled={this.disableButton()}>
                                                    {"Sauvegarder"}
                                                </Button>
                                            </>
                                }

                            </div>
                        </div>
                    </div>
                    <span className="login-rights" >{`© ${moment().year()} - Sunkhronos - All rights reserved`}</span>
                </div>
            </div>
        );
    }
}

export default ResetPassword;