import { CaretRightOutlined, UndoOutlined } from '@ant-design/icons';
import { Collapse, Divider, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { loadPois } from '../../../store/actions/location';
import { loadSettings } from '../../../store/actions/planning';
import { customersFetched, mandatesFetched } from '../../../store/features/customerManagement';
import { ICustomer, IMandate, IMissionStatus } from '../../../utils/types/customerTypes';
import { POI } from '../../../utils/types/generalTypes';
import { PlanningColor } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {
    loading: boolean;
    missionsStatus: IMissionStatus[];
    setMissions(missionsStatus?: IMissionStatus[]): void;
    refresh?: boolean;
    endRefresh?: () => void;

}

interface State {
    selectedColors: number[];
    selectedPois: number[];
    selectedMandates: number[];
    selectedCustomers: number[];
}

class MissionFilters extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedColors:[],
            selectedPois:[],
            selectedMandates:[],
            selectedCustomers:[]
        }
    }

    componentDidMount() {
        this.props.loadPois(true);
        this.props.loadSettings();
        this.props.customersFetched();
        this.props.mandatesFetched();



    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.refresh !== prevProps.refresh && this.props.refresh) {
            console.log('REFRESH ', this.props.refresh)
            this.props.endRefresh && this.props.endRefresh();
            this.setFilters()
        }
    }

    setFilters(){

        const hasCustomersOrNotSet = (m:IMissionStatus) => {
            let ok=false;
            if (selectedCustomers.length > 0){
                if(selectedCustomers.includes(m.customerId)){
                    console.log("Filter", selectedCustomers, m)
                    ok = true;
                }   
            }else{
                ok = true;
            }
            return ok
        }
        const hasMandatesOrNotSet = (m:IMissionStatus) => {
            let ok=false;
            if (selectedMandates.length > 0){
                if(selectedMandates.includes(m.mandateId  )){
                    ok = true;
                }   
            }else{
                ok = true;
            }
            return ok
        }
        const hasPoisOrNotSet = (m:IMissionStatus) => {
            let ok=false;

            if (selectedPois.length > 0){
                if (m.poiId != null && selectedPois.includes(m.poiId)){
                    ok = true;
                }else if (m.poiId == null && selectedPois.includes(-1)){ //sans poi
                    ok = true;
                }
            }else{
                ok=true
            }
            return ok
        }
        const hasColorsOrNotSet = (m: IMissionStatus) => {
            let ok=false;

            if (selectedColors.length > 0){
                if(m.colorId != null && selectedColors.includes(m.colorId)){
                    ok = true;
                }else if(selectedColors.includes(-1) && m.colorId == null){ //default color
                    ok = true;
                }
            }else{
                ok=true
            }
            return ok
        }

        let missionsStatus = cloneDeep(this.props.missionsStatus);
        const { selectedCustomers, selectedColors, selectedMandates, selectedPois } = this.state;
        if(selectedCustomers.length > 0 || selectedMandates.length > 0 || selectedColors.length > 0 || selectedPois.length > 0){
            missionsStatus = missionsStatus.filter(m => {
                let toKeep = false
                if (hasCustomersOrNotSet(m) && hasMandatesOrNotSet(m) && hasPoisOrNotSet(m) && hasColorsOrNotSet(m)){
                    toKeep = true;
                }
    
                return toKeep;
    
            })
        }        
        
        this.props.setMissions(missionsStatus)
    }

    changePois = (selectedPois: number[]) => {
        this.setState({selectedPois}, () => {
            this.setFilters()
        })
    }

    changeColors = (selectedColors: number[]) => {
        this.setState({selectedColors}, () => {
            this.setFilters()
        })
    }

    changeCustomers = (selectedCustomers: number[]) => {
        this.setState({selectedCustomers}, () => {
            this.setFilters()
        })
    }

    changeMandates = (selectedMandates: number[]) => {
        this.setState({selectedMandates}, () => {
            this.setFilters()
        })
    }

    resetFilters = (resetAll=true, resetPois=false, resetMandates=false, resetColors=false, resetCustomers=false) => {
        let selectedColors = cloneDeep(this.state.selectedColors);
        let selectedPois = cloneDeep(this.state.selectedPois);
        let selectedMandates = cloneDeep(this.state.selectedMandates);
        let selectedCustomers = cloneDeep(this.state.selectedCustomers);

        if (resetAll){
            selectedColors=[];
            selectedMandates=[];
            selectedPois=[];
            selectedCustomers=[];

        }else{
            if (resetPois){
                selectedPois=[]
            }
            if (resetMandates){
                selectedMandates=[]
            }
            if (resetColors){
                selectedColors=[]
            }
            if (resetCustomers){
                selectedCustomers=[]
            }
        }

        this.setState({
            selectedColors,
            selectedMandates,
            selectedPois,
            selectedCustomers
        }, () => {
            this.setFilters()
        })
    }

    render() {
        const { selectedColors, selectedPois, selectedMandates, selectedCustomers } = this.state;
        const { loading, loadingPois, pois, colors, loadingColors, customers, loadingCustomers, mandates, loadingMandates } = this.props;


        let activeFilters = false;
        if (selectedColors.length > 0 || selectedPois.length > 0 || selectedMandates.length > 0 || selectedCustomers.length > 0){
            activeFilters = true;
        }

        const availableMandates = selectedCustomers ? mandates?.filter(m=>selectedCustomers.includes(m.customerId)) : mandates
        const availablesPois = selectedCustomers ? pois?.filter(p=> p.customerIds && p.customerIds.some(customerId => selectedCustomers.includes(customerId))) : mandates

        return (
            <div className='mission-filters'>
                {loading ?
                    null
                    :
                    <>
                        <Collapse
                            className="__collapse-background-transparent"
                            bordered={false}
                            style={{ textAlign: "initial" }}
                            accordion={true}
                            expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize: 10}} rotate={isActive ? 90 : 0} />}
                        >
                            <Collapse.Panel
                                className={`__collapse-main ${activeFilters? "__collapse-red" : "__collapse-normal"}`}
                                header={'Filtrer les missions'} 
                                key={`collapse-index-missions-filters-panel-1`}
                                style={{
                                    marginBottom: 7,
                                    // background: 'var(--background-color)',
                                    borderRadius: 'var(--border-radius)',
                                    border: 'none',
                                }}
                                extra={<UndoOutlined style={activeFilters === false ? { cursor: 'not-allowed', opacity: 0.5 } : { cursor: 'pointer' }} onClick={(e) => {e.stopPropagation(); activeFilters === true && this.resetFilters(true)}} title="Réinitialiser tous les filtres" />}>
                        
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 12 }}>
                                    <Select
                                        disabled={customers == undefined}
                                        loading={loadingCustomers}
                                        mode="multiple"
                                        autoClearSearchValue={false}
                                        allowClear
                                        placeholder="Client"
                                        style={{ width: '100%' }}
                                        value={selectedCustomers.length > 0 ? selectedCustomers : []}
                                        onChange={(customersIds: number[]) => this.changeCustomers(customersIds)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                        options={customers && customers.map((p: ICustomer) => ({label: p.title, value: p.id}))}
                                    />
                                    <Select
                                        disabled={availableMandates == undefined}
                                        loading={loadingMandates}
                                        mode="multiple"
                                        autoClearSearchValue={false}
                                        allowClear
                                        placeholder="Mandats"
                                        style={{ width: '100%' }}
                                        value={selectedMandates.length > 0 ? selectedMandates : []}
                                        onChange={(mandatesIds: number[]) => this.changeMandates(mandatesIds)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                        options={availableMandates && availableMandates.map((p: IMandate) => ({label: p.title, value: p.id}))}
                                    />
                                    <Divider style={{marginTop: 8, marginBottom: 8}}/>
                                    <Select
                                        disabled={availablesPois == undefined}
                                        loading={loadingPois}
                                        mode="multiple"
                                        autoClearSearchValue={false}
                                        allowClear
                                        placeholder="Lieux de travail"
                                        style={{ width: '100%' }}
                                        value={selectedPois.length > 0 ? selectedPois : []}
                                        onChange={(poisIds: number[]) => this.changePois(poisIds)}
                                        filterOption={true}
                                        optionFilterProp="label"
                                        showArrow
                                        maxTagCount={"responsive"}
                                        options={availablesPois && availablesPois.map((p: POI) => ({label: p.title, value: p.id})).concat({label: 'Sans lieu de travail', value: -1})}
                                    />
                                    <Select
                                        disabled={colors == undefined}
                                        loading={loadingColors}
                                        mode="multiple"
                                        autoClearSearchValue={false}
                                        allowClear
                                        placeholder="Couleurs"
                                        style={{ width: '100%' }}
                                        value={selectedColors.length > 0 ? selectedColors : []}
                                        onChange={(colorsIds: number[]) => this.changeColors(colorsIds)}
                                        filterOption={true}
                                        optionFilterProp="title"
                                        showArrow
                                        maxTagCount={"responsive"}
                                        options={colors && colors.map((c: PlanningColor) => ({title: c.title, label: <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 5}}><div style={{width: 10, height: 10, borderRadius: 'var(--border-radius)', backgroundColor: c.color}}/><span>{c.title}</span></div>, value: c.id})).concat([{title: 'Couleur par défaut', label: <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 5}}><div style={{width: 10, height: 10, borderRadius: 'var(--border-radius)', backgroundColor: 'var(--primary-color)'}}/><span>{'Couleur par défaut'}</span></div>, value: -1}])}
                                    />
                                    
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <Divider style={{marginTop: '5px'}} type={'horizontal'}/>
                    </>
                }
            </div>
        )
    }

}

const mapStateToProps = (state: ApplicationState) => ({
    displayFilters: state.window.displayFilters,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    pois: state.location.pois?.data,
    loadingPois: state.location.loadingPois,
    colors: state.planning.settings.colors,
    loadingColors: state.planning.loadingSettings,
    customers: state.customerManagement.customers.data,
    loadingCustomers: state.customerManagement.customers.loading,
    mandates: state.customerManagement.mandates.data,
    loadingMandates: state.customerManagement.mandates.loading
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadPois: (forceReload?: boolean) => dispatch(loadPois(forceReload)),
    loadSettings: (forceReload?: boolean) => dispatch(loadSettings(forceReload)),
    customersFetched: (forceReload?: boolean) => dispatch(customersFetched({ forceReload: forceReload ?? false })),
    mandatesFetched: (forceReload?: boolean) => dispatch(mandatesFetched({ forceReload: forceReload ?? false })),
});


const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(MissionFilters);