import moment, { Moment } from 'moment';
import { ThunkAction } from 'redux-thunk';
import { EXTREMLY_LONG_RELOAD, LONG_RELOAD } from '../../utils/constants';
import Network from '../../utils/network';
import { PlanningEvent, PlanningOvertime, PlanningSettings, PlanningTemplate, PlanningUserRow, ReportSettings, UserAvailability, UserAvailabilityWithUpdate } from '../../utils/types/planningTypes';
import { ApplicationState, PlanningState, StoreAction, StoreDispatch } from '../../utils/types/storeTypes';
import { convertNetworkSettingsToPlanningSettings, convertUserAvailabilitiesNetworkToUserAvailabilities, showNotification } from '../../utils/utils';
import {
    CHANGE_CELLS_PER_ROW, CHANGE_DRAGGED_GROUP_EVENT_USER_ID,
    CHANGE_DRAGGED_USER_EVENT_USER_ID, CHANGE_NEXT_LOADING, CHANGE_NEXT_USER_ROWS, CHANGE_NOW_LOADING, CHANGE_NOW_USER_ROWS, CHANGE_PREV_LOADING, CHANGE_PREV_USER_ROWS, CHANGE_REPORT_SETTINGS, CHANGE_SELECT_GROUPS, CHANGE_SELECT_USERS, CHANGE_SETTINGS, CHANGE_TEAM_AVAILABILITIES, CHANGE_TEMPLATES, CHANGE_USER_AVAILABILITIES, CHANGE_USER_ROWS, RESET, SET_EVENT_METHODS, SET_LOADING_USER_AVAILABILITIES, TOGGLE_DISPLAY_WORKING_TIME, TOGGLE_LOADING_PLANNING, TOGGLE_LOADING_SETTINGS, UPDATE_TEMPLATES
} from '../reducer/planning';

export type Effect = ThunkAction<any, PlanningState, any, StoreAction>;

/**
 * Toggle stored displayWorkingTime state
 */
export const toggleLoadingPlanning = (l: boolean): StoreAction => {
    return { type: TOGGLE_LOADING_PLANNING, data: l };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeUserRows = (userRows: PlanningUserRow[]): StoreAction => {
    return { type: CHANGE_USER_ROWS, data: userRows };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changePrevUserRows = (userRows: PlanningUserRow[]): StoreAction => {
    return { type: CHANGE_PREV_USER_ROWS, data: userRows };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeNowUserRows = (userRows: PlanningUserRow[]): StoreAction => {
    return { type: CHANGE_NOW_USER_ROWS, data: userRows };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeNextUserRows = (userRows: PlanningUserRow[]): StoreAction => {
    return { type: CHANGE_NEXT_USER_ROWS, data: userRows };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changePrevLoading = (l: boolean): StoreAction => {
    return { type: CHANGE_PREV_LOADING, data: l };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeNowLoading = (l: boolean): StoreAction => {
    return { type: CHANGE_NOW_LOADING, data: l };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeNextLoading = (l: boolean): StoreAction => {
    return { type: CHANGE_NEXT_LOADING, data: l };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeSelectGroups = (selectGroups?: number[]): StoreAction => {
    return { type: CHANGE_SELECT_GROUPS, data: selectGroups };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const changeSelectUsers = (selectUsers?: number[]): StoreAction => {
    return { type: CHANGE_SELECT_USERS, data: selectUsers };
}

/**
 * Toggle stored displayWorkingTime state
 */
export const toggleDisplayWorkingTime = (): StoreAction => {
    return { type: TOGGLE_DISPLAY_WORKING_TIME };
}

/**
 * Change stored templates
 * @param templates the new templates
 */
export const changeTemplates = (templates: PlanningTemplate[]): StoreAction => {
    return { type: CHANGE_TEMPLATES, data: templates };
}

export const updateTemplates = (templates: PlanningTemplate[]): StoreAction => {
    return { type: UPDATE_TEMPLATES, data: templates };
}

/**
 * Change stored settings
 * @param settings the new settings
 */
export const changeSettings = (settings: PlanningSettings): StoreAction => {
    return { type: CHANGE_SETTINGS, data: settings };
}

export const toggleLoadingSettings = (loading: boolean): StoreAction => ({ type: TOGGLE_LOADING_SETTINGS, data: loading });

export const loadSettings = (forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {

    const applicationState: ApplicationState = getState();
    if (applicationState.planning.settings?.updated === undefined || moment().diff(applicationState.planning.settings.updated, "minutes") >= EXTREMLY_LONG_RELOAD ||
        forceReload) {
        dispatch(toggleLoadingSettings(true));
        Network.getSettings().then(
            response => {
                dispatch(changeSettings(convertNetworkSettingsToPlanningSettings(response)));
            },
            () => {
                showNotification("Un problème est survenu pendant le chargement des paramètres du planning", "warning")
                dispatch(toggleLoadingSettings(false));
            },
        );
    }
}

/**
 * Change stored settings
 * @param settings the new settings
 */
export const changeReportSettings = (settings: ReportSettings): StoreAction => {
    return { type: CHANGE_REPORT_SETTINGS, data: settings };
}

/**
 * Change stored currentWeek
 * @param currentWeek the new currentWeek
 */
export const changeCellsPerRow = (cellsPerRow: number): StoreAction => {
    return { type: CHANGE_CELLS_PER_ROW, data: cellsPerRow };
}

/**
 * Change the stored dragged group event user's id
 * @param id the user's id
 */
export const changeDraggedGroupEventUserId = (id: number | undefined): StoreAction => ({ type: CHANGE_DRAGGED_GROUP_EVENT_USER_ID, data: id });


/**
 * Change the stored dragged user event user's id
 * @param id the user's id
 */
export const changeDraggedUserEventUserId = (id: number | undefined): StoreAction => ({ type: CHANGE_DRAGGED_USER_EVENT_USER_ID, data: id });

export const setLoadingUserAvailabilities = (loading: boolean): StoreAction => ({ type: SET_LOADING_USER_AVAILABILITIES, data: loading });
export const changeUserAvailabilities = (userId: number, userAvailabilities: UserAvailability[]): StoreAction => ({ type: CHANGE_USER_AVAILABILITIES, data: { userId, userAvailabilities } });
export const changeTeamAvailabilities = (teamAvailabilities: UserAvailabilityWithUpdate[]): StoreAction => ({ type: CHANGE_TEAM_AVAILABILITIES, data: teamAvailabilities });

export const loadUserAvailabilities = (userId: number, year: Moment, forceReload = false) => (dispatch: StoreDispatch, getState: () => ApplicationState) => {
    const applicationState: ApplicationState = getState();
    const userAvailabilities = applicationState.planning.teamAvailabilities.find(ta => ta.userId === userId);
    if (userAvailabilities === undefined || moment().diff(userAvailabilities.updated, "minutes") >= LONG_RELOAD || forceReload) {
        dispatch(setLoadingUserAvailabilities(true));
        Network.getUserAvailabilities(userId, undefined, year.clone().startOf('year'), year.clone().endOf('year')).then(
            response => {
                if (response.error) {
                    showNotification("Un problème est survenu pendant le chargement disponibilités", "error");
                } else {
                    dispatch(changeUserAvailabilities(userId, convertUserAvailabilitiesNetworkToUserAvailabilities(response.data)));
                }
            },
            () => {
                showNotification("Un problème est survenu pendant le chargement disponibilités", "error");
            },
        ).finally(() => dispatch(setLoadingUserAvailabilities(false)));
    }
}

/**
 * Set the event methods
 * @param onClickEvent method
 * @param onEditEvent method
 * @param onDeleteEvent method
 */
export const setEventMethods = (
    onClickEvent: (event: PlanningEvent) => void,
    onEditEvent: (event: PlanningEvent) => void,
    onDeleteEvent: (event: PlanningEvent) => void,
    onEditOvertime: (o: PlanningOvertime) => void,
    onDeleteOvertime: (o: PlanningOvertime) => void): StoreAction => ({ type: SET_EVENT_METHODS, data: { onClickEvent, onEditEvent, onDeleteEvent, onEditOvertime, onDeleteOvertime } });

/**
 * Reset planning reducer
 */
export const reset = () => ({ type: RESET });