import { TypedUseSelectorHook, connect as reduxConnect, useSelector } from 'react-redux';
import rootReducer from './reducer/index';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export const connect = <T>(mappedState: (state: RootState) => T) => reduxConnect(mappedState);