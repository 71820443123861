import { Drawer, Empty, Popover, Space, Spin, Tooltip } from 'antd';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Privileges } from '../../privileges';
import { changeCompanyDetails, changeCountries, changeLanguages, changeMedals, reset } from '../../store/actions/crm';
import Network from '../../utils/network';
import { Company, Country, FullSMSAccount, Medal, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { checkPrivilege, showNotification, ShrinkedText } from '../../utils/utils';
// import ClickParam from 'antd/lib/menu';
import { MailOutlined, MessageOutlined, PlusOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { changeSMSAccount, changeSMSAccountLoading } from '../../store/actions/messages';
import getFormat from '../../utils/Lang';
import { CRMMessage, CRMMessageClient } from '../../utils/types/networkTypes';
import { IntlProps } from '../app/LanguageProvider';
import CircleButton from '../common/fields/circleButton';
import Card from '../common/general/card';
import { tableColumnTextFilterConfig } from '../courseManagement/tableSearch';
import ModalCreateMessage from './messages/modalCreateMessage';
import SMS from './messages/sms';

type ReduxProps = ConnectedProps<typeof connector>

interface IProps {
    user: User | undefined;
    companyDetails: Company | undefined;
}

type Props = IProps & RouterProps & ReduxProps & IntlProps;

interface State {
    sms?: FullSMSAccount;
    smsLoading: boolean
    openSMSConfig: boolean;
    createNewMessage: boolean;
    messages?: CRMMessage[];
    messagesLoading: boolean;
    expandedRowKey: string[];
}

/**
 * Component for the CRM management page
 */
class MessagesTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            smsLoading: false,
            sms: undefined,
            openSMSConfig: false,
            createNewMessage: false,
            messages: undefined,
            messagesLoading: false,
            expandedRowKey: [],
        }
    }

    componentDidMount() {
        this.getAllMessages()
        this.refreshSMS()
    }



    // componentWillUnmount() {
    //     // this.props.reset!();
    // }

    // componentDidUpdate(prevProps: Props) {

    // }


    getAllMessages = () => {
        this.setState({ messagesLoading: true })
        Network.getClientMessages().then(
            (response) => {
                this.setState({ messages: response, messagesLoading: false })
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the sent messages' }), "warning");
                this.setState({ messages: undefined, messagesLoading: false })

            }
        )
    }

    refreshSMS = () => {
        this.props.changeSMSAccountLoading(true)

        Network.getFullSMS().then(
            (response: FullSMSAccount) => {
                this.props.changeSMSAccount(response)
                this.props.changeSMSAccountLoading(false)
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the SMS' }), "warning");
                this.props.changeSMSAccountLoading(false)
            }
        );
    }
    closeModal = (reload?: boolean) => {
        if (reload) {
            this.refreshSMS()
            this.getAllMessages()
        }
        this.setState({ createNewMessage: false })
    }
    changeSMS = (smsAccount: FullSMSAccount) => {
        this.props.changeSMSAccount(smsAccount)
    }


    render() {
        const { openSMSConfig, messages, messagesLoading, createNewMessage, expandedRowKey } = this.state
        const { intl } = this.props;
        const sms_privilege = checkPrivilege(Privileges.SMS.Visit, this.props.currentUser)

        return (
            <Card
                title={<FormattedMessage defaultMessage={'External communications'} />}
                icon={<SoundOutlined />}
                style={{ height: 'fit-content', minHeight: 'inherit' }}
                headerElements={[
                    <Space key={`smsSent-sms-card-extras`}>
                        <CircleButton
                            onClick={() => this.setState({ createNewMessage: true })}
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'New communication' })}
                        // placement="topLeft"
                        // key="crm-promotion-add" 
                        />
                        <CircleButton
                            type={openSMSConfig ? 'primary' : 'default'}
                            placement={"bottomRight"}
                            icon={<SettingOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'SMS Configuration' })}
                            onClick={() => this.setState((state) => ({ openSMSConfig: !state.openSMSConfig }))}>
                        </CircleButton>
                    </Space>
                ]}
            >
                <div
                    className='messages-tab-container'
                    style={{ height: 'fit-content' }}
                >
                    <Table
                        loading={messagesLoading}
                        key={`messages-tab-list-messages`}
                        className='__table_messagesTab'
                        rowKey={(cd: CRMMessage) => `table-item-${cd.id}`}
                        dataSource={messages || []}
                        columns={this.columns}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No communication'} />} /> }}
                        pagination={{
                            defaultPageSize: 16,
                            pageSizeOptions: ["8", "16", "24", "32", "64", "72"],
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} messages'} values={{ range0: range[0], range1: range[1], total }} />
                        }}
                        scroll={{ x: 'max-content' }}
                        onRow={(data: CRMMessage) => ({
                            onClick: () => {
                                let expandedRowKey = cloneDeep(this.state.expandedRowKey)
                                if (!expandedRowKey.includes(`table-item-${data.id}`)) {
                                    expandedRowKey.push(`table-item-${data.id}`)
                                } else {
                                    expandedRowKey = expandedRowKey.filter(rowKey => rowKey !== `table-item-${data.id}`)
                                }
                                this.setState({ expandedRowKey })

                            }
                        })}
                        expandable={{
                            expandedRowKeys: expandedRowKey,
                            onExpandedRowsChange: (expandedRowKey => this.setState({ expandedRowKey: expandedRowKey as string[] })),
                            expandedRowRender: record =>
                                <Table
                                    style={{ marginTop: 11, marginBottom: 11, width: 'calc( 100% - 32px)', border: '1px solid var(--primary-color)', borderRadius: 'var(--border-radius)' }}
                                    // loading={messagesLoading}
                                    key={`messages-tab-list-messages-clients`}
                                    className='__table_messagesTab_ExpandedTable'
                                    rowKey={(cd: CRMMessageClient) => cd.id}
                                    dataSource={record.clients || []}
                                    columns={this.clientsColumn}
                                    pagination={false}
                                //scroll={{ x: 'max-content' }}
                                />
                            ,
                            rowExpandable: record => record.clients.length > 0,
                        }}
                    />

                    {sms_privilege &&
                        <Drawer
                            title={<FormattedMessage defaultMessage={'SMS Configuration'} />}
                            open={openSMSConfig}
                            getContainer={false}
                            width={'100%'}
                            rootStyle={{ top: 15 }}
                            contentWrapperStyle={{
                                borderTopLeftRadius: 'var(--border-radius)',
                                borderTopRightRadius: 'var(--border-radius)',
                                height: 'calc( 100vh - 100px )',
                            }}
                            headerStyle={{
                                height: 85
                            }}
                            bodyStyle={{ padding: 10 }}
                            onClose={() => this.setState({ openSMSConfig: false })}
                        >
                            {!this.props.smsAccountLoading && this.props.smsAccount !== undefined ?
                                <SMS />
                                :
                                this.props.smsAccountLoading ?
                                    <Spin
                                        style={{ marginTop: '20px', textAlign: "center", display: "block" }}
                                        size="large"
                                        spinning={this.props.smsAccountLoading}
                                    />
                                    :
                                    <Empty
                                        style={{ marginTop: '20px', textAlign: "center", display: "block" }}
                                    />
                            }
                        </Drawer>
                    }
                    <ModalCreateMessage createNewMessage={createNewMessage} onClose={this.closeModal} />
                </div>
            </Card>

        )

    }
    columns: ColumnsType<CRMMessage> = [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            dataIndex: 'created',
            key: 'created',
            width: '200px',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.created).unix() - moment(b.created).unix(),
            },
            render: (created: string) => moment(created).format(getFormat('DATE_AND_TIME'))

        },
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            // dataIndex: 'type',
            key: 'type',
            width: '80px',
            sorter: (a: CRMMessage, b: CRMMessage) => !a.type ? -1 : !b.type ? 1 : a.type < b.type ? -1 : 1,
            render: (record: CRMMessage) => {
                return (
                    <Tooltip
                        title={record.type === 'sms' ? this.props.intl.formatMessage({ defaultMessage: 'SMS' }) : this.props.intl.formatMessage({ defaultMessage: 'Email' })}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{record.type === 'sms' ? <MessageOutlined /> : record.type === 'mail' ? <MailOutlined /> : null}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Message'} />,
            // dataIndex: 'message',
            key: 'message',
            onFilter: (value, record) => {
                const message = record.type === 'sms' && record.sms ? record.sms.text : record.type === 'mail' && record.mail ? record.mail.title : ''
                return message!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<CRMMessage>(),
            sorter: (a: CRMMessage, b: CRMMessage) => {
                const aText = a.type === 'sms' && a.sms ? a.sms.text : a.type === 'mail' && a.mail ? a.mail.title : ''
                const bText = b.type === 'sms' && b.sms ? b.sms.text : b.type === 'mail' && b.mail ? b.mail.title : ''
                return !aText ? -1 : !bText ? 1 : aText < bText ? -1 : 1
            },
            render: (record: CRMMessage) => record.type === 'sms' && record.sms ? <pre style={{ fontFamily: 'inherit', marginBottom: 0 }}>{record.sms.text}</pre> : record.type === 'mail' && record.mail ? <Popover title={record.mail.title} content={<div style={{ fontFamily: 'inherit', marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: record.mail.text }} />}>{record.mail.title}</Popover> : ''
        },
        Table.EXPAND_COLUMN,
        {
            title: <FormattedMessage defaultMessage={'Recipients'} />,
            // dataIndex: 'message',
            key: 'dest',
            width: 25,
            render: (record: CRMMessage) => record.clients ? record.clients.length : 0
        },
    ];
    clientsColumn: ColumnsType<CRMMessageClient> = [

        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            // dataIndex: 'firstName',
            key: 'lastName',
            onFilter: (value, record) => {
                return record.lastName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<CRMMessageClient>(),
            sorter: (a: CRMMessageClient, b: CRMMessageClient) => a.lastName < b.lastName ? -1 : 1,
            render: (ob: CRMMessageClient) => {
                return <ShrinkedText key={`shrinked-text-lesson-name-${ob.id}`} maxSize={250}>{ob.lastName}</ShrinkedText>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            // dataIndex: 'firstName',
            key: 'firstName',
            onFilter: (value, record) => {
                return record.firstName!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            width: 250,
            sorter: (a: CRMMessageClient, b: CRMMessageClient) => a.firstName < b.firstName ? -1 : 1,
            ...tableColumnTextFilterConfig<CRMMessageClient>(),
            render: (ob: CRMMessageClient) => {
                return <ShrinkedText key={`shrinked-text-lesson-name-${ob.id}`} maxSize={240}>{ob.firstName}</ShrinkedText>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Email'} />,
            // dataIndex: 'email',
            key: 'email',
            onFilter: (value, record) => {
                return record.mail!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            width: 250,
            ...tableColumnTextFilterConfig<CRMMessageClient>(),
            sorter: (a: CRMMessageClient, b: CRMMessageClient) => !a.mail ? -1 : !b.mail ? 1 : a.mail < b.mail ? -1 : 1,
            render: (ob: CRMMessageClient) => {
                return <ShrinkedText key={`shrinked-text-lesson-mail-${ob.id}`} maxSize={240}>{ob.mail}</ShrinkedText>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Phone'} />,
            // dataIndex: 'mobile',
            key: 'mobile',
            onFilter: (value, record) => {
                return record.mobile!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            width: 200,
            ...tableColumnTextFilterConfig<CRMMessageClient>(),
            sorter: (a: CRMMessageClient, b: CRMMessageClient) => !a.mobile ? -1 : !b.mobile ? 1 : a.mobile < b.mobile ? -1 : 1,
            render: (record: CRMMessageClient) => record.mobile
        },
        {
            title: <FormattedMessage defaultMessage={'Language'} />,
            dataIndex: 'language',
            key: 'language',
            width: 50
        },
    ]
}

const mapStateToProps = (state: ApplicationState) => ({
    companyDetails: state.crm.companyDetails,
    currentUser: state.user.currentUser,
    smsAccount: state.messages.smsAccount,
    smsAccountLoading: state.messages.smsAccountLoading
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeCompanyDetails: (c: Company) => dispatch(changeCompanyDetails(c)),
    changeMedals: (m: Medal[]) => dispatch(changeMedals(m)),
    changeCountries: (c: Country[]) => dispatch(changeCountries(c)),
    changeLanguages: (c: Country[]) => dispatch(changeLanguages(c)),
    changeSMSAccountLoading: (loading: boolean) => dispatch(changeSMSAccountLoading(loading)),
    changeSMSAccount: (smsAccount: FullSMSAccount) => dispatch(changeSMSAccount(smsAccount)),
    reset: () => dispatch(reset()),
    dispatch,

});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(injectIntl(MessagesTab)));