import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, Popover, Row, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import { MouseEvent, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Group, POI, Sector } from '../../../utils/types/generalTypes';
import CircleButton from '../../common/fields/circleButton';
import { tableColumnTextFilterConfig } from '../tableSearch';

interface Props {
    pois: POI[];
    selectedPois: number[];
    setSelectedPois(poiIds: number[]): void;
    body: JSX.Element;
    title: string;
    button: {
        title: string;
        icon: JSX.Element
        disabled?: boolean;

    }
    confirmButton?: {
        title: string | JSX.Element;
        onClick(e: MouseEvent): void;
        disabled?: boolean;
    }
    open: boolean;
    setOpen(open: boolean): void;
}

const PoiFastEdit = (props: Props) => {
    const { title, pois, selectedPois, setSelectedPois, body, button, confirmButton, open, setOpen } = props
    const intl = useIntl();


    const hide = useCallback(() => {
        setOpen(false);
    }, [setOpen])

    const handleOpenChange = useCallback((newOpen: boolean) => {
        setOpen(newOpen);
    }, [setOpen])

    const poisColumns = useMemo((): ColumnProps<POI>[] => ([
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Sectors'} />,
            dataIndex: 'sectors',
            key: 'sectors',
            render: (sectors: Sector[]) => sectors.map(s => (
                <Tag className="__report-groups-users-tags" style={s.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={s.id} >
                    {s.title}
                </Tag>
            ))

        },
        {
            title: <FormattedMessage defaultMessage={'Groups'} />,
            dataIndex: 'groups',
            key: 'groups',
            render: (sectors: Group[]) => sectors.map(g => (
                <Tag className="__report-groups-users-tags" style={g.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={g.id} >
                    {g.name}
                </Tag>
            ))

        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            className: '__width_60 __centered-text',
            // className: '__poi-actions',
            render: (record: POI) => {
                return <CircleButton title={intl.formatMessage({ defaultMessage: 'Deselect location' })} icon={<MinusCircleOutlined />} onClick={() => setSelectedPois(selectedPois.filter(pId => pId !== record.id))} />
            },
        }
    ]), [intl, selectedPois, setSelectedPois])

    return (

        <Popover
            overlayStyle={{ width: 650 }}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="bottomRight"
            arrowPointAtCenter
            key={`event-popover-pois-${pois.length}`}
            title={<span onClick={(e) => e.stopPropagation()}>{title}</span>}
            content={
                <Row gutter={[10, 10]} onClick={(e) => e.stopPropagation()}>
                    <Collapse bordered={false} style={{ width: '100%' }}>
                        <Collapse.Panel key={'collapse-poi-list'} header={<FormattedMessage defaultMessage={'{count} selected events'} values={{ count: selectedPois.length }} />}>
                            <Table
                                size='small'
                                key="table-pois-fast"
                                className='__poi-list-fast'
                                rowKey={(p: any) => p.id}
                                dataSource={pois.filter(p => p.id && selectedPois.includes(p.id))}
                                columns={poisColumns}
                                // loading={this.state.groupsLoading}
                                pagination={{
                                    defaultPageSize: 4,
                                    //pageSizeOptions: ["8", "16", "24", "32", "64", "72"],
                                    showSizeChanger: false,
                                    hideOnSinglePage: true,
                                    showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} points of interest'} values={{ range0: range[0], range1: range[1], total }} />
                                }}
                            //onRow={this.poiOnRow}
                            //rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedPoi }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                    <Col xs={{ span: 24 }}>
                        <Divider style={{ margin: '0' }} />
                    </Col>
                    {body}
                    <Col span={24} style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 8 }}>
                        <Button
                            type={'dashed'}
                            onClick={hide}
                        >
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>
                        {confirmButton && <Button
                            type={'primary'}
                            disabled={confirmButton.disabled}
                            onClick={(e) => { confirmButton.onClick(e); hide() }}>{confirmButton.title}</Button>}
                    </Col>

                </Row>
            }
        >
            <CircleButton
                key={`modifier-circlebutton-poi-object-${selectedPois.length}-edit`}
                icon={button.icon}
                title={button.title}
                onClick={(e) => e.stopPropagation()}
                disabled={button.disabled}
            />
        </Popover>
    );
};

export default PoiFastEdit;