import { FullSMSAccount } from "../../utils/types/generalTypes";
import { StoreAction } from "../../utils/types/storeTypes";
import { CHANGE_SMS_ACCOUNT, CHANGE_SMS_ACCOUNT_LOADING, RESET } from "../reducer/messages";

/**
 * Change stored company details
 * @param companyDetails the new company details to store
 * @return a store action
 */
export const changeSMSAccount = (smsAccount: FullSMSAccount): StoreAction => ({ type: CHANGE_SMS_ACCOUNT, data: smsAccount });


/**
 * Change stored company products
 * @param companyPoducts the new company products to store
 * @return a store action
 */
export const changeSMSAccountLoading = (loading: boolean): StoreAction => ({ type: CHANGE_SMS_ACCOUNT_LOADING, data: loading });

/**
 * Reset all stored values
 * @return a store action
 */
export const reset = () => ({ type: RESET });

