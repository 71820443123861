import L, { LatLng, LatLngTuple, latLng } from "leaflet";
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import { default as icon, default as iconNew } from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import React, { useMemo, useRef, useState } from 'react';
import { FeatureGroup, GeoJSON, LayersControl, MapContainer, Marker, TileLayer, Tooltip, useMapEvents } from 'react-leaflet';
import { connect } from 'react-redux';
import { Course, POI, Sector } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
// import { GeoJSON as GeoJsonObject } from 'geojson';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal } from 'antd';
import "leaflet/dist/leaflet.css";
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import isEqual from "react-fast-compare";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FormattedMessage, injectIntl } from "react-intl";
import MarkerClusterGroup from 'react-leaflet-cluster';
import key from 'weak-key';
import '../../../styles/map.css';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from "../../app/LanguageProvider";
import PoiEditForm from '../../courseManagement/poi/poiEditForm';
import PoiShowForm from '../../courseManagement/poi/poiShowForm';
import GoogleMaps from "./googleMaps";
import MapSearchField from "./mapSearchField";

const markerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconRetinaUrl: iconRetina,

    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [13, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [13, 41],  // the same for the shadow
    popupAnchor: [-1, -41] // point from which the popup should open relative to the iconAnchor
});

const newMarkerIcon = L.icon({
    iconUrl: iconNew,
    shadowUrl: iconShadow,
    iconRetinaUrl: iconRetina,

    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [13, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [13, 41],  // the same for the shadow
    popupAnchor: [-1, -41] // point from which the popup should open relative to the iconAnchor
});
// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow
// });

// L.Marker.prototype.options.icon = DefaultIcon;

interface IProps {
    onClick?: (poi: number) => void;
    isModify?: (pois: POI[]) => void;
    className?: string,
    pois?: POI[];
    isBasePois?: boolean;
    isEditMode: boolean;
    onlyDisplay?: boolean;
    sectors?: Sector[];
    courses?: Course[];
    isSmartphone: boolean;
}

type Props = IProps & IntlProps;

interface State {
    zoom: number;
    center: LatLngTuple;
    pois?: POI[];
    defaultPois?: POI[];
    draggable: boolean;
    idNewPoi: number;
    isDrawerVisible: boolean;
    isAddingPOI: boolean;
    isEditPOI: boolean;
    poiSelected?: POI;
    poiEdit?: POI;
    googleMapsApiKey?: string;
}

/**
 * Component that represent a Skeleton (placeholder component when loading element)
 */
class MapLeaflet extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            center: [46.25952238233216, 7.474810746155075],
            zoom: 12,
            pois: this.props.pois,
            draggable: true,
            idNewPoi: -1,
            isDrawerVisible: false,
            isAddingPOI: false,
            isEditPOI: false
        }
    }

    componentDidMount() {
        this.setState({ pois: this.props.pois ? [...this.props.pois] : undefined, defaultPois: this.props.pois ? [...this.props.pois] : undefined });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.isEditMode !== this.props.isEditMode && (prevState.isAddingPOI === true || prevState.isDrawerVisible === true)) {
            this.setState({ isDrawerVisible: false, isAddingPOI: false });
        }
        const { defaultPois } = this.state;
        if (this.props.pois && !isEqual(this.props.pois, defaultPois)) {
            this.setState({ pois: this.props.pois ? [...this.props.pois] : undefined, defaultPois: this.props.pois ? [...this.props.pois] : undefined });
        }
    }

    addMarker = (event: any, title?: string, address?: string) => {
        let { idNewPoi } = this.state;
        const { pois } = this.state;
        const { intl } = this.props;
        const coords: POI = {
            id: idNewPoi,
            title: title ?? intl.formatMessage({ defaultMessage: 'New' }) + (idNewPoi * -1),
            latitude: event.latlng.lat,
            longitude: event.latlng.lng,
            address: address
        };
        const samePoint = pois?.filter(p => p.latitude === coords.latitude && p.longitude === coords.longitude);
        if (samePoint && samePoint.length > 0) {
            Modal.info({

                title: intl.formatMessage({ defaultMessage: 'Unable to place a POI' }),
                content: (<FormattedMessage defaultMessage={'Another POI already exists at this location.'} />),
                icon: null,
                centered: true,
            });
        } else {
            pois ?
                this.setState({ pois: [...pois, coords], idNewPoi: idNewPoi -= 1 }, () => {
                    this.props.isModify && this.state.pois && this.props.isModify(this.state.pois);
                })
                :
                showNotification(intl.formatMessage({ defaultMessage: 'Unable to add a POI' }), 'error')
        }
    };

    // deletePoi = (poi: number) => {
    //     let { pois } = this.state;
    //     pois = pois.filter(p => p.id !== poi);
    //     this.props.isModify && this.props.isModify();
    //     this.setState({ pois });
    // }

    // editPoi = (poiId: number) => {
    //     let poi: POI | undefined = this.state.pois.find(p => p.id === poiId);
    //     poi && (poi = { ...poi });
    //     this.setState({ poiEdit: poi, isAddingPOI: true });
    // }

    deletePoi = (poi: number) => {
        let { pois } = this.state;

        if (poi < 0) {
            pois = pois?.filter(p => {
                if (p.id !== poi) {
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            pois = pois?.map(p => {
                if (p.id !== poi) {
                    return p;
                } else {
                    p.toDelete = true;
                    p.toUpdate = false;
                    return p;
                }
            });
        }

        pois && this.setState({ pois }, () => {
            this.props.isModify && pois && this.props.isModify(pois);
        });
    }

    editPoi = (poiId: number) => {
        let poi: POI | undefined = this.state.pois?.find(p => p.id === poiId);
        poi && (poi = { ...poi });
        this.setState({ poiEdit: poi, isAddingPOI: true });
    }

    showPoi = (poiId: number) => {
        const poi: POI | undefined = this.state.pois?.find(p => p.id === poiId);
        this.setState({ poiSelected: poi, isDrawerVisible: true });
    }

    /**
     * @description
     * Takes an Array<V>, and a grouping function,
     * and returns a Map of the array grouped by the grouping function.
     *
     * @param list An array of type V.
     * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
     *                  K is generally intended to be a property key of V.
     *
     * @returns Map of the array grouped by the grouping function.
     */
    //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    //    const map = new Map<K, Array<V>>();
    groupBy(list: Array<POI>) {
        const map = new Map();
        list.forEach((item) => {
            const courses: Course[] | undefined = item.courses;
            courses?.forEach(c => {
                const collection = map.get(c.id);
                if (!collection) {
                    map.set(c.id, [item]);
                } else {
                    collection.push(item);
                }
            })
        });
        return map;
    }

    updatePoi = () => {
        const poi = { ...this.state.poiEdit };
        if (poi) {

            if (poi.image && typeof (poi.image) === "string" && poi.image.includes("https://storage.googleapis.com/")) {
                poi.image = poi.image.replace("https://storage.googleapis.com/", "");
            }


            let { pois } = this.state;
            pois = pois?.map(p => {
                if (p.id === poi.id) {
                    if (poi.id !== undefined && poi.id > 0) {
                        poi.toUpdate = true;
                        poi.toDelete = false;
                        poi.oldData = p;
                    }
                    return poi;
                } else {
                    return p;
                }
            })
            // let alertMessage = "Le POI a été mis à jour.";

            this.setState({ pois, poiSelected: poi, isAddingPOI: false, isDrawerVisible: true }, () => {
                this.props.isModify && pois && this.props.isModify(pois);
            });
        }
    }

    render() {
        const { isEditMode, courses, intl } = this.props;
        const { zoom, center, poiSelected } = this.state;
        const pois = this.state.pois ? [...this.state.pois] : [];
        const availablePois = pois?.filter(p => p.toDelete !== true);
        const parsedPois = availablePois ? this.groupBy(availablePois.filter(p => p.id !== undefined && p.id > 0)) : undefined;
        const poisWithoutCourse = availablePois?.filter(ap => ap.id !== undefined && ap.id > 0 && (ap.courses === undefined || ap.courses.length === 0))
        const poisNew = availablePois?.filter(p => p.id !== undefined && p.id < 0);

        return (
            <FullScreenContent onlyDisplay={this.props.onlyDisplay === true ? true : false}>
                <>
                    <MapContainer
                        tap={isMacOs && isSafari ? false : true}
                        preferCanvas={true}
                        className={`leaflet-container-${this.props.isEditMode ? "crosshair" : "grab"}`}
                        key={`map-container-${isEditMode}`}
                        center={center}
                        zoom={zoom}
                        zoomControl={this.props.onlyDisplay === true ? false : true}
                        dragging={this.props.onlyDisplay === true ? false : true}
                        keyboard={this.props.onlyDisplay === true ? false : true}
                        scrollWheelZoom={this.props.onlyDisplay === true ? false : true}
                        doubleClickZoom={false}
                        // onClick={(e) => this.addMarker(e)}
                        style={{ height: '100%' }}>

                        {/* <MapConsumer>
                            {(map) => {
                                // console.log("map center:", map.getCenter());
                                map.on("click", (e) => this.addMarker(e));
                                return null;
                            }}
                        </MapConsumer> */}
                        {
                            isEditMode &&
                            <MapEditMode addMarker={this.addMarker} />
                        }
                        {/* <MapSync setMove={(e: LatLngTuple) => this.setState({ center: e }, () => console.log("YEP", e))} setZoom={(e: number) => this.setState({ zoom: e })} /> */}
                        {/* <MapSync setMove={(e: LatLngTuple) => this.setState({ center: e }, () => console.log("YEP", e))} setZoom={(e: number) => this.setState({ zoom: e })} /> */}
                        {
                            isEditMode ?
                                <GoogleMaps minutes={15} apikey={this.state.googleMapsApiKey} update={(apikey) => this.setState({ googleMapsApiKey: apikey })}>
                                    <MapSearchField key={`mapsearchfield-${this.state.googleMapsApiKey}`} googleMapsApiKey={this.state.googleMapsApiKey} addMarker={this.addMarker} />
                                </GoogleMaps>
                                : null
                        }
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name={intl.formatMessage({ defaultMessage: 'Default' })}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Satellite' })}>
                                <TileLayer
                                    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Google Maps' })}>
                                <TileLayer
                                    maxZoom={20}
                                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                    url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Black and white' })}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Swiss 1' })}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://tile.osm.ch/switzerland/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name={intl.formatMessage({ defaultMessage: 'Swiss 2' })}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            {
                                pois && parsedPois && Array.from(parsedPois, ([k, value], idx) => {
                                    const actualCourse = courses?.find(c => c.id === k);
                                    const actualGeoJson = actualCourse && actualCourse.geoJson;
                                    let parsedGeoJson;
                                    try {
                                        parsedGeoJson = actualGeoJson ? JSON.parse(actualGeoJson) : undefined;
                                    } catch (error) {
                                        parsedGeoJson = undefined;
                                    }

                                    return (<LayersControl.Overlay key={`${key(pois)}-${k}-${idx}`} checked name={actualCourse && actualCourse.title ? actualCourse.title : intl.formatMessage({ defaultMessage: 'Unknown name' })}>
                                        <FeatureGroup>
                                            {
                                                value.length > 0 && parsedGeoJson &&
                                                <GeoJSON key={"geojson"} style={{ color: actualCourse?.color, weight: 4, opacity: 0.7 }} attribution={intl.formatMessage({ defaultMessage: 'Credits due to Sunkhronos' })} data={parsedGeoJson} />
                                            }

                                            <MarkerClusterGroup maxClusterRadius={(zoom: number) => zoom === 18 ? 10 : 80} chunkedLoading>
                                                {

                                                    value.length > 0 &&
                                                    value.map((p: POI) => {
                                                        if (p.longitude && p.latitude) {
                                                            return (
                                                                <DraggableMarker
                                                                    deplacePoi={(positionCenter) => {
                                                                        let newPois = pois;
                                                                        newPois = newPois.map(npf => {
                                                                            if (npf.id !== p.id) {
                                                                                return npf;
                                                                            } else {
                                                                                const newNpf = { ...npf };
                                                                                newNpf.latitude = positionCenter.lat;
                                                                                newNpf.longitude = positionCenter.lng;
                                                                                newNpf.toUpdate = true;
                                                                                newNpf.toDelete = false;
                                                                                newNpf.oldData = npf;
                                                                                return newNpf;
                                                                            }
                                                                        });
                                                                        newPois && this.setState({ pois: [...newPois] }, () => {
                                                                            this.props.isModify && newPois && this.props.isModify([...newPois]);
                                                                        });
                                                                    }}
                                                                    onClick={this.props.onClick}
                                                                    showPoi={this.showPoi}
                                                                    editPoi={this.editPoi}
                                                                    deletePoi={this.deletePoi}
                                                                    key={"draggable-marker-" + isEditMode + "-" + p.id!}
                                                                    keyId={p.id!} initialDraggable={isEditMode}
                                                                    positionCenter={latLng(p.latitude, p.longitude)}
                                                                    title={p.title} />

                                                            )
                                                        } else { return null }
                                                    })
                                                }
                                            </MarkerClusterGroup>
                                        </FeatureGroup>
                                    </LayersControl.Overlay>)
                                })
                            }
                            <LayersControl.Overlay checked name={intl.formatMessage({ defaultMessage: 'Without route' })}>
                                <FeatureGroup>
                                    <MarkerClusterGroup maxClusterRadius={(zoom: number) => zoom === 18 ? 10 : 80} chunkedLoading>
                                        {
                                            poisWithoutCourse && poisWithoutCourse.length > 0 &&
                                            poisWithoutCourse.map(p => {
                                                if (p.longitude && p.latitude) {
                                                    return (
                                                        <DraggableMarker
                                                            deplacePoi={(positionCenter) => {
                                                                let newPois = pois;
                                                                newPois = newPois?.map(npf => {
                                                                    if (npf.id !== p.id) {
                                                                        return npf;
                                                                    } else {
                                                                        const newNpf = { ...npf };
                                                                        newNpf.latitude = positionCenter.lat;
                                                                        newNpf.longitude = positionCenter.lng;
                                                                        newNpf.toUpdate = true;
                                                                        newNpf.toDelete = false;
                                                                        newNpf.oldData = npf;
                                                                        return newNpf;
                                                                    }
                                                                });
                                                                newPois && this.setState({ pois: [...newPois] }, () => {
                                                                    this.props.isModify && newPois && this.props.isModify([...newPois]);
                                                                });
                                                            }}
                                                            onClick={this.props.onClick}
                                                            showPoi={this.showPoi}
                                                            editPoi={this.editPoi}
                                                            deletePoi={this.deletePoi}
                                                            key={"draggable-marker-" + isEditMode + "-" + p.id!}
                                                            keyId={p.id!} initialDraggable={isEditMode}
                                                            positionCenter={latLng(p.latitude, p.longitude)}
                                                            title={p.title} />

                                                    )
                                                } else { return null }
                                            })
                                        }
                                    </MarkerClusterGroup>
                                </FeatureGroup>
                            </LayersControl.Overlay>
                            <LayersControl.Overlay checked={isEditMode} name={intl.formatMessage({ defaultMessage: 'New points' })}>
                                <FeatureGroup>

                                    {/* <MarkerClusterGroup chunkedLoading> */}
                                    {
                                        poisNew && poisNew.length > 0 &&
                                        poisNew.map(p => {
                                            if (p.longitude && p.latitude) {
                                                return (
                                                    <DraggableMarker
                                                        deplacePoi={(positionCenter) => {
                                                            let newPois = pois;
                                                            newPois = newPois?.map(npf => {
                                                                if (npf.id !== p.id) {
                                                                    return npf;
                                                                } else {
                                                                    const newNpf = { ...npf };
                                                                    newNpf.latitude = positionCenter.lat;
                                                                    newNpf.longitude = positionCenter.lng;
                                                                    newNpf.toUpdate = false;
                                                                    newNpf.toDelete = false;
                                                                    return newNpf;
                                                                }
                                                            });
                                                            newPois && this.setState({ pois: [...newPois] }, () => {
                                                                this.props.isModify && newPois && this.props.isModify([...newPois]);
                                                            });
                                                        }}
                                                        onClick={this.props.onClick}
                                                        showPoi={this.showPoi}
                                                        editPoi={this.editPoi}
                                                        deletePoi={this.deletePoi}
                                                        key={"draggable-marker-" + isEditMode + "-" + p.id!}
                                                        keyId={p.id!} initialDraggable={isEditMode}
                                                        positionCenter={latLng(p.latitude, p.longitude)}
                                                        title={p.title} />

                                                )
                                            } else { return null }
                                        })
                                    }
                                    {/* </MarkerClusterGroup> */}
                                </FeatureGroup>
                            </LayersControl.Overlay>
                        </LayersControl>
                    </MapContainer>
                    {
                        this.props.onlyDisplay !== true &&
                        <Drawer
                            destroyOnClose={true}
                            width={this.props.isSmartphone ? '100%' : '450px'}
                            title={poiSelected ? poiSelected.title : intl.formatMessage({ defaultMessage: 'New point of interest' })}
                            placement="right"
                            onClose={() => this.setState({ isDrawerVisible: false, poiSelected: undefined })}
                            visible={this.state.isDrawerVisible}
                            className="__drawer"
                            getContainer={false}
                            style={{ position: 'absolute' }}
                        >
                            {poiSelected && <PoiShowForm poiShow={poiSelected} />}
                        </Drawer>
                    }

                    {
                        this.props.onlyDisplay !== true &&
                        <Drawer
                            destroyOnClose={true}
                            width={this.props.isSmartphone ? '100%' : '450px'}
                            title={poiSelected && poiSelected.id ? poiSelected.title : intl.formatMessage({ defaultMessage: 'New point of interest' })}
                            placement="right"
                            onClose={() => poiSelected && poiSelected.id ? this.setState({ isAddingPOI: false }) : this.setState({ isAddingPOI: false })}
                            visible={this.state.isAddingPOI}
                            className="__drawer"
                            getContainer={false}
                            style={{ position: 'absolute' }}
                            footer={
                                <div style={{ textAlign: 'right', }} >
                                    {
                                        <>
                                            <Button onClick={() => this.setState({ isAddingPOI: false })} style={{ marginRight: 8 }}>
                                                Annuler
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.updatePoi();
                                                }} type="primary"
                                            >
                                                Valider
                                            </Button>
                                        </>
                                    }
                                </div>
                            }
                        >
                            {this.state.poiEdit && <PoiEditForm fromMap={true} poiEdit={this.state.poiEdit} setPoiEdit={(poiEdit) => this.setState({ poiEdit })} />}
                        </Drawer>
                    }
                </>
            </FullScreenContent>
        )
    }
}

interface DraggableProps {
    keyId: number;
    initialDraggable: boolean;
    positionCenter: LatLng;
    title?: string;
    deletePoi: (poi: number) => void;
    editPoi: (poi: number) => void;
    showPoi: (poi: number) => void;
    onClick?: (poi: number) => void;
    deplacePoi: (positionCenter: LatLng) => void;
}

function DraggableMarker(props: DraggableProps) {
    const [position, setPosition] = useState(props.positionCenter);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            click() {
                if (props.onClick !== undefined) {
                    props.onClick(props.keyId);
                } else {
                    props.initialDraggable ?
                        props.editPoi(props.keyId)
                        : props.showPoi(props.keyId)
                }
            },
            dragend() {
                const marker: any = markerRef.current;
                if (marker !== null) {
                    setPosition(marker.getLatLng());
                    props.deplacePoi(marker.getLatLng());

                }
            },
            contextmenu() {
                const marker: any = markerRef.current;
                if (marker !== null) {
                    props.initialDraggable && props.deletePoi(props.keyId);
                }
            },
        }),
        [props],
    );

    return (
        <Marker
            opacity={props.keyId < 0 ? 0.7 : 1}
            icon={props.keyId < 0 ? newMarkerIcon : markerIcon}
            key={"marker-" + props.keyId}
            draggable={props.initialDraggable}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}>
            {
                props.onClick !== undefined ?
                    <Tooltip direction="top" offset={[-1, -41]}>{props.title}</Tooltip>
                    :
                    null
            }
        </Marker>
    );
}

function MapEditMode(props: { addMarker: any }) {
    useMapEvents({
        click: (e) => {
            props.addMarker(e);
        },

    });
    return null;
}

function FullScreenContent(props: { children: any, onlyDisplay: boolean }) {
    const handle = useFullScreenHandle();

    return (
        !isIOS && !props.onlyDisplay ?
            <>
                <Button className="__fullscreen-map-button" onClick={handle.enter}>
                    <FullscreenOutlined />
                </Button>

                <FullScreen className="__fullscreen-map-minimized" handle={handle}>
                    {
                        handle.active &&
                        <Button className="__fullscreen-map-button" onClick={handle.exit}>
                            <FullscreenExitOutlined />
                        </Button>
                    }
                    {props.children}
                </FullScreen>
            </>
            :
            props.children
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

const connector = connect(mapStateToProps)

export default connector(injectIntl(MapLeaflet));
