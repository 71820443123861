import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { CSSProperties } from 'react';

interface IProps {
    id?: string;
    title?: string;
    withoutTooltip?: boolean;
    showTooltip?: boolean;
    icon: React.ReactNode;
    className?: string;
    style?: CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    small?: boolean;
    placement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
    disabled?: boolean;
    loading?: boolean;
    type?: "primary" | "ghost" | "dashed" | "link" | "text" | "default";
}

type Props = IProps & ButtonProps;

interface State {

}

/**
 * Component that represent a circle button
 */
class CircleButton extends React.Component<Props, State> {
    render() {
        const { small, className, placement, title, disabled, type, withoutTooltip, showTooltip, ...props } = this.props;

        const button = (
            <Button
                type={type ? type : "default"}
                className={`${small ? 'circle-button-small' : 'circle-button'} ${className ? className : ''}`}
                // className={`${className ? className : ''}`}
                // size={small ? 'small' : 'middle'}
                title={withoutTooltip || showTooltip !== true ? title : undefined}
                shape="circle"
                disabled={disabled}
                {...props} />
        );

        return (
            disabled || withoutTooltip || showTooltip !== true ?
                button
                :
                <Tooltip
                    overlayClassName="circle-button-tooltip"
                    title={title}
                    placement={placement}>
                    {button}
                </Tooltip>
        );
    }
}

export default CircleButton;