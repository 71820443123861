import { SwapOutlined } from "@ant-design/icons";
import { Col, Popover, Row } from "antd";
import moment from "moment";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdPhotoCamera } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CaseType } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import { UserEventsData } from "../../../utils/objects/cct/userEventsData";
import { ExternalAppOptimizedEvent, OvertimeOptimizedEvent, PlanningEvent, PlanningTemplate } from "../../../utils/types/planningTypes";
import { SimpleEvent } from "../../../utils/types/reportTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { colorIsBright, convertPlanningEventToSimpleEvent, getCaseAndPlural, momentDurationFormat, roundDecimals } from "../../../utils/utils";
import { TimeClockStatus } from "../../workControl/tabs/timeclockControl";
import FAIcon from "../FAIcon";

export enum EventSource {
    PLANNINGSTATISTIC = 1,
    TEMPLATE = 2,
    TIMECONTROL = 3,
}

interface Props {
    event: OvertimeOptimizedEvent | ExternalAppOptimizedEvent | PlanningTemplate | PlanningEvent;
    displayTimes?: boolean;
    displayIcons?: boolean;
    displayNbBreaktimes?: boolean;
    displayOverlay?: boolean;
    source?: EventSource;
    timeClockStatus?: TimeClockStatus;
    style?: CSSProperties;

}
export const Event = (props: Props) => {
    const { event, displayTimes, displayIcons, displayOverlay, source, displayNbBreaktimes } = props;
    const [icons, setIcons] = useState<JSX.Element[] | string[]>([]);

    const company = useSelector((state: ApplicationState) => state.user.company);
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const windowWidth = useSelector((state: ApplicationState) => state.window.width);
    const intl = useIntl();

    useEffect(() => {
        const styles: CSSProperties = {
            lineHeight: '16px'
        };
        const backgroundColor = event.color?.color;
        const color = props.timeClockStatus === TimeClockStatus.FINISHED || props.timeClockStatus === TimeClockStatus.STARTED || (backgroundColor && colorIsBright(backgroundColor)) ? "var(--dark-color)" : "white";
        const breaktimeNbColor = color !== 'white' ? "white" : "var(--dark-color)";
        let iconList = [
            <span style={styles} key={'confirmed'} className='__monthly-planning-cell-icon' ><FaCheckCircle style={{ color: color }} title={intl.formatMessage({ defaultMessage: 'Confirmed event' })} /></span>,
            (((event as PlanningTemplate).breakTimes && (event as PlanningTemplate).breakTimes!.length > 0) ? <span
                style={styles}
                key={'breaktime'}
                className='__monthly-planning-cell-icon'
                title={intl.formatMessage({ defaultMessage: '{breakTimes, plural, one {There is 1 break\nTotal duration: {breakDuration}h} other {There is {breakTimes} breaks\nTotal duration: {breakDuration}}}' }, { breakTimes: (event as PlanningTemplate).breakTimes && (event as PlanningTemplate).breakTimes!.length, breakDuration: roundDecimals(event.totalHoursBreakTime ?? 0) })}
            // title={`Il y a ${(event as PlanningTemplate).breakTimes?.length} pause${(event as PlanningTemplate).breakTimes && (event as PlanningTemplate).breakTimes!.length > 1 ? 's' : ''}\nDurée total: ${roundDecimals(event.totalHoursBreakTime ?? 0)}h`}
            >
                <div style={{ position: 'relative' }}>
                    <FAIcon prefix="fas" name="mug" color={color} />
                    {(displayNbBreaktimes && ((event as PlanningTemplate).breakTimes && (event as PlanningTemplate).breakTimes!.length > 0))
                        ? <span style={{ position: 'absolute', left: '4px', fontSize: '11px', top: '0px', color: breaktimeNbColor }}>{(event as PlanningTemplate).breakTimes && (event as PlanningTemplate).breakTimes?.length}</span>
                        : <></>
                    }
                </div>
            </span> : <></>),
            <span style={styles} key={'multipleDays'} className='__overtime-count'><SwapOutlined style={{ color: color }} title={intl.formatMessage({ defaultMessage: 'Event over 2 days' })} /></span>,
            <span style={styles} key={'images'} className='__monthly-planning-cell-icon' ><MdPhotoCamera style={{ color: color }} title={intl.formatMessage({ defaultMessage: 'Event with images' })} /></span>
        ];
        if (!event.confirmed) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'confirmed');
        }

        if (!(event.totalHoursBreakTime && event.totalHoursBreakTime > 0)) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'breaktime');
        }

        if (!(event.hasImages)) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'images');
        }

        if ((moment(event.startDate).isSame(moment(event.endDate), 'date')) || moment(event.endDate).format('HH:mm:ss') === "00:00:00") {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'multipleDays');
        }
        setIcons(iconList);
    }, [displayNbBreaktimes, event, intl, props.timeClockStatus, source]);


    const RenderCellContent = useCallback(() => {
        const simpleEvent: SimpleEvent = convertPlanningEventToSimpleEvent({
            ...event,
            startDate: moment(event.startDate),
            endDate: moment(event.endDate),
        });
        const eventEffective = moment.duration(UserEventsData.eventDuration(simpleEvent, true, true, undefined, undefined, true), "seconds");
        const eventEffectiveString = momentDurationFormat(eventEffective);
        return (
            <>
                <Popover
                    mouseEnterDelay={0.4}
                    open={displayOverlay ? undefined : false}
                    overlayClassName={windowWidth <= 605 ? 'event-popover' : ''}
                    placement="right"
                    arrowPointAtCenter
                    trigger={isSmartphone ? 'click' : 'hover'}
                    key={`event-popover-${event.id}`}
                    title={
                        <div style={{ lineHeight: source === EventSource.PLANNINGSTATISTIC || source === EventSource.TIMECONTROL ? '25px' : '35px', textAlign: 'center' }}>
                            <p><span style={{ fontWeight: 600 }}>{event.title}</span></p>
                            <p>{`${moment(event.startDate).format(getFormat('DATE'))} - ${moment(event.endDate).format(getFormat('DATE'))}`}</p>
                        </div>
                    }
                    content={
                        <Row gutter={[10, 10]} style={{ width: windowWidth <= 605 ? (windowWidth - 35) : 350 }}>
                            <Col span={12}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Duration'} />{':'}</span></Col>
                            <Col span={12}>{eventEffectiveString}</Col>
                            {event?.userNote && <Col span={12}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'User note'} />{':2'}</span></Col>}
                            {event?.userNote && <Col span={12}>{event?.userNote}</Col>}
                            {event?.poi && <Col span={12}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Workplace'} />{':3'}</span></Col>}
                            {event?.poi && <Col span={12}>{event?.poi?.title}</Col>}

                            {event?.project && <Col span={12}><span style={{ fontWeight: 600 }}>{company?.projectDisplayText ? `${getCaseAndPlural(company.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE)}:` : `${intl.formatMessage({ defaultMessage: 'Projects' })}:5`}</span></Col>}
                            {event?.project && <Col span={12}>{event?.project?.title}</Col>}
                            {event?.staffType && <Col span={12}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Skill'} />{':4'}</span></Col>}
                            {event?.staffType && <Col span={12}>{event?.staffType?.name}</Col>}
                        </Row>
                    }
                >
                    <div className="__event-parent">
                        <div className="__event-details" style={{ lineHeight: source === EventSource.PLANNINGSTATISTIC || source === EventSource.TIMECONTROL ? '16px' : source === EventSource.TEMPLATE ? '18px' : '25px' }} >
                            <span className="__event-title" style={source === EventSource.TIMECONTROL ? { maxWidth: '127px' } : source === EventSource.TEMPLATE ? { maxWidth: '125px' } : {}} title={source === EventSource.TIMECONTROL ? undefined : event.title}>{event.title}</span>
                            {
                                displayTimes ?
                                    <div className="__event-times" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={source === EventSource.TIMECONTROL ? { fontSize: '15px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' } : { fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                            <span>{moment(event.startDate).format(getFormat('TIME_SHORT'))}</span>
                                            <span>-</span>
                                            <span>{props.timeClockStatus === TimeClockStatus.STARTED ? '...' : moment(event.endDate).format(getFormat('TIME_SHORT'))}</span>
                                        </span>
                                    </div>
                                    : null
                            }
                        </div>

                        {
                            displayIcons && icons.length > 0 && icons.length < 4 ?
                                <div className='__monthly-planning-cell-icons' style={{ flexDirection: 'column', display: 'flex' }}>
                                    {icons.map(iconComponent => iconComponent)}
                                </div>
                                : null
                        }
                    </div>
                    {
                        displayIcons && icons.length > 3 ?
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div className='__monthly-planning-cell-icons' style={{ flexDirection: 'row', gap: "4px", display: "flex" }}>
                                    {icons.map(iconComponent => iconComponent)}
                                </div>

                            </div>
                            : null
                    }
                </Popover >
            </>
        );
    }, [event, displayOverlay, windowWidth, isSmartphone, source, company, intl, displayTimes, props.timeClockStatus, displayIcons, icons]);



    return (
        <div
            className='timeclock-control-table-event-cell'
            style={{
                opacity: props.timeClockStatus === TimeClockStatus.FINISHED ? 0.6 : props.timeClockStatus === TimeClockStatus.STARTED ? 0.6 : 1,
                width: source === EventSource.PLANNINGSTATISTIC || source === EventSource.TIMECONTROL ? '100%' : '90%',
                marginLeft: source === EventSource.PLANNINGSTATISTIC || source === EventSource.TIMECONTROL ? 0 : '5%',
                background: props.timeClockStatus === TimeClockStatus.FINISHED || props.timeClockStatus === TimeClockStatus.STARTED ?
                    'white'
                    : event.color?.color ?? 'var(--primary-color)',
                borderColor: props.timeClockStatus === TimeClockStatus.FINISHED || props.timeClockStatus === TimeClockStatus.STARTED ?
                    '#252729'
                    : event.color?.color ?? 'var(--primary-color)',
                borderStyle: props.timeClockStatus === TimeClockStatus.FINISHED || props.timeClockStatus === TimeClockStatus.STARTED ?
                    'solid'
                    : undefined,
                color: props.timeClockStatus === TimeClockStatus.FINISHED || props.timeClockStatus === TimeClockStatus.STARTED ?
                    'black'
                    : event.color?.color && colorIsBright(event.color.color) ? "var(--dark-color)" : "white",
                ...props.style
            }}
        >
            <RenderCellContent key={`event-cell-${event.id}`} />
        </div >
    );
};
export default Event;