import React, { ReactNode } from 'react';
import { RouterProps } from '../../utils/types/generalTypes';

interface IProps {
    children: ReactNode;
}

type Props = IProps & RouterProps;

/**
 * 
 */
class AddLanguage extends React.Component<Props> {

    componentDidMount() {
        //check if there is a language, if not add french as default
        const lang = this.props.match.params.lang;
        if (!lang) {
            const path = this.props.location.pathname + this.props.location.search;
            const final = '/fr' + path;
            this.props.history.push(final);
            window.location.reload();
        }
    }

    render() {
        return null;
    }
}

export default AddLanguage;