import React from 'react';
// import TimePicker from 'react-time-picker'

import { Button, Modal, Row, Steps } from 'antd';
import { ConnectedProps, connect } from 'react-redux';
import { SelectedEventV2 } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { showNotification } from '../../../../utils/utils';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps {
    open: boolean;
    selectedEvents: SelectedEventV2[]
}

interface State {
    current: number;
}

/**
 * Component that represent a delete circle button with pop confirm
 */
class ModalDuplicateEvents extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            current: 0,
        }
    }

    next = () => {
        this.setState(prevState => ({ current: prevState.current + 1 > 2 ? 2 : prevState.current + 1 }));
    }

    prev = () => {
        this.setState(prevState => ({ current: prevState.current - 1 < 0 ? 0 : prevState.current - 1 }));
    }

    renderUser = () => {
        const { selectedEvents } = this.props;
        return (
            <Row gutter={[10, 10]} style={{ width: '100%' }}>
                {
                    selectedEvents?.map(selectedCell => {
                        return (
                            <p key={`key-${selectedCell.userId}-${selectedCell.eventId}`}>YEAH</p>
                            // <Col xs={{ span: 24 }} key={`selectedCellsParsedByUser-duplicate-user-${selectedCell.name}`}>
                            //     <b>{selectedCell.name}</b>
                            //     <ul>
                            //         {
                            //             selectedCell.events.map(e => <li key={`selectedCellsParsedByUser-duplicate-user-${selectedCell.name}-event-${e.title}-${e.startDate?.valueOf()}-${e.endDate?.valueOf()}`}>{e.title} {e.startDate ? `(${e.startDate.format("DD/MM/YYYY HH:mm")} > ${e.endDate ? e.endDate.format("DD/MM/YYYY HH:mm") : ""})` : ""}</li>)
                            //         }
                            //     </ul>
                            // </Col>
                        );
                    })
                }
            </Row>
        )
    }

    renderDay = () => {
        return (
            <p>Day</p>
        )
    }

    renderDuplicate = () => {
        return (
            <p>Duplicate</p>
        )
    }

    render() {
        const { current } = this.state;
        const { open } = this.props;

        const steps = [
            {
                title: "Utilisateurs",
                content: this.renderUser(),
            },
            {
                title: 'Dates',
                content: this.renderDay(),
            },
            {
                title: 'Dupliquer',
                content: this.renderDuplicate(),
            },
        ];
        const items = steps.map((item) => ({ key: item.title, title: item.title }));

        return (
            <Modal
                title="Dupliquer des événements"
                width={700}
                destroyOnClose={true}
                open={open}
                footer={[
                    <div key={`modal-duplicate-events-steps`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {current > 0 && (
                            <Button type="dashed" onClick={() => this.prev()}>
                                Previous
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => showNotification('Processing complete!', "succes")}>
                                Done
                            </Button>
                        )}
                    </div>
                ]}>
                <Steps current={current} items={items} />
                <div style={{ marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{steps[current].content}</div>
                <div style={{ marginTop: 24 }}>

                </div>
            </Modal >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps);

export default connector(ModalDuplicateEvents);