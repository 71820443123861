import { Switch, SwitchProps } from 'antd';
import React, { CSSProperties } from 'react';

interface IProps {
    label: string;
    checked: boolean;
    style?: CSSProperties;
    switchStyle?: CSSProperties;
    onChange?: (checked: boolean) => void;
}

type Props = IProps & SwitchProps;

interface State {

}

/**
 * Component that represent a circle button
 */
class SwitchLabeled extends React.Component<Props, State> {
    render() {
        const { label, checked, style, onChange } = this.props;
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', ...style }}>
                <Switch {...this.props} style={this.props.switchStyle} onChange={onChange} checked={checked} />
                <p onClick={() => onChange && onChange(!checked)} style={{ cursor: 'pointer' }}>{label}</p>
            </div>
        )
    }
}

export default SwitchLabeled;