import React from 'react';

interface Props {
    children: React.ReactNode;
}

/**
 * Component that represent a card
 */
class SimpleBloc extends React.Component<Props> {
    render() {
        return (
            <div style={{ borderRadius: 'var(--border-radius)', padding: '10px', backgroundColor: 'var(--background-color)' }}>
                {this.props.children}
            </div>
        );
    }
}
export default SimpleBloc;