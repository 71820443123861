import { Marker, icon } from 'leaflet';
import { GeoSearchControl, GoogleProvider, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/assets/css/leaflet.css';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-leaflet';

import { useIntl } from 'react-intl';
import MarkerAddIcon from '../../../images/marker-add-icon.png';

interface MapSearchFieldProps {
    addMarker: (e: any, title?: string, address?: string) => void;
    googleMapsApiKey?: string;
}

const MapSearchField: React.FC<MapSearchFieldProps> = ({ addMarker, googleMapsApiKey }) => {
    const intl = useIntl();
    const provider = useMemo((): GoogleProvider | OpenStreetMapProvider => {
        if (googleMapsApiKey && googleMapsApiKey.length > 0) {
            return new GoogleProvider({
                apiKey: googleMapsApiKey,
                language: "fr",
                region: 'CH'
            });
        } else {
            return new OpenStreetMapProvider({
                params: {
                    'accept-language': 'fr', // render results in Dutch
                    countrycodes: 'ch', // limit search results to the Netherlands
                    addressdetails: 0, // include additional address detail parts
                },
            });
        }
    }, [googleMapsApiKey])


    const searchControl = useMemo(() => new (GeoSearchControl as any)({
        notFoundMessage: intl.formatMessage({ defaultMessage: 'Sorry, this address could not be found' }),
        searchLabel: intl.formatMessage({ defaultMessage: 'Search for an address' }),
        provider: provider,
        style: 'bar',
        showMarker: false
    }), [intl, provider])

    const customIcon = icon({
        iconUrl: MarkerAddIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });
    const map = useMap();

    useEffect(() => {
        map.addControl(searchControl);

        map.on('geosearch/showlocation', (result: any) => {
            const latLng = {
                lat: result.location.y,
                lng: result.location.x,

            };
            const marker = new Marker(latLng, {
                icon: customIcon,
                draggable: true,
                title: result.location.label
            });

            marker.addTo(map);

            marker.on('click', (e) => {
                e.originalEvent.preventDefault();
                e.originalEvent.stopImmediatePropagation();
                addMarker(e, result.location.label, result.location.label);
                marker.remove();
            });
        });

        return () => {
            map.removeControl(searchControl);
        };
    }, [addMarker, customIcon, map, searchControl]);

    return null;
};


export default MapSearchField;