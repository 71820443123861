/**
 * Constants containing the privileges
 */
export const Privileges = {
    Report: {
        Visit: "planning:visit",
    },
    Dashboard:{
        Display: "dashboard:display"
    },
    Planning: {
        Visit: "planning:visit",
        Edition: "planning:edition",
        readOnlyAllPlanning: "planning:readOnlyAllPlanning"
    },
    CRM: {
        Visit: "crm:visit",
    },
    SMS: {
        Visit: "sms:visit",
    }
}

/**
 * Constants containing the privileges
 */
export const CompanyDetailsPrivilege = {
    TimeClock: {
        Visit: 'time-clock:visit',
        Edition: 'time-clock:edition'
    }
}

/**
 * The application privileges
 */
const privileges = [
    "planning:visit", //only for non ext companies
    "planning:edition", //only for normal user with planning editing (for himself only) privilege
    "planning:readOnlyAllPlanning", //only for normal user that can see all the planning (read only)
    "crm:visit", //only for ext companies
    "sms:visit", //only for ext companies or company with these rights
    "dashboard:display"
]


export default privileges;