import { ArrowLeftOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';
import { BsReverseLayoutTextSidebarReverse } from 'react-icons/bs';
import Anticon from '../common/general/anticon';
import Container from '../common/navigations/container';
//import MonthlyPlanning from './monthly/monthlyPlanning';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdAppSettingsAlt, MdOutlineEmergency } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Network from '../../utils/network';
import { MobileAppSettings, MobileAppTheme } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import Emergencies from './tabs/emergencies';
import Overview from './tabs/overview';
import DirectoryListing from './tabs/sidePanel';
import CategoryDetails from './tabs/sidePanel/categoryDetails';


export const MobileApp = () => {
    const history = useHistory();
    const { lang, tab, id } = useParams<{ tab?: string, lang?: string, id?: string; }>();
    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone);
    const [mobileAppSettings, setMobileAppSettings] = useState<MobileAppSettings | undefined>(undefined);
    const [theme, setTheme] = useState<MobileAppTheme | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const intl = useIntl();

    const goTo = useCallback((link: string): void => history.push(`/${lang}/${link}`), [history, lang]);

    const onChangeTab = useCallback((tabStr: string): void => {
        goTo('mobile-app/' + tabStr);
    }, [goTo]);

    const getCurrentTab = useCallback(() => {

        switch (tab) {
            case 'overview':
            case 'emergencies':
            case 'side-panel':
                return tab;
            default:
                goTo('mobile-app/overview');
        }
        goTo('mobile-app/overview');
    }, [tab, goTo]);


    const loadMobileOverview = useCallback(() => {
        setLoading(true);
        Network.getMobileOverview().then(
            (response: { error: boolean, message: string, data: { mobileAppSettings: MobileAppSettings, theme: MobileAppTheme; }; }) => {
                if (response.error === false) {
                    setMobileAppSettings({ ...response.data.mobileAppSettings, type: 'MobileAppSettings' });
                    setTheme({ ...response.data.theme, type: 'MobileAppTheme' });

                } else {
                    showNotification(response.message, "warning");
                }
                setLoading(false);


            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the mobile settings' }), "warning");
                setLoading(false);
            }
        );
    }, [intl]);

    const updateMobileOverview = useCallback((data: { mobileAppSettings?: MobileAppSettings, theme?: MobileAppTheme; }) => {
        if (!loading) {
            setLoading(true);
            Network.updateMobileOverview(data).then(
                (response: { error: boolean, message: string, data: { mobileAppSettings: MobileAppSettings, theme: MobileAppTheme; }; }) => {
                    if (response.error === false) {
                        setMobileAppSettings({ ...response.data.mobileAppSettings, type: 'MobileAppSettings' });
                        setTheme({ ...response.data.theme, type: 'MobileAppTheme' });
                    } else {
                        showNotification(response.message, "warning");
                    }
                    setLoading(false);
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the mobile settings' }), "warning");
                    setLoading(false);
                }
            );
        }

    }, [intl, loading]);
    const onUpdateMobileAppSettings = useCallback((data: MobileAppSettings) => {
        const mobileOverview: { mobileAppSettings: MobileAppSettings, theme?: MobileAppTheme; } = {
            mobileAppSettings: data
        };
        updateMobileOverview(mobileOverview);
    }, [updateMobileOverview]);
    const onUpdateTheme = useCallback((data: MobileAppTheme) => {
        const mobileOverview: { mobileAppSettings?: MobileAppSettings, theme: MobileAppTheme; } = {
            theme: data
        };
        updateMobileOverview(mobileOverview);
    }, [updateMobileOverview]);


    useEffect(() => {
        loadMobileOverview();
    }, [loadMobileOverview]);


    const items = useMemo(() => [
        {
            label: (
                <span>
                    <Anticon icon={<MdAppSettingsAlt />} />
                    <FormattedMessage defaultMessage={'Overview'} />
                </span>
            ),
            key: 'overview',
            children: <Spin spinning={mobileAppSettings === undefined || theme === undefined}>
                {(mobileAppSettings !== undefined && theme !== undefined) && <Overview loading={loading} mobileAppSettings={mobileAppSettings!} theme={theme!} onUpdate={(data) => (data.type === 'MobileAppSettings') ? onUpdateMobileAppSettings(data) : onUpdateTheme(data)} />}
            </Spin>
        },
        {
            label: (
                <span>
                    <Anticon icon={<BsReverseLayoutTextSidebarReverse />} />
                    <FormattedMessage defaultMessage={'Side panel'} />
                </span>
            ),
            key: 'side-panel',
            children: id != null ? <CategoryDetails /> : <DirectoryListing />
        },
        {
            label: (
                <span>
                    <MdOutlineEmergency className='anticon' />
                    <FormattedMessage defaultMessage={'Emergencies'} />
                </span>
            ),
            key: 'emergencies',
            children: <Spin spinning={mobileAppSettings === undefined || theme === undefined}>
                {(mobileAppSettings !== undefined && theme !== undefined) && <Emergencies loading={loading} mobileAppSettings={mobileAppSettings!} theme={theme!} />}
            </Spin>
        },

    ], [id, mobileAppSettings, theme, loading, onUpdateMobileAppSettings, onUpdateTheme]);


    return (
        <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Mobile app' }), link: "/mobile-app" }]}>
            <Tabs animated
                tabBarExtraContent={(isSmartphone || id == null) ? undefined :
                    {

                        left: (
                            <div style={{ marginRight: '10px' }}>
                                <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => goTo('mobile-app/side-panel')} />
                            </div>
                        )

                    }
                }
                activeKey={getCurrentTab()}
                onChange={onChangeTab}
                items={items}
            />
        </Container>
    );
};

export default MobileApp;
