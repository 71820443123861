import { ApartmentOutlined, CloseOutlined, DashOutlined, EditOutlined, FolderOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../../rbacRules';
import { IconPacks } from '../../../../utils/fontawesome/IconPacks';
import Network from '../../../../utils/network';
import { ListingCategory, ListingSubCategory, RouterProps } from '../../../../utils/types/generalTypes';
import { ListingCategoryBody, ListingSubCategoryBody } from '../../../../utils/types/networkTypes';
import { showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FAIcon from '../../../common/FAIcon';
import FontAwesomePicker from '../../../common/fields/FontAwesomePicker/FontAwesomePicker';
import CircleButton from '../../../common/fields/circleButton';
import DeleteButton from '../../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../../common/fields/inputField';
import SpeedDial from '../../../common/fields/speedDial';
import Can from '../../../common/general/can';
import Card from '../../../common/general/card';

//different data type of a listing item body
enum DataType {
    Name, Link, External, Image, Sort, Icon
}

type AllCategory = ListingCategory | ListingSubCategory | ListingSubCategoryBody;

type Props = RouterProps & IntlProps;

interface State {
    categoryId: number;
    category: ListingCategory | undefined;
    subCategories: ListingSubCategory[] | undefined;
    editCategory: ListingCategory | undefined;
    editSubCategory: ListingSubCategory | undefined;
    createSubCategory: ListingSubCategoryBody | undefined;
    loading: boolean;

}

class CategoryDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            categoryId: Number(this.props.match.params.id),
            category: undefined,
            subCategories: undefined,
            editCategory: undefined,
            editSubCategory: undefined,
            createSubCategory: undefined,
            loading: false,
        };
    }

    componentDidMount() {
        //get category details
        this.refreshCategory();

        //get category's sub categories
        this.refreshSubCategories();
    }

    /**
     * Refresh category
     * @param message a message to display as a success message - optional
     */
    refreshCategory = (message?: string) => {
        Network.getListingCategories(this.state.categoryId).then(
            response => {
                console.log("Received categories", response);
                this.setState({ category: response, editCategory: undefined });
                if (message) showNotification(message, "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the categories' }), "warning")
        );
    };

    /**
     * Refresh category's sub categories
     * @param message a message to display as a success message - optional
     */
    refreshSubCategories = (message?: string) => {
        Network.getListingSubCategories(this.state.categoryId).then(
            response => {
                this.setState({ subCategories: response, editSubCategory: undefined, createSubCategory: undefined });
                if (message) showNotification(message, "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the subcategories' }), "warning")
        );
    };

    /**
     * Update the data of a category
     * @param category the category to update
     * @param type the type of data that have been changed
     * @param value the new value
     * @returns the updated category
     */
    dataChanged = (category: AllCategory, type: DataType, value: any): AllCategory => {
        switch (type) {
            case DataType.Name:
                category!.name = value.target.value;
                break;
            case DataType.Link:
                category!.link = value.target.value;
                break;
            case DataType.External:
                category!.external = value.target.checked;
                break;
            case DataType.Image:
                category!.image = value;
                break;
            case DataType.Sort:
                category!.sort_order = value;
                break;
            case DataType.Icon:
                (category as ListingCategory).icon = value;
                break;
        }
        return category;
    };

    /**
     * Called when the user changed a value for the category
     * @param type the type of data that have been changed
     * @param value the new value
     */
    categoryDataChanged = (type: DataType, value: any) => this.setState({ editCategory: this.dataChanged(this.state.editCategory!, type, value) as ListingCategory });

    /**
     * Called when the user changed a value for a sub category
     * @param type the type of data that have been changed
     * @param value the new value
     */
    subCategoryDataChanged = (type: DataType, value: any) => this.setState({ editSubCategory: this.dataChanged(this.state.editSubCategory!, type, value) as ListingSubCategory });

    /**
     * Called when the user changed a value for the new sub category
     * @param type the type of data that have been changed
     * @param value the new value
     */
    createSubCategoryDataChanged = (type: DataType, value: any) => this.setState({ createSubCategory: this.dataChanged(this.state.createSubCategory!, type, value) as ListingSubCategoryBody });

    /**
     * Delete the category
     */
    deleteCategory = () => {
        Network.deleteListingCategory(this.state.categoryId).then(
            () => this.props.history.push({ pathname: `/${this.props.match.params.lang}/directory-listing`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The category has been successfully deleted' }) } }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the category' }), "warning"),
        );
    };

    /**
     * Delete a sub category
     * @param id the id of the sub category to delete
     */
    deleteSubCategory = (id: number): void => {
        Network.deleteListingSubCategory(id).then(
            () => this.refreshSubCategories(this.props.intl.formatMessage({ defaultMessage: 'The subcategory has been successfully deleted' })),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the subcategory' }), "warning"),
        );
    };

    /**
     * Update the category
     */
    updateCategory = (): void => {
        this.setState({ loading: true });
        const { editCategory } = this.state;
        const body: ListingCategoryBody = {
            id: editCategory?.id,
            name: editCategory?.name,
            image: editCategory?.image,
            link: editCategory?.link,
            external: editCategory?.external,
            sort_order: editCategory?.sort_order,
            icon: editCategory?.icon
        };

        //update category
        Network.editListingCategory(body).then(
            () => {
                this.setState({ loading: false });
                this.refreshCategory(this.props.intl.formatMessage({ defaultMessage: 'The category has been successfully updated' }));
            },
            () => {
                this.setState({ loading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the category' }), "warning");
            },
        );
    };

    /**
     * Update a sub category
     */
    updateSubCategory = (): void => {
        this.setState({ loading: true });
        const { editSubCategory } = this.state;
        const body: ListingSubCategoryBody = {
            id: editSubCategory?.id,
            category_id: this.state.category?.id,
            name: editSubCategory?.name,
            image: editSubCategory?.image,
            link: editSubCategory?.link,
            external: editSubCategory?.external,
            sort_order: editSubCategory?.sort_order,
        };

        //update category
        Network.editListingCategory(body).then(
            () => {
                this.setState({ loading: false });
                this.refreshSubCategories(this.props.intl.formatMessage({ defaultMessage: 'The subcategory has been successfully updated' }));
            },
            () => {
                this.setState({ loading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the subcategory' }), "warning");
            },
        );
    };

    /**
     * Create a sub category
     */
    createSubCategory = (): void => {
        this.setState({ loading: true });
        const { createSubCategory } = this.state;
        const body: ListingSubCategoryBody = {
            id: createSubCategory?.id,
            category_id: this.state.category?.id,
            name: createSubCategory?.name,
            image: createSubCategory?.image,
            link: createSubCategory?.link,
            external: createSubCategory?.external,
            sort_order: createSubCategory?.sort_order,
        };

        //update category
        Network.editListingCategory(body).then(
            () => {
                this.setState({ loading: false });
                this.refreshSubCategories(this.props.intl.formatMessage({ defaultMessage: 'The subcategory has been successfully created' }));
            },
            () => {
                this.setState({ loading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the subcategory' }), "warning");
            },
        );
    };

    /**
     * Render a category and sub category card content
     * @param category the category to render
     * @param editCategory the category to render for editing version
     * @param dataChanged the method to call on data changes
     * @returns the content to render
     */
    renderCategoryContent = (category: AllCategory, editCategory: AllCategory | undefined, dataChanged: (type: DataType, value: any) => void, onSave: () => void): React.ReactNode => {
        const { intl } = this.props;
        if (editCategory) {
            return (
                <div>
                    <span className="listing-category-section">
                        <p><FormattedMessage defaultMessage={'Name'} />{':'}</p>
                        <InputField
                            className="listing-category-link"
                            value={editCategory?.name}
                            placeholder={intl.formatMessage({ defaultMessage: 'Name' })}
                            onChange={(e: InputFieldOnChangeEvent) => dataChanged(DataType.Name, e)} />
                    </span>
                    <span className="listing-category-section">
                        <p><FormattedMessage defaultMessage={'Link'} />{':'}</p>
                        <InputField
                            className="listing-category-link"
                            value={editCategory?.link}
                            placeholder={intl.formatMessage({ defaultMessage: 'Link' })}
                            onChange={(e: InputFieldOnChangeEvent) => dataChanged(DataType.Link, e)} />
                    </span>
                    <span className="listing-category-section">
                        <p><FormattedMessage defaultMessage={'Sort order'} />{':'}</p>
                        <InputNumber
                            className="listing-category-link"
                            value={editCategory?.sort_order}
                            placeholder={intl.formatMessage({ defaultMessage: 'Sort' })}
                            onChange={(e) => dataChanged(DataType.Sort, e)} />
                    </span>
                    <Checkbox
                        className="listing-category-section"
                        style={{ width: 'fit-content' }}
                        checked={editCategory?.external}
                        onChange={(e) => dataChanged(DataType.External, e)}>
                        <FormattedMessage defaultMessage={'External link'} />
                    </Checkbox>
                    <span className="listing-category-section">
                        <p style={{ marginRight: '5px' }}><FormattedMessage defaultMessage={'Icon'} />{':'}</p>
                        <FontAwesomePicker pack={IconPacks.Documents} selectedIcon={(editCategory as typeof this.state.editCategory)?.icon ?? ''} setSelectedIcon={(icon) => dataChanged(DataType.Icon, icon)} mode='POPOVER' />
                    </span>
                    <div className="flex-center">
                        <Button
                            className="listing-category-save-button"
                            onClick={onSave}
                            type="primary"
                            loading={this.state.loading}>
                            <FormattedMessage defaultMessage={'Save'} />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    {category?.link &&
                        <span className="listing-category-section">
                            <p><FormattedMessage defaultMessage={'Link'} />{':'}</p>
                            <p className="listing-category-link">{category?.link}</p>
                        </span>
                    }
                    <Checkbox
                        className="listing-category-section checkbox-readonly"
                        checked={category?.external}>
                        <FormattedMessage defaultMessage={'External link'} />
                    </Checkbox>
                    <span className="listing-category-section">
                        <p style={{ marginRight: '5px' }}><FormattedMessage defaultMessage={'Icon'} />{':'}</p>
                        <FontAwesomePicker disabled pack={IconPacks.Documents} selectedIcon={(category as typeof this.state.editCategory)?.icon ?? ''} setSelectedIcon={(icon) => dataChanged(DataType.Icon, icon)} />
                    </span>
                </div>
            );
        }
    };

    /**
     * Render the categories cards
     * @returns the content to render
     */
    renderSubCategories = (): React.ReactNode => {
        const { intl } = this.props;
        const { subCategories, editSubCategory, createSubCategory } = this.state;

        return (
            <div className="listing-card">
                {createSubCategory &&
                    <Card
                        className="listing-category-subcategory-card"
                        icon={<ApartmentOutlined />}
                        title={createSubCategory.name ? createSubCategory.name : <FormattedMessage defaultMessage={'New submenu'} />}>
                        {this.renderCategoryContent(createSubCategory, createSubCategory, this.createSubCategoryDataChanged, this.createSubCategory)}
                    </Card>
                }
                {
                    subCategories?.map(sub => {
                        const isEditing: boolean = editSubCategory !== undefined && sub.id === editSubCategory.id;
                        return (
                            <Card
                                key={`listing-subcategory-card-${sub.id}`}
                                className="listing-category-subcategory-card"
                                icon={<ApartmentOutlined />}
                                title={sub.name}
                                headerElements={[
                                    <SpeedDial
                                        key={`listing-subcategory-speed-dial-${sub.id}`}
                                        title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                        icon={<MoreOutlined />}
                                        openIcon={<DashOutlined />}
                                        buttons={[
                                            <CircleButton
                                                key={`listing-subcategory-edit-button-${sub.id}`}
                                                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                                                title={isEditing ? intl.formatMessage({ defaultMessage: 'Cancel' }) : intl.formatMessage({ defaultMessage: 'Edit' })}
                                                disabled={editSubCategory && !isEditing}
                                                onClick={() => this.setState({ editSubCategory: isEditing ? undefined : Object.assign({}, sub) })} />,
                                            <DeleteButton
                                                key={`listing-subcategory-delete-button-${sub.id}`}
                                                text={<FormattedMessage defaultMessage={'Do you want to delete this submenu?'} />}
                                                okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                                                cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                                                onConfirm={() => this.deleteSubCategory(sub.id)} />,
                                        ]} />
                                ]}>
                                {this.renderCategoryContent(sub, isEditing ? editSubCategory : undefined, this.subCategoryDataChanged, this.updateSubCategory)}
                            </Card>
                        );
                    })
                }
            </div>
        );
    };

    render() {
        const { category, editCategory, createSubCategory } = this.state;
        const { intl } = this.props;

        return (
            <Can rule={Rules.DirectoryListing.Visit}>
                <div className="listing-container">
                    <Card
                        className="listing-card"
                        icon={category?.icon ? <FAIcon pack={IconPacks.Documents} prefix={category.icon.split(" ")[0]} name={category.icon.split(' ')[1]} /> : <FolderOutlined />}
                        title={category ? category.name : <FormattedMessage defaultMessage={'Menu'} />}
                        headerElements={[
                            <SpeedDial
                                key={"listing-category-speed-dial"}
                                title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                icon={<MoreOutlined />}
                                openIcon={<DashOutlined />}
                                buttons={[
                                    <CircleButton
                                        key={'listing-category-add-button'}
                                        icon={createSubCategory ? <CloseOutlined /> : <PlusOutlined />}
                                        title={createSubCategory ? intl.formatMessage({ defaultMessage: 'Cancel submenu creation' }) : intl.formatMessage({ defaultMessage: 'Add a submenu' })}
                                        onClick={() => this.setState({ createSubCategory: createSubCategory ? undefined : {} })} />,
                                    <CircleButton
                                        key={"listing-category-edit-button"}
                                        icon={editCategory ? <CloseOutlined /> : <EditOutlined />}
                                        title={editCategory ? intl.formatMessage({ defaultMessage: 'Cancel' }) : intl.formatMessage({ defaultMessage: 'Edit' })}
                                        onClick={() => this.setState({ editCategory: editCategory ? undefined : Object.assign({}, category) })} />,
                                    <DeleteButton
                                        key={"listing-category-delete-button"}
                                        text={<FormattedMessage defaultMessage={'Do you want to delete this menu and all its submenus?'} />}
                                        okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                                        cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                                        onConfirm={this.deleteCategory} />,
                                ]} />
                        ]}>
                        {category && this.renderCategoryContent(category, editCategory, this.categoryDataChanged, this.updateCategory)}
                    </Card>
                    {this.renderSubCategories()}
                </div>
            </Can>
        );
    }
}

export default withRouter(injectIntl(CategoryDetails));