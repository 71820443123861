import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React, { CSSProperties } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps } from '../../app/LanguageProvider';
import Anticon from '../general/anticon';
import CircleButton from './circleButton';

interface Props extends IntlProps {
    text: React.ReactNode;
    title?: string;
    okText?: string;
    cancelText?: string;
    placement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
    buttonPlacement?: "top" | "left" | "right" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
    small?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    onConfirm: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
    withoutTooltip?: boolean;
    primary?: boolean;
}

interface State {

}

/**
 * Component that represent a delete circle button with pop confirm
 */
class DeleteButton extends React.Component<Props, State> {
    render() {
        return (
            <Popconfirm
                title={this.props.text}
                okText={this.props.okText ?? <FormattedMessage defaultMessage={'Delete'} />}
                cancelText={this.props.cancelText ?? <FormattedMessage defaultMessage={'Cancel'} />}
                okButtonProps={{ type: 'link', danger: true }}
                cancelButtonProps={{ type: 'link' }}
                icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                placement={this.props.placement}
                onConfirm={this.props.onConfirm}
                onCancel={(e) => e?.stopPropagation()}>
                <CircleButton
                    type={this.props.primary ? 'primary' : undefined}
                    withoutTooltip={this.props.withoutTooltip}
                    icon={<Anticon><RiDeleteBinLine /></Anticon>}
                    title={this.props.title ?? this.props.intl.formatMessage({ defaultMessage: 'Delete' })}
                    small={this.props.small}
                    style={this.props.style}
                    onClick={e => e.stopPropagation()}
                    placement={this.props.buttonPlacement}
                    disabled={this.props.disabled} />
            </Popconfirm>
        );
    }
}

export default injectIntl(DeleteButton);