import { LocalizationMap, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Privileges } from '../../privileges';
import { CompanyTypes } from '../../utils/constants';
import { Company, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { checkPrivilege } from '../../utils/utils';


interface IProps {
    isSmartphone: boolean;
    company?: Company;
    currentUser?: User;
}

type Props = IProps & RouterProps;

interface State { }

class Documentation extends React.Component<Props, State> {
    render() {
        const { isSmartphone, company, currentUser } = this.props;
        return (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <ViewerWithPlugins isSmartphone={isSmartphone} company={company} currentUser={currentUser} />
            </Worker>
        );
    }
}

function ViewerWithPlugins(props: { isSmartphone: boolean, company?: Company, currentUser?: User }) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    let url = "";

    if (props.company?.type === CompanyTypes.CCNT) {
        url = "https://storage.googleapis.com/snowpros/doc/doc_algo_ccnt.pdf";
    } else if (props.company?.type === CompanyTypes.SECURITY) {
        url = "https://storage.googleapis.com/snowpros/doc/doc_algo_cct_security.pdf";
    } else if (checkPrivilege(Privileges.CRM.Visit, props.currentUser)) {
        url = props.isSmartphone ? "https://storage.googleapis.com/snowpros/doc/Documentation_min.pdf" : "https://storage.googleapis.com/snowpros/doc/Documentation.pdf";
    }

    return (
        <Viewer
            defaultScale={props.isSmartphone ? SpecialZoomLevel.PageWidth : 1.7}
            localization={fr_FR as unknown as LocalizationMap}
            plugins={[defaultLayoutPluginInstance]}
            fileUrl={url + `?time=${moment().valueOf()}`} />
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(Documentation));