import { RRule } from 'rrule';
import { IEventFrequences, IEventWeekdays } from './types/planningTypes';


/**
 * Enumeration for the different data type of an event
 */
export enum EventDataType {
    Title,
    Owner,
    UserId,
    GroupId,
    Range,
    Range1,
    IsDraggable,
    Interval,
    Frequence,
    Weekdays,
    Starting,
    Finish,
    Until,
    Count,
    IsGlobal,
    AsTemplate,
    Color,
    Confirmed,
    SendMessage,
    TypeOfDay,
    TypeOfDayOff,
    Poi,
    StaffType,
    Project,
    Description,
    isPaid,
    
    BasePoi,
    Vehicle,
    Distance,
    Department,
    IgnoreTimeClock

}

/**
 * Enumeration for the different rrule frequences for an event
 */
export const EventFrequences: IEventFrequences = Object.freeze({ Hourly: RRule.HOURLY, Daily: RRule.DAILY,  Weekly: RRule.WEEKLY, Monthly: RRule.MONTHLY, Yearly: RRule.YEARLY});

/**
 * Enumeration for the different rrule weekday for an event
 */
export const EventWeekdays: IEventWeekdays = Object.freeze({Monday: RRule.MO.weekday, Tuesday: RRule.TU.weekday, Wednesday: RRule.WE.weekday, Thursday: RRule.TH.weekday, Friday: RRule.FR.weekday, Saturday: RRule.SA.weekday, Sunday: RRule.SU.weekday});

/**
 * Enumeration for the type of calendar data request
 */
export enum CalendarDataType {
    All = 0,
    Users = 1,
    Group = 2,
    Planned = 3,
    Available = 4,
}

/**
 * Enumeration for confirmation state for a calendar data request
 */
export enum CalendarDataConfirmationState {
    All = 0,
    Confirmed = 1,
    NotConfirmed = 2,
}

/**
 * Enumeration for the different event owner
 */
export enum PlanningEventOwner {
    User, Group, Global,
}

/**
 * Enumeration for occupancy rate fequency
 */
export enum OccupancyRateFrequency {
    Daily = 0, Weekly = 1, Monthly = 2, Yearly = 3,
}