import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Row, Select, Switch, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAllCodesDiscount } from '../../../actions/products';
import Network from '../../../utils/network';
import { BCSchoolClient, CodeDiscount, Company, CompanyProduct, Country, Language, Medal } from '../../../utils/types/generalTypes';
import { LoadClientBodyRequest, PromotionTranslationBodyRequest } from '../../../utils/types/networkTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import MailPreview from './mailPreview';


import { FormattedMessage, injectIntl } from 'react-intl';
import deUrl from '../../../../src/images/lang/de.webp';
import enUrl from '../../../../src/images/lang/en.webp';
import frUrl from '../../../../src/images/lang/fr.webp';
import itUrl from '../../../../src/images/lang/it.webp';
import getFormat from '../../../utils/Lang';
import { IntlProps } from '../../app/LanguageProvider';

enum DataType { CodesDiscount, Range, MedalId, ProductIds, ActivityIds, LanguageIds, CountryIds }

interface Props extends IntlProps {
    currentStep: number;
    create: boolean;
    setNextClients?: (value: boolean) => void;
    setNextMail?: (value: boolean) => void;
    finishCreatePromotion?: () => void;
    cancelCreate?: () => void;

    companyCodeDiscounts?: CodeDiscount[];
    loadingCompanyCodeDiscounts: boolean;
    companyProducts: CompanyProduct[];
    loadingCompanyProducts: boolean;
    medals: Medal[];
    countries: Country[];
    languages?: Language[];
    companyDetails?: Company;
    dispatch: StoreDispatch;
    name?: string;
}

interface State {
    clients?: BCSchoolClient[];
    selectedClients?: number[];
    selectedCodeDiscount?: CodeDiscount;
    selectedProducts?: CompanyProduct[];
    loadClientBodyRequest: LoadClientBodyRequest;
    loadingClients: boolean;
    isFilterModified: boolean;

    language: string,
    titleMailFr: string;
    contentMailFr: string;
    enableLanguageFr: boolean;
    titleMailEn: string;
    contentMailEn: string;
    enableLanguageEn: boolean;
    titleMailIt: string;
    contentMailIt: string;
    enableLanguageIt: boolean;
    titleMailDe: string;
    contentMailDe: string;
    enableLanguageDe: boolean;
}

/**
 * Component for the promotions tab in the CRM page
 */
class CreatePromotion extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadClientBodyRequest: {
                From: moment().startOf('year'),
                To: moment().endOf('year')
            },
            loadingClients: false,
            isFilterModified: true,
            language: "en",
            titleMailFr: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Promotion` : "Titre par défaut",
            contentMailFr: "<p>Veuillez modifier le message.</p>",
            enableLanguageFr: false,
            titleMailEn: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Promotion` : "Default title",
            contentMailEn: "<p>Please change the message.</p>",
            enableLanguageEn: true,
            titleMailIt: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Promozione` : "Titolo predefinito",
            contentMailIt: "<p>Si prega di cambiare il messaggio.</p>",
            enableLanguageIt: false,
            titleMailDe: this.props.companyDetails ? `${this.props.companyDetails.version === 0 ? "ESS " : ""}${this.props.companyDetails.name} - Förderung` : "Standardtitel",
            contentMailDe: "<p>Bitte ändern Sie die Nachricht.</p>",
            enableLanguageDe: false,
        };
    }

    componentDidMount() {
        this.checkCodesDiscountAreLoaded();
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.create === false && this.props.create === true) {
            this.createPromotion();
        }
        this.checkCodesDiscountAreLoaded();
    }

    changeLang = (language: string) => {
        if (this.state.language !== language) {
            this.setState({ language });
        }
    }

    createPromotion = () => {
        const { selectedCodeDiscount, selectedClients, clients } = this.state;
        const { intl } = this.props;
        if (selectedClients && clients && selectedCodeDiscount) {
            const selectedClientsMail = clients.filter(c => c.Id ? selectedClients.includes(c.Id) : false).map(c => {
                return { "mail": c.Email, "lang": c.LanguageId }
            });

            const mailContent: PromotionTranslationBodyRequest[] = [];
            if (this.props.name?.toLowerCase() === undefined) {
                showNotification(intl.formatMessage({ defaultMessage: 'The school name is missing' }), "error");
                return;
            }
            if (this.state.enableLanguageFr) {
                mailContent.push({
                    lang: "fr",
                    title: this.state.titleMailFr,
                    description: this.state.contentMailFr,
                    schoolName: this.props.name.toLowerCase()
                });
            }
            if (this.state.enableLanguageEn) {
                mailContent.push({
                    lang: "en",
                    title: this.state.titleMailEn,
                    description: this.state.contentMailEn,
                    schoolName: this.props.name.toLowerCase()
                });
            }
            if (this.state.enableLanguageIt) {
                mailContent.push({
                    lang: "it",
                    title: this.state.titleMailIt,
                    description: this.state.contentMailIt,
                    schoolName: this.props.name.toLowerCase()
                });
            }
            if (this.state.enableLanguageDe) {
                mailContent.push({
                    lang: "de",
                    title: this.state.titleMailDe,
                    description: this.state.contentMailDe,
                    schoolName: this.props.name.toLowerCase()
                });
            }



            if (selectedClientsMail.length > 0) {
                selectedCodeDiscount && Network.createPromotion({ code: selectedCodeDiscount.code, clients: selectedClientsMail, mailContent }).then(
                    () => {
                        showNotification(intl.formatMessage({ defaultMessage: 'The promotion has been successfully created' }), "success");
                        this.props.finishCreatePromotion && this.props.finishCreatePromotion();
                        this.props.cancelCreate && this.props.cancelCreate();
                    },
                    () => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the promotion' }), "error");
                        this.props.cancelCreate && this.props.cancelCreate();
                    },
                );
            } else {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the promotion' }), "error");
                this.props.cancelCreate && this.props.cancelCreate();
            }

        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the promotion' }), "error");
            this.props.cancelCreate && this.props.cancelCreate();
        }

    }

    checkCodesDiscountAreLoaded = () => {
        if (this.props.companyDetails !== undefined && this.props.companyCodeDiscounts === undefined) {
            this.props.dispatch(fetchAllCodesDiscount({ school__external_id: this.props.companyDetails.int_id }, "fr"));
        }
    }

    getClients = () => {
        const { intl } = this.props;
        this.setState({ loadingClients: true, selectedClients: undefined, isFilterModified: false });
        const { loadClientBodyRequest } = this.state;
        //get directory listing
        Network.getBCLoadClients(loadClientBodyRequest).then(
            (response) => {
                if (response.clients) {
                    let parsedClients: BCSchoolClient[] = response.clients;
                    if (parsedClients.length > 0 && parsedClients[0].Id) {
                        this.setState({ clients: parsedClients, loadingClients: false });
                    } else {
                        parsedClients = parsedClients.map((pc, id) => {
                            pc.Id = id;
                            return pc;
                        })
                        this.setState({ clients: parsedClients, loadingClients: false });
                    }
                } else {
                    this.setState({ clients: undefined, loadingClients: false, isFilterModified: true });
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers.' }), "error");
                }
            },
            () => {
                this.setState({ clients: undefined, loadingClients: false, isFilterModified: true });
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers.' }), "error");
            },
        );
    }
    /**
     * Called when a client is (un)checked
     * @param keys the new keys of checked clients
     */
    onChangeSelectedClients = (keys: React.Key[]) => {
        this.setState({ selectedClients: keys as number[] }, () => {
            if (this.props.setNextMail) {
                if (this.state.selectedClients && this.state.selectedClients.length > 0) {
                    this.props.setNextMail(true);
                } else {
                    this.props.setNextMail(false);
                }
            }
        });
    };


    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        // let { companyProducts, companyLevels } = this.props;
        const { companyCodeDiscounts, companyProducts } = this.props;
        const { loadClientBodyRequest } = this.state;
        let { selectedProducts, isFilterModified } = this.state;
        switch (type) {
            case DataType.CodesDiscount: {
                const selectedCodeDiscount = companyCodeDiscounts?.find((cp) => cp.id === value);
                this.setState({ selectedCodeDiscount }, () => {
                    if (this.state.selectedCodeDiscount === undefined) {
                        this.props.setNextClients && this.props.setNextClients(false);
                        this.props.setNextMail && this.props.setNextMail(false);
                    } else {
                        this.props.setNextClients && this.props.setNextClients(true);
                    }
                });
                break;
            }
            case DataType.Range:
                loadClientBodyRequest.From = moment(value[0]);
                loadClientBodyRequest.To = moment(value[1]);
                isFilterModified = true;
                break;
            case DataType.CountryIds:
                loadClientBodyRequest.CountryIds = value;
                isFilterModified = true;
                break;
            case DataType.LanguageIds:
                loadClientBodyRequest.LanguageIds = value;
                isFilterModified = true;
                break;
            // case DataType.MedalId:
            //     loadClientBodyRequest.MedalId = value;
            //     break;
            case DataType.ProductIds:
                selectedProducts = companyProducts?.filter((cp) => value.includes(cp.id));
                this.setState({ selectedProducts });
                if (selectedProducts) {
                    loadClientBodyRequest.ProductIds = selectedProducts.map(sp => sp.externalId);
                    loadClientBodyRequest.ActivityIds = selectedProducts.map(sp => sp.externalActivityId);
                } else {
                    loadClientBodyRequest.ActivityIds = loadClientBodyRequest.ProductIds = undefined;
                }
                isFilterModified = true;
                break;
        }

        this.setState({ loadClientBodyRequest, isFilterModified });
    }

    renderCodeDiscount = () => {
        const { selectedCodeDiscount } = this.state;
        const { companyCodeDiscounts, loadingCompanyCodeDiscounts } = this.props;
        return (
            <>
                <p><FormattedMessage defaultMessage={'This assistant will guide you through the creation of your promotion.'} /></p>
                <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'First, please select the discount voucher you want to add to your promotion'} />{':'}</p>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 12 }} md={{ span: 5 }} lg={{ span: 4 }} style={{ margin: 'auto 0 auto 0' }}>
                        <span><FormattedMessage defaultMessage={'Discount voucher:'} /></span>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 9 }} lg={{ span: 8 }}>
                        <Select
                            loading={loadingCompanyCodeDiscounts}
                            className="planning-modal-input-100"
                            showSearch
                            allowClear
                            showArrow
                            maxTagCount='responsive'
                            filterOption={true}
                            optionFilterProp="label"
                            placeholder={companyCodeDiscounts === undefined || companyCodeDiscounts.length === 0 ? <FormattedMessage defaultMessage={'No discount voucher available'} /> : <FormattedMessage defaultMessage={'Select the discount voucher'} />}
                            disabled={loadingCompanyCodeDiscounts && (companyCodeDiscounts === undefined || companyCodeDiscounts.length === 0)}
                            onChange={(id: number) => this.dataChanged(DataType.CodesDiscount, id)}
                            value={selectedCodeDiscount?.id}>
                            {companyCodeDiscounts?.map(cd => <Select.Option label={cd.code} value={cd.id} key={`code-discount-to-mail-${cd.id}`}><span title={cd.code}>{cd.code}</span></Select.Option>)}
                        </Select>
                    </Col>
                </Row>
            </>
        )
    }

    renderFilters = () => {
        const { clients } = this.state;
        return clients?.map(c => <p key={`client-${c.Id}`}>{c.Email}</p>)
    }

    renderClients = () => {
        const clientTableLocale = { emptyText: <FormattedMessage defaultMessage={'No customer available'} /> }
        const { clients, loadClientBodyRequest, selectedProducts, loadingClients, selectedClients, isFilterModified } = this.state;
        const { companyProducts, loadingCompanyProducts, countries, languages, intl } = this.props;
        // return clients?.map(c => <p key={`client-${c.Id}`}>{c.Email}</p>)
        return (
            <Row gutter={[15, 15]}>
                <Col span={24}>
                    <p><FormattedMessage defaultMessage={'Now, please select the customers who will receive the promotion by email.'} /></p>
                    <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'You can refine the list using the available filters.'} /></p>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }}>
                            <Card size="small" title={<FormattedMessage defaultMessage={'Filter by previous purchases'} />}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span>
                                            <FormattedMessage defaultMessage={'Dates'} />
                                            <Tooltip title={intl.formatMessage({ defaultMessage: 'The purchased course falls within the chosen dates.' })}>
                                                <InfoCircleOutlined className="tab-tooltip-info" />
                                            </Tooltip>
                                        </span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <DatePicker.RangePicker
                                            showTime={false}
                                            allowClear={false}
                                            className="__crm-codeDiscount-modal-input"
                                            format={getFormat('DATE')}
                                            value={[loadClientBodyRequest.From, loadClientBodyRequest.To]}
                                            onChange={d => this.dataChanged(DataType.Range, d)}

                                            ranges={{
                                                [intl.formatMessage({ defaultMessage: 'Today' })]: [moment(), moment()],
                                                [intl.formatMessage({ defaultMessage: 'This week' })]: [moment().startOf('week'), moment().endOf('week')],
                                                [intl.formatMessage({ defaultMessage: 'This month' })]: [moment().startOf('month'), moment().endOf('month')],
                                                [intl.formatMessage({ defaultMessage: 'This year' })]: [moment().startOf('year'), moment().endOf('year')],
                                            }} />
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Course'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        {/* <p className="event-section-title">{"Cours"}</p> */}
                                        <Select
                                            loading={loadingCompanyProducts}
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={companyProducts === undefined || companyProducts.length === 0 ? <FormattedMessage defaultMessage={'No courses available'} /> : <FormattedMessage defaultMessage={'Select the courses'} />}
                                            disabled={companyProducts === undefined || companyProducts.length === 0}
                                            onChange={(id: number[]) => this.dataChanged(DataType.ProductIds, id)}
                                            value={selectedProducts?.map(sp => sp.id)}
                                            filterOption={true}
                                            optionFilterProp="label">
                                            {companyProducts?.map(cp => <Select.Option label={cp.title} value={cp.id} key={`cd-company-product-${cp.id}`}><span title={cp.title}>{cp.title}</span></Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Countries'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={countries === undefined || countries.length === 0 ? <FormattedMessage defaultMessage={'No countries available'} /> : <FormattedMessage defaultMessage={'Select the countries'} />}
                                            disabled={countries === undefined || countries.length === 0}
                                            onChange={(id: string[]) => this.dataChanged(DataType.CountryIds, id)}
                                            value={loadClientBodyRequest.CountryIds}
                                            filterOption={true}
                                            optionFilterProp="label"
                                        >
                                            {countries?.map(c => <Select.Option value={c.Id} label={c.Text} key={`crm-promotions-country-${c.Id}`}>{c.Text}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col xs={{ span: 6 }} style={{ margin: 'auto' }}>
                                        <span><FormattedMessage defaultMessage={'Language'} /></span>
                                    </Col>
                                    <Col xs={{ span: 18 }}>
                                        <Select
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            mode="multiple"
                                            maxTagCount='responsive'
                                            placeholder={languages === undefined || languages.length === 0 ? <FormattedMessage defaultMessage={'No languages available'} /> : <FormattedMessage defaultMessage={'Select the language(s)'} />}
                                            disabled={languages === undefined || languages.length === 0}
                                            onChange={(id: string[]) => this.dataChanged(DataType.LanguageIds, id)}
                                            value={loadClientBodyRequest.LanguageIds}
                                            filterOption={true}
                                            optionFilterProp="label"
                                        >
                                            {languages?.map(c => <Select.Option label={c.Text} value={c.Id} key={`crm-promotions-languages-${c.Id}`}>{c.Text}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    {/* <Col xs={{ span: 8 }} style={{ margin: 'auto' }}>
                                        <span>{"Médaille"}</span>
                                    </Col>
                                    <Col xs={{ span: 16 }}>
                                        <Select
                                            className="planning-modal-input-100"
                                            showSearch
                                            allowClear
                                            showArrow
                                            maxTagCount='responsive'
                                            placeholder={medals?.length === 0 ? "Aucune médaille disponible" : "Choisissez le(s) médaille(s)"}
                                            disabled={medals?.length === 0}
                                            onChange={(id: string) => this.dataChanged(DataType.MedalId, id)}
                                            value={loadClientBodyRequest.MedalId}
                                        // filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {medals?.map(c => <Select.Option value={c.Id} key={`crm-promotions-medals-${c.Id}`}>{c.Text}</Select.Option>)}
                                        </Select>
                                    </Col> */}
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>

                            <Button disabled={!isFilterModified} loading={loadingClients} type="primary" onClick={this.getClients} key="promotion-load-clients">
                                <FormattedMessage defaultMessage={'Load customers'} />
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>

                    <Card size="small" bodyStyle={{ padding: '1px 0 10px 0' }} title={<FormattedMessage defaultMessage={'Select customers'} />} extra={selectedClients ? selectedClients.length : null}>
                        <Table
                            locale={clientTableLocale}
                            loading={loadingClients}
                            key={`table-promotion-client`}
                            className='__poi-list'
                            rowKey={(sc: BCSchoolClient) => sc.Id!}
                            dataSource={clients}
                            columns={this.columns}
                            // loading={this.state.groupsLoading}
                            pagination={{
                                size: "small",
                                showLessItems: true,
                                defaultPageSize: 8,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} customers'} values={{ range0: range[0], range1: range[1], total }} />
                            }}
                            scroll={{ x: 'max-content' }}
                            // onRow={this.codeDiscountOnRow}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: this.onChangeSelectedClients,
                                selectedRowKeys: selectedClients,
                                selections: [
                                    Table.SELECTION_ALL,
                                    Table.SELECTION_NONE,
                                ]
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }

    renderMailByLanguage = (forceLanguage?: string) => {
        const { selectedCodeDiscount, titleMailFr, contentMailFr, titleMailEn, contentMailEn, titleMailIt, contentMailIt, titleMailDe, contentMailDe, language, enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        const warningMessage = <p style={{ fontWeight: "bold" }}><FormattedMessage defaultMessage={'This language will not be considered for the email. Please enable it if you want to include it in the send-out, so you can modify it.'} /></p>;
        switch (forceLanguage ? forceLanguage : language) {
            case "fr":
                if (enableLanguageFr) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailFr: value })}
                        modifyContent={(value: string) => this.setState({ contentMailFr: value })}
                        title={titleMailFr}
                        codeDiscount={selectedCodeDiscount}
                        content={contentMailFr}
                    />
                } else {
                    return warningMessage
                }
            case "it":
                if (enableLanguageIt) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailIt: value })}
                        modifyContent={(value: string) => this.setState({ contentMailIt: value })}
                        title={titleMailIt}
                        codeDiscount={selectedCodeDiscount}
                        content={contentMailIt}
                    />
                } else {
                    return warningMessage
                }
            case "de":
                if (enableLanguageDe) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailDe: value })}
                        modifyContent={(value: string) => this.setState({ contentMailDe: value })}
                        title={titleMailDe}
                        codeDiscount={selectedCodeDiscount}
                        content={contentMailDe}
                    />
                } else {
                    return warningMessage
                }
            default:
                if (enableLanguageEn) {
                    return <MailPreview
                        modifyTitle={(value: string) => this.setState({ titleMailEn: value })}
                        modifyContent={(value: string) => this.setState({ contentMailEn: value })}
                        title={titleMailEn}
                        codeDiscount={selectedCodeDiscount}
                        content={contentMailEn}
                    />
                } else {
                    return warningMessage
                }
        }
    }

    changeSwitchValue = (language: string, value: boolean) => {
        const { enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        const { intl } = this.props;

        switch (language) {
            case "fr":
                if (!value && !enableLanguageEn && !enableLanguageDe && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. It is not possible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageFr: value });
                }
                break;
            case "it":
                if (!value && !enableLanguageEn && !enableLanguageDe && !enableLanguageFr) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. It is not possible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageIt: value });
                }
                break;
            case "de":
                if (!value && !enableLanguageEn && !enableLanguageFr && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. It is not possible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageDe: value });
                }
                break;
            default:
                if (!value && !enableLanguageFr && !enableLanguageDe && !enableLanguageIt) {
                    showNotification(intl.formatMessage({ defaultMessage: 'At least one language must be active. It is not possible to deactivate this language.' }), "warning");
                } else {
                    this.setState({ enableLanguageEn: value });
                }
                break;
        }
    }

    renderSwitchByLanguage = () => {
        const { language, enableLanguageEn, enableLanguageFr, enableLanguageDe, enableLanguageIt } = this.state;
        switch (language) {
            case "fr":
                return <Switch size="small" checked={enableLanguageFr} onChange={(e) => this.changeSwitchValue(language, e)} style={{ marginTop: '8px' }} />
            case "it":
                return <Switch size="small" checked={enableLanguageIt} onChange={(e) => this.changeSwitchValue(language, e)} style={{ marginTop: '8px' }} />
            case "de":
                return <Switch size="small" checked={enableLanguageDe} onChange={(e) => this.changeSwitchValue(language, e)} style={{ marginTop: '8px' }} />
            default:
                return <Switch size="small" checked={enableLanguageEn} onChange={(e) => this.changeSwitchValue(language, e)} style={{ marginTop: '8px' }} />
        }
    }

    renderMessage = () => {
        const { language, clients, selectedClients, titleMailEn, titleMailFr, titleMailDe, titleMailIt } = this.state;
        return (
            <>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <p><FormattedMessage defaultMessage={'Finally, please edit the email content in the desired languages. Clients who do not match any of the activated languages will receive the email in the first available language.'} /></p>
                        <p style={{ marginBottom: '10px' }}><FormattedMessage defaultMessage={'To edit the title, simply click on it (below the logo). The same applies to the message (below the title).'} /></p>
                    </Col>

                    <Col span={24}>
                        <Card>
                            <Row gutter={[15, 15]}>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <p className="__create-promotion-title" style={{ width: '25px', marginTop: '3px' }}>A: </p>
                                    <Select
                                        style={{ width: 'calc(100% - 20px)' }}
                                        showSearch={false}
                                        allowClear={false}
                                        showArrow
                                        mode="multiple"
                                        maxTagCount='responsive'
                                        placeholder={clients === undefined || clients.length === 0 ? <FormattedMessage defaultMessage={'No customers available'} /> : <FormattedMessage defaultMessage={'Select the customers'} />}
                                        disabled={clients === undefined || clients.length === 0}
                                        // onChange={(ids: number[]) => this.onChangeSelectedClients(ids)}
                                        value={selectedClients}
                                        filterOption={true}
                                        optionFilterProp="label"
                                    >
                                        {clients?.filter(c => selectedClients?.includes(c.Id!)).map(c => <Select.Option label={c.Email} value={c.Id!} key={`create-promotion-render-message-client-${c.Id}`}>{c.Email}</Select.Option>)}
                                    </Select>
                                </Col>
                                <div className="__create-promotion-hr"></div>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <p className="__create-promotion-title" style={{ width: '55px' }}>Objet: </p>
                                    <p>{language === "fr" ? titleMailFr : language === "de" ? titleMailDe : language === "it" ? titleMailIt : titleMailEn}</p>
                                </Col>
                                <div className="__create-promotion-hr"></div>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className="__create-promotion-title" style={{ lineHeight: "32px" }}>E-mail: </span>
                                    <div style={{ height: "32px", display: "flex" }}>
                                        {this.renderSwitchByLanguage()}
                                        <Select
                                            // disabled={edited}
                                            className="__create-promotion-select-lang"
                                            value={language}
                                            bordered={false}
                                            showArrow={false}
                                            onChange={n => this.changeLang(n)}>
                                            <Select.Option value={"en"} key={`language-en`}><img alt="" style={{ maxWidth: "100%" }} src={enUrl} /></Select.Option>
                                            <Select.Option value={"fr"} key={`language-fr`}><img alt="" style={{ maxWidth: "100%" }} src={frUrl} /></Select.Option>
                                            <Select.Option value={"de"} key={`language-de`}><img alt="" style={{ maxWidth: "100%" }} src={deUrl} /></Select.Option>
                                            <Select.Option value={"it"} key={`language-it`}><img alt="" style={{ maxWidth: "100%" }} src={itUrl} /></Select.Option>
                                        </Select>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    {this.renderMailByLanguage()}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    renderSummary = () => {
        return <p>s</p>
    }

    renderStep = () => {
        const { currentStep } = this.props;
        switch (currentStep) {
            case 0:
                return this.renderCodeDiscount();
            case 1:
                return this.renderClients();
            case 2:
                return this.renderMessage();
            case 4:
                return this.renderSummary();
            default:
                return this.renderCodeDiscount();

        }
    }

    render() {

        return (
            <Row>
                <Col xs={{ span: 24 }}>
                    {
                        this.renderStep()
                    }
                </Col>
            </Row >

        )
    }

    columns: ColumnProps<BCSchoolClient>[] = [
        {
            title: <FormattedMessage defaultMessage={'Email'} />,
            dataIndex: 'Email',
            key: 'Email',
        },
    ];
}

const mapStateToProps = (state: ApplicationState) => ({
    medals: state.crm.medals,
    countries: state.crm.countries,
    languages: state.crm.languages,
    loadingCompanyCodeDiscounts: state.crm.loadingCompanyCodeDiscounts,
    companyCodeDiscounts: state.crm.companyCodeDiscounts,
    companyProducts: state.crm.companyProducts,
    loadingCompanyProducts: state.crm.loadingCompanyProducts,
    companyDetails: state.crm.companyDetails,
    name: state.crm.companyDetails?.name,
});


export default connect(mapStateToProps)(injectIntl(CreatePromotion));