import { ProjectOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { GiPalmTree } from 'react-icons/gi';
import { Rules } from '../../rbacRules';
import { RouterProps } from '../../utils/types/generalTypes';
import Can from '../common/general/can';
import Container from '../common/navigations/container';
import ReportTab from './reportTab';
import VacationsTab from './vacationsTab';

//Keys for the different tabs
enum TabKeys {
    Vacations = "v",
    Report = "r",
}

//Links for the different tabs
enum TabLink {
    Vacations = "vacations",
    Report = "report",
}

interface IProps { }

type Props = IProps & RouterProps;

interface State { }

class Report extends React.Component<Props, State> {
    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        switch (this.props.match.params.tab) {
            case TabLink.Vacations:
                return TabKeys.Vacations;
            default:
                return TabKeys.Report;
        }
    }

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (key) {
            case TabKeys.Vacations:
                link = TabLink.Vacations;
                break;
            default:
                link = TabLink.Report;
                break;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/report/${link}`);
    }

    render() {
        const currentTab = this.getCurrentTab();
        return (
            <Can rule={Rules.Report.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: "Rapport", link: "/report" }]}>
                    <Tabs animated activeKey={currentTab} onChange={this.onChangeTab}>
                        <Tabs.TabPane forceRender key={TabKeys.Report} tab={
                            <span>
                                <ProjectOutlined />
                                {"Rapports mensuels"}
                            </span>
                        }>
                            <ReportTab />
                        </Tabs.TabPane>
                        <Tabs.TabPane forceRender key={TabKeys.Vacations} tab={
                            <span>
                                <GiPalmTree className='anticon' />
                                {"Vacances"}
                            </span>
                        }>
                            <VacationsTab />
                        </Tabs.TabPane>
                    </Tabs>
                </Container>
            </Can>
        )
    }
}

export default Report;