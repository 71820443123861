/**
 * Constants containing the rules
 */
export const Rules = {
    Sidemenu: {
        Admin: "sidemenu:admin",
    },
    Newsfeed: {
        Filter: "newsfeed:filter",
        Delete: "newsfeed:delete",
        AllFeeds: "newsfeed:all",
    },
    Message: {
        Filter: "message:filter",
        Delete: "message:delete",
    },
    TeamManagement: {
        Visit: "teamManagement:visit",
        MakeAdmin: "teamManagement:makeAdmin",
    },
    CustomerManagement: {
        Visit: "customerManagement:visit",
    },
    CourseManagement: {
        Visit: "courseManagement:visit",
    },
    Project: {
        Visit: "project:visit",
    },
    Emergencies: {
        Visit: "emergencies:visit",
    },
    DirectoryListing: {
        Visit: "directoryListing:visit",
    },
    Dashboard: {
        Display: "dashboard:display",
    },
    Planning: {
        Weekly: "planning:weekly",
        Visit: "planning:visit",
        Filters: "planning:filters",
        AI: "planning:ai",
        Settings: "planning:settings",
        DnDVersion: "planning:dndVersion",
        Management: "planning:management",
    },
    Report: {
        Visit: "report:visit",
        Beta: "report:beta:visit",
    },
    SunkhronosLive: {
        Visit: "sunkhronosLive:visit",
    },
    Configurations: {
        Visit: "configurations:visit",
    }
};

/**
 * The application Role Based Access Control rules
 */
const rules = {
    user: {
        static: [
            "administration:action",
            "planning:weekly",
            "planning:filters"
        ]
    },
    admin: {
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "customerManagement:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    groupAdmin: {
        static: [
            "sidemenu:admin",
            "report:visit",
            "dashboard:display",
            "planning:filters"
        ]
    }
};

export const companyModules = [
    {
        id: -1,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 55,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 152,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "customerManagement:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 57,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            // "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 68,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            // "project:visit",
            "dashboard:display",
        ]
    },
    // {
    //     id:47,
    //     static: [
    //         "sidemenu:admin",
    //         "newsfeed:filter",
    //         "newsfeed:delete",
    //         "newsfeed:all",
    //         "message:filter",
    //         "message:delete",
    //         "teamManagement:visit",
    //         "teamManagement:makeAdmin",
    //         "courseManagement:visit",
    //         "sunkhronosLive:visit",
    //         "administration:action",
    //         "emergencies:visit",
    //         "directoryListing:visit",
    //         "planning:filters",
    //         "planning:settings",
    //         "planning:dndVersion",
    //         "planning:management",
    //         // "report:visit",
    //         "report:beta:visit",
    //         "configurations:visit",
    //         // "project:visit",
    //     ]
    // },
    {
        id: 66,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            // "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            // "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 70,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            // "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 80,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 96, // Hotel - Restaurant
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 101,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 102,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 103,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 135,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 104,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 113,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 117,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 123,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "courseManagement:visit",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "administration:action",
            "emergencies:visit",
            "sunkhronosLive:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
        ]
    },
    {
        id: 129,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 132,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 136,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            // "project:visit",
            "dashboard:display",
            "customerManagement:visit",
        ]
    },
    {
        id: 140,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "sunkhronosLive:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 142, // Intenso
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 143, // Enzler
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 145, // Swiss Security (démo CCT)
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },
    {
        id: 150,
        static: [
            "sidemenu:admin",
            "newsfeed:filter",
            "newsfeed:delete",
            "newsfeed:all",
            "message:filter",
            "message:delete",
            "teamManagement:visit",
            "teamManagement:makeAdmin",
            "courseManagement:visit",
            "administration:action",
            "emergencies:visit",
            "directoryListing:visit",
            "planning:filters",
            "planning:ai",
            "planning:settings",
            "planning:dndVersion",
            "planning:management",
            "planning:weekly",
            "report:visit",
            // "report:beta:visit",
            "configurations:visit",
            "project:visit",
            "dashboard:display",
        ]
    },

];

export default rules;