import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment";
import { customerTestData } from "../../components/customersManagement/dataTest/data";
import { ReloadTimes } from "../../utils/constants";
import Network from "../../utils/network";
import { ICustomer, IMission } from "../../utils/types/customerTypes";
import { ApplicationState, CustomerManagementState } from "../../utils/types/storeTypes";
import { convertICostumersNetworkToICustomers, isDeprecated } from "../../utils/utils";

export enum MissionAction {
    ADD = 'add',
    UPDATE = 'update',
    REMOVE = 'remove'
}

export const CUSTOMER_MANAGEMENT_INIT_STATE:CustomerManagementState = { 
    customers: { loading: false },
    mandates: { loading: false },
    sites: { loading: false },
    missions: { loading: false },
}

export const customersFetched = createAsyncThunk("customers/fetch", async () =>  {
    const response = await Network.getCustomers();
    const customers = convertICostumersNetworkToICustomers(response.data);
    return {updatedAt: moment(), customers}
}, {
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { customerManagement } = thunkAPI.getState() as ApplicationState;
        return ( arg.forceReload || isDeprecated(customerManagement.customers.updatedAt, ReloadTimes.MEDIUM_RELOAD) )
    },
})

export const mandatesFetched = createAsyncThunk("mandates/fetch", async () =>  {
    const response = await Network.getMandates();
    return {updatedAt: moment().toISOString(), mandates: response.data}
}, {
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { customerManagement } = thunkAPI.getState() as ApplicationState;
        return ( arg.forceReload || isDeprecated(customerManagement.mandates.updatedAt ? moment(customerManagement.mandates.updatedAt) : undefined, ReloadTimes.MEDIUM_RELOAD) )
    },
})

export const missionsFetched = createAsyncThunk("missions/fetch", async () =>  {
    const response = await Network.getMissions();
    return {updatedAt: moment().toISOString(), missions: response.data}
}, {
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { customerManagement } = thunkAPI.getState() as ApplicationState;
        return ( arg.forceReload || isDeprecated(customerManagement.missions.updatedAt ? moment(customerManagement.missions.updatedAt) : undefined, ReloadTimes.MEDIUM_RELOAD) )
    },
})

export const sitesFetched = createAsyncThunk("sites/fetch", async () =>  {
    const response = await Network.getSites();
    return {updatedAt: moment().toISOString(), sites: response.data}
}, {
    condition: (arg: {forceReload: boolean}, thunkAPI) => {
        const { customerManagement } = thunkAPI.getState() as ApplicationState;
        return ( arg.forceReload || isDeprecated(customerManagement.sites.updatedAt ? moment(customerManagement.sites.updatedAt) : undefined, ReloadTimes.MEDIUM_RELOAD) )
    },
})

export const customerUpdated = createAsyncThunk("customer/update", async (arg: {customerId: number, newData: Partial<ICustomer>}) => {
    // const response = await Network.updateCustomer();
    let newCustomer = cloneDeep(customerTestData.find((customer) => customer.id === arg.customerId));

    if(newCustomer) {
        newCustomer = {
            ...newCustomer,
            ...arg.newData
        };
    }

    return newCustomer as ICustomer;
})

const customerManagementSlice = createSlice({
    name: 'customerManagement',
    initialState: CUSTOMER_MANAGEMENT_INIT_STATE,
    reducers: { 
        reset: () => {
            return {
                ...CUSTOMER_MANAGEMENT_INIT_STATE
            }
        },
        updateMissions: (state, action:PayloadAction<{missions: IMission[], action: MissionAction }>) => {
            const oldMissions = cloneDeep(state.missions);
            let newMissions: IMission[] = [];

            switch (action.payload.action) {
                case MissionAction.ADD:
                    if(oldMissions.data !== undefined) {
                        newMissions = oldMissions.data.concat(action.payload.missions);
                    }else {
                        newMissions = action.payload.missions;
                    }                    
                    break;
                case MissionAction.UPDATE:
                    if(oldMissions.data !== undefined) {
                        newMissions = oldMissions.data.map((oldMission) => {
                            const found = action.payload.missions.find(mission => mission.id === oldMission.id)
                            if(found) {
                                return found;
                            }
        
                            return oldMission;
                        });
                    }else {
                        newMissions = action.payload.missions;
                    }
        
                    return {
                        ...state,
                        missions: {
                            loading: false,
                            updatedAt: moment().toISOString(),
                            data: newMissions
                        }
                    };
                        
                    break;
                case MissionAction.REMOVE:
                    if(oldMissions.data !== undefined) {
                        newMissions = oldMissions.data.filter(oldMission => !action.payload.missions.some(mission => mission.id === oldMission.id));
                    }
                    break;            
                default:
                    break;

            }

            return {
                ...state,
                missions: {
                    loading: false,
                    updatedAt: moment().toISOString(),
                    data: newMissions
                }
            };
            
        }
    },
    extraReducers(builder) {
        // #region customersFetched
        builder.addCase(customersFetched.pending, (state) => {
            state.customers.loading = true;
        })

        builder.addCase(customersFetched.fulfilled, (state, action) => {
            state.customers.updatedAt = action.payload.updatedAt;
            state.customers = {
                ...state.customers,
                loading: false,
                updatedAt: action.payload.updatedAt,
                data: action.payload.customers 
            };
        })

        builder.addCase(customersFetched.rejected, (state) => {
            state.customers.loading = false;
        })
        // #endregion
        // #region mandatesFetched
        builder.addCase(mandatesFetched.pending, (state) => {
            state.mandates.loading = true;
        })

        builder.addCase(mandatesFetched.fulfilled, (state, action) => {
            state.mandates.updatedAt = action.payload.updatedAt;
            state.mandates = {
                ...state.mandates,
                loading: false,
                updatedAt: action.payload.updatedAt,
                data: action.payload.mandates 
            };
        })

        builder.addCase(mandatesFetched.rejected, (state) => {
            state.mandates.loading = false;
        })
        // #endregion
        // #region sitesFetched
        builder.addCase(sitesFetched.pending, (state) => {
            state.sites.loading = true;
        })

        builder.addCase(sitesFetched.fulfilled, (state, action) => {
            state.sites.updatedAt = action.payload.updatedAt;
            state.sites = {
                ...state.sites,
                loading: false,
                updatedAt: action.payload.updatedAt,
                data: action.payload.sites 
            };
        })

        builder.addCase(sitesFetched.rejected, (state) => {
            state.sites.loading = false;
        })
        // #endregion
        // #region missionsFetched
        builder.addCase(missionsFetched.pending, (state) => {
            state.missions.loading = true;
        })

        builder.addCase(missionsFetched.fulfilled, (state, action) => {
            state.missions.updatedAt = action.payload.updatedAt;
            state.missions = {
                ...state.missions,
                loading: false,
                updatedAt: action.payload.updatedAt,
                data: action.payload.missions 
            };
        })

        builder.addCase(missionsFetched.rejected, (state) => {
            state.missions.loading = false;
        })
        // #endregion
        // #region customerUpdated
        builder.addCase(customerUpdated.fulfilled, (state, action) => {            
            state.customers.data = state.customers.data?.map(c => {
                if(c.id !== action.meta.arg.customerId) return c
                else return {...c, ...action.payload}
            })
        })
        // #endregion
    }
})

export const { reset, updateMissions } = customerManagementSlice.actions

export default customerManagementSlice.reducer