import { CheckOutlined, CloseOutlined, DashOutlined, InfoCircleOutlined, MoreOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Container } from '@mui/material';
import { Avatar, Col, Divider, Popconfirm, Popover, Row, Select, Space, Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import moment, { Duration, Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { BsEyeSlash } from 'react-icons/bs';
import { LuClock12 } from 'react-icons/lu';
import { MdMoreTime } from 'react-icons/md';
import { RiArrowRightDoubleLine, RiCupFill } from 'react-icons/ri';
import { TbClockCheck, TbClockEdit, TbClockX } from 'react-icons/tb';
import { TfiPencil } from 'react-icons/tfi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeTemplates } from '../../../store/actions/planning';
import { changeGroups, changeUsers } from '../../../store/actions/teamManagement';
import { changeCompany } from '../../../store/actions/user';
import { BLUE_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Company, EventClocked, EventClockedDates, EventClockedsByDate, EventWithEventClockByDate, EventWithEventClocked, Group, RouterProps, SmallEventClocked, StartAndEndDates, TimeClockControlFiltersCheck, TimeClockEventDuration, User } from '../../../utils/types/generalTypes';
import { NetworkEventClockedResponse, NetworkEventClockedsConfirmRejectSetAdmin, NetworkEventClockedSetAdmin, NetworkEventWithEventClocked, NetworkSetEventParameters, NetworkEventClocked as NetworkSmallEventClocked } from '../../../utils/types/networkTypes';
import { PlanningEvent, PlanningTemplate } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps, UserDispatchProps } from '../../../utils/types/storeTypes';
import { colorIsBright, containARangeOutside, convertNetworkEventsToPlanningEventsV2, convertNetworkEventWithEventClockedToEventWithEventClocked, isNullOrEmpty, momentDurationFormat, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import SpeedDial from '../../common/fields/speedDial';
import Anticon from '../../common/general/anticon';
import ControlTime from '../../common/general/controlTime';
import Event, { EventSource } from '../../common/general/event';
import { dashboardDisableFilters as dashboardFilters } from '../../courseManagement/tableSearch';
import EditEventClockedModal from './timeclockControl/editEventClockedModal';
import ReplaceByTemplate from './timeclockControl/replaceByTemplate';

export enum TimeClockStatus {
    NONE = 1,
    STARTED = 2,
    FINISHED = 3,
    PAUSED = 4,
    MANUALLY_SETTED = 5,
    ADMIN_MODIFIED = 6,
    REFUSED = 7,
    APPROVED = 8,
    FROM_TIME_CLOCK = 9
}

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = RouterProps & IProps & ReduxProps & TeamManagementDispatchProps & UserDispatchProps & IntlProps;

interface State {
    isEventClockedsLoading: 'idle' | 'loading' | 'success' | 'error';
    allEventClockeds: EventWithEventClocked[];
    displayEditModal: boolean;
    selectedEventClocked?: EventWithEventClocked;
    isButtonsLoading: boolean;
    selectedEventWithEventClockeds: number[];
    editableEventIds: number[];
    selectedDay: Moment;
    selectedDates: { startDate: Moment, endDate: Moment; };
    eventClockedsByDate: EventClockedsByDate[];

    allEventsWithEventClocked: EventWithEventClocked[];
    selectedEventWithEventClocked?: EventWithEventClocked;
    eventsWithEventClockedByDate: EventWithEventClockByDate[];

    selectedFilters: number[];
    filteredEventsWithClockedByDate: EventWithEventClockByDate[];

    display: 'day' | 'week' | 'month' | 'year';
}

export const DateType = {
    PLANIFIED: 'planified',
    CLOCKED: 'clocked',
    MANUAL: 'manual',
    TEMPORARY: 'temporary'
};

export const DateTypeWarning = {
    BEFORE: 'before',
    AFTER: 'after'
};

class TimeClockControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEventClockedsLoading: 'idle',
            allEventClockeds: [],
            displayEditModal: false,
            isButtonsLoading: false,
            selectedEventWithEventClockeds: [],
            editableEventIds: [],
            selectedDay: moment(),
            selectedDates: {
                startDate: moment().startOf('week').startOf('day'),
                endDate: moment().endOf('week').endOf('day')
            },
            eventClockedsByDate: [],

            allEventsWithEventClocked: [],
            eventsWithEventClockedByDate: [],
            selectedFilters: [],
            filteredEventsWithClockedByDate: [],

            display: 'day',
        };
    }

    componentDidMount() {
        if (this.props.users === undefined || this.props.users.length === 0) this.getUsers();

        if (isNullOrEmpty(this.props.templates)) {
            Network.getTemplates().then(
                response => {
                    if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning")
                    else {
                        this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data))
                    }
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning"),
            );
        }
        this.getEventClockeds();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!isEqual(this.state.selectedDates, prevState.selectedDates)) {
            this.getEventClockeds();
        }
    }

    getUsers = () => {
        Network.getAllUsers().then(
            response => {
                this.props.changeUsers!(response);
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning");
            }
        );
    };

    getEventClockeds = (force = false) => {
        if (force || !(this.state.isEventClockedsLoading === 'loading')) {
            this.setState({ isEventClockedsLoading: 'loading' }, () => {
                Network.getEventsWithEventClockedOnSelectedDays(this.state.selectedDates).then(
                    response => {
                        this.setState({ isEventClockedsLoading: 'success' }, () => this.pushRawEventClockeds(response));
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events clocked' }), "warning");
                        this.setState({ isEventClockedsLoading: 'error' });
                    }
                );
            });
        }
    };

    pushRawEventClockeds = (networkResponse: NetworkEventClockedResponse) => {
        const { users } = this.props;

        const allEventsWithEventClocked: EventWithEventClocked[] = [];
        const eventsWithEventClockedByDate: EventWithEventClockByDate[] = [];
        const filteredEventsWithClockedByDate: EventWithEventClockByDate[] = [];

        for (let day = this.state.selectedDates.startDate.clone(); day.isSameOrBefore(this.state.selectedDates.endDate); day.add(1, 'day')) {
            eventsWithEventClockedByDate.push({
                date: day.clone(),
                events: []
            });
            filteredEventsWithClockedByDate.push({
                date: day.clone(),
                events: []
            });
        }

        const networkData: NetworkEventWithEventClocked[] = networkResponse.data.filter((nEventWithEC: NetworkEventWithEventClocked) => ((nEventWithEC.typeOfDay == null && nEventWithEC.typeOfDayOff == null) || (nEventWithEC.typeOfDay != null && nEventWithEC.typeOfDay.canBeBadged) || (nEventWithEC.typeOfDayOff != null && nEventWithEC.typeOfDayOff.canBeBadged)));

        networkData.forEach((nEventWithEC) => {
            const user = users.find(e => e.id == nEventWithEC.userId);
            const event = convertNetworkEventWithEventClockedToEventWithEventClocked(nEventWithEC, user);

            if ((!event.clocked?.isConfirmed && !event.clocked?.isRefused)) {
                allEventsWithEventClocked.push(event);
                eventsWithEventClockedByDate.find(e => e.date.isSame(event.startDate, 'day'))?.events.push(event);
                filteredEventsWithClockedByDate.find(e => e.date.isSame(event.startDate, 'day'))?.events.push(event);
            }
        });

        allEventsWithEventClocked.sort((a: EventWithEventClocked, b: EventWithEventClocked) => moment(a.startDate).diff(moment(b.startDate)));

        eventsWithEventClockedByDate.forEach((e) => {
            e.events.sort((a: EventWithEventClocked, b: EventWithEventClocked) => moment(a.startDate).diff(moment(b.startDate)));
        });

        this.setState({ allEventsWithEventClocked, editableEventIds: networkResponse.rights, eventsWithEventClockedByDate, filteredEventsWithClockedByDate }, this.filterEventsWithClocked);
    };

    removeRawEventClocked = (networkResponse: NetworkEventClockedResponse) => {
        const { allEventsWithEventClocked, display } = this.state;
        const selectedDay = this.state.selectedDay.clone();
        const eventsWithEventClockedByDate = cloneDeep(this.state.eventsWithEventClockedByDate);
        const filteredEventsWithClockedByDate = cloneDeep(this.state.filteredEventsWithClockedByDate);

        const networkData: NetworkSmallEventClocked = networkResponse.data;
        const eventClockedsNew = allEventsWithEventClocked.filter(e => e.id !== networkData.id);

        if (display === 'day') {
            eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events = eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events.filter(e => e.clocked?.id !== networkData.id);
            filteredEventsWithClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events = eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events.filter(e => e.clocked?.id !== networkData.id);
        }
        else {
            eventsWithEventClockedByDate.forEach(event => {
                event.events = event.events.filter(e => e.clocked?.id !== networkData.id);
            });
            filteredEventsWithClockedByDate.forEach(event => {
                event.events = event.events.filter(e => e.clocked?.id !== networkData.id);
            });
        }

        const selectedEventWithEventClockeds = this.state.selectedEventWithEventClockeds.filter(i => i != networkData.eventId);

        this.setState(() => ({ allEventsWithEventClocked: eventClockedsNew, eventsWithEventClockedByDate, filteredEventsWithClockedByDate, selectedEventWithEventClockeds }), this.filterEventsWithClocked);
    };

    removeRawEventClockeds = (networkResponse: NetworkEventClockedResponse) => {
        const { allEventsWithEventClocked, display } = this.state;
        const selectedDay = this.state.selectedDay.clone();
        const eventsWithEventClockedByDate = cloneDeep(this.state.eventsWithEventClockedByDate);
        const filteredEventsWithClockedByDate = cloneDeep(this.state.filteredEventsWithClockedByDate);
        let newEventsWithEventClocked: EventWithEventClocked[] = allEventsWithEventClocked;
        const networkData: NetworkSmallEventClocked[] = networkResponse.data;

        networkData.forEach((eventClocked: NetworkSmallEventClocked) => {
            newEventsWithEventClocked = newEventsWithEventClocked.filter(e => e.id !== eventClocked.id);

            if (display === 'day') {
                eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events = eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events.filter(e => e.clocked?.id !== eventClocked.id);
                filteredEventsWithClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events = eventsWithEventClockedByDate.filter(e => e.date.isSame(selectedDay, 'day'))[0].events.filter(e => e.clocked?.id !== eventClocked.id);
            }
            else {
                eventsWithEventClockedByDate.forEach(event => {
                    event.events = event.events.filter(e => e.clocked?.id !== eventClocked.id);
                });
                filteredEventsWithClockedByDate.forEach(event => {
                    event.events = event.events.filter(e => e.clocked?.id !== eventClocked.id);
                });
            }
        });

        this.setState(() => ({ allEventsWithEventClocked: newEventsWithEventClocked, selectedEventWithEventClockeds: [], eventsWithEventClockedByDate, filteredEventsWithClockedByDate }), this.filterEventsWithClocked);
    };

    onConfirmEventClocked = (eventClockedId: number) => {
        this.setState({ isButtonsLoading: true });
        Network.confirmEventClocked(eventClockedId).then(
            response => {
                this.removeRawEventClocked(response);
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully confirmed' }), "success");
            },
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while confirming the event' }), "warning");
            }
        );
    };

    onConfirmEventClockeds = (eventClockedsIds: number[]) => {
        const networkEventClockeds: NetworkEventClockedsConfirmRejectSetAdmin[] = [];
        eventClockedsIds.forEach((i) => {
            networkEventClockeds.push({
                eventClockedId: this.state.allEventsWithEventClocked.find(e => e.id == i)?.clocked?.id ? this.state.allEventsWithEventClocked.find(e => e.id == i)!.clocked!.id! : -1,
            });
        });

        this.setState({ isButtonsLoading: true });
        Network.confirmOrRejectEventsWithClocked('confirm', networkEventClockeds).then(
            response => {
                this.removeRawEventClockeds(response);
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The events have been successfully confirmed' }), "success");
            },
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while confirming the events' }), "warning");
            }
        );
    };

    onRejectEventClocked = (eventClocked: SmallEventClocked) => {
        this.setState({ isButtonsLoading: true });
        Network.rejectEventClocked(eventClocked.id).then(
            response => {
                this.removeRawEventClocked(response);
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully rejected' }), "success");
            },
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while rejecting the event' }), "warning");
            }
        );
    };

    onRejectEventClockeds = (eventClockedsIds: number[]) => {
        const networkEventClockeds: NetworkEventClockedsConfirmRejectSetAdmin[] = [];
        eventClockedsIds.forEach((i) => {
            networkEventClockeds.push({
                eventClockedId: this.state.allEventsWithEventClocked.find(e => e.id == i)?.clocked?.id ? this.state.allEventsWithEventClocked.find(e => e.id == i)!.clocked!.id! : -1,
            });
        });

        this.setState({ isButtonsLoading: true });
        Network.confirmOrRejectEventsWithClocked('reject', networkEventClockeds).then(
            response => {
                this.removeRawEventClockeds(response);
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The events have been successfully rejected' }), "success");
            },
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while rejecting the events' }), "warning");
            }
        );
    };

    onRejectAllBreakTimeClockeds = (eventClocked: EventWithEventClocked) => {
        Network.rejectAllBreakTimeClocked(eventClocked.id).then(
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The breaks have been successfully rejected' }), "success");
            },
            () => {
                this.setState({ isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while rejecting the breaks' }), "warning");
            }
        );
    };

    onConfirmEditEventClocked = (eventClocked: EventClocked, params: NetworkEventClockedSetAdmin) => {
        Network.setAdminEventClocked(eventClocked.id, params).then(
            response => {
                this.removeRawEventClocked(response);
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully updated and confirmed' }), "success");
                this.setState({ displayEditModal: false });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "warning");
            }
        );

    };

    onChangeSelectedEventWithEventClockeds = (keys: number[]) => this.setState({ selectedEventWithEventClockeds: keys.filter(key => this.state.allEventsWithEventClocked.find(e => (e.id == key && (e.clocked?.endDate || e.clocked?.manualEndDate))) !== undefined) });

    getTimeClockStatus = (ec: EventWithEventClocked): TimeClockStatus => {

        if (ec.clocked === undefined)
            return TimeClockStatus.NONE;

        if (ec.clocked.isConfirmed)
            return TimeClockStatus.APPROVED;

        if (ec.clocked.isRefused)
            return TimeClockStatus.REFUSED;

        if (ec.clocked.isManual)
            return TimeClockStatus.MANUALLY_SETTED;

        if (ec.clocked.adminStartDate && ec.clocked.adminEndDate)
            return TimeClockStatus.ADMIN_MODIFIED;

        if (ec.clocked.startDate && ec.clocked.endDate === undefined)
            return TimeClockStatus.STARTED;

        if (ec.fromTimeClock)
            return TimeClockStatus.FROM_TIME_CLOCK;

        if (ec.clocked.startDate && ec.clocked.endDate)
            return TimeClockStatus.FINISHED;


        return TimeClockStatus.NONE;
    };

    renderEventClockedDateTable = (event: EventWithEventClocked, durations: TimeClockEventDuration, forceTimeClockStatus?: TimeClockStatus) => {
        const timeClockStatus = forceTimeClockStatus ?? this.getTimeClockStatus(event);
        switch (timeClockStatus) {
            case TimeClockStatus.NONE:
                return <></>;
            case TimeClockStatus.MANUALLY_SETTED:
                if (event.clocked && event.clocked.manualStartDate && event.clocked.manualEndDate) {
                    let startAndEndDates: StartAndEndDates | undefined;
                    if (event.clocked.calculated) {
                        startAndEndDates = {
                            startDate: moment(event.clocked.calculated.startDate),
                            endDate: moment(event.clocked.calculated.endDate)
                        };
                    } else {
                        throw Error("Legacy code");
                    }

                    return (
                        <Event
                            displayNbBreaktimes
                            key={`badgeevent-running-${event.id}`}
                            event={{
                                ...event,
                                startDate: startAndEndDates.startDate,
                                endDate: startAndEndDates.endDate,
                                color: { title: '', color: event.color },
                                totalHoursBreakTime: durations.badgBreakTimeSeconds ? durations.badgBreakTimeSeconds / 3600.0 : undefined,
                                breakTimes: durations.badgBreakTimes,
                            } as PlanningTemplate}
                            source={EventSource.TIMECONTROL}
                            timeClockStatus={TimeClockStatus.MANUALLY_SETTED}
                            displayIcons
                            displayTimes
                        />
                    );
                } else {
                    return <></>;
                }
            case TimeClockStatus.STARTED:
                if (event.clocked) {
                    let startAndEndDates: StartAndEndDates | undefined;
                    if (event.clocked.calculated) {
                        startAndEndDates = {
                            startDate: moment(event.clocked.calculated.startDate),
                            endDate: moment(event.clocked.calculated.endDate)
                        };
                    } else {
                        throw Error("Legacy code");
                    }
                    return (
                        <Event
                            displayNbBreaktimes
                            key={`badgeevent-running-${event.id}`}
                            event={{
                                ...event,
                                startDate: startAndEndDates.startDate,
                                endDate: startAndEndDates.endDate,
                                color: { title: '', color: event.color },
                                totalHoursBreakTime: durations.badgBreakTimeSeconds ? durations.badgBreakTimeSeconds / 3600.0 : undefined,
                                breakTimes: durations.badgBreakTimes,
                            } as PlanningEvent}
                            source={EventSource.TIMECONTROL}
                            timeClockStatus={TimeClockStatus.STARTED}
                            displayIcons
                            displayTimes
                        />
                    );
                } else {
                    return <></>;
                }
            case TimeClockStatus.FINISHED:
            case TimeClockStatus.PAUSED:
            case TimeClockStatus.ADMIN_MODIFIED:
            case TimeClockStatus.REFUSED:
            case TimeClockStatus.APPROVED:
            default:
                if (event.clocked && event.clocked.endDate) {
                    let startAndEndDates: StartAndEndDates | undefined;
                    if (event.clocked.calculated) {
                        startAndEndDates = {
                            startDate: moment(event.clocked.calculated.startDate),
                            endDate: moment(event.clocked.calculated.endDate)
                        };
                    } else {
                        throw Error("Legacy code")
                    }
                    return (
                        <Event
                            displayNbBreaktimes
                            key={`badgeevent-${event.id}`}
                            event={{
                                ...event,
                                startDate: startAndEndDates.startDate,
                                endDate: startAndEndDates.endDate,
                                color: { title: '', color: event.color },
                                totalHoursBreakTime: durations.badgBreakTimeSeconds ? durations.badgBreakTimeSeconds / 3600.0 : undefined,
                                breakTimes: durations.badgBreakTimes,
                            } as PlanningEvent}
                            source={EventSource.TIMECONTROL}
                            displayIcons
                            displayTimes
                        />
                    );
                } else {
                    return <></>;
                }
        }
    };

    renderEventClockedWarning = (event: EventWithEventClocked) => {
        const timeClockStatus = this.getTimeClockStatus(event);
        switch (timeClockStatus) {
            case TimeClockStatus.NONE:
                return <Anticon><TbClockX style={{ fontSize: 20, color: RED_COLOR }} /></Anticon>;
            case TimeClockStatus.FROM_TIME_CLOCK:
                return <Anticon><MdMoreTime style={{ fontSize: 22, color: '#FF7F50' }} /></Anticon>;
            case TimeClockStatus.MANUALLY_SETTED:
                return (
                    <Popover
                        arrowPointAtCenter={true}
                        content={event.clocked && event.clocked && event.clocked.manualDescription && event.clocked.manualDescription.length > 0 ?
                            `Raison: ${event.clocked?.manualDescription}`
                            : "Aucune raison spécifiée"}
                        placement='bottomRight'
                        trigger={'click'}
                    >
                        <Anticon><TbClockEdit style={{ fontSize: 21, color: YELLOW_COLOR }} /></Anticon>
                    </Popover>
                );
            case TimeClockStatus.STARTED:
                return <Anticon><LuClock12 style={{ fontSize: 18, color: BLUE_COLOR }} className='time-clock-control-running-icon' /></Anticon>;
            case TimeClockStatus.FINISHED:
            case TimeClockStatus.PAUSED:
            case TimeClockStatus.ADMIN_MODIFIED:
            case TimeClockStatus.REFUSED:
            case TimeClockStatus.APPROVED:
            default:
                return <></>;
        }
    };

    getEventClockedStartedOrManual = (event: EventWithEventClocked): EventClockedDates => {
        const clockedBreakTimeDuration = event ? event.breakTimes.reduce((value, breakTimeClocked) => value = value + (breakTimeClocked.isClocked === true && breakTimeClocked.clocked && breakTimeClocked.clocked.isRefused === false && breakTimeClocked.isPaid === false ? breakTimeClocked.totalHours : 0), 0) : 0;


        if (event.clocked)
            if (event.clocked.isManual)
                return {
                    startDate: event.clocked.manualStartDate!,
                    endDate: event.clocked.manualEndDate,
                    breakTimeDuration: clockedBreakTimeDuration
                };
            else
                return {
                    startDate: event.clocked.startDate,
                    endDate: event.clocked.endDate ? event.clocked.endDate : undefined,
                    breakTimeDuration: clockedBreakTimeDuration
                };
        return {};
    };

    getEventClockedDuration = (eventClockedDates: EventClockedDates) => {
        if (eventClockedDates.endDate && eventClockedDates.startDate)
            return moment.duration(eventClockedDates.endDate.diff(eventClockedDates.startDate, "seconds", true), "seconds");

        return undefined;
    };

    getTimeClockEventDuration = (event: EventWithEventClocked) => {
        const { intl } = this.props;
        const errors: string[] = [];
        if (event.calculated) {
            const planDates: EventClockedDates = {
                startDate: event.startDate,
                endDate: event.endDate
            }
            const planEffectiveSeconds = event.calculated.tSWOWNPB;
            const planBreakTimeSeconds = (event.calculated.tSWO - event.calculated.tSWOWNPB);
            const planDuration = moment.duration(event.calculated.tSWO, 'seconds')
            const breakTimes = event.breakTimes.filter((breaktime) => breaktime.active && breaktime.isPaid === false);
            const planBreakTimes = breakTimes.filter((breaktime) => !breaktime.clocked);

            const badgDates: EventClockedDates = {};
            let badgDuration: Duration | undefined = undefined;
            let badgBreakTimeSeconds = 0;
            let badgEffectiveSeconds: number | undefined = undefined;

            let badgBreakTimes = event.breakTimes.filter((breaktime) => breaktime.clocked);

            if (event.clocked?.calculated) {
                badgDates.startDate = moment(event.clocked.calculated.startDate);
                badgDates.endDate = moment(event.clocked.calculated.endDate);

                badgDuration = moment.duration(event.clocked.calculated.tS, "seconds");
                badgBreakTimeSeconds = (event.clocked.calculated.tS - event.clocked.calculated.tSWNPB);
                badgEffectiveSeconds = event.clocked.calculated.tSWNPB;
            }

            if (badgBreakTimes.length === 0) {
                badgBreakTimeSeconds = planBreakTimeSeconds;
                badgBreakTimes = cloneDeep(planBreakTimes);
            }

            if (badgDuration) {
                if (badgDuration.asSeconds() < badgBreakTimeSeconds) {
                    errors.push(intl.formatMessage({ defaultMessage: 'Duration of scheduled event pause' }));
                }
            }

            if (badgDates.startDate && badgDates.endDate && containARangeOutside({ startDate: badgDates.startDate, endDate: badgDates.endDate }, badgBreakTimes.map(b => ({ startDate: b.startDate, endDate: b.endDate })))) {
                errors.push(intl.formatMessage({ defaultMessage: 'Break outside planned event' }));
            }

            if (planDuration) {
                if (planDuration.asSeconds() < planBreakTimeSeconds) {
                    errors.push(intl.formatMessage({ defaultMessage: 'Duration of badge event pause' }));
                }
            }

            if (planDates.startDate && planDates.endDate && containARangeOutside({ startDate: planDates.startDate, endDate: planDates.endDate }, planBreakTimes.map(b => ({ startDate: b.startDate, endDate: b.endDate })))) {
                errors.push(intl.formatMessage({ defaultMessage: 'Break outside the badge event' }));
            }

            let planDurationIsHigher: boolean | undefined = undefined;
            let difference: string | undefined = undefined;
            if (badgEffectiveSeconds !== undefined && planEffectiveSeconds !== undefined) {

                planDurationIsHigher = planEffectiveSeconds > badgEffectiveSeconds;

                difference = momentDurationFormat(moment.duration(badgEffectiveSeconds - planEffectiveSeconds, "seconds"));
            }

            const durations: TimeClockEventDuration = {
                planDates,
                planDuration,
                planBreakTimes,
                planBreakTimeSeconds,
                planEffectiveSeconds,

                badgDates,
                badgDuration,
                badgBreakTimes,
                badgBreakTimeSeconds,
                badgEffectiveSeconds,

                planDurationIsHigher,
                difference,
                errors
            };
            return durations

        } else {
            throw new Error("Legacy code");
        }

    }

    renderEventClockedTotalDuration = (event: EventWithEventClocked) => {
        const durations = this.getTimeClockEventDuration(event);
        const eventStatus = this.getTimeClockStatus(event)

        return (
            <>
                <Row>
                    {
                        durations.errors.length > 0 ?
                            durations.errors.map((e, idx) => (
                                <Col key={`error-${e.toString()}-${idx}`} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                                    <Tag style={{ fontSize: 15, backgroundColor: `${RED_COLOR}11`, borderColor: `${RED_COLOR}bb`, color: `${RED_COLOR}bb`, maxWidth: '100%', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        <span title={e} style={{ textOverflow: 'ellipsis', width: '100%' }}>
                                            {e}
                                        </span>
                                    </Tag>
                                </Col>
                            ))

                            : event.clocked ?
                                eventStatus === TimeClockStatus.STARTED ?
                                    <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                                        <Tag style={{ fontSize: 15, backgroundColor: `${BLUE_COLOR}11`, borderColor: `${BLUE_COLOR}bb`, color: `${BLUE_COLOR}bb`, margin: 0 }}><FormattedMessage defaultMessage={'In progress'} /></Tag>
                                    </Col>
                                    : <Col xs={{ span: 24 }}>
                                        {
                                            durations.badgEffectiveSeconds !== undefined ?
                                                <p style={{ fontSize: 16 }}>{momentDurationFormat(moment.duration(durations.badgEffectiveSeconds, "seconds"))}</p>
                                                : null
                                        }
                                        {
                                            event.fromTimeClock ?
                                                null
                                                : durations.badgEffectiveSeconds !== undefined && durations.planEffectiveSeconds !== undefined ?
                                                    !durations.planDurationIsHigher ?
                                                        <Tag style={{ fontSize: 15, backgroundColor: `${ORANGE_COLOR}11`, borderColor: `${ORANGE_COLOR}cc`, color: `${ORANGE_COLOR}cc`, margin: 0 }}>{durations.difference}</Tag>
                                                        :
                                                        <Tag style={{ fontSize: 15, backgroundColor: `${GREEN_COLOR}11`, borderColor: `${GREEN_COLOR}cc`, color: `${GREEN_COLOR}cc`, margin: 0 }}>{durations.difference}</Tag>
                                                    :
                                                    <Tag style={{ fontSize: 15, backgroundColor: `${RED_COLOR}11`, borderColor: `${RED_COLOR}cc`, color: `${RED_COLOR}cc`, margin: 0 }}><FormattedMessage defaultMessage={'Corrupted data'} /></Tag>

                                        }
                                    </Col>
                                :
                                <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                                    <Tag style={{ fontSize: 15, backgroundColor: `${RED_COLOR}11`, borderColor: `${RED_COLOR}bb`, color: `${RED_COLOR}bb`, margin: 0 }}><FormattedMessage defaultMessage={'Not clocked'} /></Tag>
                                </Col>
                    }
                </Row>
            </>
        );
    };

    renderEventClockedInformations = () => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <p style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'The different states of the timeclock:'} /></p>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><Anticon><TbClockEdit style={{ fontSize: 21, color: YELLOW_COLOR }} /></Anticon><p><FormattedMessage defaultMessage={'Manually clocked event'} /></p></div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><Anticon><MdMoreTime style={{ fontSize: 22, color: '#FF7F50' }} /></Anticon><p><FormattedMessage defaultMessage={'Unplanned clocked event'} /></p></div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><Anticon><TbClockX style={{ fontSize: 20, color: RED_COLOR }} /></Anticon><p><FormattedMessage defaultMessage={'Not clocked event'} /></p></div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'start' }}><Anticon><LuClock12 style={{ fontSize: 18, color: BLUE_COLOR }} className=' time-clock-control-running-icon' /></Anticon><p><FormattedMessage defaultMessage={'Event in progress'} /></p></div>
        </div>
    );

    renderEventCell = (event: EventWithEventClocked) => {
        const isAtLeastOneBreakTime = event.breakTimes.some(breaktime => breaktime.active);
        return (
            <div
                className='timeclock-control-table-event-cell'
                style={{
                    backgroundColor: event.color ?? 'var(--primary-color)',
                    borderColor: event.color ?? 'var(--primary-color)',
                    color: event.color && colorIsBright(event.color) ? "var(--dark-color)" : "white"
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span title={event.title} style={{ textOverflow: "ellipsis", width: '110px', whiteSpace: "nowrap", overflow: "hidden", marginTop: '-3px' }}>{event.title}</span>
                    </div>
                    <div className='timeclock-control-table-event-cell-icons'>
                        <span className='timeclock-control-table-event-cell-icon' style={isAtLeastOneBreakTime ? {} : { display: 'none' }}><RiCupFill className="anticon" title={this.props.intl.formatMessage({ defaultMessage: 'Event with breaks' })} /></span>
                    </div>
                </div>
            </div>
        );
    };

    setEvent = (eventId: number, params: NetworkSetEventParameters) => {
        this.setState({ isButtonsLoading: true });
        Network.setEventClockedData(eventId, params).then(
            (response: { data: NetworkEventWithEventClocked, error: boolean, message: string; }) => {
                const { users } = this.props;
                let filteredEventsWithClockedByDate = this.state.filteredEventsWithClockedByDate;
                let eventsWithEventClockedByDate = this.state.eventsWithEventClockedByDate;

                if (response.data && (response.data.ignoreTimeClock || (response.data.typeOfDay != null && response.data.typeOfDay.canBeBadged === false) || (response.data.typeOfDayOff != null && response.data.typeOfDayOff.canBeBadged === false))) {
                    filteredEventsWithClockedByDate = filteredEventsWithClockedByDate.map(bundled => ({ date: bundled.date, events: bundled.events.filter(e => e.id !== eventId) }));
                    eventsWithEventClockedByDate = eventsWithEventClockedByDate.map(bundled => ({ date: bundled.date, events: bundled.events.filter(e => e.id !== eventId) }));
                } else if (response.data) {
                    filteredEventsWithClockedByDate = filteredEventsWithClockedByDate.map(bundled => ({ date: bundled.date, events: bundled.events.map(e => e.id === eventId ? convertNetworkEventWithEventClockedToEventWithEventClocked(response.data, users.find(u => u.id == e.user.id)) : e) }));
                    eventsWithEventClockedByDate = eventsWithEventClockedByDate.map(bundled => ({ date: bundled.date, events: bundled.events.filter(e => e.id !== eventId) }));
                }
                this.setState({ filteredEventsWithClockedByDate, eventsWithEventClockedByDate, isButtonsLoading: false });
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The event has been successfully updated' }), "success");
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the event' }), "warning");
                this.setState({ isButtonsLoading: false });
            }
        );
    };

    onReplaceByTemplate = (eventId: number, templateId: number) => {
        this.setEvent(eventId, { templateId });
    };

    changeFilters = (values: number[]) => {
        this.setState({ selectedFilters: values.length === 0 ? [] : values }, this.filterEventsWithClocked);
    };

    filterEventsWithClocked = () => {
        const { eventsWithEventClockedByDate, selectedFilters } = this.state;

        const filteredEventsWithClockedByDate = cloneDeep(eventsWithEventClockedByDate);


        if (selectedFilters.length !== 0) {
            filteredEventsWithClockedByDate.forEach(date => {
                date.events = [];
            });

            eventsWithEventClockedByDate.forEach(ewcByDate => {
                ewcByDate.events.forEach(event => {
                    let alreadyAdded = false;
                    if (!event.clocked?.isConfirmed && !event.clocked?.isRefused) {
                        if (selectedFilters.includes(TimeClockControlFiltersCheck.RUNNING)) {
                            if (event.clocked?.startDate && !event.clocked.endDate && !event.clocked.isManual) {
                                filteredEventsWithClockedByDate.find(e => e.date.isSame(ewcByDate.date, 'day'))?.events.push(event);
                                alreadyAdded = true;
                            }
                        }

                        if (!alreadyAdded && selectedFilters.includes(TimeClockControlFiltersCheck.WAITING)) {
                            if ((event.clocked?.startDate !== undefined && event.clocked?.endDate !== undefined)) {
                                filteredEventsWithClockedByDate.find(e => e.date.isSame(ewcByDate.date, 'day'))?.events.push(event);
                                alreadyAdded = true;
                            }
                        }

                        if (!alreadyAdded && selectedFilters.includes(TimeClockControlFiltersCheck.NOTCLOCKED)) {
                            if ((!event.clocked)) {
                                filteredEventsWithClockedByDate.find(e => e.date.isSame(ewcByDate.date, 'day'))?.events.push(event);
                                alreadyAdded = true;
                            }
                        }
                    }
                });
            });
        }

        this.setState({ filteredEventsWithClockedByDate });
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedEventWithEventClockeds: keys as number[] });

    TimeClockControlFiltersOptions = [
        {
            id: 1,
            name: this.props.intl.formatMessage({ defaultMessage: 'In progress' })
        },
        {
            id: 2,
            name: this.props.intl.formatMessage({ defaultMessage: 'Pending' })
        },
        {
            id: 3,
            name: this.props.intl.formatMessage({ defaultMessage: 'Not clocked' })
        }
    ];

    render() {
        const { eventsWithEventClockedByDate, selectedFilters, selectedEventWithEventClockeds, display, displayEditModal, selectedEventWithEventClocked, filteredEventsWithClockedByDate } = this.state;
        const { isSmartphone, intl } = this.props;
        const selectedDay = this.state.selectedDay.clone();

        const today = filteredEventsWithClockedByDate.find(e => e.date.isSame(selectedDay, 'day'));

        const eventsWithEventClocked: EventWithEventClocked[] = (!isSmartphone && display === 'day') ? today ? today.events : [] : filteredEventsWithClockedByDate.reduce((i: EventWithEventClocked[], eventByDay) => i = [...i, ...eventByDay.events], []);

        const TimeControlEventClockedsColumns: ColumnsType<EventWithEventClocked> = [
            {
                title: <FormattedMessage defaultMessage={'Employee'} />,
                key: 'employee',
                fixed: isSmartphone ? undefined : 'left',
                onFilter: (value, record) => {
                    return record.user.name!
                        .toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase());
                },
                ...dashboardFilters<EventWithEventClocked>(!this.props.isSmartphone, uniqBy(this.state.allEventsWithEventClocked.map(aec => ({ text: aec.user.name!, value: aec.user.name! })).sort((a, b) => a.text.localeCompare(b.text)), 'text')),
                sorter: (a: EventWithEventClocked, b: EventWithEventClocked) => !a.user.name ? -1 : !b.user.name ? 1 : a.user.name < b.user.name ? -1 : 1,
                className: isSmartphone ? '__min-width-150' : '__min-width-300',
                render: (event: EventWithEventClocked) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {isSmartphone ? null : <Avatar key={`avatar-object-${event.user.id}`} size={50} icon={<UserOutlined />} src={`${event.user.avatar != null ? event.user.avatar : ''}`} style={{ backgroundColor: 'var(--primary-color)' }} />}
                        <p title={event.user.name} style={{ maxWidth: `calc(${isSmartphone ? 150 : 300}px - 20px)`, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{event.user.name}</p>
                    </div >
                )
            },
            ...(!(display === 'day') || isSmartphone ?
                [
                    {
                        title: <FormattedMessage defaultMessage={'Date'} />,
                        className: `timeclock-control-table-fixed-even ${isSmartphone ? '__width_120' : '__width_160'}`,
                        key: 'date',
                        onFilter: (value: any, record: any) => {
                            return record.title!
                                .toString()
                                .toLowerCase()
                                .includes(value.toString().toLowerCase());
                        },
                        ...dashboardFilters<EventWithEventClocked>(!this.props.isSmartphone, uniqBy(this.state.allEventsWithEventClocked.map(aec => ({ text: aec.title, value: aec.title })).sort((a, b) => a.text.localeCompare(b.text)), 'text')),
                        sorter: (a: EventWithEventClocked, b: EventWithEventClocked) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,
                        render: (event: EventWithEventClocked) => {
                            const dateFormat = isSmartphone ? getFormat('DATE_SHORT') : getFormat('DATE');
                            return (
                                <Container style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }}>
                                            {moment(event.startDate).format(dateFormat)}
                                        </Col>
                                    </Row>
                                </Container>
                            );
                        }
                    }
                ]
                :
                []
            ),
            {
                title: (
                    <div className='timeclock-control-plan-badge'>
                        <span><FormattedMessage defaultMessage={'Planned'} /></span>
                        <span><Anticon><RiArrowRightDoubleLine /></Anticon></span>
                        <span><FormattedMessage defaultMessage={'Clocked'} /></span>
                    </div>
                ),
                className: '__width_400 __centered-text',
                key: 'event',
                onFilter: (value, record) => {
                    return record.title!
                        .toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase());
                },
                render: (event: EventWithEventClocked) => {
                    const newEvent = cloneDeep(event);

                    const durations = this.getTimeClockEventDuration(newEvent);


                    return (
                        <Tooltip open={event.fromTimeClock ? false : undefined} title={event.title} mouseEnterDelay={0.8} mouseLeaveDelay={0} placement='left'>
                            <div className='timeclock-control-plan-badge'>
                                {
                                    event.fromTimeClock ?
                                        <span></span>
                                        :
                                        <Event
                                            displayNbBreaktimes
                                            key={`planevent-${event.id}`}
                                            timeClockStatus={[TimeClockStatus.FINISHED, TimeClockStatus.MANUALLY_SETTED].includes(this.getTimeClockStatus(event)) ? TimeClockStatus.FINISHED : undefined}
                                            event={{
                                                ...event,
                                                color: { title: '', color: event.color },
                                                totalHoursBreakTime: durations.planBreakTimeSeconds ? durations.planBreakTimeSeconds / 3600.0 : undefined,
                                                breakTimes: durations.planBreakTimes
                                            } as PlanningEvent}
                                            source={EventSource.TIMECONTROL}
                                            displayIcons
                                            displayTimes />
                                }
                                {
                                    event.fromTimeClock || this.getTimeClockStatus(event) === TimeClockStatus.NONE ?
                                        <span></span>
                                        :
                                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Anticon><RiArrowRightDoubleLine /></Anticon></span>
                                }
                                <Tooltip open={event.fromTimeClock ? undefined : false} title={newEvent.title} mouseEnterDelay={0.8} mouseLeaveDelay={0} placement='left'>
                                    <div>
                                        {this.renderEventClockedDateTable(newEvent, durations)}
                                    </div>
                                </Tooltip>
                            </div>
                        </Tooltip>
                    );
                }
            },
            {
                title: (
                    <span><FormattedMessage defaultMessage={'Duration'} />
                        <Tooltip title={
                            <div>
                                <p><FormattedMessage defaultMessage={'Event duration'} /></p>
                                <p><FormattedMessage defaultMessage={'The time difference is displayed below the planned time:'} /></p>
                                <p><FormattedMessage defaultMessage={' - Green: shorter duration'} /></p>
                                <p><FormattedMessage defaultMessage={' - Red: longer duration'} /></p>
                                <p><FormattedMessage defaultMessage={' - Blue: Timeclock in progress'} /></p>
                            </div>
                        }>
                            <InfoCircleOutlined className="tab-tooltip-info" />
                        </Tooltip>
                    </span>
                ),
                sorter: (a: EventWithEventClocked, b: EventWithEventClocked) => {
                    const aStartDate = a.clocked?.isManual ? a.clocked.manualStartDate : a.startDate;
                    const bStartDate = b.clocked?.isManual ? b.clocked.manualStartDate : b.startDate;
                    return !aStartDate ? -1 : !bStartDate ? 1 : aStartDate < bStartDate ? -1 : 1;
                },
                key: 'totalDuration',
                className: '__width_150 __centered-text',
                render: (event: EventWithEventClocked) => this.renderEventClockedTotalDuration(event)

            },
            {
                title: (
                    <Popover content={this.renderEventClockedInformations}>
                        <QuestionCircleOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
                    </Popover>
                ),
                key: 'warning',
                className: '__width_50',
                align: 'center',
                render: (event: EventWithEventClocked) => this.renderEventClockedWarning(event)
            },
            {
                title: <FormattedMessage defaultMessage={'Actions'} />,
                key: 'modifier',
                className: `${isSmartphone ? '__width_100' : '__width_260'} __centered-text`,
                render: (event: EventWithEventClocked) => {
                    const { editableEventIds } = this.state;
                    const canEdit = (!event.clocked?.endDate && !event.clocked?.manualEndDate);
                    const showActions = editableEventIds.find(i => i == event.id) !== undefined;

                    const confirmButtons = [
                        <CircleButton
                            withoutTooltip={true}
                            key={`modifier-circlebutton-object-${event.id}-confirm`}
                            icon={<CheckOutlined style={{ fontSize: '15px' }} />}
                            loading={this.state.isButtonsLoading}
                            disabled={canEdit}
                            title={intl.formatMessage({ defaultMessage: 'Accept' })}
                            onClick={() => event.clocked ? this.onConfirmEventClocked(event.clocked.id) : null}
                        />,
                        <CircleButton
                            withoutTooltip
                            key={`modifier-circlebutton-object-${event.id}-refuse`}
                            icon={<CloseOutlined style={{ fontSize: '15px' }} />}
                            loading={this.state.isButtonsLoading}
                            disabled={canEdit}
                            title={intl.formatMessage({ defaultMessage: 'Reject' })}
                            onClick={() => event.clocked ? this.onRejectEventClocked(event.clocked) : null}
                        />,
                    ];

                    const hasEventTimeClockData = this.getTimeClockStatus(event) !== TimeClockStatus.NONE;

                    const otherActions = [

                        <ReplaceByTemplate loading={this.state.isButtonsLoading} key={`replace-template-${event.id}-component`} event={event} onDone={(templateId) => this.onReplaceByTemplate(event.id, templateId)} />,
                        <Popconfirm
                            placement='topLeft'
                            // open={hasEventTimeClockData ? undefined : false}
                            key={`modifier-circlebutton-ignore-object-${event.id}-edit`}
                            onConfirm={() => hasEventTimeClockData && !this.state.isButtonsLoading && this.setEvent(event.id, { ignoreTimeClock: true })}
                            disabled={!hasEventTimeClockData || this.state.isButtonsLoading}
                            title={
                                <>
                                    <p><FormattedMessage defaultMessage={'There is timeclock data for this event.'} /></p>
                                    <p style={{ whiteSpace: 'nowrap' }}><FormattedMessage defaultMessage={'Do you really want to ignore it?'} /></p>
                                </>
                            }
                            okText={<FormattedMessage defaultMessage={'Ignore'} />}
                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                        >
                            <CircleButton
                                type='dashed'
                                withoutTooltip
                                icon={<Anticon icon={<BsEyeSlash style={{ opacity: 0.9 }} />} />}
                                loading={this.state.isButtonsLoading}
                                title={intl.formatMessage({ defaultMessage: 'Ignore' })}
                                onClick={() => !hasEventTimeClockData && !this.state.isButtonsLoading && this.setEvent(event.id, { ignoreTimeClock: true })}
                            />
                        </Popconfirm>,
                        <CircleButton
                            type='dashed'
                            withoutTooltip
                            disabled={!event.clocked}
                            key={`modifier-circlebutton-object-${event.id}-edit`}
                            icon={<Anticon icon={<TfiPencil style={{ opacity: 0.9 }} />} />}
                            loading={this.state.isButtonsLoading}
                            title={intl.formatMessage({ defaultMessage: 'Edit' })}
                            onClick={() => this.setState({ displayEditModal: true, selectedEventClocked: event, selectedEventWithEventClocked: event })}
                        />
                    ];
                    const dialButtons = isSmartphone ?
                        [...confirmButtons, ...otherActions]
                        :
                        [
                            ...confirmButtons,
                            <div key={'divider-main-actions'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                            </div>,
                            ...otherActions
                        ];

                    return (
                        showActions ?
                            isSmartphone ?
                                <SpeedDial
                                    title={"Plus d'actions"}
                                    icon={<MoreOutlined />}
                                    openIcon={<DashOutlined />}
                                    buttons={dialButtons} />
                                :
                                <div style={{ display: 'inline-grid', gridTemplateColumns: '1fr 1fr 10px 1fr 1fr 1fr', gridColumnGap: '3px' }} key={`modifier-div-object-${event.id}`}>
                                    {dialButtons}
                                </div>
                            : <></>
                    );
                }
            }
        ];
        let tableHeight = this.props.height - 236;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <>
                <ControlTime
                    tableRender={
                        <Table
                            loading={this.state.isEventClockedsLoading === 'loading'}
                            className='__time-clock-table'
                            size='small'
                            rowKey={(i: EventWithEventClocked) => i.id.toString()}
                            rowSelection={isSmartphone ? undefined :
                                {
                                    type: "checkbox",
                                    selectedRowKeys: selectedEventWithEventClockeds,
                                    onChange: this.onChangeSelectedUsers,
                                    getCheckboxProps: (record: EventWithEventClocked) => ({
                                        disabled: this.state.editableEventIds.filter(key => this.state.allEventsWithEventClocked.find(e => (e.id == record.id && e.id === key && (e.clocked?.endDate || e.clocked?.manualEndDate))) !== undefined).length <= 0,
                                        name: record.id.toString(),
                                    }),
                                    fixed: true,
                                }
                            }
                            dataSource={eventsWithEventClocked}
                            scroll={{ x: true, y: tableHeight }}
                            columns={TimeControlEventClockedsColumns}
                            pagination={false}
                        />
                    }
                    isEmpty={eventsWithEventClocked && eventsWithEventClocked.length == 0}
                    isEmptyText={intl.formatMessage({ defaultMessage: 'There are no events left to process' })}
                    display={this.state.display}
                    loading={this.state.isEventClockedsLoading}
                    filtersRender={
                        <Select
                            mode="multiple"
                            className="__filter-limit-size"
                            allowClear
                            placeholder={<FormattedMessage defaultMessage={'Filters'} />}
                            onChange={(e) => this.changeFilters(e)}
                            value={selectedFilters}
                            style={{ width: isSmartphone ? '100%' : '250px' }}
                            filterOption
                            optionFilterProp='label'
                            showArrow
                            disabled={this.state.isEventClockedsLoading === 'idle'}
                            loading={this.state.isEventClockedsLoading === 'loading'}
                            maxTagCount={'responsive'} >
                            {
                                this.TimeClockControlFiltersOptions.map((f) => {
                                    return <Select.Option label={f.name} value={f.id} key={'filters-' + f.id}>{f.name}</Select.Option>;
                                })
                            }
                        </Select>
                    }
                    headerRender={
                        selectedEventWithEventClockeds.length > 0 ?
                            <Space wrap>
                                <Popconfirm
                                    disabled={selectedEventWithEventClockeds.length === 0}
                                    key="eventclocked-popup-button-confirm"
                                    title={<FormattedMessage defaultMessage={'{count, plural, one {1 event is about to be accepted. Would you like to continue?} other {{count} events are about to be accepted. Would you like to continue?}}'} values={{ count: selectedEventWithEventClockeds.length }} />}
                                    okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                    icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                    placement={"top"}
                                    onConfirm={() => this.onConfirmEventClockeds(this.state.selectedEventWithEventClockeds)}
                                >
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Accept ({count})' }, { count: selectedEventWithEventClockeds.length })}
                                        loading={this.state.isButtonsLoading}
                                        disabled={selectedEventWithEventClockeds.length === 0}
                                        icon={<CheckOutlined style={{ fontSize: '15px' }} />}
                                    />
                                </Popconfirm>
                                <Popconfirm
                                    disabled={selectedEventWithEventClockeds.length === 0}
                                    key="eventclocked-popup-button-reject"
                                    title={<FormattedMessage defaultMessage={'{count, plural, one {1 event is about to be rejected. Would you like to continue?} other {{count} events are about to be rejected. Would you like to continue?}}'} values={{ count: selectedEventWithEventClockeds.length }} />}
                                    okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                    icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                    placement={"top"}
                                    onConfirm={() => this.onRejectEventClockeds(this.state.selectedEventWithEventClockeds)}
                                >
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Reject ({count})' }, { count: selectedEventWithEventClockeds.length })}
                                        loading={this.state.isButtonsLoading}
                                        disabled={selectedEventWithEventClockeds.length === 0}
                                        icon={<CloseOutlined style={{ fontSize: '15px' }} />}
                                    />
                                </Popconfirm>
                            </Space>
                            : undefined

                    }
                    dataByDate={
                        (filteredEventsWithClockedByDate ?? eventsWithEventClockedByDate).map(eWECByDate => ({
                            date: eWECByDate.date,
                            isConfirmedNr: eWECByDate.events.reduce((e, v) => e = e + (v.clocked?.isConfirmed ? 1 : 0), 0),
                            objectsNr: (!filteredEventsWithClockedByDate) ? eWECByDate.events.length : eventsWithEventClockedByDate.reduce((e, febd) => e = (febd.date === eWECByDate.date) ? febd.events.length : e, 0),
                            filteredObjectsNr: (filteredEventsWithClockedByDate) ? filteredEventsWithClockedByDate.reduce((e, febd) => e = (febd.date === eWECByDate.date) ? febd.events.length : e, 0) : eWECByDate.events.length
                        }))
                    }
                    changeDate={(startDate, endDate) => this.setState({ selectedDates: { startDate: startDate, endDate: endDate } })}
                    changeDisplay={(display) => this.setState({ display })}
                    changeSelectedDay={(selectedDay) => this.setState({ selectedDay })}
                    selectedDay={selectedDay}
                    icon={<TbClockCheck className='anticon' />}
                    refresh={this.getEventClockeds}
                    displayTypes={[
                        {
                            value: 'daily',
                            label: <FormattedMessage defaultMessage={'Daily'} />
                        },
                        {
                            value: 'weekly',
                            label: <FormattedMessage defaultMessage={'Weekly'} />
                        },
                        {
                            value: 'monthly',
                            label: <FormattedMessage defaultMessage={'Monthly'} />
                        },
                    ]}
                />
                {
                    displayEditModal && selectedEventWithEventClocked?.clocked ?
                        <EditEventClockedModal
                            currentStep={'breaks'}
                            selectedEventId={selectedEventWithEventClocked.id}
                            onCancel={() => this.setState({ displayEditModal: false })}
                            onEdit={this.onConfirmEditEventClocked}
                        />
                        : null
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    windowWidth: state.window.width,
    height: state.window.height,
    users: state.teamManagement.users,
    usersLoading: state.teamManagement.usersLoading,
    groups: state.teamManagement.groups,
    company: state.user.company,
    currentUser: state.user.currentUser,
    templates: state.planning.templates,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(TimeClockControl)));
