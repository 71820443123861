import CSS from 'csstype';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML, DraftEditorCommand, Editor, EditorState, RichUtils } from 'draft-js';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CodeDiscount } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import InputField from '../../common/fields/inputField';

export enum MailPreviewType { Birthday, Librairy }

interface Props {
    type?: MailPreviewType;
    title?: string;
    content?: string;
    signature?: string;
    name: string | undefined;
    codeDiscount?: CodeDiscount;
    modifyTitle?: (value: string) => void;
    modifyContent?: (value: string) => void;
}

interface State {
    editorState: EditorState;
}
const crmMailSection: CSS.Properties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px",
    flexDirection: "column"
};

const crmMailLogo = {
    width: "120px",
    height: "120px",
}

const crmMailImage = {
    width: "100%",
}

class MailPreview extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty(),
        };
    }

    componentDidMount() {
        // this.refreshContent();
        this.startEditor();
    }

    componentDidUpdate() {
        if (this.props.content !== convertToHTML(this.state.editorState.getCurrentContent())) {
            this.startEditor();
        }

    }

    modifyTitle = (value: string) => {
        const { modifyTitle } = this.props;
        modifyTitle && modifyTitle(value);

    }

    modifyContent = (value: string) => {
        const { modifyContent } = this.props;

        modifyContent && modifyContent(value);
    }

    editorChange = (e: EditorState): void => {
        this.modifyContent(convertToHTML(e.getCurrentContent()))
        this.setState({ editorState: e });
    }

    handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.editorChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    startEditor = () => {
        const { content } = this.props;
        if (content) {
            const blocksFromHTML = convertFromHTML(content);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            this.setState({ editorState: EditorState.createWithContent(state) });
        }
        // this.setState({ editCompanyProduct: true, companyProductToEdit: this.state.companyProductToShow ? { ...this.state.companyProductToShow } : undefined })
    }

    getContent = () => {
        switch (this.props.type) {
            case MailPreviewType.Birthday:
                return (
                    <div style={crmMailSection}>
                        <span style={{ fontSize: '20px', marginBottom: '30px' }}><FormattedMessage defaultMessage={'Happy birthday'} /></span>
                        {
                            <span><FormattedMessage defaultMessage={'To celebrate your birthday, we’re offering you a discount voucher that can be used on your next order at'} /> <a href="https://swisskischool.ch">https://swisskischool.ch</a></span>
                        }
                    </div>
                );
            case MailPreviewType.Librairy:
                return (
                    <div style={crmMailSection}>
                        <span style={{ fontSize: '20px', marginBottom: '30px' }}><FormattedMessage defaultMessage={'Photo library'} /></span>
                        {
                            <span><FormattedMessage defaultMessage={'Don’t forget to check your photo library. You can find it in the account area.'} /> <a href="https://swisskischool.ch/fr/account/photos">https://swisskischool.ch/fr/account/photos</a></span>
                        }
                    </div>
                );
            default: {
                const { title, content } = this.props;
                return (
                    <div style={crmMailSection}>
                        <InputField maxLength={50} className="__mail-preview-title-input" value={title} onChange={(e) => {
                            this.modifyTitle(e.target.value);
                        }} />
                        {/* <span style={{ fontSize: '20px', marginBottom: '30px' }}>{title !== undefined ? title : "You recieved a new message."}</span> */}
                        {
                            content !== undefined ?
                                <div style={{ width: "100%" }}>
                                    {
                                        <Editor
                                            editorState={this.state.editorState}
                                            onChange={(e) => this.editorChange(e)}
                                            handleKeyCommand={this.handleKeyCommand}
                                        />

                                    }
                                </div>
                                :
                                <span><FormattedMessage defaultMessage={'You can consult your message in your Swisskischool profile:'} /> <a href="https://swisskischool.ch/fr/account/messages">https://swisskischool.ch/fr/account/messages</a></span>
                        }
                    </div>
                );
            }
        }
    }

    render() {
        const { signature, codeDiscount, name } = this.props;
        const parsedName = name?.toLowerCase();

        return (
            <div style={{ maxWidth: ' 800px', margin: 'auto', textAlign: 'center', lineHeight: '30px' }}>
                {
                    parsedName &&
                    <div style={crmMailSection}>
                        <img alt='' style={crmMailLogo} src={`https://storage.googleapis.com/snowpros/images/mail/${parsedName}.png`} />
                    </div>
                }
                {
                    this.getContent()
                }
                {
                    codeDiscount ?
                        <div style={{ cursor: 'default' }}>
                            <div style={{ width: '80%', margin: 'auto', paddingTop: '20px', paddingBottom: '20px', border: '1px solid #b1b1b1', fontWeight: 'bold' }}>
                                {
                                    codeDiscount.code
                                }
                            </div>
                        </div>
                        : null
                }
                {
                    parsedName &&
                    <div style={crmMailSection}>
                        <img alt='' style={crmMailImage} src={`https://storage.googleapis.com/snowpros/images/mail/mail-${parsedName}.jpg`} />
                    </div>
                }
                {
                    signature &&
                    <div style={crmMailSection}>
                        <p>{"----------------------------------------"}</p>
                        {
                            signature.split("\n").map((l, i) => <p key={`crm-mail-preview-signature-${i}`}>{l}</p>)
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    name: state.crm.companyDetails?.name,
    signature: state.crm.signature,
});

export default connect(mapStateToProps)(MailPreview);