import { Button, Col, Modal, Row, Spin } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Network from '../../../utils/network';
import { UserPin } from "../../../utils/types/generalTypes";
import { showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";

interface Props extends IntlProps {
    onClose: (refresh: boolean) => void;
    userId: number;
    refresh: (message: string) => void
}

interface State {
    loading: boolean;
    userPin?: UserPin;
    generatedAt?: Moment

}

class DisplayPinModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            userPin: undefined,
            generatedAt: undefined
        }
    }
    componentDidMount(): void {
        this.getOrGenUserPin()
    }

    getOrGenUserPin = (force = false) => {
        // get all timeclocked events
        if (force || !this.state.loading) {
            this.setState({ loading: true }, () => {
                Network.userPinTimeClock(this.props.userId).then(
                    response => {
                        const userPin: UserPin = {
                            pin: response.pin,
                            created: response.created,
                            modified: response.modified
                        }
                        this.setState({ loading: false, userPin, generatedAt: moment() });
                        this.props.refresh(this.props.intl.formatMessage({ defaultMessage: 'The display/generation of PIN has been successfully completed' }))
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while retrieving the PIN' }), "warning");
                        this.setState({ loading: false });
                    }
                );
            })

        }

    }

    render() {
        const { onClose } = this.props
        const { userPin, loading: initLoading } = this.state
        const pin = userPin ? Array.from(String(userPin.pin), Number) : [];

        return (
            <Modal
                style={{ top: '50px' }}
                open={true}
                width={'50%'}
                className={'init-device-modal'}
                title={<FormattedMessage defaultMessage={'Connecting a timeclock'} />}
                onCancel={() => onClose(false)}
                destroyOnClose={true}
                footer={[
                    <></>,
                    <></>,
                    <Button
                        key='details-modal-cancel'
                        type='dashed'
                        onClick={() => onClose(false)}>
                        <FormattedMessage defaultMessage={'Close'} />
                    </Button>
                ]}>
                <Spin spinning={initLoading}>
                    <Row gutter={[50, 10]} style={{ gap: 10 }}>
                        <Col key={`map-pin-number-first}`} xs={{ span: 2 }} md={{ span: 2 }}></Col>
                        {pin.map((pin_number, k) => <Col className="map-pin-number-case" key={`map-pin-number-${pin_number}-${k}`} xs={{ span: 3 }} md={{ span: 3 }}>{pin_number}</Col>)}
                        <Col key={`map-pin-number-first}`} xs={{ span: 3 }} md={{ span: 3 }}></Col>
                    </Row>
                </Spin>
            </Modal>
        )
    }
}

export default injectIntl(DisplayPinModal);