import { Component } from 'react';

export interface ContainerTabsItemProps {
    addOrUpdateExtra: (actions: React.ReactNode, key: string) => void;
    addOrUpdateFilters: (filters: React.ReactNode, key: string) => void;
    keyLink: string
}

export abstract class ContainerTabsItem<Props extends ContainerTabsItemProps, State> extends Component<Props, State> {
    abstract getExtra(): React.ReactNode;
    abstract getFilters(): React.ReactNode;
}

export default ContainerTabsItem;