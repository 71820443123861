import { BsCalendar2Check } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "../../common/general/card";

export const DownloadApp = () => {
    const intl = useIntl();
    return (
        <Card
            className="dashboard-card"
            title={<FormattedMessage defaultMessage={'Download application'} />}
            icon={<BsCalendar2Check className="anticon" />}
        >
            {/* <div style={{ position: 'relative' }}>
                <Availabilities {...this.props} theme={themeDefault} user={this.props.currentUser} />
            </div> */}
            <p><FormattedMessage defaultMessage={'Please download the new version of the mobile application to enter availability.'} /></p>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <a title={intl.formatMessage({ defaultMessage: 'Download for iOS' })} href='https://apps.apple.com/ch/app/sunkhronos-business/id6447674238' target={'_blank'} rel="noreferrer">
                    <img style={{ cursor: 'pointer', width: '256px' }} src="https://storage.googleapis.com/sunkhronos/download-on-apple.png" alt={intl.formatMessage({ defaultMessage: 'Download for iOS' })} />
                </a>
                <a title={intl.formatMessage({ defaultMessage: 'Download for Android' })} href='https://play.google.com/store/apps/details?id=ch.sunkhronos.business' target={'_blank'} rel="noreferrer">
                    <img style={{ cursor: 'pointer', width: '256px' }} src="https://storage.googleapis.com/sunkhronos/download-on-android.png" alt={intl.formatMessage({ defaultMessage: 'Download for Android' })} />
                </a>
            </div>
        </Card>

    );
}
export default DownloadApp