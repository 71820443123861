import { CheckCircleOutlined, DeleteOutlined, EditOutlined, GlobalOutlined, InfoCircleOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Modal, Popover } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { BiStopwatch } from 'react-icons/bi';
import { RiCupLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { changeDraggedGroupEventUserId, changeDraggedUserEventUserId } from '../../store/actions/planning';
import getFormat from '../../utils/Lang';
import { CellEvent, PlanningEvent, Project } from '../../utils/types/planningTypes';
import { ApplicationState, PlanningDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { checkRBACRule, colorIsBright, lighter } from '../../utils/utils';
import CircleButton from '../common/fields/circleButton';
import Can from '../common/general/can';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    userId?: number;
    cellEvent: CellEvent;
    currentProjects: Project[] | undefined;
    // Redux
    cellsPerRow?: number;
    onClick?: (event: PlanningEvent) => void;
    onEdit?: (event: PlanningEvent, editOccurence?: boolean) => void;
    onDelete?: (event: PlanningEvent, deleteOccurence?: boolean) => void;
}

type Props = IProps & PlanningDispatchProps & ReduxProps;

interface State {
    isDragging: boolean;
}

/**
 * Component that represent an event
 * This component is as a function because it needs Hooks to work with DnD
 */
class Event extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDragging: false,
        };
    }

    /**
     * Get the event width in px
     * @return the event width
     */
    getEventWidth = (): number => {
        const { cellEvent, cellsPerRow } = this.props;
        const { startCell } = this.props.cellEvent;

        const width = this.props.windowWidth > 2000 ? 65 : (this.props.windowWidth > 1000 ? 55 : 45);

        const endOfRange = Math.floor(startCell / cellsPerRow!) * this.props.cellsPerRow! + (cellsPerRow! - 1)
        const toEndOfDay = endOfRange - cellEvent.startCell; //0
        const toEndOfEvent = cellEvent.endCell - cellEvent.startCell; //1

        //if the end of event is greater than the end of day (24:00) cut the event so it won't go beyond the calendar
        //a cell is 40px + 30px so the event arrive at the end of 30min time
        const result = (toEndOfEvent > toEndOfDay ? toEndOfDay : toEndOfEvent);

        return (result < 0 ? 1 : result) * width + Math.ceil(width * 0.8);
    }

    /**
     * Get the event's border radius
     * The radius depend on weather the event start and end on the same day (row)
     */
    getEventBorderRadius = (): string => {
        const { isStart, isEnd } = this.props.cellEvent;

        if (isStart && isEnd) return "5px";
        else if (isStart && !isEnd) return "5px 0px 0px 5px";
        else if (!isStart && isEnd) return "0px 5px 5px 0px";
        else return "0px";
    }

    /**
     * Called when the user click on the event
     */
    onClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        this.props.onClick!(this.props.cellEvent.event);
    }

    /**
     * Handle the edition process of the event
     */
    onEdit = (): void => {
        const { event } = this.props.cellEvent;
        this.props.onEdit!(event);
    }

    /**
     * Handle the deletion process of the event
     */
    onDelete = (): void => {
        const { event } = this.props.cellEvent;
        const modal = Modal.info({});
        modal.update({
            title: <span className="flex-center"><InfoCircleOutlined style={{ color: 'var(--primary-color)', marginRight: '20px' }} /><p><FormattedMessage defaultMessage={'Are you sure you want to delete {title}?'} values={{ title: event.title }} /></p></span>,
            content: (
                <div className='planning-info-modal'>
                    <Button danger onClick={() => { this.props.onDelete!(event); modal.destroy(); }}><FormattedMessage defaultMessage={'Delete'} /></Button>
                    <Button onClick={() => modal.destroy()}><FormattedMessage defaultMessage={'Cancel'} /></Button>
                </div>
            ),
            okButtonProps: { style: { display: 'none' } },
            maskClosable: true,
            icon: null,
            centered: true,
        })
    }

    /**
     * Called when the event start being dragged
     * @param e the triggered drag event
     */
    onDragStart = (e: React.DragEvent) => {
        this.setState({ isDragging: true });

        const { cellEvent, userId } = this.props;
        const { event } = cellEvent;

        // change the stored user id according to the event owner
        if (event.groupId || event.isGlobal) {
            this.props.changeDraggedGroupEventUserId!(userId);
        } else {
            this.props.changeDraggedUserEventUserId!(userId);
        }

        e.dataTransfer.setData('cellEvent', JSON.stringify(this.props.cellEvent));
    }

    /**
     * Called when the event stop being dragged
     */
    onDragEnd = () => {
        this.setState({ isDragging: false });
        setTimeout(() => {
            this.props.changeDraggedUserEventUserId!(undefined);
            this.props.changeDraggedGroupEventUserId!(undefined);
        }, 10);
    }

    /**
     * Get the summary popover
     * @returns the summary component
     */
    summary = (): React.ReactNode => {
        const { event } = this.props.cellEvent;
        // set color
        const color = event.color ? (colorIsBright(event.color.color) ? "var(--primary-color)" : event.color.color) : "var(--primary-color)";

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <div className="planning-event-summary-header">
                    <span className="planning-event-summary-flex" style={{ marginRight: '20px' }}>
                        <div className="planning-event-summary-dot" style={{ backgroundColor: color }} />
                        <p style={{ fontSize: '17px', marginRight: '10px' }}>{event.title}</p>
                        {event.userId && <UserOutlined style={{ color }} />}
                        {event.groupId && <TeamOutlined style={{ color }} />}
                        {event.isGlobal && <GlobalOutlined style={{ color }} />}
                        {event.confirmed && <CheckCircleOutlined style={{ color, marginLeft: '10px' }} />}
                    </span>
                    <Can
                        rule={Rules.Planning.Management}
                        privilege={Privileges.Planning.Edition}
                        privilegeCondition={event.userId !== undefined && this.props.currentUserId === event.userId}>
                        <span className="planning-event-summary-flex">
                            <CircleButton style={{ marginRight: '10px' }} title={"Modifier"} icon={<EditOutlined />} onClick={this.onEdit} />
                            <CircleButton title={"Supprimer"} icon={<DeleteOutlined />} onClick={this.onDelete} />
                        </span>
                    </Can>
                </div>
                <div className="planning-event-summary-flex" style={{ marginLeft: '20px' }}>
                    <span className="planning-event-summary-flex">
                        <p className="planning-event-summary-time" style={{ color }}>{event.startDate.format(getFormat('TIME_SHORT'))}</p>
                        <p className="planning-event-summary-day">{event.startDate.format("DD MMMM")}</p>
                    </span>
                    <p style={{ margin: '0px 15px' }}>{"-"}</p>
                    <span className="planning-event-summary-flex">
                        <p className="planning-event-summary-time" style={{ color }}>{event.endDate.format(getFormat('TIME_SHORT'))}</p>
                        <p className="planning-event-summary-day">{event.endDate.format("DD MMMM")}</p>
                    </span>
                </div>
                <div className="planning-event-summary-buttons">
                </div>
            </div>
        );
    }


    render() {
        const { isDragging } = this.state;
        const { cellEvent } = this.props;
        const { event } = cellEvent;

        const finished = moment().endOf('hour').diff(moment(cellEvent.event.endDate), 'minutes') > 30;

        const parentStyle: React.CSSProperties = {
            color: event.color && colorIsBright(event.color.color) ? "var(--dark-color)" : "var(--light-color)",
            backgroundImage: event.color ? `linear-gradient(to right, ${event.color.color}, ${lighter(event.color.color)})` : "linear-gradient(to right, var(--primary-color), #d8ad70)",
            backgroundColor: event.color ? event.color.color : "var(--primary-color)",
            width: `${this.getEventWidth()}px`,
            borderRadius: this.getEventBorderRadius(),
            marginTop: `${cellEvent.overlap * 35}px`,
            opacity: checkRBACRule(Rules.Planning.Management) ? (isDragging ? 0.2 : finished ? 0.6 : 1) : 1,
            border: event.confirmed ? `1px solid green` : undefined,
        }

        const borderStyle: React.CSSProperties = {
            backgroundColor: event.confirmed ? 'green' : undefined,
            opacity: event.confirmed ? 0.8 : undefined,
        }

        const isDraggable = event.isDraggable && !finished;
        const checkProject = this.props.currentProjects !== undefined && this.props.currentProjects.length > 0;

        return (
            checkProject && !(event.project && event.project.id && this.props.currentProjects && this.props.currentProjects.length > 0 && this.props.currentProjects.map(p => p.id).includes(event.project.id)) ?
                null
                :
                <Can
                    rule={Rules.Planning.Management}
                    privilege={Privileges.Planning.Edition}
                    privilegeCondition={event.userId !== undefined && this.props.currentUserId === event.userId}
                    otherwise={
                        <Popover
                            content={this.summary()}
                            placement="bottom"
                            overlayStyle={{ display: isDragging ? 'none' : undefined }}>
                            <div
                                className="planning-event-parent"
                                style={parentStyle}
                                onClick={this.onClick}>
                                {cellEvent.isStart && <div className="planning-event-front-border" style={borderStyle} />}
                                <p className="planning-event-title">{event.title}</p>
                                {cellEvent.isEnd && <div className="planning-event-back-border" style={borderStyle} />}
                            </div>
                        </Popover>
                    }>
                    <Popover
                        content={this.summary()}
                        placement="bottom"
                        overlayStyle={{ display: isDragging ? 'none' : undefined }}>
                        <div
                            className={`planning-event-parent ${isDraggable ? "planning-event-drag-parent" : ""}`}
                            style={parentStyle}
                            draggable={isDraggable}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}
                            onDragOver={e => e.stopPropagation()}
                            onClick={this.onClick}>
                            {cellEvent.isStart && <div className="planning-event-front-border" style={borderStyle} />}
                            {/* Delete width: 100% to take */}
                            <p style={{ width: '100%' }} className="planning-event-title">{event.title} <span style={{ float: 'right', marginRight: '10px', marginLeft: '10px' }}>{event.breakTimes && event.breakTimes.length > 0 ? <RiCupLine className="anticon" /> : ""} {event.overtimes && event.overtimes.length > 0 && event.overtimes.filter(o => o.isConfirmed === true).length > 0 ? <BiStopwatch className="anticon" /> : ""}</span></p>
                            {cellEvent.isEnd && <div className="planning-event-back-border" style={borderStyle} />}
                        </div>
                    </Popover>
                </Can>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeDraggedGroupEventUserId: (id: number | undefined) => dispatch(changeDraggedGroupEventUserId(id)),
    changeDraggedUserEventUserId: (id: number | undefined) => dispatch(changeDraggedUserEventUserId(id)),
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUserId: state.user.currentUser?.id,
    windowWidth: state.window.width,
    cellsPerRow: state.planning.cellsPerRow,
    onClick: state.planning.onClickEvent,
    onEdit: state.planning.onEditEvent,
    onDelete: state.planning.onDeleteEvent,
});
const connector = connect(mapStateToProps, mapDispatchToProps);


export default connector(Event);
