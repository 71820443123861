import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

// interface iProps {
//     itemSize?: number;
//     overscanRowCount?: number;
//     rowCount?: number;
// }

type Props = Parameters<typeof Table>[0];

const VirtualTable = (props: Props) => {
    const [vt, /*set_components*/] = useVT(() => ({
        scroll:
        {
            y: props.scroll && props.scroll.y ? props.scroll.y : 600,
            // itemSize: props.itemSize,            // Set your desired row height
            // overscanRowCount: props.overscanRowCount,    // Number of rows to render outside the visible area
            // rowCount: props.rowCount,
        }
    }), []);


    return (
        <Table
            {...props}
            pagination={false}
            components={vt}
        />
    );
};

export default VirtualTable;
