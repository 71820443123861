import React, { HTMLAttributes } from 'react';

/**
 * Component that represent a title
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export default class Title extends React.Component<HTMLAttributes<{}>, {}> {
    render() {
        const { className, children, ...props } = this.props;
        return (
            <div className={`title ${className ? className : ''}`} {...props}>
                <p>{children}</p>
            </div>
        );
    }
}
