import { CloseOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import MobileStepper from '@mui/material/MobileStepper';
import { Button, Col, Divider, Image, Input, Row, Space, Switch, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import SwipeableViews from 'react-swipeable-views';
//import { autoPlay } from 'react-swipeable-views-utils';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import getFormat from '../../../utils/Lang';
import { GenericFile } from '../../../utils/types/generalTypes';
import { NetworkEventImage } from '../../../utils/types/networkTypes';
import CircleButton from '../fields/circleButton';
import DeleteButton from '../fields/deleteButton';

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Props {
    images: NetworkEventImage[] | {
        id: number;
        name: string;
        image: string;
        fullImageUrl: string;
        src: string;
        file: File | string | GenericFile;
        active: boolean;
        created?: Moment;
        modified?: Moment;
    }[];
    width: number;
    height: number;
    deleteImage?(imageId: number): void
    setImageName?(imageId: number, name: string): void
    activeImage?(imageId: number, state?: boolean): void
    details?: boolean;
    leftText?: string;
    rightText?: string;
}
export const Carousel = (props: Props) => {
    const { images, width, height, deleteImage, setImageName, activeImage, details, leftText, rightText } = props
    const [activeStep, setActiveStep] = useState(0);
    const [editName, setEditName] = useState<{ editing: boolean, name: undefined | string }>({ editing: false, name: undefined });
    const [active, setClicked] = useState<boolean>(images.length > activeStep && images[activeStep] != null ? images[activeStep].active : false)
    const maxSteps = images.length;
    const intl = useIntl()

    useEffect(() => {
        if (images[activeStep])
            setClicked(images[activeStep].active)
    }, [images, activeStep])

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep + 1 < images.length)
            setClicked(images[activeStep + 1].active)
        resetEditName()
    }, [activeStep, images])

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep - 1 >= 0 && activeStep - 1 < images.length)
            setClicked(images[activeStep - 1].active)
        resetEditName()
    }, [activeStep, images])

    const handleStepChange = useCallback((step: number) => {
        setActiveStep(step);
        setClicked(images[step].active)
        resetEditName()
    }, [images])

    const resetEditName = () => {
        setEditName({ editing: false, name: undefined })
    }

    return (
        <div style={{ maxWidth: width, flexGrow: 1, position: 'relative', width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <SwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                style={{ height: height, overflow: 'hidden', backgroundColor: '#b1b1b11a', display: 'flex', justifyContent: 'center', flex: 1 }}
                containerStyle={{ alignItems: 'center' }}
                slideStyle={{ display: 'flex', justifyContent: 'center' }}
            >
                {images.map((step, index) => (
                    <div key={step.id}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Image
                                src={step.fullImageUrl}
                                wrapperStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                style={{ objectFit: 'contain', maxHeight: height, maxWidth: width, width: 'fit-content', height: 'fit-content' }}
                                height={(height)}
                                width={(width)}
                                preview={{ mask: <EyeOutlined /> }}
                            />
                        ) : null}
                    </div>
                ))}
            </SwipeableViews>
            {images.length > 1 &&
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    style={{ backgroundColor: 'transparent', height: 45 }}
                    nextButton={
                        <Button
                            type={'primary'}
                            style={{ border: 'none' }}
                            size="middle"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            {rightText !== undefined ? rightText === '' ? null : rightText : <FormattedMessage defaultMessage={'Next'} />}
                            {<RightOutlined />}
                        </Button>
                    }
                    backButton={
                        <Button
                            type={'primary'}
                            style={{ border: 'none' }}
                            size="middle"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            {<LeftOutlined />}
                            {leftText !== undefined ? leftText === '' ? null : leftText : <FormattedMessage defaultMessage={'Previous'} />}
                        </Button>
                    }
                />
            }
            {!details && deleteImage &&
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    bottom: images.length > 1 ? 45 : 0,
                    padding: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    //borderRadius: 'var(--border-radius)',
                    backgroundColor: "#F2F2F290",
                }}>
                    <p title={images.length > activeStep && images[activeStep] != null ? images[activeStep].name : ''} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 250 }} >{images.length > activeStep ? images[activeStep].name : ''}</p>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>

                        {
                            active && activeImage ?
                                <Tooltip title={intl.formatMessage({ defaultMessage: '{action} the image in the mobile application' }, { action: active ? intl.formatMessage({ defaultMessage: 'Hide' }) : intl.formatMessage({ defaultMessage: 'Show' }) })}>
                                    <Switch
                                        checkedChildren={<EyeOutlined />}
                                        unCheckedChildren={<EyeInvisibleOutlined />}
                                        onChange={(state) => { activeImage!(images[activeStep].id, state); setClicked(!images[activeStep].active) }}
                                        checked={active}
                                    />
                                </Tooltip>
                                :
                                <>
                                    <Tooltip title={intl.formatMessage({ defaultMessage: '{action} the image in the mobile application' }, { action: active ? intl.formatMessage({ defaultMessage: 'Hide' }) : intl.formatMessage({ defaultMessage: 'Show' }) })}>
                                        {activeImage &&
                                            <Switch
                                                checkedChildren={<EyeOutlined />}
                                                unCheckedChildren={<EyeInvisibleOutlined />}
                                                onChange={(state) => { activeImage!(images[activeStep].id, state); setClicked(!images[activeStep].active) }}
                                                checked={active}
                                            />}
                                    </Tooltip>
                                    {deleteImage &&
                                        <DeleteButton
                                            small
                                            key={`carousel-${images[activeStep].id}-delete`}
                                            text={<FormattedMessage defaultMessage={'Do you want to permanently delete this image?'} />}
                                            disabled={activeImage ? active : false}
                                            onConfirm={() => {
                                                images.length > activeStep && images[activeStep] != null && deleteImage(images[activeStep].id)
                                                if (images[activeStep - 1] != null) {
                                                    setActiveStep(activeStep - 1)
                                                } else {
                                                    setActiveStep(0)
                                                }
                                            }
                                            } />}
                                </>
                        }
                    </div>


                </div>
            }
            {details &&
                <>
                    <Divider plain style={{ marginTop: 10, marginBottom: 10 }} />
                    <Row gutter={[10, 10]} justify='center'>
                        <Col md={{ span: 24 }}>
                            <Row gutter={[15, 15]}>
                                <Col style={{ display: 'flex', alignItems: 'center' }} xs={{ span: 4 }}>{'Image:'}</Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }} xs={{ span: 2 }}>
                                    {activeImage &&
                                        <Tooltip title={intl.formatMessage({ defaultMessage: '{action} the image in the mobile application' }, { action: active ? intl.formatMessage({ defaultMessage: 'Hide' }) : intl.formatMessage({ defaultMessage: 'Show' }) })}>
                                            <Switch
                                                checkedChildren={<EyeOutlined />}
                                                unCheckedChildren={<EyeInvisibleOutlined />}
                                                onChange={(state) => { activeImage!(images[activeStep].id, state); setClicked(!images[activeStep].active) }}
                                                checked={active}
                                            />
                                        </Tooltip>
                                    }
                                </Col>

                                <Col style={{ display: 'flex', alignItems: 'center' }} xs={{ span: 18 }} >
                                    {editName.editing ?
                                        <Space style={{ width: "100%", justifyContent: "end" }}>
                                            <Input onChange={(e) => setEditName({ editing: true, name: e.target.value })} value={editName.name} placeholder={intl.formatMessage({ defaultMessage: 'Image name' })} />
                                            <CircleButton
                                                disabled={setImageName == null || editName.name == null || editName.name === ''}
                                                icon={<CloseOutlined />}
                                                title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                placement="left"
                                                onClick={() => { resetEditName() }}
                                            />
                                            <CircleButton
                                                disabled={setImageName == null || editName.name == null || editName.name === ''}
                                                icon={<SaveOutlined />}
                                                title={intl.formatMessage({ defaultMessage: 'Save' })}
                                                placement="left"
                                                onClick={() => { setImageName!(images[activeStep].id, editName.name!); resetEditName() }}
                                            />
                                        </Space>
                                        :

                                        <Space style={{ width: "100%", justifyContent: "end" }}>
                                            <a href={images[activeStep].fullImageUrl} >{images[activeStep].name ? images[activeStep].name : images[activeStep].image.split('/')[1]}</a>

                                            <CircleButton
                                                disabled={setImageName == null}
                                                icon={<EditOutlined />}
                                                title={intl.formatMessage({ defaultMessage: 'Edit image name' })}
                                                placement="left"
                                                onClick={() => setEditName({ editing: true, name: images[activeStep].name ? images[activeStep].name : images[activeStep].image.split('/')[1] })}
                                            />
                                            {deleteImage && <DeleteButton
                                                title={intl.formatMessage({ defaultMessage: 'Delete the image' })}
                                                text={<FormattedMessage defaultMessage={'Do you want to delete this image?'} />}
                                                key="carousel-delete-button"
                                                disabled={active}
                                                onConfirm={() => deleteImage!(images[activeStep].id)}
                                                placement="topRight"

                                                buttonPlacement="right"
                                            />}
                                        </Space>
                                    }
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }} xs={{ span: 12 }}><FormattedMessage defaultMessage={'Image added on'} /></Col>
                                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: "end", textAlign: 'right' }} xs={{ span: 12 }} >
                                    {images[activeStep].created !== undefined && moment(images[activeStep].created).format(getFormat('DATE'))}
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }} xs={{ span: 12 }}><FormattedMessage defaultMessage={'Image modified on'} /></Col>
                                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: "end", textAlign: 'right' }} xs={{ span: 12 }} >
                                    {images[activeStep].modified !== undefined && moment(images[activeStep].modified).format(getFormat('DATE'))}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            }

        </div>
    );
}

export default Carousel;