import { DeleteOutlined, InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Collapse, DatePicker, InputNumber, Modal, Radio, Row, Select, Steps, Switch, Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import * as momentJs from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import React, { ReactElement } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { loadPois } from '../../store/actions/location';
import { CaseType } from '../../utils/constants';
import { EventDataType, PlanningEventOwner } from '../../utils/enumerations';
import Network from '../../utils/network';
import { StaffType } from '../../utils/types/generalTypes';
import { BreakTime, BreakTimeEdit, PlanningEffectivePeriod, PlanningEvent, PlanningTemplate } from '../../utils/types/planningTypes';
import { ApplicationState, LocationDispatchProps, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, checkRBACRule, convertNetworkEffectivePeriodsToPlanningEffectivePeriods, displayErrorMessage, getCaseAndPlural, getWeekdayText, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';

const moment = extendMoment(momentJs);
type ReduxProps = ConnectedProps<typeof connector>
interface IProps {
    currentEvent: PlanningEvent;
    onEdit: (event: PlanningEvent, sendMessage?: boolean) => void;
    onCreate: (event: PlanningEvent, userIds: number[], groupIds: number[], sendMessage?: boolean, asTemplate?: boolean,) => void;
    onCancel: () => void;
    loading: boolean;
    occurenceOnly: boolean;
    openSettings: () => void;
}


interface State {
    userIds: number[];
    groupIds: number[];
    currentStep: number;
    currentTemplate: PlanningTemplate | undefined;
    currentEvent: PlanningEvent;
    untilShowTime: boolean;
    periods: PlanningEffectivePeriod[];
    asTemplate: boolean;
    sendMessage: boolean;
    isCreate: boolean;
    // breakTimes?: BreakTime[];
    editBreakTime: BreakTimeEdit;
    editBreakTimeError: boolean;
    filterByType: boolean;
    staffTypes?: StaffType[];
    initCurrentEvent: PlanningEvent;
}

type Props = IProps & LocationDispatchProps & ReduxProps & IntlProps;

/**
 * Component that represent the modal to create and edit an event
 */
class CreateEditModal extends React.Component<Props, State> {

    titleInput: any = undefined;

    constructor(props: Props) {
        super(props);

        this.state = {
            initCurrentEvent: cloneDeep(this.props.currentEvent),
            userIds: this.props.currentEvent.userId ? [this.props.currentEvent.userId] : [],
            groupIds: this.props.currentEvent.groupId ? [this.props.currentEvent.groupId] : [],
            currentStep: 0,
            currentTemplate: undefined,
            currentEvent: this.props.currentEvent,
            untilShowTime: true,
            periods: [],
            asTemplate: false,
            sendMessage: false,
            isCreate: !this.props.currentEvent.id,
            editBreakTime: {
                startDate: this.props.currentEvent.startDate,
                endDate: this.props.currentEvent.endDate,
            },
            editBreakTimeError: false,
            filterByType: true,
            // breakTimes: this.props.currentEvent.breakTimes,
        }
    }

    componentDidMount() {
        if (this.state.currentEvent.owner === PlanningEventOwner.User && this.state.userIds.length > 0) this.getUserEffectivePeriods(this.state.currentEvent.userId!);
        this.props.loadPois!();
        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response })
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the abilities' }), "error")
        );
    }


    componentDidUpdate(prevProps: Props) {
        // if the current event color have been updated in settings, update it in current event
        const { currentEvent } = this.state;
        if (prevProps.settings !== this.props.settings && this.props.settings.colors && currentEvent.color) {
            const color = this.props.settings.colors.find(c => c.id === currentEvent.color!.id);
            if (!color) {
                //if the color was deleted, set default
                currentEvent.color = undefined;
                this.setState({ currentEvent });
            } else if (color !== currentEvent.color) {
                //otherwise if the color is not the same, update it
                currentEvent.color = color;
                this.setState({ currentEvent });
            }
        }

        // focus on the title input
        if (!this.titleInput) {
            this.titleInput = document.getElementById('planning-title-input');
            this.titleInput?.focus();
        }
    }

    /**
     * Get effective periods for a user
     * @param userId the user's id for whom the effective periods must be fetch
     */
    getUserEffectivePeriods = (userId: number): void => {
        //get user's effective periods
        Network.getEffectivePeriods(userId).then(
            response => this.setState({ periods: convertNetworkEffectivePeriodsToPlanningEffectivePeriods(response) }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning")
        );
    }

    /**
     * Load a template as new event
     * @param templateId the id of the template to load
     */
    loadTemplate = (templateId: number) => {
        const template = cloneDeep(this.props.templates?.find((t: PlanningEvent) => t.id === templateId));
        if (template && templateId !== undefined) {
            let currentEvent = cloneDeep(this.state.currentEvent);
            const startDate = currentEvent.startDate.clone();

            //clone event
            const diffSeconds = template.endDate.diff(template.startDate, 'seconds');
            currentEvent = cloneDeep(template);
            currentEvent.id = undefined;
            currentEvent.isDraggable = true;
            currentEvent.isGlobal = false;

            currentEvent.startDate = startDate.clone().hours(template.startDate.hours()).minutes(template.startDate.minutes()).seconds(template.startDate.seconds());
            currentEvent.endDate = currentEvent.startDate.clone().add(diffSeconds, 'seconds');

            if (template.breakTimes && template.breakTimes.length > 0) {
                let breaktimes: BreakTime[] = [];
                let breaktimeTmp: BreakTime;
                template.breakTimes?.forEach(b => {
                    breaktimeTmp = cloneDeep(b);

                    breaktimeTmp.startDate = currentEvent.startDate.clone().add(breaktimeTmp.startDate.diff(template.startDate, "seconds"), "seconds");
                    breaktimeTmp.endDate = currentEvent.startDate.clone().add(breaktimeTmp.endDate.diff(template.startDate, "seconds"), "seconds");

                    breaktimes.push(breaktimeTmp);
                });

                breaktimes = breaktimes.sort((a, b) => a.startDate.diff(b.startDate));

                currentEvent.breakTimes = breaktimes;
            }

            this.setState({ currentEvent, currentTemplate: template, sendMessage: template.sendMessage === undefined ? false : template.sendMessage });
        } else {
            this.setState({ currentEvent: cloneDeep(this.state.initCurrentEvent), currentTemplate: undefined });

            templateId !== undefined && showNotification(this.props.intl.formatMessage({ defaultMessage: 'Template not found' }), "error");
        }
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: EventDataType, value: any) => {
        const { currentEvent } = this.state;

        switch (type) {
            case EventDataType.Title:
                currentEvent.title = value.target.value;
                break;
            case EventDataType.Description:
                currentEvent.description = value.target.value;
                break;
            case EventDataType.UserId:
                if (this.state.isCreate) {
                    this.setState({ userIds: value });
                    if (value.length === 1) this.getUserEffectivePeriods(value[0]);
                } else {
                    currentEvent.userId = value;
                    if (value) this.getUserEffectivePeriods(value);
                }
                break;
            case EventDataType.GroupId:
                if (this.state.isCreate) {
                    this.setState({ groupIds: value });
                } else {
                    currentEvent.groupId = value;
                }
                break;
            case EventDataType.Range:
                currentEvent.startDate = moment(value[0]);
                currentEvent.endDate = moment(value[1]);
                if (currentEvent.endDate.isBefore(currentEvent.endDate, "second")) {
                    currentEvent.endDate = currentEvent.startDate.clone().add(1, "hour");
                }
                break;
            case EventDataType.Owner:
                currentEvent.owner = value.target.value;
                currentEvent.isGlobal = value.target.value === PlanningEventOwner.Global;
                if (this.state.isCreate) {
                    this.setState({ userIds: [], groupIds: [] });
                } else {
                    currentEvent.userId = undefined;
                    currentEvent.groupId = undefined;
                }
                break;
            case EventDataType.IsDraggable:
                currentEvent.isDraggable = value;
                break;
            case EventDataType.Color:
                currentEvent.color = this.props.settings.colors!.find(c => c.id === value);
                break;
            case EventDataType.AsTemplate:
                this.setState({ asTemplate: value.target.checked });
                break;
            case EventDataType.SendMessage:
                this.setState({ sendMessage: value.target.checked });
                break;
            case EventDataType.TypeOfDay:
                currentEvent.typeOfDay = this.props.typesOfDay!.find(t => t.id === value);
                if (currentEvent.typeOfDay != undefined) {
                    currentEvent.typeOfDayOff = undefined
                }
                break;
            case EventDataType.TypeOfDayOff:
                currentEvent.typeOfDayOff = this.props.typesOfDayOff!.find(t => t.id === value);
                if (currentEvent.typeOfDayOff !== undefined) {
                    currentEvent.typeOfDay = undefined
                }
                break;
            case EventDataType.StaffType:
                currentEvent.staffType = this.state.staffTypes!.find(t => t.id === value);
                break;
            case EventDataType.Poi:
                currentEvent.poi = this.props.pois?.data?.find(t => t.id === value);
                break;
            case EventDataType.BasePoi:
                currentEvent.basePoi = this.props.basePois?.data?.find(t => t.id === value);
                break;
            case EventDataType.Distance:
                currentEvent.distance = value !== null ? value : undefined;
                break;
            case EventDataType.Vehicle:
                currentEvent.vehicle = this.props.vehicles?.data?.find(t => t.id === value);
                break;
            case EventDataType.Project:
                currentEvent.project = this.props.project!.find(t => t.id === value);
                break
        }
        this.setState({ currentEvent });
    }

    /**
     * Render method for the weekday select
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderWeekdaySelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {getWeekdayText(props.value)}
        </Tag>
    );


    /**
     * Render method for the owner selects
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderOwnerSelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {props.label}
        </Tag>
    );

    /**
     * Check if a date must be disabled
     * @param date the current date to verify
     * @returns true if the date must be disabled, false otherwise
     */
    disableDate = (date: Moment): boolean => {
        const { periods, currentEvent } = this.state;

        if ((this.state.isCreate && this.state.userIds.length > 0) || (!this.state.isCreate && currentEvent.userId)) {
            let atLeastOnce = false;
            for (let i = 0; i < periods.length; ++i) {
                // if (date.within(moment.range(periods[i].startDate, periods[i].endDate))) return false;
                if (date.isBetween(periods[i].startDate, periods[i].endDate, "days", "[]")) {
                    atLeastOnce = true;
                }
            }
            return !atLeastOnce;
        } else {
            return false;
        }
    }

    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    disableTime = (date: Moment | null) => {
        const { settings } = this.props;
        if (date !== null && settings.startHourOfDay && settings.endHourOfDay) {
            return {
                disabledHours: () => range(0, settings.startHourOfDay!.hours()).concat(range(settings.endHourOfDay!.hours() + 1, 24)),
                disabledMinutes: (hours: number) => {
                    if (this.props.settings.endHourOfDay && hours === this.props.settings.endHourOfDay.hours()) {
                        return range(this.props.settings.endHourOfDay.minutes() + 1, 59);
                    } else {
                        return [];
                    }
                }
            }
        }
        return {}
    }

    /**
     * Cancel the creation, edition
     */
    onCancel = (): void => {
        this.setState({ currentStep: 0, currentTemplate: undefined });
        this.props.onCancel();
    }

    /**
     * Called when the user click on the OK button
     */
    onDone = (): void => {
        const { currentEvent } = this.state;
        // currentEvent.breakTimes = this.state.breakTimes;

        //if there is no owner, display an error message (create mode)
        if (!currentEvent.isGlobal && (this.state.isCreate ? (this.state.userIds.length === 0 && this.state.groupIds.length === 0) : (!currentEvent.userId && !currentEvent.groupId))) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select an user or a group' }), "planning-modal-ok-button");
        //if there is no title, display an error message
        else if (currentEvent.title.length === 0) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select a name for the event' }), "planning-modal-ok-button");
        else {
            this.setState({ currentStep: 0, currentTemplate: undefined });
            this.state.isCreate ? this.props.onCreate(currentEvent, this.state.userIds, this.state.groupIds, this.state.sendMessage, this.state.asTemplate) : this.props.onEdit(currentEvent, this.state.sendMessage);
        }
    }

    validateBreakTime = () => {
        const { currentEvent, editBreakTime } = this.state;
        if (editBreakTime === undefined || editBreakTime.title === undefined || editBreakTime.startDate === undefined || editBreakTime.endDate === undefined) {
            this.setState({ editBreakTimeError: true });
            return false;
        } else {
            const breakTimesTmp: BreakTime = {
                title: editBreakTime.title,
                startDate: editBreakTime.startDate,
                endDate: editBreakTime.endDate,
                isPaid: editBreakTime.isPaid ? editBreakTime.isPaid : false,
                active: true,
                isClocked: false,
                totalHours: 0.0,
                totalSeconds: 0.0,
                id: -1,
            };
            if (currentEvent.breakTimes === undefined) {
                currentEvent.breakTimes = [breakTimesTmp];
            } else {
                currentEvent.breakTimes.push(breakTimesTmp);
            }

            this.setState({
                currentEvent,
                editBreakTime: {
                    startDate: this.props.currentEvent.startDate,
                    endDate: this.props.currentEvent.endDate,
                },
                editBreakTimeError: false
            });
            return true;
        }

    }

    genExtra = (index: number) => {
        return (<DeleteOutlined onClick={() => {
            const { currentEvent } = this.state;
            currentEvent.breakTimes?.splice(index, 1);
            this.setState({ currentEvent });
        }} />)
    }

    /**
     * Render the content of the create/edit modal
     */
    renderModalContent = () => {
        const { RangePicker } = DatePicker;
        const { Panel } = Collapse;
        const { currentEvent, isCreate, editBreakTime, filterByType } = this.state;
        const { intl } = this.props;
        const range: [Moment, Moment] = [currentEvent.startDate ? moment(currentEvent.startDate, "DD-MM-YYYY HH:mm") : moment().set({ h: 0, m: 0, s: 0 }), currentEvent.endDate ? moment(currentEvent.endDate, "DD-MM-YYYY HH:mm") : moment().set({ h: 23, m: 59, s: 59 })];
        // const until = currentEvent.rrule?.until ? moment(currentEvent.rrule.until) : undefined;
        const canManage = checkRBACRule(Rules.Planning.Management);
        const currentUser = currentEvent.userId ? this.props.users?.find(u => u.id === currentEvent.userId) : (this.state.userIds && this.state.userIds.length === 1 ? this.props.users?.find(u => u.id === this.state.userIds[1]) : undefined);

        // START "Filters by type"
        let users = this.props.users;
        let groups = this.props.groups;
        // Filter by StaffType
        filterByType && currentEvent.staffType && (users = users?.filter(u => u.staffType && u.staffType.length > 0 ? u.staffType.findIndex(st => st.id === currentEvent.staffType?.id) > -1 : false));
        // If the POI has no groups configured, no groups available / Else check groups available to POI
        filterByType && currentEvent.poi && (currentEvent.poi.groups === undefined || currentEvent.poi.groups.length === 0) ?
            groups = []
            :
            filterByType && currentEvent.poi && currentEvent.poi.groups && currentEvent.poi.groups.length > 0 && (groups = groups?.filter(gi => currentEvent.poi?.groups?.find(pg => pg.id === gi.id) !== undefined))
        // If the POI has no groups configured (before), no users available / Else filter by POI (with group)
        filterByType && currentEvent.poi && groups && groups.length === 0 ?
            users = []
            :
            filterByType && currentEvent.poi && currentEvent.poi.groups && currentEvent.poi.groups.length > 0 && (users = users?.filter(u => u.group_users?.find(gu => groups?.find(g => g.id === gu.group) !== undefined) !== undefined));
        // END "Filters by type"

        switch (this.state.currentStep) {
            case 0: {
                let colors: any = [<Select.Option label={intl.formatMessage({ defaultMessage: 'Default' })} value={-1} key={`planning-colors-default`}>{<span className="flex-row"><p><FormattedMessage defaultMessage={'Default'} /></p><p style={{ color: 'var(--primary-color)', fontSize: '30px', display: currentEvent.color ? undefined : 'none' }}>•</p></span>}</Select.Option>];
                if (this.props.settings.colors) {
                    colors = this.props.settings.colors!.map(c => <Select.Option label={c.title} value={c.id!} key={`planning-colors-${c.id}`}><span className="flex-row"><p>{c.title}</p><p style={{ fontSize: '30px', color: c.color, display: currentEvent.color?.id === c.id ? 'none' : undefined }}>•</p></span></Select.Option>);
                    colors.unshift(<Select.Option label={intl.formatMessage({ defaultMessage: 'Default' })} value={-1} key={`planning-colors-default`}>{<span className="flex-row"><p><FormattedMessage defaultMessage={'Default'} /></p><p style={{ color: 'var(--primary-color)', fontSize: '30px', display: currentEvent.color ? undefined : 'none' }}>•</p></span>}</Select.Option>);
                }

                return (
                    <Row gutter={[15, 15]}>
                        {
                            this.state.isCreate && this.props.templates!.length > 0 &&
                            <Col xs={{ span: 24 }} md={{ span: 12, offset: 12 }}>
                                <Select
                                    className="event-modal-data-entry"
                                    placeholder={<FormattedMessage defaultMessage={'Load data from a template'} />}
                                    allowClear
                                    showSearch
                                    onChange={(id: number) => this.loadTemplate(id)}
                                    value={this.state.currentTemplate ? this.state.currentTemplate.id as number : undefined}
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {this.props.templates!.map(t => <Select.Option label={t.title} key={`planning-templates-${t.id}`} value={t.id!}>{t.title}</Select.Option>)}
                                </Select>
                            </Col>
                        }
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title={<FormattedMessage defaultMessage={'Details'} />} extra={

                                        <Checkbox
                                            disabled={currentEvent.owner === PlanningEventOwner.Global}
                                            checked={this.state.filterByType}
                                            onChange={e => this.setState({ filterByType: e.target.checked })}
                                        >
                                            <FormattedMessage defaultMessage={'Filter by selected criteria'} />
                                        </Checkbox>
                                    }>
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 24 }}>
                                                {
                                                    canManage ?
                                                        <div className="planning-modal-section" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Radio.Group
                                                                style={{ marginBottom: currentEvent.isGlobal ? '42px' : '10px' }}
                                                                onChange={(e) => this.dataChanged(EventDataType.Owner, e)}
                                                                value={currentEvent.owner}>
                                                                <Radio value={PlanningEventOwner.User}><FormattedMessage defaultMessage={'User'} /></Radio>
                                                                <Radio value={PlanningEventOwner.Group}><FormattedMessage defaultMessage={'Group'} /></Radio>
                                                                <Radio value={PlanningEventOwner.Global}><FormattedMessage defaultMessage={'Global'} /></Radio>
                                                            </Radio.Group>
                                                            {currentEvent.owner === PlanningEventOwner.User &&
                                                                (users && users.length === 0 ?
                                                                    <p style={{ color: 'red' }}>Aucun utilisateur disponible correspondant aux types.</p>
                                                                    :
                                                                    <Select
                                                                        mode={isCreate ? "multiple" : undefined}
                                                                        allowClear
                                                                        maxTagCount={1}
                                                                        showSearch
                                                                        onChange={ids => this.dataChanged(EventDataType.UserId, ids)}
                                                                        tagRender={this.renderOwnerSelect}
                                                                        value={isCreate ? this.state.userIds : currentEvent.userId}
                                                                        filterOption={true}
                                                                        optionFilterProp="label">
                                                                        {users?.map(u => <Select.Option label={`${u.last_name} ${u.first_name}`} key={`planning-users-${u.id}`} value={u.id}>{`${u.last_name} ${u.first_name}`}</Select.Option>)}
                                                                    </Select>)
                                                            }
                                                            {currentEvent.owner === PlanningEventOwner.Group &&
                                                                (groups && groups.length === 0 ?
                                                                    <p style={{ color: 'red' }}>Aucun groupe disponible pour l'emplacement.</p>
                                                                    :
                                                                    <Select
                                                                        mode={isCreate ? "multiple" : undefined}
                                                                        allowClear
                                                                        showSearch
                                                                        maxTagCount={1}
                                                                        tagRender={this.renderOwnerSelect}
                                                                        onChange={ids => this.dataChanged(EventDataType.GroupId, ids)}
                                                                        value={isCreate ? this.state.groupIds : currentEvent.groupId}
                                                                        filterOption={true}
                                                                        optionFilterProp="label">
                                                                        {groups?.map(g => <Select.Option label={g.name} key={`planning-groups-${g.id}`} value={g.id!}>{g.name}</Select.Option>)}
                                                                    </Select>)
                                                            }
                                                        </div>
                                                        :
                                                        (() => {
                                                            const user = this.props.users?.find(u => u.id === currentEvent.userId);
                                                            return (
                                                                <div className="planning-modal-section" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ marginBottom: '10px' }}>{"Utilisateur"}</p>
                                                                    <p>{`${user?.last_name} ${user?.first_name}`}</p>
                                                                </div>
                                                            );
                                                        })()
                                                }
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <span className="flex-row">
                                                    <p>{"Début et fin"}</p>
                                                    {
                                                        currentUser && currentUser.job && currentUser.job[0] && currentUser.job[0].weekly_working_hours && this.props.settings.workingDaysOfWeek &&
                                                        <Button
                                                            className="planning-modal-range-button"
                                                            type="link"
                                                            onClick={() =>
                                                                this.dataChanged(
                                                                    EventDataType.Range,
                                                                    [
                                                                        currentEvent.startDate,
                                                                        moment(currentEvent.startDate).add(currentUser.job![0].weekly_working_hours! / this.props.settings.workingDaysOfWeek!.length, 'hours')
                                                                    ]
                                                                )}>
                                                            {"Journée utilisateur"}
                                                        </Button>
                                                    }
                                                    {
                                                        this.props.settings.startHourOfDay && this.props.settings.endHourOfDay &&
                                                        <Button
                                                            className="planning-modal-range-button"
                                                            type="link"
                                                            onClick={() => this.dataChanged(
                                                                EventDataType.Range,
                                                                [
                                                                    currentEvent.startDate.hours(this.props.settings.startHourOfDay!.hours()).minutes(this.props.settings.startHourOfDay!.minutes()),
                                                                    currentEvent.endDate.hours(this.props.settings.endHourOfDay!.hours()).minutes(this.props.settings.endHourOfDay!.minutes())
                                                                ]
                                                            )}>
                                                            {"Journée complète"}
                                                        </Button>
                                                    }
                                                </span>
                                                <RangePicker
                                                    allowClear={false}
                                                    className="planning-modal-rangepicker"
                                                    showTime={{ format: 'HH:mm:ss' }}
                                                    format="DD-MM-YYYY HH:mm:ss"
                                                    placeholder={['Heure de début', 'Heure de fin']}
                                                    value={range}
                                                    disabledDate={this.disableDate}
                                                    disabledTime={this.disableTime}
                                                    // onCalendarChange={(e) => this.dataChanged(EventDataType.Range1, e)}
                                                    onChange={(e) => this.dataChanged(EventDataType.Range, e)}
                                                    onOk={(e) => this.dataChanged(EventDataType.Range, e)} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Informations">
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 24 }}>
                                                <p className="event-section-title">{"Titre"}</p>
                                                <InputField
                                                    id="planning-title-input"
                                                    className="planning-modal-input"
                                                    value={currentEvent.title}
                                                    placeholder="Nom de l'événement"
                                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Title, e)} />
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <p className="flex-row event-section-title">
                                                    {"Description"}
                                                </p>
                                                <InputField
                                                    type="textarea"
                                                    value={currentEvent.description}
                                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Description, e)}
                                                    autoSize={{ minRows: 2 }} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {/* <div className="display-flex">
                                    <div className="flex-row">

                                        {
                                            this.state.isCreate && this.props.templates!.length > 0 &&
                                            <div className="planning-modal-section">
                                                <p className="planning-modal-section-title-first planning-modal-template-title">{"Modèle"}</p>
                                                <Select
                                                    className="planning-modal-data-entry"
                                                    placeholder={"Modèle"}
                                                    allowClear
                                                    showSearch
                                                    onChange={(id: number) => this.loadTemplate(id)}
                                                    value={this.state.currentTemplate ? this.state.currentTemplate.id as number : undefined}
                                                    filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {this.props.templates!.map(t => <Select.Option key={`planning-templates-${t.id}`} value={t.id!}>{t.title}</Select.Option>)}
                                                </Select>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex-row">


                                    </div>
                                    <div className="planning-modal-section">
                                        <p className="flex-row planning-modal-section-title">
                                            {"Description"}
                                        </p>
                                        <InputField
                                            type="textarea"
                                            value={currentEvent.description}
                                            onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(EventDataType.Description, e)}
                                            autoSize={{ minRows: 2 }} />
                                    </div>
                                    <div className="flex-row">
                                        <div className="planning-modal-section">
                                            <p className="planning-modal-section-title">{"Déplaçable"}</p>
                                            <div className="planning-modal-movable">
                                                <Switch
                                                    checked={currentEvent.isDraggable}
                                                    onChange={(e) => this.dataChanged(EventDataType.IsDraggable, e)} />
                                                <p className="planning-modal-movable-text" onClick={() => this.dataChanged(EventDataType.IsDraggable, !currentEvent.isDraggable)}>{currentEvent.isDraggable ? "Déplaçable" : "immobile"}</p>
                                            </div>
                                        </div>
                                        <div className="planning-modal-section">
                                            <div className="flex-row planning-modal-section-title">
                                                <p>{"Couleur"}</p>
                                                <div
                                                    onClick={canManage ? this.props.openSettings : undefined}
                                                    style={{ cursor: canManage ? 'pointer' : undefined, marginRight: '5px', height: '20px', width: '30px', borderRadius: '5px', backgroundColor: currentEvent.color?.color ? currentEvent.color?.color : 'var(--primary-color)' }} />
                                            </div>
                                            <Select
                                                className="planning-modal-data-entry"
                                                showSearch
                                                allowClear
                                                onChange={(id: number) => this.dataChanged(EventDataType.Color, id)}
                                                value={currentEvent.color?.id ? currentEvent.color?.id as number : -1}
                                                filterOption={(input, option) => option?.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {colors}
                                            </Select>
                                        </div>
                                    </div>
                                </div> */}
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Critères">
                                        <Row gutter={[10, 10]}>
                                            {
                                                checkRBACRule(Rules.Project.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                                                <>
                                                    <Col xs={{ span: 10 }}>
                                                        <span>{getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', false, CaseType.FIRST_LETTER_UPPERCASE)}</span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            onChange={(id: number) => this.dataChanged(EventDataType.Project, id)}
                                                            value={currentEvent.project?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.project?.map(p => <Select.Option label={p.title} value={p.id!} key={`planning-project-${p.id}`}>{p.title}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                </>
                                            }
                                            <Col xs={{ span: 10 }}>
                                                <span>{"Type de jour"}</span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDay, id)}
                                                    value={currentEvent.typeOfDay?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.typesOfDay?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>
                                            <Col xs={{ span: 10 }}>
                                                <span>{"Jour de repos"}</span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.TypeOfDayOff, id)}
                                                    value={currentEvent.typeOfDayOff?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.typesOfDayOff?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>
                                            <Col xs={{ span: 10 }}>
                                                <span>{"Aptitude"}</span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.StaffType, id)}
                                                    value={currentEvent.staffType?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.state.staffTypes?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                </Select>
                                            </Col>
                                            {
                                                checkRBACRule(Rules.CourseManagement.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id) &&
                                                <>
                                                    <Col xs={{ span: 10 }}>
                                                        <span>{"Lieu de travail"}</span>
                                                    </Col>
                                                    <Col xs={{ span: 14 }}>
                                                        <Select
                                                            className="planning-modal-input-100"
                                                            showSearch
                                                            allowClear
                                                            onChange={(id: number) => this.dataChanged(EventDataType.Poi, id)}
                                                            value={currentEvent.poi?.id}
                                                            filterOption={true}
                                                            optionFilterProp="label">
                                                            {this.props.pois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                        </Select>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: '15px' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Extra">
                                        <Row gutter={[15, 15]}>
                                            <Col xs={{ span: 24 }}>
                                                <div className="flex-row">
                                                    <p>{"Couleur"}</p>
                                                    <div
                                                        onClick={canManage ? this.props.openSettings : undefined}
                                                        style={{ cursor: canManage ? 'pointer' : undefined, marginRight: '5px', height: '20px', width: '30px', borderRadius: '5px', backgroundColor: currentEvent.color?.color ? currentEvent.color?.color : 'var(--primary-color)' }} />
                                                </div>
                                                <Select
                                                    className="event-modal-data-entry"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Color, id)}
                                                    value={currentEvent.color?.id ? currentEvent.color?.id as number : -1}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {colors}
                                                </Select>
                                            </Col>
                                            <Col xs={{ span: 24 }}>
                                                <p className="event-section-title">{"Déplaçable"}</p>
                                                <div className="planning-modal-movable">
                                                    <Switch
                                                        checked={currentEvent.isDraggable}
                                                        onChange={(e) => this.dataChanged(EventDataType.IsDraggable, e)} />
                                                    <p className="planning-modal-movable-text" onClick={() => this.dataChanged(EventDataType.IsDraggable, !currentEvent.isDraggable)}>{currentEvent.isDraggable ? "Déplaçable" : "immobile"}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
            }
            case 1:
                return (
                    <Row gutter={[15, 15]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Card size="small" title="Pauses">
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }}>
                                        <InputField
                                            // id="planning-title-input"
                                            // className="planning-modal-input"
                                            className={this.state.editBreakTimeError === true && editBreakTime.title === undefined ? "planning-modal-input-error" : ""}
                                            value={editBreakTime.title}
                                            placeholder="Titre"
                                            onChange={(e: InputFieldOnChangeEvent) => {
                                                editBreakTime.title = e.target.value;
                                                editBreakTime.title === "" && (editBreakTime.title = undefined);
                                                this.setState({ editBreakTime })
                                            }} />
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <RangePicker
                                            className={this.state.editBreakTimeError === true && (editBreakTime.startDate === undefined || editBreakTime.endDate === undefined) ? "planning-modal-input-error" : ""}
                                            allowClear={false}
                                            // className="planning-modal-rangepicker"
                                            showTime
                                            format={this.props.isSmartphone ? "DD/MM HH:mm" : "DD/MM/YYYY HH:mm"}
                                            placeholder={['Heure de début', 'Heure de fin']}
                                            value={[editBreakTime.startDate ? editBreakTime.startDate : null, editBreakTime.endDate ? editBreakTime.endDate : null]}
                                            disabledDate={this.disableDate}
                                            disabledTime={this.disableTime}
                                            onChange={(e) => {
                                                if (e && e.length > 1 && e[0] !== null && e[1] !== null) {
                                                    editBreakTime.startDate = e[0];
                                                    editBreakTime.endDate = e[1];
                                                    this.setState({ editBreakTime })
                                                }
                                            }}
                                            onOk={(e) => {
                                                if (e && e.length > 1 && e[0] !== null && e[1] !== null) {
                                                    editBreakTime.startDate = e[0];
                                                    editBreakTime.endDate = e[1];
                                                    this.setState({ editBreakTime })
                                                }
                                            }}
                                        // onOk={(e) => this.dataChanged(EventDataType.Range, e)} 
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <Checkbox
                                            checked={editBreakTime.isPaid}
                                            onChange={(e) => {
                                                editBreakTime.isPaid = e.target.checked;
                                                this.setState({ editBreakTime })
                                            }}>
                                            {"À la charge de l'employeur"}
                                        </Checkbox>
                                        <Tooltip overlay={"Si la pause est à la charge de l'employeur celle-ci ne sera pas déduite de la durée de l'événement."} placement="right">
                                            <InfoCircleOutlined style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} xs={{ span: 24 }}>
                                        <Button type="primary" style={{ minWidth: "150px" }} onClick={this.validateBreakTime} loading={this.props.loading}>
                                            {"Ajouter"}
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    currentEvent.breakTimes && currentEvent.breakTimes.length > 0 &&
                                    <Row style={{ marginTop: '20px' }} gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }}>
                                            <Collapse
                                                accordion={true}
                                            // defaultActiveKey={['1']}
                                            // onChange={callback}
                                            // expandIconPosition={expandIconPosition}
                                            >
                                                {
                                                    currentEvent.breakTimes.map((bt, index) => {
                                                        return (
                                                            <Panel header={bt.title} key={index + 1} extra={this.genExtra(index)}>
                                                                <p>Début: {bt.startDate.format("DD.MM.YYYY HH:mm")}</p>
                                                                <p>Fin: {bt.endDate.format("DD.MM.YYYY HH:mm")}</p>
                                                                <p>Durée: {Math.round((bt.endDate.diff(bt.startDate, "hours", true) + Number.EPSILON) * 100) / 100}h</p>
                                                                <p>{bt.isPaid ? "À la charge de l'employeur." : "À la charge de l'employé."}</p>
                                                            </Panel>
                                                        )
                                                    })
                                                }

                                            </Collapse>
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row gutter={[15, 15]}>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Général">
                                        {
                                            canManage && this.state.isCreate &&
                                            <>
                                                <Checkbox
                                                    checked={this.state.asTemplate}
                                                    onChange={(e) => this.dataChanged(EventDataType.AsTemplate, e)}>
                                                    {"Créer un modèle"}
                                                </Checkbox>
                                                <Tooltip overlay={"Un modèle permet la création d'un nouvel événement en se basant sur la configuration de ce dernier."} placement="right">
                                                    <InfoCircleOutlined style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                                </Tooltip><br />
                                            </>
                                        }
                                        <Checkbox
                                            checked={this.state.sendMessage}
                                            onChange={(e) => this.dataChanged(EventDataType.SendMessage, e)}>
                                            {"Envoyer par message"}
                                        </Checkbox>
                                        <Tooltip overlay={"Envoyer une notification par message aux utilisateurs concernés"} placement="right">
                                            <InfoCircleOutlined style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <Card size="small" title="Trajet & Véhicule">
                                        <Row gutter={[10, 10]}>
                                            <Col xs={{ span: 10 }}>
                                                <span>{"Lieu de départ"}</span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    disabled={this.props.basePois === undefined ? true : this.props.basePois.data === undefined ? true : this.props.basePois.data.length <= 0}
                                                    onChange={(id: number) => this.dataChanged(EventDataType.BasePoi, id)}
                                                    value={currentEvent.basePoi?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.basePois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`planning-tod-${t.id}`}>{t.title}</Select.Option>)}
                                                </Select>
                                            </Col>

                                            <Col xs={{ span: 10 }}>
                                                <span title="Distance en km">{"Distance"}</span>
                                                <Tooltip title={"Distance totale du trajet aller, en Km"}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <InputNumber
                                                    min={0}
                                                    max={1000}
                                                    id="planning-distance-input"
                                                    className="planning-modal-input"
                                                    value={currentEvent.distance}
                                                    placeholder="Distance en km"
                                                    onChange={e => this.dataChanged(EventDataType.Distance, e)} />
                                            </Col>
                                            <Col xs={{ span: 10 }}>
                                                <span>{"Véhicule"}</span>
                                            </Col>
                                            <Col xs={{ span: 14 }}>
                                                <Select
                                                    className="planning-modal-input-100"
                                                    showSearch
                                                    allowClear
                                                    onChange={(id: number) => this.dataChanged(EventDataType.Vehicle, id)}
                                                    value={currentEvent.vehicle?.id}
                                                    filterOption={true}
                                                    optionFilterProp="label">
                                                    {this.props.vehicles?.data?.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-tod-${t.id}`}>{t.name}</Select.Option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
        }
    }

    render() {
        const { currentEvent, isCreate } = this.state;
        const title = isCreate ? "Ajouter un événement" : `Éditer l'événement: ${currentEvent.title}`;

        return (
            currentEvent ?
                <Modal
                    width="880px"
                    className="planning-modal"
                    title={title}
                    visible={Boolean(currentEvent)}
                    onCancel={this.onCancel}
                    destroyOnClose={true}
                    footer={[
                        <Button type="dashed" onClick={this.onCancel} key="planning-modal-cancel-button">
                            {'Annuler'}
                        </Button>,
                        this.props.occurenceOnly ?
                            null
                            :
                            <div key="planning-modal-next-previous-buttons">
                                <Button disabled={this.state.currentStep === 0} onClick={() => this.setState({ currentStep: this.state.currentStep - 1 })}>
                                    <LeftOutlined />
                                    <span className="modal-button-next-previous-title">{'Précédent'}</span>
                                </Button>
                                <Button disabled={this.state.currentStep === 1} onClick={() => this.setState({ currentStep: this.state.currentStep + 1 })}>
                                    <span className="modal-button-next-previous-title">{'Suivant'}</span>
                                    <RightOutlined />
                                </Button>
                            </div>,
                        <Button id="planning-modal-ok-button" type="primary" onClick={this.onDone} loading={this.props.loading} key="planning-modal-button-ok">
                            {isCreate ? 'Créer' : 'Sauvegarder'}
                        </Button>
                    ]}>
                    {
                        this.props.occurenceOnly ?
                            <Steps
                                type="navigation"
                                size="small"
                                current={this.state.currentStep}
                                onChange={(current) => this.setState({ currentStep: current })}
                                style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                                <Steps.Step title="Général" key="planning-modal-step-1" />
                            </Steps>
                            :
                            <Steps
                                type="navigation"
                                size="small"
                                current={this.state.currentStep}
                                onChange={(current) => this.setState({ currentStep: current })}
                                style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                                <Steps.Step title="Général" key="planning-modal-step-0" />
                                <Steps.Step title="Options" key="planning-modal-step-2" />
                            </Steps>
                    }
                    {this.renderModalContent()}
                </Modal>
                : null

        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
    settings: state.planning.settings,
    templates: state.planning.templates,
    typesOfDay: state.configurations.typesOfDay,
    typesOfDayOff: state.configurations.typesOfDayOff,
    project: state.configurations.project,
    vehicles: state.configurations.vehicles,
    basePois: state.location.basePois,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    pois: state.location.pois,
});
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(injectIntl(CreateEditModal));