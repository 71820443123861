import { DashOutlined, EditOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Empty, Popover, Row, Space, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { cloneDeep } from "lodash";
import moment from "moment";
import React from "react";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { BsListTask } from "react-icons/bs";
import { FormattedMessage, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { changeTypesOfDay, changeTypesOfDayOff } from "../../../store/actions/configurations";
import { changeTemplates } from "../../../store/actions/planning";
import { BreaktimeCalculatedTypes } from "../../../utils/constants";
import Network from "../../../utils/network";
import { UserEventsData } from "../../../utils/objects/cct/userEventsData";
import { POI, StaffType } from "../../../utils/types/generalTypes";
import { NetworkEvent } from "../../../utils/types/networkTypes";
import { PlanningTemplate, TypeOfDay } from "../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch } from "../../../utils/types/storeTypes";
import { convertNetworkEventToPlanningEvent, convertNetworkEventsToPlanningEvents, convertNetworkEventsToPlanningEventsV2, convertPlanningEventToSimpleEvent, isNullOrEmpty, momentDurationFormat, showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import FAIcon from "../../common/FAIcon";
import CircleButton from "../../common/fields/circleButton";
import DeleteButton from "../../common/fields/deleteButton";
import SpeedDial from "../../common/fields/speedDial";
import Anticon from "../../common/general/anticon";
import Card from "../../common/general/card";
import Event, { EventSource } from "../../common/general/event";
import { tableColumnTextFilterConfig } from "../../courseManagement/tableSearch";
import TemplateModal from "../../planning/templateModal";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, IntlProps { }

interface State {
    editTemplateId?: number;
    archivedOnly: boolean;
    archivedTemplates: PlanningTemplate[];
    loadingArchived: boolean;
    loading: boolean;
}

class Template extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            archivedOnly: false,
            archivedTemplates: [],
            loadingArchived: false,
            loading: false,
        };
    }


    templateColumnsActions = (record: PlanningTemplate) => {
        console.log(`Template active ${record.title} ${record.active}`);
        return [
            <Space key={`template-edit-space-${record.id}`} >
                <CircleButton
                    key={`template-archiv-${record.id}`}
                    icon={record.active ? <span className='anticon'><BiArchiveIn /></span> : <span className='anticon'><BiArchiveOut /></span>}
                    title={record.active ? this.props.intl.formatMessage({ defaultMessage: 'Archive' }) : this.props.intl.formatMessage({ defaultMessage: 'Activate' })}
                    withoutTooltip
                    onClick={() => this.toggleTemplateArchivState(record)}
                />
                <DeleteButton
                    text={<FormattedMessage defaultMessage={'Do you want to delete this template?'} />}
                    key={`template-delete-${record.id}`}
                    onConfirm={() => this.onDeleteTemplate(record)}
                    withoutTooltip
                />
                <CircleButton
                    key={`template-edit-${record.id}`}
                    icon={<EditOutlined />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                    withoutTooltip
                    onClick={() => this.setState({ editTemplateId: record.id })}
                />
            </Space>
        ];
    };
    templateColumns: ColumnProps<PlanningTemplate>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: "title",
            className: '__min-width-200',
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            render: (record: PlanningTemplate) => {
                const totalHoursBreakTime = record.breakTimes ? record.breakTimes.reduce((value: number, b) => value += b.totalHours, 0) : 0;
                return <Event event={{ ...record, totalHoursBreakTime }} displayTimes displayIcons displayOverlay source={EventSource.TEMPLATE} />;
            },
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1,

        },
        {
            title: <FormattedMessage defaultMessage={'Duration'} />,
            key: "title",
            className: '__width_100 __centered-text',
            render: (record: PlanningTemplate) => {
                const simpleEvent = convertPlanningEventToSimpleEvent({
                    ...record,
                    startDate: moment(record.startDate),
                    endDate: moment(record.endDate),
                });

                const eventEffective = moment.duration(UserEventsData.eventDuration(simpleEvent, true, true, undefined, undefined, true), "seconds");
                const eventEffectiveString = momentDurationFormat(eventEffective);

                const paidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.PAID, undefined, undefined, true), "seconds");
                const paidBreaktimeDurationStr = momentDurationFormat(paidBreaktimeDuration);

                const notPaidBreaktimeDuration = moment.duration(UserEventsData.breaktimesDuration([simpleEvent], BreaktimeCalculatedTypes.NOTPAID, undefined, undefined, true), "seconds");
                const notPaidBreaktimeDurationStr = momentDurationFormat(notPaidBreaktimeDuration);
                return (
                    <Popover
                        overlayInnerStyle={{ width: '350px' }}
                        mouseEnterDelay={0.5}
                        title={<FormattedMessage defaultMessage={'Hours details'} />}
                        content={
                            <>
                                {
                                    paidBreaktimeDuration.asMinutes() !== 0 ?
                                        <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                            <p><FormattedMessage defaultMessage={'Paid breaks'} />{':'}</p>
                                            <p style={{ fontStyle: 'italic', opacity: '0.9', textDecoration: 'line-through' }}>{paidBreaktimeDurationStr}</p>
                                        </div>
                                        : null
                                }
                                {
                                    notPaidBreaktimeDuration.asMinutes() !== 0 ?
                                        <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                            <p><FormattedMessage defaultMessage={'Unpaid breaks'} />{':'}</p>
                                            <p>-{notPaidBreaktimeDurationStr}</p>
                                        </div>
                                        : null
                                }
                                <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                                    <p><FormattedMessage defaultMessage={'Effective time'} />{':'}</p>
                                    <p>{eventEffectiveString}</p>
                                </div>
                            </>
                        }
                    >
                        <span>{eventEffectiveString}</span>
                    </Popover >
                );
            },
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => {
                const simpleEventA = convertPlanningEventToSimpleEvent({
                    ...a,
                    startDate: moment(a.startDate),
                    endDate: moment(a.endDate),
                });
                const simpleEventB = convertPlanningEventToSimpleEvent({
                    ...b,
                    startDate: moment(b.startDate),
                    endDate: moment(b.endDate),
                });
                const durationA = UserEventsData.eventDuration(simpleEventA, true, true, undefined, undefined, true);
                const durationB = UserEventsData.eventDuration(simpleEventB, true, true, undefined, undefined, true);

                return durationA - durationB;
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Projects'} />,
            dataIndex: "project",
            key: "typeOfDay",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            render: (typeOfDay: TypeOfDay) => typeOfDay?.title,
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.typeOfDay ? -1 : !b.typeOfDay ? 1 : a.typeOfDay < b.typeOfDay ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Type of day'} />,
            dataIndex: "typeOfDay",
            key: "typeOfDay",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            render: (typeOfDay: TypeOfDay) => typeOfDay?.title,
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.typeOfDay ? -1 : !b.typeOfDay ? 1 : a.typeOfDay < b.typeOfDay ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Type of vacation'} />,
            dataIndex: "typeOfDayOff",
            key: "typeOfDayOff",
            className: '__min-width-170',
            onFilter: (value, record) => {
                return record
                    .title!.toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase());
            },
            ...tableColumnTextFilterConfig<PlanningTemplate>(),
            render: (typeOfDayOff: TypeOfDay) => typeOfDayOff?.title,
            sorter: (a: PlanningTemplate, b: PlanningTemplate) => !a.typeOfDayOff ? -1 : !b.typeOfDayOff ? 1 : a.typeOfDayOff < b.typeOfDayOff ? -1 : 1,
        },
        {
            title: <FormattedMessage defaultMessage={'Point of interest'} />,
            dataIndex: "poi",
            key: "poi",
            className: '__min-width-170',
            render: (poi: POI) => poi?.title,
        },
        {
            title: <FormattedMessage defaultMessage={'Abilities'} />,
            dataIndex: "staffType",
            key: "staffType",
            className: '__min-width-170',
            render: (staffType: StaffType) => {
                if (staffType) {
                    return (
                        <Tag className="__report-groups-users-tags" color="#f5f5f5">
                            {staffType.name}
                        </Tag>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: "actions",
            className: '__width_160 __centered-text',
            render: (record: PlanningTemplate) => {
                return this.props.isSmartphone ? (
                    <SpeedDial
                        key="poi-actions-speeddial"
                        title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        buttons={this.templateColumnsActions(record)}
                    />
                ) : (
                    this.templateColumnsActions(record)
                );
            },
        },
    ];

    templateColumnsMobile: ColumnProps<PlanningTemplate>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: "title",
            key: "title",
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: "actions",
            width: "90px",
            //className: "__poi-actions",
            render: (record: PlanningTemplate) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <SpeedDial
                            key="poi-actions-speeddial"
                            title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            buttons={this.templateColumnsActions(record)}
                        />
                    </div>
                );
            },
        },
    ];

    componentDidMount() {
        if (isNullOrEmpty(this.props.templates)) {
            this.refreshTemplates();
        }
        Network.getTypeOfDay().then(
            response => this.props.changeTypesOfDay(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
        // get types of day
        Network.getTypeOfDayOff().then(
            response => this.props.changeTypesOfDayOff(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
    }

    toggleTemplateArchivState = (template: PlanningTemplate) => {
        const { intl } = this.props;
        let templates = this.props.templates ? cloneDeep(this.props.templates) : [];
        let archivedTemplates = cloneDeep(this.state.archivedTemplates);
        const newTemplateActive = !template.active;
        if (template && template.id) {
            Network.archivTemplate(template.id, newTemplateActive).then(
                (response: NetworkEvent) => {
                    const newTemplate = convertNetworkEventToPlanningEvent(response);
                    if (newTemplateActive) {
                        templates.push(newTemplate);
                        archivedTemplates = archivedTemplates.filter(t => t.id !== newTemplate.id);
                    } else {
                        templates = templates?.filter(t => t.id !== newTemplate.id);
                        archivedTemplates.push(newTemplate);
                    }

                    templates = templates.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));
                    archivedTemplates = archivedTemplates.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));

                    this.setState({ archivedTemplates });
                    this.props.changeTemplates(templates);

                    showNotification(template.active ? intl.formatMessage({ defaultMessage: 'The template has been successfully activated' }) : intl.formatMessage({ defaultMessage: 'The template has been successfully archived' }), "success");
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the template' }), "warning")
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while archiving the template' }), "warning")
        }

    };

    refreshTemplates = () => {
        this.setState({ loading: true });
        Network.getTemplates().then(
            response => {
                if (response.error) showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning");
                else {
                    this.props.changeTemplates!(convertNetworkEventsToPlanningEventsV2(response.data));
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the templates' }), "warning")
        ).finally(() => this.setState({ loading: false }));
    };

    refreshArchivedTemplated = () => {
        this.setState({ loadingArchived: true });
        Network.getArchivedTemplates().then(
            (response) => this.setState({ archivedTemplates: convertNetworkEventsToPlanningEvents(response) }),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the archived templates' }), "warning")
        ).finally(() => this.setState({ loadingArchived: false }));
    };

    onDeleteTemplate = (template: PlanningTemplate) => {
        Network.deleteTemplate(template.id!).then(
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The template has been successfully deleted' }), "success");
                this.refreshTemplates();
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the templates' }), "warning"),
        );
    };

    render() {
        const { archivedOnly, loading, loadingArchived, archivedTemplates } = this.state;
        const { templates, intl } = this.props;
        let tableHeight = this.props.height - 242;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <Card
                className="team-card"
                icon={<Anticon icon={<BsListTask />} />}
                title={<FormattedMessage defaultMessage={'Templates'} />}
                headerElements={[
                    <CircleButton
                        small
                        onClick={() => this.setState(prevState => {
                            const archivedOnly = !prevState.archivedOnly;
                            if (archivedOnly) {
                                this.refreshArchivedTemplated();
                            }
                            return ({ archivedOnly });
                        })}
                        type={archivedOnly ? "primary" : "default"}
                        icon={<FAIcon prefix={"fal"} name='box-archive' />}
                        title={archivedOnly ? intl.formatMessage({ defaultMessage: 'Show active templates' }) : intl.formatMessage({ defaultMessage: 'Show archived templates' })}
                        withoutTooltip
                        key="planning-template-toggleArchived"
                    />,
                    <CircleButton
                        small
                        onClick={() => this.setState({ editTemplateId: -1 })}
                        icon={<PlusOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Add a template' })}
                        placement="topLeft"
                        key="planning-template-add"
                    />,
                ]}
            >
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }}>
                        <Table
                            rowKey={(p: any) => p.id}
                            dataSource={archivedOnly ? archivedTemplates : templates}
                            columns={
                                this.props.isSmartphone
                                    ? this.templateColumnsMobile
                                    : this.templateColumns
                            }
                            loading={archivedOnly ? loadingArchived : loading}
                            pagination={false}
                            scroll={(archivedOnly ? archivedTemplates.length === 0 : (templates === undefined || templates.length === 0)) ? {} : { x: true, y: tableHeight }}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No template'} />} /> }}
                        />
                    </Col>
                </Row>
                {
                    this.state.editTemplateId !== undefined ?
                        <TemplateModal
                            visible={this.state.editTemplateId !== undefined}
                            templateId={this.state.editTemplateId}
                            isEdit={true}
                            onClose={() => this.setState({ editTemplateId: undefined })}
                        />
                        : null
                }
            </Card>
        );
    }
}


const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTemplates: (t: PlanningTemplate[]) => dispatch(changeTemplates(t)),
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
    changeTypesOfDayOff: (t: TypeOfDay[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    templates: state.planning.templates,
    isSmartphone: state.window.isSmartphone,
    height: state.window.height

});


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Template));