import React from 'react'
import Modal from 'antd/lib/modal/Modal';
import { Button, Select, DatePicker } from 'antd';
import { PlanningOvertime } from '../../utils/types/planningTypes';
import { displayErrorMessage, cloneOvertime } from '../../utils/utils';
import { ApplicationState } from '../../utils/types/storeTypes';
import { connect } from 'react-redux';
import { User } from '../../utils/types/generalTypes';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';

enum DataType { User, Title, Range }

interface Props {
    undertime: PlanningOvertime;
    users: User[] | undefined;
    loading: boolean;
    onCancel: () => void;
    onDone: (undertime: PlanningOvertime) => void;
}

interface State {
    undertime: PlanningOvertime;
}

/**
 * Component that represent the modal to create and edit an undertime
 */
class UndertimeModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            undertime: cloneOvertime(props.undertime),
        };
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        const { undertime } = this.state;

        switch (type) {
            case DataType.User:
                undertime.userId = value;
                break;
            case DataType.Title:
                undertime.title = value.target.value;
                break;
            case DataType.Range:
                undertime.startDate = value ? value[0] : undefined;
                undertime.endDate = value ? value[1] : undefined;
                break;
        }

        this.setState({ undertime });
    }

    /**
     * Called when the user is done editing/ creating
     */
    onDone = () => {
        const { undertime } = this.state;
        if (undertime.startDate.diff(undertime.endDate, 'minutes') === 0) displayErrorMessage("Les dates de début et de fin ne peuvent pas être pareilles.", "overtime-modal-ok-button");
        else this.props.onDone(undertime);
    }

    render() {
        const { undertime } = this.state;
        return (
            <Modal
                className="planning-modal"
                title={this.props.undertime.id ? (this.props.undertime.title && this.props.undertime.title.length > 0 ? this.props.undertime.title : "Modifier des heures négatives") : "Ajouter des heures négatives"}
                visible={Boolean(undertime)}
                style={{ minWidth: '620px' }}
                onCancel={this.props.onCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={this.props.onCancel} key="overtime-modal-cancel-button">
                        {'Annuler'}
                    </Button>,
                    <Button id="overtime-modal-ok-button" type="primary" onClick={this.onDone} loading={this.props.loading} key="planning-modal-button-ok">
                        {this.props.undertime.id ? 'Sauvegarder' : 'Créer'}
                    </Button>
                ]}>
                <div className="planning-overtime-modal-section">
                    <p>{"Utilisateur"}</p>
                    <Select
                        className="planning-overtime-modal-input"
                        showSearch
                        onChange={id => this.dataChanged(DataType.User, id)}
                        value={this.state.undertime.userId}
                        filterOption={true}
                        optionFilterProp="label">
                        {this.props.users!.map(u => <Select.Option label={`${u.last_name} ${u.first_name}`} key={`planning-overtime-user-${u.id}`} value={u.id!}>{`${u.last_name} ${u.first_name}`}</Select.Option>)}
                    </Select>
                </div>
                <div className="planning-overtime-modal-section" style={{ margin: '20px 0px' }}>
                    <p>{"Début et de fin"}</p>
                    <DatePicker.RangePicker
                        allowClear={false}
                        className="planning-overtime-modal-input"
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ format: "HH:mm" }}
                        placeholder={["Date de début", "Date de fin"]}
                        onChange={d => this.dataChanged(DataType.Range, d)}
                        value={[undertime.startDate ? undertime.startDate : undefined, undertime.endDate ? undertime.endDate : undefined] as any} />
                </div>
                <div className="planning-overtime-modal-section">
                    <p>{"Titre"}</p>
                    <InputField
                        className="planning-overtime-modal-input"
                        placeholder={"Titre"}
                        allowClear
                        value={undertime.title}
                        onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(DataType.Title, e)} />
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
});

export default connect(mapStateToProps)(UndertimeModal);