import { Button, Modal, Steps } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../utils/types/storeTypes';
import CreatePromotion from './createPromotion';

interface Props {
    createPromotion: boolean;
    closeModalCreatePromotion: (reload: boolean) => void;
    height: number;
}

interface State {
    currentStep: number;
    nextClients: boolean;
    nextMail: boolean;
    isModified: boolean;
    create: boolean;
}

/**
 * Component for the promotions tab in the CRM page
 */
class ModalCreatePromotion extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentStep: 0,
            nextClients: false,
            nextMail: false,
            isModified: false,
            create: false,
        };
    }

    setNextClients = (value: boolean) => {
        this.setState({ isModified: true, nextClients: value })
    }

    setNextMail = (value: boolean) => {
        this.setState({ isModified: true, nextMail: value })
    }

    closeModalCreatePromotion = (reload = false) => {
        this.props.closeModalCreatePromotion(reload);
        this.setState({ isModified: false, currentStep: 0, nextClients: false, nextMail: false, create: false });
    }

    cancelCreate = () => {
        this.setState({ create: false });
    }

    render() {
        const { createPromotion } = this.props;
        const { nextMail, nextClients, isModified, create } = this.state;
        let { currentStep } = this.state;

        return (

            <Modal
                centered={this.props.height < 900 ? true : false}
                style={this.props.height < 900 ? {} : { top: 100 }}
                key="modal-create-promotion"
                destroyOnClose={true}
                className="crm-promotion-create-modal"
                closable={true}
                keyboard={!isModified}
                maskClosable={!isModified}
                onCancel={() => this.closeModalCreatePromotion()}
                title={<FormattedMessage defaultMessage={'Create a promotion'} description={'Title'} />}
                visible={createPromotion}
                footer={[
                    <Button type="dashed" onClick={() => this.closeModalCreatePromotion()} key="modal-create-promotion-close">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <div key="modal-create-promotion-previous-next">
                        <Button type="default" disabled={currentStep === 0} onClick={() => this.setState({ currentStep: currentStep === 0 ? currentStep : currentStep -= 1 })} key="modal-create-promotion-previous">
                            <FormattedMessage defaultMessage={'Previous'} />
                        </Button>
                        <Button disabled={(currentStep === 0 && !nextClients) || (currentStep === 1 && !nextMail)} type="primary"
                            onClick={() => {
                                if (currentStep === 2) {
                                    this.setState({ create: true });
                                } else {
                                    this.setState({ currentStep: currentStep += 1 });
                                }
                            }}
                            key="modal-create-promotion-next">
                            {
                                currentStep === 2 ?
                                    <FormattedMessage defaultMessage={'Send'} />
                                    :
                                    <FormattedMessage defaultMessage={'Next'} />
                            }
                        </Button>
                    </div>,
                ]}
                width="1000px"
            >
                <Steps
                    type="navigation"
                    size="small"
                    current={currentStep}
                    onChange={(current) => {
                        this.setState({ currentStep: current })
                    }}
                    style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                    <Steps.Step title="Bon" key="promotion-modal-step-0" />
                    <Steps.Step disabled={!nextClients} title="Clients" key="promotion-modal-step-1" />
                    <Steps.Step disabled={!nextMail && !nextMail} title="Mail" key="promotion-modal-step-2" />
                    {/* <Steps.Step title="Résumé" key="promotion-modal-step-3" /> */}
                </Steps>
                <CreatePromotion cancelCreate={this.cancelCreate} finishCreatePromotion={() => this.closeModalCreatePromotion(true)} create={create} setNextClients={this.setNextClients} setNextMail={this.setNextMail} currentStep={currentStep} />
            </Modal>

        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    medals: state.crm.medals,
    countries: state.crm.countries,
    height: state.window.height,
});

export default connect(mapStateToProps)(ModalCreatePromotion);