import { CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import moment from 'moment';
import { BiDollar } from 'react-icons/bi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../../rbacRules';
import { MOMENT_FORMAT, PAGESIZEOPTIONS } from '../../../../utils/constants';
import { convertIAggregatedEventToIAggregatedEventWithUser } from '../../../../utils/dataConverter/customerConverters';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { IAggregatedEventWithUser } from '../../../../utils/types/customerTypes';
import { RouterProps } from '../../../../utils/types/generalTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import CircleButton from '../../../common/fields/circleButton';
import Anticon from '../../../common/general/anticon';
import Can from '../../../common/general/can';
import Card from '../../../common/general/card';
import { ContainerTabsItem, ContainerTabsItemProps } from '../../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
}

interface State {
    aggregatedEvents: IAggregatedEventWithUser[];
    updatedAt?: string;
    loading: boolean;
    loadingSet: boolean;
    selected: number[];
}

export class GlobalOfficeValidation extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            aggregatedEvents: [],
            loading: false,
            loadingSet: false,
            selected: []
        };
    }

    aggregatedColumns: ColumnProps<IAggregatedEventWithUser>[] = [
        {
            title: <FormattedMessage defaultMessage={'User'} />,
            key: 'user',
            className: '__min-width-200',
            sorter: (a, b) => {
                const aFullName = `${a.user?.first_name} ${a.user?.last_name}`.toLocaleLowerCase();
                const bFullName = `${b.user?.first_name} ${b.user?.last_name}`.toLocaleLowerCase();
                return aFullName.localeCompare(bFullName);
            },
            render: (_, record) => `${record.user?.first_name} ${record.user?.last_name}`,
        },
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            dataIndex: 'date',
            className: '__width_140',
            sorter: (a, b) => {
                return moment(a.date).diff(moment(b.date));
            },
            // defaultSortOrder: "ascend",
            render: (value) => moment(value).format(getFormat('DATE')),
        },
        {
            title: <FormattedMessage defaultMessage={'Duration'} />,
            key: 'duration',
            dataIndex: 'duration',
            className: '__width_120'
        },
        {
            title: <FormattedMessage defaultMessage={'Accounting code'} />,
            key: 'accounting',
            className: '__width_150 __centered-text',
            render: (_, record) => `${record.accountingCombination.analyticPlan.code}-${record.accountingCombination.rubric.code}`,
        },
        {
            title: <FormattedMessage defaultMessage={'Events'} />,
            key: 'splittedEvents',
            className: '__width_120 __centered-text',
            render: (_, record) => record.splittedEvents.length,
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            fixed: 'right',
            className: '__width_80 __centered-text',
            render: (_, record) => {
                return (
                    <CircleButton
                        icon={<CheckOutlined />}
                        title={"Modifier"}
                        placement="left"
                        onClick={() => this.onValidateAggregatedEvenets(record.id)} />
                );
            },
        }
    ];

    componentDidMount() {
        Network.getAggregatedEventsForGlobalOfficeShifts(moment().startOf("year"), moment()).then(
            (response) => {
                this.setState({ aggregatedEvents: convertIAggregatedEventToIAggregatedEventWithUser(response.data, this.props.users), updatedAt: moment().toISOString() });
            },
            () => showNotification("Erreur lors du chargement des horaires des employés", "error")
        ).finally(() => this.setState({ loading: false }));
    }

    getExtra = () => null;
    getFilters = () => null;

    onChangeAggregated = (keys: React.Key[]) => !this.state.loadingSet && this.setState({ selected: keys as number[] });

    onValidateAggregatedEvenets = (id?: number) => {
        const { selected } = this.state;
        const ids: number[] = [];

        if (id) ids.push(id);
        else ids.concat(selected);

        if (ids.length > 0) {
            this.setState({ loadingSet: true })
            Network.setAggregatedEvents(ids).then(
                response => {
                    this.setState(prevState => {
                        return { loadingSet: false, selected: id ? prevState.selected : [], updatedAt: moment().toISOString(), aggregatedEvents: prevState.aggregatedEvents.filter(a => response.data.findIndex(d => d.id === a.id) === -1) }
                    })
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while validation the selected hours' }), "error")
            ).finally(() => this.setState({ loadingSet: false }));
        }
    }

    render() {
        const { loading, loadingSet, aggregatedEvents, selected } = this.state;
        let tableHeight = this.props.height - 349;
        if (tableHeight < 250) tableHeight = 250;
        const pageSizeOptions: number[] = PAGESIZEOPTIONS.filter(e => e < aggregatedEvents.length);

        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <Card
                    title={"Global Office"}
                    icon={<Anticon><BiDollar /></Anticon>}
                    headerElements={[
                        <CircleButton
                            loading={loading || loadingSet}
                            disabled={selected.length === 0}
                            withoutTooltip
                            key="globalOfficeValidate"
                            icon={<CheckOutlined />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Validate selected hours' })}
                            onClick={() => this.onValidateAggregatedEvenets()}
                        />
                    ]}>
                    <Table
                        rowKey={(ae) => ae.id}
                        dataSource={aggregatedEvents}
                        columns={this.aggregatedColumns}
                        loading={loading}
                        scroll={aggregatedEvents.length === 0 ? undefined : { x: true, y: tableHeight }}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeAggregated, selectedRowKeys: selected }}
                        expandable={{
                            expandedRowRender: record => {
                                return (
                                    <div>
                                        <p><FormattedMessage defaultMessage={'Related event(s)'} /></p>
                                        <ul>
                                            {
                                                record.splittedEvents.map(splittedEvent => {
                                                    return (
                                                        <li key={`splitted-event-${splittedEvent.id}`}><FormattedMessage defaultMessage={'The event on {date} from {start} to {end} (duration: {time})'} values={{ date: moment(splittedEvent.date).format(getFormat('DATE')), start: moment(splittedEvent.startDatetime, MOMENT_FORMAT).format(getFormat('TIME_SHORT')), end: moment(splittedEvent.endDatetime, MOMENT_FORMAT).format(getFormat('TIME_SHORT')), time: splittedEvent.duration }} /></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            },
                            rowExpandable: record => record.splittedEvents.length > 0
                        }}
                        pagination={{
                            defaultPageSize: 16,
                            pageSizeOptions: pageSizeOptions,
                            showSizeChanger: true,
                            hideOnSinglePage: false,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: range[0], range1: range[1], total }} />
                        }} />
                </Card>
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    users: state.teamManagement.users
});

const connector = connect(mapStateToProps);

export default connector(withRouter(injectIntl(GlobalOfficeValidation)));
