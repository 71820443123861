import React from 'react';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import { customersFetched } from '../../store/features/customerManagement';
import { ContainerTabsState, RouterProps } from '../../utils/types/generalTypes';
import { StoreDispatch } from '../../utils/types/storeTypes';
import { IntlProps } from '../app/LanguageProvider';
import Can from '../common/general/can';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateFilters } from '../common/navigations/containerTabs';
import { MenuLinks } from '../common/navigations/sideMenu';
import Customers from './customers/customers';
import Mandates from './mandates/mandates';
import Missions from './missions/missions';


type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, RouterProps, IntlProps { }
interface State extends ContainerTabsState {
    tabFiltersActivated?: { status: boolean, key: string }[];
}

export const enum CustomerManagementTabLink {
    CUSTOMERS = "customers",
    MANDATES = "mandates",
    MISSIONS = "missions",
}

class CustomersManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tabItems: [
                {
                    key: CustomerManagementTabLink.CUSTOMERS,
                    label: props.intl.formatMessage({ defaultMessage: 'Customers' }),
                    children: <Customers keyLink={CustomerManagementTabLink.CUSTOMERS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                },
                {
                    key: CustomerManagementTabLink.MANDATES,
                    label: props.intl.formatMessage({ defaultMessage: 'Mandates' }),
                    children: <Mandates keyLink={CustomerManagementTabLink.MANDATES} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                },
                {
                    key: CustomerManagementTabLink.MISSIONS,
                    label: props.intl.formatMessage({ defaultMessage: 'Missions' }),
                    children: <Missions keyLink={CustomerManagementTabLink.MISSIONS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                },
            ]
        };
        this.props.customersFetched();
    }

    componentDidMount() {
        this.props.customersFetched();
    }

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));

    addOrUpdateFilters = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateFilters(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfFilters } = this.state;
        const { intl } = this.props;
        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <ContainerTabs
                    contentClassName='__new-right-panel'
                    breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'CRM' }), link: `/${MenuLinks.CRM}/${this.props.match.params.tab}` }]}
                    tabItems={tabItems}
                    tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                    tabFilters={tabListOfFilters?.find(item => item.key === this.props.match.params.tab)?.content}
                />
            </Can>
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    customersFetched: (forceReload?: boolean) => dispatch(customersFetched({ forceReload: forceReload ?? false })),
});
const connector = connect(null, mapDispatchToProps);

export default connector(injectIntl(CustomersManagement));
