import { CheckOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Col, Row, Segmented, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import toFinite from 'lodash/toFinite';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import '../../styles/messages.css';
import { usersColumns } from '../../utils/constants';
import Network from '../../utils/network';
import { Group, RouterProps, User } from '../../utils/types/generalTypes';
import { CreateMessageBody, CreateMessageBodyRefType } from '../../utils/types/networkTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { alert } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import SwitchLabeled from '../common/fields/switchLabeled';
import Card from '../common/general/card';
import SimpleBloc from '../common/general/simpleBloc';
import Container from '../common/navigations/container';

interface IProps {
    currentUser: User;
    windowHeigth: number;
    windowWidth: number;
    isSmartphone: boolean;
}

type Props = IProps & RouterProps & IntlProps;

interface State {
    values: CreateMessageBody;
    users: User[];
    usersFilter: User[];
    groups: Group[];
}

class CreateMessage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            values: {
                title: "",
                text: "",
                optional: false,
                ref_id: undefined,
                ref_ids: undefined,
                ref_type: CreateMessageBodyRefType.USER,
            },
            usersFilter: [],
            users: [],
            groups: [],
        }
    }

    columns = usersColumns.filter(o => o.key === "first_name" || o.key === "last_name" || o.key === "image" || o.key === "role");

    componentDidMount() {
        //get all users
        Network.getAllUsers().then(
            response => this.setState({ users: response }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
        );

        //get all groups
        Network.getGroups().then(
            response => this.setState({ groups: response }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning")
        );
    }

    /**
     * Decide weither or not to show the create button
     */
    showButton = (): boolean => {
        const { values } = this.state;
        let bool: boolean = values.ref_type !== undefined && values.title.length > 0 && values.text.length > 0;

        if (values.ref_type === "user")
            bool = bool && values.ref_ids !== undefined && values.ref_ids.length > 0;

        if (values.ref_type === "group")
            bool = bool && values.ref_ids !== undefined && values.ref_ids.length > 0;

        return bool;
    }

    /**
     * Called when the ref type is changed
     */
    onChangeRefType = (val: string) => {
        const { values } = this.state;
        values.ref_type = val as CreateMessageBodyRefType;
        values.ref_id = undefined;
        values.ref_ids = undefined;
        this.setState({ values });
    }

    /**
    * Called when a attribute of values changed
    */
    onChangeValue = (event: InputFieldOnChangeEvent | RadioChangeEvent, name: string) => {
        const { values } = this.state;
        values[name] = event.target.value;
        this.setState({ values });
    }

    /**
     * Called when the value of optional changed
     */
    onChangeOptional = (checked: boolean) => {
        const { values } = this.state;
        values.optional = checked;
        this.setState({ values });
    }

    /**
     * Called when a user is (un)checked
     * @param keys the new keys of checked users
     */
    onChangeUsers = (groupsIds: number[]) => {
        const { values } = this.state;
        values.ref_ids = groupsIds;
        this.setState({ values });
    }

    /**
     * Called when a group is (un)checked
     * @param keys the new keys of checked groups
     */
    onChangeGroups = (userIds: number[]) => {
        const { values } = this.state;
        values.ref_ids = userIds;
        this.setState({ values });
    }

    /**
     * Handle creation of message
     */
    handleCreateMessage = () => {
        const { values } = this.state;

        if (values.ref_type === CreateMessageBodyRefType.USER) {
            values.ref_ids = (values.ref_ids as string[]).map((id: string) => parseInt(id));
            values.ref_id = values.ref_ids![0];
        } else if (values.ref_type === CreateMessageBodyRefType.GROUP) {
            values.ref_id = parseInt(values.ref_id as string);
        } else if (values.ref_type === CreateMessageBodyRefType.COMPANY) {
            values.ref_id = this.props.currentUser.company_id as number;
        }

        Network.createMessage(values).then(
            () => this.props.history.push({ pathname: `/${this.props.match.params.lang}/messages/list`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The message has been successfully sent' }) } }),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the message' }), "warning")
        );
    }

    search = (value: string) => {
        const { users } = this.state;

        const usersFilter = users.filter(o =>
            o.first_name.toLowerCase().includes(value.toLowerCase()) || o.last_name.toLowerCase().includes(value.toLowerCase())
        );

        this.setState({ usersFilter });
    };

    render() {
        const { isSmartphone, intl } = this.props;
        const { values, users, groups } = this.state;

        return (
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Messaging' }), link: "/messages/list" }, { title: intl.formatMessage({ defaultMessage: 'Add a message' }), link: "/messages/create" }]}>
                <Row gutter={[20, 20]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 22 }}
                        lg={{ span: 20 }}
                        xl={{ span: 18 }}
                        xxl={{ span: 16 }}
                    >
                        <Card icon={<FormOutlined />} title={<FormattedMessage defaultMessage={'Write a new message'} />}>
                            <Row gutter={[20, 20]}>
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Row gutter={[20, 20]}>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <InputField
                                                style={{ width: isSmartphone ? '100%' : '80%' }}
                                                maxLength={100}
                                                showCount
                                                onChange={(e: InputFieldOnChangeEvent) => this.onChangeValue(e, "title")}
                                                value={values.title}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Title' })} />
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <InputField
                                                type="textarea"
                                                autoSize={{ minRows: 3 }}
                                                maxLength={300}
                                                showCount
                                                onChange={(e: InputFieldOnChangeEvent) => this.onChangeValue(e, "text")}
                                                value={values.text}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Message' })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Row gutter={[20, 20]}>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>

                                            <Segmented
                                                block
                                                size={isSmartphone ? 'middle' : 'large'}
                                                style={{ width: "100%", fontSize: isSmartphone ? '12px' : '14px' }}
                                                key={'timeclock-show-options'}
                                                value={values.ref_type}
                                                options={[
                                                    {
                                                        value: CreateMessageBodyRefType.USER,
                                                        label: intl.formatMessage({ defaultMessage: 'For selected users' })
                                                    },
                                                    {
                                                        value: CreateMessageBodyRefType.GROUP,
                                                        label: intl.formatMessage({ defaultMessage: 'For specified groups' })
                                                    },
                                                    {
                                                        value: CreateMessageBodyRefType.COMPANY,
                                                        label: intl.formatMessage({ defaultMessage: 'For everyone' })
                                                    },
                                                ]}
                                                onChange={(mode) => this.onChangeRefType(mode as any)}
                                            />
                                        </Col>
                                        {
                                            values.ref_type && [CreateMessageBodyRefType.USER, CreateMessageBodyRefType.GROUP].includes(values.ref_type) ?
                                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                                    <SimpleBloc>
                                                        {
                                                            values.ref_type === CreateMessageBodyRefType.USER ?
                                                                <>
                                                                    <SwitchLabeled
                                                                        style={{ marginBottom: '8px' }}
                                                                        onChange={this.onChangeOptional}
                                                                        label={intl.formatMessage({ defaultMessage: 'Confirmation option for recipients' })}
                                                                        checked={values.optional}
                                                                        checkedChildren={<CheckOutlined />}
                                                                        unCheckedChildren={<CloseOutlined />}
                                                                    />
                                                                    <Select
                                                                        mode="multiple"
                                                                        allowClear
                                                                        placeholder={<FormattedMessage defaultMessage={'Select users'} />}
                                                                        style={{ width: '100%' }}
                                                                        className='__select-auto-height'
                                                                        value={values.ref_ids?.map(id => toFinite(id))}
                                                                        onChange={this.onChangeUsers}
                                                                        filterOption={true}
                                                                        optionFilterProp="label"
                                                                        showArrow
                                                                    >
                                                                        {
                                                                            users.map((u) => {
                                                                                const label = `${u.last_name} ${u.first_name}`;
                                                                                return <Select.Option label={label} value={u.id} key={"users-" + u.id}>{label}</Select.Option>;
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </>
                                                                :
                                                                values.ref_type === CreateMessageBodyRefType.GROUP ?
                                                                    <Select
                                                                        mode="multiple"
                                                                        allowClear
                                                                        placeholder={<FormattedMessage defaultMessage={'Select groups'} />}
                                                                        style={{ width: '100%' }}
                                                                        className='__select-auto-height'
                                                                        value={values.ref_ids?.map(id => toFinite(id))}
                                                                        onChange={this.onChangeGroups}
                                                                        filterOption={true}
                                                                        optionFilterProp="label"
                                                                        showArrow
                                                                    >
                                                                        {
                                                                            groups.map((g) => {
                                                                                const label = g.name;
                                                                                return <Select.Option value={g.id} key={"groups-" + g.id} label={label}>{label}</Select.Option>;
                                                                            })
                                                                        }
                                                                    </Select>
                                                                    : null
                                                        }
                                                    </SimpleBloc>
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col >
                </Row >
                <Row>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 22 }}
                        lg={{ span: 20 }}
                        xl={{ span: 18 }}
                        xxl={{ span: 16 }}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            type="primary"
                            className="messages-submit"
                            onClick={this.handleCreateMessage}
                            disabled={!this.showButton()}
                        >
                            <FormattedMessage defaultMessage={'Send'} />
                        </Button>
                    </Col>
                </Row>
            </Container >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    windowHeigth: state.window.height,
    windowWidth: state.window.width,
    currentUser: state.user.currentUser as User,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps)(injectIntl(CreateMessage));