import { ArrowDownOutlined, ExportOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Badge, Col, DatePicker, Divider, Popover, Row, Select, Space, Spin } from "antd";
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import React, { forwardRef } from "react";
import { BsCalendar2Week } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { BLUE_COLOR, GREEN_COLOR, MOMENT_FORMAT_DATE_TO_NETWORK, RED_COLOR, YELLOW_COLOR } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { DashboardOccupancyRate } from "../../../utils/types/generalTypes";
import { NetworkOccupancyRate } from "../../../utils/types/networkTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { cloneMomentWithLocale, showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import CircleButton from "../../common/fields/circleButton";
import Card from "../../common/general/card";

const enum TypeFilled {
    ALL = 0,
    UNDERFILLED = 1,
    FILLED = 2,
    OVERFILLED = 3,
}

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    filter?: boolean;
    monthly?: boolean;
    createNewEvent?: (date: Moment, occupancyRate: NetworkOccupancyRate) => void;
    startDate?: Moment;
    endDate?: Moment;
    pickedStartDate?: Moment;
    refresh?: boolean;
    changePickedStartDate?: (date: Moment,) => void;
    showEventDetails?: (eventId?: number) => void;
}

interface State {
    occupancyRates?: DashboardOccupancyRate[];
    loadingOccupancyRates: boolean;
    typeFilled: TypeFilled;
    occupancyRatesTitles: string[];
    occupancyRatesTitlesSelected: string[];
    filterOccupancyRates?: DashboardOccupancyRate[];
    startDate: Moment;
    endDate: Moment;
    pickedStartDate: Moment;
    pickedEndDate: Moment;
    changeMonthButton: boolean;
    loading: number;
    data_last_updated: {
        updated_at: Moment;
        start_day: Moment;
        end_day: Moment;
    } | undefined,
}

class OccupancyRatesControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const dateStart = this.props.startDate ? moment(this.props.startDate).utc(true).set({ second: 0 }) : moment();
        const dateEnd = this.props.filter && this.props.startDate ? moment(this.props.startDate).utc(true).set({ second: 0 }) : moment().add(2, 'd');
        const pickedDateStart = this.props.pickedStartDate ? this.props.pickedStartDate.clone().utc(true).set({ second: 0 }) : dateStart.clone();
        const pickedDateEnd = this.props.pickedStartDate ? this.props.pickedStartDate.clone().utc(true).set({ second: 0 }) : dateEnd.clone();

        this.state = {
            occupancyRates: [],
            loadingOccupancyRates: false,
            typeFilled: TypeFilled.ALL,
            occupancyRatesTitles: [],
            occupancyRatesTitlesSelected: [],
            loading: 0,
            startDate: dateStart,
            endDate: dateEnd,
            pickedStartDate: pickedDateStart,
            pickedEndDate: pickedDateEnd,
            changeMonthButton: true,
            data_last_updated: undefined,
        };

    }
    componentDidMount() {
        this.updateDays();

        console.log(this.props.startDate);
        console.log(this.props.endDate);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.startDate &&
            this.props.endDate &&
            (!prevProps.startDate?.isSame(this.props.startDate, "days") || !prevProps.endDate?.isSame(this.props.endDate, "days"))
        ) {
            const date = this.props.filter && this.props.startDate ? this.props.startDate.clone().utc(true) : moment();
            this.setState({ startDate: date, endDate: date, pickedStartDate: date, pickedEndDate: date }, () => {
                this.updateDays();
            });
        }
        else if (
            !this.state.loadingOccupancyRates &&
            this.props.pickedStartDate !== undefined &&
            !this.props.pickedStartDate.isSame(prevProps.pickedStartDate, "d")
        ) {
            this.setState({ pickedStartDate: this.props.pickedStartDate, pickedEndDate: this.props.pickedStartDate }, () => {
                this.updateDays();
            });

        }
        else if (
            !this.state.loadingOccupancyRates &&
            (this.props.refresh !== prevProps.refresh)
        ) {
            this.updateDays();
        }
    }

    getDays = (start_day: Moment, end_day: Moment, force = false) => {
        if (force || !this.state.loadingOccupancyRates) {
            Network.getDashboardOccupancyRatesV2(start_day.format(MOMENT_FORMAT_DATE_TO_NETWORK), end_day.format(MOMENT_FORMAT_DATE_TO_NETWORK))
                .then((response: { [day: string]: (DashboardOccupancyRate)[] | null; }) => {

                    const occupancyRates: DashboardOccupancyRate[] = [];
                    let occupancyRatesTitles: string[] = [];

                    Object.keys(response).forEach((key) => {
                        if (key != null && response[key] != null && Object.keys(response[key]!).length) {
                            for (let i = 0; i < Object.keys(response[key]!).length; i++) {
                                if (response[key]![i] !== null && response[key]![i] !== undefined) {
                                    const data = response[key]![i];
                                    data.events = data.events.map(e => {
                                        return {
                                            ...e,
                                            loadedUser: this.props.users.find(u => u.id === e.userId)
                                        };
                                    });
                                    occupancyRates.push(data);
                                    occupancyRatesTitles.push(data.occupancyRate.title);
                                }
                            }
                        }
                    });
                    occupancyRatesTitles = Array.from(new Set(occupancyRatesTitles.sort((a, b) => a.localeCompare(b))));
                    occupancyRates.sort((a, b) => a.occupancyRate.title < b.occupancyRate.title ? -1 : 1);

                    this.setState({ changeMonthButton: false, occupancyRates: occupancyRates, loadingOccupancyRates: false, occupancyRatesTitles, startDate: start_day, endDate: end_day, data_last_updated: { updated_at: moment(), start_day, end_day } });
                })
                .catch((error) => {
                    console.log(`heeeere ${error}`);
                    this.setState({ changeMonthButton: false, occupancyRates: [], loadingOccupancyRates: false, occupancyRatesTitles: [] });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading dashboard information' }), "warning");
                });
        }
    };

    updateDays = () => {
        const { pickedStartDate, pickedEndDate } = this.state;
        const start_day = this.props.pickedStartDate ? this.props.pickedStartDate.clone().utc(true) : pickedStartDate.clone().utc(true);
        const end_day = this.props.pickedStartDate ? this.props.pickedStartDate.clone().utc(true) : pickedEndDate.clone().utc(true);

        if (
            !this.state.loadingOccupancyRates && (
                (this.state.data_last_updated === undefined) ||
                ((this.state.data_last_updated.start_day.toDate().toUTCString() === start_day.toDate().toUTCString()) && (this.state.data_last_updated.end_day.toDate().toUTCString() === end_day.toDate().toUTCString()) && (this.state.data_last_updated.updated_at < moment().subtract(0.05, 'minutes'))) ||
                (this.state.data_last_updated.start_day.toDate().toUTCString() !== start_day.toDate().toUTCString()) ||
                (this.state.data_last_updated.end_day.toDate().toUTCString() !== end_day.toDate().toUTCString())
            )
        ) {
            this.setState({ loadingOccupancyRates: true }, () => {
                this.getDays(start_day, end_day, true);
            });
        }

    };

    onChangeDateRange = (value: any) => {
        let { pickedStartDate, pickedEndDate } = this.state;
        pickedStartDate = value[0];
        pickedEndDate = value[1];

        this.setState({ pickedStartDate, pickedEndDate }, () => {
            this.validateDateRange();
        });
    };

    validateDateRange = () => {
        if (this.state.pickedStartDate && this.state.pickedEndDate) {
            this.getDays(this.state.pickedStartDate, this.state.pickedEndDate);
        }
    };

    onChangeDate = (value: any) => {
        let { pickedStartDate, pickedEndDate } = this.state;
        pickedStartDate = value;
        pickedEndDate = value;

        if (this.props.changePickedStartDate) {
            this.props.changePickedStartDate(pickedStartDate);
        } else {
            this.setState({ pickedStartDate, pickedEndDate }, () => {
                this.validateDate();
            });
        }
    };
    onChangeDatePrev = () => {
        const date = this.props.pickedStartDate ? this.props.pickedStartDate.clone() : this.state.pickedStartDate.clone();
        const day = date.clone().subtract(1, "day").startOf("day");
        this.onChangeDate(day);
    };

    onChangeDateNext = () => {
        const date = this.props.pickedStartDate ? this.props.pickedStartDate.clone() : this.state.pickedStartDate.clone();
        const day = date.add(1, "day").startOf("day");
        this.onChangeDate(day);
    };

    validateDate = () => {
        if (this.state.pickedStartDate && this.state.pickedEndDate) {
            this.getDays(this.state.pickedStartDate, this.state.pickedEndDate);
        }
    };

    onChangeOccupancyRatesTitles = (value: string[]) => this.setState({ occupancyRatesTitlesSelected: value });

    onChangeFilled = (value: SelectValue) => this.setState({ typeFilled: value ? parseInt(value.toString()) : TypeFilled.ALL });

    getOccupancyRateFrequencyText = (frequency: number): string => {
        const { intl } = this.props;
        switch (frequency) {
            case 1:
                return intl.formatMessage({ defaultMessage: 'this week' });
            case 2:
                return intl.formatMessage({ defaultMessage: 'this month' });
            case 3:
                return intl.formatMessage({ defaultMessage: 'this year' });
            default:
                return intl.formatMessage({ defaultMessage: 'today' });
        }
    };

    disableDate = (date: any): boolean => {
        const { startDate, endDate } = this.props;

        return (date && startDate && endDate && (date.isBefore(startDate) || date.isAfter(endDate)));
    };

    renderOccupancyRateLine = (or: DashboardOccupancyRate, color = GREEN_COLOR) => {
        const { intl } = this.props;
        return (
            <Popover
                overlayStyle={{ maxWidth: '390px' }}
                mouseEnterDelay={0.5}
                trigger={this.props.filter ? 'hover' : 'click'}
                key={`dashboard-occupancy-rate-fullfilled-${or.occupancyRate.id}`}
                content={
                    <Space direction="vertical" size={"large"}>
                        {
                            or.count > 0 &&
                            <FormattedMessage
                                defaultMessage={'{count, plural, one {1 event is missing {title} {skill} {location} {time}.} other {{count} events are missing {title} {skill} {location} {time}.}}'}
                                values={{
                                    count: or.occupancyRate.quantity - or.numberOfEvents,
                                    title: or.occupancyRate.typeOfDay ? or.occupancyRate.typeOfDay.title + ", " : '',
                                    skill: or.occupancyRate.staffType ? intl.formatMessage({ defaultMessage: 'with ability "{name}", ' }, { name: or.occupancyRate.staffType.name }) : '',
                                    location: or.occupancyRate.poi ? intl.formatMessage({ defaultMessage: 'from location "{name}", ' }, { name: or.occupancyRate.poi.title }) : '',
                                    time: intl.formatMessage({ defaultMessage: 'for {type}' }, { type: this.getOccupancyRateFrequencyText(or.occupancyRate.frequency) })
                                }} />
                        }
                        {
                            or.numberOfEventsOutOfHours > 0 &&
                            <FormattedMessage defaultMessage={'{count, plural, one {There is 1 event that does not respect hours} other {There are {count} events that does not respect hours}}'} values={{ count: or.numberOfEventsOutOfHours }} />
                        }
                        {
                            or.numberOfEvents > 0 ?
                                <div>
                                    <b><FormattedMessage defaultMessage={'Events that meet the conditions:'} /></b>
                                    {
                                        or.events.map((event) => {
                                            return <p style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} key={`ff-event-id-${event.id}-${or.occupancyRate.id}`} onClick={() => this.props.showEventDetails && this.props.showEventDetails(event.id)}><span>{event.loadedUser ? `${event.loadedUser.first_name} ${event.loadedUser.last_name}` : event.userId}:</span> <span>{event.title}</span></p>;
                                        })
                                    }
                                </div>
                                : null
                        }
                    </Space>
                }
            >
                <div onDoubleClick={() => this.props.createNewEvent && this.props.createNewEvent(this.state.pickedStartDate.clone(), or.occupancyRate)} style={{ cursor: 'pointer' }} className="dashboard-occupancy-rate-list">
                    <p>
                        {
                            this.props.filter && <PlusCircleOutlined onClick={() =>
                                this.props.createNewEvent && this.props.createNewEvent(this.state.pickedStartDate.clone(), or.occupancyRate)
                            } style={{ marginRight: '5px' }} />
                        }
                        {or.occupancyRate.title}
                    </p>
                    <Badge offset={[-3, 2]} dot={true} count={or.numberOfEventsOutOfHours} size="small" color={YELLOW_COLOR}>
                        <div className={"dashboard-occupancy-rate-line"} style={{ backgroundColor: color }}>
                            <p>{`${or.numberOfEvents}/${or.occupancyRate.quantity} ${this.props.filter ? '' : intl.formatMessage({ defaultMessage: '{count, plural, one {event} other {events}}' }, { count: or.count })}`}</p>
                        </div>
                    </Badge>
                </div>
            </Popover>
        );
    };

    render() {
        const { occupancyRates, startDate, endDate, loadingOccupancyRates, typeFilled, occupancyRatesTitles, occupancyRatesTitlesSelected } = this.state;
        const { pickedStartDate, intl } = this.props;
        const day = pickedStartDate ? pickedStartDate.clone() : startDate.clone();
        const dayToDisplay = cloneMomentWithLocale(day).subtract(1, 'days');

        const underFilled: Array<DashboardOccupancyRate[]> = [];
        const fullFilled: Array<DashboardOccupancyRate[]> = [];
        const overFilled: Array<DashboardOccupancyRate[]> = [];
        let nbOfDays = 0;
        for (let i = moment(startDate); i <= endDate; i.add(1, 'days')) {
            nbOfDays++;
            if (occupancyRatesTitlesSelected.length !== 0) {
                underFilled.push(occupancyRates!.filter(item => item.count > 0 && moment(item.date).isSame(i, 'date') && occupancyRatesTitlesSelected.includes(item.occupancyRate.title)));
                fullFilled.push(occupancyRates!.filter(item => item.count === 0 && moment(item.date).isSame(i, 'date') && occupancyRatesTitlesSelected.includes(item.occupancyRate.title)));
                overFilled.push(occupancyRates!.filter(item => item.count < 0 && moment(item.date).isSame(i, 'date') && occupancyRatesTitlesSelected.includes(item.occupancyRate.title)));
            } else {
                underFilled.push(occupancyRates!.filter(item => item.count > 0 && moment(item.date).isSame(i, 'date')));
                fullFilled.push(occupancyRates!.filter(item => item.count === 0 && moment(item.date).isSame(i, 'date')));
                overFilled.push(occupancyRates!.filter(item => item.count < 0 && moment(item.date).isSame(i, 'date')));
            }
        }
        const { Option } = Select;

        if (this.props.monthly) {
            return (
                <>
                    <div style={{ paddingBottom: '10px', position: "sticky", top: "0", backgroundColor: "white", zIndex: 2 }}>
                        <Row gutter={[10, 10]}>
                            <Col key="col-month-occupancyRates-1" xs={24} sm={{ span: 0 }} style={{ textAlign: 'right', paddingRight: '6px' }}>
                                <DatePicker.RangePicker
                                    style={{ width: '250px' }}
                                    key="picker-month-occupancyRates"
                                    ranges={{
                                        [intl.formatMessage({ defaultMessage: 'Today' })]: [moment(), moment()],
                                        [intl.formatMessage({ defaultMessage: 'This week' })]: [moment().startOf('week'), moment().endOf('week')],
                                        [intl.formatMessage({ defaultMessage: 'This month' })]: [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    allowClear={false}
                                    value={[this.state.pickedStartDate, this.state.pickedEndDate]}
                                    onChange={this.onChangeDateRange}
                                    format={getFormat('DATE')}
                                    disabled={loadingOccupancyRates}
                                />
                                <CircleButton small={true} key={`occupancy-rates-validate_date`} onClick={() => this.validateDateRange()} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Confirm dates' })} icon={<ExportOutlined />} />
                            </Col>
                            {
                                this.props.filter &&
                                <Col key="col-month-occupancyRates" xs={24} style={{ textAlign: 'right', paddingRight: '6px', display: 'flex', gap: '5px' }}>

                                    <CircleButton
                                        small
                                        icon={<LeftOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Previous day' })}
                                        onClick={this.onChangeDatePrev}
                                        disabled={loadingOccupancyRates || this.props.startDate?.isSame(this.props.endDate, 'days') || this.props.pickedStartDate?.isSame(this.props.startDate, "d")} />
                                    <DatePicker
                                        disabledDate={this.disableDate}
                                        style={{ width: '100%' }}
                                        key="picker-month-occupancyRates"
                                        allowClear={false}
                                        value={this.props.pickedStartDate ? this.props.pickedStartDate : this.state.pickedStartDate}
                                        onChange={this.onChangeDate}
                                        format={getFormat('DATE')}
                                        disabled={loadingOccupancyRates || this.props.startDate?.isSame(this.props.endDate, 'days')}
                                    />
                                    <CircleButton
                                        small
                                        icon={<RightOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Next day' })}
                                        onClick={this.onChangeDateNext}
                                        disabled={loadingOccupancyRates || this.props.startDate?.isSame(this.props.endDate, 'days') || this.props.pickedStartDate?.isSame(this.props.endDate, "d")} />
                                </Col>
                            }
                            {
                                <Col xs={24} md={{ span: 12 }}>
                                    <Select
                                        autoClearSearchValue={false}
                                        value={occupancyRatesTitlesSelected}
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        allowClear
                                        placeholder={<FormattedMessage defaultMessage={'Type of day'} />}
                                        onChange={this.onChangeOccupancyRatesTitles}
                                        style={{ width: '100%' }}
                                        disabled={loadingOccupancyRates}
                                        showArrow
                                    >
                                        {
                                            occupancyRatesTitles.sort((a, b) => a.localeCompare(b)).map((o, c) => {
                                                return <Option value={o} key={o + c}>{o}</Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            }
                            <Col xs={24} md={{ span: 12 }}>
                                <Select
                                    defaultValue={TypeFilled.ALL}
                                    value={typeFilled}
                                    placeholder={<FormattedMessage defaultMessage={'Events to display'} />}
                                    onChange={this.onChangeFilled}
                                    style={{ width: '100%' }}
                                    disabled={loadingOccupancyRates}
                                    showArrow
                                >
                                    <Option value={TypeFilled.ALL}><FormattedMessage defaultMessage={'All events'} /></Option>
                                    <Option value={TypeFilled.UNDERFILLED}><FormattedMessage defaultMessage={'Missing events'} /></Option>
                                    <Option value={TypeFilled.FILLED}><FormattedMessage defaultMessage={'Complete events'} /></Option>
                                    <Option value={TypeFilled.OVERFILLED}><FormattedMessage defaultMessage={'Overloaded events'} /></Option>
                                </Select>
                            </Col>
                        </Row>
                    </div>
                    {
                        loadingOccupancyRates ?
                            <div style={{ textAlign: 'center' }}>
                                <Spin />
                            </div>
                            :
                            [...Array(nbOfDays).keys()].map((_, index) => {
                                dayToDisplay.add(1, 'days');
                                return (
                                    <div key={`div-occupancy-rate-${dayToDisplay.format('MM/DD/YYYY')}`}>
                                        {
                                            !this.props.filter &&
                                            <Divider className="dashboard-section-title"><ArrowDownOutlined style={{ fontSize: "10px" }} />{dayToDisplay.format(getFormat('DATE_LONG'))} <ArrowDownOutlined style={{ fontSize: "10px" }} /></Divider>
                                        }
                                        {
                                            [TypeFilled.ALL, TypeFilled.UNDERFILLED].includes(typeFilled) && underFilled[index].sort((a, b) => {
                                                const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                if (nameA < nameB)
                                                    return -1;
                                                if (nameA > nameB)
                                                    return 1;
                                                return 0;
                                            }).map(or => this.renderOccupancyRateLine(or, RED_COLOR))
                                        }
                                        {
                                            [TypeFilled.ALL, TypeFilled.FILLED].includes(typeFilled) && fullFilled[index].sort((a, b) => {
                                                const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                if (nameA < nameB)
                                                    return -1;
                                                if (nameA > nameB)
                                                    return 1;
                                                return 0;
                                            }).map(or => this.renderOccupancyRateLine(or, GREEN_COLOR))
                                        }
                                        {
                                            [TypeFilled.ALL, TypeFilled.OVERFILLED].includes(typeFilled) && overFilled[index].sort((a, b) => {
                                                const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                if (nameA < nameB)
                                                    return -1;
                                                if (nameA > nameB)
                                                    return 1;
                                                return 0;
                                            }).map(or => this.renderOccupancyRateLine(or, BLUE_COLOR))
                                        }
                                    </div>
                                );
                            })
                    }
                </>
            );
        } else {
            return (
                <Card
                    className="dashboard-card"
                    icon={<BsCalendar2Week className="anticon" />}
                    title={<FormattedMessage defaultMessage={'Requirements control'} />}
                    containerClassName="dashboard-scrollable"
                    headerElements={[
                        <Col key="col-month-occupancyRates" xs={0} sm={this.props.filter ? { span: 0 } : { span: 24 }}>
                            <DatePicker.RangePicker
                                style={{ width: '250px' }}
                                key="picker-month-occupancyRates"
                                ranges={{
                                    [intl.formatMessage({ defaultMessage: 'Today' })]: [moment(), moment()],
                                    [intl.formatMessage({ defaultMessage: 'This week' })]: [moment().startOf('week'), moment().endOf('week')],
                                    [intl.formatMessage({ defaultMessage: 'This month' })]: [moment().startOf('month'), moment().endOf('month')],
                                }}
                                allowClear={false}
                                value={[this.state.pickedStartDate, this.state.pickedEndDate]}
                                onChange={this.onChangeDateRange}
                                format={getFormat('DATE')}
                                disabled={loadingOccupancyRates}
                            />
                            <CircleButton small={true} key={`occupancy-rates-validate_date`} onClick={() => this.validateDateRange()} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Confirm dates' })} icon={<ExportOutlined />} />
                        </Col>
                    ]}>
                    <>
                        <div style={{ paddingBottom: '10px', position: "sticky", top: "0", backgroundColor: "white", zIndex: 2 }}>
                            <Row gutter={[10, 10]}>
                                <Col key="col-month-occupancyRates-1" xs={24} sm={{ span: 0 }} style={{ textAlign: 'right', paddingRight: '6px' }}>
                                    <DatePicker.RangePicker
                                        style={{ width: '250px' }}
                                        key="picker-month-occupancyRates"
                                        ranges={{
                                            [intl.formatMessage({ defaultMessage: 'Today' })]: [moment(), moment()],
                                            [intl.formatMessage({ defaultMessage: 'This week' })]: [moment().startOf('week'), moment().endOf('week')],
                                            [intl.formatMessage({ defaultMessage: 'This month' })]: [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        allowClear={false}
                                        value={[this.state.pickedStartDate, this.state.pickedEndDate]}
                                        onChange={this.onChangeDateRange}
                                        format={getFormat('DATE')}
                                        disabled={loadingOccupancyRates}
                                    />
                                    <CircleButton small={true} key={`occupancy-rates-validate_date`} onClick={() => this.validateDateRange()} style={{ marginLeft: '5px' }} title={intl.formatMessage({ defaultMessage: 'Confirm dates' })} icon={<ExportOutlined />} />
                                </Col>
                                {
                                    this.props.filter &&
                                    <Col key="col-month-occupancyRates" xs={24} style={{ textAlign: 'right', paddingRight: '6px' }}>
                                        <DatePicker
                                            disabledDate={this.disableDate}
                                            style={{ width: '100%' }}
                                            key="picker-month-occupancyRates"
                                            allowClear={false}
                                            value={this.props.pickedStartDate ? this.props.pickedStartDate : this.state.pickedStartDate}
                                            onChange={this.onChangeDate}
                                            format={getFormat('DATE')}
                                            disabled={loadingOccupancyRates || this.props.startDate?.isSame(this.props.endDate, 'days')}
                                        />
                                    </Col>
                                }
                                {
                                    !this.props.filter &&
                                    <Col xs={24} md={{ span: 12 }}>
                                        <Select
                                            autoClearSearchValue={false}
                                            value={occupancyRatesTitlesSelected}
                                            mode="multiple"
                                            maxTagCount="responsive"
                                            allowClear
                                            placeholder={<FormattedMessage defaultMessage={'Type of day'} />}
                                            onChange={this.onChangeOccupancyRatesTitles}
                                            style={{ width: '100%' }}
                                            disabled={underFilled.length === 0 || loadingOccupancyRates}
                                            showArrow
                                        >
                                            {
                                                occupancyRatesTitles.sort((a, b) => a.localeCompare(b)).map((o, c) => {
                                                    return <Option value={o} key={o + c}>{o}</Option>;
                                                })
                                            }
                                        </Select>
                                    </Col>
                                }
                                {
                                    this.props.filter ?
                                        null
                                        :
                                        <Col xs={24} md={{ span: 12 }}>
                                            <Select
                                                defaultValue={TypeFilled.ALL}
                                                value={typeFilled}
                                                placeholder={<FormattedMessage defaultMessage={'Events to display'} />}
                                                onChange={this.onChangeFilled}
                                                style={{ width: '100%' }}
                                                disabled={underFilled.length === 0 || loadingOccupancyRates}
                                                showArrow
                                            >

                                                <Option value={TypeFilled.ALL}><FormattedMessage defaultMessage={'All events'} /></Option>
                                                <Option value={TypeFilled.UNDERFILLED}><FormattedMessage defaultMessage={'Missing events'} /></Option>
                                                <Option value={TypeFilled.FILLED}><FormattedMessage defaultMessage={'Complete events'} /></Option>
                                                <Option value={TypeFilled.OVERFILLED}><FormattedMessage defaultMessage={'Overloaded events'} /></Option>
                                            </Select>
                                        </Col>
                                }
                            </Row>
                        </div>
                        {
                            loadingOccupancyRates ?
                                <div style={{ textAlign: 'center' }}>
                                    <Spin />
                                </div>
                                :
                                [...Array(nbOfDays).keys()].map((_, index) => {
                                    dayToDisplay.add(1, 'days');
                                    return (
                                        <div key={`div-occupancy-rate-${dayToDisplay.format('MM/DD/YYYY')}`}>
                                            {
                                                !this.props.filter &&
                                                <Divider className="dashboard-section-title"><ArrowDownOutlined style={{ fontSize: "10px" }} />{dayToDisplay.format(getFormat('DATE_LONG'))} <ArrowDownOutlined style={{ fontSize: "10px" }} /></Divider>
                                            }
                                            {
                                                [TypeFilled.ALL, TypeFilled.UNDERFILLED].includes(typeFilled) && underFilled[index].sort((a, b) => {
                                                    const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0;
                                                }).map(or => this.renderOccupancyRateLine(or, RED_COLOR))
                                            }
                                            {
                                                [TypeFilled.ALL, TypeFilled.FILLED].includes(typeFilled) && fullFilled[index].sort((a, b) => {
                                                    const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0;
                                                }).map(or => this.renderOccupancyRateLine(or, GREEN_COLOR))
                                            }
                                            {
                                                [TypeFilled.ALL, TypeFilled.OVERFILLED].includes(typeFilled) && overFilled[index].sort((a, b) => {
                                                    const nameA = a.occupancyRate.title.toLowerCase(), nameB = b.occupancyRate.title.toLowerCase();
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0;
                                                }).map(or => this.renderOccupancyRateLine(or, BLUE_COLOR))
                                            }
                                        </div>
                                    );
                                })
                        }
                    </>
                </Card>
            );
        }
    }
}
const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    users: state.teamManagement.users
});

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

const ConnectedOccupancyRateControl = connector(OccupancyRatesControl);


const ForwardedOccupancyRatesControl = forwardRef<OccupancyRatesControl, any>((props: Props, ref) => {
    const intl = useIntl();

    return <ConnectedOccupancyRateControl {...props} ref={ref as React.Ref<OccupancyRatesControl>} intl={intl} />;
});

ForwardedOccupancyRatesControl.displayName = "FowardedOccupancyRate";

export default ForwardedOccupancyRatesControl;