import { ClearOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Empty, message, Modal, Row, Space } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import React, { CSSProperties } from "react";
import { BlockPicker, ChromePicker, ColorResult } from "react-color";
import isEqual from "react-fast-compare";
import { CgColorPicker } from "react-icons/cg";
import { IoMdColorPalette } from "react-icons/io";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { changeSettings } from "../../../store/actions/planning";
import Network from "../../../utils/network";
import { NetworkSettings } from "../../../utils/types/networkTypes";
import { PlanningColor, PlanningSettings } from "../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch } from "../../../utils/types/storeTypes";
import { cloneSettings, convertNetworkSettingsToPlanningSettings, convertPlanningSettingsToNetworkSettings, findSimilarColors, isNullOrEmpty, searchIgnoreCaseAndAccentInsensitive, showNotification } from "../../../utils/utils";
import { IntlProps } from "../../app/LanguageProvider";
import CircleButton from "../../common/fields/circleButton";
import DeleteButton from "../../common/fields/deleteButton";
import InputField, { InputFieldOnChangeEvent } from "../../common/fields/inputField";
import Anticon from "../../common/general/anticon";
import Bloc from "../../common/general/bloc";
import Card from "../../common/general/card";

const colorStyle: CSSProperties = {
    width: '60px',
    height: '23px',
    borderRadius: 'var(--border-radius)',
};
const blockColorStyle: CSSProperties = {
    borderRadius: 'var(--border-radius)',
    height: '23px',
    width: '100%'
}

const swatchStyle: CSSProperties = {
    padding: '5px',
    background: '#fff',
    borderRadius: 'var(--border-radius)',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
};
const popoverStyle: CSSProperties = {
    position: 'absolute',
    zIndex: '2',
    top: '40px',
    left: '-48px'
};
const popoverStyle2: CSSProperties = {
    position: 'absolute',
    zIndex: '2',
    top: '20px',
    left: '-130px'
};

const coverStyle: CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
};

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, IntlProps { }

interface State {
    editColor?: PlanningColor;
    colorSearch?: string;
    displayColorSearchPicker: boolean;
    search?: string;
    displayColorPicker?: number;
    filteredColors?: PlanningColor[];
    settings?: PlanningSettings;
}

class Colors extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            settings: cloneSettings(this.props.settings),
            filteredColors: cloneDeep(this.props.settings.colors),
            displayColorSearchPicker: false,
        };
    }
    componentDidMount(): void {
        Network.getSettings().then(
            response => {
                const settings = convertNetworkSettingsToPlanningSettings(response);
                !isEqual(this.props.settings, settings) && this.props.changeSettings!(settings);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "warning")
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (!isEqual(prevProps.settings, this.props.settings) || !isEqual(prevProps.settings.colors, this.props.settings.colors)) {
            this.setState({ settings: cloneSettings(this.props.settings), filteredColors: this.getFilteredColors(this.state.search) })
        }

    }

    onEditColor = (color?: PlanningColor) => {
        this.setState({
            editColor: {
                id: color ? color.id : -1,
                title: color ? color.title : "",
                color: color ? color.color : "#C09F50",
            }
        });
    }

    onChangeEditColorTitle = (event: InputFieldOnChangeEvent) => {
        const color = cloneDeep(this.state.editColor)

        if (color) {
            color.title = event.target.value;
            this.setState({ editColor: color });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The color could not be found' }), "error");
        }
    }

    onChangeEditColor = (c: ColorResult): void => {
        const color = cloneDeep(this.state.editColor)
        if (color) {
            color.color = c.hex;
            this.setState({ editColor: color });
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'The color could not be found' }), "error");
        }


    }

    getFilteredColors = (searchTitle?: string, searchColor?: string) => {
        let filteredColors = cloneDeep(this.props.settings.colors);
        if (isNullOrEmpty(filteredColors)) {
            return undefined;
        }

        if (searchTitle && searchTitle.length > 0) {
            filteredColors = filteredColors?.filter(color => searchIgnoreCaseAndAccentInsensitive(color.title, searchTitle));
        }

        if (searchColor && searchColor.length > 0) {
            const listOfSimilarColorsIdx: number[] = findSimilarColors(searchColor, filteredColors.map(color => color.color), 80, true) as number[];
            filteredColors = listOfSimilarColorsIdx.map(c => {
                return filteredColors![c];
            });
        }

        return filteredColors;
    }

    colorPickerSearch = () => {
        const { colorSearch, displayColorSearchPicker } = this.state;
        return (
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <div
                    style={swatchStyle}
                    onClick={() => this.setState({ displayColorSearchPicker: true })}
                >
                    {
                        colorSearch ?
                            <div style={{ ...colorStyle, background: `${colorSearch}` }} />
                            :
                            <div title={this.props.intl.formatMessage({ defaultMessage: 'Search for a color' })} style={{ ...colorStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Anticon icon={<CgColorPicker />} />
                            </div>
                    }
                </div>
                <div style={displayColorSearchPicker ? popoverStyle2 : { display: 'none' }}>
                    <div style={coverStyle} onClick={() => this.setState({ displayColorSearchPicker: false, filteredColors: this.getFilteredColors(this.state.search, this.state.colorSearch) })} />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '227px',
                        padding: '6px 12px',
                        border: 'var(--border)',
                        borderBottom: 'none',
                        borderTopLeftRadius: 'var(--border-radius)',
                        borderTopRightRadius: 'var(--border-radius)',
                        backgroundColor: 'white',
                        zIndex: '999'
                    }}>
                        <p style={{
                            zIndex: '999'
                        }}><FormattedMessage defaultMessage={'Search for a color'} /></p>
                        <ClearOutlined title={this.props.intl.formatMessage({ defaultMessage: 'Delete' })} style={{ cursor: 'pointer', zIndex: '999' }} onClick={() => this.setState({ colorSearch: undefined, filteredColors: this.getFilteredColors(this.state.search, undefined), displayColorSearchPicker: false })} />
                    </div>
                    <ChromePicker
                        disableAlpha={true}
                        color={colorSearch}
                        onChange={(c: ColorResult) => this.setState({ colorSearch: c.hex })}
                    />
                </div>
            </div>
        )
    }

    colorPicker = (color: PlanningColor, edit = false) => {
        if (edit) {
            return (
                <Space direction="vertical">
                    <InputField
                        allowClear={true}
                        value={color.title}
                        placeholder={this.props.intl.formatMessage({ defaultMessage: 'Title' })}
                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeEditColorTitle(e)} />
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{ ...swatchStyle, width: '100%' }}
                        >
                            <div style={{ ...blockColorStyle, background: `${color.color}` }} />
                        </div>
                        <div style={this.state.displayColorPicker === color.id ? popoverStyle : { display: 'none' }}>
                            <BlockPicker
                                colors={[]}
                                color={color.color}
                            />
                        </div>
                    </div>
                    <div>
                        <p style={{ width: '227px', padding: '5px 0', textAlign: 'center', border: 'var(--border)', borderBottom: 'none', borderTopLeftRadius: 'var(--border-radius)', borderTopRightRadius: 'var(--border-radius)' }}><FormattedMessage defaultMessage={'Select a color'} /></p>
                        <ChromePicker
                            disableAlpha={true}
                            color={color.color}
                            onChange={(c: ColorResult) => this.onChangeEditColor(c)}
                        />
                    </div>

                </Space>
            );
        } else {
            return (
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <div
                        style={swatchStyle}
                        onMouseEnter={() => this.setState({ displayColorPicker: color.id })}
                        onMouseLeave={() => this.setState({ displayColorPicker: undefined })}
                    >
                        <div style={{ ...colorStyle, background: `${color.color}` }} />
                    </div>
                    <div style={this.state.displayColorPicker === color.id ? popoverStyle : { display: 'none' }}>
                        <BlockPicker
                            colors={[]}
                            color={color.color}
                        />
                    </div>
                </div>
            );
        }
    }

    onSearch = (val: string) => {
        if (val?.length > 0) {
            this.setState({ search: val, filteredColors: this.getFilteredColors(val, this.state.colorSearch) });
        } else {
            this.setState({ search: undefined, filteredColors: this.getFilteredColors(undefined, this.state.colorSearch) });
        }
    }

    onColorSearch = (color?: string) => {
        if (color !== undefined && color.length > 0) {
            this.setState({ colorSearch: color, filteredColors: this.getFilteredColors(this.state.search, color) });
        } else {
            this.setState({ search: undefined, filteredColors: this.getFilteredColors(this.state.search, undefined) });
        }
    }
    updateSettings = (settings: PlanningSettings): void => {
        if (settings) {
            const networkSettings: NetworkSettings = convertPlanningSettingsToNetworkSettings(settings);

            // update settings
            Network.updateSettings(networkSettings).then(
                response => {
                    const settings = convertNetworkSettingsToPlanningSettings(response);
                    !isEqual(this.props.settings, settings) && this.props.changeSettings!(settings);
                    this.setState({ settings, editColor: undefined });
                    message.success(this.props.intl.formatMessage({ defaultMessage: 'The planning has been successfully updated' }));
                },
                () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the settings' }), "warning"),
            );
        }

    }
    saveColor = (): void => {
        const editColor = this.state.editColor
        const settings = cloneDeep(this.state.settings)

        if (settings !== undefined && editColor !== undefined && editColor.title !== undefined && editColor.title !== '' && editColor.color !== undefined && editColor.color !== '') {

            if (editColor.id !== undefined && editColor.id !== -1 && settings.colors) {
                settings.colors = settings.colors?.filter(c => c.id !== editColor.id)
            }
            if (settings.colors === undefined) {
                settings.colors = [editColor]
            } else {
                settings.colors.push(editColor)
            }
            this.updateSettings(settings)
        } else {
            message.warning(this.props.intl.formatMessage({ defaultMessage: 'Please fill all the fields' }))
        }
    }
    deleteColor = (color: PlanningColor): void => {
        const settings = cloneDeep(this.state.settings)
        if (settings !== undefined && color !== undefined && color.id !== undefined && color.id !== -1) {
            settings.colors = settings.colors!.filter(c => c.id !== color.id);
            this.updateSettings(settings)
        }
    }

    render() {
        const { editColor, filteredColors } = this.state;
        return (
            <>
                <Card
                    title={<FormattedMessage defaultMessage={'Colors'} />}
                    icon={<Anticon icon={<IoMdColorPalette />} />}
                    headerElements={[
                        <CircleButton
                            small
                            disabled={this.state.editColor !== undefined}
                            key={`colors-header-add-color`}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Add a color' })}
                            onClick={() => this.onEditColor()}
                            icon={<PlusOutlined />}
                            placement="right" />
                    ]}
                >
                    <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
                        <Col xs={{ span: 24 }}>
                            <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                                <InputField
                                    style={{ flex: 1 }}
                                    placeholder={this.props.intl.formatMessage({ defaultMessage: 'Search' })}
                                    value={this.state.search}
                                    onChange={e => this.onSearch(e.target.value)} />
                                {this.colorPickerSearch()}
                            </div>
                        </Col>
                        {
                            filteredColors ?
                                filteredColors.map(color => (
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }} key={`planning-settings-color-${color.id}`}>
                                        <Bloc title={color.title} containerStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                            {this.colorPicker(color)}
                                            <Space>
                                                <DeleteButton
                                                    text={<span><p><FormattedMessage defaultMessage={'Do you really want to delete this color?'} /></p><p><FormattedMessage defaultMessage={'For all events containing this color, the value will be changed to the default color'} /></p></span>}
                                                    onConfirm={() => this.deleteColor(color)}
                                                    buttonPlacement="bottom"
                                                    small />
                                                <CircleButton
                                                    small
                                                    icon={<EditOutlined />}
                                                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                                                    placement="bottom"
                                                    onClick={() => this.onEditColor(color)}
                                                />
                                            </Space>
                                        </Bloc>
                                    </Col>
                                ))
                                :
                                <Empty description={<FormattedMessage defaultMessage={'No color available'} />} />
                        }
                    </Row>
                </Card>
                <Modal
                    open={editColor !== undefined}
                    width="275px"
                    title={editColor?.id && editColor.id > 0 ? <FormattedMessage defaultMessage={'Edit color'} /> : <FormattedMessage defaultMessage={'New color'} />}
                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                    onCancel={() => this.setState({ editColor: undefined })}
                    okText={editColor?.id && editColor.id > 0 ? <FormattedMessage defaultMessage={'Edit'} /> : <FormattedMessage defaultMessage={'Create'} />}
                    okButtonProps={{ disabled: (editColor === undefined || editColor.title === undefined || editColor.title === '' || editColor.color === undefined || editColor.color === '') }}
                    onOk={() => this.saveColor()}
                >
                    {
                        editColor ?
                            this.colorPicker(editColor, true)
                            : null
                    }
                </Modal>
            </>
        );
    }
}


const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeSettings: (s: PlanningSettings) => dispatch(changeSettings(s)),
});

const mapStateToProps = (state: ApplicationState) => ({
    settings: state.planning.settings,
});


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Colors));