import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../../utils/types/generalTypes';

interface IProps {
    items: { title: string; link: string }[];
}

type Props = IProps & RouterProps;

/**
 * Component that represent a breadcrumb
 */
class Breadcrumb extends React.Component<Props> {

    /**
     * Go to an address
     * @param link the link to load
     */
    goTo = (link: string) => this.props.history.push(`/${this.props.match.params.lang}${link}`);

    render() {
        return (
            <div className="breadcrumb-content">
                {this.props.items.map((item, index) => (
                    <span
                        key={`breadcrumb-${index}`}
                        className="breadcrumb-item"
                        onClick={() => this.goTo(item.link)}>
                        <p className="breadcrumb-title">{item.title}</p>
                        {
                            index !== this.props.items.length - 1 ?
                                <p className="breadcrumb-slash">{"/"}</p>
                                : null
                        }
                    </span>
                ))}
            </div>
        );
    }
}


export default withRouter(Breadcrumb);