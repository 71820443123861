import { CloudSyncOutlined, DashOutlined, EditOutlined, MinusOutlined, MoreOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Drawer, Empty, message, Popconfirm, Row, Segmented, Select, Table, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { SegmentedValue } from 'antd/lib/segmented';
import { ColumnProps } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import isEqual from 'react-fast-compare';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { MdOutlineAssignmentReturned, MdOutlineMapsHomeWork, MdOutlineTransform } from 'react-icons/md';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { loadDepartments } from '../../../store/actions/configurations';
import { changeCourses, changeSectors, loadPois } from '../../../store/actions/location';
import Network from '../../../utils/network';
import { Course, Department, POI, PoiWithUpdate, Sector } from '../../../utils/types/generalTypes';
import { ApplicationState, LocationDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import SpeedDial from '../../common/fields/speedDial';
import Anticon from '../../common/general/anticon';
import Card from '../../common/general/card';
import { tableColumnTextFilterConfig } from '../tableSearch';
import PoiEditForm from './poiEditForm';
import PoiFastEdit from './poiFastEdit';
import PoiShowForm from './poiShowForm';


type ReduxProp = ConnectedProps<typeof connector>

interface IProps {
    pois?: PoiWithUpdate;
    basePois?: PoiWithUpdate;
    departurePoint?: boolean;
    changePois(pois: POI[]): void;
    changeBasePois(pois: POI[]): void;
}

type Props = IProps & LocationDispatchProps & ReduxProp & IntlProps;

interface State {
    selectedPoi: number[];
    searchText: string;
    searchedColumn: string | number;
    isDrawerVisible: boolean;
    poiShow?: POI;
    poiEdit?: POI;
    displayAlert: boolean;
    alertMessage: string;
    alertType: 'success' | 'info' | 'warning' | 'error';
    loadingUpdate: boolean;
    selectedGroups: number[];
    selectedSectors: number[];
    loadingButton: boolean;
    openAssign: boolean;
    openTransform: boolean;
    segmentedValue: SegmentedValue;
    popConfirmPrimaryWarning: boolean;
    department?: Department;
}


class PoiTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            segmentedValue: 'add',
            openAssign: false,
            openTransform: false,
            selectedGroups: [],
            selectedSectors: [],
            selectedPoi: [],
            searchText: '',
            searchedColumn: '',
            isDrawerVisible: false,
            alertMessage: '',
            alertType: 'success',
            displayAlert: false,
            loadingUpdate: false,
            loadingButton: false,
            popConfirmPrimaryWarning: false,
            department: undefined
        }
    }

    componentDidMount() {
        this.props.loadDepartments();

        // if (this.props.departurePoint) {
        //     if(this.props.basePois && this.props.basePois.data) { 
        //         this.setState({ isLoadingPois: false })
        //     }
        // } else {
        //     this.props.pois && this.setState({ isLoadingPois: false });
        // }
        // this.refreshSectors()
    }

    // componentDidUpdate() {
    //     const { pois, basePois } = this.props;
    //     if (this.state.isLoadingPois !== false) {
    //         if (this.props.departurePoint) {
    //             if(basePois && basePois.data) { 
    //                 this.setState({ isLoadingPois: false })
    //             }
    //         } else {
    //             pois && this.setState({ isLoadingPois: false });
    //         }
    //     }
    // }

    refreshCourses = () => {
        Network.getCourse().then(
            (response: Course[]) => {
                const courses = response;
                this.props.changeCourses!(courses);
            }
        )
    }

    refreshSectors = () => {
        Network.getSector().then(
            (response: Sector[]) => {
                const sectors = response;
                this.props.changeSectors!(sectors);
            }
        )
    }

    startAddPoi = (isBasePoi = false) => {
        this.setState({ poiEdit: { isBasePoi } })
    }

    /**
     * Called when a poi is (un)checked
     * @param keys the new keys of checked poi
     */
    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedPoi: keys as number[] });

    /**
         * Give the poi table's rows some attributes
         * @param record the data source for the row
         */
    poiOnRow = (record: POI) => ({
        onClick: () => this.setState({ poiShow: record })
    });

    deletePoi = (poi?: POI, poiIds?: number[]) => {
        const { intl } = this.props;
        let pois: POI[] = [];
        let changePois: any = undefined;

        if (this.props.departurePoint) {
            pois = this.props.basePois?.data ? this.props.basePois?.data : [];
            changePois = this.props.changeBasePois;
        } else {
            pois = this.props.pois?.data ? this.props.pois?.data : [];
            changePois = this.props.changePois;
        }
        if (poi && poi.id !== undefined) {
            Network.deletePoi([poi.id]).then(
                success => {
                    if (success.error) {
                        if (success.notFoundIds && success.notFoundIds.length > 0) {
                            const notFound = pois?.filter(p => success.notFoundIds.includes(p.id));
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (point not found): {pois}' }, { pois: notFound?.map(nf => nf.title).join(", ") }), "warning");
                            pois = pois?.filter(p => p.id !== poi.id);
                            pois && changePois(pois);
                        }
                        if (success.hasEvent && success.hasEvent.length > 0) {
                            const hasEvent = pois?.filter(p => success.hasEvent.includes(p.id));
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (has one or more events): {pois}' }, { pois: hasEvent?.map(nf => nf.title).join(", ") }), "warning");
                        }
                    } else {
                        pois = pois?.filter(p => p.id !== poi.id);
                        pois && changePois(pois);
                        showNotification(intl.formatMessage({ defaultMessage: 'POI {title} successfully deleted' }, { title: poi.title }), "success");
                    }
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the POI {title}' }, { title: poi.title }), "error");
                },
            );
        } else if (poiIds !== undefined && poiIds.length > 0) {
            Network.deletePoi(poiIds).then(
                success => {
                    const notDelete: number[] = [];
                    if (success.error) {
                        if (success.notFoundIds && success.notFoundIds.length > 0) {
                            const notFound = pois?.filter(p => success.notFoundIds.includes(p.id));
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (point not found): {pois}' }, { pois: notFound?.map(nf => nf.title).join(", ") }), "warning");
                        }
                        if (success.hasEvent && success.hasEvent.length > 0) {
                            notDelete.concat(success.hasEvent);
                            const hasEvent = pois?.filter(p => success.hasEvent.includes(p.id));
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (has one or more events): {pois}' }, { pois: hasEvent?.map(nf => nf.title).join(", ") }), "warning");
                        }
                        pois = pois?.filter(p => poiIds.find(ptod => ptod === p.id) === undefined && (p.id === undefined || !notDelete.includes(p.id)));
                        pois && changePois(pois);
                    } else {
                        pois = pois?.filter(p => poiIds.find(ptod => ptod === p.id) === undefined);
                        pois && changePois(pois);
                        showNotification(intl.formatMessage({ defaultMessage: 'The POIs have been successfully deleted' }), "success");
                    }
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the POIs' }), "error");
                },
            );
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deletion' }), "warning");
        }
    }

    delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    updatePoi = (forceReload?: boolean) => {
        const { intl } = this.props;
        let pois: POI[] = [];
        let changePois: any = undefined;

        if (this.props.departurePoint) {
            pois = this.props.basePois?.data ? this.props.basePois?.data : [];
            changePois = this.props.changeBasePois;
        } else {
            pois = this.props.pois?.data ? this.props.pois?.data : [];
            changePois = this.props.changePois;
        }
        this.setState({ loadingUpdate: true });
        const poi = { ...this.state.poiEdit };
        if (poi) {
            if (!poi.title || !poi.latitude || !poi.longitude) {
                showNotification(intl.formatMessage({ defaultMessage: 'Please provide the title, latitude and logitude' }), "error");
                this.setState({ loadingUpdate: false });
                return;
            }
            poi.courses?.forEach(c => {
                c.geoJson = undefined;
            });
            Network.updatePoi(poi).then(
                async (response: POI) => {
                    if (poi.id === undefined) {
                        if (pois && pois.length > 0) {
                            pois.unshift(response);
                        } else {
                            pois = [response];
                        }
                    } else {
                        pois = pois?.map(p => {
                            if (p.id === response.id) {
                                return response;
                            } else {
                                return p;
                            }
                        });
                    }
                    const alertMessage = intl.formatMessage({ defaultMessage: 'The POI has been successfully updated' });
                    pois && changePois(pois);
                    this.setState({ poiShow: response, poiEdit: undefined, alertMessage, alertType: 'success', displayAlert: true, loadingUpdate: false });
                    this.props.loadPois(forceReload);

                    if (forceReload) {
                        for (let i = 0; i < 4; i++) {
                            await this.delay(1500);
                            this.props.loadPois(forceReload);
                        }
                    }

                },
                () => {
                    if (poi.id !== undefined) {
                        const alertMessage = intl.formatMessage({ defaultMessage: 'An error occurred while updating the POI' });
                        this.setState({ poiEdit: undefined, alertMessage, alertType: 'error', displayAlert: true, loadingUpdate: false });
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating POI' }), 'error');
                        this.setState({ poiShow: undefined, poiEdit: undefined, loadingUpdate: false });
                    }
                },
            )
        }
    }

    poiColumnsActions = (record: POI) => {
        return [
            <DeleteButton
                key="template-modal-delete-poi"
                text={<FormattedMessage defaultMessage={'This action will delete the selected location. Events that use this location will not be deleted.'} />}
                onConfirm={() => this.deletePoi(undefined, this.state.selectedPoi)}
                placement={'bottom'}
            />,
            <CircleButton
                key='mobile-table-actions-modify'
                className="__card-button-space"
                icon={<EditOutlined />}
                title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                placement="left"
                onClick={() => this.setState({ poiShow: record, poiEdit: { ...record } })}
            />

        ]
    }

    closeShowDrawer = () => {
        this.setState({ poiShow: undefined, displayAlert: false });
    }

    closeEditDrawer = () => {
        if (this.state.poiEdit?.id !== undefined) {
            this.setState({ poiEdit: undefined })
        } else {
            this.setState({ poiEdit: undefined, poiShow: undefined });
        }
    }

    convertToWorkPlace = () => {
        const { selectedPoi } = this.state;
        const { pois, basePois, intl } = this.props;
        Network.convertBasePoiToPoi(selectedPoi).then(
            success => {
                let successBasePoiToPoi = selectedPoi;
                let poisData = cloneDeep(pois?.data);
                const basePoisData = cloneDeep(basePois?.data);
                console.log("HERE")
                if (success.error) {
                    console.log("Success error")
                    if (success.notFoundIds && success.notFoundIds.length > 0) {
                        successBasePoiToPoi = successBasePoiToPoi.filter(sp => !success.notFoundIds.includes(sp));
                        const notFound = basePois?.data?.filter(p => success.notFoundIds.includes(p.id));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (point not found): {pois}' }, { pois: notFound?.map(nf => nf.title).join(", ") }), "warning");

                    }
                    if (success.hasEvent && success.hasEvent.length > 0) {
                        console.log("SUCCESSHASEVENT", success.hasEvent)
                        successBasePoiToPoi = successBasePoiToPoi.filter(sp => !success.hasEvent.includes(sp));
                        const hasEvent = basePois?.data?.filter(p => success.hasEvent.includes(p.id));
                        console.log("HASEVENT", hasEvent)
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (has one or more events): {pois}' }, { pois: hasEvent?.map(nf => nf.title).join(", ") }), "warning");

                    }


                } else {
                    showNotification(intl.formatMessage({ defaultMessage: '{count, plural, one {Point of interest successfully converted} other {Points of interest successfully converted}}' }, { count: selectedPoi.length }), "success");
                }
                const basePoisDataCleaned = basePoisData?.filter(p => p.id && !successBasePoiToPoi.includes(p.id));
                const basePoisDataToPoiData = basePoisData?.filter(p => p.id && successBasePoiToPoi.includes(p.id));
                if (basePoisDataToPoiData) {
                    poisData = poisData ? poisData.concat(basePoisDataToPoiData) : basePoisDataToPoiData;
                    this.props.changePois(poisData);
                }

                this.props.changeBasePois(basePoisDataCleaned ? basePoisDataCleaned : []);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while converting the point of interest' }), "error");
            }
        );
    }

    duplicateToWorkPlace = (force = false) => {
        const { selectedPoi, loadingButton } = this.state;
        const { intl } = this.props;

        if (!loadingButton) {
            this.setState({ loadingButton: true }, () =>
                Network.duplicateBasePoiToPoi(selectedPoi, force).then(
                    success => {
                        if (success.error) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while duplicating the points of interest' }), "warning");

                        } else {
                            message.success(intl.formatMessage({ defaultMessage: '{count} locations successfully duplicated' }, { count: selectedPoi.length }));
                        }
                        this.props.loadPois(true);
                        this.setState({ loadingButton: false, openTransform: false })
                    },
                    () => {
                        this.setState({ loadingButton: false })
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while duplicating the point of interest' }), "error");
                    }
                ))
        }
    }

    convertToDeparturePoint = () => {
        const { selectedPoi } = this.state;
        const { pois, basePois, intl } = this.props;
        Network.convertPoiToBasePoi(selectedPoi).then(
            success => {
                let successPoiToBasePoi = selectedPoi;
                const poisData = cloneDeep(pois?.data);
                let basePoisData = cloneDeep(basePois?.data);
                if (success.error) {
                    console.log("SUCCESS", success)

                    if (success.notFoundIds && success.notFoundIds.length > 0) {
                        successPoiToBasePoi = successPoiToBasePoi.filter(sp => !success.notFoundIds.includes(sp));

                        const notFound = pois?.data?.filter(p => success.notFoundIds.includes(p.id));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (point not found): {pois}' }, { pois: notFound?.map(nf => nf.title).join(", ") }), "warning");

                    }
                    if (success.hasEvent && success.hasEvent.length > 0) {
                        successPoiToBasePoi = successPoiToBasePoi.filter(sp => !success.hasEvent.includes(sp));

                        const hasEvent = pois?.data?.filter(p => success.hasEvent.includes(p.id));
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the following points of interest (has one or more events): {pois}' }, { pois: hasEvent?.map(nf => nf.title).join(", ") }), "warning");

                    }

                } else {
                    showNotification(intl.formatMessage({ defaultMessage: '{count, plural, one {Point of interest successfully converted} other {Points of interest successfully converted}}' }, { count: selectedPoi.length }), "success");
                }
                console.log("POISDATA", poisData)
                const poisDataCleaned = poisData?.filter(p => p.id && !successPoiToBasePoi.includes(p.id));
                console.log("POISDATA", poisData)
                const poisDataToBasePoiData = poisData?.filter(p => p.id && successPoiToBasePoi.includes(p.id));
                if (poisDataToBasePoiData) {
                    console.log("BASEPOISDATA", basePoisData)
                    basePoisData = basePoisData ? basePoisData.concat(poisDataToBasePoiData) : poisDataToBasePoiData;
                    console.log("BASEPOISDATA", basePoisData)
                    this.props.changeBasePois(basePoisData);
                }

                this.props.changePois(poisDataCleaned ? poisDataCleaned : []);
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while converting the point of interest' }), "error");
            }
        );
    }
    duplicateToDeparturePoint = (force = false) => {
        const { selectedPoi, loadingButton } = this.state;
        const { intl } = this.props;

        if (!loadingButton) {
            this.setState({ loadingButton: true }, () =>
                Network.duplicatePoiToBasePoi(selectedPoi, force).then(
                    success => {
                        if (success.error) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while duplicating the points of interest' }), "warning");

                        } else {
                            message.success(intl.formatMessage({ defaultMessage: '{count} locations successfully duplicated' }, { count: selectedPoi.length }));
                        }
                        this.props.loadPois(true);
                        this.setState({ loadingButton: false, openTransform: false })
                    },
                    () => {
                        this.setState({ loadingButton: false })
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while duplicating the point of interest' }), "error");
                    }
                ))
        }
    }

    quickAssignPois = () => {
        const { selectedPoi, selectedSectors, selectedGroups, segmentedValue, department } = this.state;
        const { intl } = this.props;
        Network.quickAssignPoi(segmentedValue, selectedPoi, selectedSectors, selectedGroups, department?.id).then(
            success => {
                if (success.error) {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while the assignment' }), "warning");

                } else {
                    message.success(intl.formatMessage({ defaultMessage: '{count} locations successfully updated' }, { values: selectedPoi.length }));
                }
                this.setState({ segmentedValue: 'add' })
                this.props.loadPois(true);

            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while the assignment' }), "error");
            }
        );
    }
    render() {
        const { departurePoint, intl } = this.props;
        let pois: POI[] | undefined = undefined;
        if (departurePoint) {
            pois = this.props.basePois?.data;
        } else {
            pois = this.props.pois?.data;
        }
        if (pois === undefined) {
            pois = [];
        }
        const headerButtons = [
            departurePoint ?
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                    <PoiFastEdit
                        open={this.state.openAssign}
                        setOpen={(openAssign) => this.setState({ openAssign })}
                        pois={pois}
                        selectedPois={this.state.selectedPoi}
                        setSelectedPois={selectedPoi => this.setState({ selectedPoi })}
                        button={{
                            icon: <Anticon icon={<MdOutlineAssignmentReturned />} />,
                            title: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Assign' }),
                            disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0)
                        }}
                        title={'Assignation rapide'}
                        confirmButton={{
                            title: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Assign' }),
                            disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0 || (this.state.selectedGroups.length === 0 && this.state.selectedSectors.length === 0 && this.state.department === undefined)),
                            onClick: (e) => { e.stopPropagation(); this.quickAssignPois(); this.setState({ selectedGroups: [], selectedSectors: [], department: undefined }) }
                        }}
                        body={
                            <>
                                <Segmented
                                    value={this.state.segmentedValue}
                                    onChange={(segmentedValue) => this.setState({ segmentedValue })}
                                    options={[
                                        {
                                            label: intl.formatMessage({ defaultMessage: 'Add' }),
                                            value: 'add',
                                            icon: <PlusOutlined />,
                                        },
                                        {
                                            label: intl.formatMessage({ defaultMessage: 'Remove' }),
                                            value: 'remove',
                                            icon: <MinusOutlined />,
                                        },
                                    ]}
                                />
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }} >
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                        <div >
                                            <FormattedMessage defaultMessage={'{action} sectors'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                        </div>
                                        <div >
                                            <Select
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                allowClear
                                                placeholder={<FormattedMessage defaultMessage={'Sectors to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                style={{ width: 270 }}
                                                value={this.state.selectedSectors}
                                                onChange={(selectedSectors) => this.setState({ selectedSectors })}
                                                filterOption={true}
                                                optionFilterProp="label"
                                                showArrow
                                                disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                options={this.props.sectors ? this.props.sectors.map(s => ({ value: s.id, label: s.title })) : []}
                                            >
                                            </Select>
                                        </div>
                                    </div>

                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                        <div >
                                            <FormattedMessage defaultMessage={'{action} groups'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                        </div>
                                        <div >
                                            <Select
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                allowClear
                                                placeholder={<FormattedMessage defaultMessage={'Groups to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                style={{ width: 270 }}
                                                value={this.state.selectedGroups}
                                                onChange={(selectedGroups) => this.setState({ selectedGroups })}
                                                filterOption={true}
                                                optionFilterProp="label"
                                                showArrow
                                                disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                options={this.props.groups ? this.props.groups.map(s => ({ value: s.id, label: s.name })) : []}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                    {this.props.departments && this.props.departments.data.length > 0 &&
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                            <div >
                                                <FormattedMessage defaultMessage={'{action} department'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                            </div>
                                            <div >
                                                <Select
                                                    allowClear
                                                    placeholder={<FormattedMessage defaultMessage={'Department to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                    style={{ width: 270 }}
                                                    value={this.state.department?.id}
                                                    onChange={(departmentId) => this.setState({ department: this.props.departments.data.find(d => d.id === departmentId) })}
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    showArrow
                                                    disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                    options={this.props.departments ? this.props.departments.data.map(s => ({ value: s.id, label: s.name })) : []}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </>
                        }
                    />
                    <PoiFastEdit
                        open={this.state.openTransform}
                        setOpen={(openTransform) => this.setState({ openTransform })}
                        pois={pois}
                        selectedPois={this.state.selectedPoi}
                        setSelectedPois={selectedPoi => this.setState({ selectedPoi })}
                        button={{ icon: <Anticon icon={<MdOutlineTransform />} />, title: intl.formatMessage({ defaultMessage: 'Convert or duplicate' }), disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0) }}
                        title={intl.formatMessage({ defaultMessage: '{count, plural, one {Convert or duplicate 1 location} other {Convert or duplicate {count} location}}' }, { count: this.state.selectedPoi.length })}
                        body={
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                                        <FormattedMessage defaultMessage={'As departure location'} />
                                        <span className="anticon" ><BiMap /></span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                                        <Button
                                            type={'primary'}
                                            key="poi-duplic-base-poi-button"
                                            //className="__card-button-space"
                                            loading={this.state.loadingButton}
                                            disabled={this.state.loadingButton}
                                            //disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.duplicateToDeparturePoint(true);
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Duplicate'} />
                                        </Button>
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                                        <FormattedMessage defaultMessage={'Convert to workplace'} />
                                        <span className="anticon" ><MdOutlineMapsHomeWork /></span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                                        <Button
                                            key="poi-convert-base-poi-button"
                                            //className="__card-button-space"
                                            disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.convertToWorkPlace();
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Convert'} />
                                        </Button>
                                        <Button
                                            type={'primary'}
                                            key="poi-duplic-base-poi-button"
                                            //className="__card-button-space"
                                            loading={this.state.loadingButton}
                                            disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.duplicateToWorkPlace();
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Duplicate'} />
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        }

                    />
                    <DeleteButton
                        key="poi-delete-button"
                        //className="__card-button-space"
                        disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                        //icon={<DeleteOutlined />}
                        //title={"Supprimer"}
                        text={
                            <span>
                                <FormattedMessage defaultMessage={'{count, plural, one {This action will delete the selected point of interest.} other {This action will delete {count} selected points of interest.}}'} values={{ count: this.state.selectedPoi.length }} />
                                <FormattedMessage defaultMessage={'{count, plural, one {The events using this point of interest will not be deleted.} other {The events using these points of interest will not be deleted.}}'} values={{ count: this.state.selectedPoi.length }} />
                            </span>
                        }
                        // small={this.props.small}
                        // style={this.props.style}
                        onConfirm={() => this.deletePoi(undefined, this.state.selectedPoi)}
                        placement={'bottom'}
                    />
                    <CircleButton
                        onClick={() => this.startAddPoi(true)}
                        //className="__card-button-space"
                        key="poi-add-button"
                        title={intl.formatMessage({ defaultMessage: 'Add a location' })}
                        icon={<PlusOutlined />}
                    //placement="bottom" 
                    />
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                    <PoiFastEdit
                        open={this.state.openAssign}
                        setOpen={(openAssign) => this.setState({ openAssign })}
                        pois={pois}
                        selectedPois={this.state.selectedPoi}
                        setSelectedPois={selectedPoi => this.setState({ selectedPoi })}
                        button={{
                            icon: <Anticon icon={<MdOutlineAssignmentReturned />} />,
                            title: intl.formatMessage({ defaultMessage: 'Assign' }),
                            disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0)
                        }}
                        title={intl.formatMessage({ defaultMessage: 'Quick assign' })}
                        confirmButton={{
                            title: this.state.segmentedValue === 'add' ? intl.formatMessage({ defaultMessage: 'Assign' }) : intl.formatMessage({ defaultMessage: 'Unassign' }),
                            disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0 || (this.state.selectedGroups.length === 0 && this.state.selectedSectors.length === 0 && this.state.department === undefined)),
                            // disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0 || (this.state.selectedGroups.length === 0 && this.state.selectedSectors.length === 0)), 
                            onClick: () => { this.quickAssignPois(); this.setState({ selectedGroups: [], selectedSectors: [] }) }
                        }}
                        body={
                            <>
                                <Segmented
                                    value={this.state.segmentedValue}
                                    onChange={(segmentedValue) => this.setState({ segmentedValue })}
                                    options={[
                                        {
                                            label: intl.formatMessage({ defaultMessage: 'Add' }),
                                            value: 'add',
                                            icon: <PlusOutlined />,
                                        },
                                        {
                                            label: intl.formatMessage({ defaultMessage: 'Remove' }),
                                            value: 'remove',
                                            icon: <MinusOutlined />,
                                        },
                                    ]}
                                />
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }} >
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                        <div >
                                            <FormattedMessage defaultMessage={'{action} sectors'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                        </div>
                                        <div >
                                            <Select
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                allowClear
                                                placeholder={<FormattedMessage defaultMessage={'Sectors to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                style={{ width: 270 }}
                                                value={this.state.selectedSectors}
                                                onChange={(selectedSectors) => this.setState({ selectedSectors })}
                                                filterOption={true}
                                                optionFilterProp="label"
                                                showArrow
                                                disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                options={this.props.sectors ? this.props.sectors.map(s => ({ value: s.id, label: s.title })) : []}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                        <div >
                                            <FormattedMessage defaultMessage={'{action} groups'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                        </div>
                                        <div >
                                            <Select
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                allowClear
                                                placeholder={<FormattedMessage defaultMessage={'Groups to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                style={{ width: 270 }}
                                                value={this.state.selectedGroups}
                                                onChange={(selectedGroups) => this.setState({ selectedGroups })}
                                                filterOption={true}
                                                optionFilterProp="label"
                                                showArrow
                                                disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                options={this.props.groups ? this.props.groups.map(s => ({ value: s.id, label: s.name })) : []}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                    {this.props.departments && this.props.departments.data.length > 0 &&
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >

                                            <div >
                                                <FormattedMessage defaultMessage={'{action} department'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />
                                            </div>
                                            <div >
                                                <Select
                                                    allowClear
                                                    placeholder={<FormattedMessage defaultMessage={'Department to {action}'} values={{ action: this.state.segmentedValue === 'remove' ? intl.formatMessage({ defaultMessage: 'Remove' }) : intl.formatMessage({ defaultMessage: 'Add' }) }} />}
                                                    style={{ width: 270 }}
                                                    value={this.state.department?.id}
                                                    onChange={(departmentId) => this.setState({ department: this.props.departments.data.find(d => d.id === departmentId) })}
                                                    filterOption={true}
                                                    optionFilterProp="label"
                                                    showArrow
                                                    disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                                    options={this.props.departments ? this.props.departments.data.map(s => ({ value: s.id, label: s.name })) : []}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </>
                        }
                    />
                    <PoiFastEdit
                        open={this.state.openTransform}
                        setOpen={(openTransform) => this.setState({ openTransform })}
                        pois={pois}
                        selectedPois={this.state.selectedPoi}
                        setSelectedPois={selectedPoi => this.setState({ selectedPoi })}
                        button={{ icon: <Anticon icon={<MdOutlineTransform />} />, title: intl.formatMessage({ defaultMessage: 'Convert or duplicate' }), disabled: (this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0) }}
                        title={intl.formatMessage({ defaultMessage: '{count, plural, one {Convert or duplicate 1 location} other {Convert or duplicate {count} location}}' }, { count: this.state.selectedPoi.length })}
                        body={
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                                        <FormattedMessage defaultMessage={'As departure location'} />
                                        <span className="anticon" ><MdOutlineMapsHomeWork /></span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                                        <Button
                                            type={'primary'}
                                            key="poi-duplic-base-poi-button"
                                            //className="__card-button-space"
                                            loading={this.state.loadingButton}
                                            disabled={this.state.loadingButton}
                                            //disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.duplicateToWorkPlace(true);
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Duplicate'} />
                                        </Button>
                                    </span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                                        <FormattedMessage defaultMessage={'Convert to workplace'} />
                                        <span className="anticon" ><BiMap /></span>
                                    </span>
                                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', gap: 8 }}>
                                        <Button
                                            key="poi-convert-base-poi-button"
                                            //className="__card-button-space"
                                            disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.convertToDeparturePoint();
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Convert'} />
                                        </Button>
                                        <Button
                                            type={'primary'}
                                            key="poi-duplic-base-poi-button"
                                            //className="__card-button-space"
                                            loading={this.state.loadingButton}
                                            disabled={this.state.loadingButton}
                                            //disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.duplicateToDeparturePoint();
                                            }}
                                        >
                                            <FormattedMessage defaultMessage={'Duplicate'} />
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        }
                    />
                    <DeleteButton
                        key="poi-delete-button"
                        //className="__card-button-space"
                        disabled={this.state.selectedPoi === undefined || this.state.selectedPoi.length === 0}
                        //icon={<DeleteOutlined />}
                        //title={"Supprimer"}
                        text={
                            <span>
                                <FormattedMessage defaultMessage={'{count, plural, one {This action will delete the selected point of interest.} other {This action will delete {count} selected points of interest.}}'} values={{ count: this.state.selectedPoi.length }} />
                                <FormattedMessage defaultMessage={'{count, plural, one {The events using this point of interest will not be deleted.} other {The events using these points of interest will not be deleted.}}'} values={{ count: this.state.selectedPoi.length }} />
                            </span>
                        }
                        // small={this.props.small}
                        // style={this.props.style}
                        onConfirm={() => this.deletePoi(undefined, this.state.selectedPoi)}
                        placement={'bottom'}
                    />
                    <CircleButton
                        onClick={() => this.startAddPoi()}
                        //className="__card-button-space"
                        key="poi-add-button"
                        title={intl.formatMessage({ defaultMessage: 'Add a location' })}
                        icon={<PlusOutlined />}
                    //placement="bottom"
                    />
                </div>
        ];
        const hasDepartments = this.props.departments && this.props.departments.data && this.props.departments.data.length > 0
        let filteredColumns = this.props.isSmartphone ? this.poiColumnsMobile : this.poiColumns
        if (!departurePoint) {
            filteredColumns = filteredColumns.filter(p => p.key !== 'isPrimary')
        }
        if (!hasDepartments) {
            filteredColumns = filteredColumns.filter(p => p.key !== 'department')
        }

        return (
            <>
                <Card
                    icon={departurePoint ? <BiMap className="anticon" /> : <span className="anticon" ><MdOutlineMapsHomeWork /></span>}
                    title={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                            <span><FormattedMessage defaultMessage={'Registered {location}'} values={{ location: departurePoint ? intl.formatMessage({ defaultMessage: 'departure locations' }) : intl.formatMessage({ defaultMessage: 'Workplaces' }) }} /></span>
                            <CircleButton
                                small
                                title={intl.formatMessage({ defaultMessage: 'Update locations' })}
                                icon={<CloudSyncOutlined />}
                                onClick={() => this.props.loadPois(true)}
                                loading={this.props.loadingPois}
                                disabled={this.props.pois == undefined}
                            />
                        </div>
                    }
                    headerElements={[
                        this.props.isSmartphone ?
                            <SpeedDial
                                key='poi-actions-speeddial'
                                title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                icon={<MoreOutlined />}
                                openIcon={<DashOutlined />}
                                buttons={headerButtons} />
                            :
                            headerButtons

                    ]}>
                    <Table
                        //loading={this.state.isLoadingPois}
                        key="table-pois"
                        className='__poi-list'
                        rowKey={(p: any) => p.id}
                        dataSource={pois}
                        columns={filteredColumns}
                        loading={this.props.loadingPois}
                        pagination={{
                            defaultPageSize: 16,
                            pageSizeOptions: ["8", "16", "24", "32", "64", "72", "500"],
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} points of interest'} values={{ range0: range[0], range1: range[1], total }} />
                        }}
                        onRow={this.poiOnRow}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedPoi }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No point of interest'} />} /> }}
                    />
                </Card>
                <Drawer
                    destroyOnClose={true}
                    width={this.props.isSmartphone ? '100%' : '450px'}
                    title={this.state.poiShow?.title}
                    placement="right"
                    onClose={() => this.closeShowDrawer()}
                    open={this.state.poiShow !== undefined}
                    className="__drawer"
                    footer={
                        <div style={{ display: "flex", justifyContent: "space-between" }} >

                            <Button type={"dashed"} onClick={() => {
                                this.deletePoi(this.state.poiShow);
                                this.closeShowDrawer();
                            }} style={{ marginRight: 8 }}
                            >
                                <FormattedMessage defaultMessage={'Delete'} />
                            </Button>
                            <div>
                                <Button onClick={() => this.closeShowDrawer()} style={{ marginRight: 8 }}>
                                    <FormattedMessage defaultMessage={'Close'} />
                                </Button>
                                <Button onClick={() => this.setState({ poiEdit: { ...this.state.poiShow }, displayAlert: false })} type="primary">
                                    <FormattedMessage defaultMessage={'Edit'} />
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        {this.state.poiShow && <PoiShowForm poiShow={this.state.poiShow} />}
                        <Row gutter={[10, 10]}>
                            {
                                this.state.displayAlert &&
                                <Col style={{ marginTop: '10px' }} xs={{ span: 24 }}>
                                    <Alert
                                        afterClose={() => this.setState({ displayAlert: false })}
                                        message={this.state.alertMessage}
                                        type={this.state.alertType}
                                        showIcon closable
                                    />
                                </Col>
                            }
                        </Row>
                    </div>


                </Drawer>
                <Modal
                    closable={!this.state.loadingUpdate}
                    destroyOnClose={true}
                    width={this.props.isSmartphone ? '100%' : 800}
                    title={this.state.poiEdit?.id ? <FormattedMessage defaultMessage={'Edit a POI'} /> : <FormattedMessage defaultMessage={'Create a POI'} />}
                    //placement="right"
                    onCancel={() => this.closeEditDrawer()}
                    open={this.state.poiEdit !== undefined}
                    className="__drawer"
                    footer={
                        <div style={{ textAlign: 'right', }} >
                            {
                                <>
                                    <Button disabled={this.state.loadingUpdate} onClick={() => this.closeEditDrawer()} style={{ marginRight: 8 }}>
                                        Fermer
                                    </Button>
                                    {this.state.poiEdit && this.state.poiEdit.isPrimary && this.props.basePois && this.props.basePois.data && this.props.basePois.data.filter(p => p.isPrimary && isEqual(p.department, this.state.poiEdit?.department) && this.state.poiEdit?.id !== p.id).length > 0 ?
                                        <Popconfirm
                                            open={this.state.popConfirmPrimaryWarning}
                                            title={<><p style={{ fontWeight: 'bolder' }}><FormattedMessage defaultMessage={'Change of primary location'} /></p><p><FormattedMessage defaultMessage={'This action will replace the primary location and recalculate distances for reports. All company user reports will be affected.'} /></p></>}
                                            okText={<FormattedMessage defaultMessage={'Confirm'} />}
                                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                            okButtonProps={{ type: 'link' }}
                                            cancelButtonProps={{ type: 'link' }}
                                            icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                                            placement={'topRight'}
                                            onConfirm={() => this.updatePoi(true)}
                                            onCancel={() => this.setState({ popConfirmPrimaryWarning: false })}
                                        >
                                            <Button loading={this.state.loadingUpdate} onClick={() => this.setState({ popConfirmPrimaryWarning: true })} type="primary">
                                                <FormattedMessage defaultMessage={'Save'} />
                                            </Button>
                                        </Popconfirm>
                                        :
                                        <Button loading={this.state.loadingUpdate} disabled={this.state.poiEdit === undefined} onClick={() => this.updatePoi()} type="primary">
                                            <FormattedMessage defaultMessage={'Save'} />
                                        </Button>
                                    }
                                </>
                            }
                        </div>
                    }
                >
                    {this.state.poiEdit && <PoiEditForm poiEdit={this.state.poiEdit} setPoiEdit={(poiEdit) => this.setState({ poiEdit })} />}
                </Modal>

            </>
        )
    }

    poiColumns: ColumnProps<POI>[] = [
        {
            title: <FormattedMessage defaultMessage={'Primary location'} />,
            key: 'isPrimary',
            width: 30,
            sorter: (a: POI, b: POI) => Number(a.isPrimary) - Number(b.isPrimary),
            render: (ob: POI) => {
                return (
                    <>
                        {ob.isPrimary ?
                            <div className="both-center" style={{ color: 'var(--success-color)', fontSize: '22px' }}><AiFillCheckCircle className='anticon' /></div>
                            :
                            <div className="both-center" style={{ color: 'var(--positive-color)', fontSize: '22px' }}><AiFillCloseCircle className='anticon' /></div>
                        }
                    </>

                )
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        // {
        //     title: 'Km',
        //     dataIndex: 'km',
        //     key: 'km',
        //     onFilter: (value, record) => {
        //         return record.km ?
        //             record.km.toString()
        //                 .toLowerCase()
        //                 .includes(value.toString().toLowerCase())
        //             : false
        //     },
        //     ...tableColumnTextFilterConfig<POI>(),
        //     sorter: (a: POI, b: POI) => !a.km ? -1 : !b.km ? 1 : a.km < b.km ? -1 : 1
        // },
        {
            title: <FormattedMessage defaultMessage={'Longitude'} />,
            dataIndex: 'longitude',
            key: 'longitude',
            onFilter: (value, record) => {
                return record.longitude!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.longitude ? -1 : !b.longitude ? 1 : a.longitude < b.longitude ? -1 : 1

        },
        {
            title: <FormattedMessage defaultMessage={'Latitude'} />,
            dataIndex: 'latitude',
            key: 'latitude',
            onFilter: (value, record) => {
                return record.latitude!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.latitude ? -1 : !b.latitude ? 1 : a.latitude < b.latitude ? -1 : 1

        },
        {
            title: <FormattedMessage defaultMessage={'Sectors'} />,
            dataIndex: 'sectors',
            key: 'sectors',
            render: (sectors: Sector[]) => sectors.map(s => (
                <Tag className="__report-groups-users-tags" style={s.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={s.id} >
                    {s.title}
                </Tag>
            ))

        },
        {
            title: <FormattedMessage defaultMessage={'Groups'} />,
            // dataIndex: 'groups',
            key: 'groups',
            // filters: this.props.groups?.map(g => ({value: g.id || 0, text: g.name || ''})),
            ...tableColumnTextFilterConfig<POI>(),
            onFilter: (value, record) => {
                const test = record.groups && record.groups.some(g => g.name && g.name
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                )
                return test || false;
            },
            render: (poi: POI) => poi.groups && poi.groups.map(g => (
                <Tag className="__report-groups-users-tags" style={g.id ? { cursor: 'pointer' } : {}} color="#f5f5f5" key={g.id} >
                    {g.name}
                </Tag>
            ))

        },
        {
            title: <FormattedMessage defaultMessage={'Department'} />,
            key: 'department',
            sorter: (a: POI, b: POI) => !a.department ? -1 : !b.department ? 1 : String(a.department.name) < String(b.department.name) ? -1 : 1,
            render: (poi: POI) => poi.department ? poi.department.name : ''
        },
        // {
        //     title: 'Parcours',
        //     dataIndex: 'courses',
        //     key: 'courses',
        //     render: (courses: Course[]) => courses.map(c => (
        //         <Tag className="__report-groups-users-tags" style={c.id ? { cursor: 'pointer', marginBottom: "2px", marginTop: "2px" } : { marginBottom: "4px" }} color="#f5f5f5" key={c.id} >
        //             {c.title}
        //         </Tag>
        //     ))

        // },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '130px',
            // className: '__poi-actions',
            render: (record: POI) => {
                return (
                    this.props.isSmartphone ?
                        <SpeedDial
                            key='poi-actions-speeddial'
                            title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            buttons={this.poiColumnsActions(record)} />
                        :
                        this.poiColumnsActions(record)
                );
            },
        }
    ];

    poiColumnsMobile: ColumnProps<POI>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'title',
            key: 'title',
            onFilter: (value, record) => {
                return record.title!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<POI>(),
            sorter: (a: POI, b: POI) => !a.title ? -1 : !b.title ? 1 : a.title < b.title ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '90px',
            className: '__poi-actions',
            render: (record: POI) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <SpeedDial
                        key='poi-actions-speeddial'
                        title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                        icon={<MoreOutlined />}
                        openIcon={<DashOutlined />}
                        buttons={this.poiColumnsActions(record)} />
                </div>
            },
        }
    ];
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    courses: state.location.courses,
    sectors: state.location.sectors,
    groups: state.teamManagement.groups,
    departments: state.configurations.departments,
    loadingPois: state.location.loadingPois
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadPois: (force?: boolean) => dispatch(loadPois(force)),
    changeCourses: (courses: Course[]) => dispatch(changeCourses(courses)),
    changeSectors: (sectors: Sector[]) => dispatch(changeSectors(sectors)),
    loadDepartments: (force?: boolean) => dispatch(loadDepartments(force)),
});
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(injectIntl(PoiTab))