import React, { CSSProperties } from 'react';

interface Props {
    icon?: React.ReactNode;
    style?: CSSProperties;
    children?: React.ReactNode;
    onClick?: any;
    className?: string;
}

/**
 * Component that represent a card
 */
class Anticon extends React.Component<Props> {
    render() {
        return (
            <span onClick={this.props.onClick} style={this.props.style} className={`anticon ${this.props.className ?? ''}`}>{this.props.icon ?? this.props.children}</span>
        );
    }
}
export default Anticon;