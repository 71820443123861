import { Checkbox, Divider, Select } from "antd";
import { ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useCompany from "../../../../hooks/useCompany";
import useDepartments from "../../../../hooks/useDepartments";
import useGroups from "../../../../hooks/useGroups";
import usePOIs from "../../../../hooks/usePOIs";
import useProjects from "../../../../hooks/useProjects";
import useStaffTypes from "../../../../hooks/useStaffTypes";
import useTypeOfDays from "../../../../hooks/useTypeOfDays";
import useUsers from "../../../../hooks/useUsers";
import { CaseType } from "../../../../utils/constants";
import { DictionaryString } from "../../../../utils/types/generalTypes";
import { getCaseAndPlural } from "../../../../utils/utils";
import FAIcon from "../../../common/FAIcon";
import CircleButton from "../../../common/fields/circleButton";
const { Option } = Select;

interface Props {
    showEmpty: boolean;
    setShowEmpty: (show: boolean) => void;

    allUserIds: string[];
    selectedUsers: number[];
    changeUsers: (ids: number[]) => void;

    selectedGroups: number[];
    usersToExclude: number[];
    changeGroups: (ids: number[], userToExclude: number[]) => void;

    selectedTypeOfDays: number[];
    changeTypeOfDays: (ids: number[]) => void;

    selectedDepartments: number[];
    changeDepartments: (ids: number[]) => void;

    selectedProjects: number[];
    changeProjects: (ids: number[]) => void;

    selectedStaffTypes: number[];
    changeStaffTypes: (ids: number[]) => void;

    selectedPOIs: number[];
    changePOIs: (POIs: number[]) => void;

    reset: () => void;

    confirmed: boolean | undefined;
    setConfirmed: (confirmed: boolean | undefined) => void;
}

const Filters = (props: Props) => {
    const intl = useIntl();
    const company = useCompany();
    const typeOfDays = useTypeOfDays();
    const users = useUsers();
    const groups = useGroups();
    const POIs = usePOIs();
    const departments = useDepartments();
    const projects = useProjects();
    const staffTypes = useStaffTypes();
    const usersSorted = useMemo(() => users.sort((a, b) => `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`) ? 1 : -1), [users]);
    const usersForExclusion = useMemo(() => {
        let userIdsFromGroups: number[] = [];
        if (props.selectedGroups.length > 0) {
            const userIdInSelectedGroups = props.selectedGroups.reduce((obj, group) => {
                if (group)
                    obj[group] = [];
                return obj;
            }, {} as DictionaryString<number[]>);
            for (const user of users)
                if (user.group_users)
                    if (user.group_users.some(g => props.selectedGroups.includes(g.group)))
                        for (const groupUser of user.group_users)
                            if (groupUser.group in userIdInSelectedGroups)
                                userIdInSelectedGroups[groupUser.group].push(user.id);

            userIdsFromGroups = Object.keys(userIdInSelectedGroups).reduce((userIds, groupId) => [...userIds, ...userIdInSelectedGroups[groupId]], [] as number[]);
        }
        return {
            usersAvailableForExclusion: users.filter(u => userIdsFromGroups.includes(u.id)),
            usersToExclude: props.usersToExclude.filter(u => userIdsFromGroups.includes(u))
        };
    }, [props, users]);

    // #region Users
    const userFilter = <Filter title={<FormattedMessage defaultMessage={'Users'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Users to display'} />}
            style={{ width: '100%' }}
            value={props.selectedUsers}
            onChange={props.changeUsers}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                usersSorted.filter(u => props.allUserIds.includes(`${u.id}`)).map(user => (<Option
                    value={user.id}
                    key={"users-" + user.id}
                    label={`${user.last_name} ${user.first_name}`}
                >
                    {user.last_name} {user.first_name}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region Groups
    const groupFilter = <Filter title={<FormattedMessage defaultMessage={'Groups'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Groups to display'} />}
            style={{ width: '100%' }}
            value={props.selectedGroups}
            onChange={(groups) => props.changeGroups(groups, props.usersToExclude)}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                groups.map(group => (<Option
                    value={group.id}
                    key={"groups-" + group.id}
                    label={`${group.name}`}
                >
                    {group.name}
                </Option>
                ))
            }
        </Select>}
    />;

    const excludeFilter = <Filter title={<FormattedMessage defaultMessage={'Exclude'} />}
        input={<Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Users to exclude'} />}
            style={{ width: '100%' }}
            value={usersForExclusion.usersToExclude}
            onChange={(exclude) => props.changeGroups(props.selectedGroups, exclude)}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                usersForExclusion.usersAvailableForExclusion.map(user => (<Option
                    value={user.id}
                    key={"users-to-exclude-" + user.id}
                    label={`${user.last_name} ${user.first_name}`}
                >
                    {`${user.last_name} ${user.first_name}`}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region Type of days
    const typeOfDaysFilter = <Filter title={<FormattedMessage defaultMessage={'Type of days'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Types of day to display'} />}
            style={{ width: '100%' }}
            value={props.selectedTypeOfDays}
            onChange={props.changeTypeOfDays}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                typeOfDays.map(t => (<Option
                    value={t.id}
                    key={"tod-" + t.id}
                    label={`${t.title}`}
                >
                    {t.title}
                </Option>
                ))
            }
        </Select>}
    />;
    // #endregion

    // #region POIs
    const POIsFilter = POIs ? <Filter title={<FormattedMessage defaultMessage={'Points of interests'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Points of interest to display'} />}
            style={{ width: '100%' }}
            value={props.selectedPOIs}
            onChange={props.changePOIs}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                POIs.map(poi => (<Option
                    value={poi.id}
                    key={"poi-" + poi.id}
                    label={`${poi.title}`}
                >
                    {poi.title}
                </Option>
                ))
            }
        </Select>}
    /> : <></>;
    // #endregion

    // #region Confirmed
    const confirmedFilter = <Filter input={
        <div className="confirmed-filter">
            <span><FormattedMessage defaultMessage={'Events'} /></span>

            <div onClick={() => props.setConfirmed(props.confirmed === undefined ? true : props.confirmed ? false : undefined)}>
                <FAIcon prefix='fas' name='caret-left' />
                <span>{props.confirmed === undefined
                    ?
                    <FormattedMessage defaultMessage={'All'} />
                    : props.confirmed
                        ? <FormattedMessage defaultMessage={'Confirmed'} />
                        : <FormattedMessage defaultMessage={'Not confirmed'} />
                }
                </span>
                <FAIcon prefix='fas' name='caret-right' />
            </div>
        </div>
    } />;
    // #endregion

    // #region Departments
    const departmentsFilter = <Filter title={<FormattedMessage defaultMessage={'Departments'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Departments to display'} />}
            style={{ width: '100%' }}
            value={props.selectedDepartments}
            onChange={props.changeDepartments}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                departments && departments.data && departments.data.map(d => (<Option
                    value={d.id}
                    key={"department-" + d.id}
                    label={`${d.name}`}
                >
                    {d.name}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Departments
    const staffTypesFilter = <Filter title={<FormattedMessage defaultMessage={'Staff types'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Staff types to display'} />}
            style={{ width: '100%' }}
            value={props.selectedStaffTypes}
            onChange={props.changeStaffTypes}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                staffTypes && staffTypes.data?.map(s => (<Option
                    value={s.id}
                    key={"st-" + s.id}
                    label={`${s.name}`}
                >
                    {s.name}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Projects
    const projectsFilter = <Filter title={company?.projectDisplayText ? getCaseAndPlural(company.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : <FormattedMessage defaultMessage={'Projects'} />} input={
        <Select
            className="__filter-users-group-select"
            listHeight={500}
            mode="multiple"
            allowClear
            // placeholder={<FormattedMessage defaultMessage={'Projects to display'} />}
            style={{ width: '100%' }}
            value={props.selectedProjects}
            onChange={props.changeProjects}
            filterOption={true}
            optionFilterProp="label"
            showArrow
            maxTagCount={"responsive"}
        >
            {
                projects.length > 0 && projects.map(p => (<Option
                    value={p.id}
                    key={"project-" + p.id}
                    label={`${p.title}`}
                >
                    {p.title}
                </Option>
                ))
            }
        </Select>
    } />;
    // #endregion

    // #region Show empty
    const showEmptyFilter = <Filter input={
        <div>
            <Checkbox checked={!props.showEmpty} onChange={(e) => props.setShowEmpty(!e.target.checked)}>
                <FormattedMessage defaultMessage={'Hide users with no events'} />
            </Checkbox>
        </div>
    } />;
    // #endregion

    return (
        <div className="filters">
            <div className="main-title"><FAIcon prefix="far" name="user" /> Utilisateurs</div>
            {groupFilter}
            {props.selectedGroups.length > 0 ? excludeFilter : <></>}
            <Divider />
            {userFilter}
            <Divider />
            {showEmptyFilter}

            <div className="main-title"><FAIcon prefix="far" name="calendar" /> Evénements</div>
            {typeOfDaysFilter}
            <Divider />
            {POIsFilter}
            {departments && departments.data && departments.data.length > 0
                ?
                <>
                    <Divider />
                    {departmentsFilter}
                </>
                : <></>
            }
            {projects.length > 0
                ?
                <>
                    <Divider />
                    {projectsFilter}
                </>
                : <></>
            }
            <Divider />
            {staffTypesFilter}
            <Divider />
            {confirmedFilter}

            <div className="reset-filters-button">
                <CircleButton
                    className='__monthly-planning-btn-filter'
                    small={true}
                    type={"default"}
                    icon={<FAIcon prefix="fal" name="rotate-left" color="dark" />}
                    onClick={props.reset}
                    title={intl.formatMessage({ defaultMessage: 'Reset' })} />
            </div>
        </div>
    );
};

const Filter = (props: { title?: ReactNode; input: ReactNode; }) => {
    return (
        <div className="filter-group">
            {props.title ? <div className="title">{props.title}</div> : <></>}
            <div>
                {props.input}
            </div>
        </div>
    );
};

export default Filters;