import { TagOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from './inputField';

interface IProps {
    tags: string[] | undefined;
    onTagCreated: (tag: string) => void;
    onTagClosed?: (tag: string, index: number) => void;
    withoutAnimation?: boolean; // for the tags div animation
}

interface State {
    value: string;
}

type Props = IProps & InputProps & IntlProps;

/**
 * Component that represent a input field for tags
 */
class TagField extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            value: "",
        }
    }

    /**
     * Create a new tag
     */
    createTag = () => {
        this.props.onTagCreated(this.state.value);
        this.setState({ value: "" });
    }

    /**
     * Delete a tag
     * @param t the tag to delete
     * @param index the index of the tag
     */
    onClose = (t: string, index: number) => {
        if (this.props.onTagCreated !== undefined) {
            const f = this.props.onTagClosed as (tag: string, index: number) => void;
            f(t, index);
        }
    }

    render() {
        const { className, tags, onTagClosed, withoutAnimation, intl } = this.props;
        return (
            <div className={className}>
                <div className={tags && tags.length > 0 && !withoutAnimation ? "expand-appear-right-animation" : ""}>
                    <span className={tags && tags.length > 0 ? "tagfield-tags" : ""}>
                        {
                            tags?.map((t: string, index: number) => (
                                <Tag
                                    className="tagfield-tag"
                                    color="var(--primary-color)"
                                    key={`tags-${index}-${t}`}
                                    closable={onTagClosed !== undefined}
                                    onClose={() => this.onClose(t, index)}>
                                    {t}
                                </Tag>
                            ))
                        }
                    </span>
                </div>
                <InputField
                    value={this.state.value}
                    placeholder={intl.formatMessage({ defaultMessage: "Press 'Enter' to add a tag" })}
                    onChange={(e: InputFieldOnChangeEvent) => this.setState({ value: e.target.value })}
                    addonAfter={<TagOutlined className="tagfield-icon" onClick={this.createTag} />}
                    onPressEnter={this.createTag} />
            </div>
        );
    }
}

export default injectIntl(TagField);