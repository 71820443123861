import { Button, Drawer, Spin } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../../styles/ccnt.css';
import type { CcntReport as CcntReportType } from '../../../utils/types/reportTypes';
import CcntReport from './ccntReport';
import CcntReportPrintPage from './ccntReportPrintPage';

interface IProps {
    isVisible: boolean;
    isLoading: boolean;
    ccnt?: CcntReportType;
    close: () => void;
    year: Moment;
}

type Props = IProps;

interface State {
    displayCcntPrintPage: boolean;
}

class DrawerCcntReport extends React.Component<Props, State> {
    componentRef: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            displayCcntPrintPage: false,
        };

    }



    close = () => {
        this.props.close();
    };



    printPDF = () => {
        this.setState({ displayCcntPrintPage: true });
    };



    render() {
        const { ccnt, isLoading } = this.props;
        const { displayCcntPrintPage } = this.state;

        return (
            <>
                <Drawer
                    destroyOnClose={true}
                    width={'90%'}
                    title={"Ccnt (Vers.INF.FR 01.21)"}
                    placement="right"
                    onClose={() => this.close()}
                    visible={this.props.isVisible}
                    className="__drawer"
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {
                                <>
                                    <Button onClick={() => this.close()} style={{ marginRight: 8 }}>
                                        <FormattedMessage defaultMessage={'Cancel'} />
                                    </Button>
                                    <Button type="primary" onClick={this.printPDF} ><FormattedMessage defaultMessage={'Print'} /></Button>
                                </>
                            }
                        </div>
                    }
                >
                    {
                        isLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: 'var(--border-radius)', border: '1px solid rgb(177, 177, 177)' }}>
                                <Spin size='large' />
                            </div>
                            :
                            <>
                                {ccnt ?
                                    <div className='interactivCcnt v2'>
                                        {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <CcntReport ccnt={ccnt} ref={el => (this.componentRef = el)} year={this.props.year} />
                                    </div>
                                    :
                                    <><FormattedMessage defaultMessage={'An error occurred while loading the report.'} /><br /><FormattedMessage defaultMessage={'Contact WebEvolutions support.'} /></>}
                            </>
                    }
                </Drawer>
                {(displayCcntPrintPage && ccnt) && <CcntReportPrintPage ccnt={ccnt} close={() => this.setState({ displayCcntPrintPage: false })} year={this.props.year} />}
            </>
        );

    }
}

export default (DrawerCcntReport);