import { EditOutlined, HourglassOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { CellOvertime, PlanningOvertime } from '../../utils/types/planningTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import CircleButton from '../common/fields/circleButton';
import DeleteButton from '../common/fields/deleteButton';
import Can from '../common/general/can';

interface RowRanges {
    start: [number, number];
    end?: [number, number]; //if start === end no need to duplicate
}

interface Props {
    cellOvertime: CellOvertime;
    cellsPerRow?: number,
    windowWidth: number,
    onEdit?: (overtime: PlanningOvertime) => void,
    onDelete?: (overtime: PlanningOvertime) => void,
}

interface State {
    rowRanges: RowRanges;
}

class Overtime extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        //get rowRanges
        const { startCell, endCell } = this.props.cellOvertime;
        const { cellsPerRow } = this.props;

        //for start cell
        const startRow = Math.floor(startCell / cellsPerRow!);
        const start: [number, number] = [startRow * cellsPerRow!, startRow * cellsPerRow! + (cellsPerRow! - 1)];

        //for end cell
        const endRow = Math.floor(endCell / cellsPerRow!);
        const end: [number, number] = [endRow * cellsPerRow!, (endRow + 1) * (cellsPerRow! - 1)];

        const rowRanges = {
            start,
            end: start[0] !== end[0] ? end : undefined,
        }

        this.state = {
            rowRanges,
        };
    }

    /**
     * Get the event width in px
     * @return the event width
     */
    getEventWidth = (): number => {
        const { cellOvertime } = this.props;
        const width = this.props.windowWidth > 2000 ? 65 : (this.props.windowWidth > 1000 ? 55 : 45);

        const toEndOfDay = this.state.rowRanges.start[1] - cellOvertime.startCell;
        const toEndOfEvent = cellOvertime.endCell - cellOvertime.startCell;

        //if the end of event is greater than the end of day (24:00) cut the event so it won't go beyond the calendar
        //a cell is 40px + 30px so the event arrive at the end of 30min time
        const result = (toEndOfEvent > toEndOfDay ? toEndOfDay : toEndOfEvent);

        return (result <= 0 ? 1 : result) * width + Math.ceil(width * 0.8);
    }

    render() {
        const { cellOvertime } = this.props;
        const { overtime } = cellOvertime;
        const title = overtime.title && overtime.title.length > 0 ? overtime.title : "Heures supp.";
        const color = 'var(--overtime-primary-color)';

        const summary = (
            <div onClick={(e) => e.stopPropagation()}>
                <div className="planning-event-summary-header">
                    <span className="planning-event-summary-flex" style={{ marginRight: '20px' }}>
                        <div className="planning-event-summary-dot" style={{ backgroundColor: color }} />
                        <p style={{ fontSize: '17px', marginRight: '10px' }}>{title} <span>{overtime.isConfirmed === false && <HourglassOutlined />}</span></p>
                    </span>
                    <Can
                        rule={Rules.Planning.Management}
                        privilege={Privileges.Planning.Edition}>
                        <span className="planning-event-summary-flex">
                            <CircleButton style={{ marginRight: '10px' }} title={"Modifier"} icon={<EditOutlined />} onClick={() => this.props.onEdit!(overtime)} />
                            <DeleteButton
                                text={"Etes-vous sûr de vouloir supprimer ces heures " + (overtime.isNegative === true ? "négatives?" : "supplémentaires?")}
                                onConfirm={() => this.props.onDelete!(overtime)} />
                        </span>
                    </Can>
                </div>
                <div className="planning-event-summary-flex" style={{ marginLeft: '20px' }}>
                    <span className="planning-event-summary-flex">
                        <p className="planning-event-summary-time" style={{ color }}>{overtime.startDate.format("HH:mm")}</p>
                        <p className="planning-event-summary-day">{overtime.startDate.format("DD MMMM")}</p>
                    </span>
                    <p style={{ margin: '0px 15px' }}>{"-"}</p>
                    <span className="planning-event-summary-flex">
                        <p className="planning-event-summary-time" style={{ color }}>{overtime.endDate.format("HH:mm")}</p>
                        <p className="planning-event-summary-day">{overtime.endDate.format("DD MMMM")}</p>
                    </span>
                </div>
                <div className="planning-event-summary-buttons">
                </div>
            </div>
        );

        const style: React.CSSProperties = {
            width: `${this.getEventWidth()}px`,
        }

        return (
            <Popover
                content={summary}
                placement="bottom">
                <div onClick={e => e.stopPropagation()} className={"planning-event-parent planning-" + (overtime.isNegative === true ? "undertime" : "overtime") + "-parent"} style={style}>
                    <p className="planning-event-title">{title} <span>{overtime.isConfirmed === false && <HourglassOutlined />}</span></p>
                </div>
            </Popover>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    windowWidth: state.window.width,
    cellsPerRow: state.planning.cellsPerRow,
    onEdit: state.planning.onEditOvertime,
    onDelete: state.planning.onDeleteOvertime,
});

export default connect(mapStateToProps)(Overtime);