import { Button, Col, Modal, Row, Tag } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { ReactElement } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import { UserAvailabilityEdit } from '../../../utils/types/planningTypes';
import { getWeekdayText } from '../../../utils/utils';
import AvailabilityEditForm, { AvailabilityState } from './availabilityEditForm';

interface Props {
    userRole?: boolean;
    availability?: UserAvailabilityEdit;
    loading: boolean;
    availabilityState: AvailabilityState;
    onCancel: () => void;
    onOk: () => void;
}

interface State {
    availability?: UserAvailabilityEdit;
    resetForm: boolean;
    validateForm: boolean;
}

/**
 * Component for the creation/ edition of a period/exclusion
 */
class AvailabilityEditModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            availability: cloneDeep(props.availability),
            resetForm: false,
            validateForm: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.availability, this.props.availability)) {
            this.setState({ availability: cloneDeep(this.props.availability) });
        }
    }


    /**
     * Cancel the creation, edition
     */
    onCancel = (): void => {
        this.props.onCancel();
    }

    /**
     * Called when the user click on the OK button
     */
    onReset = (): void => {
        this.setState({ resetForm: true });
    }
    /**
     * Called when the user click on the OK button
     */
    onDone = (): void => {
        this.setState({ validateForm: true });

    }

    /**
     * Render method for the weekday select
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderWeekdaySelect = (props: any): ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {getWeekdayText(props.value)}
        </Tag>
    );

    render() {
        const { availability, resetForm, validateForm } = this.state;
        const { availabilityState } = this.props;
        const propsAvailability = this.props.availability;
        const isEdit = availability?.id !== undefined;
        return (
            <Modal
                className="__availability-modal"
                open={propsAvailability !== undefined}
                title={
                    isEdit ?
                        availabilityState === AvailabilityState.Close ?
                            <FormattedMessage defaultMessage={'Edit availability'} />
                            :
                            <FormattedMessage defaultMessage={'Edit unavailability'} />
                        :
                        availabilityState === AvailabilityState.Close ?
                            <FormattedMessage defaultMessage={'Add an availability'} />
                            :
                            <FormattedMessage defaultMessage={'Add an unavailability'} />
                }
                // style={{ minWidth: '620px' }}
                width={"850px"}
                onCancel={this.onCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={this.onCancel} key="period-modal-cancel-button">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <div key="period-modal-save-delete-button">
                        <Button type="default" onClick={this.onReset} key="period-modal-reset-button">
                            <FormattedMessage defaultMessage={'Reset'} />
                        </Button>
                        <Button loading={this.props.loading} id="periods-modal-ok-button" type="primary" onClick={this.onDone} key="planning-modal-button-ok">
                            {isEdit ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Create'} />}
                        </Button>
                    </div>
                ]}>

                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }}>
                        {
                            availability ?
                                <AvailabilityEditForm
                                    availabilityState={availabilityState}
                                    availability={availability}
                                    resetForm={resetForm}
                                    validateForm={validateForm}
                                    changeResetForm={(val) => this.setState({ resetForm: val })}
                                    changeValidateForm={(val) => this.setState({ validateForm: val })}
                                    close={() => this.props.onOk()}
                                />
                                : null
                        }
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default AvailabilityEditModal;