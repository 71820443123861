import { StoreAction } from "../../utils/types/storeTypes";
import { Company, Medal, Country, CompanyProduct, CodeDiscount, Language } from "../../utils/types/generalTypes";
import { RESET, CHANGE_COMPANY_DETAILS, CHANGE_SIGNATURE, CHANGE_MEDALS, CHANGE_COUNTRIES, CHANGE_COMPANY_PRODUCTS, CHANGE_COMPANY_EDIT_PRODUCT, CHANGE_COMPANY_CODES_DISCOUNT, CHANGE_LANGUAGES } from "../reducer/crm";

/**
 * Change stored company details
 * @param companyDetails the new company details to store
 * @return a store action
 */
export const changeCompanyDetails = (companyDetails: Company): StoreAction => ({ type: CHANGE_COMPANY_DETAILS, data: companyDetails });


/**
 * Change stored company products
 * @param companyPoducts the new company products to store
 * @return a store action
 */
export const changeCompanyProducts = (companyProducts: CompanyProduct[]): StoreAction => ({ type: CHANGE_COMPANY_PRODUCTS, data: companyProducts });

/**
* Change stored company codes discounts
* @param companyCodeDiscounts the new company codes discount to store
* @return a store action
*/
export const changeCompanyCodesDiscount = (companyCodeDiscounts: CodeDiscount[]): StoreAction => ({ type: CHANGE_COMPANY_CODES_DISCOUNT, data: companyCodeDiscounts });

/**
 * Change stored company products
 * @param companyPoducts the new company products to store
 * @return a store action
 */
export const changeCompanyEditProduct = (companyEditProduct: CompanyProduct[]): StoreAction => ({ type: CHANGE_COMPANY_EDIT_PRODUCT, data: companyEditProduct });

/**
 * Change stored signature
 * @param signature the new signature to store
 * @return a store action
 */
export const changeSignature = (signature: string): StoreAction => ({ type: CHANGE_SIGNATURE, data: signature });

/**
 * Change stored medals
 * @param medals the new medals to store
 * @return a store action
 */
export const changeMedals = (medals: Medal[]): StoreAction => ({ type: CHANGE_MEDALS, data: medals });

/**
 * Change stored countries
 * @param countries the new languages to store
 * @return a store action
 */
export const changeCountries = (countries: Country[]): StoreAction => ({ type: CHANGE_COUNTRIES, data: countries });
/**
 * Change stored languages
 * @param countries the new languages to store
 * @return a store action
 */
export const changeLanguages = (languages: Language[]): StoreAction => ({ type: CHANGE_LANGUAGES, data: languages });

/**
 * Reset all stored values
 * @return a store action
 */
export const reset = () => ({ type: RESET });