import { ReconciliationOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { BsCalendar3 } from 'react-icons/bs';
import { IoMdColorPalette } from 'react-icons/io';
import { TbTemplate } from 'react-icons/tb';
import Anticon from '../common/general/anticon';
import Container from '../common/navigations/container';
//import MonthlyPlanning from './monthly/monthlyPlanning';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../../utils/types/storeTypes';
import Colors from './tabs/colors';
import OccupancyRate from './tabs/occupancyRate';
import PlanningTab from './tabs/planning';
import Template from './tabs/template';


export const Planning = () => {
    const history = useHistory()
    const { lang, tab } = useParams<{ tab?: string, lang?: string }>()
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser)
    const fullscreen = useSelector((state: ApplicationState) => state.window.fullscreen)

    const intl = useIntl();

    const goTo = useCallback((link: string): void => history.push(`/${lang}/${link}`), [history, lang])

    const onChangeTab = useCallback((tabStr: string): void => {
        if (tabStr === 'planning') {
            tabStr = 'monthly'
        }
        goTo('planning/' + tabStr)
    }, [goTo])

    const getCurrentTab = useCallback(() => {

        switch (tab) {
            case 'monthly':
                return 'planning'
            case 'weekly':
                return 'planning'
            case 'requirements':
            case 'templates':
            case 'colors':
                return tab
            default:
                goTo('planning/monthly')
        }
        goTo('planning/monthly')
    }, [tab, goTo])

    const items = useMemo(() => [
        {
            label: (
                <span>
                    <Anticon icon={<BsCalendar3 />} />
                    <FormattedMessage defaultMessage={'Planning'} />
                </span>
            ),
            key: 'planning',
            children: <PlanningTab key={'planningComponent'} />
        },
        ...([2, 3].includes(currentUser ? currentUser.role : 1) ? [
            {
                label: (
                    <span>
                        <ReconciliationOutlined />
                        <FormattedMessage defaultMessage={'Requirements'} />
                    </span>
                ),
                key: 'requirements',
                children: <OccupancyRate key={'requirementsComponent'} />
            },
            {
                label: (
                    <span>
                        <Anticon icon={<TbTemplate />} />
                        <FormattedMessage defaultMessage={'Templates'} />
                    </span>
                ),
                key: 'templates',
                children: <Template key={'templatesComponent'} />
            },
            {
                label: (
                    <span>
                        <Anticon icon={<IoMdColorPalette />} />
                        <FormattedMessage defaultMessage={'Colors'} />
                    </span>
                ),
                key: 'colors',
                children: <Colors key={'colorsComponent'} />
            }
        ]
            :
            []),

    ], [currentUser])


    return (
        fullscreen ?
            <>{items.find(i => i.key === getCurrentTab())?.children}</>
            :
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Planning' }), link: "/planning" }]}>
                <Tabs animated activeKey={getCurrentTab()} onChange={onChangeTab} items={items} />
            </Container>
    );
}

export default Planning;
