import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppLanguage } from "../../utils/constants";
import { LanguageState } from "../../utils/types/storeTypes";

export const INIT_STATE: LanguageState = {
    current: AppLanguage.FR
};

const languageSlice = createSlice({
    name: 'language',
    initialState: INIT_STATE,
    reducers: {
        languageChanged: (state, action: PayloadAction<LanguageState['current']>) => {
            state.current = action.payload;
        }
    },
});

export const { languageChanged } = languageSlice.actions;
export default languageSlice.reducer;