import moment from "moment";
import { ICustomer, ISite } from "../../../utils/types/customerTypes";

export const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}


export const loremIpsum = (
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fermentum, velit a ullamcorper pharetra, risus lectus eleifend eros, in fermentum turpis metus sit amet dolor. Phasellus suscipit scelerisque suscipit. Proin a velit risus. Etiam at tincidunt diam, non molestie augue. Duis sit amet euismod nunc. Sed sit amet erat malesuada ligula consectetur eleifend. Sed mauris augue, consequat quis dolor quis, dapibus tempus lacus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse id enim malesuada metus auctor egestas. Sed eget lectus eget ipsum tempor dignissim.\n \
    Integer vehicula lacus sed sapien molestie, a sodales quam vehicula. Sed eu accumsan ligula. Suspendisse ac iaculis est. Proin nec fringilla mauris. Mauris finibus sollicitudin nibh id laoreet. Phasellus nec neque turpis. Aliquam vitae sollicitudin dui. Curabitur a dictum nibh. Nullam non ullamcorper dui. Curabitur quis rhoncus diam. Nam eget elementum turpis. Vivamus congue dignissim ipsum, at facilisis leo venenatis ut. Nam in eros quam. Donec vestibulum, ligula molestie dignissim laoreet, tortor nisl molestie nulla, ac faucibus metus dui in sem. Maecenas aliquam hendrerit rhoncus. Quisque gravida accumsan felis eget lobortis.\n  \
    Maecenas at tellus commodo, porta tortor ut, vulputate metus. Cras rhoncus sapien non nisl sodales, eget varius nunc luctus. Ut posuere dui at pellentesque iaculis. Duis a diam ut augue egestas consequat. Nam aliquam at purus in faucibus. In vitae tristique diam. Fusce efficitur leo quam, et vulputate velit commodo vulputate. Fusce accumsan consectetur est, ut hendrerit risus ornare quis. Nunc nisi odio, condimentum et viverra in, maximus ut ipsum. In erat nisl, feugiat quis cursus non, facilisis vel est. Vivamus dapibus vel tortor nec laoreet. Phasellus sagittis malesuada est, sed pulvinar turpis pharetra ut. Nullam feugiat laoreet quam non rhoncus. Suspendisse potenti.\n  \
    Phasellus et urna at odio posuere malesuada. Donec suscipit velit in est gravida, ut aliquam justo lobortis. Aliquam molestie felis nec euismod rhoncus. Nulla ac tincidunt lectus. Mauris quis accumsan arcu. Nullam nunc orci, mattis sit amet blandit eget, suscipit vel nulla. Sed convallis enim nulla. Ut volutpat vulputate felis, a semper arcu placerat ac. Nulla facilisi. Nam sed ligula ut ante vulputate mattis quis eget diam. Quisque in mauris metus. Vivamus non faucibus elit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed scelerisque sapien non turpis convallis porttitor.\n  \
    Aliquam consectetur elit sed purus iaculis, non interdum lorem luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus vitae ligula efficitur ante gravida lobortis. Sed vestibulum, nunc at pulvinar sagittis, erat orci suscipit lectus, id rhoncus ligula metus mattis ligula. Vivamus a nisl lectus. Vivamus et tellus eu dui gravida hendrerit. Donec placerat purus eu nisl sagittis sodales. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque lectus felis, dictum eget ligula at, egestas lacinia erat. Aliquam dignissim augue nec est vestibulum, sit amet rhoncus lectus eleifend. Quisque viverra laoreet neque, sed tempor purus varius sed. Sed sodales, urna nec dignissim aliquet, nisi tellus tincidunt est, eget ultricies nisi magna vitae elit. Praesent vehicula gravida turpis sit amet tristique. Nulla ac sapien eget libero ultrices pretium. Praesent pulvinar vel ante vitae rutrum.\n \
    Quisque maximus sapien porttitor, condimentum ante dignissim, commodo mauris. In ante ligula, blandit a diam in, ultrices commodo neque. Sed aliquam id ante vel efficitur. Pellentesque quis pharetra nunc, sed dignissim quam. Phasellus hendrerit, lorem a imperdiet pellentesque, magna odio volutpat libero, id semper sapien sapien nec elit. Vivamus mollis dignissim purus nec pretium. Nullam arcu ante, aliquam ut arcu nec, laoreet fermentum dolor. In pellentesque arcu ac dapibus volutpat. In imperdiet, sem a mattis imperdiet, odio dui vulputate orci, eget accumsan nibh ipsum eget enim. Ut quis cursus odio. Morbi id mollis urna. Donec id tristique turpis, sed pretium elit. \n \
    Suspendisse viverra, elit ac pretium mollis, massa ligula vulputate velit, eu venenatis mauris nisi non dolor. Vivamus id interdum nibh. Nunc varius pharetra scelerisque. Nulla at lectus et ligula tincidunt vulputate. Quisque ut ante nec lectus imperdiet sodales. Donec rutrum malesuada odio, quis dapibus tortor lobortis et. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc sed metus non nibh elementum egestas id vitae nibh. \n \
    Proin luctus ex nec ipsum pulvinar viverra. Cras sollicitudin, mauris a hendrerit dictum, sem ipsum pulvinar odio, id scelerisque purus lacus in justo. Donec quis nisi mi. Sed at neque a urna sagittis rhoncus quis efficitur urna. Curabitur lobortis magna et elit egestas mattis. Phasellus convallis at ex nec pretium. Nam condimentum finibus enim, quis pretium risus posuere ac. Sed sollicitudin ultrices massa, quis feugiat leo feugiat et. Donec tempor, nisl eu aliquam dictum, eros felis venenatis dolor, ac venenatis enim risus vel magna. Proin fringilla mauris nec sapien commodo, a hendrerit mi condimentum. Sed lobortis elit non turpis elementum, in ultricies tortor mollis. Nulla varius mauris in diam maximus, gravida varius nisl lacinia. Sed vulputate tempus erat, vitae sollicitudin orci lacinia non. Nam elementum, tellus nec porttitor aliquet, mi velit volutpat lorem, id tincidunt justo purus sit amet odio."
);

const numberOfObjects = 10;

export const siteTestData: ISite[] = new Array(numberOfObjects).fill(null).map((_, i) => {
    const site: ISite = {
        active: true,
        customerId: i,
        id: i,
        title: `Site n° ${i}`,
        contacts: [],
        templates: []
    };

    return site;
})

export const customerTestData: ICustomer[] = new Array(numberOfObjects).fill(null).map((_, i) => {
    const descEnd = getRandomArbitrary(0, loremIpsum.length - 1);
    const descStart = getRandomArbitrary(0, descEnd - 1);
    const client: ICustomer = {
        active: true,
        created: moment().add(i, "hours"),
        modified: moment().add(i, "day"),
        id: i,
        title: `Client n° ${i}`,
        description: loremIpsum.substring(descStart, descEnd),
        site: siteTestData[i],
        logoUrl: `https://i.pravatar.cc/150?img=${i}`,
        bannerUrl: `https://picsum.photos/seed/${i}/200/100`,
        unassigned: false
    };

    return client;
})