import { Button, DatePicker, Modal } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import getFormat from '../../../utils/Lang';
import { PlanningVacations } from '../../../utils/types/planningTypes';
import { cloneVacations, displayErrorMessage } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

//different data type of a listing directory body
enum DataType { Title, Range }

interface Props extends IntlProps {
    vacations: PlanningVacations;
    loading: boolean;
    onCancel: () => void;
    onDone: (vacations: PlanningVacations) => void;
}

interface State {
    vacations: PlanningVacations;
}

/**
 * Modal to create and edit vacations
 */
class CreateEditModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            vacations: cloneVacations(this.props.vacations),
        };
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any): void => {
        const { vacations } = this.state;
        switch (type) {
            case DataType.Title:
                vacations.title = value.target.value;
                break;
            case DataType.Range:
                vacations.startDate = value[0];
                vacations.endDate = value[1];
                break;
        }

        this.setState({ vacations });
    }

    /**
     * Called when the user is done editing/ creating
     */
    onDone = () => {
        const { vacations } = this.state;
        if (!vacations.title) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please provide a title for the vacation.' }), "holidays-modal-ok-button");
        else if (!vacations.startDate && !vacations.endDate) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select a start and end date' }), "holidays-modal-ok-button");
        else this.props.onDone(vacations);
    }

    render() {
        const { vacations } = this.state;
        const { intl } = this.props;
        return (
            <Modal
                className="configurations-modal"
                title={this.props.vacations.title !== "" ? this.props.vacations.title : <FormattedMessage defaultMessage={'Add holidays'} />}
                open={Boolean(this.props.vacations)}
                destroyOnClose={true}
                onCancel={this.props.onCancel}
                footer={[
                    <Button type="dashed" onClick={this.props.onCancel} key="occupancy-rate-modal-button-cancel">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <Button id="holidays-modal-ok-button" type="primary" onClick={this.onDone} loading={this.props.loading} key="occupancy-rate-modal-button-ok">
                        {this.props.vacations.id ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Add'} />}
                    </Button>
                ]}>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Title'} /></p>
                    <InputField
                        className="configurations-section-field"
                        onChange={(v: InputFieldOnChangeEvent) => this.dataChanged(DataType.Title, v)}
                        value={vacations.title}
                        placeholder={intl.formatMessage({ defaultMessage: 'Title' })} />
                </div>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Start and end'} /></p>
                    <DatePicker.RangePicker
                        allowClear={false}
                        className="configurations-section-field"
                        format={getFormat('DATE')}
                        value={[vacations.startDate, vacations.endDate]}
                        onChange={d => this.dataChanged(DataType.Range, d)} />
                </div>
            </Modal>
        )
    }
}

export default injectIntl(CreateEditModal);