import { Button, Result } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../utils/types/generalTypes';
import { AppTheme } from './externalRouteSelector';

interface IProps {
    theme: AppTheme;
}

type Props = IProps & RouterProps;

class ExternalNotFound extends React.Component<Props> {
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Désolé, la page visitée n'existe pas."
                    extra={<Button onClick={() => this.props.history.push(`/${this.props.match.params.lang}/dashboard`)} type="primary">Retour au tableau de bord</Button>}
                />
            </div>
        );
    }
}

export default withRouter(ExternalNotFound);