import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Progress, Row, Spin, Steps } from "antd";
import moment, { Moment } from "moment";
import React from "react";
import { RxCheckCircled } from "react-icons/rx";
import { FormattedMessage, injectIntl } from "react-intl";
import Network from "../../../../utils/network";
import { TimeClockDeviceInit } from "../../../../utils/types/generalTypes";
import { showNotification } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";

interface Props extends IntlProps {
    onClose: (refresh: boolean) => void
    isSmartphone: boolean;
    nrDevices: number;
    maxDevices: number;
}

interface State {
    initLoading: boolean;
    timeClockDeviceInit?: TimeClockDeviceInit;
    generatedAt?: Moment
    currentStep: number;
    activated: boolean;
    cooldownNow: Moment;
    cooldownTimer?: NodeJS.Timeout;
    cooldownStartValue?: number;
    stopCooldown: boolean;
}

class InitNewDeviceModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            initLoading: false,
            timeClockDeviceInit: undefined,
            generatedAt: undefined,
            currentStep: 1,
            activated: false,
            cooldownNow: moment().clone(),
            stopCooldown: false,
        }
    }
    componentWillUnmount(): void {
        this.state.cooldownTimer !== undefined && clearInterval(this.state.cooldownTimer);
    }

    componentDidMount(): void {
        this.initNewTimeClockDevice() // Disable when full modal is released
        if (this.state.cooldownTimer === undefined) this.startCooldown(); // Disable when full modal is released
    }

    initNewTimeClockDevice = (force = false) => {
        // get all timeclocked events
        if (force || !this.state.initLoading) {
            this.setState({ initLoading: true }, () => {
                Network.initTimeClockDevices().then(
                    response => {
                        const timeClockDeviceInit: TimeClockDeviceInit = {
                            pin: response.pin,
                            expiry: response.expiry,
                            max_nr_devices: response.max_nr_devices,
                            nr_devices: response.nr_devices
                        }
                        const cooldownStartValue = moment().clone().diff(timeClockDeviceInit.expiry)
                        this.setState(() => ({ initLoading: false, timeClockDeviceInit, generatedAt: moment(), cooldownStartValue }), this.startCooldown);
                    },
                    () => {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the PIN' }), "warning");
                        this.setState({ initLoading: false });
                    }
                );
            })

        }

    }

    startCooldown() {
        const timer = () => {
            if (!this.state.stopCooldown)
                this.setState({ cooldownNow: moment().clone() });
        }
        this.setState({ cooldownTimer: setInterval(timer, 1000) });
    }

    render() {
        const { onClose, isSmartphone, maxDevices, nrDevices } = this.props
        const { timeClockDeviceInit, currentStep, activated, cooldownNow, cooldownStartValue, initLoading, cooldownTimer, stopCooldown } = this.state
        const pin = timeClockDeviceInit ? Array.from(String(timeClockDeviceInit.pin), Number) : [];

        let cooldownValue;
        if (!stopCooldown && cooldownStartValue)
            cooldownValue = cooldownNow.diff(timeClockDeviceInit?.expiry) * 100 / cooldownStartValue;

        if (cooldownTimer && cooldownValue && cooldownValue <= 0) {
            clearInterval(cooldownTimer);
            this.setState({ stopCooldown: true });
        }

        const modalContent = [
            <Row gutter={[10, 10]} key='timeclock-device-add-content'>
                <Col xs={{ span: 24 }} style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '22px' }}><FormattedMessage defaultMessage={'Download the Sunkhronos Timeclock application'} /></p>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ display: 'flex', fontSize: '18px' }}>
                            <Row>
                                <Col xs={{ span: 24 }} style={{ display: 'flex' }}>
                                    <p style={{ margin: 'auto' }}><FormattedMessage defaultMessage={'Scan the QR-Code to download the Sunkhronos Timeclock application from Apple Store'} /> <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'(on iPad only)'} /></span></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <Image src='https://cdn2.me-qr.com/qr/76126778.png?v=1694089932' preview={false} />
                            </div>
                        </Col>
                    </Row>
                </Col >
            </Row >,
            <Row gutter={[10, 10]} key='timeclock-device-add-content'>
                <Col xs={{ span: 24 }} style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '22px' }}><FormattedMessage defaultMessage={'Pairing the physical timeclock'} /></p>
                </Col>
                {
                    nrDevices < maxDevices ?
                        <Col xs={{ span: 24 }}>
                            <Row gutter={[10, 10]}>
                                <Col xs={{ span: 24 }} md={{ span: 16 }} style={{ display: 'flex', fontSize: '18px' }}>
                                    <Row>
                                        <Col xs={{ span: 24 }}>
                                            <p><FormattedMessage defaultMessage={'On the Sunkhronos Timeclock application installed on your physical device, enter the code shown on the right.'} /></p>
                                            <p style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '20px' }}><FormattedMessage defaultMessage={'The pairing code is valid for 15 minutes'} /></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ textAlign: 'center' }}>
                                    <p style={{ color: '#8F8F8F' }}><FormattedMessage defaultMessage={'Pairing code'} /></p>
                                    <div style={{ margin: 'auto', border: '2px dashed #8F8F8F' }}>
                                        {
                                            stopCooldown ?
                                                <>
                                                    <p style={{ fontSize: '30px', fontWeight: 'bold', color: '#E46868' }}>
                                                        <FormattedMessage defaultMessage={'Code expired'} />
                                                    </p>
                                                    <p style={{ fontSize: '20px' }}>
                                                        <FormattedMessage defaultMessage={'To generate a new code, please repeat the procedure'} />
                                                    </p>
                                                </>
                                                :
                                                pin.length != 0 && cooldownStartValue && cooldownValue && cooldownValue != 0 && !initLoading ?
                                                    <>
                                                        <p style={{ fontSize: '40px', fontWeight: 'bold' }}>{pin}</p>
                                                        <Progress
                                                            style={{ position: 'absolute', bottom: isSmartphone ? 0 : 5, left: '5%', width: '90%' }}
                                                            percent={cooldownValue}
                                                            trailColor="#C4C4C4"
                                                            strokeColor={cooldownValue && cooldownValue > 30 ? '#49A9D8' : cooldownValue && cooldownValue > 15 ? '#e38039' : '#F13C3C'}
                                                            success={{
                                                                percent: undefined,
                                                                strokeColor: '#49A9D8'
                                                            }}
                                                            showInfo={false}
                                                        />
                                                    </>
                                                    :

                                                    <Spin />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <>
                            <Col xs={{ span: 24 }}>
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} style={{ display: 'flex', fontSize: '18px' }}>
                                        <Row>
                                            <Col xs={{ span: 24 }}>
                                                <p style={{ fontSize: '20px', fontWeight: 'bold', color: '#E46868' }}><FormattedMessage defaultMessage={'Maximum number of physical devices reached'} /></p>
                                                <p style={{ marginTop: '10px', fontSize: '20px' }}><FormattedMessage defaultMessage={'To add a new device, delete an existing device or contact an administrator to increase the device limit'} /></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                }
            </Row>,
            <Row gutter={[10, 10]} key='timeclock-device-add-content'>
                <Col xs={{ span: 24 }} style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '22px' }}><FormattedMessage defaultMessage={'Activating the timeclock'} /></p>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Row gutter={[10, 10]} style={{ display: 'flex', marginTop: '20px' }}>
                        <Col xs={{ span: 24 }} style={{ textAlign: 'center', margin: 'auto' }}>
                            {
                                !activated ?
                                    <>
                                        <LoadingOutlined style={{ fontSize: '80px' }} />
                                        <p style={{ fontSize: '24px', marginTop: '10px' }}><FormattedMessage defaultMessage={'Activating in progress'} /></p>
                                    </>
                                    :
                                    <>
                                        <RxCheckCircled style={{ fontSize: '80px', color: '#52e314' }} />
                                        <p style={{ fontSize: '24px', marginTop: '10px' }}><FormattedMessage defaultMessage={'The timeclock has been activated'} /></p>
                                    </>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        ];

        const steps = [
            {
                title: <FormattedMessage defaultMessage={'Download'} />
            },
            {
                title: <FormattedMessage defaultMessage={'Pairing'} />
            },
            // {
            //     title: 'Activation'
            // }
        ];

        return (
            <Modal
                style={{ top: '50px' }}
                open={true}
                width={isSmartphone ? '80%' : '1000px'}
                className={'init-device-modal'}
                title={<FormattedMessage defaultMessage={'Connecting a timeclock'} />}
                onCancel={() => onClose(false)}
                destroyOnClose={true}
                footer={[
                    <>
                        {
                            currentStep == 0 || (currentStep == 1 && stopCooldown) || (currentStep == 1 && nrDevices >= maxDevices) &&
                            <Button
                                key='details-modal-cancel'
                                type='dashed'
                                onClick={() => onClose(false)}>
                                {stopCooldown || nrDevices >= maxDevices ? <FormattedMessage defaultMessage={'Close'} /> : <FormattedMessage defaultMessage={'Cancel'} />}
                            </Button>
                        }
                    </>,
                    <>
                        <Button
                            key='details-modal-cancel'
                            type='primary'
                            disabled={currentStep == 2 && !activated || (currentStep == 1 && nrDevices >= maxDevices)}
                            onClick={() => {
                                // if (currentStep == steps.length - 1) onClose(true);
                                // else {
                                //     if (currentStep == 0 && nrDevices < maxDevices) this.initNewTimeClockDevice();

                                //     if (currentStep == 1 && cooldownTimer) {
                                //         clearInterval(cooldownTimer);
                                //         this.setState({ stopCooldown: true });
                                //         setTimeout(() => this.setState({ activated: true }), 1000);
                                //     }

                                //     const nextStep = currentStep + 1;
                                //     this.setState({ currentStep: nextStep })
                                // }
                                onClose(true);
                            }}>
                            {/* {currentStep == 2 ? 'Terminer' : 'Suivant'} */}
                            <FormattedMessage defaultMessage={'Done'} />
                        </Button>
                    </>
                ]}>
                <Steps
                    current={currentStep}
                    items={steps}
                />
                {
                    modalContent[currentStep]
                }
            </Modal>
        )
    }
}

export default injectIntl(InitNewDeviceModal);