import { PostsState, StoreAction } from '../../utils/types/storeTypes';

export const POSTS_INIT_STATE: PostsState = {
    news: []
};

export const CHANGE_NEWS = ('CHANGE_NEWS');
export const RESET = ('RESET_POSTS');

const posts = (state: PostsState = POSTS_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_NEWS:
            return {
                ...state,
                news: action.data,
            }
        case RESET:
            return POSTS_INIT_STATE;
        default:
            return state;
    }
};

export default posts;