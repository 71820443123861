import * as Sentry from "@sentry/react";
import { createBrowserHistory } from 'history';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppStore from './store/store';

const history = createBrowserHistory();

Sentry.init(
    {
        dsn: "https://b96613ca02f14c19912bf9162e725be4@o293784.ingest.sentry.io/5202270",
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications',
            'instantSearchSDKJSBridgeClearHighlight',
            'instantSearch.clearHighlight'
        ],

        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        environment: process.env.REACT_APP_NODE,


        integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        ],

        tracesSampleRate: 0.05,
        tracePropagationTargets: [/https?:\/\/(preprod(-bonvin)?\.)?sunkhro.ch/],
        allowUrls: [/https?:\/\/(preprod(-bonvin)?\.)?sunkhro.ch/]
    }
);



export const store = AppStore;

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

export default 1;