import { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { CcntErrorCode, CcntErrorType, CcntReport, CcntReportError } from '../../../utils/types/reportTypes';

interface Props {
    errors: CcntReport['errors'];
}

export default class CcntErrorsTooltip extends Component<Props> {
    render() {
        return (
            <>
                {this.props.errors.map((e, i) =>
                    <>
                        <CcntError error={e} key={i} />
                        {i === this.props.errors.length - 1 ? <></> : <hr />}
                    </>
                )}
            </>
        );
    }
}

interface CcntErrorProps {
    error: CcntReportError;
}

class CcntError extends Component<CcntErrorProps> {
    getErrorTypeText = () => {
        switch (this.props.error.type) {
            case CcntErrorType.CCNT_WARNING:
                return <FormattedMessage defaultMessage={'N-CLA Warning'} />;
            case CcntErrorType.ERROR:
                return <FormattedMessage defaultMessage={'Error'} />;
            case CcntErrorType.INFO:
                return <FormattedMessage defaultMessage={'Information'} />;
            case CcntErrorType.WARNING:
                return <FormattedMessage defaultMessage={'Warning'} />;
        }
    };

    getErrorDescription = () => {
        let desc: ReactNode | undefined;

        switch (this.props.error.code) {
            case CcntErrorCode.NEGATIVE_HOURS:
                desc = <FormattedMessage defaultMessage={'An event has a negative duration.'} />;
                break;
            case CcntErrorCode.LONG_DAY:
                desc = <FormattedMessage defaultMessage={'An event lasts more than 14 hours.'} />;
                break;
            case CcntErrorCode.OVER_24_HOURS:
                desc = <FormattedMessage defaultMessage={'An event lasts more than 24 hours.'} />;
                break;
            case CcntErrorCode.INPUT_MIX:
                desc = <>
                    <FormattedMessage defaultMessage={'Incompatible types of day according to N-CLA standards.'} />
                    <br />
                    <FormattedMessage defaultMessage={'If several event types are present on the same day, check that they are of type X, F, M or T.'} />
                </>;
                break;
            case CcntErrorCode.TOO_LONG_WORK_ON_HALF_REST_DAY:
                desc = <FormattedMessage defaultMessage={'A work event lasting more than 5 hours is planned over a half-day off.'} />;
                break;
            case CcntErrorCode.TOO_MANY_DIFFERENT_EVENTS:
                desc = <>
                    <FormattedMessage defaultMessage={'Too many different events on the same day.'} />
                    <br />
                    <FormattedMessage defaultMessage={' - Check that there are no more than two types of day'} />
                    <br />
                    <FormattedMessage defaultMessage={' - Check that there are no more than 2 events if they are not of type X, F or T.'} />
                </>;
                break;
            case CcntErrorCode.TOO_MANY_F:
                desc = <FormattedMessage defaultMessage={'Several rest events (F) on the same day.'} />;
                break;
            case CcntErrorCode.TOO_MANY_M:
                desc = <FormattedMessage defaultMessage={'Several rest events (M) on the same day.'} />;
                break;
            case CcntErrorCode.TOO_MANY_X:
                desc = <FormattedMessage defaultMessage={'Several rest events (X) on the same day.'} />;
                break;
            case CcntErrorCode.TOO_MANY_WORK_DAYS_IN_A_ROW:
                desc = <FormattedMessage defaultMessage={'More than 6 working days without rest days.'} />;
                break;
            case CcntErrorCode.WEEK_OVER_50_HOURS:
                desc = <FormattedMessage defaultMessage={'More than 50 hours planned in a week.'} />;
                break;
            case CcntErrorCode.WRONG_INPUT:
                desc = <>
                    <FormattedMessage defaultMessage={'Invalid type of day. Correct event type.'} />
                    <br />
                    <FormattedMessage defaultMessage={'Valid types: T | X | F | V | Mi | M | A | Ma | D.'} />
                </>
                break;
            case CcntErrorCode.WEEK_WITHOUT_TWO_REST_DAYS:
                desc = <FormattedMessage defaultMessage={'Week without 2 days off.'} />;
                break;
            default:
                desc = <FormattedMessage defaultMessage={'Unknown error, please contact WebEvolutions support'} />;
                break;
        }

        return <>{desc}</>;
    };

    render() {
        return (<>
            <div style={{ fontWeight: 'bold' }}>{this.getErrorTypeText()}</div>
            <div>{this.getErrorDescription()}</div>
        </>);
    }
}