import { UserOutlined } from '@ant-design/icons';
import { Avatar, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { UserVacationsSummary } from '../../utils/types/networkTypes';
import { alert } from '../../utils/utils';
import Card from '../common/general/card';

interface IProps { }

type Props = IProps & RouterProps;

interface State {
    summaries: UserVacationsSummary[] | undefined;
    year: number;
}

/**
 * Component that represent the vacations tab for the report page
 */
class VacationsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            summaries: undefined,
            year: moment().year(),
        };
    }

    componentDidMount() {
        // get all user's vacations summary
        Network.getUserVacationsSummary(this.state.year).then((response) => {
            this.setState({ summaries: response });
        })
            .catch(() => alert("Un problème est survenu pendant le chargement des vacances.", "warning"))
    }

    /**
     * Render days
     * @param days the days to render
     * @returns the string with the rendered days
     */
    renderDays = (days: number) => days && days > 0 ? `${(Math.round(days * 10) / 10)} jour${days > 1 ? 's' : ''}` : ' - ';

    columns: ColumnsType<UserVacationsSummary> = [
        {
            title: 'Nom',
            dataIndex: ['user', 'last_name'],
            key: 'last_name',
            fixed: true,
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.user.last_name.localeCompare(b.user.last_name),
            defaultSortOrder: 'ascend',
            width: 180,
        },
        {
            title: 'Prénom',
            dataIndex: ['user', 'first_name'],
            key: 'first_name',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.user.first_name.localeCompare(b.user.first_name),
            width: 180,
        },
        {
            title: 'Image',
            dataIndex: ['user', 'image'],
            key: 'image',
            render: (link: string) => (
                <div className="both-center">
                    <Avatar size="large" src={link} icon={<UserOutlined />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
            width: 68,
        },
        {
            title: 'Vacances annuels',
            dataIndex: 'yearlyVacationDays',
            key: 'yearlyVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.yearlyVacationDays !== undefined && b.yearlyVacationDays !== undefined ? a.yearlyVacationDays - b.yearlyVacationDays : -1,
            render: this.renderDays,
        },
        {
            title: 'Vacances utilisées',
            dataIndex: 'takenVacationDays',
            key: 'takenVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.takenVacationDays !== undefined && b.takenVacationDays !== undefined ? a.takenVacationDays - b.takenVacationDays : -1,
            render: this.renderDays,
        },
        {
            title: "Vacances restantes",
            dataIndex: 'remainingVacationDays',
            key: 'remainingVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.remainingVacationDays !== undefined && b.remainingVacationDays !== undefined ? a.remainingVacationDays - b.remainingVacationDays : -1,
            render: (days: number) => (
                <p style={{ fontWeight: 'bold' }}>
                    {this.renderDays(days)}
                </p>
            ),
        },
        {
            title: 'Vacances supplémentaires',
            dataIndex: 'extraVacationDays',
            key: 'extraVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.extraVacationDays !== undefined && b.extraVacationDays !== undefined ? a.extraVacationDays - b.extraVacationDays : -1,
            render: this.renderDays,
        },
        {
            title: 'Vacances totales',
            dataIndex: 'totalVacationDays',
            key: 'totalVacationDays',
            sorter: (a: UserVacationsSummary, b: UserVacationsSummary) => a.totalVacationDays !== undefined && b.totalVacationDays !== undefined ? a.totalVacationDays - b.totalVacationDays : -1,
            render: this.renderDays,
        },
    ];

    /**
     * Give the users table's rows some attributes
     * @param record the data source for the row
     */
    onRow = (record: UserVacationsSummary) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/vacations?id=${record.user.id}`),
    })

    render() {
        return (
            <div>
                <Card title={"Utilisateurs"} icon={<UserOutlined />}>
                    <Table
                        dataSource={this.state.summaries}
                        columns={this.columns}
                        loading={this.state.summaries === undefined}
                        pagination={false}
                        rowKey={s => `report-vacations-summary-${s.user.id}`}
                        onRow={this.onRow}
                        scroll={{ x: true, y: 800 }} />
                </Card>
            </div>
        )
    }
}

export default withRouter(VacationsTab);