import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

interface Props {
    icon: IconDefinition;
    selected?: boolean;
    onClick: () => void;
}

class FontAwesomePickerIcon extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className={`__icon-item ${this.props.selected ? '__selected' : ''}`}>
                <FontAwesomeIcon
                    className="__clickable"
                    onClick={() => this.props.onClick()}
                    color={this.props.selected ? 'green' : "#333333"}
                    icon={this.props.icon}
                    size={'lg'}
                />
            </div>
        );
    }
}

export default FontAwesomePickerIcon;