import { EditOutlined, PlusOutlined, ReconciliationOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Col, Empty, List, Modal, Row, Space, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import { cloneDeep } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeOccupancyRates } from '../../../store/actions/configurations';
import { OccupancyRateFrequency } from '../../../utils/enumerations';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { OccupancyRateBodyRequest } from '../../../utils/types/networkTypes';
import { PlanningOccupancyRate } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { alert, convertNetworkOccupancyRatesToPlanningOccupancyRates, convertNetworkOccupancyRateToPlanningOccupancyRate, getOccupancyRateFrequencyText, getOccupancyRateSummaryText, getWeekdayText, isNullOrEmpty, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Card from '../../common/general/card';
import CreateEditModal from '../../configurations/occupancyRate/createEditModal';

interface IProps {
    occupancyRates: PlanningOccupancyRate[];
    isSmartphone: boolean;
}

type Props = IProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    currentOccupancyRate: PlanningOccupancyRate | undefined;
    editOccupancyRate: PlanningOccupancyRate | undefined;
    loading: boolean;
    searchOccupancyRate?: string;
}

/**
 * Component that represent the occupancy rate tab in the configurations page
 */
class OccupancyRate extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            currentOccupancyRate: undefined,
            editOccupancyRate: undefined,
            loading: false,
        };
    }

    componentDidMount() {
        // get occupancy rates
        this.refresh();
    }

    /**
     * Refresh the the occupancy rates
     * @param message a message to display as a success message - optional
     */
    refresh = (message?: string): void => {
        this.setState({ loading: true });
        Network.getOccupancyRates().then(
            response => {
                const occupancyRates = convertNetworkOccupancyRatesToPlanningOccupancyRates(response);
                this.props.changeOccupancyRates!(occupancyRates);
                if (message) alert(message, "success");
                this.setState({ loading: false, editOccupancyRate: undefined, currentOccupancyRate: this.state.currentOccupancyRate ? occupancyRates.find((o: PlanningOccupancyRate) => o.id === this.state.currentOccupancyRate!.id) : occupancyRates[0] });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the requirements' }), "warning");
                this.setState({ loading: false });
            },
        );
    }



    /**
     * Start adding an occupancy rate
     */
    addOccupancyRate = (): void => {
        const editOccupancyRate: PlanningOccupancyRate = {
            title: "",
            typeOfDay: undefined,
            quantity: 1,
            frequency: OccupancyRateFrequency.Daily,
            rrule: {},
        };
        this.setState({ editOccupancyRate });
    }

    /**
     * Start editing an occupancy rate
     * @param occupancyRate the occupancy rate to edit
     */
    editOccupancyRate = (occupancyRate: PlanningOccupancyRate): void => {
        this.setState({ editOccupancyRate: occupancyRate });
    }

    /**
     * Update an occupancy rate
     * @param occupancyRate the occupancy rate body request
     */
    onUpdateOccupancyRate = (occupancyRate: OccupancyRateBodyRequest) => {
        Network.updateOccupancyRate(occupancyRate).then(
            (response) => {
                const newOccupancyRate = convertNetworkOccupancyRateToPlanningOccupancyRate(response);
                let occupancyRates = cloneDeep(this.props.occupancyRates);
                if (occupancyRate.id && occupancyRate.id > 0) {
                    occupancyRates = occupancyRates.map(o => {
                        if (o.id === occupancyRate.id) {
                            return newOccupancyRate;
                        } else {
                            return o;
                        }
                    })
                } else {
                    occupancyRates.push(newOccupancyRate);
                    occupancyRates = occupancyRates.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));
                }
                this.props.changeOccupancyRates!(occupancyRates);
                this.setState({ loading: false, editOccupancyRate: undefined, currentOccupancyRate: this.state.currentOccupancyRate ? occupancyRates.find((o: PlanningOccupancyRate) => o.id === this.state.currentOccupancyRate!.id) : occupancyRates[0] });

            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the requirements' }), "warning"),
        );
    }

    /**
     * Delete an occupancy rate
     * @param occupancyRate the occupancy rate to delete
     */
    onDeleteOccupancyRate = (occupancyRate: PlanningOccupancyRate): void => {
        Network.deleteOccupancyRate(occupancyRate.id!).then(
            () => {
                let occupancyRates = cloneDeep(this.props.occupancyRates);
                occupancyRates = occupancyRates.filter(o => o.id !== occupancyRate.id);
                this.props.changeOccupancyRates!(occupancyRates);
                this.setState({ loading: false, editOccupancyRate: undefined, currentOccupancyRate: this.state.currentOccupancyRate ? occupancyRates.find((o: PlanningOccupancyRate) => o.id === this.state.currentOccupancyRate!.id) : occupancyRates[0] });


            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the requirements' }), "warning"),
        );
    }

    /**
     * Render an occupancy rate (a list item)
     * @param occupancyRate the item to render
     * @returns the component to render
     */
    renderOccupancyRate = (occupancyRate: PlanningOccupancyRate): React.ReactElement => {
        return (
            <List.Item style={{ paddingLeft: '20px', cursor: 'pointer' }} onClick={() => this.setState({ currentOccupancyRate: occupancyRate })} actions={[
                <CircleButton
                    key={`occupancyRateTab-${occupancyRate.id}-modify`}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                    icon={<EditOutlined />}
                    onClick={e => {
                        e.stopPropagation();
                        this.editOccupancyRate(occupancyRate);
                    }} />,
                <DeleteButton
                    key={`occupancyRateTab-${occupancyRate.id}-delete`}
                    text={<FormattedMessage defaultMessage={'Do you want to delete this rule?'} />}
                    onConfirm={e => {
                        e?.stopPropagation();
                        this.onDeleteOccupancyRate(occupancyRate);
                    }}
                    placement="left" />
            ]}>
                <p style={{ fontWeight: this.state.currentOccupancyRate && this.state.currentOccupancyRate.id === occupancyRate.id ? 'bold' : undefined }}>{occupancyRate.title}</p>
            </List.Item>
        );
    }

    renderModalForCurrentOccupancyRate = (title: string | undefined, visible: boolean, content: React.ReactElement): React.ReactElement => {
        return (
            <Modal
                width="98%"
                title={title}
                open={visible}
                destroyOnClose={true}
                onCancel={() => this.setState({ currentOccupancyRate: undefined })}
                footer={[
                    <Button type="dashed" onClick={() => this.setState({ currentOccupancyRate: undefined })} key="majoration-modal-button-cancel">
                        <FormattedMessage defaultMessage={'Close'} />
                    </Button>,
                ]}>
                {content}
            </Modal>

        )
    }

    renderCurrentOccupancyRate = (): React.ReactElement | undefined => {
        const { currentOccupancyRate } = this.state;
        const { intl } = this.props;
        return (
            currentOccupancyRate &&

            <div style={{ padding: '10px 0px 10px 0px' }} className='occupancy-rate-list'>
                <div className="occupancy-rate-section">
                    <p>
                        {getOccupancyRateSummaryText(intl, currentOccupancyRate)}
                    </p>
                </div>
                {
                    currentOccupancyRate.department ?
                        <div className="occupancy-rate-section">
                            <p><FormattedMessage defaultMessage={'Department'} />{":"}</p>
                            <p>{currentOccupancyRate.department ? currentOccupancyRate.department.name : "-"}</p>
                        </div>
                        : null
                }
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Types of day involved'} />{":"}</p>
                    <p>{currentOccupancyRate.typeOfDay ? currentOccupancyRate.typeOfDay.title : "-"}</p>
                </div>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Ability involved'} />{":"}</p>
                    <p>{currentOccupancyRate.staffType ? currentOccupancyRate.staffType.name : "-"}</p>
                </div>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'POI involved'} />{":"}</p>
                    <p>{currentOccupancyRate.poi ? currentOccupancyRate.poi.title : "-"}</p>
                </div>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Quantity'} />{":"}</p>
                    <p>{`${currentOccupancyRate.quantity} événement${currentOccupancyRate.quantity > 1 ? 's' : ''} / ${intl.formatMessage(getOccupancyRateFrequencyText(currentOccupancyRate.frequency)).toLowerCase()}`}</p>
                </div>
                <div className="occupancy-rate-section">
                    <p><FormattedMessage defaultMessage={'Frequency'} />{":"}</p>
                    <p>{intl.formatMessage(getOccupancyRateFrequencyText(currentOccupancyRate.frequency))}</p>
                </div>
                {
                    currentOccupancyRate.startDate && currentOccupancyRate.endDate &&
                    <div className="occupancy-rate-section">
                        <p><FormattedMessage defaultMessage={'Due dates'} />{":"}</p>
                        {
                            this.props.isSmartphone ?
                                <p>{`${currentOccupancyRate.startDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR'))} - ${currentOccupancyRate.endDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR'))}`}</p>
                                :
                                <p><FormattedMessage defaultMessage={'From {start} to {end}'} values={{ start: currentOccupancyRate.startDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')), end: currentOccupancyRate.endDate.format(getFormat('DAY_SHORT_AND_MONTH_AND_YEAR')) }} /></p>
                        }
                    </div>
                }
                {
                    currentOccupancyRate.startHour && currentOccupancyRate.endHour &&
                    <div className="occupancy-rate-section">
                        <p><FormattedMessage defaultMessage={'Start and end time'} />{":"}</p>
                        <p>{`${currentOccupancyRate.startHour.format(getFormat('TIME_SHORT'))} - ${currentOccupancyRate.endHour.format(getFormat('TIME_SHORT'))}`}</p>
                    </div>
                }
                {
                    currentOccupancyRate.rrule && currentOccupancyRate.rrule.byweekday && currentOccupancyRate.rrule.byweekday.length > 0 &&
                    <div className="occupancy-rate-section">
                        <p><FormattedMessage defaultMessage={'Days of week'} />{":"}</p>
                        <p><FormattedMessage defaultMessage={'Every {day}'} values={{ day: currentOccupancyRate.rrule.byweekday.slice(1).reduce((acc, val) => acc + ", " + getWeekdayText(val)?.toLowerCase(), getWeekdayText(currentOccupancyRate.rrule.byweekday[0])?.toLowerCase()) }} /></p>
                    </div>
                }
                {
                    currentOccupancyRate.duringHolidays &&
                    <p className="occupancy-rate-section"><FormattedMessage defaultMessage={'Available on public holidays.'} /></p>
                }
                {
                    currentOccupancyRate.duringVacations &&
                    <p className="occupancy-rate-section"><FormattedMessage defaultMessage={'Available during vacations and non-working days.'} /></p>
                }
            </div>
        );
    }

    onSearchOccupancyRate = (value: string) => {
        this.setState({ searchOccupancyRate: value });
    }

    render() {
        const { currentOccupancyRate, searchOccupancyRate } = this.state;
        const { intl } = this.props;
        const detailContent = this.renderCurrentOccupancyRate();

        let data = this.props.occupancyRates;

        if (!isNullOrEmpty(searchOccupancyRate)) {
            data = data.filter(d => d.title.toLocaleLowerCase().indexOf(searchOccupancyRate.toLocaleLowerCase()) > 0);
        }

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Card title={<FormattedMessage defaultMessage={'Requirements'} />} icon={<UnorderedListOutlined />} className='__min-height-100-percent' headerElements={[
                        <CircleButton
                            style={{ marginRight: '8px' }}
                            key="occupancy-rate-add-occupancy"
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Add a requirement' })}
                            onClick={this.addOccupancyRate} />
                    ]}>
                        {
                            this.state.loading ?
                                <div style={{ textAlign: 'center' }}>
                                    <Spin />
                                </div>
                                : this.props.occupancyRates.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No requirements'} />} />
                                    :
                                    <Space size={"middle"} direction='vertical' style={{ width: '100%' }}>
                                        <Search
                                            value={searchOccupancyRate}
                                            placeholder={intl.formatMessage({ defaultMessage: 'Search by title' })}
                                            allowClear
                                            key={`search-requirement`}
                                            onChange={(event) => this.onSearchOccupancyRate(event.target.value)} />
                                        <List
                                            loading={this.state.loading}
                                            className="occupancy-rate-list"
                                            renderItem={this.renderOccupancyRate}
                                            dataSource={data} />
                                    </Space>
                        }
                    </Card>
                </Col>
                {
                    detailContent !== undefined && this.props.isSmartphone ?
                        this.renderModalForCurrentOccupancyRate(currentOccupancyRate?.title, detailContent !== undefined, detailContent)
                        : detailContent !== undefined ?
                            <Col xs={{ span: 24 }} md={{ span: 14 }}>
                                <Card title={currentOccupancyRate?.title} icon={<ReconciliationOutlined />} className='__min-height-100-percent'>
                                    {detailContent}
                                </Card>
                            </Col>
                            : null

                }
                {this.state.editOccupancyRate !== undefined &&
                    <CreateEditModal
                        occupancyRate={this.state.editOccupancyRate}
                        onCancel={() => this.setState({ editOccupancyRate: undefined })}
                        loading={this.state.loading}
                        onDone={this.onUpdateOccupancyRate}
                    />
                }
            </Row >
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeOccupancyRates: (o: PlanningOccupancyRate[]) => dispatch(changeOccupancyRates(o)),
});

const mapStateToProps = (state: ApplicationState) => ({
    occupancyRates: state.configurations.occupancyRates,
    typesOfDay: state.configurations.typesOfDay,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OccupancyRate));