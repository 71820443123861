import { isEqual } from "lodash";
import { memo, useCallback, useEffect, useRef } from "react";
import { Cell } from "../../../../utils/types/generalTypes";
import ImportCell from "./importCell";

interface Props {
    rowIndex: number;
    gridTemplate: string,
    cells: Cell[]
    ignoreErrors: boolean;
    selectedCellIndex?: number;
    // activeCell: number[];
    isActive: boolean;
    setActiveRow: () => void;
    onMove: (direction: string) => void;
    setActiveCell: (cell: number[]) => void;
    onMultipleCellDelete: () => void;
    onCellChange: (value: string, rowIndex: number, cellIndex: number) => void;
    onCellPaste: (paste: React.ClipboardEvent<HTMLDivElement>, rowIndex: number, cellIndex: number) => void;
    onCellFocus: (cell: number) => void;
    onRowFocus: () => void;
    onMultipleCellCopy: () => void;
    onRowDelete: () => void;
    isMultipleCellsSelected: boolean;
    resetSelection: () => void;
    setEdit: (edit: boolean) => void;
}

const ImportRow = memo((props: Props) => {
    const rowRef = useRef<HTMLDivElement | null>(null)


    const onSelectRow = useCallback(() => {
        props.setActiveRow()
    }, [props])

    const onRowKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (['Delete', 'Backspace'].includes(e.code)) {
            e.preventDefault();
            e.stopPropagation();
            props.onRowDelete()
        }
    }, [props])

    useEffect(() => {
        if (props.isActive)
            rowRef.current?.focus()
    }, [props.isActive])

    return (
        <div
            className={`div-table-row div-table-content`}
            style={{ gridTemplateColumns: props.gridTemplate }} key={`import-table-row-${props.rowIndex}`}
        >
            <div
                className={`div-table-cell line-index${props.isActive ? ' selected' : ''}${props.cells.some(c => (c.error.error || c.error.fromNetwork) && !props.ignoreErrors) ? ' error' : ''}`}
                onClick={onSelectRow}
                onKeyDown={onRowKeyDown}
                tabIndex={100}
                onFocus={props.onRowFocus}
                ref={rowRef}
            >
                {props.rowIndex + 1}
            </div>
            {
                props.cells.map((cell, cellIndex) => {
                    return (
                        <ImportCell
                            ignoreError={props.ignoreErrors}
                            key={`${props.rowIndex}-${cellIndex}-${cell}`}
                            cell={cell}
                            onChange={(e) => {
                                props.onCellChange(e, props.rowIndex, cellIndex)
                            }}
                            onPaste={(e) => props.onCellPaste(e, props.rowIndex, cellIndex)}
                            selected={props.selectedCellIndex === cellIndex}
                            onFocus={() => props.onCellFocus(cellIndex)}
                            error={cell.error}
                            onMove={props.onMove}
                            rowSelected={props.isActive}
                            multipleCellSelected={props.isMultipleCellsSelected}
                            onMultipleCellDelete={props.onMultipleCellDelete}
                            onMultipleCellCopy={props.onMultipleCellCopy}
                            index={cellIndex}
                            rowIndex={props.rowIndex}
                            resetSelection={props.resetSelection}
                            setEdit={props.setEdit}
                        />
                    )
                })
            }
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.isActive === nextProps.isActive
        && prevProps.ignoreErrors === nextProps.ignoreErrors
        && prevProps.selectedCellIndex === nextProps.selectedCellIndex
        && prevProps.cells.every((cell, index) => cell.value === nextProps.cells[index].value)
        && prevProps.cells.every((cell, index) => isEqual(cell.error, nextProps.cells[index].error.error))
    )
})

ImportRow.displayName = 'brah'

export default ImportRow;