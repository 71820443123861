import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Empty, Popconfirm, Radio, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import { BsCalendarCheck, BsCalendarX, BsGear } from 'react-icons/bs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUserAvailabilities, loadUserAvailabilities } from '../../../store/actions/planning';
import { changeUser } from '../../../store/actions/teamManagement';
import { GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { RouterProps, User } from '../../../utils/types/generalTypes';
import { GeneralResponse } from '../../../utils/types/networkTypes';
import { UserAvailability, UserAvailabilityEdit } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Card from '../../common/general/card';
import { AvailabilityState } from './availabilityEditForm';
import AvailabilityEditModal from './availabilityEditModal';

export const enum UserAvailabilityStatus {
    CONFIRMED = "isConfirmed",
    REFUSED = "isRefused",
    WAITING = "waiting",
}

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, RouterProps, IntlProps {

}

interface State {
    editAvailability?: UserAvailabilityEdit;
    year: Moment;
}

/**
 * Component that represent the user/group's periods page
 */
class UserAvailabilityTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            year: moment()
        };
    }

    componentDidMount() {
        if (this.props.user === undefined) {
            this.props.history.push(`/${this.props.match.params.lang}/team-management/list`);
        }
        this.getAvailabilities();
    }

    componentDidUpdate() {
        if (this.props.user === undefined) {
            this.props.history.push(`/${this.props.match.params.lang}/team-management/list`);
        }
    }


    columns = (): ColumnsType<UserAvailability> => [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            key: 'title',
            dataIndex: 'title',
            className: '__min-width-200',
        },
        {
            title: <FormattedMessage defaultMessage={'Start date'} />,
            key: 'startDateRule',
            dataIndex: 'startDateRule',
            className: '__width_180 __centered-text',
            render: (value: Moment) => {
                const valutStr = value.format(getFormat('DATE'));
                return <span>{valutStr}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'End date'} />,
            key: 'endDateRule',
            dataIndex: 'endDateRule',
            className: '__width_180 __centered-text',
            render: (value: Moment) => {
                const valutStr = value.format(getFormat('DATE'));
                return <span>{valutStr}</span>;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Start hour'} />,
            key: 'startTime',
            dataIndex: 'startTime',
            className: '__width_150 __centered-text',
            render: (value: Moment) => value.format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'End hour'} />,
            key: 'endTime',
            dataIndex: 'endTime',
            className: '__width_150 __centered-text',
            render: (value: Moment) => value.format(getFormat('TIME_SHORT'))
        },
        {
            title: <FormattedMessage defaultMessage={'Type'} />,
            dataIndex: 'isContinue',
            key: 'type',
            className: '__width_150 __centered-text',
            render: (isContinue: boolean) => isContinue ? "Continue" : "Récurrente",
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Continue'} />,
                    value: true,
                },
                {
                    text: <FormattedMessage defaultMessage={'Recurring'} />,
                    value: false,
                }
            ],
            filterMultiple: false,
            onFilter: (isContinue, record) => record.isContinue === isContinue,
        },
        {
            title: <FormattedMessage defaultMessage={'Creator'} />,
            key: 'isUserCreated',
            dataIndex: 'isUserCreated',
            className: '__width_150 __centered-text',
            render: (isUserCreated: boolean) => {
                if (isUserCreated) {
                    return <span>Utilisateur</span>;
                } else {
                    return <span>Administrateur</span>;
                }
            },
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'User'} />,
                    value: true
                },
                {
                    text: <FormattedMessage defaultMessage={'Administrator'} />,
                    value: false,
                }
            ],
            filterMultiple: false,
            onFilter: (isUserCreated, record) => record.isUserCreated === isUserCreated,
        },
        {
            title: <FormattedMessage defaultMessage={'Status'} />,
            key: 'status',
            className: '__width_100 __centered-text',
            render: (_, record) => {
                if (record.isConfirmed) {
                    return <CheckCircleOutlined title={this.props.intl.formatMessage({ defaultMessage: 'Confirmed' })} style={{ color: GREEN_COLOR }} />;
                } else if (record.isRefused) {
                    return <CloseCircleOutlined title={this.props.intl.formatMessage({ defaultMessage: 'Rejected' })} style={{ color: RED_COLOR }} />;
                } else {
                    return <QuestionCircleOutlined title={this.props.intl.formatMessage({ defaultMessage: 'Pending' })} style={{ color: YELLOW_COLOR }} />;
                }
            },
            filters: [
                {
                    text: <FormattedMessage defaultMessage={'Confirmed'} />,
                    value: UserAvailabilityStatus.CONFIRMED,
                },
                {
                    text: <FormattedMessage defaultMessage={'Rejected'} />,
                    value: UserAvailabilityStatus.REFUSED,
                },
                {
                    text: <FormattedMessage defaultMessage={'Pending'} />,
                    value: UserAvailabilityStatus.WAITING,
                }
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                switch (value) {
                    case UserAvailabilityStatus.CONFIRMED:
                        return record.isConfirmed;
                    case UserAvailabilityStatus.REFUSED:
                        return record.isRefused;
                    default:
                        return record.isConfirmed === false && record.isRefused === false;
                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            fixed: 'right',
            className: '__width_120 __centered-text',
            render: (_, record) => {
                return (
                    <Space>
                        <DeleteButton
                            text={<FormattedMessage defaultMessage={'Do you want to delete this availability?'} />}
                            key="template-modal-delete-color"
                            onConfirm={() => this.deleteAvailabilities([record.id])}
                            placement="topRight"
                            buttonPlacement="right"
                        />
                        <CircleButton
                            icon={<EditOutlined />}
                            title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                            placement="left"
                            onClick={() => this.startEditPeriod(cloneDeep(record))} />
                    </Space>
                );
            },
        }
    ];

    getAvailabilities = () => {
        if (this.props.user) {
            this.props.loadUserAvailabilities(this.props.user.id, this.state.year, true);
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading your account informations' }), "error");
        }
    };

    changeYear = (year: null | Moment) => {
        if (year) this.setState({ year: year }, () => this.getAvailabilities());
    };

    startEditPeriod = (availability?: UserAvailabilityEdit) => {
        if (this.props.user && availability === undefined) {
            availability = {
                user: {
                    id: this.props.user.id,
                    firstName: this.props.user.first_name,
                    lastName: this.props.user.last_name,
                    availabilityClosedByDefault: false, //TODO
                },
                rrule: undefined,
                isContinue: true
            };
        }
        this.setState({ editAvailability: cloneDeep(availability) });
    };

    deleteAvailabilities = (availabilities: number[]) => {
        Network.deleteUserAvailability(availabilities).then(
            (response: GeneralResponse) => {
                const availabilities = this.props.teamAvailabilities.find(ta => ta.userId === this.props.user?.id);
                const successIds = response.successIds;
                if (successIds !== undefined && successIds.length > 0) {
                    if (this.props.user)
                        this.props.changeUserAvailabilities(this.props.user.id, availabilities ? availabilities.data.filter(d => !successIds.includes(d.id)) : []);
                }
                if (response.error) {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deletion, please reload the page' }), "error");
                } else {
                    const isPlural = response.successIds !== undefined && response.successIds.length > 1;
                    showNotification(this.props.intl.formatMessage({ defaultMessage: '{count, plural, one {The availability has been successfully deleted} other {The avaialabilities have been successfully deleted}}' }, { count: isPlural }), "success");
                }
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deletion, please reload the page' }), "error");
            }
        )
    }

    onChangeAvailabilityClosedByDefault = (checked: boolean) => {
        // Network.activateReports()
        const user = cloneDeep(this.props.user);
        if (user) {
            Network.editUserAvailabilityDefault({ id: user.id, availabilityClosedByDefault: checked }).then(
                (response: GeneralResponse) => {
                    if (response.error) {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the default value' }), "error")
                    } else {
                        user.availabilityClosedByDefault = response.data;
                        this.props.changeUser(user);
                    }
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the default value' }), "error")
                }
            )
        }
    }

    render() {
        const { year, editAvailability } = this.state;
        const { teamAvailabilities, loadingAvailabilities } = this.props;
        const availabilityClosedByDefault = this.props.user?.availabilityClosedByDefault;
        const availabilities = teamAvailabilities.find(ta => ta.userId === this.props.user?.id);

        let title = <FormattedMessage defaultMessage={'Unavailabilities'} />;
        let icon = <BsCalendarX />;
        if (availabilityClosedByDefault) {
            title = <FormattedMessage defaultMessage={'Availabilities'} />;
            icon = <BsCalendarCheck />;
        }

        let tableHeight = this.props.height - 306;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} >
                    <Card
                        title={<FormattedMessage defaultMessage={'General'} />}
                        icon={<span className='anticon'><BsGear /></span>}
                    >
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
                            <p><FormattedMessage defaultMessage={'Default planning status'} />{':'}</p>
                            <Radio.Group buttonStyle='solid' value={availabilityClosedByDefault}>
                                <Popconfirm
                                    icon={<QuestionCircleOutlined style={{ color: RED_COLOR }} />}
                                    title={<b><FormattedMessage defaultMessage={'This change will convert all configured availabilities into unavailabilities. Are you sure you want to continue?'} /></b>}
                                    onConfirm={() => this.onChangeAvailabilityClosedByDefault(false)}
                                    okText={<FormattedMessage defaultMessage={'Yes'} />}
                                    cancelText={<FormattedMessage defaultMessage={'No'} />}
                                >
                                    <Radio.Button
                                        value={false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    ><FormattedMessage defaultMessage={'Opened'} /></Radio.Button>
                                </Popconfirm>
                                <Popconfirm
                                    icon={<QuestionCircleOutlined style={{ color: RED_COLOR }} />}
                                    title={<b><FormattedMessage defaultMessage={'This change will convert all configured unvailabilities into availabilities. Are you sure you want to continue?'} /></b>}
                                    onConfirm={() => this.onChangeAvailabilityClosedByDefault(true)}
                                    okText={<FormattedMessage defaultMessage={'Yes'} />}
                                    cancelText={<FormattedMessage defaultMessage={'No'} />}
                                >
                                    <Radio.Button
                                        value={true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    ><FormattedMessage defaultMessage={'Closed'} /></Radio.Button>
                                </Popconfirm>
                            </Radio.Group>
                        </div>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} >
                    <Card
                        title={title}
                        icon={icon}
                        headerElements={[
                            <Space key="availability-card-header-elements">
                                <DatePicker.YearPicker
                                    disabled={loadingAvailabilities}
                                    className='__datepicker-year'
                                    value={year}
                                    onChange={this.changeYear}
                                    allowClear={false} />
                                <CircleButton
                                    disabled={editAvailability !== undefined || loadingAvailabilities}
                                    small
                                    key="team-availability-add-button"
                                    icon={<PlusOutlined />}
                                    title={availabilityClosedByDefault ? this.props.intl.formatMessage({ defaultMessage: 'Add an availability' }) : this.props.intl.formatMessage({ defaultMessage: 'Add an unavailability' })}
                                    onClick={() => this.startEditPeriod()} />
                            </Space>
                        ]}>
                        {
                            availabilities === undefined || availabilities.data.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={availabilityClosedByDefault ? <FormattedMessage defaultMessage={'No availability'} /> : <FormattedMessage defaultMessage={'No unavailability'} />} />
                                :
                                <Table
                                    columns={this.columns()}
                                    loading={loadingAvailabilities}
                                    dataSource={availabilities.data}
                                    rowKey={s => `report-hours-summary-${s.id}`}
                                    scroll={{ x: true, y: tableHeight }} />
                        }
                    </Card>
                </Col>
                {/* {
                    periods && periods.length > 0 &&
                    <Col xs={{ span: 24 }} >
                        <Card title="Détails" icon={<ControlOutlined />}>
                            <Calendar
                                dateCellRender={this.dateCellRender}
                                monthCellRender={this.monthCellRender} />
                        </Card>
                    </Col>
                } */}
                {
                    <AvailabilityEditModal
                        availabilityState={availabilityClosedByDefault ? AvailabilityState.Close : AvailabilityState.Open}
                        availability={editAvailability}
                        onCancel={() => this.setState({ editAvailability: undefined })}
                        onOk={() => this.setState({ editAvailability: undefined })}
                        loading={loadingAvailabilities} />
                }
            </Row>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
    loadUserAvailabilities: (a: number, b: Moment, c?: boolean) => dispatch(loadUserAvailabilities(a, b, c)),
    changeUserAvailabilities: (a: number, b: UserAvailability[]) => dispatch(changeUserAvailabilities(a, b)),
});

const mapStateToProps = (state: ApplicationState) => ({
    height: state.window.height,
    teamAvailabilities: state.planning.teamAvailabilities,
    loadingAvailabilities: state.planning.loadingAvailabilities,
    user: state.teamManagement.user,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(injectIntl(UserAvailabilityTab)));