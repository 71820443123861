

import { Spin } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import Network from '../../../utils/network';
import { GeneralResponse } from '../../../utils/types/networkTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';

interface IProps {
    children: React.ReactNode;
    minutes: number;
    update: (key?: string) => void;
    apikey?: string;
}

type Props = IProps & IntlProps;

interface State {
    loading: boolean;
}

class GoogleMapsKey extends React.Component<Props, State> {
    intervalId: NodeJS.Timeout | null = null;

    static defaultProps = {
        minutes: 15,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        const { minutes } = this.props;
        const intervalMillis = minutes * 60 * 1000;

        this.intervalId = setInterval(this.getGoogleMapsKey, intervalMillis);
        this.getGoogleMapsKey();
    }

    getGoogleMapsKey = () => {
        const { apikey, intl } = this.props;
        Network.getGoogleMapsKey().then(
            (response: GeneralResponse) => {
                const newApiKey = response.data;
                if (apikey !== newApiKey) {
                    this.props.update(newApiKey);

                }
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading Google Maps' }), "error");
                if (apikey !== undefined) {
                    this.props.update();
                }
            }
        ).finally(() => this.setState({ loading: false }));
    }

    componentWillUnmount() {
        this.intervalId && clearInterval(this.intervalId);
    }

    render() {
        const { children } = this.props;
        const { loading } = this.state;
        return (
            <>
                {
                    loading ?
                        <Spin style={{ marginTop: '20px' }} />
                        : children
                }
            </>
        );
    }
}

export default injectIntl(GoogleMapsKey);