import { CloudSyncOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { type ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import { GoPencil } from 'react-icons/go';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisableForGlobalOffice } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { ICustomer, ISite, ISiteState } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { isNullOrEmpty } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import Anticon from '../../common/general/anticon';
import VirtualTable from '../../common/general/virtualTable';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
    customer: ICustomer;
}

interface State {
    sites: ISiteState;
    filteredSites?: ISite[];
    pageSize: number;
    currentPage: number;
    searchValue: string;
    columns: ColumnsType<ISite>;
}

export class Sites extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentPage: 1,
            pageSize: 12,
            searchValue: '',
            sites: { loading: true },
            columns: this.getInitialColumns(),
        };
    }

    componentDidMount(): void {
        this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
        this.props.addOrUpdateFilters(this.getFilters(), this.props.keyLink);

        this.sitesFetched();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (!isEqual(this.state.sites, prevState.sites)) this.props.addOrUpdateExtra(this.getExtra(), this.props.keyLink);
    }

    getInitialColumns = () => {
        const { intl } = this.props;
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;
        return [
            {
                title: <FormattedMessage defaultMessage={'Title'} />,
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                className: '__min-width-200',
            },
            {
                title: <FormattedMessage defaultMessage={'Email'} />,
                key: 'email',
                dataIndex: 'email',
                className: '__width_300',
            },
            {
                title: <FormattedMessage defaultMessage={'Mobile'} />,
                key: 'phone',
                dataIndex: 'phone',
                className: '__width_180',
            },
            {
                title: <FormattedMessage defaultMessage={'Address'} />,
                key: 'adress',
                className: '__width_350',
                render: (_, record) => {
                    return `${record.adress}, ${record.zip} ${record.city}`;
                }
            },
            {
                title: <FormattedMessage defaultMessage={'Language'} />,
                key: 'language',
                dataIndex: 'language',
                className: '__width_180 __centered-text',
            },
            {
                title: <FormattedMessage defaultMessage={'Country'} />,
                key: 'country',
                dataIndex: 'country',
                className: '__width_100 __centered-text',
            },
            {
                title: <FormattedMessage defaultMessage={'Actions'} />,
                key: 'actions',
                fixed: 'right',
                className: '__width_120 __centered-text',
                render: () => {
                    return (
                        <Space>
                            <DeleteButton
                                text={<FormattedMessage defaultMessage={'Do you want to delete this branch?'} />}
                                key="template-modal-delete-color"
                                onConfirm={() => console.log("DELETE")}
                                placement="topRight"
                                buttonPlacement="right"
                                title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : undefined}
                                disabled={isGlobalOfficeEnabled}
                            />
                            <CircleButton
                                icon={<Anticon><GoPencil /></Anticon>}
                                title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Edit' })}
                                disabled={isGlobalOfficeEnabled}
                                placement="left"
                                onClick={() => console.log("EDIT")} />
                        </Space>
                    );
                },
            }
        ] as ColumnsType<ISite>;
    }

    getExtra = () => {
        const { sites } = this.state;
        const { intl } = this.props;
        const isGlobalOfficeEnabled = this.props.company && this.props.company.isGlobalOfficeEnabled;
        return (
            <>
                <Tooltip placement='left' open={sites.updatedAt ? undefined : false} title={<p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}><FormattedMessage defaultMessage={'Updated on {date}'} values={{ date: moment(sites.updatedAt).format(getFormat('DATE_AND_ON_TIME')) }} /></p>}>
                    <CircleButton
                        small
                        withoutTooltip
                        title={intl.formatMessage({ defaultMessage: 'Force update' })}
                        icon={<CloudSyncOutlined />}
                        onClick={() => this.sitesFetched()}
                        loading={sites.loading} />
                </Tooltip>
                <CircleButton
                    small
                    key="site-add"
                    title={isGlobalOfficeEnabled ? intl.formatMessage(DisableForGlobalOffice) : intl.formatMessage({ defaultMessage: 'Add a branch' })}
                    disabled={isGlobalOfficeEnabled}
                    icon={<PlusOutlined />}
                    withoutTooltip />
            </>
        )
    }

    getFilters = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Search
                    value={this.state.searchValue}
                    placeholder={this.props.intl.formatMessage({ defaultMessage: 'Search for a branch' })}
                    allowClear
                    key={`search-site`}
                    onChange={(event) => this.searchCustomers(event.target.value)} />
            </div>
        )
    }

    sitesFetched = () => {
        const { customer } = this.props;
        this.setState(prevState => ({ sites: { ...prevState, loading: true } }));
        Network.getCustomerSites(customer.id).then(
            (response) => {
                this.setState({ sites: { data: response.data, loading: false, updatedAt: moment().toISOString() } });
            },
            () => {
                this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
            }
        );
    }

    searchCustomers = (value: string) => {
        const search = value.toLocaleLowerCase()
        this.setState({ searchValue: search }, () => this.filterCustomers());
    }

    filterCustomers = () => {
        const { searchValue } = this.state;
        let filteredSites = cloneDeep(this.state.sites.data);

        if (!isNullOrEmpty(searchValue)) {
            filteredSites = (filteredSites ?? this.state.sites.data)?.filter(site => `${site.title}`.toLocaleLowerCase().indexOf(searchValue) >= 0);
        }

        this.setState({ filteredSites: filteredSites ?? [] })
    }

    goToCustomer = (customerId: number) => this.props.history.push(`/${this.props.match.params.lang}/crm/sites/${customerId}`);

    render() {
        const { filteredSites, currentPage, pageSize, sites, columns } = this.state;
        const sitesData = filteredSites ?? sites.data;

        let firstCustomerPagination = (currentPage - 1) * pageSize;
        let lastCustomerPagination = currentPage * pageSize;
        if (sitesData && firstCustomerPagination > sitesData?.length) firstCustomerPagination = 0;
        if (sitesData && lastCustomerPagination > sitesData?.length) lastCustomerPagination = pageSize;

        let tableHeight = this.props.height - 168;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <VirtualTable
                loading={sites.loading}
                rowKey={(site: ISite) => `site-${site.id}`}
                dataSource={sitesData}
                scroll={{ x: true, y: tableHeight }}
                columns={columns}
                pagination={false}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    displayFilters: state.window.displayFilters,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
    height: state.window.height
});
const connector = connect(mapStateToProps)

export default withRouter(connector(injectIntl(Sites)));