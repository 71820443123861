import { CalendarOutlined, HomeOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Col, DatePicker, Empty, Pagination, Popover, Row, Select, Spin } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeGroups, changeUsers } from "../../../store/actions/teamManagement";
import { CaseType, MOMENT_LONG_DATE_FORMAT } from "../../../utils/constants";
import getFormat from "../../../utils/Lang";
import Network from "../../../utils/network";
import { StatsOnlyPlanning } from "../../../utils/types/generalTypes";
import { ExternalAppOptimizedEvent } from "../../../utils/types/planningTypes";
import { ApplicationState } from "../../../utils/types/storeTypes";
import { getCaseAndPlural, showNotification } from "../../../utils/utils";
import CircleButton from "../../common/fields/circleButton";
import InputField, { InputFieldOnChangeEvent } from "../../common/fields/inputField";
import Card from "../../common/general/card";
import Event, { EventSource } from '../../common/general/event';


export const PlanningV2 = () => {
    const [planning, setPlanning] = useState<StatsOnlyPlanning | undefined>(undefined)
    const [todayEvents, setTodayEvents] = useState<ExternalAppOptimizedEvent[]>([])
    const [day, setDay] = useState<Moment>(moment())
    const previousSelectedDay = useRef<Moment>(moment())
    const [sortBy, setSortBy] = useState<'eventTitle' | 'userName' | 'eventStartDate' | 'eventEndDate'>('eventStartDate')
    const [selectedGroups, setSelectedGroups] = useState<number[]>([])
    const [search, setSearch] = useState<string>()
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [planningLoading, setPlanningLoading] = useState<boolean>(false)
    const planningLoaded = useRef<boolean>(false)
    const usersLoading = useRef<boolean>(false)
    const usersLoaded = useRef<boolean>(false)
    const groupsLoading = useRef<boolean>(false)
    const groupsLoaded = useRef<boolean>(false)

    const isSmartphone = useSelector((state: ApplicationState) => state.window.isSmartphone)
    const groups = useSelector((state: ApplicationState) => state.teamManagement.groups)
    const currentUser = useSelector((state: ApplicationState) => state.user.currentUser)
    const company = useSelector((state: ApplicationState) => state.user.company)

    const dispatch = useDispatch()

    const intl = useIntl();

    useEffect(() => {
        if (!usersLoading.current && !usersLoaded.current) {
            usersLoading.current = true
            Network.getAllUsers().then(
                response => {
                    usersLoaded.current = true
                    usersLoading.current = false
                    dispatch(changeUsers(response))
                },
                () => {
                    usersLoading.current = false
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning")
                }
            )
        }

    }, [dispatch, intl])

    useEffect(() => {
        if (groups === undefined) {
            if (!groupsLoading.current && !groupsLoaded.current) {
                groupsLoading.current = true
                Network.getGroups().then(
                    response => {
                        groupsLoading.current = false
                        groupsLoaded.current = true
                        dispatch(changeGroups(response))
                    },
                    () => {
                        groupsLoading.current = false
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning")
                    }
                )
            }
        } else {
            groupsLoaded.current = true
        }
    }, [dispatch, groups, intl])

    const generateTodayEvents = useCallback(() => {
        if (planning) {
            let todayEvents = cloneDeep(planning.todayEvents);
            if (sortBy === 'eventTitle') {
                todayEvents = todayEvents?.sort((a, b) => `${a.title}${a.userName}`.localeCompare(`${b.title}${b.userName}`));

            } else if (sortBy === 'userName') {
                todayEvents = todayEvents?.sort((a, b) => `${a.userName}${a.title}`.localeCompare(`${b.userName}${b.title}`));
            } else if (sortBy == "eventStartDate") {
                todayEvents = todayEvents?.sort((a, b) => (moment(a.startDate).unix() < moment(b.startDate).unix()) ? -1 : 1);
            } else if (sortBy === 'eventEndDate') {
                todayEvents = todayEvents?.sort((a, b) => (moment(a.endDate).unix() < moment(b.endDate).unix()) ? -1 : 1);
            }

            if (search) {
                todayEvents = todayEvents?.filter(e => e.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || e.userName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            }
            if (selectedGroups !== undefined && selectedGroups.length > 0) {
                todayEvents = todayEvents?.filter(e => e.userGroups.find(uG => uG.id !== undefined && selectedGroups.includes(uG.id)) !== undefined ? true : false)
            }
            setTodayEvents(todayEvents)
        }

    }, [planning, sortBy, selectedGroups, search])

    const loadStatistics = useCallback(async (force = false) => {
        if (force || (!planningLoading && !planningLoaded.current)) {
            setPlanningLoading(true)
            previousSelectedDay.current = day
            if (currentUser && currentUser.company_id !== undefined) {
                await Network.getPlanningStatistics(currentUser.company_id, day.format("YYYY/MM/DD")).then(
                    response => {
                        planningLoaded.current = true
                        setPlanning(response.planning)
                        setPlanningLoading(false)
                    },
                    () => {
                        setPlanningLoading(false)
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the contracts' }), "warning")
                    }
                )
            }
        }
    }, [planningLoading, day, currentUser, intl])

    useEffect(() => {
        if (planning !== undefined && sortBy && (search || selectedGroups)) {
            generateTodayEvents()
        }
    }, [planning, sortBy, selectedGroups, search, generateTodayEvents])



    useEffect(() => {
        if (planningLoading === false && planningLoaded.current === false)
            loadStatistics()
    }, [loadStatistics, planningLoading])



    const getUserText = useCallback((event: ExternalAppOptimizedEvent): string => {
        return `${event.userName}`;
    }, [])

    const previousDay = useCallback(() => {
        const newDay = day.clone().add(-1, "day");
        setDay(newDay)
    }, [day])

    const nextDay = useCallback(() => {
        const newDay = day.clone().add(1, "day");
        setDay(newDay)
    }, [day])

    const onChangeMonth = useCallback((date: Moment | null) => {
        if (date) {
            setDay(date)
        }
    }, [])

    const handleSearch = useCallback((event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        search.length === 0 ? setSearch(undefined) : setSearch(search)
    }, [])

    const onChangeSort = useCallback((value?: 'eventTitle' | 'userName' | 'eventStartDate' | 'eventEndDate') => {
        setSortBy(value ?? 'eventTitle')
    }, [])

    useEffect(() => {
        if (day !== previousSelectedDay.current) loadStatistics(true)
    }, [day, loadStatistics])

    const CustomDatePicker = useCallback((cprops: { className?: string }) => {
        return (
            <div key={`custom-date-picker-div-${day.format('YYYYMMDD')}`} className={cprops.className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
                <DatePicker
                    className={`__weekly-planning-month-picker ${cprops.className}-picker`}
                    key={`week-picker-${day.format("YY")}-${day.week()}`}
                    //picker='week'
                    suffixIcon={null}
                    placement={'bottomLeft'}
                    size='large'
                    bordered={false}
                    //format={customWeekStartEndFormat}
                    allowClear={false}
                    inputReadOnly
                    style={{
                        textAlign: 'center'
                    }}
                    disabled={planningLoading}
                    value={day}
                    format={getFormat('DAY_SHORT_AND_MONTH_HALF_AND_YEAR')}
                    onChange={onChangeMonth}
                />


            </div>


        )
    }, [day, planningLoading, isSmartphone, onChangeMonth])


    //if (users?.length === 0 || (planning && planning.nbEvents === 0)) return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Spin spinning /></div>;

    return (
        <Card
            style={{
                boxShadow: 'none',
                height: 'inherit'
            }}
            className="planning-statistics-card"
            title={<FormattedMessage defaultMessage={'Daily planning'} />}
            icon={<CalendarOutlined />}
            headerElements={!isSmartphone ? [
                <div key={`datepicker-dashboard-planning-component`} style={{ display: 'flex', justifyContent: 'center', gap: '5px', zIndex: 3, paddingTop: '10px', paddingBottom: '10px', marginTop: '-10px', position: 'sticky', top: '0px', left: 0, width: '100%' }}>
                    <CircleButton
                        small
                        icon={<LeftOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Previous days' })}
                        disabled={planningLoading}
                        onClick={previousDay}
                    />
                    <CustomDatePicker
                        className='dashboard-datepicker-input-container'
                    />
                    <CircleButton
                        small
                        icon={<RightOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Next days' })}
                        disabled={planningLoading}
                        onClick={nextDay}
                    />
                    <CircleButton
                        small
                        icon={<HomeOutlined />}
                        title={intl.formatMessage({ defaultMessage: 'Today' })}
                        onClick={() => onChangeMonth(moment())}
                        loading={planningLoading}
                        disabled={day.format('YYYYMMDD') === moment().format('YYYYMMDD')}
                    />
                </div>
            ] : []}
        >

            <Row gutter={[10, 10]}>
                {isSmartphone && <Col span={24}>
                    <div key={`datepicker-dashboard-planning-component`} style={{ display: 'flex', justifyContent: 'center', gap: '5px', zIndex: 3, paddingTop: '10px', paddingBottom: '10px', marginTop: '-10px', position: 'sticky', top: '0px', left: 0, width: '100%' }}>
                        <CircleButton
                            small
                            icon={<LeftOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Previous days' })}
                            disabled={planningLoading}
                            onClick={previousDay}
                        />
                        <CustomDatePicker
                            className='dashboard-datepicker-input-container'
                        />
                        <CircleButton
                            small
                            icon={<RightOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Next days' })}
                            disabled={planningLoading}
                            onClick={nextDay}
                        />
                        <CircleButton
                            small
                            icon={<HomeOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Today' })}
                            onClick={() => onChangeMonth(moment())}
                            loading={planningLoading}
                            disabled={day.format('YYYYMMDD') === moment().format('YYYYMMDD')}
                        />
                    </div>
                </Col>}
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <InputField
                        type="search"
                        onChange={handleSearch}
                        style={{ width: '100%' }} />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Select
                        defaultValue={'eventTitle'}
                        placeholder={<FormattedMessage defaultMessage={'Sort by'} />}
                        onChange={onChangeSort}
                        style={{ width: '100%' }}
                        showArrow
                    >
                        <Select.Option value={'eventTitle'}><FormattedMessage defaultMessage={'Sort by events'} /></Select.Option>
                        <Select.Option value={'userName'}><FormattedMessage defaultMessage={'Sort by users'} /></Select.Option>
                        <Select.Option value={'eventStartDate'}><FormattedMessage defaultMessage={'Sort by start time'} /></Select.Option>
                        <Select.Option value={'eventEndDate'}><FormattedMessage defaultMessage={'Sort by end time'} /></Select.Option>
                    </Select>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Select
                        disabled={selectedGroups.length === 0}
                        loading={planningLoading}
                        mode={'multiple'}
                        maxTagCount={'responsive'}
                        value={selectedGroups}
                        placeholder={<FormattedMessage defaultMessage={'User with group'} />}
                        onChange={setSelectedGroups}
                        style={{ width: '100%' }}
                        showArrow
                        options={planning?.groups.map(g => ({ value: g.id, label: g.name }))}
                    />
                </Col>
            </Row>

            {
                planningLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spin size='large' style={{ marginTop: '15px' }} indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />} />
                    </div>
                    :
                    planning && planning.todayEvents.length > 0 ?
                        <div style={{ marginTop: 20 }} >
                            <Row gutter={[10, 10]} style={{ width: '100%' }} key={`dashboard-today-events`} >

                                {todayEvents.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(e => {
                                    const duration = moment.duration(moment(e.endDate).diff(e.startDate));

                                    return (
                                        <>
                                            <Col span={2} style={{ height: 40, minWidth: 20, fontSize: 40, display: 'flex', alignItems: 'center', color: e.color?.color ?? 'var(--primary-color)' }}>•</Col>
                                            <Popover
                                                placement='bottomLeft'
                                                trigger={isSmartphone ? 'click' : 'hover'}
                                                title={e.userGroups.length > 0 ? <FormattedMessage defaultMessage={'User is in groups'} /> : <FormattedMessage defaultMessage={'User is not in groups'} />}
                                                content={
                                                    e.userGroups.length > 0
                                                        ?
                                                        <ul key={`group-list-container-items-event-${e.id}-user-${e.userId}`}>
                                                            {e.userGroups.map(uG => <li key={`group-list-item-${uG.id}-event-${e.id}-user-${e.userId}`}>{uG.name}</li>)}
                                                        </ul>
                                                        :
                                                        false
                                                }
                                            >
                                                <Col span={10} style={{ display: 'flex', alignItems: 'center', height: 40 }}><span style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getUserText(e)}</span></Col>
                                            </Popover>
                                            <Popover
                                                placement="bottomRight"
                                                trigger={isSmartphone ? 'click' : 'hover'}
                                                //arrowPointAtCenter={true}
                                                key={`statistics-event-popover-${e.id}`}
                                                title={
                                                    <div style={{ lineHeight: '25px' }}>
                                                        <p><span style={{ fontWeight: 600 }}>{e.title}</span></p>
                                                    </div>}
                                                content={
                                                    <Row gutter={[10, 0]} style={{ width: 350 }}>
                                                        <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'From'} />{':'}</span></Col>
                                                        <Col span={18}>{moment(e.startDate).format(MOMENT_LONG_DATE_FORMAT)}</Col>
                                                        <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'To'} />{':'}</span></Col>
                                                        <Col span={18}>{moment(e.endDate).format(MOMENT_LONG_DATE_FORMAT)}</Col>

                                                        <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Duration'} />{':'}</span></Col>
                                                        <Col span={18}>{moment.utc(duration.asMilliseconds()).format(getFormat('TIME_SHORT'))}</Col>
                                                        {e.userNote && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'User note'} />{':'}</span></Col>}
                                                        {e.userNote && <Col span={18}>{e.userNote}</Col>}
                                                        {e.poi && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Workplace'} />{':'}</span></Col>}
                                                        {e.poi && <Col span={18}>{e.poi?.title}</Col>}

                                                        {e.project && <Col span={6}><span style={{ fontWeight: 600 }}>{company?.projectDisplayText ? getCaseAndPlural(company?.projectDisplayText, true, CaseType.FIRST_LETTER_UPPERCASE) : intl.formatMessage({ defaultMessage: 'Projects' })}</span>{':'}</Col>}
                                                        {e.project && <Col span={18}>{e.project?.title}</Col>}

                                                        {e.staffType && <Col span={6}><span style={{ fontWeight: 600 }}><FormattedMessage defaultMessage={'Skill'} />{':'}</span></Col>}
                                                        {e.staffType && <Col span={18}>{e?.staffType?.name}</Col>}
                                                    </Row>
                                                }
                                            >
                                                <Col span={12}>
                                                    <Event key={`event-${e.id}`} event={e} source={EventSource.PLANNINGSTATISTIC} displayIcons displayTimes />
                                                </Col>
                                            </Popover>

                                        </>
                                    )
                                }
                                )}
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
                                    <Pagination
                                        current={currentPage}
                                        onChange={(page: number, pageSize: number) => { setCurrentPage(page); setPageSize(pageSize) }}
                                        total={todayEvents.length}
                                        defaultPageSize={pageSize}
                                        pageSizeOptions={["8", "16", "24", "32", "64", "72"]}
                                        showSizeChanger={true}
                                        hideOnSinglePage={true}
                                        showTotal={(total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} events'} values={{ range0: range[0], range1: range[1], total }} />}
                                    />
                                </div>
                            </Row>


                        </div>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No event planned today'} />} />

            }
        </Card>
    );

}
export default PlanningV2