import { MessagesState, StoreAction } from '../../utils/types/storeTypes';

export const MESSAGES_INIT_STATE: MessagesState = {
    smsAccount: undefined,
    smsAccountLoading: false
};

export const CHANGE_SMS_ACCOUNT = ('CHANGE_SMS_ACCOUNT');
export const CHANGE_SMS_ACCOUNT_LOADING = ('CHANGE_SMS_ACCOUNT_LOADING');
export const RESET = ('RESET_MESSAGES')



const messages = (state: MessagesState = MESSAGES_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_SMS_ACCOUNT:
            return {
                ...state,
                smsAccount: action.data,
            };
        case CHANGE_SMS_ACCOUNT_LOADING:
            return {
                ...state,
                smsAccountLoading: action.data,
            };
        case RESET:
            return MESSAGES_INIT_STATE;
        default:
            return state;
    }
};

export default messages;