import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { RiFilterOffLine } from 'react-icons/ri';

export function tableColumnTextFilterConfig<T>(): ColumnType<T> {
    const searchInputHolder: { current: any | null } = { current: null };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return <div style={{ padding: 8 }}>
                <Input
                    ref={node => searchInputHolder.current = node}
                    placeholder={'Chercher'}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: '200px', marginRight: '8px' }}
                />
                <Button style={{ marginRight: '2px', height: '33px', width: '33px' }} type="primary" onClick={() => confirm()} icon={<SearchOutlined />} />
                <Button style={{ height: '33px', width: '33px' }} icon={<span className='anticon'><RiFilterOffLine /></span>} onClick={clearFilters} />
            </div >
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputHolder.current?.select());
            }
        },
    }
}
export function dashboardDisableFilters<T>(display: boolean, filters: ColumnFilterItem[] | undefined): ColumnType<T> {
    if (!display) {
        return {
            filterSearch: false,
        }
    } else {
        return {
            filterSearch: true,
            filters: filters,
        }
    }
}
