// utils/injectIntl.js

import hoistNonReactStatic from 'hoist-non-react-statics';
import { ComponentType } from 'react';
import { injectIntl as baseInjectIntl } from 'react-intl';

/**
 * A fixed injectIntl that hoists statics.
 */
export default function injectIntlHOC(WrappedComponent: ComponentType<any>): ComponentType<any> {
    const WrapperComponent = baseInjectIntl(WrappedComponent);

    hoistNonReactStatic(WrapperComponent, WrappedComponent);

    return WrapperComponent;
}