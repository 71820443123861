import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, InputNumber, Popconfirm, Radio, Row, Select, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MOMENT_SHORT_TIME_FORMAT } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import { CodeDiscount, CompanyLevel, CompanyProduct } from '../../../utils/types/generalTypes';
import { IntlProps } from '../../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

enum DataType { Title, ExpiryDate, NbTimes, Amount, Percent, Lessons, Levels, LessonDateStart, LessonDateEnd, LessonDurationDays, LessonDurationHours }

interface Props extends IntlProps {
    codeDiscount: CodeDiscount;
    companyProducts: CompanyProduct[];
    loadingCompanyLevels: boolean;
    companyLevels: CompanyLevel[];
    loadingCompanyProducts: boolean;
    closeCodeDiscount: (codeDiscount?: CodeDiscount) => void;

}

interface State {
    reductionType: number;
    codeDiscount: CodeDiscount;
    isModified: boolean;
}

class CodeDiscountShowModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            codeDiscount: { ...this.props.codeDiscount },
            reductionType: this.props.codeDiscount.percent ? 2 : 1,
            isModified: false,
        };
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        const { companyProducts, companyLevels } = this.props;
        const { codeDiscount } = this.state;
        switch (type) {
            case DataType.Title:
                codeDiscount.code = value.target.value;
                break;
            case DataType.Amount:
                codeDiscount.percent = undefined;
                codeDiscount.amount = value;
                break;
            case DataType.Percent:
                codeDiscount.amount = undefined;
                codeDiscount.percent = value;
                break;
            case DataType.ExpiryDate:
                codeDiscount.expiryAt = value;
                break;
            case DataType.NbTimes:
                codeDiscount.nbOfTimes = value;
                break;
            case DataType.Lessons: {
                const newCompanyProducts = companyProducts.filter((cp) => value.includes(cp.id))
                codeDiscount.lessons = newCompanyProducts;
                break;
            }
            case DataType.Levels: {
                const newCompanyLevels = companyLevels.filter((cl) => value.includes(cl.id))
                codeDiscount.levels = newCompanyLevels;
                break;
            }
            case DataType.LessonDateStart:
                codeDiscount.lessonFrom = value;
                break;
            case DataType.LessonDateEnd:
                codeDiscount.lessonTo = value;
                break;
            case DataType.LessonDurationDays:
                codeDiscount.lessonDurationDays = value;
                break;
            case DataType.LessonDurationHours:
                codeDiscount.lessonDurationHours = value;
                break;
        }

        this.setState({ codeDiscount, isModified: true });
    }

    render() {
        const { companyProducts, loadingCompanyProducts, companyLevels, loadingCompanyLevels, intl } = this.props;
        const { reductionType, codeDiscount, isModified } = this.state;
        return (
            <Modal
                keyboard={!isModified}
                closable={true}
                maskClosable={!isModified}
                title={codeDiscount.id < 0 ? <FormattedMessage defaultMessage={'New discount voucher'} /> : isModified ? <FormattedMessage defaultMessage={'Edit discount voucher'} /> : <FormattedMessage defaultMessage={'Discount voucher details'} />}
                visible={codeDiscount !== undefined}
                width={"880px"}
                onOk={() => this.props.closeCodeDiscount(codeDiscount)}
                onCancel={() => this.props.closeCodeDiscount(undefined)}
                footer={[
                    isModified ?
                        <Popconfirm
                            key="code-discount-cancel"
                            title={<FormattedMessage defaultMessage={'Do you want to cancel your changes?'} />}
                            onConfirm={() => this.props.closeCodeDiscount(undefined)}
                            okText={<FormattedMessage defaultMessage={'Yes'} />}
                            cancelText={<FormattedMessage defaultMessage={'No'} />}
                        >
                            <Button type="dashed" key="occupancy-rate-modal-button-cancel">
                                <FormattedMessage defaultMessage={'Cancel'} />
                            </Button>
                        </Popconfirm>
                        :
                        <Button key="code-discount-close" type="dashed" onClick={() => this.props.closeCodeDiscount(undefined)}>
                            <FormattedMessage defaultMessage={'Close'} />
                        </Button>,
                    <Button key="code-discount-save" disabled={!isModified} type="primary" onClick={() => this.props.closeCodeDiscount(codeDiscount)}>
                        <FormattedMessage defaultMessage={'Save'} />
                    </Button>
                ]}
            >
                <Row gutter={[15, 15]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col xs={{ span: 24 }}>
                                <Card size="small" title={<FormattedMessage defaultMessage={'Discount voucher'} />}>
                                    <Row gutter={[15, 15]}>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span><FormattedMessage defaultMessage={'Code'} /></span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <InputField
                                                className={"__crm-codeDiscount-modal-input"}
                                                value={codeDiscount.code}
                                                placeholder={intl.formatMessage({ defaultMessage: 'Code' })}
                                                onChange={(e: InputFieldOnChangeEvent) => {
                                                    this.dataChanged(DataType.Title, e)
                                                }} />
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <Row gutter={[8, 8]}>
                                                <Col xs={{ span: 24 }}>
                                                    <Radio.Group
                                                        buttonStyle="solid"
                                                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                                        value={reductionType}
                                                        onChange={(e) => this.setState({ reductionType: e.target.value })}>
                                                        <Radio.Button style={{ borderRadius: "18px 0px 0px 18px", width: "50%", textAlign: "right" }} value={1}><FormattedMessage defaultMessage={'Amount'} /></Radio.Button>
                                                        <Radio.Button style={{ borderRadius: "0px 18px 18px 0px", width: "50%" }} value={2}><FormattedMessage defaultMessage={'Percentage'} /></Radio.Button>
                                                    </Radio.Group>
                                                </Col>

                                                {
                                                    reductionType === 1 ?
                                                        <>
                                                            <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                                                <span><FormattedMessage defaultMessage={'Amount'} /></span>
                                                            </Col>
                                                            <Col xs={{ span: 14 }}>
                                                                <InputNumber
                                                                    className="__crm-codeDiscount-modal-input"
                                                                    type="number"
                                                                    min={1}
                                                                    value={codeDiscount.amount}
                                                                    onChange={n => this.dataChanged(DataType.Amount, n)} />
                                                            </Col></>
                                                        :
                                                        <>
                                                            <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                                                <span><FormattedMessage defaultMessage={'Percentage'} /></span>
                                                            </Col>
                                                            <Col xs={{ span: 14 }}>
                                                                <InputNumber
                                                                    className="__crm-codeDiscount-modal-input"
                                                                    type="number"
                                                                    min={1}
                                                                    max={100}
                                                                    value={codeDiscount.percent}
                                                                    onChange={n => this.dataChanged(DataType.Percent, n)} />
                                                            </Col>
                                                        </>

                                                }
                                            </Row>
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col xs={{ span: 24 }}>
                                <Card size="small" title={<FormattedMessage defaultMessage={'Conditions'} />}>
                                    <Row gutter={[15, 15]}>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span>
                                                <FormattedMessage defaultMessage={'Quantity'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Number of times the code can be used. Unlimited when this field is not defined.' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <InputNumber
                                                placeholder={intl.formatMessage({ defaultMessage: 'Unlimited' })}
                                                className="__crm-codeDiscount-modal-input"
                                                type="number"
                                                min={0}
                                                value={codeDiscount.nbOfTimes}
                                                onChange={n => this.dataChanged(DataType.NbTimes, n)} />
                                        </Col>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <FormattedMessage defaultMessage={'Expiration'} />
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <DatePicker
                                                className="__crm-codeDiscount-modal-input"
                                                format={getFormat('DATE')}
                                                value={codeDiscount.expiryAt ? moment(codeDiscount.expiryAt) : undefined}
                                                onChange={d => this.dataChanged(DataType.ExpiryDate, d)} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col xs={{ span: 24 }}>
                                <Card size="small" title={<FormattedMessage defaultMessage={'Products'} />}>
                                    <Row gutter={[15, 15]}>
                                        <Col xs={{ span: 24 }}>
                                            <p className="event-section-title"><FormattedMessage defaultMessage={'Course'} /></p>
                                            <Select
                                                loading={loadingCompanyProducts}
                                                className="planning-modal-input-100"
                                                showSearch
                                                allowClear
                                                showArrow
                                                mode="multiple"
                                                maxTagCount='responsive'
                                                filterOption={true}
                                                optionFilterProp="label"
                                                placeholder={companyProducts.length === 0 ? <FormattedMessage defaultMessage={'No course available'} /> : <FormattedMessage defaultMessage={'Select courses'} />}
                                                disabled={companyProducts.length === 0}
                                                onChange={(id: number[]) => this.dataChanged(DataType.Lessons, id)}
                                                value={codeDiscount.lessons?.map(l => l.id)}>
                                                {companyProducts.map(cp => <Select.Option label={cp.title} value={cp.id} key={`cd-company-product-${cp.id}`}><span title={cp.title}>{cp.title}</span></Select.Option>)}
                                            </Select>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <p className="event-section-title"><FormattedMessage defaultMessage={'Levels'} /></p>
                                            <Select
                                                loading={loadingCompanyLevels}
                                                className="planning-modal-input-100"
                                                showSearch
                                                allowClear
                                                showArrow
                                                mode="multiple"
                                                maxTagCount='responsive'
                                                filterOption={true}
                                                optionFilterProp="label"
                                                placeholder={companyLevels.length === 0 ? <FormattedMessage defaultMessage={'No level available'} /> : <FormattedMessage defaultMessage={'Select a level'} />}
                                                disabled={companyLevels.length === 0}
                                                onChange={(id: number[]) => this.dataChanged(DataType.Levels, id)}
                                                value={codeDiscount.levels?.map(l => l.id)}>
                                                {companyLevels.map(cp => {
                                                    let cpName = cp.name;
                                                    if (cpName === "" || cpName === undefined) {
                                                        cpName = `${intl.formatMessage({ defaultMessage: 'No name' })} ${cp.externalId}`;
                                                    }
                                                    return <Select.Option label={cpName} value={cp.id} key={`cd-company-product-${cp.id}`}><span title={cpName}>{cpName}</span></Select.Option>
                                                })}
                                            </Select>
                                        </Col>

                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span>
                                                <FormattedMessage defaultMessage={'From'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'The course starts on the chosen date.' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <DatePicker
                                                className="__crm-codeDiscount-modal-input"
                                                format={getFormat('DATE_AND_TIME_SHORT')}
                                                showTime={{ format: MOMENT_SHORT_TIME_FORMAT }}
                                                value={codeDiscount.lessonFrom ? moment(codeDiscount.lessonFrom) : undefined}
                                                onChange={d => this.dataChanged(DataType.LessonDateStart, d)} />
                                        </Col>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span>
                                                <FormattedMessage defaultMessage={'Until'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'The course ends before the chosen date.' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <DatePicker
                                                className="__crm-codeDiscount-modal-input"
                                                format={getFormat('DATE_AND_TIME_SHORT')}
                                                showTime={{ format: MOMENT_SHORT_TIME_FORMAT }}
                                                value={codeDiscount.lessonTo ? moment(codeDiscount.lessonTo) : undefined}
                                                onChange={d => this.dataChanged(DataType.LessonDateEnd, d)} />
                                        </Col>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span>
                                                <FormattedMessage defaultMessage={'Duration (hours)'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Course duration in hours.' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <InputNumber
                                                className="__crm-codeDiscount-modal-input"
                                                type="number"
                                                min={0}
                                                value={codeDiscount.lessonDurationHours}
                                                onChange={n => this.dataChanged(DataType.LessonDurationHours, n)} />
                                        </Col>
                                        <Col xs={{ span: 10 }} style={{ margin: 'auto' }}>
                                            <span>
                                                <FormattedMessage defaultMessage={'Duration (days)'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Course duration in days' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xs={{ span: 14 }}>
                                            <InputNumber
                                                className="__crm-codeDiscount-modal-input"
                                                type="number"
                                                min={0}
                                                value={codeDiscount.lessonDurationDays}
                                                onChange={n => this.dataChanged(DataType.LessonDurationDays, n)} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        )
    }
}

export default injectIntl(CodeDiscountShowModal);