import { Col, Divider, Row } from 'antd';
import CountUp from 'react-countup';
import { AiOutlineBranches } from 'react-icons/ai';
import { BsFolderCheck } from 'react-icons/bs';
import { LuContact } from 'react-icons/lu';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { RiMapPin2Line } from 'react-icons/ri';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Rules } from '../../../rbacRules';
import { PLACEHOLDER_IMAGE } from '../../../utils/constants';
import Network from '../../../utils/network';
import { ICustomer, ISite } from '../../../utils/types/customerTypes';
import { RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import Anticon from '../../common/general/anticon';
import Can from '../../common/general/can';
import SpaceContent from '../../common/general/spaceContent';
import ContainerTabsItem, { ContainerTabsItemProps } from '../../common/navigations/containerTabsItem';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, ContainerTabsItemProps, IntlProps {
    customer: ICustomer;
}

interface State {
    sites?: ISite[];
}

export class Customer extends ContainerTabsItem<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        Network.getCustomerSites(this.props.customer.id).then(
            (response) => {
                this.setState({ sites: response.data });
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the branches' }), "error")
        );
    }

    getExtra = () => null;
    getFilters = () => null;

    render() {
        const { customer, width } = this.props;
        const { sites } = this.state;
        const { logoUrl, title, code, site: smallSite } = customer;
        const site = sites?.find(s => s.id === smallSite.id);

        const widthFullHd = width <= 1920;

        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <div style={{ display: 'grid', gridTemplateColumns: '500px 1fr', gap: '10px' }}>
                    <SpaceContent space={20} style={{ background: 'var(--background-card-color)', borderRadius: 'var(--border-radius)', padding: '20px', width: '100%', maxWidth: '100%' }}>
                        <SpaceContent direction='vertical' space={0}>
                            <img style={{ width: '100px', height: '100px', borderRadius: '100%' }} src={`${logoUrl ?? PLACEHOLDER_IMAGE}`} />
                            <span style={{ fontStyle: 'italic', fontSize: '90%' }}>{code}</span>
                        </SpaceContent>
                        <SpaceContent direction='vertical' align='flex-start'>
                            <p style={{ fontWeight: 'bold' }}>{title}</p>
                            {
                                site ?
                                    <SpaceContent>
                                        <Anticon icon={<RiMapPin2Line />} />
                                        <p>{`${site.adress}, ${site.zip} ${site.city}`}</p>
                                    </SpaceContent>
                                    : null
                            }
                        </SpaceContent>
                    </SpaceContent>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: widthFullHd ? 12 : 6 }}>
                            <div style={{ padding: '20px', background: 'var(--background-card-color)', height: '100%', width: '100%', borderRadius: 'var(--border-radius)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', flexDirection: 'column' }}>
                                <Anticon icon={<AiOutlineBranches size={20} />} />
                                <CountUp
                                    delay={0}
                                    end={30}
                                >
                                    {({ countUpRef }) => (
                                        <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                            <span style={{ fontSize: '150%' }} ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <p><FormattedMessage defaultMessage={'branches'} /></p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: widthFullHd ? 12 : 6 }}>
                            <div style={{ padding: '20px', background: 'var(--background-card-color)', height: '100%', width: '100%', borderRadius: 'var(--border-radius)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', flexDirection: 'column' }}>
                                <Anticon icon={<LuContact size={20} />} />
                                <CountUp
                                    delay={0}
                                    end={15}
                                >
                                    {({ countUpRef }) => (
                                        <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                            <span style={{ fontSize: '150%' }} ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <p><FormattedMessage defaultMessage={'contacts'} /></p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: widthFullHd ? 12 : 6 }}>
                            <div style={{ padding: '20px', background: 'var(--background-card-color)', height: '100%', width: '100%', borderRadius: 'var(--border-radius)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', flexDirection: 'column' }}>
                                <Anticon icon={<BsFolderCheck size={20} />} />
                                <CountUp
                                    delay={0}
                                    end={10}
                                >
                                    {({ countUpRef }) => (
                                        <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                            <span style={{ fontSize: '150%' }} ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <p><FormattedMessage defaultMessage={'mandates'} /></p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: widthFullHd ? 12 : 6 }}>
                            <div style={{ padding: '20px', background: 'var(--background-card-color)', height: '100%', width: '100%', borderRadius: 'var(--border-radius)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', flexDirection: 'column' }}>
                                <Anticon icon={<MdOutlineEditCalendar size={20} />} />
                                <CountUp
                                    delay={0}
                                    end={20}
                                >
                                    {({ countUpRef }) => (
                                        <div style={{ display: 'flex', gap: '5px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}>
                                            <span style={{ fontSize: '150%' }} ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <p><FormattedMessage defaultMessage={'missions'} /></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Divider orientation='left'><FormattedMessage defaultMessage={'Notes'} /></Divider>
                <Row gutter={[10, 10]}>
                    {customer.notes?.map(n => {
                        return (
                            <Col key={`note-${n.id}`} xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 8 }}>
                                <SpaceContent direction='vertical' style={{ background: 'var(--background-card-color)', borderRadius: 'var(--border-radius)', padding: '20px' }}>
                                    <b>{n.title}</b>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{n.description}</p>
                                </SpaceContent>
                            </Col>
                        )
                    })}
                </Row>
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    displayFilters: state.window.displayFilters,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(injectIntl(Customer)));