import React from 'react'
import { StoreDispatch, ApplicationState, TeamManagementDispatchProps } from '../../utils/types/storeTypes';
import { changeUsers, toggleUsersLoading } from '../../store/actions/teamManagement';
import { User, RouterProps } from '../../utils/types/generalTypes';
import { connect } from 'react-redux';
import Network from '../../utils/network';
import { alert } from '../../utils/utils';
import { Table, Avatar, Button, DatePicker } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import CircleButton from '../common/fields/circleButton';
import Card from '../common/general/card';
import moment, { Moment } from 'moment';
import { withRouter } from 'react-router-dom';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';

interface IProps {
    users: User[];
    usersLoading: boolean;
}

type Props = IProps & TeamManagementDispatchProps & RouterProps;

interface State {
    month: Moment;
    selectedUsers: number[];
    isLoading: number | undefined; //the id of the concerned user, or -1 if all
    searchedUsers: User[] | undefined;
}

/**
 * Component that represent the report tab in the report page
 */
class ReportTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            month: moment().startOf("month"),
            selectedUsers: [],
            isLoading: undefined,
            searchedUsers: undefined,
        };
    }

    componentDidMount() {
        // get all users
        this.props.toggleUsersLoading!(true);
        Network.getAllUsers().then(
            response => this.props.changeUsers!(response),
            () => alert("Un problème est survenu pendant le chargement des utilisateurs", "warning"),
        );
    }

    columns: ColumnsType<User> = [
        {
            title: 'Nom',
            dataIndex: 'last_name',
            key: 'last_name',
            fixed: true,
            sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
            defaultSortOrder: 'ascend',
            width: '100px',
        },
        {
            title: 'Prénom',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
            width: '100px',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (link: string) => (
                <div className="both-center">
                    <Avatar size="large" src={link} icon={<UserOutlined />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
            width: '60px',
        },
        {
            title: 'Télécharger',
            key: 'download',
            render: (user: User) => (
                <div className="flex-center">
                    <CircleButton
                        title={"Télécharger"}
                        icon={<DownloadOutlined />}
                        onClick={() => this.generateReports(user)}
                        disabled={Boolean(this.state.isLoading)}
                        loading={this.state.isLoading === user.id} />
                </div>
            )
        }
    ];

    /**
     * Called when a user is (un)checked
     * @param keys the new keys of checked groups
     */
    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    /**
     * Automatic downlaod blob
     * @param blob the blob to download
     * @param filename the file name
     */
    downloadBlob = (blob: Blob, filename: string) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    }

    /**
     * Generate the reports for the selected users
     * @param user the concerned user, if one report must be generated - optional
     */
    generateReports = (user?: User) => {
        let userIds: number[] | undefined = [];

        //if a userId is specified, create an array with one id 
        if (user) {
            userIds.push(user.id);
            this.setState({ isLoading: user.id });
        }
        // else use the selected users array
        else {
            userIds = this.state.selectedUsers;
            this.setState({ isLoading: -1 });
        }

        // if all users are selected, unset userIds
        if (userIds.length === this.props.users.length) userIds = undefined;

        // create filename
        let filename = `${this.state.month.format("YYYY-MM")}.zip`;
        if (user) filename = `${this.state.month.format("YYYY-MM")}_${user.last_name}_${user.first_name}.xlsx`.replace(" ", "-");
        else if (userIds && userIds.length === 1) {
            const selectedUser = this.props.users.find(u => u.id === userIds![0]);
            if (selectedUser) filename = `${this.state.month.format("YYYY-MM")}_${selectedUser.last_name}_${selectedUser.first_name}.xlsx`.replace(" ", "-");
        }

        // generate reports
        Network.generateReports(this.state.month.format("YYYY-MM-01"), userIds).then(
            blob => {
                this.downloadBlob(blob, filename);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert("Un problème est survenu pendant la création des rapports.", "warning");
                this.setState({ isLoading: undefined });
            }
        );
    }

    /**
     * Generate the company report for the selected users
     */
    generateCompanyReport = () => {
        this.setState({ isLoading: -2 });
        // generate reports
        Network.generateCompanyReport(this.state.month.format("YYYY-MM-01")).then(
            blob => {
                this.downloadBlob(blob, `company_${this.state.month.format("YYYY-MM")}.xlsx`);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert("Un problème est survenu pendant la création des rapports.", "warning");
                this.setState({ isLoading: undefined });
            }
        );
    }

    /**
     * Handle users filtering with search bar
     * @param event the triggered event
     */
    handleSearchUsers = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedUsers: undefined });
        } else {
            const searchedUsers = this.props.users.filter(u => `${u.last_name}${u.first_name}`.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            this.setState({ searchedUsers });
        }
    }

    render() {
        return (
            <div>
                <Card icon={<UserOutlined />} title={"Utilisateurs"}>
                    <div className="report-report-header">
                        <div className="report-report-month">
                            <p>{"Mois et année"}</p>
                            <DatePicker
                                className="report-report-month-picker"
                                picker="month"
                                format={"MMMM, YYYY"}
                                value={this.state.month}
                                onChange={d => this.setState({ month: d ?? this.state.month })}
                                allowClear={false} />
                        </div>
                        <div>
                            {
                                this.state.selectedUsers.length > 0 &&
                                <Button
                                    className="fade-in-animation"
                                    style={{ animationDuration: '0.5s' }}
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={() => this.generateReports()}
                                    disabled={Boolean(this.state.isLoading)}
                                    loading={this.state.isLoading === -1}>
                                    {`Télécharger ${this.state.selectedUsers.length} rapport${this.state.selectedUsers.length > 1 ? 's' : ''}`}
                                </Button>
                            }
                            <Button
                                style={{ marginLeft: '10px' }}
                                icon={<DownloadOutlined />}
                                onClick={() => this.generateCompanyReport()}
                                disabled={Boolean(this.state.isLoading)}
                                loading={this.state.isLoading === -2}>
                                {"Rapport complet"}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <InputField
                            onChange={this.handleSearchUsers}
                            style={{ width: '322px', marginBottom: '10px' }}
                            type="search" />
                        <Table
                            loading={this.props.usersLoading}
                            dataSource={this.state.searchedUsers ?? this.props.users}
                            columns={this.columns}
                            pagination={false}
                            scroll={{ x: true }}
                            rowKey={(g: any) => g.id}
                            rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }} />
                    </div>
                </Card>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    toggleUsersLoading: (b: boolean) => dispatch(toggleUsersLoading(b)),
});

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
    usersLoading: state.teamManagement.usersLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportTab));