import qs from "qs";
import axiosapi from "../utils/axiosapi";

// import {
//     TYPE_INIT_PRODUCTS,
//     TYPE_GET_ALL_PRODUCTS,
//     // TYPE_GET_ALL_PRODUCTS_SUCCESS,
//     TYPE_GET_ALL_PRODUCTS_FAILURE,
// } from '../store/reducer/products';

import { CHANGE_COMPANY_CODES_DISCOUNT, CHANGE_COMPANY_EDIT_PRODUCT, CHANGE_COMPANY_LEVELS, CHANGE_COMPANY_PRODUCTS, FAILURE_COMPANY_CODES_DISCOUNT, FAILURE_COMPANY_LEVELS, FAILURE_COMPANY_PRODUCTS, LOADING_COMPANY_CODES_DISCOUNT, LOADING_COMPANY_LEVELS, LOADING_COMPANY_PRODUCTS } from "../store/reducer/crm";

// export const initProducts = () => (dispatch, getState) => {
//     dispatch({
//         type: TYPE_INIT_PRODUCTS
//     });
// };

export const fetchAllProducts = (params, language) => (dispatch) => {

    dispatch({
        type: LOADING_COMPANY_PRODUCTS,
        data: true,
    });

    params = JSON.parse(JSON.stringify(params));
    params.offset = 0;
    // params.school__version = 0;

    // if the school is a department use department id instead of school id
    // if (params.department_id) params.school_id = undefined;

    params.sunkhro = true
    // params.external_activity_id = "9DLZJ"

    // fetch lessons
    axiosapi.get('/lessons/', {
        'headers': {
            'Accept-Language': language,
        },
        params: params, paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma', encode: false, skipNulls: true });
        }
    }).then(response => {
        if (params.external_id) {
            if (response.data.length > 0) {
                response.data = response.data[0]
                dispatch({
                    type: CHANGE_COMPANY_EDIT_PRODUCT,
                    data: response.data
                });
                dispatch({
                    type: LOADING_COMPANY_PRODUCTS,
                    data: false,
                });
            }
        } else {
            dispatch({
                type: CHANGE_COMPANY_PRODUCTS,
                data: response.data
            });
            dispatch({
                type: LOADING_COMPANY_PRODUCTS,
                data: false,
            });
        }
    }).catch(error => {
        dispatch({
            type: FAILURE_COMPANY_PRODUCTS
        });
        dispatch({
            type: LOADING_COMPANY_PRODUCTS,
            data: false,
        });
        console.error(error);
    });
};

export const fetchAllLevels = (params, language) => (dispatch) => {
    dispatch({
        type: LOADING_COMPANY_LEVELS,
        data: true,
    });

    params = JSON.parse(JSON.stringify(params));
    params.offset = 0;

    // fetch lessons
    axiosapi.get('/levels/', {
        'headers': {
            'Accept-Language': language,
        },
        params: params, paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma', encode: false, skipNulls: true });
        }
    }).then(response => {
        dispatch({
            type: CHANGE_COMPANY_LEVELS,
            data: response.data
        });
        dispatch({
            type: LOADING_COMPANY_LEVELS,
            data: false,
        });
    }).catch(error => {
        dispatch({
            type: FAILURE_COMPANY_LEVELS
        });
        dispatch({
            type: LOADING_COMPANY_LEVELS,
            data: false,
        });
        console.error(error);
    });
};

export const fetchAllCodesDiscount = (params, language) => (dispatch) => {
    dispatch({
        type: LOADING_COMPANY_CODES_DISCOUNT,
        data: true,
    });
    params = JSON.parse(JSON.stringify(params));
    params.sunkhro = true

    // fetch lessons
    axiosapi.get('/code_discount/', {
        'headers': {
            'Accept-Language': language,
        },
        params: params, paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma', encode: false, skipNulls: true });
        }
    }).then(response => {
        dispatch({
            type: CHANGE_COMPANY_CODES_DISCOUNT,
            data: response.data
        });
        dispatch({
            type: LOADING_COMPANY_CODES_DISCOUNT,
            data: false,
        });
    }).catch(error => {
        dispatch({
            type: FAILURE_COMPANY_CODES_DISCOUNT
        });
        dispatch({
            type: LOADING_COMPANY_CODES_DISCOUNT,
            data: false,
        });
        console.error(error);
    });
};