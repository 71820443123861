import { InboxOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Badge, Col, DatePicker, Popover, Row, Select } from "antd";
import moment, { Moment } from "moment";
import React, { Fragment } from "react";
import { BsSortAlphaDown, BsSortAlphaUpAlt, BsSortDownAlt, BsSortNumericDown, BsSortNumericUpAlt, BsSortUpAlt } from "react-icons/bs";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { CaseType } from "../../../../utils/constants";
import getFormat from "../../../../utils/Lang";
import { DepartmentWithUpdate, DictionaryNumber, PlanningUser, PoiWithUpdate, User } from "../../../../utils/types/generalTypes";
import { NetworkMonthlyPlanningRow } from "../../../../utils/types/networkTypes";
import { Project, TypeOfDay } from "../../../../utils/types/planningTypes";
import { ApplicationState } from "../../../../utils/types/storeTypes";
import { colorIsBright, getCaseAndPlural } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";
import CircleButton from "../../../common/fields/circleButton";
import Anticon from "../../../common/general/anticon";

const enum SortOrder {
    Asc = 1,
    Desc = -1
}

const enum SortOptionsValues {
    Name = 0,
    Event = 1,
    DateTime = 2,

}

const enum SortOptionsType {
    USERNAME = 'username',
    EVENT = 'event',
    SCHEDULE = 'schedule',
    DEFAULT = 'default',
}

const SortOptionMessages = defineMessages<SortOptionsType>({
    [SortOptionsType.USERNAME]: { defaultMessage: 'Username' },
    [SortOptionsType.EVENT]: { defaultMessage: 'Event name' },
    [SortOptionsType.SCHEDULE]: { defaultMessage: 'Schedule' },
    [SortOptionsType.DEFAULT]: { defaultMessage: 'Unknown' },
});

const sortOptions = (order?: SortOrder) => [
    {
        id: SortOptionsValues.Name,
        title: SortOptionsType.USERNAME,
        icon: order === undefined ? undefined : order === SortOrder.Asc ? <BsSortAlphaDown /> : <BsSortAlphaUpAlt />
    },
    {
        id: SortOptionsValues.Event,
        title: SortOptionsType.EVENT,
        icon: order === undefined ? undefined : order === SortOrder.Asc ? <BsSortAlphaDown /> : <BsSortAlphaUpAlt />
    },
    {
        id: SortOptionsValues.DateTime,
        title: SortOptionsType.SCHEDULE,
        icon: order === undefined ? undefined : order === SortOrder.Asc ? <BsSortNumericDown /> : <BsSortNumericUpAlt />
    }
];

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    data: DictionaryNumber<PlanningUser>;
    date: Moment;
    maxStartDate: Moment;
    maxEndDate: Moment;
    reset?: boolean;
    refresh?: boolean;
    changeDate: (date: Moment,) => void;
    resetOff?: () => void;
    refreshOff?: () => void;
    showEventDetails?: (eventId?: number) => void;

    projects?: Project[];
    pois?: PoiWithUpdate;
    typesOfDay: TypeOfDay[];
    departments: DepartmentWithUpdate;
    users?: User[];
}

type Props = IProps & ReduxProps & IntlProps;

interface State {
    idxHover: number;
    selectedProjectsIds?: number[];
    selectedPoisIds?: number[];
    selectedTypesOfDayIds?: number[];
    selectedDepartmentsIds?: number[];
    selectedUsersIds?: number[];
    sortOption: SortOptionsValues;
    sortOrder: SortOrder;
    sortOpen: boolean;
    // data?: MonthlyPlanningByUser[];
}

class DailySummary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            idxHover: -1,
            sortOption: SortOptionsValues.Name,
            sortOrder: SortOrder.Asc,
            sortOpen: false

        };

    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.reset && !prevProps.reset) {
            this.setState({ selectedDepartmentsIds: undefined, selectedPoisIds: undefined, selectedProjectsIds: undefined, selectedTypesOfDayIds: undefined, selectedUsersIds: undefined }, () => {
                this.props.resetOff && this.props.resetOff();
            });
        }

    }

    onChangeDay = (value: any) => {
        this.props.changeDate(value);
    };

    onChangeDayPrev = () => {
        this.props.changeDate(this.props.date.clone().subtract(1, "day").startOf("day"));
    };

    onChangeDayNext = () => {
        this.props.changeDate(this.props.date.clone().add(1, "day").startOf("day"));
    };

    /**
     * Check if a date must be disabled
     * @param date the current date to verify
     * @returns true if the date must be disabled, false otherwise
     */
    disableDate = (date: any): boolean => {
        const { maxStartDate, maxEndDate } = this.props;

        return (date && maxStartDate && maxEndDate && (date.isBefore(maxStartDate) || date.isAfter(maxEndDate)));
    };

    onChangeSort = (val: SortOptionsValues) => {
        const { sortOption } = this.state;
        let { sortOrder } = this.state;

        sortOrder = sortOption === val ? -1 * sortOrder : SortOrder.Asc;

        this.setState({ sortOption: val, sortOrder });
    };

    onSortChangeOpen = (val: boolean) => {
        this.setState({ sortOpen: val });
    };

    render() {
        const { idxHover, selectedProjectsIds, selectedPoisIds, selectedTypesOfDayIds, selectedDepartmentsIds, selectedUsersIds, sortOption, sortOrder, sortOpen } = this.state;
        const { data, date, projects, pois, typesOfDay, departments, users, showEventDetails, intl } = this.props;

        const dateStr = `${moment(this.props.date).format("YYYYMMDD")}`;
        const { Option } = Select;
        const SortIcon = sortOrder === SortOrder.Asc ? BsSortDownAlt : BsSortUpAlt;

        let parsedData: NetworkMonthlyPlanningRow[] = [];
        Object.keys(data).forEach((k) => {
            const events = data[parseInt(k)].days[dateStr]?.events;
            if (events !== undefined && events.length > 0) {
                parsedData = parsedData.concat(events);
            }
        });

        // Filter
        if (selectedUsersIds && selectedUsersIds.length > 0)
            parsedData = parsedData.filter(e => selectedUsersIds.findIndex(sp => sp === e.userId) !== -1);
        if (selectedProjectsIds && selectedProjectsIds.length > 0)
            parsedData = parsedData.filter(e => selectedProjectsIds.findIndex(sp => sp === e.projectId) !== -1);
        if (selectedPoisIds && selectedPoisIds.length > 0)
            parsedData = parsedData.filter(e => selectedPoisIds.findIndex(sp => sp === e.poiId) !== -1);
        if (selectedTypesOfDayIds && selectedTypesOfDayIds.length > 0)
            parsedData = parsedData.filter(e => selectedTypesOfDayIds.findIndex(sp => sp === e.typeOfDayId) !== -1);
        if (selectedDepartmentsIds && selectedDepartmentsIds.length > 0)
            parsedData = parsedData.filter(e => selectedDepartmentsIds.findIndex(sp => sp === e.departmentId) !== -1);

        // Sort
        parsedData = parsedData.sort((a, b) => {
            switch (sortOption) {
                case SortOptionsValues.Name:
                    if (sortOrder === SortOrder.Asc) {
                        return `${a.userLastName} ${a.userLastName}`.toLocaleLowerCase().localeCompare(`${b.userLastName} ${b.userLastName}`.toLocaleLowerCase());
                    } else {
                        return `${b.userLastName} ${b.userLastName}`.toLocaleLowerCase().localeCompare(`${a.userLastName} ${a.userLastName}`.toLocaleLowerCase());
                    }
                case SortOptionsValues.Event:
                    if (sortOrder === SortOrder.Asc) {
                        return a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase());
                    } else {
                        return b.title.toLocaleLowerCase().localeCompare(a.title.toLocaleLowerCase());
                    }
                case SortOptionsValues.DateTime:
                    if (sortOrder === SortOrder.Asc) {
                        return moment(a.startDate).diff(moment(b.startDate)) ?? moment(a.endDate).diff(moment(b.endDate));
                    } else {
                        return moment(b.startDate).diff(moment(a.startDate)) ?? moment(b.endDate).diff(moment(a.endDate));
                    }
                default:
                    return 0;
            }
        });

        return (
            <Fragment>
                <div style={{ paddingBottom: '10px', position: "sticky", top: "0", backgroundColor: "white", zIndex: 2 }}>
                    <Row gutter={[5, 10]}>
                        <Col xs={24} style={{ textAlign: 'right', paddingRight: '6px', display: 'flex', gap: '5px' }}>

                            <CircleButton
                                small
                                withoutTooltip
                                style={{ minWidth: "32px" }}
                                icon={<LeftOutlined />}
                                title={intl.formatMessage({ defaultMessage: 'Previous day' })}
                                onClick={this.onChangeDayPrev}
                                disabled={this.props.maxStartDate?.isSame(this.props.maxEndDate, 'days') || date.isSame(this.props.maxStartDate, "d")} />
                            <DatePicker
                                disabledDate={this.disableDate}
                                style={{ width: '100%' }}
                                key="picker-day-occupancyRates"
                                allowClear={false}
                                value={date}
                                onChange={this.onChangeDay}
                                format={getFormat('DATE')}
                                disabled={this.props.maxStartDate?.isSame(this.props.maxEndDate, 'days')}
                            />
                            <CircleButton
                                small
                                withoutTooltip
                                style={{ minWidth: "32px" }}
                                icon={<RightOutlined />}
                                title={intl.formatMessage({ defaultMessage: 'Next day' })}
                                onClick={this.onChangeDayNext}
                                disabled={this.props.maxStartDate?.isSame(this.props.maxEndDate, 'days') || date.isSame(this.props.maxEndDate, "d")} />
                        </Col>
                        {
                            users !== undefined && users.length > 0 &&
                            <Col xs={24} md={{ span: 24 }}>
                                <Select
                                    value={selectedUsersIds}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={<FormattedMessage defaultMessage={'Username'} />}
                                    onChange={(e) => this.setState({ selectedUsersIds: e })}
                                    style={{ width: '100%' }}
                                    showArrow
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        users.map(user => {
                                            return <Option label={`${user.first_name} ${user.last_name}`} value={user.id} key={`user-value-${user.id}`}>{user.first_name} {user.last_name}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }
                        {
                            projects !== undefined && projects.length > 0 &&
                            <Col xs={24} md={{ span: 12 }}>
                                <Select
                                    value={selectedProjectsIds}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : intl.formatMessage({ defaultMessage: 'Project' }), true, CaseType.FIRST_LETTER_UPPERCASE)}
                                    onChange={(e) => this.setState({ selectedProjectsIds: e })}
                                    style={{ width: '100%' }}
                                    showArrow
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        projects?.map(project => {
                                            return <Option label={project.title} value={project.id} key={`project-value-${project.id}`}>{project.title}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }
                        {
                            pois !== undefined && pois.data && pois.data.length > 0 &&
                            <Col xs={24} md={{ span: 12 }}>
                                <Select
                                    value={selectedPoisIds}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={<FormattedMessage defaultMessage={'Workplace'} />}
                                    onChange={(e) => this.setState({ selectedPoisIds: e })}
                                    style={{ width: '100%' }}
                                    showArrow
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        pois.data.map(poi => {
                                            return <Option label={poi.title} value={poi.id} key={`poi-value-${poi.id}`}>{poi.title}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }
                        {
                            typesOfDay !== undefined && typesOfDay.length > 0 &&
                            <Col xs={24} md={{ span: 12 }}>
                                <Select
                                    value={selectedTypesOfDayIds}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={<FormattedMessage defaultMessage={'types of day'} />}
                                    onChange={(e) => this.setState({ selectedTypesOfDayIds: e })}
                                    style={{ width: '100%' }}
                                    showArrow
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        typesOfDay.map(typeOfDay => {
                                            return <Option label={typeOfDay.title} value={typeOfDay.id} key={`typeOfDay-value-${typeOfDay.id}`}>{typeOfDay.title}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }
                        {
                            departments !== undefined && departments.data && departments.data.length > 0 &&
                            <Col xs={24} md={{ span: 12 }}>
                                <Select
                                    value={selectedDepartmentsIds}
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={<FormattedMessage defaultMessage={'Departments'} />}
                                    onChange={(e) => this.setState({ selectedDepartmentsIds: e })}
                                    style={{ width: '100%' }}
                                    showArrow
                                    filterOption={true}
                                    optionFilterProp="label"
                                >
                                    {
                                        departments.data.map(department => {
                                            return <Option label={department.name} value={department.id} key={`department-value-${department.id}`}>{department.name}</Option>;
                                        })
                                    }
                                </Select>
                            </Col>
                        }
                    </Row>
                </div>

                {
                    data && Object.keys(data).length > 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '3px', paddingRight: '3px', marginTop: '10px' }}>
                                <p><FormattedMessage defaultMessage={'Number of events {count}'} values={{ count: parsedData.length }} /></p>
                                <Popover
                                    overlayClassName="__select-list-options-popover"
                                    trigger="click"
                                    onOpenChange={this.onSortChangeOpen}
                                    open={sortOpen}
                                    overlayStyle={{ width: '200px' }}
                                    placement="leftTop"
                                    content={
                                        <>
                                            <p className="__select-list-title">Trier par: </p>
                                            <div className="__select-list-options">
                                                {
                                                    sortOptions(sortOrder).map(o => {
                                                        return (
                                                            <div key={`dailySummary-sortby-${o.id}`} onClick={() => this.onChangeSort(o.id)} className={`__select-list-options-line ${sortOption === o.id ? 'hover' : ''}`}>
                                                                <p>{o.title}</p>
                                                                {
                                                                    sortOption === o.id ?
                                                                        <Anticon icon={o.icon} />
                                                                        :
                                                                        <p></p>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </>
                                    }>
                                    <Badge dot={sortOption !== SortOptionsValues.Name || sortOrder !== SortOrder.Asc}>
                                        <SortIcon title={this.props.intl.formatMessage({ defaultMessage: 'Sorted by {name}' }, { name: this.props.intl.formatMessage(SortOptionMessages[sortOptions().find(s => s.id === sortOption)?.title || SortOptionsType.DEFAULT]) })} style={{ cursor: 'pointer', fontSize: '110%' }} />
                                    </Badge>
                                </Popover>
                            </div>
                            <div>
                                <Row gutter={[8, 8]}>
                                    {
                                        parsedData.length === 0 ?

                                            <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                <InboxOutlined />
                                                <p><FormattedMessage defaultMessage={'No events according to filters'} /></p>
                                            </Col>
                                            :
                                            parsedData.map(e => {
                                                const fullname = `${e.userLastName} ${e.userFirstName}`;
                                                let eventColor = e.color ?? 'var(--primary-color)';
                                                if (eventColor.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i))
                                                    eventColor = "whitesmoke";

                                                return (
                                                    <Col xs={{ span: 24 }}
                                                        key={`dailySummary-data-${e.id}-${fullname}`}
                                                        onClick={() => showEventDetails && showEventDetails(e.id)}
                                                        onMouseEnter={() => this.setState({ idxHover: e.id })}
                                                        onMouseLeave={() => this.setState({ idxHover: -1 })}
                                                        title={`${fullname}: ${e.title}\n${moment(e.startDate).format(getFormat('DATE_AND_TIME_SHORT'))} - ${moment(e.endDate).format(getFormat('DATE_AND_TIME_SHORT'))}`}>
                                                        <div
                                                            style={{
                                                                margin: '0 2px 0 2px',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                padding: '2px 6px',
                                                                border: "2px solid",
                                                                borderColor: eventColor,
                                                                backgroundColor: idxHover === e.id ? eventColor : undefined,
                                                                color: idxHover === e.id ? e.color && colorIsBright(e.color) ? "var(--dark-color)" : 'white' : "var(--dark-color)",
                                                                width: 'calc(100% - 4px)',
                                                                borderRadius: "8px",
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <p style={{ maxWidth: '125px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', }}>{fullname}: </p>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: 'calc(100% - 125px)' }}>
                                                                <p style={{ maxWidth: 'calc(100% - 104px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{e.title} </p>
                                                                <p>({moment(e.startDate).format(getFormat('TIME_SHORT'))} - {moment(e.endDate).format(getFormat('TIME_SHORT'))})</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })
                                    }
                                </Row>
                            </div>
                        </div>
                        :
                        <span className="dashboard-empty">
                            <InboxOutlined />
                            <p><FormattedMessage defaultMessage={'No events available'} /></p>
                        </span>
                }
            </Fragment>
        );

    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    projects: state.configurations.project,
    pois: state.location.pois,
    typesOfDay: state.configurations.typesOfDay,
    departments: state.configurations.departments,
    users: state.teamManagement.users,
});
const connector = connect(mapStateToProps);

export default connector(injectIntl(DailySummary));