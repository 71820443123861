import { CloseOutlined, MessageOutlined } from '@ant-design/icons';
import { Card, Col, Collapse, Drawer, Empty, Row, Space, Table, Tabs, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { AiOutlinePhone } from 'react-icons/ai';
import { GrStatusCritical, GrStatusGood, GrStatusInfo, GrStatusUnknown } from 'react-icons/gr';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../../styles/sms.css';
import getFormat from '../../../utils/Lang';
import { FullSMSSent, FullSMSSentLinksClicked, FullSMSSentResponse } from '../../../utils/types/generalTypes';
import CircleButton from '../../common/fields/circleButton';



interface IProps {
    sms: FullSMSSent;
    // eslint-disable-next-line @typescript-eslint/ban-types
    setSelectedSMSSent: Function;

}

type Props = IProps;


const SMSSent = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState<string>("links")
    const intl = useIntl();

    const onChangeTab = (tab: string): void => {
        // let link = "";
        switch (tab) {
            case "responses":
                setSelectedTab("responses");
                break;
            default:
                setSelectedTab("links");
        }
    }
    const SMSSentResponseColumns: ColumnProps<FullSMSSentResponse>[] = [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            width: 45,
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.date).unix() - moment(b.date).unix(),
            },
            render: (ob: FullSMSSentResponse) => {
                return <div>{moment(ob.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Receipt date'} />,
            key: 'date',
            width: 45,
            render: (ob: FullSMSSentResponse) => {
                return <div>{moment(ob.received_date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Phone'} />,
            key: 'phone',
            width: 60,
            onFilter: (value, record) => {
                return record.phone!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            sorter: (a: FullSMSSentResponse, b: FullSMSSentResponse) => a.phone < b.phone ? -1 : 1,
            render: (ob: FullSMSSentResponse) => {
                return <div className="both-center">{ob.phone}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Message'} />,
            key: 'text',
            render: (ob: FullSMSSentResponse) => {
                return <div style={{ whiteSpace: 'pre-wrap', overflowX: "auto", wordWrap: "break-word" }}>{ob.text}</div>
            },

        },
    ]
    const SMSSentLinksClickedColumns: ColumnProps<FullSMSSentLinksClicked>[] = [
        {
            title: <FormattedMessage defaultMessage={'Date'} />,
            key: 'date',
            width: 45,
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) =>
                    moment(a.date).unix() - moment(b.date).unix(),
            },
            render: (ob: FullSMSSentLinksClicked) => {
                return <div>{moment(ob.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'URL'} />,
            width: 200,
            key: 'url',
            onFilter: (value, record) => {
                return record.url!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            sorter: (a: FullSMSSentLinksClicked, b: FullSMSSentLinksClicked) => a.url < b.url ? -1 : 1,
            render: (ob: FullSMSSentLinksClicked) => {
                return <div>{ob.url}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Short URL'} />,
            key: 'short_url',
            width: 50,
            onFilter: (value, record) => {
                return record.short_url!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            sorter: (a: FullSMSSentLinksClicked, b: FullSMSSentLinksClicked) => a.short_url < b.short_url ? -1 : 1,
            render: (ob: FullSMSSentLinksClicked) => {
                return <div>{ob.short_url}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 1'} />,
            key: 'info1',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.info1}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 2'} />,
            key: 'info2',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.info2}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 3'} />,
            key: 'info3',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.info3}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Info 4'} />,
            key: 'info4',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.info4}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Brand'} />,
            key: 'brand',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.brand}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'Model'} />,
            key: 'model',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.model}</div>
            },

        },
        {
            title: <FormattedMessage defaultMessage={'System'} />,
            key: 'os',
            width: "40px",
            render: (ob: FullSMSSentLinksClicked) => {
                return <div className="both-center">{ob.os}</div>
            },

        },



    ]
    const genStatus = useCallback((full = false) => {
        if (full) {
            return (
                <>
                    {(props.sms.status === undefined || props.sms.status === null) ?
                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}><FormattedMessage defaultMessage={'INFORMATION'} /></span><p><FormattedMessage defaultMessage={'The SMS has probably been sent'} /></p></div>} ><div className="both-center"> {"     INFORMATION"}</div></Tooltip>
                        :
                        <>
                            {props.sms.status.code === 1 || props.sms.status.code === 3 ?
                                <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><div className="both-center">{" " + props.sms.status.message}</div></Tooltip>
                                :
                                <>
                                    {props.sms.status.code === 2 || props.sms.status.code === 4 || props.sms.status.code === 5 || props.sms.status.code === 6 || props.sms.status.code === 7 || props.sms.status.code === 9 || props.sms.status.code === 11 || props.sms.status.code === 12 ?
                                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><div className="both-center"> {" " + props.sms.status.message}</div></Tooltip>
                                        :
                                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><div className="both-center">{" " + props.sms.status.message}</div></Tooltip>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            )
        } else {
            return (
                <>
                    {(props.sms.status === undefined || props.sms.status === null) ?
                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}><FormattedMessage defaultMessage={'INFORMATION'} /></span><p><FormattedMessage defaultMessage={'The SMS has probably been sent'} /></p></div>} ><span className='anticon'><GrStatusInfo /></span> </Tooltip>
                        :
                        <>
                            {props.sms.status.code === 1 || props.sms.status.code === 3 ?
                                <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><span className='anticon'><GrStatusGood /></span></Tooltip>
                                :
                                <>
                                    {props.sms.status.code === 2 || props.sms.status.code === 4 || props.sms.status.code === 5 || props.sms.status.code === 6 || props.sms.status.code === 7 || props.sms.status.code === 9 || props.sms.status.code === 11 || props.sms.status.code === 12 ?
                                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><span className='anticon'><GrStatusCritical /></span></Tooltip>
                                        :
                                        <Tooltip title={<div><span style={{ fontWeight: "bold" }}>{props.sms.status.message}</span><p>{props.sms.status.description}</p></div>} ><span className='anticon'><GrStatusUnknown /></span></Tooltip>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            )
        }
    }, [props.sms])

    return (
        <>
            <Drawer
                visible={true}
                width="100VW"
                closable={false}


            >
                <div className={'__smssent'}>
                    <Row style={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }} gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                            <Card
                                title={<FormattedMessage defaultMessage={'SMS'} />}
                                extra={[
                                    <Space key={`smsSent-sms-card-extras`}>
                                        <CircleButton placement={"bottomRight"} icon={<CloseOutlined />} title={intl.formatMessage({ defaultMessage: 'Close SMS' })} onClick={() => props.setSelectedSMSSent(undefined)}></CircleButton>
                                    </Space>
                                ]}
                            >
                                <>
                                    <Row gutter={[10, 10]}>
                                        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                            <Card
                                                title={<>{genStatus()}<span> {moment(props.sms.date).utc(true).format(getFormat('DATE_SHORT_AND_TIME'))} - {props.sms.sender}</span> </>}
                                                extra={[
                                                ]}
                                            >
                                                {props.sms.to !== undefined && props.sms.to !== null && props.sms.to !== "" ? <div className="sms-account-details"><span className="sms-account-details-title"><AiOutlinePhone className='anticon' /> </span><span className="sms-account-details-content ">{props.sms.to}</span></div> : null}
                                                {props.sms.text !== undefined && props.sms.text !== null && props.sms.text !== "" ? <div className="sms-account-details"><span className="sms-account-details-title"><MessageOutlined /> </span><span className="sms-account-details-content " style={{ whiteSpace: 'pre-wrap', overflowX: "auto", wordWrap: "break-word" }}>{props.sms.text}</span></div> : null}

                                            </Card>
                                        </Col>
                                        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                            <Row style={{ textAlign: "center" }} gutter={[10, 10]}>
                                                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                                    <Card
                                                        title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Status'} /></span>}
                                                        extra={[
                                                        ]}
                                                    >
                                                        <div><span>{genStatus(true)}</span></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                                    <Card
                                                        title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Cost'} /></span>}
                                                        extra={[
                                                        ]}
                                                    >
                                                        <div><span>{props.sms.credit}</span></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                                    <Card
                                                        title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Number of links'} /></span>}
                                                        extra={[
                                                        ]}
                                                    >
                                                        <div><span>{props.sms.links ? props.sms.links.length : 0}</span></div>
                                                    </Card>
                                                </Col>
                                                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                                                    <Card
                                                        title={<span className="sms-account-details-card-title"><FormattedMessage defaultMessage={'Number of replies'} /></span>}
                                                        extra={[
                                                        ]}
                                                    >
                                                        <div><span>{props.sms.response.length}</span></div>
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} xxl={{ span: 24 }}>
                            <Tabs
                                type={"card"}
                                style={{ textAlign: 'initial', width: "100%" }}
                                centered animated
                                onChange={onChangeTab}
                                activeKey={selectedTab}
                            >
                                <Tabs.TabPane key={"links"} tab={
                                    <span>
                                        <FormattedMessage defaultMessage={'Links'} />
                                    </span>
                                }
                                >
                                    {props.sms.links && props.sms.links.length > 0 ?
                                        <Collapse
                                            accordion={true}
                                            style={{ textAlign: 'initial', width: "100ve" }}
                                        >
                                            {props.sms.links.map((link) => {
                                                return (
                                                    <Collapse.Panel style={{ textAlign: 'initial', width: "100%" }} key={link.id}
                                                        header={
                                                            <Row style={{ textAlign: 'initial', width: "100%" }} gutter={[10, 10]}>
                                                                <Col xs={{ span: 8 }}>
                                                                    {link.creation_date}
                                                                </Col>
                                                                <Col xs={{ span: 8 }}>
                                                                    <FormattedMessage defaultMessage={'Url: {link}'} values={{ url: link.url }} />
                                                                </Col>
                                                                <Col xs={{ span: 8 }}>
                                                                    <FormattedMessage defaultMessage={'{count, plural, one {1 event} other {{count} events}}'} values={{ count: link.clicked.length }} />
                                                                </Col>
                                                            </Row>
                                                        }>
                                                        <Table
                                                            //tableLayout={"fixed"}
                                                            className={"__logistic-object-value-list"}
                                                            rowKey={(i: FullSMSSentLinksClicked) => i.id !== undefined ? i.id : -1}
                                                            dataSource={link.clicked}
                                                            columns={SMSSentLinksClickedColumns}
                                                            pagination={{
                                                                defaultPageSize: 8,
                                                                pageSizeOptions: ["8", "16", "32", "64", "72"],
                                                                showSizeChanger: true,
                                                                hideOnSinglePage: true,
                                                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} links'} values={{ range0: range[0], range1: range[1], total }} />
                                                            }}
                                                        />
                                                    </Collapse.Panel>
                                                )

                                            })}
                                        </Collapse>
                                        :
                                        <Empty style={{ backgroundColor: "white", borderRadius: "var(--border-radius)", border: "1px solid #c6c6c6", padding: "10px" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }



                                </Tabs.TabPane>
                                <Tabs.TabPane style={{ paddingLeft: "10px", paddingRight: "10px" }} key={"responses"} tab={
                                    <span>
                                        <FormattedMessage defaultMessage={'Replies'} />
                                    </span>
                                }
                                >
                                    <Table
                                        //tableLayout={"fixed"}
                                        className={"__logistic-object-value-list"}
                                        rowKey={(i: FullSMSSentResponse) => i.id !== undefined ? i.id : -1}
                                        dataSource={props.sms.response}
                                        columns={SMSSentResponseColumns}
                                        pagination={{
                                            defaultPageSize: 8,
                                            pageSizeOptions: ["8", "16", "32", "64", "72"],
                                            showSizeChanger: true,
                                            hideOnSinglePage: true,
                                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} responses'} values={{ range0: range[0], range1: range[1], total }} />
                                        }}
                                    />

                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </Drawer>
        </>
    )
}
export default SMSSent;