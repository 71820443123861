import { CloseOutlined, DollarCircleOutlined, EditOutlined, GlobalOutlined, HomeOutlined, LockOutlined, PhoneOutlined, SearchOutlined, ShopOutlined, ShoppingCartOutlined, TranslationOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, Empty, InputNumber, Modal, Row, Space, Switch, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import toFinite from 'lodash/toFinite'
import moment from 'moment'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bcCountries, bcLanguages, CHF_FORMAT, MOMENT_FORMAT_DISPLAY } from '../../utils/constants'
import getFormat from '../../utils/Lang'
import Network from '../../utils/network'
import { Company, CustomerLoyalty, SchoolClient } from '../../utils/types/generalTypes'
import { ApplicationState } from '../../utils/types/storeTypes'
import { alert, showNotification } from '../../utils/utils'
import { IntlProps } from '../app/LanguageProvider'
import CircleButton from '../common/fields/circleButton'
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField'
import Card from '../common/general/card'

interface State {
    clients: SchoolClient[];
    search: string;
    searchedClients: SchoolClient[] | undefined;
    selectedClient: SchoolClient | undefined;
    selectedCustomerLoyalty?: CustomerLoyalty;
    modifiedCustomerLoyalty?: CustomerLoyalty;
    currentPage: number;
    loading: boolean;
    loadingSearch: boolean;
    total: number;
    pageSize: number;
    searchedTotal: number;
    onlyFromSkiResort: boolean;
    openUpdatePasswordModal: boolean;
    clientNewPassword: string;
    clientPasswordId?: number;
    errorPassword: boolean;
}
interface IProps {
    companyDetails?: Company;
}

const minLengthClientPassword = 8;

type Props = IProps & IntlProps;

/**
 * Component for the clients tab in the CRM page
 */
class ClientsTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            clients: [],
            search: "",
            searchedClients: undefined,
            searchedTotal: 0,
            selectedClient: undefined,
            currentPage: 0,
            loading: false,
            loadingSearch: false,
            total: 0,
            pageSize: 15,
            onlyFromSkiResort: false,
            openUpdatePasswordModal: false,
            clientNewPassword: "",
            errorPassword: false,
        };
    }

    componentDidMount() {
        // // get total of school clients
        // Network.getTotalSchoolClients().then(
        //     response => this.setState({ total: response.total }),
        //     () => alert("Il y a eu un problème pendant le chargement des client.", "warning"),
        // );
        // get school clients
        this.changeClientsPage(1);
    }

    /**
     * Change the current clients page
     * @param page the new page number
     */
    changeClientsPage = (page: number, pageSize?: number, search?: boolean): void => {
        const { intl } = this.props;
        this.setState({ loading: true, loadingSearch: true });
        if (this.state.searchedClients || search) {
            const { search } = this.state;
            page -= 1; //because the page start from 0 and the antd table pagination at 1
            Network.getSchoolClients(page, search, pageSize !== undefined ? pageSize : this.state.pageSize, this.state.onlyFromSkiResort).then(
                response => {
                    this.setState({ searchedClients: response.users, searchedTotal: response.total, loading: false, loadingSearch: false, currentPage: page, pageSize: pageSize !== undefined ? pageSize : this.state.pageSize });
                    this.setState({});
                },
                () => {
                    alert(intl.formatMessage({ defaultMessage: 'An error occurred while executing the search' }), "warning");
                    this.setState({ loading: false, loadingSearch: false });
                },
            );
        } else {
            page -= 1; //because the page start from 0 and the antd table pagination at 1
            //get directory listing
            Network.getSchoolClients(page, undefined, pageSize !== undefined ? pageSize : this.state.pageSize, this.state.onlyFromSkiResort).then(
                response => {
                    this.setState({ clients: response.users, total: response.total, loading: false, loadingSearch: false, currentPage: page, pageSize: pageSize !== undefined ? pageSize : this.state.pageSize });
                },
                () => {
                    alert(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers' }), "warning");
                    this.setState({ loading: false, loadingSearch: false });
                },
            );
        }
    }

    changePageSize = (current: number, size: number): void => {
        this.changeClientsPage(1, size);
    }

    /**
     * Search clients
     * @param event the triggered event
     */
    searchClients = () => {
        this.changeClientsPage(1, undefined, true);
    }

    /**
     * Open the client details modal
     * @param client the client for which display the details
     */
    openModal = (client: SchoolClient): void => {
        const modal = Modal.info({});
        const loyalty = client.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
        const language = bcLanguages.find(l => l.Id === client.language)?.Text;
        const country = bcCountries.find(l => l.Id === client.country)?.Text;
        modal.update({
            maskClosable: true,
            icon: null,
            onOk: () => modal.destroy(),
            title: <p>{`${client?.user.last_name} ${client?.user.first_name}`}</p>,
            content: (
                <div>
                    {client.language &&
                        <div className="crm-section">
                            <TranslationOutlined className="crm-section-icon" />
                            <p>{language ? this.props.intl.formatMessage(language) : ''}</p>
                        </div>
                    }
                    {client.country &&
                        <div className="crm-section">
                            <GlobalOutlined className="crm-section-icon" />
                            <p>{country ? this.props.intl.formatMessage(country) : ''}</p>
                        </div>
                    }
                    {client.address &&
                        <div className="crm-section">
                            <HomeOutlined className="crm-section-icon" />
                            <p>{`${client.address}${client.zip ? `, ${client.zip}` : ''}${client.town ? `, ${client.town}` : ''}`}</p>
                        </div>
                    }
                    {
                        client.phone &&
                        <div className="crm-section">
                            <PhoneOutlined className="crm-section-icon" />
                            <p>{client.phone}</p>
                        </div>
                    }
                    {
                        loyalty &&
                        <div className="crm-section">
                            <DollarCircleOutlined className="crm-section-icon" />
                            <p>{`${loyalty.points} point${loyalty.points > 1.0 ? 's' : ''}`}</p>
                        </div>
                    }
                    {
                        loyalty &&
                        <div className="crm-section">
                            <ShoppingCartOutlined className="crm-section-icon" />
                            <p>{CHF_FORMAT.format(loyalty.totalSpending)}</p>
                        </div>
                    }
                    {
                        client.never_logged_in &&
                        <div className="crm-section">
                            <p>{this.props.intl.formatMessage({ defaultMessage: 'This user has not yet logged into the online sales platform.' })}</p>
                        </div>
                    }
                    <div className="crm-section">
                        <p>
                            {
                                client.come_from_ski_resort_counter ?
                                    this.props.intl.formatMessage({ defaultMessage: 'This account was added at a counter.' })
                                    :
                                    this.props.intl.formatMessage({ defaultMessage: 'This account was added on the online sales platform.' })
                            }
                        </p>
                    </div>
                </div>
            ),
        });
    }

    columnsActions = (record: SchoolClient) => {
        const { intl } = this.props;
        return [
            <Space direction="horizontal" key={`clientsTab-columns-action-${record.Id}`}>
                <CircleButton
                    key='company-clients-tab-table-actions'
                    className="__card-button-space"
                    icon={<EditOutlined />}
                    title={intl.formatMessage({ defaultMessage: 'Edit loyalty points' })}
                    placement="left"
                    onClick={(e) => {
                        e.stopPropagation();
                        const loyalty = record.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                        if (loyalty) {
                            this.setState({ modifiedCustomerLoyalty: { ...loyalty }, selectedCustomerLoyalty: loyalty });
                        } else if (this.props.companyDetails) {
                            this.setState({ selectedCustomerLoyalty: { userId: record.user.id, id: -1, points: 0, totalSpending: 0, companyId: this.props.companyDetails.id } }, () => {
                                this.setState({ modifiedCustomerLoyalty: this.state.selectedCustomerLoyalty })
                            });
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the customers data' }), "error")
                        }
                    }}
                />
                <CircleButton
                    title={intl.formatMessage({ defaultMessage: 'Edit password' })}
                    icon={<span style={{ position: 'relative' }}><LockOutlined /><EditOutlined className="team-create-user-password" /></span>}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ openUpdatePasswordModal: true, clientPasswordId: record.user.id })
                    }} />
            </Space>
        ]
    }

    saveCustomerLoyalty = () => {
        const { modifiedCustomerLoyalty } = this.state;
        const { intl } = this.props;
        modifiedCustomerLoyalty && Network.saveCustomerLoyalty(modifiedCustomerLoyalty).then(
            response => {
                if (response.id) {
                    const { selectedCustomerLoyalty } = this.state;
                    if (selectedCustomerLoyalty) {
                        selectedCustomerLoyalty.points = modifiedCustomerLoyalty.points;
                        selectedCustomerLoyalty.totalSpending = modifiedCustomerLoyalty.totalSpending
                    }
                    this.setState({ selectedCustomerLoyalty: selectedCustomerLoyalty }, () => this.setState({ selectedCustomerLoyalty: undefined, modifiedCustomerLoyalty: undefined }));
                    showNotification(intl.formatMessage({ defaultMessage: 'The loyalty points has been successfully updated' }), "success");
                } else if (response.errorCode === "cusloyadm-100") {
                    showNotification(intl.formatMessage({ defaultMessage: 'Please check the data' }), "warning");
                } else if (response.errorCode === "cusloyadm-101") {
                    showNotification(intl.formatMessage({ defaultMessage: 'Nonexistent loyalty points (sync)' }), "warning");
                } else if (response.errorCode === "cusloyadm-102") {
                    showNotification(intl.formatMessage({ defaultMessage: 'Customer temporarily unavailable' }), "warning");
                } else if (response.errorCode === "cusloyadm-103") {
                    showNotification(intl.formatMessage({ defaultMessage: 'School temporarily unavailable' }), "warning");
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the loyalty points' }), "error");
                }
            },
            () => {
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the loyalty points' }), "error");
            },
        );
    }

    changeOnlyFromSkiResort = () => {
        this.setState({ onlyFromSkiResort: !this.state.onlyFromSkiResort }, () => {
            this.changeClientsPage(1);
        })
    }

    /**
     * Update the password
     */
    updatePassword = () => {
        const { intl } = this.props;
        if (this.state.clientPasswordId) {
            if (this.state.clientNewPassword.length >= minLengthClientPassword) {
                Network.userUpdatePassword(this.state.clientPasswordId, this.state.clientNewPassword).then(
                    () => {
                        this.setState({ openUpdatePasswordModal: false, clientNewPassword: "", clientPasswordId: undefined, errorPassword: false });
                        showNotification(intl.formatMessage({ defaultMessage: 'The password has been successfully updated' }), "success");
                    },
                    () => {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the password' }), "warning")
                    },
                );
            } else {
                this.setState({ errorPassword: true })
            }
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'User not found' }), "error")
        }

    }

    render() {
        const { selectedCustomerLoyalty } = this.state;
        const { intl } = this.props;
        return (
            <Card icon={<ShopOutlined />} title={<FormattedMessage defaultMessage={'Online customers'} />}>
                <Row gutter={[10, 10]} className="__filter-to-table">
                    <Col>
                        <div className="crm-search">
                            <Space direction="horizontal">
                                <InputField
                                    value={this.state.search}
                                    className="crm-search-field"
                                    onPressEnter={this.searchClients}
                                    onChange={(e: InputFieldOnChangeEvent) => this.setState({ search: e.target.value })} />
                                <CircleButton
                                    icon={<SearchOutlined />}
                                    title={intl.formatMessage({ defaultMessage: 'Search' })}
                                    small
                                    loading={this.state.loadingSearch || this.state.loading}
                                    onClick={this.searchClients} disabled={this.state.search.length === 0} />
                                {this.state.searchedClients &&
                                    <CircleButton
                                        small
                                        icon={<CloseOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                        onClick={() => this.setState({ searchedClients: undefined, search: "" })} />}

                            </Space>
                        </div>
                    </Col>
                    <Col className="__center-vertically">
                        <p><Switch checked={this.state.onlyFromSkiResort} onChange={this.changeOnlyFromSkiResort} style={{ marginRight: '5px' }} /><FormattedMessage defaultMessage={'Only users added at the counter'} /></p>
                    </Col>
                    {/* <Col className="__center-vertically">
                        <p><Switch defaultChecked style={{ marginRight: '5px' }} />Afficher les archivés? </p>
                    </Col> */}
                </Row>
                <Table
                    className='__poi-list __crm-clients-table'
                    rowKey={(u: any) => `crm-client-${u.user.id}`}
                    columns={this.clientsColumns}
                    dataSource={this.state.searchedClients ? this.state.searchedClients : this.state.clients.map(c => c)}
                    loading={this.state.loading}
                    scroll={{ x: 'max-content', y: "800px" }}
                    onRow={record => ({
                        onClick: () => this.openModal(record),
                    })}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No customer'} />} /> }}
                    pagination={{
                        current: this.state.currentPage + 1,
                        pageSize: this.state.pageSize,
                        hideOnSinglePage: true,
                        total: this.state.searchedClients ? this.state.searchedTotal : this.state.total,
                        onChange: this.changeClientsPage,
                        onShowSizeChange: this.changePageSize,
                        showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} users'} values={{ range0: range[0], range1: range[1], total }} />
                    }} />

                <Modal
                    width="400px"
                    open={this.state.modifiedCustomerLoyalty !== undefined}
                    title={<FormattedMessage defaultMessage={'Edit loyalty points'} />}
                    onOk={undefined}
                    onCancel={() => this.setState({ modifiedCustomerLoyalty: undefined })}
                    footer={[
                        <Button key={`clientsTab-modal-cancel`} onClick={() => this.setState({ modifiedCustomerLoyalty: undefined })}>
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button
                            key={`clientsTab-modal-modify`}
                            onClick={this.saveCustomerLoyalty}
                        // loading={this.state.loading}
                        // disabled={!this.state.email || !this.state.email.includes("@")}
                        >
                            <FormattedMessage defaultMessage={'Edit'} />
                        </Button>
                    ]}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'Total spending'} /></Col>
                        <Col xs={{ span: 12 }}>
                            <InputNumber
                                style={{ width: '100%', minWidth: '100%' }}
                                onChange={(e) => {
                                    const { modifiedCustomerLoyalty } = this.state;
                                    modifiedCustomerLoyalty && (modifiedCustomerLoyalty.totalSpending = toFinite(e));
                                    this.setState({ modifiedCustomerLoyalty });
                                }}
                                value={this.state.modifiedCustomerLoyalty?.totalSpending} />
                        </Col>
                        <Col xs={{ span: 12 }}><FormattedMessage defaultMessage={'Loyalty points'} /></Col>
                        <Col xs={{ span: 12 }}>
                            <InputNumber
                                style={{ width: '100%', minWidth: '100%' }}
                                onChange={(e) => {
                                    const { modifiedCustomerLoyalty } = this.state;
                                    modifiedCustomerLoyalty && (modifiedCustomerLoyalty.points = toFinite(e));
                                    this.setState({ modifiedCustomerLoyalty });
                                }}
                                value={this.state.modifiedCustomerLoyalty?.points} />
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Collapse
                                className="__collapse-background-transparent"
                                // bordered={false}
                                style={{ textAlign: "initial" }}
                                accordion={true}>
                                <Collapse.Panel header={<FormattedMessage defaultMessage={'History of the last modification'} />} key="1">
                                    <Row gutter={[10, 10]}>
                                        {/* <Col xs={{ span: 24 }}>
                                    <h3>Valeur actuelle:</h3>
                                    <p>Dépenses totales: {this.state.selectedCustomerLoyalty?.totalSpending}</p>
                                    <p>Points de fidélité: {this.state.selectedCustomerLoyalty?.points}</p>
                                </Col> */}
                                        {
                                            selectedCustomerLoyalty?.modifiedUser ?
                                                selectedCustomerLoyalty?.beforeModifiedPoints ?
                                                    <Col xs={{ span: 24 }}>
                                                        <Row gutter={[10, 10]}>
                                                            <Col xs={{ span: 6 }}>
                                                                <p><b><FormattedMessage defaultMessage={'Date'} />{':'}</b></p>
                                                                <p><b><FormattedMessage defaultMessage={'Admin'} />{':'}</b></p>
                                                            </Col>
                                                            <Col xs={{ span: 18 }}>
                                                                <p>{moment(selectedCustomerLoyalty.modified).format(getFormat('DATE_AND_ON_TIME'))}</p>
                                                                <p>{selectedCustomerLoyalty?.modifiedUser}</p>
                                                            </Col>
                                                            <Col xs={{ span: 24 }} >
                                                                <p><b><FormattedMessage defaultMessage={'Values before modification'} />{':'}</b></p>
                                                                <Row gutter={[0, 0]}>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p><FormattedMessage defaultMessage={'Total spending'} />{':'}</p>
                                                                        <p><FormattedMessage defaultMessage={'Loyalty points'} />{':'}</p>
                                                                    </Col>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p>{this.state.selectedCustomerLoyalty?.beforeModifiedTotalSpending}</p>
                                                                        <p>{this.state.selectedCustomerLoyalty?.beforeModifiedPoints}</p>
                                                                    </Col>
                                                                </Row>
                                                                <p style={{ marginTop: '10px' }}><b><FormattedMessage defaultMessage={'Values after modification'} />{':'}</b></p>
                                                                <Row gutter={[0, 0]}>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p><FormattedMessage defaultMessage={'Total spending'} />{':'}</p>
                                                                        <p><FormattedMessage defaultMessage={'Loyalty points'} />{':'}</p>
                                                                    </Col>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p>{this.state.selectedCustomerLoyalty?.afterModifiedTotalSpending}</p>
                                                                        <p>{this.state.selectedCustomerLoyalty?.afterModifiedPoints}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    :
                                                    <Col xs={{ span: 24 }}>
                                                        <Row gutter={[10, 10]}>
                                                            <Col xs={{ span: 24 }}>
                                                                <p><FormattedMessage defaultMessage={'This is the first modification'} /></p>
                                                            </Col>
                                                            <Col xs={{ span: 6 }}>
                                                                <p><b><FormattedMessage defaultMessage={'Date'} />{':'}</b></p>
                                                                <p><b><FormattedMessage defaultMessage={'Admin'} />{':'}</b></p>
                                                            </Col>
                                                            <Col xs={{ span: 18 }}>
                                                                <p>{moment(selectedCustomerLoyalty.modified).format(MOMENT_FORMAT_DISPLAY)}</p>
                                                                <p>{selectedCustomerLoyalty?.modifiedUser}</p>
                                                            </Col>
                                                            <Col xs={{ span: 24 }}>
                                                                <p><b><FormattedMessage defaultMessage={'New values'} />{':'}</b></p>
                                                                <Row gutter={[0, 0]}>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p><FormattedMessage defaultMessage={'Total spending'} />{':'}</p>
                                                                        <p><FormattedMessage defaultMessage={'Loyalty points'} />{':'}</p>
                                                                    </Col>
                                                                    <Col xs={{ span: 12 }}>
                                                                        <p>{this.state.selectedCustomerLoyalty?.afterModifiedTotalSpending}</p>
                                                                        <p>{this.state.selectedCustomerLoyalty?.afterModifiedPoints}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                :
                                                <Col xs={{ span: 24 }}>
                                                    <p><FormattedMessage defaultMessage={'No modifications has been made'} /></p>
                                                </Col>
                                        }
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title={<FormattedMessage defaultMessage={'Edit password'} />}
                    visible={this.state.openUpdatePasswordModal}
                    okText={<FormattedMessage defaultMessage={'Save'} />}
                    onOk={this.updatePassword}
                    cancelButtonProps={{ type: 'dashed' }}
                    onCancel={() => this.setState({ openUpdatePasswordModal: false, clientNewPassword: "", clientPasswordId: undefined })}>
                    <div>
                        <InputField
                            error={this.state.errorPassword ? intl.formatMessage({ defaultMessage: 'At least {min} characters' }, { min: minLengthClientPassword }) : undefined}
                            minLength={minLengthClientPassword}
                            value={this.state.clientNewPassword}
                            type="password"
                            placeholder={intl.formatMessage({ defaultMessage: 'New password' })}
                            onChange={(e: InputFieldOnChangeEvent) => this.setState({ clientNewPassword: e.target.value, errorPassword: false })}
                            onPressEnter={this.updatePassword} />
                    </div>
                </Modal>
            </Card>
        )
    }

    clientsColumns: ColumnProps<SchoolClient>[] = [
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            dataIndex: ['user', 'last_name'],
            key: 'last_name',
            fixed: 'left',
            sorter: (a: SchoolClient, b: SchoolClient) => a.user.last_name.localeCompare(b.user.last_name),
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            dataIndex: ['user', 'first_name'],
            key: 'first_name',
            sorter: (a: SchoolClient, b: SchoolClient) => a.user.first_name.localeCompare(b.user.first_name),
        },
        {
            title: <FormattedMessage defaultMessage={'Email'} />,
            dataIndex: ['user', 'email'],
            key: 'email',
            sorter: (a: SchoolClient, b: SchoolClient) => a.user.email.localeCompare(b.user.email),
        },
        {
            title: <FormattedMessage defaultMessage={'Total spending'} />,
            key: 'totalSpending',
            width: '180px',
            render: (sc: SchoolClient) => {
                const loyalty = sc.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                return loyalty ? CHF_FORMAT.format(loyalty.totalSpending) : undefined;
            },
            sorter: (a: SchoolClient, b: SchoolClient) => {
                const loyaltyA = a.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                const loyaltyB = b.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                if (loyaltyA === undefined && loyaltyB === undefined) {
                    return 0;
                } else if (loyaltyA === undefined) {
                    return 1;
                } else if (loyaltyB === undefined) {
                    return -1;
                } else {
                    if (loyaltyA.totalSpending > loyaltyB.totalSpending) {
                        return -1;
                    } else if (loyaltyA.totalSpending < loyaltyB.totalSpending) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Loyalty points'} />,
            key: 'points',
            width: '180px',
            render: (sc: SchoolClient) => {
                const loyalty = sc.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                return loyalty ? loyalty.points : undefined;
            },
            sorter: (a: SchoolClient, b: SchoolClient) => {
                const loyaltyA = a.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                const loyaltyB = b.customerLoyalty?.find(c => c.companyId === this.props.companyDetails?.id);
                if (loyaltyA === undefined && loyaltyB === undefined) {
                    return 0;
                } else if (loyaltyA === undefined) {
                    return 1;
                } else if (loyaltyB === undefined) {
                    return -1;
                } else {
                    if (loyaltyA.points > loyaltyB.points) {
                        return -1;
                    } else if (loyaltyA.points < loyaltyB.points) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            },
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '160px',
            fixed: 'right',
            // className: '__poi-actions',
            render: (record: SchoolClient) => {
                return (
                    this.columnsActions(record)
                    // <SpeedDial
                    //     key='company-product-actions-speeddial'
                    //     title={"Actions"}
                    //     icon={<MoreOutlined />}
                    //     openIcon={<DashOutlined />}
                    //     buttons={this.poiColumnsActions(record)} />
                );
            },
        }
    ];
}
const mapStateToProps = (state: ApplicationState) => ({
    companyDetails: state.crm.companyDetails,
});

export default connect(mapStateToProps)(injectIntl(ClientsTab));