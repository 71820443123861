import { Col, Divider, InputNumber, Row, Select, Switch } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import Network from '../../../utils/network';
import { Course, Group, POI, Sector } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import InputField from '../../common/fields/inputField';

type ReduxProps = ConnectedProps<typeof connector>;
interface IProps {
    isSmartphone: boolean;
    poiEdit: POI;
    groups: Group[] | undefined;
    setPoiEdit: (poi: POI) => void;
    fromMap?: boolean;
    primaryPoi?: POI;
}

type Props = IProps & ReduxProps;

interface State {
    sectors: Sector[],
    courses: Course[],

}

class PoiEditForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sectors: [],
            courses: [],
        }
    }

    componentDidMount() {
        Network.getSector().then(
            (response: Sector[]) => {
                const sectors = response;
                this.setState({ sectors });
            }
        )
        Network.getCourse("short").then(
            (response: Course[]) => {
                const courses = response;
                this.setState({ courses });
            }
        )
        const { poiEdit } = this.props;
        const poi = poiEdit;
        poi && poi.image && poi.image !== "" && (poi.image = "https://storage.googleapis.com/" + poi.image);
        this.props.setPoiEdit(poi);
    }

    render() {
        const { poiEdit, groups } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                <div style={{ width: '49%' }}>
                    <Row gutter={[10, 10]}>
                        {
                            poiEdit.isBasePoi &&
                            <>
                                <Col xs={{ span: 16 }}>
                                    <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Main location (headquarters)'} /></p>
                                </Col>
                                <Col xs={{ span: 8 }} style={{ display: "flex", justifyContent: 'flex-end' }}>
                                    <Switch
                                        onChange={checked => {
                                            const poi = poiEdit;
                                            poi && (poi.isPrimary = checked);
                                            this.props.setPoiEdit(poi);
                                        }}
                                        checked={poiEdit.isPrimary} />
                                </Col>
                            </>
                        }
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Title'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <InputField
                                onChange={(e: { target: { value: string | undefined; }; }) => {
                                    const poi = poiEdit;
                                    poi && (poi.title = e.target.value);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.title}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Address'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <InputField
                                onChange={(e: { target: { value: string | undefined; }; }) => {
                                    const poi = poiEdit;
                                    poi && (poi.address = e.target.value);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.address}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Longitude'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <InputNumber
                                onChange={(e) => {
                                    const poi = poiEdit;
                                    poi && (poi.longitude = e !== null ? e : undefined);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.longitude}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Latitude'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <InputNumber
                                onChange={(e) => {
                                    const poi = poiEdit;
                                    poi && (poi.latitude = e !== null ? e : undefined);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.latitude}
                                style={{ width: '100%', minWidth: '100%' }}
                            />
                        </Col>
                    </Row>
                </div>
                <Divider type={'vertical'} style={{ height: 'auto' }} />
                <div style={{ width: '49%' }}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Sectors'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <Select
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear
                                placeholder={<FormattedMessage defaultMessage={'Sectors to assign'} />}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    const poi = poiEdit;
                                    const sectors = this.state.sectors.filter(c => e.findIndex(id => id === c.id) !== -1)
                                    poi && (poi.sectors = sectors);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.sectors?.map(s => s.id!)}
                                filterOption={true}
                                optionFilterProp="label"
                                showArrow
                            >
                                {
                                    this.state.sectors.length > 0 &&
                                    this.state.sectors.map((g) => {
                                        return <Select.Option label={g.title} value={g.id!} key={"sectors-" + g.id}>{g.title}</Select.Option>
                                    })
                                }
                            </Select>
                        </Col>

                        <Col xs={{ span: 8 }}>
                            <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Groups'} />{':'}</p>
                        </Col>
                        <Col xs={{ span: 16 }}>
                            <Select
                                // disabled={this.props.loading}
                                mode="multiple"
                                maxTagCount="responsive"
                                allowClear
                                placeholder={<FormattedMessage defaultMessage={'Groups to assign'} />}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    const poi = poiEdit;
                                    const theGroups = groups?.filter(g => e.findIndex(id => id === g.id) !== -1)
                                    poi && (poi.groups = theGroups);
                                    this.props.setPoiEdit(poi);
                                }}
                                value={poiEdit?.groups?.map(g => g.id!)}
                                // value={this.state.selectGroups}
                                // onChange={this.changeGroup}
                                filterOption={true}
                                optionFilterProp="label"
                                showArrow
                            >
                                {
                                    groups && groups.length > 0 &&
                                    groups.map((g) => {
                                        return <Select.Option label={g.name} value={g.id!} key={"courses-" + g.id}>{g.name}</Select.Option>
                                    })
                                }
                            </Select>

                        </Col>
                        {this.props.departments && this.props.departments.data.length > 0 &&
                            <>
                                <Col xs={{ span: 8 }}>
                                    <p style={{ textAlign: 'left' }}><FormattedMessage defaultMessage={'Department'} />{':'}</p>
                                </Col>
                                <Col xs={{ span: 16 }}>
                                    <Select
                                        style={{ width: '100%' }}
                                        //className="team-create-user-inputs"
                                        showSearch
                                        allowClear
                                        //onChange={(e: number) => this.onChangeDepartment(e)}
                                        onChange={(e) => {
                                            const poi = poiEdit;
                                            const department = this.props.departments.data.find(g => g.id === e)
                                            poi && (poi.department = department);
                                            this.props.setPoiEdit(poi);
                                        }}
                                        placeholder={<FormattedMessage defaultMessage={'Department'} />}
                                        value={poiEdit.department?.id}
                                        filterOption={true}
                                        optionFilterProp="label">
                                        {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                    </Select>
                                </Col>
                            </>
                        }

                        {/* {this.props.fromMap !== true &&
                    <Col xs={{ span: 24 }}>
                        <ImageUploader
                            imageWithoutHttp={true}
                            className="image-uploader-full-width"
                            image={poiEdit.image}
                            onUploaded={(e) => {
                                const poi = poiEdit;
                                poi && (poi.image = e);
                                this.props.setPoiEdit(poi);
                            }}
                        />
                    </Col>} */}

                    </Row>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    groups: state.teamManagement.groups,
    departments: state.configurations.departments
});

const connector = connect(mapStateToProps);
export default connector(PoiEditForm);