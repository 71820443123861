import { Popover, Switch } from 'antd';
import moment, { Moment } from 'moment';
import { CSSProperties, useCallback, useMemo, type FC } from 'react';
import { BsCalendar3Event } from 'react-icons/bs';
import { RiTimerFlashLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { RED_COLOR } from '../../../../../utils/constants';
import getFormat from '../../../../../utils/Lang';
import { BreakTime } from '../../../../../utils/types/planningTypes';
import FAIcon from '../../../../common/FAIcon';
import Anticon from '../../../../common/general/anticon';
import { breaktimeError } from '../editEventClockedModal';

export const ItemTypes = {
    BOX: 'box',
};

export interface BreaktimeObject {
    breakTime: BreakTime;
    onActiveChange: (checked: boolean, breakTimeId: number) => void;
    isActive: boolean;
    error: breaktimeError | 'NONE';
    dateBounds: { from?: Moment; to?: Moment; }; // Show the full date if outside this bound 
}

export const BreaktimeObject: FC<BreaktimeObject> = (props) => {
    const intl = useIntl();
    const rowStyle: CSSProperties = useMemo(() => ({
        padding: '5px 10px',
        display: 'grid',
        gridTemplateColumns: '.4fr 2fr 3fr 1fr .7fr',
        alignItems: 'flex-start',
        justifyContent: 'center',
        textAlign: 'left',
        fontStyle: props.isActive ? 'normal' : 'italic',
        color: props.isActive ? 'black' : '#a1a1a1',
    }), [props.isActive]);

    const getErrorText = useCallback(() => {
        switch (props.error) {
            case 'OUT_OF_EVENT_BOUNDS':
                return <><FormattedMessage defaultMessage={'The break is outside event hours'} /></>;
            case 'NONE':
            default:
                return <></>;
        }
    }, [props.error]);

    const isWithinBounds = useCallback(() => {
        if (props.dateBounds.from && props.dateBounds.from.isBefore(props.breakTime.startDate, 'date'))
            return false;

        if (props.dateBounds.to && props.dateBounds.to.isAfter(props.breakTime.endDate, 'date'))
            return false;

        return true;
    }, [props.breakTime, props.dateBounds]);

    const renderBreakContent = useCallback(() => {
        return (
            <div style={rowStyle}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                    <span>{props.breakTime.isClocked ? <Anticon icon={<RiTimerFlashLine title={intl.formatMessage({ defaultMessage: 'Clocked break' })} />} /> : <Anticon icon={<BsCalendar3Event title={intl.formatMessage({ defaultMessage: 'Planned break' })} />} />}</span>
                    <span>{props.breakTime.isPaid ? <FAIcon prefix='far' name={'sack-dollar'} /> : <></>}</span>
                </div>
                <div>
                    {props.breakTime.title}
                </div>
                <div>
                    {moment(props.breakTime.startDate).format(isWithinBounds() ? getFormat('TIME') : getFormat('DATE_SHORT_AND_TIME'))}
                    &nbsp;-&nbsp;
                    {moment(props.breakTime.endDate).format(isWithinBounds() ? getFormat('TIME') : getFormat('DATE_SHORT_AND_TIME'))}
                    {props.error !== 'NONE'
                        ? <Popover trigger={'hover'} content={getErrorText()} placement='bottom'>
                            &nbsp;<FAIcon prefix="fas" name="triangle-exclamation" color={RED_COLOR} />
                        </Popover>
                        : <></>
                    }
                </div>
                <div>
                    {moment.duration(props.breakTime.totalHours, 'hours').format('mm')} min
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <Switch checked={props.isActive} onChange={(checked) => props.onActiveChange(checked, props.breakTime.id)} />
                </div>
            </div>
        );
    }, [rowStyle, props, intl, isWithinBounds, getErrorText]);

    return (
        <div style={{ width: '100%', }}>
            {renderBreakContent()}
        </div>
    );
};
