import { DashOutlined, DeleteOutlined, MoreOutlined, PlusOutlined, TagsOutlined } from '@ant-design/icons';
import { Empty, Popconfirm, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAllCodesDiscount } from '../../../actions/products';
import { changeCompanyCodesDiscount } from '../../../store/actions/crm';
import axiosapi from '../../../utils/axiosapi';
import { CodeDiscount, Company, CompanyLevel, CompanyProduct, RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState, CRMDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import SpeedDial from '../../common/fields/speedDial';
import Card from '../../common/general/card';
import { tableColumnTextFilterConfig } from '../../courseManagement/tableSearch';
import CodeDiscountCreateEditModal from './codeDiscountCreateEditModal';
import CodeDiscountShowModal from './codeDiscountShowModal';

interface IProps {
    isSmartphone: boolean;
    companyDetails?: Company;
    loadingCompanyLevels: boolean;
    companyLevels: CompanyLevel[];
    companyCodeDiscounts?: CodeDiscount[];
    loadingCompanyCodeDiscounts: boolean;
    companyProducts: CompanyProduct[];
    loadingCompanyProducts: boolean;
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & CRMDispatchProps & IntlProps;

interface State {
    codeDiscountToShow?: CodeDiscount;
    codeDiscountToEdit?: CodeDiscount;
}

class CodeDiscountTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.companyDetails !== undefined) {
            this.props.dispatch(fetchAllCodesDiscount({ school__external_id: this.props.companyDetails.int_id }, "fr"));
        }

    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.companyDetails && !prevProps.companyDetails) {
            this.props.dispatch(fetchAllCodesDiscount({ school__external_id: this.props.companyDetails.int_id }, "fr"));
        }
    }

    updateCodeDiscount = (codeDiscountToEdit?: CodeDiscount) => {
        this.setState({ codeDiscountToEdit })
    }

    codeDiscountOnRow = (record: CodeDiscount) => ({
        onClick: () => this.setState({ codeDiscountToShow: record })
    });

    closeCodeDiscount = (codeDiscount?: CodeDiscount) => {
        const { intl } = this.props;
        if (codeDiscount) {
            if (codeDiscount.id > 0) {
                axiosapi.patch(`/code_discount/${codeDiscount.id}/`, {
                    code: codeDiscount.code,
                    expiryAt: codeDiscount.expiryAt,
                    nbOfTimes: codeDiscount.nbOfTimes,
                    amount: codeDiscount.amount ? codeDiscount.amount : null,
                    percent: codeDiscount.percent ? codeDiscount.percent : null,
                    levels: codeDiscount.levels,
                    lessons: codeDiscount.lessons,
                    lessonFrom: codeDiscount.lessonFrom,
                    lessonTo: codeDiscount.lessonTo,
                    lessonDurationDays: codeDiscount.lessonDurationDays,
                    lessonDurationHours: codeDiscount.lessonDurationHours,
                    school: { "externalId": this.props.companyDetails?.int_id, "departmentId": this.props.companyDetails?.department_id }
                }, {
                    headers: { "accept-language": "fr" }
                }).then(response => {
                    const newCodeDiscount: CodeDiscount = response.data;
                    const { companyCodeDiscounts } = this.props;
                    const theCodeDiscounts = companyCodeDiscounts?.map(cd => {
                        if (cd.id === newCodeDiscount.id) {
                            return newCodeDiscount;
                        } else {
                            return cd;
                        }
                    });
                    this.props.changeCompanyCodesDiscount!(theCodeDiscounts ? theCodeDiscounts : [])
                    this.setState({ codeDiscountToEdit: undefined, codeDiscountToShow: undefined }, () => showNotification(intl.formatMessage({ defaultMessage: 'The discount voucher has been successfully updated' }), "success"));
                }).catch(error => {
                    console.error("Update error : ", error);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the discount voucher' }), "error");
                });
            } else {
                axiosapi.post(`/code_discount/`, {
                    code: codeDiscount.code,
                    expiryAt: codeDiscount.expiryAt,
                    nbOfTimes: codeDiscount.nbOfTimes,
                    amount: codeDiscount.amount ? codeDiscount.amount : null,
                    percent: codeDiscount.percent ? codeDiscount.percent : null,
                    levels: codeDiscount.levels,
                    lessons: codeDiscount.lessons,
                    lessonFrom: codeDiscount.lessonFrom,
                    lessonTo: codeDiscount.lessonTo,
                    lessonDurationDays: codeDiscount.lessonDurationDays,
                    lessonDurationHours: codeDiscount.lessonDurationHours,
                    school: { "externalId": this.props.companyDetails?.int_id, "departmentId": this.props.companyDetails?.department_id }
                }, {
                    headers: { "accept-language": "fr" }
                }).then(response => {
                    const newCodeDiscount: CodeDiscount = response.data;
                    let codeDiscounts = this.props.companyCodeDiscounts ? [...this.props.companyCodeDiscounts] : undefined;
                    if (codeDiscounts) {
                        codeDiscounts.unshift(newCodeDiscount);
                    } else {
                        codeDiscounts = [newCodeDiscount];
                    }
                    this.props.changeCompanyCodesDiscount!(codeDiscounts);
                    this.setState({ codeDiscountToEdit: undefined, codeDiscountToShow: undefined }, () => showNotification(intl.formatMessage({ defaultMessage: 'The discount voucher has been successfully created' }), "success"));
                }).catch(error => {
                    console.error("Create error : ", error);
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the discount voucher' }), "error");
                });
            }

        } else {
            this.setState({ codeDiscountToEdit: undefined, codeDiscountToShow: undefined });

        }
    }



    columnActions = (record: CodeDiscount) => {
        const { intl } = this.props;
        return [
            <Popconfirm
                key={`sectorTab-action-popconfirm-${record.id}`}
                title={<FormattedMessage defaultMessage={'Do you want to permanently delete this discout voucher?'} />}
                onConfirm={(e) => {
                    e?.stopPropagation();
                    axiosapi.delete(`/code_discount/${record.id}/`, {
                        headers: { "accept-language": "fr" }
                    }).then(() => {
                        const { companyCodeDiscounts } = this.props;
                        const theCodeDiscounts = companyCodeDiscounts?.filter(cd => {
                            if (cd.id !== record.id) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                        this.props.changeCompanyCodesDiscount!(theCodeDiscounts ? theCodeDiscounts : [])
                        showNotification(intl.formatMessage({ defaultMessage: 'The discount voucher successfully deleted' }), "success");
                    }).catch(error => {
                        console.error("Error : ", error);
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the discount voucher' }), "error");
                    });
                }}
                onCancel={(e) => {
                    e?.stopPropagation();
                }}
                okText={<FormattedMessage defaultMessage={'Yes'} />}
                cancelText={<FormattedMessage defaultMessage={'No'} />}
            >
                <CircleButton
                    onClick={(e) => e.stopPropagation()}
                    key={`code-discount-action-delete-${record.id}`}
                    className="__card-button-space"
                    icon={<DeleteOutlined />}
                    title={intl.formatMessage({ defaultMessage: 'Delete permanently' })}
                    placement={'left'} />
            </Popconfirm>
            ,
        ]
    }

    render() {
        const { codeDiscountToEdit, codeDiscountToShow } = this.state;
        const { companyProducts, loadingCompanyProducts, companyLevels, loadingCompanyLevels, companyCodeDiscounts, intl } = this.props;
        return (
            <div>
                <Card icon={<TagsOutlined />} title={<FormattedMessage defaultMessage={'Discount vouchers'} />}
                    headerElements={[
                        <CircleButton
                            // small={true}
                            onClick={() => this.setState({ codeDiscountToShow: { id: -1, code: "" } })}
                            icon={<PlusOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Add a discount voucher' })}
                            placement="topLeft"
                            key="crm-code-discount-add" />
                    ]}>
                    <Table
                        // loading={this.state.isLoadingPois}
                        key={`table-company-codes-discount`}
                        className='__poi-list'
                        rowKey={(cd: CodeDiscount) => cd.id}
                        dataSource={companyCodeDiscounts}
                        columns={this.columns}
                        // loading={this.state.groupsLoading}
                        pagination={{
                            defaultPageSize: 16,
                            pageSizeOptions: ["8", "16", "24", "32", "64", "72"],
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} discount vouchers'} values={{ range0: range[0], range1: range[1], total }} />
                        }}
                        scroll={{ x: 'max-content' }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No discount voucher'} />} /> }}
                        onRow={this.codeDiscountOnRow}
                    // rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedPoi }}
                    />

                    <CodeDiscountCreateEditModal closeCodeDiscount={this.closeCodeDiscount} codeDiscount={codeDiscountToEdit} />
                    {
                        companyProducts && codeDiscountToShow &&
                        <CodeDiscountShowModal
                            loadingCompanyLevels={loadingCompanyLevels}
                            loadingCompanyProducts={loadingCompanyProducts}
                            companyLevels={companyLevels}
                            companyProducts={companyProducts}
                            closeCodeDiscount={this.closeCodeDiscount}
                            codeDiscount={codeDiscountToShow} />}
                </Card>
            </div>
        )
    }
    columns: ColumnProps<CodeDiscount>[] = [
        {
            title: <FormattedMessage defaultMessage={'Code'} />,
            dataIndex: 'code',
            key: 'code',
            onFilter: (value, record) => {
                return record.code!
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
            },
            ...tableColumnTextFilterConfig<CodeDiscount>(),
            sorter: (a: CodeDiscount, b: CodeDiscount) => !a.code ? -1 : !b.code ? 1 : a.code < b.code ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Amount'} />,
            dataIndex: 'amount',
            key: 'amount',
            width: '250px',
            responsive: ["xxl"],
            sorter: (a: CodeDiscount, b: CodeDiscount) => !a.amount ? -1 : !b.amount ? 1 : a.amount < b.amount ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Percentage'} />,
            dataIndex: 'percent',
            key: 'percent',
            width: '250px',
            responsive: ["xxl"],
            sorter: (a: CodeDiscount, b: CodeDiscount) => !a.percent ? -1 : !b.percent ? 1 : a.percent < b.percent ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Usage'} />,
            key: 'nbOfTimesUsed',
            width: '120px',
            render: (code: CodeDiscount) => <FormattedMessage defaultMessage={'{used} / {limit}'} values={{ used: code.nbOfTimesUsed, limit: code.nbOfTimes ? code.nbOfTimes : '∞' }} />
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            width: '130px',
            // className: '__poi-actions',
            render: (record: CodeDiscount) => {
                return (
                    this.props.isSmartphone ?
                        <SpeedDial
                            key='poi-actions-speeddial'
                            title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                            icon={<MoreOutlined />}
                            openIcon={<DashOutlined />}
                            buttons={this.columnActions(record)} />
                        :
                        this.columnActions(record)
                );
            },
        }
    ];
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    companyDetails: state.crm.companyDetails,
    companyProducts: state.crm.companyProducts,
    loadingCompanyProducts: state.crm.loadingCompanyProducts,
    loadingCompanyLevels: state.crm.loadingCompanyLevels,
    companyLevels: state.crm.companyLevels,
    loadingCompanyCodeDiscounts: state.crm.loadingCompanyCodeDiscounts,
    companyCodeDiscounts: state.crm.companyCodeDiscounts,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeCompanyCodesDiscount: (c: CodeDiscount[]) => dispatch(changeCompanyCodesDiscount(c)),
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CodeDiscountTab));