import { ConfigProvider } from "antd";
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import frFR from 'antd/lib/locale/fr_FR';
import React, { ReactChild } from "react";
import { withRouter } from "react-router-dom";
import { RouterProps } from "../../utils/types/generalTypes";

interface IProps extends RouterProps {
    children?: ReactChild;
}

interface State {
}

class CustomConfigProvider extends React.Component<IProps, State> {

    getLanguage = () => {
        switch (this.props.location.pathname.split("/")[1]) {
            case 'en':
                return enUS;
            case 'de':
                return deDE;
            case 'fr':
            default:
                return frFR;
        }
    };


    render() {
        return (
            <ConfigProvider locale={this.getLanguage()}>
                {this.props.children}
            </ConfigProvider>
        );
    }
}

export default withRouter(CustomConfigProvider);