import { ArrowLeftOutlined, FilterOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { BackTop, Badge, Breadcrumb, Button, Divider, Drawer, Layout, Tabs } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { CSSProperties } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleDisplayFilters } from '../../../store/actions/window';
import { ContainerTabsState, RouterProps, TabItem } from '../../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../fields/circleButton';
import SpaceContent from '../general/spaceContent';
import SideMenu, { checkScroll, Direction } from './sideMenu';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, IntlProps {
    breadcrumb: { title: string; link: string; }[];
    backTop?: boolean;

    contentClassName?: string;
    contentStyle?: CSSProperties;

    tabItems: TabItem[];
    tabExtra?: React.ReactNode;
    tabFilters?: React.ReactNode;
    tabFiltersActivated?: boolean;
    backUrl?: string;

}

interface State {
    openDrawer: boolean;
    hasFilterActivated: boolean;
}

class ContainerTabs extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            openDrawer: false,
            hasFilterActivated: false
        };
    }

    componentDidMount = () => {
        const anchor = document.getElementById('anchor');
        if (anchor) anchor.scrollIntoView();
    };

    toggleOpenDrawer = () => this.setState(prevState => ({ openDrawer: !prevState.openDrawer }));

    goTo = (link: string) => this.props.history.push(`/${this.props.match.params.lang}${link}`);

    buttonFilter = () => {
        const { hasFilterActivated } = this.state;
        const { displayFilters, toggleDisplayFilters, intl } = this.props;
        if (this.props.tabFilters) {
            return (
                <CircleButton
                    small
                    type={displayFilters ? "primary" : "default"}
                    icon={
                        <Badge dot={hasFilterActivated}>
                            <FilterOutlined />
                        </Badge>}
                    onClick={toggleDisplayFilters}
                    title={hasFilterActivated ? intl.formatMessage({ defaultMessage: 'Active filters' }) : intl.formatMessage({ defaultMessage: 'No active filters' })} />
            )
        } else {
            return null;
        }
    }

    render() {
        const { isTablet, isSmartphone, collapsed, tabExtra, tabItems, tabFilters, contentStyle, contentClassName, backTop, breadcrumb, containerHeight } = this.props;
        const { displayFilters, backUrl, toggleDisplayFilters } = this.props;
        const { Sider, Content } = Layout;
        const hasScroll = checkScroll(document.getElementById("container-tabs-content-wrapper"), Direction.Vertical);

        return (
            <>
                <Layout className="container-layout" style={{ marginLeft: isSmartphone ? '0px' : (collapsed ? '80px' : '320px') }}>
                    {
                        isSmartphone ?
                            null :
                            <Sider width="320" trigger={null} collapsible collapsed={collapsed} style={{ position: 'fixed', maxHeight: '100%', height: '100%', left: 0, zIndex: 2 }}>
                                <SideMenu />
                            </Sider>
                    }
                    <Layout className={`main-container`}>
                        {backTop ? <BackTop /> : null}
                        {
                            isSmartphone ?
                                <Header className="container-header">
                                    <Breadcrumb>
                                        {
                                            breadcrumb.map((item, idx) => {
                                                return (
                                                    <Breadcrumb.Item key={`breadcrumb-${item.title}-${idx}`}>
                                                        <span onClick={() => this.goTo(item.link)}>{item.title}</span>
                                                    </Breadcrumb.Item>
                                                )
                                            })
                                        }
                                    </Breadcrumb>
                                    <Button icon={<MenuFoldOutlined />} type="link" onClick={this.toggleOpenDrawer} style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={this.toggleOpenDrawer}
                                        open={this.state.openDrawer}>
                                        <SideMenu />
                                    </Drawer>
                                </Header>
                                : null
                        }
                        <Content style={contentStyle} id="container" className={`__new-container${displayFilters && !isTablet ? '-filters' : ''} container-content-background ${contentClassName ?? ''}`}>
                            <Tabs
                                tabBarStyle={{ paddingLeft: '5px', paddingRight: '5px' }}
                                animated
                                activeKey={this.props.match.params.tab}
                                onChange={(path) => {
                                    this.props.history.push(`${path}`);
                                }}
                                tabBarExtraContent={
                                    {
                                        left: (
                                            backUrl ? (
                                                <div style={{ marginRight: '10px' }}>
                                                    {
                                                        backUrl ?
                                                            <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/${this.props.match.params.lang}${backUrl}`)} />
                                                            : null
                                                    }
                                                </div>
                                            )
                                                : undefined
                                        ),
                                        right: (


                                            <SpaceContent>
                                                {tabExtra}
                                                {this.buttonFilter()}
                                            </SpaceContent>
                                        )
                                    }
                                }
                                items={tabItems.map(item => {
                                    return {
                                        ...item,
                                        children: (
                                            <div style={{ position: 'relative', paddingLeft: isSmartphone ? '5px' : undefined, height: `${isTablet ? (containerHeight - 45) : containerHeight}px`, maxHeight: `${isTablet ? (containerHeight - 45) : containerHeight}px`, overflow: 'hidden' }} className={`__container-tabs-main ${displayFilters ? 'filters' : 'nofilters'}`}>
                                                <div className={`__container-tabs-main-table-container ${displayFilters ? 'filters' : 'nofilters'}`}>
                                                    <div id='container-tabs-content-wrapper' style={{ height: `100%`, overflow: 'auto', overflowX: 'hidden', paddingRight: hasScroll || displayFilters ? '5px' : undefined, position: 'relative' }}>
                                                        {item.children}
                                                    </div>
                                                </div>
                                                {
                                                    tabFilters ?
                                                        isTablet ?
                                                            <Drawer
                                                                closable={false}
                                                                style={{ position: 'absolute' }}
                                                                drawerStyle={{ backgroundColor: 'var(--background-color)' }}
                                                                onClose={toggleDisplayFilters}
                                                                width={"100%"}
                                                                getContainer={false}
                                                                open={isTablet && displayFilters && tabFilters !== undefined}
                                                                footer={[
                                                                    <SpaceContent key={"container-tabs-drawer-footer"}>
                                                                        <Button type='primary' onClick={toggleDisplayFilters}>
                                                                            <FormattedMessage defaultMessage={'Show results'} />
                                                                        </Button>
                                                                    </SpaceContent>
                                                                ]}
                                                                footerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                            >
                                                                <div style={{ padding: '15px 10px' }}>
                                                                    <h2 style={{ marginBottom: '8px' }}><FormattedMessage defaultMessage={'Filters'} /></h2>
                                                                    <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                                                                    {tabFilters}
                                                                </div>
                                                            </Drawer>
                                                            :
                                                            <div className={`__container-tabs-main-filters ${displayFilters ? 'filters' : 'nofilters'}`}>
                                                                <h2 style={{ marginBottom: '8px' }}><FormattedMessage defaultMessage={'Filters'} /></h2>
                                                                <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                                                                {tabFilters}
                                                            </div>
                                                        : null
                                                }
                                            </div>
                                        )
                                    }
                                })} />
                        </Content>
                    </Layout >
                </Layout >
            </>
        );
    }
}

// #region Redux Store
const mapStateToProps = (state: ApplicationState) => ({
    isTablet: state.window.isTablet,
    isSmartphone: state.window.isSmartphone,
    displayFilters: state.window.displayFilters,
    collapsed: state.window.navigationCollapsed,
    containerHeight: state.window.containerHeight
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleDisplayFilters: () => dispatch(toggleDisplayFilters()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// #endregion

export default withRouter(connector(injectIntl(ContainerTabs)));

export const addOrUpdateExtra = (prevState: ContainerTabsState, content: React.ReactNode, key: string) => {
    const newExtra = { key: key, content: content };
    if (prevState.tabListOfExtras && prevState.tabListOfExtras.length > 0) {
        const extraFound = prevState.tabListOfExtras.find(extra => extra.key === key);
        if (extraFound) {
            return {
                tabListOfExtras: prevState.tabListOfExtras.map(extra => {
                    if (extra.key === key) return newExtra;
                    return extra;
                })
            };
        } else {
            return { tabListOfExtras: [...prevState.tabListOfExtras].concat(newExtra) }
        }
    } else {
        return { tabListOfExtras: [newExtra] }
    }
}

export const addOrUpdateFilters = (prevState: ContainerTabsState, content: React.ReactNode, key: string) => {
    const newFilter = { key: key, content: content };
    if (prevState.tabListOfFilters && prevState.tabListOfFilters.length > 0) {
        const filterFound = prevState.tabListOfFilters.find(filter => filter.key === key);
        if (filterFound) {
            return {
                tabListOfFilters: prevState.tabListOfFilters.map(filter => {
                    if (filter.key === key) return newFilter;
                    return filter;
                })
            };
        } else {
            return { tabListOfFilters: [...prevState.tabListOfFilters].concat(newFilter) }
        }
    } else {
        return { tabListOfFilters: [newFilter] }
    }
}
