
import { faAward, faBook, faCalendarPlus, faCalendarWeek, faCalendarXmark, faCamera, faCar, faChartLine, faChartNetwork, faClipboard, faClock, faCloud, faComments, faDashboard, faDesktop, faFile, faFolder, faGear, faGlobe, faGridHorizontal, faHamburger, faHelicopter, faHouse, faIdCard, faImage, faLaptop, faLink, faLocationDot, faLock, faPaperPlane, faPhone, faRoad, faShoppingCart, faSquareInfo, faStar, faTag, faTruck, faUser, faUtensils, faWifi } from '@fortawesome/pro-regular-svg-icons';
import { IconPacks } from './IconPacks';
import FA from './fontawesome';

/**
 * Pack used for documents
 * ----------- !!!! WARNING !!!! -----------------
 * Ce fichier doit être copié dans Sunkhronos-mobile-core à chaque changement. Une mise à jour des applications doit être faite à chaque changements
 * This file must be copied over to Sunkhronos-mobile-core at every changes. An update is required for mobile applications everytime this file changes
 * -----------------------------------------------
 */
export const documentsIconPack = FA.createIconPack(
    IconPacks.Documents,
    faHouse,
    faCalendarWeek,
    faCalendarPlus,
    faCalendarXmark,
    faUser,
    faLocationDot,
    faFolder,
    faFile,
    faDashboard,
    faChartLine,
    faChartNetwork,
    faIdCard,
    faGlobe,
    faWifi,
    faCloud,
    faClock,
    faGridHorizontal,
    faDesktop,
    faLaptop,
    faPhone,
    faImage,
    faCamera,
    faStar,
    faTruck,
    faCar,
    faShoppingCart,
    faClipboard,
    faBook,
    faLock,
    faTag,
    faComments,
    faPaperPlane,
    faGear,
    faLink,
    faAward,
    faSquareInfo,
    faRoad,
    faHelicopter,
    faUtensils,
    faHamburger
);