import { CloseOutlined, EditOutlined, FormOutlined } from '@ant-design/icons';
import { DatePicker, InputNumber, List } from 'antd';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { CompanyDetailsPrivilege, Privileges } from '../../../privileges';
import { CompanyTypes } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { Company } from '../../../utils/types/generalTypes';
import { NewtworkTypeOfDayOff } from '../../../utils/types/networkTypes';
import { TypeOfDayOff } from '../../../utils/types/planningTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { alert, checkCompanyDetailsPrivilege, checkPrivilege, getCompanyType } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import SwitchLabeled from '../../common/fields/switchLabeled';

type ReduxProps = ConnectedProps<typeof connector>

interface IProps {
    typeOfDayOff: TypeOfDayOff;
    refresh: (message?: string) => void;
    cancelNew: () => void;

    company: Company | undefined;
}
type Props = IProps & ReduxProps & IntlProps

interface State {
    typeOfDayOff: TypeOfDayOff | undefined;
}

/**
 * Component that represent a list item for the types of day list
 */
class TypeOfDayOffItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            typeOfDayOff: undefined,
        };
    }

    componentDidMount() {
        // if it is a new type of day, set state's type of day
        if (this.props.typeOfDayOff.id === undefined) {
            this.setState({ typeOfDayOff: { ...this.props.typeOfDayOff } });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // when add or edit a type of day, focus the input field
        if (this.state.typeOfDayOff !== undefined && prevState.typeOfDayOff === undefined) {
            const input = document.getElementById(`tod-edit-input-${this.props.typeOfDayOff.id ? this.props.typeOfDayOff.id : '0'}`);
            if (input) input.focus();
        }
    }

    /**
     * Change a type of day title
     * @param event the triggered event
     */
    onChangeTitle = (event: InputFieldOnChangeEvent) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff!.title = event.target.value;
        this.setState({ typeOfDayOff });
    }

    /**
     * Change a type of day title
     * @param event the triggered event
     */
    onChangeAbbreviation = (event: InputFieldOnChangeEvent) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff!.abbreviation = event.target.value;
        this.setState({ typeOfDayOff });
    }

    /**
     * Change a type of day in reports
     * @param event the triggered event
     */
    onChangeInReports = (checked: boolean) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff!.inReports = checked;
        this.setState({ typeOfDayOff });
    }

    /**
     * Change a type of day in reports
     * @param event the triggered event
     */
    onChangeCountAsWorktime = (checked: boolean) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff!.countAsWorktime = checked;
        this.setState({ typeOfDayOff });
    }

    onChangeCanBeBadged = (checked: boolean) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff!.canBeBadged = checked;
        this.setState({ typeOfDayOff });
    }

    /**
 * Change number of days
 * @param event the triggered event
 */
    onChangeDaysPerYear = (value: number) => {
        const { typeOfDayOff } = this.state;
        typeOfDayOff && (typeOfDayOff.daysPerYear = value ? value : 0);
        this.setState({ typeOfDayOff });
    }

    dataChanged = (value: Moment | null) => {
        const { typeOfDayOff } = this.state;
        value && typeOfDayOff ? typeOfDayOff.expiryDate = value : (typeOfDayOff && (typeOfDayOff.expiryDate = undefined));
        this.setState({ typeOfDayOff });
    }

    /**
     * Convert to input occupancy rate into a occupancy rate body request
     * @returns the converted occupancy rate body request
     */
    convertTypeOfDayOffToBodyRequest = (): NewtworkTypeOfDayOff => {
        const { typeOfDayOff } = this.state;

        return {
            id: typeOfDayOff?.id,
            title: typeOfDayOff?.title,
            abbreviation: typeOfDayOff?.abbreviation,
            inReports: typeOfDayOff?.inReports,
            countAsWorktime: typeOfDayOff?.countAsWorktime,
            canBeBadged: typeOfDayOff?.canBeBadged,
            daysPerYear: typeOfDayOff?.daysPerYear,
            expiryDate: (typeOfDayOff && typeOfDayOff.expiryDate) ? moment(typeOfDayOff.expiryDate).format("YYYY-MM-DD") : undefined,
        }
    }

    /**
     * Create or edit the type of day
     */
    onUpdateTypeOfDayOff = (): void => {
        Network.updateTypeOfDayOff(this.convertTypeOfDayOffToBodyRequest()).then(
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'Type of day successfully updated' }), "success");
                this.props.refresh();
                this.setState({ typeOfDayOff: undefined });
            },
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while updating the type of day' }), "warning"),
        )
    }

    /**
     * Delete a type of day
     */
    onDeleteTypeOfDayOff = () => {
        Network.deleteTypeOfDayOff(this.props.typeOfDayOff.id!).then(
            () => this.props.refresh(this.props.intl.formatMessage({ defaultMessage: 'The type of day has been successfully deleted' })),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the type of day' }), "warning"),
        );
    }

    render() {
        const { typeOfDayOff } = this.state;
        const { intl } = this.props;
        return (
            <List.Item extra={<div style={{ display: 'flex', flexDirection: 'row', gap: 8, width: 180, justifyContent: 'end' }}>
                {typeOfDayOff ?
                    [
                        <CircleButton
                            key={`typeOfDayOffItem-list-${typeOfDayOff.id}-cancel`}
                            icon={<CloseOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                            placement="left"
                            onClick={() => {
                                if (typeOfDayOff.id === undefined) this.props.cancelNew();
                                this.setState({ typeOfDayOff: undefined });
                            }} />,
                        <CircleButton
                            key={`typeOfDayOffItem-list-${typeOfDayOff.id}-save`}
                            icon={<FormOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Save' })}
                            placement="left"
                            onClick={this.onUpdateTypeOfDayOff} />
                    ] : [
                        <CircleButton
                            key={`typeOfDayOffItem-list-modify`}
                            icon={<EditOutlined />}
                            title={intl.formatMessage({ defaultMessage: 'Edit' })}
                            placement="left"
                            onClick={() => this.setState({ typeOfDayOff: { ...this.props.typeOfDayOff } })} />,
                        <DeleteButton
                            text={<FormattedMessage defaultMessage={'Do you want to delete this type of day?'} />}
                            key={`typeOfDayOffItem-list-delete`}
                            onConfirm={this.onDeleteTypeOfDayOff}
                            placement="topRight"
                            buttonPlacement="right" />
                    ]
                }</div>
            }>
                {
                    typeOfDayOff ?

                        this.props.company && getCompanyType(this.props.company) === CompanyTypes.CCNT ?
                            <div className="types-of-day-content">
                                <div style={{ display: 'flex' }}>
                                    <InputField
                                        id={`tod-edit-input-${this.props.typeOfDayOff.id ? this.props.typeOfDayOff.id : '0'}`}
                                        onPressEnter={this.onUpdateTypeOfDayOff}
                                        className="configurations-section-field"
                                        value={typeOfDayOff!.title}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeTitle(e)} />
                                    <InputField
                                        maxLength={2}
                                        showCount
                                        title={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                        onPressEnter={this.onUpdateTypeOfDayOff}
                                        className="configurations-section-field-small"
                                        value={typeOfDayOff!.abbreviation}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeAbbreviation(e)} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>

                                    <span>
                                        <SwitchLabeled
                                            checked={typeOfDayOff.inReports ?? false}
                                            onChange={e => this.onChangeInReports(e)}
                                            label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                        />
                                    </span>
                                    <span>
                                        <SwitchLabeled
                                            checked={typeOfDayOff.countAsWorktime ?? false}
                                            onChange={e => this.onChangeCountAsWorktime(e)}
                                            label={intl.formatMessage({ defaultMessage: 'Count as working hours' })}
                                        />
                                    </span>
                                    {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&

                                        <span>
                                            <SwitchLabeled
                                                checked={typeOfDayOff.canBeBadged ?? true}
                                                onChange={e => this.onChangeCanBeBadged(e)}
                                                label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                            />
                                        </span>
                                    }
                                </div>
                            </div>
                            :
                            <div className="types-of-day-content">
                                <div style={{ display: 'flex' }}>
                                    <InputField
                                        id={`tod-edit-input-${this.props.typeOfDayOff.id ? this.props.typeOfDayOff.id : '0'}`}
                                        onPressEnter={this.onUpdateTypeOfDayOff}
                                        className="configurations-section-field"
                                        value={typeOfDayOff!.title}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeTitle(e)} />
                                    <InputField
                                        maxLength={2}
                                        showCount
                                        title={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Abbreviation' })}
                                        onPressEnter={this.onUpdateTypeOfDayOff}
                                        className="configurations-section-field-small"
                                        value={typeOfDayOff!.abbreviation}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeAbbreviation(e)} />
                                    <InputNumber
                                        style={{ marginLeft: '10px' }}
                                        placeholder={intl.formatMessage({ defaultMessage: 'N° days' })}
                                        onPressEnter={this.onUpdateTypeOfDayOff}
                                        value={typeOfDayOff.daysPerYear ? typeOfDayOff.daysPerYear : undefined}
                                        onChange={(e) => this.onChangeDaysPerYear(toFinite(e))} />
                                    <DatePicker
                                        style={{ marginLeft: '10px' }}
                                        className="crm-voucher-modal-input"
                                        format={getFormat('DATE')}
                                        value={typeOfDayOff.expiryDate && moment(typeOfDayOff.expiryDate)}
                                        onChange={d => this.dataChanged(d)}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <span>
                                        <SwitchLabeled
                                            checked={typeOfDayOff.inReports ?? false}
                                            onChange={e => this.onChangeInReports(e)}
                                            label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                        />
                                    </span>
                                    <span>
                                        <SwitchLabeled
                                            checked={typeOfDayOff.countAsWorktime ?? false}
                                            onChange={e => this.onChangeCountAsWorktime(e)}
                                            label={intl.formatMessage({ defaultMessage: 'Count as working hours' })}
                                        />
                                    </span>
                                    {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&

                                        <span>
                                            <SwitchLabeled
                                                checked={typeOfDayOff.canBeBadged ?? true}
                                                onChange={e => this.onChangeCanBeBadged(e)}
                                                label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                            />
                                        </span>
                                    }
                                </div>
                            </div>
                        :
                        this.props.company && getCompanyType(this.props.company) === CompanyTypes.CCNT ?
                            <div className="types-of-day-content">
                                <p>
                                    <span style={{ minWidth: '180px', display: 'inline-block' }}>{`${this.props.typeOfDayOff.title} ${this.props.typeOfDayOff.abbreviation ? `(${this.props.typeOfDayOff.abbreviation})` : ""}`}</span>
                                </p>
                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <span style={{ fontSize: '13px' }}>
                                        <SwitchLabeled
                                            disabled
                                            size='small'
                                            className="checkbox-readonly"
                                            checked={this.props.typeOfDayOff.inReports ?? false}
                                            label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                        />
                                    </span>
                                    <span style={{ fontSize: '13px' }}>
                                        <SwitchLabeled
                                            disabled
                                            size='small'
                                            className="checkbox-readonly"
                                            checked={this.props.typeOfDayOff.countAsWorktime ?? false}
                                            label={intl.formatMessage({ defaultMessage: 'Count as working hours' })}
                                        />
                                    </span>
                                    {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&

                                        <span style={{ fontSize: '13px' }}>
                                            <SwitchLabeled
                                                disabled
                                                size='small'
                                                className="checkbox-readonly"
                                                checked={this.props.typeOfDayOff.canBeBadged ?? false}
                                                label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                            />
                                        </span>
                                    }
                                </div>
                            </div>
                            :
                            <div className="types-of-day-content">
                                <p>
                                    <span style={{ minWidth: '180px', display: 'inline-block' }}>{`${this.props.typeOfDayOff.title} ${this.props.typeOfDayOff.abbreviation ? `(${this.props.typeOfDayOff.abbreviation})` : ""}`}</span> &#10132;
                                    <span style={{ paddingLeft: '3px', minWidth: '80px', display: 'inline-block' }}><FormattedMessage defaultMessage={'{count, plural, one {1 day} other {{count} days}}'} values={{ count: this.props.typeOfDayOff.daysPerYear }} /></span>  &#10132;
                                    <FormattedMessage defaultMessage={'Deadline: {date}'} values={{ date: this.props.typeOfDayOff.expiryDate ? this.props.typeOfDayOff.expiryDate : '-' }} />
                                </p>
                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <span style={{ fontSize: '13px' }}>
                                        <SwitchLabeled
                                            disabled
                                            size='small'
                                            className="checkbox-readonly"
                                            checked={this.props.typeOfDayOff.inReports ?? false}
                                            label={intl.formatMessage({ defaultMessage: 'Included in reports' })}
                                        />
                                    </span>
                                    <span style={{ fontSize: '13px' }}>
                                        <SwitchLabeled
                                            disabled
                                            size='small'
                                            className="checkbox-readonly"
                                            checked={this.props.typeOfDayOff.countAsWorktime ?? false}
                                            label={intl.formatMessage({ defaultMessage: 'Count as working hours' })}
                                        />
                                    </span>
                                    {checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) &&

                                        <span style={{ fontSize: '13px' }}>
                                            <SwitchLabeled
                                                disabled
                                                size='small'
                                                className="checkbox-readonly"
                                                checked={this.props.typeOfDayOff.canBeBadged ?? true}
                                                label={intl.formatMessage({ defaultMessage: 'Can be clocked' })}
                                            />
                                        </span>
                                    }
                                </div>
                            </div>

                }
            </List.Item >
        )
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    company: state.user.company,
});

const connector = connect(mapStateToProps)

export default connector(injectIntl(TypeOfDayOffItem));
