import { CheckCircleOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, ScissorOutlined, SnippetsFilled, SwapOutlined, WarningOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Popconfirm, Popover, Spin } from 'antd';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { FaCheckCircle } from 'react-icons/fa';
import { MdPhotoCamera } from 'react-icons/md';
import { RiCupFill, RiTimeFill } from 'react-icons/ri';
import { RxDot } from 'react-icons/rx';
import { TfiPencil } from 'react-icons/tfi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useSelectable } from 'react-selectable-box';
import { toggleLoading } from '../../../../store/actions/user';
import { MOMENT_FORMAT } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { UsersAvailability } from '../../../../utils/types/generalTypes';
import { NetworkMonthlyPlanningRow } from '../../../../utils/types/networkTypes';
import { PlanningEvent, SelectedEventV2, UserAvailabilityBodyRequest } from '../../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch } from '../../../../utils/types/storeTypes';
import { colorIsBright, isNullOrEmpty } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import Anticon from '../../../common/general/anticon';


enum StateOfDay {
    Open = 1,
    PartiallyOpen = 2,
    Close = 3,
    PartiallyClose = 4,
}

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    data?: NetworkMonthlyPlanningRow[];
    item: string;
    userId?: number;
    canPaste: boolean;
    selectedEvents: SelectedEventV2[];
    usersUnavailabilities: UsersAvailability[];
    canEdit: boolean;
    cutEvent: boolean;
    copiedEvents: PlanningEvent[] | undefined;

    deleteEvent: (eventId: number, userId: number, day: string) => void;
    showEventDetails: (eventId?: number) => void;
    onCopyEvent: (eventIds: number[], date: string) => void;
    onCutEvent: (eventIds: number[], day: string) => void;
    resetCopyCut: () => void;
    onPasteEvent: (userId: number, day: string, forceUpdate?: boolean, skipCheckAvailability?: boolean, callbackSuccess?: () => void, callbackError?: () => void) => void;

    onClickCreateEvent: (columnKey: string, userId: number) => void;
    onClickEditEvent: (eventId: number) => void;
    onSelectCell: (eventId: number, userId: number, date: string) => void;
    onSelectRowCell: (eventId: number, userId: number, date: string) => void;
    onConfirmEvent: (eventId: number, userId: number) => void;
}

interface State {
    popConfirmAvailabilityError: boolean;
    dataPlaceholder: NetworkMonthlyPlanningRow[];
    placeholderErrorCount: number;
    confirmDelete: number;
}

/**
 * Component that represent a table cell for the monthly planning
 */
class Cell extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            popConfirmAvailabilityError: false,
            dataPlaceholder: [],
            placeholderErrorCount: 0,
            confirmDelete: 0,
        };
    }

    componentDidMount(): void {
        // console.log("PERF MOUNT Cell", this.props.userId, this.props.item)
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
        if (!isEqual(this.props.selectedEvents.filter(se => this.props.data?.some(data => data.id === se.eventId)), nextProps.selectedEvents.filter(se => this.props.data?.some(data => data.id === se.eventId)))) {
            // console.log("PERF SHOULDUPDATE Cell Selected", this.props.userId, this.props.item)
            return true;
        }

        if (!isEqual(this.props.data, nextProps.data)) {
            console.log("PERF SHOULDUPDATE Cell Data Changed", nextProps.data);
            return true;
        }

        if (!isEqual(this.props.usersUnavailabilities, nextProps.usersUnavailabilities)) {
            return true;
        }

        if (this.props.canEdit !== nextProps.canEdit) {
            return true;
        }

        if (!isEqual({ ...this.state, dataPlaceholder: undefined }, { ...nextState, dataPlaceholder: undefined })) {
            // console.log("PERF SHOULDUPDATE Cell Copy Data Changed", this.state, nextState)
            return true;
        }
        // We not compare same length, to not the refresh twice, when we remove placeholder, not refresh, it will disapear on new event comming
        if (this.state.dataPlaceholder.length < nextState.dataPlaceholder.length) {
            return true;
        }

        return false;

    }

    checkEventsAvailabilities = (data: UserAvailabilityBodyRequest[], callbackSuccess: () => void, callbackError: () => void) => {
        this.props.toggleLoading!(true);
        Network.eventsCheckAvailabilities(data).then(
            response => {
                if (response.length > 0) callbackError();
                else callbackSuccess();
            },
            () => {
                callbackError();
            }
        ).finally(() => {
            this.props.toggleLoading!(false);
        });
    };

    removeDateByIds = (ids: number[], error = false) => {
        this.setState(prevState => {
            const dataPlaceholder = prevState.dataPlaceholder.filter(d => !ids.includes(d.id));
            if (error)
                return {
                    dataPlaceholder,
                    placeholderErrorCount: prevState.placeholderErrorCount + 1
                };
            else
                return {
                    dataPlaceholder,
                    placeholderErrorCount: prevState.placeholderErrorCount
                };
        });
    };

    onPasteCheckAvailability = async (userId: number, dateStr: string, forceCopy = false) => {
        const { copiedEvents } = this.props;
        if (isNullOrEmpty(copiedEvents)) return;

        const paste = () => {
            const placeholderEventIds: number[] = [];

            const dataPlaceholder: NetworkMonthlyPlanningRow[] = [];
            copiedEvents.forEach(ce => {
                const tmpId = moment().unix();
                placeholderEventIds.push(tmpId);
                const startDate = moment(this.props.item, "YYYYMMDD").set("hours", ce.startDate.hours()).set("minutes", ce.startDate.minutes()).set("minutes", ce.startDate.minutes()).set("seconds", ce.startDate.seconds());
                const durationEvent = ce.endDate.diff(ce.startDate, "seconds");

                dataPlaceholder.push({
                    ...ce,
                    userFirstName: ce.userName ?? '',
                    userLastName: ce.userName ?? '',
                    countBreaktimes: 0,
                    countNotConfirmed: 0,
                    countOvertimes: 0,
                    hasImages: false,
                    isClocked: false,
                    id: tmpId,
                    loading: true,
                    userId: this.props.userId ?? -1,
                    startDate: startDate.clone().format(MOMENT_FORMAT),
                    endDate: startDate.clone().add(durationEvent, "seconds").format(MOMENT_FORMAT),
                    modified: ce.modified ? ce.modified.format(MOMENT_FORMAT) : '',
                    color: ce.color?.color
                });
            });

            this.props.onPasteEvent(userId, dateStr, forceCopy, true, () => this.removeDateByIds(placeholderEventIds), () => this.removeDateByIds(placeholderEventIds, true));
            this.setState(prevState => ({ popConfirmAvailabilityError: false, dataPlaceholder: [...prevState.dataPlaceholder, ...dataPlaceholder] }));
        };

        const userAvailabilitiesRequestData: UserAvailabilityBodyRequest[] = [];


        copiedEvents.forEach(ce => {
            const date = moment(dateStr, 'YYYYMMDD').hours(ce.startDate.hours()).minutes(ce.startDate.minutes()).seconds(ce.startDate.seconds());
            const duration = moment.duration(ce.endDate.diff(ce.startDate));
            const durationInSeconds = duration.asSeconds();
            const endDate = date.clone().add(durationInSeconds, "seconds");
            userAvailabilitiesRequestData.push({
                userId,
                startDate: date,
                endDate: endDate,
            });
        });
        if (forceCopy) {
            paste();
        } else {
            this.checkEventsAvailabilities(userAvailabilitiesRequestData, () => paste(), () => this.setState({ popConfirmAvailabilityError: true }));
        }

    };


    parseDate = (date: Moment): Moment => {
        if (date.hours() === 0 && date.minutes() === 0) {
            date.startOf('day');
        } else if (date.minutes() === 0) {
            date.seconds(0).milliseconds(0);
        } else if (date.hours() === 23 && date.minutes() === 59) {
            date.endOf('day');
        } else if (date.minutes() === 59) {
            date.seconds(59).milliseconds(59);
        }
        return date;
    };

    menuItemsParent = (): MenuProps['items'] => {
        const { item, userId } = this.props;

        return [
            {
                label: <FormattedMessage defaultMessage={'Add an event'} />,
                key: `monthly-planning-menu-add-u${userId}-empty`,
                icon: <PlusOutlined />,
                onClick: () => {
                    userId && this.props.onClickCreateEvent(item, userId);
                },
                style: { color: 'var(--positive-color)' },
            },
        ];
    };

    renderContentCell = () => {
        const { usersUnavailabilities, item, userId, selectedEvents, canEdit, isSmartphone, intl } = this.props;
        let stateOfDay: number = StateOfDay.Open;
        let availabilityClassName = '';
        const unavailabilities = usersUnavailabilities.find(userUnavailabilities => userUnavailabilities.user.id === userId)?.unavailabilities;
        const unavailabilitiesInContact: { start: Moment, end: Moment; }[] = [];
        const cellStart = moment(item, 'YYYYMMDD').startOf('day');
        const cellEnd = moment(item, 'YYYYMMDD').endOf('day');


        unavailabilities?.forEach(unavailability => {
            const unavailabilityStart = this.parseDate(moment(unavailability.startDate));
            const unavailabilityEnd = this.parseDate(moment(unavailability.endDate));

            if (unavailabilityStart <= cellStart && unavailabilityEnd >= cellEnd) {
                stateOfDay = StateOfDay.Close;
                availabilityClassName = '__monthly-planning-cell-blocked';
            } else if (unavailabilityStart <= cellEnd && unavailabilityEnd > cellStart) {
                const unavailability_start = (unavailabilityStart.format('YYYYMMDD') !== cellStart.format('YYYYMMDD') && unavailabilityStart.isBefore(cellStart)) ? cellStart : unavailabilityStart;
                const unavailability_end = (unavailabilityEnd.format('YYYYMMDD') !== cellEnd.format('YYYYMMDD') && unavailabilityEnd.isAfter(cellEnd)) ? cellEnd : unavailabilityEnd;
                if (stateOfDay !== StateOfDay.Close && unavailability_start.format("HH:mm:ss") !== "23:59:59") { //hide unavailabilites from 59:59:59 because not useful to see
                    stateOfDay = StateOfDay.PartiallyClose;
                    availabilityClassName = '__monthly-planning-cell-partially';
                }
                unavailability_start.format("HH:mm:ss") !== "23:59:59" && unavailabilitiesInContact.push({
                    start: unavailability_start,
                    end: unavailability_end
                });

            }
        });

        const availabilitiesContent = <div>{unavailabilitiesInContact?.map((a, i) => <p key={`availability-${i}-${item}`} ><Anticon icon={<RxDot />} />{a.start.format(getFormat('TIME_SHORT'))} - {a.end.format(getFormat('TIME_SHORT'))}</p>)}</div>;

        return (
            <div
                onClick={(e) => {
                    if (this.props.canPaste) {
                        e.stopPropagation();
                        userId && this.onPasteCheckAvailability(userId, item);
                    }
                }}
            >
                <Popconfirm
                    open={this.state.popConfirmAvailabilityError}
                    title={
                        <>
                            <p style={{ fontWeight: 'bolder' }}><FormattedMessage defaultMessage={'Force copy'} /></p>
                            <p><FormattedMessage defaultMessage={"Record the event despite the selected user's lack of availability."} /></p>
                        </>
                    }
                    okText={<FormattedMessage defaultMessage={'Force copy'} />}
                    cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                    okButtonProps={{ type: 'link' }}
                    cancelButtonProps={{ type: 'link' }}
                    icon={<WarningOutlined style={{ color: '#ff2626' }} />}
                    placement={'bottom'}
                    onConfirm={(e) => {
                        e?.stopPropagation();
                        e?.preventDefault();
                        this.props.userId && this.onPasteCheckAvailability(this.props.userId, item, true);
                    }}
                    onCancel={() => this.setState({ popConfirmAvailabilityError: false })}>
                    <Popover
                        mouseEnterDelay={0.4}
                        open={stateOfDay === StateOfDay.Open || stateOfDay === StateOfDay.Close ? false : undefined}
                        content={<div style={{ display: 'flex', gap: '10px' }}>{availabilitiesContent}</div>}
                        title={stateOfDay === StateOfDay.PartiallyClose ? <FormattedMessage defaultMessage={'Unavailability'} /> : <FormattedMessage defaultMessage={'Availability'} />}
                    >
                        <div
                            className={`__monthly-planning-fill-td ${availabilityClassName}`}
                            title={canEdit ? stateOfDay === StateOfDay.Close ? intl.formatMessage({ defaultMessage: 'No availability today' }) : '' : intl.formatMessage({ defaultMessage: 'You do not have rights for this user' })}
                            style={{ cursor: canEdit ? "pointer" : "not-allowed" }}
                            onDoubleClick={() => {
                                if (canEdit && selectedEvents.length === 0 && !isSmartphone) {
                                    userId && this.props.onClickCreateEvent(item, userId);
                                }
                            }} onClick={() => {
                                if (canEdit && selectedEvents.length === 0 && isSmartphone) {
                                    userId && this.props.onClickCreateEvent(item, userId);
                                }
                            }}>

                        </div>
                    </Popover>
                </Popconfirm>
                <div
                    title={intl.formatMessage({ defaultMessage: 'Paste' })}
                    className={`__monthly-planning-cell-action-paste-parent ${canEdit ? '__canedit' : ''}`}
                    style={selectedEvents.length > 0 ? { display: 'none' } : {}}>
                    <div
                        onClick={(e) => {
                            e?.stopPropagation();
                            this.props.resetCopyCut();
                        }}
                        title={intl.formatMessage({ defaultMessage: 'Cancel copy (Esc)' })}
                        style={{ color: 'var(--dark-color)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '-5px', right: '-5px', width: '18.5px', height: '18.5px', backgroundColor: 'white', borderRadius: '50px' }}>
                        <CloseOutlined style={{ fontSize: '12px' }} />
                    </div>
                    <div className='__monthly-planning-cell-action-paste'>
                        <Anticon icon={<SnippetsFilled />} />
                    </div>
                </div>
            </div>
        );
    };

    // Render <td>
    renderCell = () => {
        const { selectedEvents, canEdit } = this.props;

        return [
            canEdit && selectedEvents.length === 0 ?
                <Dropdown trigger={['contextMenu']}
                    menu={{ items: this.menuItemsParent() }}>
                    {this.renderContentCell()}
                </Dropdown>
                :
                this.renderContentCell()
        ];
    };

    renderEvent = (event: NetworkMonthlyPlanningRow) => {
        const { selectedEvents, canEdit, copiedEvents, cutEvent, userId, item, intl } = this.props;
        let isCutted = false;
        let isCopied = false;
        if (copiedEvents?.find(ce => ce.id === event.id)) {
            if (cutEvent) isCutted = true;
            else isCopied = true;
        }
        const userSelectedEventsForDate = selectedEvents.filter(se => se.userId === userId && se.date === item);

        const selectionDisabled = this.state.dataPlaceholder.some(d => d.id === event.id);
        const selectionValue = getEventSelectValue(event.userId, event.id, item);


        const menuItems: MenuProps['items'] = userSelectedEventsForDate.length === 0 ?
            [
                {
                    label: intl.formatMessage({ defaultMessage: 'Copy' }),
                    key: `monthly-planning-menu-copy-u${userId}-e${event.id}`,
                    icon: <CopyOutlined />,
                    onClick: () => {
                        this.props.onCopyEvent([event.id], item);
                    },
                    style: {},
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Cut' }),
                    key: `monthly-planning-menu-cut-u${userId}-e${event.id}`,
                    icon: <ScissorOutlined />,
                    onClick: () => {
                        this.props.onCutEvent([event.id], item);
                    },
                    style: {},
                },
                {
                    type: 'divider',
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Confirm event' }),
                    key: `monthly-planning-menu-confirm-u${userId}-e${event.id}`,
                    icon: <CheckCircleOutlined />,
                    onClick: () => {
                        userId && this.props.onConfirmEvent(event.id, userId);
                    },
                    style: { color: 'var(--success-color)' },
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Modify event' }),
                    key: `monthly-planning-menu-modify-u${userId}-e${event.id}`,
                    icon: <TfiPencil />,
                    onClick: () => {
                        this.props.onClickEditEvent(event.id);
                    },
                    style: { color: 'var(--primary-color)' },
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Delete event' }),
                    key: `monthly-planning-menu-delete-u${userId}-e${event.id}`,
                    icon: <DeleteOutlined />,
                    onClick: () => this.setState({ confirmDelete: event.id }),
                    style: { color: 'var(--error-color)' },
                },
                {
                    type: 'divider',
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Add an event' }),
                    key: `monthly-planning-menu-add-u${userId}-empty`,
                    icon: <PlusOutlined />,
                    onClick: () => {
                        userId && this.props.onClickCreateEvent(item, userId);
                    },
                    style: { color: 'var(--positive-color)' },
                },
            ]
            :
            [
                {
                    label: intl.formatMessage({ defaultMessage: 'Duplicate selection of {time}' }, { time: moment(item, "YYYYMMDD").format(getFormat('DAY_SHORT_AND_MONTH_HALF')) }),
                    key: `monthly-planning-menu-multiple-copy-u${userId}-e${event.id}`,
                    icon: <CopyOutlined />,
                    onClick: () => {
                        this.props.onCopyEvent(userSelectedEventsForDate.map(e => e.eventId), item);
                    },
                    style: {},
                },
            ];
        let iconList = [
            <span key={'confirmed'} className='__monthly-planning-cell-icon' ><FaCheckCircle title={intl.formatMessage({ defaultMessage: 'Event confirmed' })} /></span>,
            <span key={'timeclock'} className='__monthly-planning-cell-icon' style={event.isClocked && this.props.company?.isTimeClockEnabled ? {} : { display: 'none' }}><RiTimeFill title={intl.formatMessage({ defaultMessage: 'Event clocked' })} /></span>,
            <span key={'breaktime'} className='__monthly-planning-cell-icon' style={event.countBreaktimes > 0 ? {} : { display: 'none' }}><RiCupFill className="anticon" title={intl.formatMessage({ defaultMessage: 'Event with breaks' })} /></span>,
            <span key={'overtime'} className='__monthly-planning-cell-icon' style={event.countOvertimes > 0 ? {} : { display: 'none' }}><RiTimeFill className="anticon" title={intl.formatMessage({ defaultMessage: 'Event with overtime' })} /></span>,
            <span key={'multipleDays'} className='__overtime-count'><SwapOutlined title={intl.formatMessage({ defaultMessage: '2-day event' })} /></span>,
            <span key={'images'} className='__monthly-planning-cell-icon' ><MdPhotoCamera title={intl.formatMessage({ defaultMessage: 'Event with images' })} /></span>
        ];
        if (event.countNotConfirmed !== 0) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'confirmed');
        }
        if (!this.props.company?.isTimeClockEnabled || !event.isClocked) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'timeclock');
        }
        if (!event.countBreaktimes) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'breaktime');
        }
        if (!event.countOvertimes) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'overtime');
        }
        if ((moment(event.startDate).isSame(moment(event.endDate), 'date')) || moment(event.endDate).format('HH:mm:ss') === "00:00:00") {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'multipleDays');
        }
        if (!event.hasImages) {
            iconList = iconList.filter(iconComponent => iconComponent.key !== 'images');
        }
        const cellContent = (
            <Spin size='large' indicator={<LoadingOutlined spin />} spinning={event.loading ? true : false} >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (canEdit && !isCutted) {
                            if (e.ctrlKey || e.metaKey) {
                                userId && this.props.onSelectCell(event.id, userId, item);
                            } else if (e.shiftKey) {
                                userId && this.props.onSelectRowCell(event.id, userId, item);
                            } else {
                                this.props.showEventDetails(event.id);
                            }
                        }
                        document.getSelection()?.removeAllRanges();
                    }}
                    title={canEdit ? '' : "Vous n'avez pas les droits pour cet utilisateur"}
                    className={`__monthly-planning-cell ${canEdit && !isCutted ? 'hvr-float' : ''} ${event.color && colorIsBright(event.color) ? "dark" : "white"} ${selectedEvents.length > 0 ? selectedEvents.find(se => se.eventId === event.id) ? "monthly-planning-cell-selection-on" : "monthly-planning-cell-selection-off" : ''}`}
                    style={{ backgroundColor: event.color ?? 'var(--primary-color)', color: event.color && colorIsBright(event.color) ? "var(--dark-color)" : "white", cursor: canEdit ? isCutted ? 'no-drop' : isCopied ? "cell" : "pointer" : "not-allowed", opacity: isCutted ? '0.7' : '1' }}
                >
                    <div className='__monthly-planning-cell-overlay'><Anticon className='plus'><PlusOutlined /></Anticon><Anticon className='minus'><MinusOutlined /></Anticon></div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: (iconList.length > 3) ? 'wrap' : 'nowrap' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ textOverflow: "ellipsis", width: '110px', whiteSpace: "nowrap", overflow: "hidden", marginTop: '-3px' }}>{event.title}</span>
                            <span style={{ fontSize: '12px' }}>{`${moment(event.startDate).format(getFormat('TIME_SHORT'))} - ${moment(event.endDate).format(getFormat('TIME_SHORT'))}`}</span>
                        </div>
                        <div className='__monthly-planning-cell-icons' style={(iconList.length > 3) ? { flexDirection: 'row', gap: "4px" } : {}}>
                            {iconList.map(iconComponent => iconComponent)}
                        </div>
                    </div>
                    <div className={`__monthly-planning-cell-actions ${canEdit ? '__canedit' : ''}`}>
                        <div className='__monthly-planning-cell-action-edit'
                            onClick={(e) => {
                                e?.stopPropagation();
                                this.props.onClickEditEvent(event.id);
                            }}>
                            <Anticon icon={<TfiPencil title={intl.formatMessage({ defaultMessage: 'Edit' })} />} />
                        </div>
                        <div
                            className='__monthly-planning-cell-action-delete'
                            onClick={(e) => {
                                e?.stopPropagation();
                            }}>
                            <Popconfirm
                                title={<FormattedMessage defaultMessage={'Do you want to delete this event?'} />}
                                onConfirm={(e) => {
                                    e?.stopPropagation();
                                    userId && this.props.deleteEvent(event.id, userId, item);
                                }}
                                onCancel={(e) => e?.stopPropagation()}
                                okText={<FormattedMessage defaultMessage={'Delete'} />}
                                placement={"top"}
                            >
                                <Anticon onClick={(e: any) => e?.stopPropagation()} icon={<DeleteOutlined title={intl.formatMessage({ defaultMessage: 'Delete' })} />} />
                            </Popconfirm >
                        </div>
                        <div className='__monthly-planning-cell-action-copy'
                            onClick={(e) => {
                                e?.stopPropagation();
                                this.props.onCopyEvent([event.id], item);
                            }}>
                            <Anticon icon={<CopyOutlined title={intl.formatMessage({ defaultMessage: 'Copy' })} />} />
                        </div>
                    </div>
                </div>
            </Spin>
        );

        if (!event.loading && canEdit && (selectedEvents.length === 0 || userSelectedEventsForDate.length > 0)) {
            return (
                <SelectableItem value={selectionValue} disabled={selectionDisabled}>
                    <Popconfirm
                        title={<FormattedMessage defaultMessage={'Do you want to delete this event?'} />}
                        open={this.state.confirmDelete === event.id}
                        onConfirm={() => {
                            userId && this.props.deleteEvent(event.id, userId, item);
                            this.setState({ confirmDelete: 0 });
                        }}
                        onCancel={() => this.setState({ confirmDelete: 0 })}
                        okText={<FormattedMessage defaultMessage={'Delete'} />}
                        placement={"top"}
                    >
                        <Dropdown trigger={['contextMenu']}
                            menu={{ items: menuItems }}

                        >
                            {cellContent}
                        </Dropdown>
                    </Popconfirm>
                </SelectableItem>
            );

        }

        return <SelectableItem value={selectionValue} disabled={selectionDisabled}>{cellContent}</SelectableItem>;
    };

    renderEvents = (data?: NetworkMonthlyPlanningRow[]): ReactNode[] => {
        if (data === undefined)
            return [];

        return data.map((d) => {
            return this.renderEvent(d);
        });
    };

    render() {
        const { data } = this.props;
        const { dataPlaceholder } = this.state;
        const content = this.renderCell();


        let events: NetworkMonthlyPlanningRow[] = [];
        if (data && data.length > 0)
            events = events.concat([...data]);

        if (dataPlaceholder && dataPlaceholder.length > 0)
            events = events.concat([...dataPlaceholder]);

        events = events.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

        return (
            <>
                {content}
                {events && events.length > 0 ? this.renderEvents(events) : null}
            </>
        );

    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),

});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(Cell));

const SelectableItem = ({ children, value, disabled }: { children: ReactNode, value: string, disabled: boolean; }) => {
    const { setNodeRef, isAdding, isSelected, isRemoving } = useSelectable({
        value,
        disabled
    });

    return (
        <div ref={setNodeRef} className={`__monthly-planning-one-cell-content ${isAdding ? 'isAdding' : ''} ${isSelected ? 'isSelected' : ''} ${isRemoving ? 'isRemoving' : ''}`} >
            {children}
        </div>
    );
};

export const getEventSelectValue = (userId: number, eventId: number, date: string) => {
    return `${userId}-${eventId}-${date}`;
};
