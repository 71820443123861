import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import * as momentJs from 'moment';
import { Moment } from "moment";
import { DateRange, extendMoment } from "moment-range";
import { BreaktimeCalculatedTypes, IncreasedHours, OvertimesCalculatedStatus, OvertimesCalculatedTypes, VehicleTypesIds } from "../../constants";
import { AllHolidays, MonthlyHoursOfTheYear, TypeOfDayOff } from "../../types/planningTypes";
import { CctSecurityDistance, CctSecurityEventSummary, CctSecurityEventsByContractByMonth, CctSecurityRawEvent, CctSecurityTravelZoneDistance, CctSecurityTypeDayOffOverview, CctSecurityUserHoursSummaryByYear, CctSecurityUserHoursSummaryInDay, CctSecurityUserHoursSummaryInMonth, EventsByContractByMonth, IncreasedHoursByDay, InitialDaysOffCctSecurity, InitialHoursByContractByYear, SimpleContract, SimpleEventCctSecurity, TravelSummary } from "../../types/reportTypes";
import { minutesToHours, roundDecimals, showNotification, val } from "../../utils";
import { UserEventsData } from "./userEventsData";

const moment = extendMoment(momentJs);

export class CctSecurity extends UserEventsData {
    protected _userYear?: CctSecurityUserHoursSummaryByYear;
    protected _userTargetMonth?: CctSecurityUserHoursSummaryInMonth;
    protected _totalTravelEngagementZone?: CctSecurityTravelZoneDistance;
    protected _totalTravelEngagementZone2?: CctSecurityTravelZoneDistance;
    protected _totalTravelFlatRateZone1?: CctSecurityTravelZoneDistance;
    protected _totalTravelFlatRateZone2?: CctSecurityTravelZoneDistance;
    protected _totalTravelDynamicRateZone?: CctSecurityTravelZoneDistance;
    protected _eventsByContractByMonth?: CctSecurityEventsByContractByMonth[];
    protected _allHolidays?: AllHolidays[];
    protected _events: SimpleEventCctSecurity[];
    protected _typeOfDayOff?: TypeOfDayOff[];

    protected _increasedHoursByDay?: IncreasedHoursByDay[];

    constructor(events: SimpleEventCctSecurity[], user: any, contracts?: SimpleContract[]) {
        super(events, user, contracts);
        this._events = events;
        this._eventsByContractByMonth = [];
    }

    initCctSecurity(startMonth: Moment, endMonth: Moment, increasedHoursByDay: IncreasedHoursByDay[], allHolidays: AllHolidays[], monthlyHours?: MonthlyHoursOfTheYear, initialHoursByContractByYear?: InitialHoursByContractByYear[], initialDaysOffByContractByYear?: InitialDaysOffCctSecurity[], typeOfDayOff?: TypeOfDayOff[]) {
        this._increasedHoursByDay = increasedHoursByDay;
        this._allHolidays = allHolidays;
        this._typeOfDayOff = typeOfDayOff;
        this._eventsByContractByMonth = this.eventsByContractByMonthInit(startMonth, endMonth, monthlyHours, initialHoursByContractByYear);
        this._eventsByContractByMonth?.forEach(e => {
            // data for month
            const calculatedEventsCct = this.eventsContractCaculateCct(e, monthlyHours, initialDaysOffByContractByYear);
            if (calculatedEventsCct === undefined) {
                return;
            }
            e.cctSecurityCalculatedEvents = calculatedEventsCct;

            //data for day
        });
        return this._eventsByContractByMonth;
    }

    // datefrom & dateto must be same weekday
    static calculateIncreaseHoursSameDay(dateFrom: Moment, dateTo: Moment, increasedHoursByDay: IncreasedHoursByDay[], allHolidays: AllHolidays[]) {
        let increasedHours = 0.0;
        if (!dateFrom.isSame(dateTo, "day") || increasedHoursByDay === undefined || increasedHoursByDay.length === 0) {
            return increasedHours;
        }

        const rangeEventHours = moment.range(dateFrom, dateTo);
        const dayOfWeek = dateFrom.isoWeekday();

        const holidayFound = allHolidays?.find(h => dateFrom.isBetween(h.startDate, h.endDate, "day", "[]"));

        let increasedHoursMatchDay: IncreasedHoursByDay[] | undefined;
        if (holidayFound?.isPublicHoliday) {
            increasedHoursMatchDay = increasedHoursByDay.filter(i => i.day === IncreasedHours.PUBLICHOLIDAYS_TYPE);
        } else if (holidayFound?.isDayOff) {
            increasedHoursMatchDay = increasedHoursByDay.filter(i => i.day === IncreasedHours.DAYSOFF_TYPE);
        } else {
            increasedHoursMatchDay = increasedHoursByDay.filter(i => i.day === dayOfWeek);
        }
        if (increasedHoursMatchDay.length > 0) {
            let rangeIncreasedHours: DateRange | undefined;
            let isOverlaps: boolean;
            increasedHoursMatchDay.forEach(i => {
                isOverlaps = false;
                rangeIncreasedHours = undefined;
                if (i.startTime && i.endTime) {
                    const startTime = dateFrom.clone().hour(i.startTime.hour()).minutes(i.startTime.minutes()).seconds(i.startTime.seconds());
                    let endTime = dateFrom.clone().hour(i.endTime.hour()).minutes(i.endTime.minutes()).seconds(i.endTime.seconds());
                    if (startTime.isAfter(endTime, "seconds")) endTime = endTime.add(1, "day");

                    rangeIncreasedHours = moment.range(startTime, endTime);
                    if (rangeEventHours.overlaps(rangeIncreasedHours, { adjacent: true })) {
                        isOverlaps = true;
                    }
                } else if (i.startTime && i.allDay) { // later delete allday, this else go disappear
                    rangeIncreasedHours = moment.range(i.startTime.clone().startOf("day"), i.startTime.clone().endOf("day"));
                    if (rangeEventHours.overlaps(rangeIncreasedHours, { adjacent: true })) {
                        isOverlaps = true;
                    }
                } else {
                    rangeIncreasedHours = moment.range(dateFrom.clone().startOf("day"), dateFrom.clone().endOf("day"));
                    if (rangeEventHours.overlaps(rangeIncreasedHours, { adjacent: true })) {
                        isOverlaps = true;
                    }
                }

                if (isOverlaps && rangeIncreasedHours) {
                    const rangeIntersectedIncreasedHours = rangeEventHours.intersect(rangeIncreasedHours)?.diff("minutes");
                    if (rangeIntersectedIncreasedHours !== undefined) {
                        increasedHours += minutesToHours(roundDecimals(rangeIntersectedIncreasedHours * roundDecimals((i.percentage / 100), 4), 4));
                    }
                }
            });
        }
        return increasedHours;
    }

    static calculateIncreaseHours(event: SimpleEventCctSecurity, totalOvertimesUndertimes: number, increasedHoursByDay: IncreasedHoursByDay[] = [], allHolidays: AllHolidays[] = []) {
        const events: SimpleEventCctSecurity[] = [];
        let increasedHours = 0.0;
        const notPaidBreaktimes = cloneDeep(event.breakTimes.filter(b => b.isPaid === false));
        if (notPaidBreaktimes.length > 0) {
            const eventRange: DateRange = moment.range(event.dateFrom.clone(), event.dateTo.clone());
            let breaktimeRange: DateRange | undefined = undefined;

            const virtualEvent = cloneDeep(event);
            notPaidBreaktimes.sort((a, b) => a.dateFrom.diff(b.dateFrom)).forEach(b => {
                breaktimeRange = moment.range(b.dateFrom.clone(), b.dateTo.clone());
                if (breaktimeRange.overlaps(eventRange, { adjacent: true })) {
                    virtualEvent.dateTo = breaktimeRange.start.clone();
                    events.push(cloneDeep(virtualEvent));
                    virtualEvent.dateFrom = breaktimeRange.end.clone();
                } else {
                    //TODO what to do if breaktime not overlaps event (and for paid breaktime)
                }
            });

            if (events.length > 0) {
                virtualEvent.dateTo = eventRange.end.clone();
                events.push(cloneDeep(virtualEvent));
            } else {
                events.push(cloneDeep(event));
            }
        } else {
            events.push(cloneDeep(event));
        }
        const eventsLength = events.length;
        if (eventsLength > 0 && totalOvertimesUndertimes !== 0) {
            const lastEvent = events[eventsLength - 1];
            lastEvent.dateTo = lastEvent.dateTo.add(totalOvertimesUndertimes, "hours");
            if (lastEvent.dateTo.isBefore(lastEvent.dateFrom)) {
                events.pop();
            }
        }

        events.forEach(e => {
            if (e.dateFrom.isSame(e.dateTo, "day")) {
                increasedHours += this.calculateIncreaseHoursSameDay(e.dateFrom.clone(), e.dateTo.clone(), increasedHoursByDay, allHolidays);
            } else {
                const calculatedDay = e.dateFrom.clone();
                while (calculatedDay.isSameOrBefore(e.dateTo, "day")) {
                    if (calculatedDay.isSame(e.dateTo, "day")) {
                        increasedHours += this.calculateIncreaseHoursSameDay(calculatedDay.clone().startOf("day"), e.dateTo.clone(), increasedHoursByDay, allHolidays);
                    } else if (calculatedDay.isSame(e.dateFrom, "day")) {
                        increasedHours += this.calculateIncreaseHoursSameDay(calculatedDay.clone(), calculatedDay.clone().endOf("day"), increasedHoursByDay, allHolidays);
                    } else {
                        increasedHours += this.calculateIncreaseHoursSameDay(calculatedDay.clone().startOf("day"), calculatedDay.clone().endOf("day"), increasedHoursByDay, allHolidays);
                    }
                    calculatedDay.add(1, "day");
                }
            }
        });
        return roundDecimals(increasedHours, 4);

    }

    eventCalculateCct(event: SimpleEventCctSecurity, lstTypeDayOff: CctSecurityTypeDayOffOverview[], vacationIncreasedPercentByHour?: number) {
        const eventSummary: CctSecurityEventSummary = {};

        eventSummary.project = event.project?.title;
        eventSummary.eventHours = UserEventsData.eventDuration(event, undefined, undefined, true, true); // TODO remove "true" after cctSecurity class has typeofdayoff calcs
        eventSummary.overtimesHours = UserEventsData.overtimesDuration([event], OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED, true, true); // TODO remove "true" after cctSecurity class has typeofdayoff calcs
        eventSummary.breaktimesPaidHours = UserEventsData.breaktimesDuration([event], BreaktimeCalculatedTypes.PAID, true, true); // TODO remove "true" after cctSecurity class has typeofdayoff calcs
        eventSummary.breaktimesNotPaidHours = UserEventsData.breaktimesDuration([event], BreaktimeCalculatedTypes.NOTPAID, true, true); // TODO remove "true" after cctSecurity class has typeofdayoff calcs
        eventSummary.breaktimesHours = eventSummary.breaktimesPaidHours + eventSummary.breaktimesNotPaidHours;
        eventSummary.effectiveHours = eventSummary.eventHours + eventSummary.overtimesHours - eventSummary.breaktimesNotPaidHours;
        eventSummary.deplacement = this.calculateDistanceZones(event);
        eventSummary.userRemarks = event.userRemarks;

        // Calculate increased hours by increased jours by day list
        if (this._increasedHoursByDay && this._increasedHoursByDay.length > 0 && !event.typeOfDayOff) {
            let increasedHours = 0.0;
            // Todo majoration algo with moment-range
            increasedHours = CctSecurity.calculateIncreaseHours(event, eventSummary.overtimesHours, this._increasedHoursByDay, this._allHolidays);

            eventSummary.increasedHours = increasedHours;
        }

        // Calculate increased hours to vacations by hour (if not to increased: vacationIncreasedPercentByHour = undefined)
        if (!event.typeOfDayOff) {
            if (vacationIncreasedPercentByHour === 0) {
                eventSummary.vacationIncreasedHours = 0.0;
            } else if (vacationIncreasedPercentByHour !== undefined) {
                // TODO Test if second roundDecimals is integer
                eventSummary.vacationIncreasedHours = roundDecimals(eventSummary.effectiveHours * roundDecimals((vacationIncreasedPercentByHour / 100), 4), 4);
            }
        }

        eventSummary.totalHours = roundDecimals(eventSummary.effectiveHours + (eventSummary.vacationIncreasedHours ? eventSummary.vacationIncreasedHours : 0.0) + (eventSummary.increasedHours ? eventSummary.increasedHours : 0.0));

        if (event.typeOfDayOff) {
            const dayInMonthRecap = lstTypeDayOff?.find(u => u.typeDayOff?.id === event?.typeOfDayOff?.id);

            if (dayInMonthRecap === undefined) {
                showNotification(`Erreur avec le jour de repos ${event.typeOfDayOff.title} (${event.typeOfDayOff.id})`, "error");
                return;
            } else {
                // if (dayInMonthRecap?.previousBalance)
                //     dayOffFound.startBalance = dayInMonthRecap.previousBalance;

                let hoursToDays = 0.0;
                if (val(eventSummary.totalHours) > 0) {
                    //TODO Change this value to day correctly if dayWorkingHours != 0
                    // dayWorkingHours = val(dayWorkingHours, 1);
                    // hoursToDays = roundDecimals(val(event.summary?.totalHours) / dayWorkingHours);
                    hoursToDays = 1.0;
                }
                dayInMonthRecap.actualBalance = roundDecimals(val(dayInMonthRecap.actualBalance) + hoursToDays);
                dayInMonthRecap.nextBalance = roundDecimals(val(dayInMonthRecap.nextBalance) - hoursToDays);

            }
        }
        return eventSummary;
    }

    eventsDaysCalculateCct(events: SimpleEventCctSecurity[], lstTypeDayOff: CctSecurityTypeDayOffOverview[], vacationIncreasedPercentByHour?: number) {
        const cctSecurityEvent: CctSecurityUserHoursSummaryInDay = {};
        if (events.length === 0) return cctSecurityEvent;

        //! Todo summary later ?
        cctSecurityEvent.events = events;

        cctSecurityEvent.events.forEach(e => {
            e.summary = this.eventCalculateCct(e, lstTypeDayOff, vacationIncreasedPercentByHour);
        });

        cctSecurityEvent.date = events[0].dateFrom.clone().startOf("day");

        return cctSecurityEvent;
    }

    eventsMonthlyCalculateCct(events: SimpleEventCctSecurity[], prevBalance?: number, workRate?: number, monthlyHours?: number, month?: Moment, vacationIncreasedPercentByHour?: number, typesOfDaysOff?: TypeOfDayOff[]) {
        const hours: CctSecurityUserHoursSummaryInMonth = {};
        let todoHours: undefined | number = undefined;
        if (workRate !== undefined && monthlyHours !== undefined) {
            const workRateDecimal = roundDecimals(workRate / 100);
            todoHours = roundDecimals((workRateDecimal * monthlyHours));
        }
        // else if (workRate !== undefined) {

        // } else if (monthlyHours !== undefined) {

        // }

        const lstTypeDayOff: CctSecurityTypeDayOffOverview[] = [];

        typesOfDaysOff?.forEach(todo => {
            if (!lstTypeDayOff.find(u => u.typeDayOff?.id === todo.id)) {
                lstTypeDayOff.push({
                    typeDayOff: {
                        id: todo.id,
                        title: todo.title
                    },
                    previousBalance: todo.daysPerYear,
                    actualBalance: 0,
                    nextBalance: todo.daysPerYear,
                });
            }
        });

        hours.monthSummary = {
            lstTypeDayOff: lstTypeDayOff
        };

        // Calculate by day
        if (month) {
            hours.summaryByDay = new Array(month.daysInMonth());
            const eventsCloned = cloneDeep(events);
            const dayInCalculation = month.clone().startOf('month');
            const lastDayOfMonth = month.clone().endOf('month');
            while (dayInCalculation.isSameOrBefore(lastDayOfMonth, "day")) {
                const dayEvents: SimpleEventCctSecurity[] = remove(eventsCloned, event => {
                    return event.dateFrom.isSame(dayInCalculation, "day");
                });

                hours.summaryByDay[dayInCalculation.date() - 1] = this.eventsDaysCalculateCct(dayEvents, hours.monthSummary!.lstTypeDayOff!, vacationIncreasedPercentByHour);

                dayInCalculation.add(1, 'day');
            }
        }

        hours.totDynamicRateZone = cloneDeep(this._totalTravelDynamicRateZone);
        this._totalTravelDynamicRateZone = undefined;

        hours.totEngagementZone = cloneDeep(this._totalTravelEngagementZone);
        this._totalTravelEngagementZone = undefined;

        hours.totEngagementZone2 = cloneDeep(this._totalTravelEngagementZone2);
        this._totalTravelEngagementZone2 = undefined;

        hours.totFlatRateZone1 = cloneDeep(this._totalTravelFlatRateZone1);
        this._totalTravelFlatRateZone1 = undefined;

        hours.totFlatRateZone2 = cloneDeep(this._totalTravelFlatRateZone2);
        this._totalTravelFlatRateZone2 = undefined;

        const totalIncreasedHours = hours.summaryByDay?.reduce((total, day) => {
            if (day.events === undefined) return total;
            else return roundDecimals(total + day.events.reduce((subTotal, event) => subTotal + val(event.summary?.increasedHours), 0.0));
        }, 0.0);

        const totalVacationIncreasedHours = hours.summaryByDay?.reduce((total, day) => {
            if (day.events === undefined) return total;
            else return roundDecimals(total + day.events.reduce((subTotal, event) => subTotal + val(event.summary?.vacationIncreasedHours), 0.0), 4);
        }, 0.0);

        hours.monthSummary = {
            ...hours.monthSummary,
            todoHours: todoHours,
            eventHours: UserEventsData.eventsDuration(events),
            breaktimePaidBalance: UserEventsData.breaktimesDuration(events, BreaktimeCalculatedTypes.PAID),
            breaktimeNotPaidBalance: UserEventsData.breaktimesDuration(events, BreaktimeCalculatedTypes.NOTPAID),
            overtimeUndertimeBalance: UserEventsData.overtimesDuration(events, OvertimesCalculatedTypes.ALL, OvertimesCalculatedStatus.CONFIRMED),
            increasedHours: roundDecimals(val(totalIncreasedHours)),
            vacationIncreasedHours: roundDecimals(val(totalVacationIncreasedHours)),

            prevTotalHours: prevBalance ? roundDecimals(prevBalance) : prevBalance,
        };

        hours.monthSummary.effectiveHours = roundDecimals(val(hours.monthSummary.eventHours) - val(hours.monthSummary.breaktimeNotPaidBalance) + val(hours.monthSummary.overtimeUndertimeBalance));
        hours.monthSummary.totalHours = roundDecimals(hours.monthSummary.effectiveHours + val(hours.monthSummary.increasedHours) + val(hours.monthSummary.vacationIncreasedHours));
        hours.monthSummary.nextTotalHours = roundDecimals(val(prevBalance) + hours.monthSummary.totalHours - val(hours.monthSummary.todoHours));

        return hours;
    }


    eventsContractCaculateCct(contract: EventsByContractByMonth, monthlyHours?: MonthlyHoursOfTheYear, initialDaysOffByContractByYear?: InitialDaysOffCctSecurity[]) {
        const userHoursSummary: CctSecurityUserHoursSummaryByYear = {};
        let startMonth = this._startMonth?.clone();
        let endMonth = this._endMonth?.clone();

        if (startMonth === undefined || endMonth === undefined) {
            return undefined;
        } else if (contract.startDate !== undefined && contract.endDate !== undefined) {
            if (contract.startDate.isAfter(startMonth, "days")) {
                startMonth = contract.startDate.clone();
            }
            if (contract.endDate.isBefore(endMonth, "days")) {
                endMonth = contract.endDate.clone();
            }
        }
        const events = cloneDeep(contract.events);
        const monthInCalculation = startMonth.clone();
        let typesOfDaysOff = cloneDeep(this._typeOfDayOff);

        typesOfDaysOff = typesOfDaysOff?.map(todo => {
            const userDaysOff = initialDaysOffByContractByYear?.find(dayoff => dayoff.contractId === contract.id && dayoff.typeOfDayOffId === todo.id);
            if (userDaysOff) {
                return {
                    ...todo,
                    daysPerYear: userDaysOff.numberOfDays
                };
            } else {
                return todo;
            }
        });

        let prevBalance: number | undefined = contract.initialHours;
        userHoursSummary.startBalance = prevBalance;
        while (monthInCalculation.isSameOrBefore(endMonth, "month")) {
            let plannedMonthlyHours: number | undefined = undefined;
            const monthEvents: SimpleEventCctSecurity[] = remove(events, event => {
                return event.dateFrom.isSame(monthInCalculation, "month");
            });
            switch (monthInCalculation.format('M')) {
                case "1":
                    plannedMonthlyHours = monthlyHours?.janHours;
                    // last param , first day of month
                    userHoursSummary.janHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.janHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.janHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "2":
                    plannedMonthlyHours = monthlyHours?.febHours;
                    userHoursSummary.febHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.febHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.febHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "3":
                    plannedMonthlyHours = monthlyHours?.marHours;
                    userHoursSummary.marHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.marHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.marHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "4":
                    plannedMonthlyHours = monthlyHours?.aprHours;
                    userHoursSummary.aprHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.aprHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.aprHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "5":
                    plannedMonthlyHours = monthlyHours?.mayHours;
                    userHoursSummary.mayHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.mayHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.mayHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "6":
                    plannedMonthlyHours = monthlyHours?.junHours;
                    userHoursSummary.junHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.junHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.junHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "7":
                    plannedMonthlyHours = monthlyHours?.julHours;
                    userHoursSummary.julHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.julHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.julHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "8":
                    plannedMonthlyHours = monthlyHours?.augHours;
                    userHoursSummary.augHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.augHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.augHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "9":
                    plannedMonthlyHours = monthlyHours?.sepHours;
                    userHoursSummary.sepHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.sepHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.sepHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "10":
                    plannedMonthlyHours = monthlyHours?.octHours;
                    userHoursSummary.octHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.octHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.octHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "11":
                    plannedMonthlyHours = monthlyHours?.novHours;
                    userHoursSummary.novHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.novHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.novHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
                case "12":
                    plannedMonthlyHours = monthlyHours?.decHours;
                    userHoursSummary.decHours = this.eventsMonthlyCalculateCct(monthEvents, prevBalance, contract.workRate, plannedMonthlyHours, monthInCalculation, contract.vacationIncreasedPercentByHour, typesOfDaysOff);
                    prevBalance = userHoursSummary.decHours?.monthSummary?.nextTotalHours;
                    typesOfDaysOff = typesOfDaysOff?.map(todo => ({
                        ...todo,
                        daysPerYear: userHoursSummary.decHours?.monthSummary?.lstTypeDayOff?.find(l => l.typeDayOff?.id === todo.id)?.nextBalance
                    }));
                    break;
            }
            monthInCalculation.add(1, 'month');
        }

        userHoursSummary.endBalance = prevBalance;

        return userHoursSummary;
    }


    convertCctSecurityRawEventsToSimpleEvents(cctSecurityevents: CctSecurityRawEvent[]): SimpleEventCctSecurity[] {
        const simpleEvents: any = [];

        cctSecurityevents.forEach((cctEvent) => {
            simpleEvents.push({
                dateFrom: cctEvent.dateFrom,
                dateTo: cctEvent.dateTo,
                id: cctEvent.id,
                title: cctEvent.title,
                overtimes: cctEvent.overtime ? cctEvent.overtime : [],
                breaktimes: cctEvent.breaktime ? cctEvent.breaktime : [],
            });
        });

        return simpleEvents;
    }

    // TODO À adapter selon la logique userEventsData & hoursTabv2
    calculateDistanceZones(event: SimpleEventCctSecurity) {
        const travel: TravelSummary = {};
        if (event !== undefined) {
            //initializing necessary properties
            const distances: CctSecurityDistance[] = [
                { id: VehicleTypesIds.SERVICE, distance: 0 },
                { id: VehicleTypesIds.PERSONAL, distance: 0 },
                { id: VehicleTypesIds.PASSENGER, distance: 0 },
            ];
            if (this._totalTravelEngagementZone === undefined) {
                this._totalTravelEngagementZone = { travelZone: { id: 0, name: "Zone d'engagement Primaire" } };
                this._totalTravelEngagementZone.distances = cloneDeep(distances);
            }
            if (this._totalTravelFlatRateZone1 === undefined) {
                this._totalTravelFlatRateZone1 = { travelZone: { id: 1, name: "Zone forfaitaire 1" } };
                this._totalTravelFlatRateZone1.distances = cloneDeep(distances);
            }
            if (this._totalTravelFlatRateZone2 === undefined) {
                this._totalTravelFlatRateZone2 = { travelZone: { id: 2, name: "Zone forfaitaire 2" } };
                this._totalTravelFlatRateZone2.distances = cloneDeep(distances);
            }
            if (this._totalTravelEngagementZone2 === undefined) {
                this._totalTravelEngagementZone2 = { travelZone: { id: 3, name: "Zone d'engagement Secondaire" } };
                this._totalTravelEngagementZone2.distances = cloneDeep(distances);
            }
            if (this._totalTravelDynamicRateZone === undefined) {
                this._totalTravelDynamicRateZone = { travelZone: { id: 4, name: "Zone de Régie" } };
                this._totalTravelDynamicRateZone.distances = cloneDeep(distances);
            }



            if (event.distance === undefined || event.distance === null) {
                event.distance = 0;
            }

            if (event.poi && event.basePoi && event.distance >= 0) {
                //Is Primary engagement Zone
                if (event.basePoi.isPrimary) {
                    if (event.distance <= 10 || event.distance === 0) { //Zone d'engagement
                        travel.distanceTotal = event.distance * 2;
                        travel.compensationText = "Engagement";

                        //Increment distance to the good type of vehicle
                        const travelZone = this._totalTravelEngagementZone.distances?.find(v => v.id === event.vehicle?.type);
                        if (travelZone !== undefined) {
                            travelZone.distance += 1;
                        } else {
                            //TODO ALERTE Warning : No valide vehicule type
                        }
                    } else if (event.distance <= 20 /*&& event.distance >= 10.01*/) { //Zone forfaitaire 1
                        travel.distanceTotal = event.distance * 2;
                        travel.compensationText = "Forfaitaire 1";

                        //Increment distance to the good type of vehicle
                        const travelZone = this._totalTravelFlatRateZone1.distances?.find(v => v.id === event.vehicle?.type);
                        if (travelZone !== undefined) {
                            travelZone.distance += 1;
                        } else {
                            //TODO ALERTE Warning : No valide vehicule type
                        }
                    } else if (event.distance <= 30 /*&& actualEvent.deplacement.distanceOneWay >= 20.01*/) { //Zone forfaitaire 2
                        travel.distanceTotal = event.distance * 2;
                        travel.compensationText = "Forfaitaire 2";

                        //Increment distance to the good type of vehicle
                        const travelZone = this._totalTravelFlatRateZone2.distances?.find(v => v.id === event.vehicle?.type);
                        if (travelZone !== undefined) {
                            travelZone.distance += 1;
                        } else {
                            //TODO ALERTE Warning : No valide vehicule type
                        }
                    } else if (event.distance >= 30.01) { //Zone de régie
                        travel.distanceTotal = event.distance * 2;
                        travel.distanceTotalCompensated = (event.distance * 2) - (2 * 10);
                        travel.compensationText = "Régie";

                        //Adds Compensated distance to the good type of vehicle
                        const travelZone = this._totalTravelDynamicRateZone.distances?.find(v => v.id === event.vehicle?.type);
                        if (travelZone !== undefined) {
                            travelZone.distance += travel.distanceTotalCompensated;
                        } else {
                            //TODO ALERTE Warning : No valide vehicule type
                        }
                    } else {
                        // TODO Alerte Error : distance not valid (Check negatif distance and distance format)
                    }
                } else {
                    const more40km = (event.basePoi.distanceFromPrimary !== undefined && event.basePoi.distanceFromPrimary >= 40);
                    console.log('PLOUFFF: ', more40km, event);
                    if (more40km) {
                        //TODO Code for the secondary place of engagement that is further than 40km away from the primary engagement zone
                        //! Need distance between Primary and secondary engagement zone

                        if (event.distance <= 10 || event.distance === 0) { //Zone d'engagement
                            travel.distanceTotal = event.distance * 2;
                            travel.distanceTotalCompensated = (event.basePoi.distanceFromPrimary ? event.basePoi.distanceFromPrimary * 2 : 40 * 2) - (2 * 40);
                            travel.compensationText = "Engagement secondaire";

                            //Increment distance to the good type of vehicle
                            const travelZone = this._totalTravelEngagementZone2.distances?.find(v => v.id === event.vehicle?.type);
                            if (travelZone !== undefined) {
                                travelZone.distance += travel.distanceTotalCompensated;
                            } else {
                                //TODO ALERTE Warning : No valide vehicule type
                            }
                        } else {
                            travel.distanceTotal = event.distance * 2;
                            travel.distanceTotalCompensated = 0;
                            travel.compensationText = "INVALIDE";
                        }


                        //end more40km
                    } else {
                        if (event.distance <= 10 || event.distance === 0) {
                            travel.distanceTotal = event.distance * 2;
                            travel.compensationText = "Engagement";

                            const travelZone = this._totalTravelEngagementZone.distances?.find(v => v.id === event.vehicle?.type);
                            if (travelZone !== undefined) {
                                travelZone.distance += 1;
                            } else {
                                //TODO ALERTE Warning : No valide vehicule type
                            }

                        } else {
                            // travel.distanceTotal = event.distance * 2;
                            // travel.distanceTotalCompensated = (event.distance * 2) - (2 * 10);
                            // travel.compensationText = "Régie";

                            // let travelZone = this._totalTravelDynamicRateZone.distances?.find(v => v.id === event.vehicle?.type);
                            // if (travelZone !== undefined) {
                            //     travelZone.distance += travel.distanceTotalCompensated;
                            // } else {
                            //     //TODO ALERTE Warning : No valide vehicule type
                            // }
                            travel.distanceTotal = event.distance * 2;
                            travel.distanceTotalCompensated = 0;
                            travel.compensationText = "INVALIDE";

                            //TODO ALERTE Error : Invalid distance / engagement zone
                        }
                    }
                }
            } else {
                // TODO Alerte Error           
            }

            return travel;
        }
    }
}