import React from 'react'
import Modal from 'antd/lib/modal/Modal';
import { Button, Select, DatePicker } from 'antd';
import { PlanningOvertime } from '../../utils/types/planningTypes';
import { displayErrorMessage, cloneOvertime } from '../../utils/utils';
import { ApplicationState } from '../../utils/types/storeTypes';
import { connect } from 'react-redux';
import { User } from '../../utils/types/generalTypes';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';

enum DataType { User, Title, Range }

interface Props {
    overtime: PlanningOvertime;
    users: User[] | undefined;
    loading: boolean;
    onCancel: () => void;
    onDone: (overtime: PlanningOvertime) => void;
}

interface State {
    overtime: PlanningOvertime;
}

/**
 * Component that represent the modal to create and edit an overtime
 */
class OvertimeModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            overtime: cloneOvertime(props.overtime),
        };
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        const { overtime } = this.state;

        switch (type) {
            case DataType.User:
                overtime.userId = value;
                break;
            case DataType.Title:
                overtime.title = value.target.value;
                break;
            case DataType.Range:
                overtime.startDate = value ? value[0] : undefined;
                overtime.endDate = value ? value[1] : undefined;
                break;
        }

        this.setState({ overtime });
    }

    /**
     * Called when the user is done editing/ creating
     */
    onDone = () => {
        const { overtime } = this.state;
        if (overtime.startDate.diff(overtime.endDate, 'minutes') === 0) displayErrorMessage("Les dates de début et de fin ne peuvent pas être pareilles.", "overtime-modal-ok-button");
        else this.props.onDone(overtime);
    }

    render() {
        const { overtime } = this.state;
        return (
            <Modal
                className="planning-modal"
                title={this.props.overtime.id ? (this.props.overtime.title && this.props.overtime.title.length > 0 ? this.props.overtime.title : "Modifier des heures supplémentaires") : "Ajouter des heures supplémentaires"}
                visible={Boolean(overtime)}
                style={{ minWidth: '620px' }}
                onCancel={this.props.onCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="dashed" onClick={this.props.onCancel} key="overtime-modal-cancel-button">
                        {'Annuler'}
                    </Button>,
                    <Button id="overtime-modal-ok-button" type="primary" onClick={this.onDone} loading={this.props.loading} key="planning-modal-button-ok">
                        {this.props.overtime.id ? 'Sauvegarder' : 'Créer'}
                    </Button>
                ]}>
                <div className="planning-overtime-modal-section">
                    <p>{"Utilisateur"}</p>
                    <Select
                        className="planning-overtime-modal-input"
                        showSearch
                        onChange={id => this.dataChanged(DataType.User, id)}
                        value={this.state.overtime.userId}
                        filterOption={true}
                        optionFilterProp="label">
                        {this.props.users!.map(u => <Select.Option label={`${u.last_name} ${u.first_name}`} key={`planning-overtime-user-${u.id}`} value={u.id!}>{`${u.last_name} ${u.first_name}`}</Select.Option>)}
                    </Select>
                </div>
                <div className="planning-overtime-modal-section" style={{ margin: '20px 0px' }}>
                    <p>{"Début et de fin"}</p>
                    <DatePicker.RangePicker
                        allowClear={false}
                        className="planning-overtime-modal-input"
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ format: "HH:mm" }}
                        placeholder={["Date de début", "Date de fin"]}
                        onChange={d => this.dataChanged(DataType.Range, d)}
                        value={[overtime.startDate ? overtime.startDate : undefined, overtime.endDate ? overtime.endDate : undefined] as any} />
                </div>
                <div className="planning-overtime-modal-section">
                    <p>{"Titre"}</p>
                    <InputField
                        className="planning-overtime-modal-input"
                        placeholder={"Titre"}
                        allowClear
                        value={overtime.title}
                        onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(DataType.Title, e)} />
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
});

export default connect(mapStateToProps)(OvertimeModal);