
import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';

const VirtualTable = (props) => {
    const [vt, /*set_components*/] = useVT(() => ({
        scroll:
        {
            y: props.scroll?.y ?? 600,
            // itemSize: props.itemSize,            // Set your desired row height
            overscanRowCount: 3,    // Number of rows to render outside the visible area
            rowCount: props.dataSource?.length,
        },
    }), [props.scroll?.y, props.dataSource?.length]);


    return (
        <Table
            {...props}
            className={`${props.className} __virtual-table`}
            pagination={false}
            components={vt}
            scroll={props.dataSource === undefined || props.dataSource.length === 0 ? {} : props.scroll}
        />
    );
};

export default VirtualTable;