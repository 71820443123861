import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Spin } from 'antd';
import range from 'lodash/range';
import * as momentJs from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { RouterProps, User, UserSummary } from '../../utils/types/generalTypes';
import { CellEvent, CellIndexes, PlanningCalendarData, PlanningCellDay, PlanningSettings, Project } from '../../utils/types/planningTypes';
import { ApplicationState, PlanningDispatchProps } from '../../utils/types/storeTypes';
import { checkPrivilege, checkRBACRule } from '../../utils/utils';
import Can from '../common/general/can';
import Cell from './cell';

const moment = extendMoment(momentJs);

interface IProps {
    currentUser: User | undefined;
    currentProjects: Project[] | undefined;
    calendarData: PlanningCalendarData;
    displayDayView: boolean;
    onClickCell: (date: Moment, user: UserSummary) => void;
    onDropCell: (cellEvent: CellEvent, date: Moment, user: UserSummary) => void;
    onAddOvertime: (user: UserSummary) => void;
    onAddUndertime: (user: UserSummary) => void;
    // Redux
    isSmartphone: boolean;
    settings: PlanningSettings,
    draggedGroupEventUserId?: number;
    draggedUserEventUserId?: number;
    loading: boolean;
}

type Props = IProps & PlanningDispatchProps & RouterProps;

interface State {
    today: Moment;
    currentHour: number;
    interval: NodeJS.Timeout;
}

/**
 * Component that represent the calendar of the planning
 */
class Calendar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            today: moment().startOf('day'),
            currentHour: moment().startOf('hour').hours(),
            interval: setInterval(() => this.resetInterval(), (60 - moment().minutes()) * 60000),
        }
    }

    componentWillUnmount() {
        //clear interval
        clearInterval(this.state.interval);
    }


    /**
     * Reset the one hour interval
     */
    resetInterval = () => {
        clearInterval(this.state.interval);
        const interval: NodeJS.Timeout = setInterval(() => this.setState({ currentHour: moment().startOf('hour').hours() }), 3600000);
        this.setState({ interval, currentHour: moment().startOf('hour').hours() });
    }

    /**
     * Get row height according to number of rows (days)
     */
    getRowHeight = (): string => {
        const length = this.props.settings.workingDaysOfWeek?.length;
        if (this.props.match.params.dayView) return (this.props.settings.showAvatars ? '120px' : '70px')
        else if (length && length <= 4) return `${200 / length + 10}px`;
        else return this.props.match.params.dayView ? (this.props.settings.showAvatars ? '120px' : '70px') : '43px';
    }

    /**
     * Check if the index is within cell indexes
     * @param index the index to check
     * @param cellIndexes the cell indexes
     * @returns true if the index is within the cell indexes, false otherwise
     */
    withinPeriod = (index: number, cellIndexes: CellIndexes[]): boolean => {
        for (let i = 0; i < cellIndexes.length; ++i) {
            if (index >= cellIndexes[i].startCell && index <= cellIndexes[i].endCell) return true;
        }
        return false;
    }

    /**
     * Called when a event is dropped
     * @param cellEvent the conserned cell event
     * @param date the date corresponding to the cell
     * @param user the concerned user
     * @param index the index of the cell on which the event was dropped
     */
    onDropCell = (cellEvent: CellEvent, date: Moment, user: UserSummary, index: number) => {
        if (cellEvent.startCell === index && this.props.draggedUserEventUserId === user.id) return;
        this.props.onDropCell(cellEvent, date, user);
    }

    render() {
        const { calendarData, displayDayView } = this.props;
        const { cellsPerRow } = calendarData;

        return (
            <>
                <Spin
                    className="planning-calendar-spinner"
                    size="large"
                    spinning={this.props.loading} />
                <table
                    style={this.props.loading ? { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' } : undefined}
                    className="planning-calendar-table">
                    <thead>
                        <tr className="planning-calendar-header">
                            <th className="planning-calendar-fixed-users planning-calendar-fixed-header" style={{ minWidth: this.props.isSmartphone ? '60px' : '150px', zIndex: 4 }}>{this.props.isSmartphone ? "Util." : "Utilisateur"}</th>
                            <th className="planning-calendar-fixed-dates planning-calendar-fixed-header" style={{ minWidth: this.props.isSmartphone ? '40px' : '150px', zIndex: 4 }}>{"Jour"}</th>
                            {range(calendarData.currentStartHour, calendarData.currentEndHour).map((h: number) => <th className="planning-calendar-fixed-header" colSpan={2} key={`planning-header-${h}`} style={{ backgroundColor: this.state.currentHour === h ? "var(--light-primary-color)" : undefined }}>{`${h < 10 ? '0' : ''}${h}:00`}</th>)}
                        </tr>
                    </thead>
                    {
                        calendarData.calendarUserData.map(data => {
                            const { user, days, cellPeriods, cellVacations, cellUserVacations } = data;
                            let currentCell = -cellsPerRow;
                            const rowHeight = this.getRowHeight();
                            return (
                                <Dropdown key={`planning-context-menu-${user.id}`} overlay={
                                    <Menu>
                                        {
                                            (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) || checkPrivilege(Privileges.Planning.Edition, this.props.currentUser)) &&
                                            <Menu.Item onClick={() => this.props.onAddOvertime(user)} key={`planning-add-overtime-${user.id}`}>{"Ajouter des heures supplémentaires"}</Menu.Item>
                                        }
                                        {
                                            (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) || checkPrivilege(Privileges.Planning.Edition, this.props.currentUser)) &&
                                            <Menu.Item onClick={() => this.props.onAddUndertime(user)} key={`planning-add-undertime-${user.id}`}>{"Ajouter des heures négatives"}</Menu.Item>
                                        }
                                    </Menu>
                                } trigger={['contextMenu']}>
                                    <tbody className="planning-calendar-user-row" key={`planning-user-row-${user.id}`}>
                                        {
                                            days.map((day: PlanningCellDay, index: number) => {
                                                currentCell += cellsPerRow;

                                                return (
                                                    <tr style={{ height: rowHeight }} key={`planning-row-${user.id}-${index}`}>
                                                        {
                                                            index === 0 &&
                                                            <td className={`planning-calendar-fixed-users ${displayDayView ? 'planning-calendar-day-fixed-users' : ''}`} rowSpan={7}>
                                                                <Can rule={Rules.Planning.Management} otherwise={
                                                                    <div className="planning-calendar-user" style={{ minHeight: displayDayView ? (this.props.settings.showAvatars ? '80px' : '50px') : '200px' }}>
                                                                        <span className="planning-calendar-avatar-container" style={{ cursor: 'auto', width: displayDayView ? '50px' : '100px', height: displayDayView ? '50px' : '100px' }}>
                                                                            <Avatar src={user.image} icon={<UserOutlined />} className="planning-calendar-avatar" />
                                                                        </span>
                                                                        <p className="planning-calendar-user-name" style={{ cursor: 'auto' }}>
                                                                            {`${user.last_name} ${user.first_name}`}
                                                                        </p>
                                                                    </div>
                                                                }>
                                                                    <div className="planning-calendar-user" style={{ minHeight: displayDayView ? (this.props.settings.showAvatars ? '80px' : '50px') : '200px' }}>
                                                                        {
                                                                            (!displayDayView || this.props.settings.showAvatars) &&
                                                                            <span className="planning-calendar-avatar-container" style={{ width: displayDayView ? '50px' : '100px', height: displayDayView ? '50px' : '100px' }} onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${user.id}`)}>
                                                                                <Avatar
                                                                                    src={user.image}
                                                                                    icon={<UserOutlined />}
                                                                                    className="planning-calendar-avatar" />
                                                                            </span>
                                                                        }
                                                                        <p
                                                                            className="planning-calendar-user-name"
                                                                            onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${user.id}`)}>
                                                                            {`${user.last_name} ${user.first_name}`}
                                                                        </p>
                                                                    </div>
                                                                </Can>
                                                            </td>
                                                        }
                                                        <td className="planning-calendar-fixed-dates" style={{ textAlign: "center", backgroundColor: day.date.startOf('day').diff(this.state.today, 'days') === 0 ? "var(--light-primary-color)" : undefined }}>{day.date.format(this.props.isSmartphone ? 'DD' : 'ddd DD MMM')}</td>
                                                        {
                                                            range(currentCell, currentCell + cellsPerRow).map((index: number) => {
                                                                const isVacations = this.withinPeriod(index, cellVacations);
                                                                const isUserVacations = this.withinPeriod(index, cellUserVacations);
                                                                const isPeriod = this.withinPeriod(index, cellPeriods);
                                                                return <Cell
                                                                    currentProjects={this.props.currentProjects}
                                                                    day={day}
                                                                    index={index}
                                                                    userId={user.id}
                                                                    isPeriod={isPeriod}
                                                                    isVacations={isVacations}
                                                                    isUserVacations={isUserVacations}
                                                                    canDrop={isPeriod && !isUserVacations && (this.props.draggedUserEventUserId === user.id || this.props.draggedGroupEventUserId === user.id)}
                                                                    onClick={(date: Moment) => this.props.onClickCell(date, user)}
                                                                    onDrop={(cellEvent: CellEvent, date: Moment) => this.onDropCell(cellEvent, date, user, index)}
                                                                    key={`planning-cell-${user.id}-${index}`} />
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Dropdown>
                            );
                        })
                    }
                </table>
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    settings: state.planning.settings,
    draggedGroupEventUserId: state.planning.draggedGroupEventUserId,
    draggedUserEventUserId: state.planning.draggedUserEventUserId,
    loading: state.user.loading,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(Calendar));