import moment, { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import getFormat from "../../../utils/Lang";

export const Clock = () => {
    const [clockTimer, setClockTimer] = useState<Moment>(moment())
    const clockInterval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)


    const timer = useCallback(() => {
        setClockTimer(moment())
    }, [])

    const launchInterval = useCallback((time = 1000) => {
        if (clockInterval.current === undefined) {
            clockInterval.current = setInterval(() => {
                timer()
            }, time);
        }
    }, [timer])

    useEffect(() => {
        if (clockInterval.current === undefined) {
            launchInterval(1000)
        }


    }, [timer, launchInterval])

    useEffect(() => {
        return () => {
            if (clockInterval.current !== undefined)
                clearInterval(clockInterval.current as NodeJS.Timeout)
            clockInterval.current = undefined
        };
    }, []);



    return <p data-testid="clock-span">{clockTimer?.format(getFormat('DATE_LONG_AND_TIME_LONG'))}</p>

}
export default Clock