
import { Card, Col, Row, Space, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import changelogFile from '../../files/changelog.md';
import CheckVersionButton from '../common/fields/checkVersionButton';


interface RProps {
    url: string;
    getChangelog: (url: string, callback: any) => void
    text: string | undefined;
    setText(value: string | undefined): void
}

export const Reader = (props: RProps) => {
    const getChangelog = useCallback((url: string, callback: any) => props.getChangelog(url, callback), [props])
    const [textLoading, setTextLoading] = useState<boolean>(false)
    const setText = useCallback((value: string | undefined) => props.setText(value), [props])
    useEffect(() => {
        if (!textLoading && props.text === undefined) {
            setTextLoading(true)
            getChangelog(props.url, (text: string) => {
                setText(text)
                setTextLoading(false)
            })
        }
    }, [props.url, getChangelog, textLoading, props.text, setText])

    return (
        <>
            <Spin spinning={textLoading || !props.text}>
                <ReactMarkdown>{props.text ? props.text : ''}</ReactMarkdown>
            </Spin>
        </>
    )
}
// interface IProps {
// }


const Version = () => {

    // Redux dispatch
    const [textTab] = useState<string>('admin')

    const intl = useIntl();

    const [textAdmin, setTextAdmin] = useState<string | undefined>(undefined)
    const [textApp, setTextApp] = useState<string | undefined>(undefined)
    const [textBack, setTextBack] = useState<string | undefined>(undefined)
    const getChangelog = useCallback((url: string, callback: any) => {

        fetch(url)
            .then((response) => response.text())
            .then((md) => {
                callback(md.substring(md.indexOf("\n") + 1))
            })
    }, [])


    const refreshReleaseNotes = useCallback(() => {
        setTextAdmin(undefined)
        setTextApp(undefined)
        setTextBack(undefined)
    }, [])


    return (
        <Row gutter={[10, 10]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Card
                    className='big-title-antd-card'
                    title={<FormattedMessage defaultMessage={'Release notes'} />}
                    extra={[
                        <Space key={'card-version-reader-space-key'}>
                            <CheckVersionButton onLoading={refreshReleaseNotes} updatingButtonTooltip={intl.formatMessage({ defaultMessage: 'Install update' })} updatingButtonTitle={intl.formatMessage({ defaultMessage: 'Install' })} loadingButtonTitle={intl.formatMessage({ defaultMessage: 'Search' })} tooltipTitle={intl.formatMessage({ defaultMessage: 'Check for new updates' })} />
                        </Space>
                    ]}
                >
                    {textTab === 'backend' ?
                        <Reader text={textBack} setText={setTextBack} key={'reader-backend-key'} url={(process.env.REACT_APP_NODE !== 'dev' ? process.env.REACT_APP_BACK_BASE_URL : 'https://preprod.api.sunkhro.com') + '/static/media/changelog.md'} getChangelog={getChangelog} />
                        :
                        textTab === 'app' ?
                            <Reader text={textApp} setText={setTextApp} key={'reader-app-key'} url={process.env.REACT_APP_FRONT_APP_BASE_URL + '/changelog.md'} getChangelog={getChangelog} />
                            :
                            <Reader text={textAdmin} setText={setTextAdmin} key={'reader-admin-key'} url={process.env.REACT_APP_NODE !== 'dev' ? '/changelog.md' : changelogFile} getChangelog={getChangelog} />
                    }
                </Card>
            </Col>

        </Row>
    );
}

export default Version;
