import { InfoCircleOutlined } from '@ant-design/icons';
import { Tabs, TabsProps, Tooltip } from 'antd';
import React from 'react';
import { BiDevices } from 'react-icons/bi';
import { RiMapPinUserLine } from 'react-icons/ri';
import { TbClockCheck, TbClockEdit, TbTemplate } from 'react-icons/tb';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { CompanyDetailsPrivilege, Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { changeGroups } from '../../store/actions/teamManagement';
import { changeCompany } from '../../store/actions/user';
import { Company, Group, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps, UserDispatchProps } from '../../utils/types/storeTypes';
import { checkCompanyDetailsPrivilege, checkPrivilege, checkRBACRule } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import Anticon from '../common/general/anticon';
import Container from '../common/navigations/container';
import SunkhronosLiveMap from '../sunkhronosLive/sunkhronosLiveMap';
import OvertimesControl from './tabs/overtimesControl';
import TimeclockControl from './tabs/timeclockControl';
import TimeclockDevices from './tabs/timeclockDevices';
import TimeclockTemplates from './tabs/timeclockTemplates';


type ReduxProps = ConnectedProps<typeof connector>

interface IProps {

}

type Props = RouterProps & IProps & ReduxProps & TeamManagementDispatchProps & UserDispatchProps & IntlProps;

interface State {
    type: 'timeclock' | 'overtimes';
}
enum TabKeys {
    TimeClockConfirmation = "timeclock-confirmation",
    TimeClockTemplates = "timeclock-templates",
    TimeClockDevice = "timeclock-devices",
    OverTimesConfirmation = "overtimes-confirmation",
    PositionControl = "position-control"

}
const TABS = {
    TIMECLOCK_CONFIRMATION: 'timeclock-confirmation',
    TIMECLOCK_TEMPLATES: 'timeclock-templates',
    TIMECLOCK_DEVICES: 'timeclock-devices',
    OVERTIMES_CONFIRMATION: 'overtimes-confirmation',
    POSITION_CONTROL: 'position-control'
}

class WorkControl extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            type: checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser) ? 'timeclock' : 'overtimes',
        };
    }



    /**
     * Go to a specific page
     * @param link the link of the page to go to
     */
    goTo = (link: string): void => this.props.history.push(`/${this.props.match.params.lang}/${link}`)
    onChangeTab = (tab: string): void => {
        this.goTo('workcontrol/' + tab);
    }
    getCurrentTab = () => {
        switch (this.props.match.params.tab) {
            case TABS.TIMECLOCK_CONFIRMATION:
            case TABS.TIMECLOCK_DEVICES:
            case TABS.TIMECLOCK_TEMPLATES:
            case TABS.OVERTIMES_CONFIRMATION:
            case TABS.POSITION_CONTROL:
                return this.props.match.params.tab
            default:
                this.goTo(`workcontrol/${this.state.type === 'timeclock' ? TABS.TIMECLOCK_CONFIRMATION : TABS.OVERTIMES_CONFIRMATION}`)
        }
        this.goTo(`workcontrol/${this.state.type === 'timeclock' ? TABS.TIMECLOCK_CONFIRMATION : TABS.OVERTIMES_CONFIRMATION}`)
    }

    render() {
        const { fullscreen, intl } = this.props;

        let items: TabsProps['items'] = [
            {
                key: TabKeys.TimeClockConfirmation,
                label: <span><span><TbClockCheck className='anticon' /><FormattedMessage defaultMessage={'Confirm timeclock'} /></span>
                    <Tooltip title={intl.formatMessage({ defaultMessage: 'Administrators can validate clocked events. Any information validated or modified will change the event' })}>
                        <InfoCircleOutlined className="tab-tooltip-info" />
                    </Tooltip>
                </span>,
                children: <TimeclockControl />
            },
            {
                key: TabKeys.TimeClockDevice,
                disabled: false,
                label: <span><BiDevices className='anticon' /><FormattedMessage defaultMessage={'Timeclocks'} /></span>,
                children: <TimeclockDevices />,
            },
            {
                key: TabKeys.TimeClockTemplates,
                disabled: false,
                label: <span><Anticon><TbTemplate /></Anticon><FormattedMessage defaultMessage={'Templates'} /></span>,
                children: <TimeclockTemplates />,
            },
            {
                key: TabKeys.OverTimesConfirmation,
                disabled: false,
                label: <span><TbClockEdit className='anticon' /><FormattedMessage defaultMessage={'Overtimes'} /></span>,
                children: <OvertimesControl />,
            },

        ];
        items = (checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser)) ? items.filter(i => [TabKeys.TimeClockConfirmation, TabKeys.TimeClockDevice, TabKeys.TimeClockTemplates].includes(i.key as any)) : items
        items = (checkPrivilege(Privileges.Planning.Visit, this.props.currentUser) && !checkCompanyDetailsPrivilege(CompanyDetailsPrivilege.TimeClock.Visit, this.props.company, this.props.currentUser)) ? items.filter(i => i.key === TabKeys.OverTimesConfirmation) : items
        //items = (this.props.currentUser?.role === 1) ? items.filter(i=> i.key !== TabKeys.OverTimesConfirmation) : items
        if (checkRBACRule(Rules.SunkhronosLive.Visit, this.props.currentUser?.role, this.props.currentUser?.company_id)) {
            items.push({
                key: TabKeys.PositionControl,
                disabled: false,
                label: <span><RiMapPinUserLine className='anticon' /><FormattedMessage defaultMessage={'Position control'} /></span>,
                children: <SunkhronosLiveMap />,
            })
        }
        items = (!this.props.currentUser?.role || ![2, 3].includes(this.props.currentUser?.role)) ? items.filter(item => item.key !== TabKeys.TimeClockDevice) : items
        return (
            fullscreen ?
                <>{items.find(i => i.key === this.getCurrentTab())?.children}</>
                :
                <Container className={`container-content-background-timeclock`} breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Timeclock' }), link: "" }]}>
                    <Tabs activeKey={this.getCurrentTab()} tabBarStyle={{ display: fullscreen ? 'none' : '' }} onChange={this.onChangeTab} items={items} className="timeclock-tabs-parent" />
                </Container>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeCompany: (c: Company) => dispatch(changeCompany(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    fullscreen: state.window.fullscreen,
    windowWidth: state.window.width,
    groups: state.teamManagement.groups,
    company: state.user.company,
    currentUser: state.user.currentUser,
});

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(injectIntl(WorkControl));
