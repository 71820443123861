import { ArrowLeftOutlined, CarryOutOutlined, HourglassOutlined, IdcardOutlined, InfoCircleOutlined, RocketOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';
import { Spin, Tabs, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { Privileges } from '../../../privileges';
import { Rules } from '../../../rbacRules';
import { changeUser, changeUsersGroups, resetAllAppartGroups } from '../../../store/actions/teamManagement';
import Network from '../../../utils/network';
import { Group, RouterProps, TypeOfContract, User } from '../../../utils/types/generalTypes';
import { PlanningExclusion, PlanningPeriod } from '../../../utils/types/planningTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { checkPrivilege, convertNetworkExclusionsToPlanningExclusions, convertNetworkPeriodsToPlanningPeriods, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import Can from '../../common/general/can';
import Container from '../../common/navigations/container';
import UserAvailabilityTab from '../userAvailability/userAvailabilityTab';
import UserJobTab from '../userJobTab';
import EditUserTab from './editUserTab';
import GroupsUserTab from './groupsUser';
import UserHoursTab from './userHoursTab';
import UserVacationsTab from './userVacationsTab';

//Keys for the different tabs
enum TabKeys {
    Informations = "i",
    Groups = "g",
    Availability = "a",
    Periods = "p",
    Exclusions = "e",
    Vacations = "v",
    Heures = "h",
    UserJobs = "u",
}

//Links for the different tabs
enum TabLink {
    Informations = "informations",
    Groups = "groups",
    Availability = "availabilities",
    Periods = "periods",
    Exclusions = "exclusions",
    Vacations = "vacations",
    Heures = "heures",
    UserJobs = "userjobs",
}

type ReduxProps = ConnectedProps<typeof connector>
interface IProps {
    isSmartphone: boolean;
    currentUser: User | undefined;
    windowWidth: number;
    user: User | undefined;
    typesOfContract: TypeOfContract[] | undefined;
}

type Props = IProps & RouterProps & TeamManagementDispatchProps & ReduxProps & IntlProps;

interface State {
    userId: number;
    periods: PlanningPeriod[] | undefined;
    exclusions: PlanningExclusion[] | undefined;
    periodsLoading: boolean;
    exclusionsLoading: boolean;

}

/**
 * Page for a user's details
 */
class UserDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let userId = parseInt(this.props.location.search.replace("?id=", ""));

        if (isNaN(userId)) {
            const index = this.props.location.pathname.indexOf("?id=") + 4;
            userId = parseInt(this.props.location.pathname.slice(index));
        }

        this.state = {


            userId,
            periods: undefined,
            exclusions: undefined,
            periodsLoading: false,
            exclusionsLoading: false,
        }
    }

    componentDidMount() {

        //redirect if no group id was found
        if (isNaN(this.state.userId)) this.props.history.push(`/${this.props.match.params.lang}/team-management/list`);

        //get current user's details
        Network.getUser(this.state.userId).then(
            response => this.props.changeUser(response),
            () => this.props.history.push(`/${this.props.match.params.lang}/team-management/list`)
        );

        //get user's groups
        Network.getGroups(undefined, this.state.userId).then(
            response => this.props.changeUsersGroups(response),
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the groups' }), "warning"),
        );

        //get periods and exclusions
        this.setState({ periodsLoading: true, exclusionsLoading: true });
        this.refreshPeriods(() => this.setState({ periodsLoading: false }), () => this.setState({ periodsLoading: false }));
        this.refreshExclusions(() => this.setState({ exclusionsLoading: false }), () => this.setState({ exclusionsLoading: false }));

    }

    componentWillUnmount() {
        //reset team management stored value
        this.props.reset!();
    }


    /**
     * Refresh the periods
     * @param successCallback a callback function in case of successful network refresh call - optional
     * @param failedCallback a callback function in case of failed network refresh call - optional
     */
    refreshPeriods = (successCallback?: () => void, failedCallback?: () => void): void => {
        //get current user's periods
        if (this.state.userId !== undefined) {
            Network.getPeriods(this.state.userId).then(
                response => {
                    this.setState({ periods: convertNetworkPeriodsToPlanningPeriods(response) });
                    if (successCallback) successCallback();
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning");
                    if (failedCallback) failedCallback();
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the periods' }), "warning")
        }
    }

    /**
     * Refresh the exclusions
     * @param successCallback a callback function in case of successful network refresh call - optional
     * @param failedCallback a callback function in case of failed network refresh call - optional
     */
    refreshExclusions = (successCallback?: () => void, failedCallback?: () => void) => {
        //get current user's exclusions
        if (this.state.userId !== undefined && this.state.userId !== null) {
            Network.getExclusions(this.state.userId).then(
                response => {
                    this.setState({ exclusions: convertNetworkExclusionsToPlanningExclusions(response) });
                    if (successCallback) successCallback();
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the exclusions' }), "warning");
                    if (failedCallback) failedCallback();
                }
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the exclusions' }), "warning")
        }
    }

    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        switch (this.props.match.params.tab) {
            case TabLink.Periods:
                return TabKeys.Periods;
            case TabLink.Availability:
                return TabKeys.Availability;
            case TabLink.Exclusions:
                return TabKeys.Exclusions;
            case TabLink.Groups:
                return TabKeys.Groups;
            case TabLink.Vacations:
                return TabKeys.Vacations;
            case TabLink.Heures:
                return TabKeys.Heures;
            case TabLink.UserJobs:
                return TabKeys.UserJobs;
            default:
                return TabKeys.Informations;
        }
    }

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (key) {
            case TabKeys.Periods:
                link = TabLink.Periods;
                break;
            case TabKeys.Availability:
                link = TabLink.Availability;
                break;
            case TabKeys.Exclusions:
                link = TabLink.Exclusions;
                break;
            case TabKeys.Groups:
                link = TabLink.Groups;
                break;
            case TabKeys.Vacations:
                link = TabLink.Vacations;
                break;
            case TabKeys.Heures:
                link = TabLink.Heures;
                break;
            case TabKeys.UserJobs:
                link = TabLink.UserJobs;
                break;
            default:
                link = TabLink.Informations;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/team-management/user-details/${link}?id=${this.state.userId}`);
    }

    render() {
        const { user, isSmartphone, intl } = this.props;
        const currentTab = this.getCurrentTab();

        return (
            <Can rule={Rules.TeamManagement.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Team managment' }), link: "/team-management/list" }, { title: user?.id ? `${user.last_name} ${user.first_name}` : intl.formatMessage({ defaultMessage: 'No match' }), link: `/team-management/user-details?id=${this.state.userId}` }]}>
                    <div className="team-parent">
                        {
                            this.props.user ?
                                <Tabs
                                    tabBarExtraContent={isSmartphone ? undefined :
                                        {

                                            left: (
                                                <div style={{ marginRight: '10px' }}>
                                                    <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => this.props.history.replace(`/${this.props.match.params.lang}/team-management/list/users`)} />
                                                </div>
                                            ),
                                            right: (
                                                <div style={{ marginLeft: '10px', maxWidth: '180px' }}>
                                                    <p title={`${this.props.user.first_name} ${this.props.user.last_name}`} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: '100%', fontWeight: 'bold', textTransform: 'uppercase' }}>{`${this.props.user.first_name} ${this.props.user.last_name}`}</p>
                                                </div>
                                            )
                                        }
                                    }
                                    destroyInactiveTabPane
                                    animated
                                    activeKey={currentTab}
                                    onChange={this.onChangeTab}
                                >
                                    <Tabs.TabPane key={TabKeys.Informations} tab={
                                        <span>
                                            <IdcardOutlined />
                                            <FormattedMessage defaultMessage={'Informations'} />
                                        </span>
                                    }>
                                        <EditUserTab />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key={TabKeys.Groups} tab={
                                        <span>
                                            <TeamOutlined />
                                            <FormattedMessage defaultMessage={'Groups'} />
                                        </span>
                                    }>
                                        <GroupsUserTab />
                                    </Tabs.TabPane>
                                    {
                                        checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                                        <Tabs.TabPane key={TabKeys.Availability} tab={
                                            <span>
                                                <CarryOutOutlined />
                                                <FormattedMessage defaultMessage={'Availabilities'} />
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'A period is an interval during which the user works' })}>
                                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                                </Tooltip>
                                            </span>
                                        }>
                                            <UserAvailabilityTab />
                                        </Tabs.TabPane>
                                    }
                                    {
                                        checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                                        <Tabs.TabPane key={TabKeys.Vacations} tab={
                                            <span>
                                                <RocketOutlined />
                                                <FormattedMessage defaultMessage={'Vacations'} />
                                            </span>
                                        }>
                                            <UserVacationsTab userId={this.state.userId} />
                                        </Tabs.TabPane>
                                    }
                                    {
                                        checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                                        <Tabs.TabPane key={TabKeys.Heures} tab={
                                            <span>
                                                <HourglassOutlined />
                                                <FormattedMessage defaultMessage={'Hours'} />
                                            </span>
                                        }>
                                            <UserHoursTab />
                                        </Tabs.TabPane>
                                    }
                                    {
                                        checkPrivilege(Privileges.Report.Visit, this.props.currentUser) &&
                                        <Tabs.TabPane key={TabKeys.UserJobs} tab={
                                            <span>
                                                {/* <RocketOutlined /> */}
                                                <SolutionOutlined />
                                                <FormattedMessage defaultMessage={'Contract'} />
                                            </span>
                                        }>
                                            <UserJobTab />
                                        </Tabs.TabPane>
                                    }
                                </Tabs>
                                :
                                <Spin className="team-loading-spin" size="large" />
                        }
                    </div>
                </Container>
            </Can >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUser: (u: User) => dispatch(changeUser(u)),
    changeUsersGroups: (g: Group[]) => dispatch(changeUsersGroups(g)),
    reset: () => dispatch(resetAllAppartGroups()),
});

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    windowWidth: state.window.width,
    isSmartphone: state.window.isSmartphone,
    user: state.teamManagement.user,
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(injectIntl(UserDetails));