const SELECTABLE_VALUE_SEPARATOR = "#";
export const getSelectableValueForEvent = (day: string, userId: number, eventId: number) => {
    return `${day}${SELECTABLE_VALUE_SEPARATOR}${userId}${SELECTABLE_VALUE_SEPARATOR}${eventId}`;
};

export const parseSelectableValue = (value: string) => {
    try {
        const parts = value.split(SELECTABLE_VALUE_SEPARATOR);
        if (parts.length !== 3)
            throw new Error('Selectable value malformed');

        const [dayStr, userId, eventId] = parts;

        return {
            dayStr,
            userId: parseInt(userId),
            eventId: parseInt(eventId)
        };
    } catch (err) {
        console.error(err);
    }
};