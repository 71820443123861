import { FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Tabs } from 'antd';
import React from 'react';
import { GrAppleAppStore } from 'react-icons/gr';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Privileges } from '../../privileges';
import { CompanyTypes } from '../../utils/constants';
import { Company, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { checkPrivilege } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import Container from '../common/navigations/container';
import About from './about';
import Documentation from './documentation';
import Version from './version';


//Keys for the different tabs
enum TabKeys {
    Documentation = "d",
    Version = "v",
    About = "a",
}

//Links for the different tabs
enum TabLink {
    Documentation = "documentation",
    Version = "version",
    About = "about",
}
interface IProps {
    isSmartphone: boolean;
    company?: Company;
    currentUser?: User;
}

type Props = IProps & RouterProps & IntlProps;

interface State { }

class Index extends React.Component<Props, State> {
    getCurrentTab = () => {
        const privileges = (checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) || this.props.company?.type === CompanyTypes.CCNT || this.props.company?.type === CompanyTypes.SECURITY);
        let TabDef = TabKeys.Version;
        if (privileges) {
            TabDef = TabKeys.Documentation;
        }
        switch (this.props.match.params.tab) {
            case TabLink.Documentation:
                return TabKeys.Documentation;
            case TabLink.Version:
                return TabKeys.Version;
            case TabLink.About:
                return TabKeys.About;
            default:
                return TabDef;
        }
    }

    onChangeTab = (key: string) => {
        let link = "";
        const privileges = (checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) || this.props.company?.type === CompanyTypes.CCNT || this.props.company?.type === CompanyTypes.SECURITY);
        let TabDef = TabKeys.Documentation;
        if (privileges) {
            TabDef = TabKeys.Version;
        }
        switch (key) {
            case TabKeys.Documentation:
                link = TabLink.Documentation;
                break;
            case TabKeys.Version:
                link = TabLink.Version;
                break;
            case TabKeys.About:
                link = TabLink.About;
                break;
            default:
                link = TabDef;
                break;
        }
        this.props.history.replace(`/${this.props.match.params.lang}/help/${link}`);
    }

    render() {
        const privileges = (checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) || this.props.company?.type === CompanyTypes.CCNT || this.props.company?.type === CompanyTypes.SECURITY);
        const currentTab = this.getCurrentTab();
        return (
            <Container
                backTop
                breadcrumb={[{ title: 'Aide', link: '/documentation' }, { title: this.props.intl.formatMessage({ defaultMessage: 'Documentation' }), link: '/documentation' }]}>
                <Tabs animated activeKey={currentTab} onChange={this.onChangeTab}>
                    {
                        privileges ?
                            <Tabs.TabPane forceRender key={TabKeys.Documentation} tab={
                                <span>
                                    <FilePdfOutlined />
                                    <FormattedMessage defaultMessage={'Documentation'} />
                                </span>
                            }>
                                <Documentation />
                            </Tabs.TabPane>
                            : null
                    }
                    <Tabs.TabPane forceRender key={TabKeys.Version} tab={
                        <span>
                            <GrAppleAppStore className='anticon' />
                            <FormattedMessage defaultMessage={'Version'} />
                        </span>
                    }>
                        <Version />{/*<Changelog />*/}
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender key={TabKeys.About} tab={
                        <span>
                            <InfoCircleOutlined />
                            <FormattedMessage defaultMessage={'About us'} />
                        </span>
                    }>
                        <About />
                    </Tabs.TabPane>
                </Tabs>
            </Container>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    company: state.user.company,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(injectIntl(Index));