import { FieldTimeOutlined, InfoCircleOutlined, ProjectOutlined } from '@ant-design/icons';
import { Spin, Switch, Tabs, Tooltip } from 'antd';
import React, { Suspense } from 'react';
import { BiDollarCircle } from 'react-icons/bi';
import { GiPalmTree } from 'react-icons/gi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbClockDollar, TbReport } from 'react-icons/tb';
import { connect } from 'react-redux';
import { Rules } from '../../rbacRules';
import { CompanyTypes } from '../../utils/constants';
import { Company, RouterProps } from '../../utils/types/generalTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import Anticon from '../common/general/anticon';
import Can from '../common/general/can';
import Container from '../common/navigations/container';
import GlobalOfficeValidation from './accounting/globalOffice/globalOfficeValidation';
import ReportCctHotelAndCateringTabMonthly from './ccnt/monthlyReportTab';
import PaidHours from './ccnt/paidHours';
import ReportCctHotelAndCateringTab from './ccnt/reportTab';
import ReportCctHotelAndCateringTabV2 from './ccnt/reportTabV2';
import ReportCctSecurityTab from './cctsecurity/reportTab';
import HoursTab from './hoursTab';
import BalanceYear from './normal/balanceYear';
//import ReportNormalTab from './normal/reportTab';
//import ReportTabv2 from './reportTabv2';
const ReportTabv2 = React.lazy(() => import('./reportTabv2'));
const ReportNormalTab = React.lazy(() => import('./normal/reportTab'));

import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps } from '../app/LanguageProvider';
import HoursTabV3 from './hoursTabV3';
import VacationsTabv2 from './vacationsTabv2';
//Keys for the different tabs
enum TabKeys {
    BalanceYear = "b_y",
    Vacations = "v",
    Hours = "h",
    Hoursv2 = "hv2",
    Hoursv3 = "hv3",
    Report = "r",
    PaidHours = "p_h",
    MonthlyReport = "mr",
    CctSecurity = "s",
    Ccnt = "ccnt",
    CcntV2 = "ccntv2",
    GlobalOffice = "g_o"
}

//Links for the different tabs
enum TabLink {
    BalanceYear = "balanceYear",
    Vacations = "vacations",
    Hours = "hours",
    Hoursv2 = "hoursv2",
    Hoursv3 = "hoursv3",
    Report = "report",
    PaidHours = "paidHours",
    MonthlyReport = "monthlyReport",
    CctSecurity = "cctSecurity",
    Ccnt = "ccnt",
    GlobalOffice = "globalOffice"
}

interface IProps {
    company?: Company;
}

type Props = IProps & RouterProps & IntlProps;

interface State {
    newCcntReportVersion: boolean;
    newHoursTabVersion: boolean;
}

class Reportv2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            newCcntReportVersion: true,
            newHoursTabVersion: true
        };
    }

    /**
     * Get the current tab
     */
    getCurrentTab = (): string => {
        switch (this.props.company?.type) {
            case CompanyTypes.OLD:
                switch (this.props.match.params.tab) {
                    case TabLink.Vacations:
                        return TabKeys.Vacations;
                    default:
                        return TabKeys.Report;
                }
            case CompanyTypes.CCNT:
                switch (this.props.match.params.tab) {
                    case TabLink.MonthlyReport:
                        return TabKeys.MonthlyReport;
                    case TabLink.PaidHours:
                        return TabKeys.PaidHours;
                    case TabLink.Report:
                        return TabKeys.Report;
                    default:
                        return TabKeys.Ccnt;
                }
            case CompanyTypes.NORMAL:
                switch (this.props.match.params.tab) {
                    case TabLink.Vacations:
                        return TabKeys.Vacations;
                    case TabLink.Report:
                        return TabKeys.Report;
                    case TabLink.Hoursv2:
                        return TabKeys.Hoursv2;
                    case TabLink.Hoursv3:
                        return TabKeys.Hoursv3;
                    case TabLink.BalanceYear:
                        if (this.props.company.id === 47 || this.props.company.id === 55) return TabKeys.BalanceYear;
                        else return TabKeys.Hours;
                    case TabLink.GlobalOffice:
                        if (this.props.company.isGlobalOfficeEnabled) return TabKeys.GlobalOffice;
                        else return TabKeys.Hours;
                    default:
                        return TabKeys.Hours;
                }
            case CompanyTypes.SECURITY:
                switch (this.props.match.params.tab) {
                    case TabLink.Vacations:
                        return TabKeys.Vacations;
                    case TabLink.Report:
                        return TabKeys.Report;
                    case TabLink.Hoursv2:
                        return TabKeys.Hoursv2;
                    case TabLink.Hoursv3:
                        return TabKeys.Hoursv3;
                    default:
                        return TabKeys.CctSecurity;
                }
            default:
                switch (this.props.match.params.tab) {
                    default:
                        return TabKeys.Report;
                }
        }
    };

    /**
     * Called when a tab is changed
     */
    onChangeTab = (key: string) => {
        let link = "";
        switch (this.props.company?.type) {
            case CompanyTypes.OLD:
                switch (key) {
                    case TabKeys.Vacations:
                        link = TabLink.Vacations;
                        break;
                    default:
                        link = TabLink.Report;
                        break;
                }
                break;
            case CompanyTypes.CCNT:
                switch (key) {
                    case TabKeys.MonthlyReport:
                        link = TabLink.MonthlyReport;
                        break;
                    case TabKeys.PaidHours:
                        link = TabLink.PaidHours;
                        break;
                    case TabKeys.Report:
                        link = TabLink.Report;
                        break;
                    default:
                        link = TabLink.Ccnt;
                        break;
                }
                break;
            case CompanyTypes.NORMAL:
                switch (key) {
                    case TabKeys.Vacations:
                        link = TabLink.Vacations;
                        break;
                    case TabKeys.Report:
                        link = TabLink.Report;
                        break;
                    case TabKeys.Hoursv2:
                        link = TabLink.Hoursv2;
                        break;

                    case TabKeys.Hoursv3:
                        link = TabLink.Hoursv3;
                        break;
                    case TabKeys.BalanceYear:
                        if (this.props.company.id === 47 || this.props.company.id === 55) link = TabLink.BalanceYear;
                        else link = TabLink.Hours;
                        break;
                    case TabKeys.GlobalOffice:
                        if (this.props.company.isGlobalOfficeEnabled) link = TabLink.GlobalOffice;
                        else link = TabLink.Hours;
                        break;
                    default:
                        link = TabLink.Hours;
                        break;
                }
                break;
            case CompanyTypes.SECURITY:
                switch (key) {
                    case TabKeys.Vacations:
                        link = TabLink.Vacations;
                        break;
                    case TabKeys.Report:
                        link = TabLink.Report;
                        break;
                    case TabKeys.Hoursv2:
                        link = TabLink.Hoursv2;
                        break;
                    case TabKeys.Hoursv3:
                        link = TabLink.Hoursv3;
                        break;
                    default:
                        link = TabLink.CctSecurity;
                        break;
                }
                break;
            default:
                switch (key) {
                    default:
                        link = TabLink.Report;
                        break;
                }
        }

        this.props.history.replace(`/${this.props.match.params.lang}/reportv2/${link}`);
    };

    renderTabs = () => {
        switch (this.props.company?.type) {
            case CompanyTypes.OLD:
                return (
                    <>
                        <Tabs.TabPane key={TabKeys.Report} tab={
                            <span>
                                <ProjectOutlined />
                                <FormattedMessage defaultMessage={'Monthly reports'} />
                            </span>
                        }>
                            <Suspense fallback={<Spin size='large' spinning />}>
                                <ReportTabv2 />
                            </Suspense>
                        </Tabs.TabPane>
                        <Tabs.TabPane key={TabKeys.Vacations} tab={
                            <span>
                                <GiPalmTree className='anticon' />
                                <FormattedMessage defaultMessage={'Vacations'} />
                            </span>
                        }>
                            <VacationsTabv2 />
                        </Tabs.TabPane>
                    </>
                );
            case CompanyTypes.CCNT:
                return (
                    <>
                        <Tabs.TabPane key={TabKeys.Ccnt} tab={
                            <span>
                                <ProjectOutlined />
                                {<><FormattedMessage defaultMessage={'N-CLA'} />&nbsp;
                                    <Tooltip overlayStyle={{ width: '500px' }} title={<ul>
                                        <li><FormattedMessage defaultMessage={'This 2nd version lays a more solid foundation for future updates (balances and planning assistance)'} /></li>
                                        <li><FormattedMessage defaultMessage={'Please notify the Sunkhronos team if you notice any discrepancies on the V2 reports'} /></li>
                                    </ul>}>
                                        (V2 beta <Switch size='small' checked={this.state.newCcntReportVersion} onChange={() => this.setState({ newCcntReportVersion: !this.state.newCcntReportVersion })} />)
                                    </Tooltip>
                                </>}
                            </span>
                        }>
                            {this.state.newCcntReportVersion ? <ReportCctHotelAndCateringTabV2 /> : <ReportCctHotelAndCateringTab />}
                        </Tabs.TabPane>
                        <Tabs.TabPane key={TabKeys.MonthlyReport} tab={
                            <span>
                                <span className='anticon'><SiMicrosoftexcel /></span>
                                <FormattedMessage defaultMessage={'Monthly reports'} />
                                <Tooltip title={<FormattedMessage defaultMessage={'Monthly reports to create pay slips'} />}>
                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                </Tooltip>
                            </span>
                        }>
                            <ReportCctHotelAndCateringTabMonthly />
                        </Tabs.TabPane>

                        {
                            this.props.company.enableNormalReport ?
                                <Tabs.TabPane key={TabKeys.Report} tab={
                                    <span>
                                        <ProjectOutlined />
                                        <FormattedMessage defaultMessage={'Monthly reports'} />
                                        <Tooltip title={<FormattedMessage defaultMessage={'Monthly reports outside the N-CLA'} />}>
                                            <InfoCircleOutlined className="tab-tooltip-info" />
                                        </Tooltip>
                                    </span>
                                }>
                                    <Suspense fallback={<Spin size='large' spinning />}>
                                        <ReportNormalTab />
                                    </Suspense>
                                </Tabs.TabPane>
                                : null
                        }
                        <Tabs.TabPane key={TabKeys.PaidHours} tab={
                            <span>
                                <Anticon icon={<TbClockDollar />} />
                                <FormattedMessage defaultMessage={'Paid overtimes'} />
                            </span>
                        }>
                            <PaidHours />
                        </Tabs.TabPane>
                    </>
                );
            case CompanyTypes.NORMAL:
                return (
                    <>
                        <Tabs.TabPane key={TabKeys.Hours} tab={
                            <span>
                                <FieldTimeOutlined />
                                <FormattedMessage defaultMessage={'Hours'} />
                            </span>
                        }>
                            {this.state.newHoursTabVersion ? <HoursTabV3 /> : <HoursTab />}
                        </Tabs.TabPane>
                        <Tabs.TabPane key={TabKeys.Vacations} tab={
                            <span>
                                <GiPalmTree className='anticon' />
                                <FormattedMessage defaultMessage={'Vacations'} />
                            </span>
                        }>
                            <VacationsTabv2 />
                        </Tabs.TabPane>
                        <Tabs.TabPane key={TabKeys.Report} tab={
                            <span>
                                <ProjectOutlined />
                                <FormattedMessage defaultMessage={'Monthly reports'} />
                            </span>
                        }>
                            <Suspense fallback={<Spin size='large' spinning />}>
                                <ReportNormalTab />
                            </Suspense>
                        </Tabs.TabPane>
                        {
                            this.props.company.id === 47 || this.props.company.id === 55 ?
                                <Tabs.TabPane key={TabKeys.BalanceYear} tab={
                                    <span>
                                        <Anticon><TbReport /></Anticon>
                                        <FormattedMessage defaultMessage={'Close out the year'} />
                                    </span>
                                }>
                                    <BalanceYear />
                                </Tabs.TabPane>
                                : null
                        }
                        {
                            this.props.company.isGlobalOfficeEnabled ?
                                <Tabs.TabPane key={TabKeys.GlobalOffice} tab={
                                    <span>
                                        <Anticon><BiDollarCircle /></Anticon>
                                        {"Global Office"}
                                    </span>
                                }>
                                    <GlobalOfficeValidation addOrUpdateExtra={() => { return; }} addOrUpdateFilters={() => { return; }} keyLink={TabLink.GlobalOffice} />
                                </Tabs.TabPane>
                                : null
                        }
                    </>
                );
            case CompanyTypes.SECURITY:
                return (
                    <>
                        {/* <Tabs.TabPane key={TabKeys.Hours} tab={
                            <span>
                                <FieldTimeOutlined />
                                {"Heures"}
                            </span>
                        }>
                            <HoursTab />
                        </Tabs.TabPane> */}
                        {/* <Tabs.TabPane key={TabKeys.Hoursv2} tab={
                            <span>
                                <FieldTimeOutlined />
                                {"Heures v2"}
                            </span>
                        }>
                            <HoursTabv2 />
                        </Tabs.TabPane> */}
                        {/* <Tabs.TabPane key={TabKeys.Vacations} tab={
                            <span>
                                <GiPalmTree className='anticon' />
                                {"Vacances"}
                            </span>
                        }>
                            <VacationsTabv2 />
                        </Tabs.TabPane> */}
                        <Tabs.TabPane key={TabKeys.CctSecurity} tab={
                            <span>
                                <ProjectOutlined />
                                <FormattedMessage defaultMessage={'CLA'} />
                            </span>
                        }>
                            <ReportCctSecurityTab />
                        </Tabs.TabPane>
                        {
                            this.props.company.enableNormalReport ?
                                <Tabs.TabPane key={TabKeys.Report} tab={
                                    <span>
                                        <ProjectOutlined />
                                        <FormattedMessage defaultMessage={'Monthly reports'} />
                                        <Tooltip title={<FormattedMessage defaultMessage={'Monthly reports outside the CLA'} />}>
                                            <InfoCircleOutlined className="tab-tooltip-info" />
                                        </Tooltip>
                                    </span>
                                }>
                                    <Suspense fallback={<Spin size='large' spinning />}>
                                        <ReportNormalTab />
                                    </Suspense>
                                </Tabs.TabPane>
                                : null
                        }
                    </>
                );

            default:
                return (
                    <>
                        <Tabs.TabPane key={TabKeys.Report} tab={
                            <span>
                                <ProjectOutlined />
                                <FormattedMessage defaultMessage={'Monthly reports'} />
                            </span>
                        }>
                            <Suspense fallback={<Spin size='large' spinning />}>
                                <ReportTabv2 />
                            </Suspense>
                        </Tabs.TabPane>
                    </>
                );
        }
    };

    render() {
        const currentTab = this.getCurrentTab();
        return (
            <Can rule={Rules.Report.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: this.props.intl.formatMessage({ defaultMessage: 'Reports' }), link: "/report" }]}>
                    <Tabs animated activeKey={currentTab} onChange={this.onChangeTab}>
                        {this.renderTabs()}
                    </Tabs>
                </Container>
            </Can>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
});

export default connect(mapStateToProps, undefined)(injectIntl(Reportv2));