import { Popover, Space } from "antd";
import { CSSProperties, ReactNode } from "react";
import { ChromePicker, ColorResult } from "react-color";
import { FormattedMessage } from "react-intl";

const colorStyle: CSSProperties = {
    width: '60px',
    height: '23px',
    borderRadius: 'var(--border-radius)',
};
const blockColorStyle: CSSProperties = {
    borderRadius: 'var(--border-radius)',
    height: '23px',
    width: '100%'
}

const swatchStyle: CSSProperties = {
    padding: '5px',
    background: '#fff',
    borderRadius: 'var(--border-radius)',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
};

interface Props {
    color: string;
    edit: boolean;
    onChangeColor?(color: string): void;
    topChildren?: ReactNode;
    children?: ReactNode;
}

export const ColorPicker = (props: Props) => {

    if (props.edit && props.onChangeColor) {
        return (
            <Space direction="vertical">
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                    <div
                        style={{ ...swatchStyle, width: '100%' }}
                    >
                        <div style={{ ...blockColorStyle, background: `${props.color}` }} />
                    </div>
                    {/* <div style={this.state.displayColorPicker === color.id ? popoverStyle : { display: 'none' }}>
                        <BlockPicker
                            colors={[]}
                            color={props.color}
                        />
                    </div> */}
                </div>
                <div>
                    <p style={{
                        width: '227px',
                        padding: '5px 0',
                        textAlign: 'center',
                        border: 'var(--border)',
                        borderBottom: 'none',
                        borderTopLeftRadius: 'var(--border-radius)',
                        borderTopRightRadius: 'var(--border-radius)'
                    }}>
                        <FormattedMessage defaultMessage={'Select a color'} />
                    </p>
                    <ChromePicker
                        disableAlpha={true}
                        color={props.color}
                        onChange={(c: ColorResult) => props.onChangeColor && props.onChangeColor(c.hex)}
                    />
                </div>

            </Space>
        );
    } else {
        return (
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                <Popover
                    arrowPointAtCenter
                    key={`color-popover-primary`}
                    title={<FormattedMessage defaultMessage={'Select a color'} />}
                    trigger={'click'}
                    placement='bottomRight'
                    content={
                        <>
                            {props.topChildren}
                            <ChromePicker
                                disableAlpha={true}
                                color={props.color}
                                onChange={(c: ColorResult) => props.onChangeColor && props.onChangeColor(c.hex)}
                            />
                            {props.children}
                        </>
                    }
                >
                    <div
                        style={swatchStyle}
                    >
                        <div style={{ ...colorStyle, background: `${props.color}` }} />
                    </div>
                </Popover>
            </div>
        );
    }
}
export default ColorPicker