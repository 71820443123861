import { Button, Checkbox, Modal, Select, message } from "antd";
import { Moment } from "moment";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useDepartments from "../../../../hooks/useDepartments";
import Network from "../../../../utils/network";
import { showNotification } from "../../../../utils/utils";

interface Props {
    opened: boolean;
    onClose: () => void;
    date: Moment;
}

const DownloadPlanningModal = (props: Props) => {
    const [downloading, setDownloading] = useState(false);
    const [departmentId, setDepartmentId] = useState<number>();
    const [withHours, setWithHours] = useState(false);
    const intl = useIntl();
    const departments = useDepartments();

    const downloadBlob = useCallback((blob: Blob, filename: string) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    }, []);

    const generateCompanyReport = useCallback(() => {
        setDownloading(true);
        // generate reports
        Network.generateCompanyReport(props.date.format("YYYY-MM-01"), withHours, departmentId).then(
            blob => {
                downloadBlob(blob, `company_${props.date.format("YYYY-MM")}.xlsx`);
                setDownloading(false);
                props.onClose();
                message.success(intl.formatMessage({ defaultMessage: 'The planning has been successfully downloaded' }));
            },
            () => {
                setDownloading(false);
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while downloading the planning' }), "error");
            }
        );
    }, [downloadBlob, props, intl, withHours, departmentId]);
    console.log("Departments");
    return (
        <Modal
            closable={!downloading}
            onCancel={props.onClose}
            title={<FormattedMessage defaultMessage={'Download the planning'} />}
            open={props.opened}
            destroyOnClose={true}
            width={550}
            footer={[
                <Button key={`monthlyPlanningv3-download-planning-cancel`} type="dashed" onClick={props.onClose}>
                    <FormattedMessage defaultMessage={'Cancel'} />
                </Button>,
                <Button key={`monthlyPlanningv3-download-planning-download`} type="primary" onClick={generateCompanyReport} loading={downloading}>
                    <FormattedMessage defaultMessage={'Download'} />
                </Button>

            ]}>
            {
                departments.data && departments.data.length > 0 &&
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '15px' }}>
                    <span><FormattedMessage defaultMessage={'Department'} /></span>
                    <Select
                        className="team-create-user-inputs"
                        showSearch
                        allowClear
                        onChange={(e: number | undefined) => setDepartmentId(e)}
                        placeholder={<FormattedMessage defaultMessage={'Department'} />}
                        value={departmentId}
                        filterOption={true}
                        optionFilterProp="label">
                        {departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                    </Select>
                </div>
            }
            <Checkbox checked={withHours} disabled={downloading} onChange={(e) => setWithHours(e.target.checked)}><FormattedMessage defaultMessage={'Show times?'} /></Checkbox>
        </Modal>
    );
};

export default DownloadPlanningModal;