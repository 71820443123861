import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Alert, Button, Card, Checkbox, Col, Divider, Modal, Popconfirm, Row, Spin, Tooltip } from "antd";
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from "moment";
import React, { CSSProperties } from "react";
import isEqual from "react-fast-compare";
import { BsCalendar3Event } from "react-icons/bs";
import { RiTimerFlashLine } from "react-icons/ri";
import { FormattedMessage, injectIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeUsers } from "../../../../store/actions/teamManagement";
import { MOMENT_FORMAT_TO_NETWORK } from "../../../../utils/constants";
import getFormat from "../../../../utils/Lang";
import Network from "../../../../utils/network";
import { EventClocked, EventWithEventClocked, RouterProps, User } from "../../../../utils/types/generalTypes";
import { GeneralResponse, NetworkEventClockedSetAdmin } from "../../../../utils/types/networkTypes";
import { BreakTime, BreakTimeEdit } from "../../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps, UserDispatchProps } from "../../../../utils/types/storeTypes";
import { convertBreakTimesToNetworkBreakTimes, convertNetworkEventClockedToEventClocked, convertNetworkEventWithEventClockedToEventWithEventClocked, momentDurationFormat, roundDecimals, showNotification } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";
import FAIcon from "../../../common/FAIcon";
import DateTimePicker, { DateTimePickerType, DateTimePickerVersion } from "../../../common/fields/dateTimePicker";
import InputField, { InputFieldOnChangeEvent } from "../../../common/fields/inputField";
import Anticon from "../../../common/general/anticon";
import Skeleton from "../../../common/general/skeleton";
import { DateType } from "../timeclockControl";
import { BreaktimeContainer } from "./dragAndDropBreaktimes/breaktimeContainer";
import './timeclockControl.css';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    selectedEventId: number;
    isDashboard?: boolean;
    onCancel: () => void;
    onEdit: (e: EventClocked, p: NetworkEventClockedSetAdmin) => void;
    currentStep: 'general' | 'breaks';
}

export type breaktimeError = 'OUT_OF_EVENT_BOUNDS';
export type breaktimeErrors = { breaktimeId: number; error: breaktimeError; }[];
interface State {
    currentEventClocked?: EventClocked;
    currentEventWithEventClocked?: EventWithEventClocked;
    isLoading: boolean;
    errorWithDates: boolean;
    isEventClockedLoading: boolean;
    currentStep: number;
    activatedBreakTimes: number[];
    originallyActivatedBreakTimes: number[];
    addBreaktime: boolean;
    editBreakTime: BreakTimeEdit;
    editBreakTimeError: boolean;
    breaktimesWithError: breaktimeErrors;
}

type Props = RouterProps & IProps & ReduxProps & UserDispatchProps & TeamManagementDispatchProps & IntlProps;

class EditEventClockedModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const currentStep = this.props.currentStep;


        this.state = {
            activatedBreakTimes: [],
            originallyActivatedBreakTimes: [],
            currentStep: currentStep === 'breaks' ? 0 : currentStep === 'general' ? 1 : 0,
            currentEventClocked: undefined,
            isLoading: false,
            errorWithDates: false,
            isEventClockedLoading: false,
            editBreakTime: {
                title: props.intl.formatMessage({ defaultMessage: 'New break' }),
                startDate: undefined,
                endDate: undefined,
            },
            editBreakTimeError: false,
            addBreaktime: false,
            breaktimesWithError: []
        };
    }

    componentDidMount(): void {
        if (this.props.users === undefined || this.props.users.length === 0) this.getUsers();
        this.getEventClocked();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<State>) {
        if (!isEqual(this.props.selectedEventId, prevProps.selectedEventId)) {
            this.getEventClocked();
        }

        if (!isEqual(this.state.activatedBreakTimes, prevState.activatedBreakTimes)) {
            this.revalidateBreaktimes({ start: this.state.currentEventClocked?.adminStartDate, end: this.state.currentEventClocked?.adminEndDate });
        }
    }

    getUsers = () => {
        Network.getAllUsers().then(
            response => {
                this.props.changeUsers!(response);
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning");
            }
        );
    };


    getEventClocked = () => {
        if (!this.state.isEventClockedLoading) {
            this.setState({ isEventClockedLoading: true });
            Network.getEventWithEventClocked(this.props.selectedEventId).then(
                (response: GeneralResponse) => {
                    if (response.error === false && response.data.clocked) {
                        const eventClocked = convertNetworkEventClockedToEventClocked(response.data.clocked, this.props.users);
                        const startDate = eventClocked.calculated?.startDate ? moment(eventClocked.calculated.startDate) : undefined;
                        const endDate = eventClocked.calculated?.endDate ? moment(eventClocked.calculated.endDate) : undefined;

                        const adminStartDate = eventClocked.adminStartDate ?? startDate?.clone();
                        const adminEndDate = eventClocked.adminEndDate ?? endDate?.clone();

                        const eventWithEventClocked: EventWithEventClocked = convertNetworkEventWithEventClockedToEventWithEventClocked(response.data);
                        const breaktimesClocked = eventWithEventClocked.breakTimes.filter((breaktime: BreakTime) => (breaktime.isClocked && breaktime.clocked));
                        const breaktimesPlanned = eventWithEventClocked.breakTimes.filter((breaktime: BreakTime) => (!breaktime.isClocked && breaktime.active));
                        const activeBreakTimes = breaktimesClocked.length > 0 ? breaktimesClocked : breaktimesPlanned;
                        const activatedBreakTimes = activeBreakTimes.map((breaktime: BreakTime) => breaktime.id);

                        this.setState({ activatedBreakTimes, originallyActivatedBreakTimes: cloneDeep(activatedBreakTimes), isEventClockedLoading: false, currentEventWithEventClocked: eventWithEventClocked, currentEventClocked: { ...eventClocked, adminStartDate, adminEndDate } });

                    } else {
                        console.error("Error: ", response);
                        try {
                            showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events clocked' }), "error");
                            throw new Error("Network.getEventWithEventClocked : problem with data");
                        } catch (error) {
                            Sentry.captureException(error);
                        }
                    }
                },
                () => {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the events clocked' }), "error");
                    this.setState({ isEventClockedLoading: false });
                }
            );

        }

    };

    revalidateBreaktimes = (bound: { start: Moment | undefined, end: Moment | undefined; }) => {
        const errors: typeof this.state.breaktimesWithError = [];
        const breaktimes = this.getBreaktimes();

        if (bound.start && bound.end) {

            breaktimes.forEach(b => {
                if (this.state.activatedBreakTimes.includes(b.id) && (b.startDate.isBefore(bound.start) || b.endDate.isAfter(bound.end))) {
                    errors.push({
                        breaktimeId: b.id,
                        error: 'OUT_OF_EVENT_BOUNDS'
                    });
                }
            });
        }
        console.log("Errors with breaks", errors);
        this.setState({ breaktimesWithError: errors });
    };

    getBreaktimes = () => this.state.currentEventWithEventClocked ? this.state.currentEventWithEventClocked.breakTimes : [];

    onChangeStartDate = (date: Moment | null) => {
        const { currentEventClocked } = this.state;
        this.revalidateBreaktimes({ start: date ?? undefined, end: this.state.currentEventClocked?.adminEndDate });
        if (currentEventClocked && date) {
            this.setState({ currentEventClocked: { ...currentEventClocked, adminStartDate: date } });
        }
    };

    onChangeEndDate = (date: Moment | null) => {
        const { currentEventClocked } = this.state;
        if (currentEventClocked && date) {
            this.revalidateBreaktimes({ start: this.state.currentEventClocked?.adminStartDate, end: date });
            this.setState({ currentEventClocked: { ...currentEventClocked, adminEndDate: date } });
        }
    };

    onEdit = () => {
        const { currentEventClocked, currentEventWithEventClocked, activatedBreakTimes, originallyActivatedBreakTimes } = this.state;
        if (currentEventClocked && currentEventClocked.adminStartDate && currentEventClocked.adminEndDate && currentEventWithEventClocked) {
            const modifiedEventClockedDate: NetworkEventClockedSetAdmin = {};
            if (!isEqual(originallyActivatedBreakTimes, activatedBreakTimes)) {
                const breaktimes = currentEventWithEventClocked.breakTimes.filter(breaktime => activatedBreakTimes.includes(breaktime.id));
                modifiedEventClockedDate.breakTimes = convertBreakTimesToNetworkBreakTimes(breaktimes);
            }
            modifiedEventClockedDate.adminStartDate = currentEventClocked.adminStartDate.format(MOMENT_FORMAT_TO_NETWORK);
            modifiedEventClockedDate.adminEndDate = currentEventClocked.adminEndDate.format(MOMENT_FORMAT_TO_NETWORK);

            this.props.onEdit(currentEventClocked, modifiedEventClockedDate);
        }
    };

    renderEventDates = (dateType: string, title: string,) => {
        const emptyDataStyle = { fontStyle: 'italic', color: 'gray' };
        const { currentEventClocked } = this.state;
        if (currentEventClocked) {
            const planStartDate = currentEventClocked.planStartDate;
            const planEndDate = currentEventClocked.planEndDate;
            let startDate: Moment | undefined = planStartDate;
            let endDate: Moment | undefined = planEndDate;

            switch (dateType) {
                case DateType.CLOCKED:
                    startDate = currentEventClocked.startDate ? currentEventClocked.startDate : undefined;
                    endDate = currentEventClocked.endDate ? currentEventClocked.endDate : undefined;

                    break;
                case DateType.MANUAL:
                    startDate = currentEventClocked.manualStartDate ? currentEventClocked.manualStartDate : undefined;
                    endDate = currentEventClocked.manualEndDate ? currentEventClocked.manualEndDate : undefined;
                    break;
                case DateType.TEMPORARY:
                    startDate = currentEventClocked.adminStartDate ?? undefined;
                    endDate = currentEventClocked.adminEndDate ?? undefined;
                    break;
            }

            const temporaryStyle: CSSProperties = dateType === DateType.TEMPORARY ? { color: 'green', fontWeight: 'bold' } : { color: 'black' };
            const clickableTextProps = (date: Moment | undefined, isSameAsAcceptedDate: boolean, onClickFunc?: (date: Moment) => void): { className?: string, onClick?: () => void; } => {
                if (date) {
                    return {
                        className: `__work-control-summary-date-clickable-text ${isSameAsAcceptedDate ? "active" : ""}`,
                        ...(onClickFunc ? { onClick: () => onClickFunc(date!) } : {})
                    };
                }
                return {};
            };

            return (
                <>
                    <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '120px 1.35fr .2fr 2fr' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            {title}
                        </div>
                        <div style={{ textAlign: 'right', ...temporaryStyle }} {...clickableTextProps}>
                            {
                                startDate ?
                                    <p {...clickableTextProps(startDate, startDate.isSame(currentEventClocked.adminStartDate), this.onChangeStartDate)}>
                                        <span>
                                            {startDate.format(getFormat('DATE_SHORT_AND_TIME'))}
                                        </span>
                                    </p>
                                    :
                                    <p style={emptyDataStyle}>
                                        <FormattedMessage defaultMessage={'No data'} />
                                    </p>
                            }
                        </div>
                        <div style={{ textAlign: 'center', ...temporaryStyle }}>

                            {
                                (dateType === DateType.MANUAL && !startDate) || (dateType === DateType.CLOCKED && !startDate) ?
                                    <></>
                                    : <FAIcon prefix="far" name="arrow-right" />
                            }

                        </div>
                        <div style={temporaryStyle}>
                            {
                                endDate ?
                                    <p {...clickableTextProps(endDate, endDate.isSame(currentEventClocked.adminEndDate), this.onChangeEndDate)}>
                                        <span>
                                            {endDate.isSame(startDate, 'date') ? endDate.format(getFormat('TIME')) : endDate.format(getFormat('TIME'))}
                                        </span>
                                    </p>
                                    :
                                    <p style={{ ...emptyDataStyle, ...((dateType === DateType.TEMPORARY && !endDate) ? { color: 'red' } : {}) }}>
                                        {
                                            dateType === DateType.PLANIFIED ?
                                                <FormattedMessage defaultMessage={'No data'} />
                                                :
                                                dateType === DateType.CLOCKED ?
                                                    startDate ? currentEventClocked.manualStartDate ?
                                                        <FormattedMessage defaultMessage={'Not stopped'} />
                                                        :
                                                        <FormattedMessage defaultMessage={'In progress'} />
                                                        :
                                                        ''
                                                    :
                                                    dateType === DateType.TEMPORARY ?
                                                        <FormattedMessage defaultMessage={'Missing date'} />
                                                        :
                                                        ''
                                        }
                                    </p>
                            }
                        </div>
                    </div>
                </>
            );

        }
        return <></>;
    };

    renderStepContent = () => {
        const { intl } = this.props;
        const { currentEventClocked, currentEventWithEventClocked, activatedBreakTimes } = this.state;
        const breaktimes: BreakTime[] = currentEventWithEventClocked ? currentEventWithEventClocked.breakTimes : [];
        const filteredBreakTimes = breaktimes.filter((breakTimeClocked) => activatedBreakTimes.includes(breakTimeClocked.id));
        const breakTimeDurationTotal = filteredBreakTimes.reduce((value, breakTimeClocked) => value = value + breakTimeClocked.totalSeconds, 0);
        const paidBreaktimesDuration = filteredBreakTimes.reduce((value, breakTimeClocked) => value = value + ((breakTimeClocked.isPaid) ? breakTimeClocked.totalSeconds : 0), 0);

        const handleChangeBreakState = (newState: boolean, breakTimeId: number) => {
            const breakTime = breaktimes.find(b => b.id === breakTimeId);

            if (breakTime) {
                if (newState) {
                    this.setState({ activatedBreakTimes: [...this.state.activatedBreakTimes, breakTime.id] });
                } else {
                    this.setState({ activatedBreakTimes: this.state.activatedBreakTimes.filter(b => b !== breakTime.id) });
                }

            }
        };

        return (
            currentEventClocked === undefined ?
                <Row gutter={[15, 15]}>
                    <Col md={{ span: 24 }}>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col xs={{ span: 24 }}>
                                <Card size='small' title={<FormattedMessage defaultMessage={'Breaks'} />}>
                                    <Skeleton className="newslist-skeleton" loading={currentEventClocked === undefined} paragraph={{ rows: 3 }} />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: 24 }}>
                        <Row gutter={[15, 15]} style={{ marginBottom: '15px' }}>
                            <Col xs={{ span: 24 }}>
                                <Card size='small' title={<FormattedMessage defaultMessage={'Hours'} />}>
                                    <Skeleton className="newslist-skeleton" loading={currentEventClocked === undefined} paragraph={{ rows: 5 }} />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                :
                <>
                    <Card
                        title={
                            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <span>
                                        <FormattedMessage defaultMessage={'Breaks'} />
                                    </span>
                                    <span style={{ fontStyle: 'italic', fontWeight: 'normal', fontSize: '80%' }}>
                                        <FormattedMessage defaultMessage={'Total {total} ({paid} paid)'} values={{ total: momentDurationFormat(moment.duration(breakTimeDurationTotal, "seconds")), paid: momentDurationFormat(moment.duration(paidBreaktimesDuration, "seconds")) }} />
                                    </span>
                                </div>
                                <div onClick={() => this.setState({ addBreaktime: true })} style={{ cursor: 'pointer' }}>
                                    <FAIcon prefix={'far'} name={'circle-plus'} />
                                </div>
                            </div>
                        }
                        bodyStyle={{
                            padding: '0px',
                            overflow: 'hidden'
                        }}
                        style={{ marginBottom: '15px' }}>
                        <Row gutter={[15, 15]}>
                            <Col span={24}>
                                <BreaktimeContainer dateBounds={{ from: this.state.currentEventClocked?.adminStartDate, to: this.state.currentEventClocked?.adminEndDate }} errors={this.state.breaktimesWithError} activeBreaks={filteredBreakTimes} breaks={breaktimes} onBreakStateChange={handleChangeBreakState} />
                            </Col>
                        </Row>
                    </Card>
                    <Card title={<FormattedMessage defaultMessage={'Hours'} />}>
                        <div>{this.renderEventDates(DateType.PLANIFIED, intl.formatMessage({ defaultMessage: 'Planned' }))}</div>
                        <div>{this.renderEventDates(DateType.CLOCKED, intl.formatMessage({ defaultMessage: 'Clocked' }))}</div>
                        <div>{this.renderEventDates(DateType.MANUAL, intl.formatMessage({ defaultMessage: 'Manual entry' }))}</div>
                        {
                            currentEventClocked.manualDescription ?
                                <div style={{ marginTop: '10px' }}>
                                    <Alert message={<FormattedMessage defaultMessage={'Reason: {text}'} values={{ text: currentEventClocked.manualDescription }} />} type="warning" />
                                </div>
                                : <></>
                        }
                        <div style={{ display: 'flex', marginTop: '20px' }} className="__shorter-datetime-picker">
                            <DateTimePicker
                                valueFrom={currentEventClocked.adminStartDate}
                                onChangeFrom={this.onChangeStartDate}
                                valueTo={currentEventClocked.adminEndDate}
                                onChangeTo={this.onChangeEndDate}
                                showTime
                                titleFrom={<span style={{ marginRight: '30px' }}><FormattedMessage defaultMessage={'Effective hours'} /></span>}
                                titleTo={<FAIcon prefix="far" name="arrow-right" style={{ marginLeft: '10px', marginRight: '5px ' }} />}
                                direction="row"
                                type={DateTimePickerType.RANGE}
                                version={DateTimePickerVersion.FIELD}
                                setError={(error) => this.setState({ errorWithDates: error })}
                            />
                        </div>
                    </Card>
                </>
        );
    };

    checkBreaktimeFields = () => {
        const { editBreakTime } = this.state;
        return (editBreakTime === undefined || editBreakTime.title === undefined || !editBreakTime.startDate || !editBreakTime.endDate);
    };

    validateBreakTime = () => {
        const { currentEventWithEventClocked, editBreakTime } = this.state;
        const activatedBreakTimes = cloneDeep(this.state.activatedBreakTimes);

        if (editBreakTime === undefined || editBreakTime.title === undefined || !editBreakTime.startDate || !editBreakTime.endDate || !currentEventWithEventClocked) {
            this.setState({ editBreakTimeError: true });
            return false;
        } else {

            let minId = currentEventWithEventClocked.breakTimes?.reduce((prev, curr) => {
                return prev < curr.id ? prev : curr.id;
            }, 0.0);
            if (minId === undefined || minId > -1) {
                minId = -1;
            } else {
                minId--;
            }
            const startDate = editBreakTime.startDate.seconds(0).milliseconds(0);
            const endDate = editBreakTime.endDate.seconds(0).milliseconds(0);
            const breakTimesTmp: BreakTime = {
                active: true,
                title: editBreakTime.title,
                startDate,
                endDate,
                isPaid: editBreakTime.isPaid ? editBreakTime.isPaid : false,
                totalHours: roundDecimals(endDate.diff(startDate, 'hours', true)),
                totalSeconds: roundDecimals(endDate.diff(startDate, 'seconds', true)),
                isClocked: false,
                id: editBreakTime.id ?? minId,
            };

            if (currentEventWithEventClocked.breakTimes === undefined) {
                currentEventWithEventClocked.breakTimes = [breakTimesTmp];
            } else {
                const breaktimeToChangeId = currentEventWithEventClocked.breakTimes.findIndex(b => b.id === breakTimesTmp.id);
                if (breaktimeToChangeId >= 0) {
                    currentEventWithEventClocked.breakTimes[breaktimeToChangeId] = breakTimesTmp;
                } else {
                    currentEventWithEventClocked.breakTimes.push(breakTimesTmp);
                }
            }
            activatedBreakTimes.push(breakTimesTmp.id);

            this.setState({
                activatedBreakTimes,
                currentEventWithEventClocked,
                editBreakTime: {
                    title: this.props.intl.formatMessage({ defaultMessage: 'New break' }),
                    startDate: undefined,
                    endDate: undefined,
                },
                editBreakTimeError: false
            });
            return true;
        }

    };

    render() {
        const { onCancel, usersLoading, intl } = this.props;
        const { currentEventClocked, errorWithDates, isLoading, isEventClockedLoading, editBreakTime, currentEventWithEventClocked, activatedBreakTimes } = this.state;
        const title = <FormattedMessage defaultMessage={'Edit clocked event: {event}'} values={{ event: currentEventClocked !== undefined ? currentEventClocked.title : intl.formatMessage({ defaultMessage: 'Unnamed' }) }} />;
        const breaktimes: BreakTime[] = currentEventWithEventClocked ? currentEventWithEventClocked.breakTimes : [];
        const filteredBreakTimes = breaktimes.filter((breakTimeClocked) => activatedBreakTimes.includes(breakTimeClocked.id));
        const breakTimeTotalSeconds = filteredBreakTimes.reduce((value, breakTimeClocked) => value = value + breakTimeClocked.totalSeconds, 0);
        const paidBreaktimesTotalSeconds = filteredBreakTimes.reduce((value, breakTimeClocked) => value = value + ((breakTimeClocked.isPaid) ? breakTimeClocked.totalSeconds : 0), 0);
        const notPaidBreaktimesTotalSeconds = breakTimeTotalSeconds - paidBreaktimesTotalSeconds;

        const eventTotalSeconds = currentEventClocked?.adminStartDate && currentEventClocked?.adminEndDate ? currentEventClocked.adminEndDate.diff(currentEventClocked.adminStartDate, "seconds") : 0;



        console.log("YEAHH", eventTotalSeconds, breakTimeTotalSeconds, paidBreaktimesTotalSeconds, breakTimeTotalSeconds - paidBreaktimesTotalSeconds);

        const recapRowStyle: CSSProperties = {
            display: 'grid',
            gridTemplateColumns: '25px 1fr 140px',
            alignItems: 'center',
            gap: '10px'
        };

        return (
            <Modal
                style={{ top: '50px', zIndex: 100000 }}
                width='880px'
                open={true}
                className='planning-modal'
                title={title}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={[
                    <Button
                        key='details-modal-cancel'
                        type='dashed'
                        onClick={onCancel}>
                        <FormattedMessage defaultMessage={'Close'} />
                    </Button>,
                    null,
                    (<div key={'confirm-button'} style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'center', alignItems: 'center' }}>
                        <Popconfirm
                            placement={'topRight'}
                            key='eventclocked-edit-popconfirm-modal'
                            title={<div>
                                <p><FormattedMessage defaultMessage={'Modifying the event will also confirm it. Would you like to continue?'} /></p>

                                <div style={{ marginTop: '10px' }}>
                                    <div style={recapRowStyle}>
                                        <FAIcon prefix="far" name="clock" />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                            {currentEventClocked?.adminStartDate?.format(getFormat('DATE_SHORT_AND_TIME'))}
                                            <FAIcon prefix="far" name="arrow-right" />
                                            {currentEventClocked?.adminEndDate?.format(currentEventClocked.adminEndDate.isSame(currentEventClocked.adminStartDate, 'date') ? getFormat('TIME') : getFormat('DATE_SHORT_AND_TIME'))}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {momentDurationFormat(moment.duration(eventTotalSeconds, "seconds"))}
                                        </div>
                                    </div>

                                    <div style={recapRowStyle}>
                                        <FAIcon prefix="far" name="mug" />
                                        <span><FormattedMessage defaultMessage={'{count, plural, one {1 break} other {{count} breaks}}'} values={{ count: this.state.activatedBreakTimes.length }} /></span>
                                        <span style={{ textAlign: 'right' }}>- {momentDurationFormat(moment.duration(notPaidBreaktimesTotalSeconds, "seconds"))}</span>
                                    </div>
                                    {
                                        breaktimes.filter(b => this.state.activatedBreakTimes.includes(b.id)).map(b => (
                                            <div key={b.id}>
                                                <span style={{ color: 'grey', fontSize: '85%', marginLeft: '36px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                    <div>{b.isClocked ? <Anticon icon={<RiTimerFlashLine title={intl.formatMessage({ defaultMessage: 'Break clocked' })} />} /> : <Anticon icon={<BsCalendar3Event title={intl.formatMessage({ defaultMessage: 'Break clocked' })} />} />}
                                                        &nbsp;{b.isPaid ? <><FAIcon prefix='far' name={'sack-dollar'} />&nbsp;</> : <></>}
                                                        {b.title}
                                                    </div>
                                                    <div>
                                                        <span style={{ textDecorationLine: b.isPaid ? 'line-through' : 'none' }}>
                                                            {momentDurationFormat(moment.duration(b.totalSeconds, "seconds"))}
                                                        </span>
                                                        {b.isPaid ? <span> 0m</span> : <></>}
                                                    </div>
                                                </span>
                                            </div>
                                        ))
                                    }

                                    <Divider style={{ margin: '4px' }} />

                                    <div style={recapRowStyle}>
                                        <FAIcon prefix="far" name="hourglass-clock" />
                                        <span><FormattedMessage defaultMessage={'Effective duration'} /></span>
                                        <span style={{ textAlign: 'right', fontWeight: 'bold' }}>{eventTotalSeconds !== undefined ? momentDurationFormat(moment.duration(eventTotalSeconds - notPaidBreaktimesTotalSeconds, "seconds")) : ''}</span>
                                    </div>
                                </div>
                            </div>}
                            onConfirm={this.onEdit}
                            onCancel={(e) => e?.stopPropagation()}
                            okText={<FormattedMessage defaultMessage={'Modify and confirm event'} />}
                            cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                            icon={<QuestionCircleOutlined style={{ color: '#FF7070' }} />}
                            disabled={errorWithDates || this.state.breaktimesWithError.length > 0}
                        >
                            <Button
                                key='details-modal-edit'
                                type='primary'
                                disabled={errorWithDates || this.state.breaktimesWithError.length > 0}
                                loading={isLoading || isEventClockedLoading}>
                                <FormattedMessage defaultMessage={'Modify and confirm'} />
                            </Button>
                        </Popconfirm>
                    </div>)
                ]}>
                <Spin spinning={usersLoading || isLoading || isEventClockedLoading}>
                    {this.renderStepContent()}
                </Spin>
                <Modal
                    width="400px"
                    style={{ top: "150px" }}
                    title={editBreakTime.id ? <FormattedMessage defaultMessage={'Edit break: {name}'} values={{ name: editBreakTime.title }} /> : <FormattedMessage defaultMessage={'Add a break'} />}
                    open={this.state.addBreaktime}
                    onCancel={() => this.setState({ addBreaktime: false })}
                    destroyOnClose={true}
                    footer={[
                        <div key={`eventmodal-add-breaktime-action`} style={{ display: 'flex', justifyContent: editBreakTime.id === undefined ? 'space-between' : 'flex-end' }}>
                            {
                                editBreakTime.id === undefined ?
                                    <Button title="Sauvegarder la pause et ajouter une autre" type="default" onClick={this.validateBreakTime} disabled={this.state.editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                        <FormattedMessage defaultMessage={'Add and continue'} />
                                    </Button>
                                    : null
                            }
                            <Button type="primary" onClick={() => { this.validateBreakTime(); this.setState({ addBreaktime: false }); }} disabled={this.state.editBreakTimeError || this.checkBreaktimeFields()} key="breaktime-modal-button-ok">
                                {editBreakTime.id ? <FormattedMessage defaultMessage={'Edit'} /> : <FormattedMessage defaultMessage={'Add and close'} />}
                            </Button>
                        </div>
                    ]}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }}>
                            <InputField
                                className={this.state.editBreakTimeError === true && editBreakTime.title === undefined ? "planning-modal-input-error" : ""}
                                value={editBreakTime.title}
                                placeholder="Titre"
                                onChange={(e: InputFieldOnChangeEvent) => {
                                    editBreakTime.title = e.target.value;
                                    editBreakTime.title === "" && (editBreakTime.title = undefined);
                                    this.setState({ editBreakTime });
                                }} />
                        </Col>
                        <Col xs={{ span: 24 }}>
                            {currentEventWithEventClocked ?
                                <DateTimePicker
                                    valueFrom={editBreakTime.startDate}
                                    minFrom={currentEventClocked?.adminStartDate?.clone()}
                                    maxFrom={currentEventClocked?.adminEndDate?.clone()}
                                    onChangeFrom={(e) => {
                                        if (e) editBreakTime.startDate = e;
                                        this.setState({ editBreakTime });
                                    }}
                                    placeHolderDateFrom={intl.formatMessage({ defaultMessage: 'Start date' })}
                                    placeHolderTimeFrom={intl.formatMessage({ defaultMessage: 'Start hour' })}
                                    formatTimeFrom={getFormat('TIME_SHORT')}
                                    timeViewFrom={["hours", "minutes"]}
                                    disableDateFrom={(date) => date.isBefore(currentEventClocked?.adminStartDate, 'days') || date.isAfter(currentEventClocked?.adminEndDate, 'days')}

                                    valueTo={editBreakTime.endDate}
                                    minTo={currentEventClocked?.adminStartDate?.clone()}
                                    maxTo={currentEventClocked?.adminEndDate?.clone()}
                                    onChangeTo={(e) => {
                                        if (e) editBreakTime.endDate = e;
                                        this.setState({ editBreakTime });
                                    }}

                                    placeHolderDateTo={intl.formatMessage({ defaultMessage: 'End date' })}
                                    placeHolderTimeTo={intl.formatMessage({ defaultMessage: 'End hour' })}
                                    formatTimeTo={getFormat('TIME_SHORT')}
                                    timeViewTo={["hours", "minutes"]}
                                    disableDateTo={(date) => date.isBefore(currentEventClocked?.adminStartDate, 'days') || date.isAfter(currentEventClocked?.adminEndDate, 'days')}

                                    showTime={true}
                                    type={DateTimePickerType.RANGE}
                                    version={DateTimePickerVersion.FIELD}
                                    setError={(error) => this.setState({ editBreakTimeError: error })}
                                />
                                :
                                <Skeleton loading repeat={2} />
                            }
                        </Col>
                        <Col xs={{ span: 24 }}>
                            <Checkbox
                                checked={editBreakTime.isPaid}
                                onChange={(e) => {
                                    editBreakTime.isPaid = e.target.checked;
                                    this.setState({ editBreakTime });
                                }}>
                                <FormattedMessage defaultMessage={"At the employer's expense (paid break)"} />
                            </Checkbox>
                            <Tooltip overlay={<FormattedMessage defaultMessage={'If the break is paid for by the employer, it will not be deducted from the duration of the event.'} />} placement="right">
                                <InfoCircleOutlined className="tab-tooltip-info" />
                            </Tooltip>
                        </Col>
                    </Row>
                </Modal>
            </Modal >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    windowWidth: state.window.width,
    users: state.teamManagement.users,
    usersLoading: state.teamManagement.usersLoading,
    groups: state.teamManagement.groups,
    company: state.user.company,
    currentUser: state.user.currentUser,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withRouter(injectIntl(EditEventClockedModal)));

