import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../../rbacRules';
import Network from '../../../utils/network';
import { ICustomer } from '../../../utils/types/customerTypes';
import { ContainerTabsState, RouterProps } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { convertICostumerNetworkToICustomer } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import Can from '../../common/general/can';
import ContainerTabs, { addOrUpdateExtra, addOrUpdateFilters } from '../../common/navigations/containerTabs';
import { CustomerManagementTabLink } from '../customersManagement';
import Mandates from '../mandates/mandates';
import Sites from '../sites/sites';
import Customer from './customer';

type ReduxProps = ConnectedProps<typeof connector>
interface Props extends ReduxProps, RouterProps, IntlProps {
}

interface State extends ContainerTabsState {
    tabFiltersActivated?: { status: boolean, key: string }[];
    customer?: ICustomer;
    loadingCustomer: boolean;
}


export const enum CustomerTabLink {
    DETAILS = "details",
    SITES = "sites",
    MANDATES = "mandates",
    CONTACTS = "contacts",
}

class CustomerManagement extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tabItems: [
                {
                    key: CustomerTabLink.DETAILS,
                    label: props.intl.formatMessage({ defaultMessage: 'Informations' }),
                    children: null
                },
                {
                    key: CustomerTabLink.MANDATES,
                    label: props.intl.formatMessage({ defaultMessage: 'Mandates' }),
                    children: null
                },
                {
                    key: CustomerTabLink.SITES,
                    label: props.intl.formatMessage({ defaultMessage: 'Branches' }),
                    children: null
                },
                {
                    key: CustomerTabLink.CONTACTS,
                    label: props.intl.formatMessage({ defaultMessage: 'Contacts' }),
                    children: null
                },
            ],
            loadingCustomer: false
        }
    }

    componentDidMount(): void {
        const { intl } = this.props;
        if (this.props.match.params.customerId) {
            const customerId = parseInt(this.props.match.params.customerId);

            this.setState({ loadingCustomer: true })
            Network.getCustomer(customerId).then(
                (response) => {
                    const customer = convertICostumerNetworkToICustomer(response.data);
                    this.setState({
                        customer: customer,
                        loadingCustomer: false,
                        tabItems: [
                            {
                                key: CustomerTabLink.DETAILS,
                                label: intl.formatMessage({ defaultMessage: 'Informations' }),
                                children: <Customer customer={customer} keyLink={CustomerTabLink.DETAILS} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                            },
                            {
                                key: CustomerTabLink.MANDATES,
                                label: intl.formatMessage({ defaultMessage: 'Mandates' }),
                                children: <Mandates customer={customer} keyLink={CustomerTabLink.MANDATES} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                            },
                            {
                                key: CustomerTabLink.SITES,
                                label: intl.formatMessage({ defaultMessage: 'Branches' }),
                                children: <Sites customer={customer} keyLink={CustomerTabLink.SITES} addOrUpdateExtra={this.addOrUpdateExtra} addOrUpdateFilters={this.addOrUpdateFilters} />
                            },
                            {
                                key: CustomerTabLink.CONTACTS,
                                label: intl.formatMessage({ defaultMessage: 'Contacts' }),
                                children: null
                            },
                        ],
                    });
                },
                () => {
                    this.setState({ loadingCustomer: false });
                    this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
                }
            );
        } else {
            this.props.history.replace(`/${this.props.match.params.lang}/crm/`);
        }
    }

    addOrUpdateExtra = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateExtra(prevState, content, key));

    addOrUpdateFilters = (content: React.ReactNode, key: string) => this.setState(prevState => addOrUpdateFilters(prevState, content, key));

    render() {
        const { tabItems, tabListOfExtras, tabListOfFilters } = this.state;
        return (
            <>
                <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                    <ContainerTabs
                        backUrl={`/crm/${CustomerManagementTabLink.CUSTOMERS}`}
                        contentClassName='__new-right-panel'
                        breadcrumb={[{ title: this.props.intl.formatMessage({ defaultMessage: 'CRM' }), link: `` }]}
                        tabItems={tabItems}
                        tabExtra={tabListOfExtras?.find(item => item.key === this.props.match.params.tab)?.content}
                        tabFilters={tabListOfFilters?.find(item => item.key === this.props.match.params.tab)?.content}
                    />
                </Can>
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    customers: state.customerManagement.customers,
    displayFilters: state.window.displayFilters,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
})

const connector = connect(mapStateToProps)

export default connector(injectIntl(CustomerManagement));