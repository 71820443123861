import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LoadingGroup, LoadingGroups } from "../../utils/types/generalTypes";

const LoadingSplashScreen: React.FC<{ dataToLoad: LoadingGroups, dataLoading?: LoadingGroup['key']; loadingGroups: LoadingGroups; }> = React.memo((props) => {
    const prevLoading = useRef(props.dataLoading);
    const [processed, setProcessed] = useState<string[]>([]);

    useEffect(() => {
        prevLoading.current = props.dataLoading;
        if (props.dataLoading) {
            if (!processed.includes(props.dataLoading))
                setProcessed(prev => [...prev, props.dataLoading ?? '']);
        }
    }, [props.dataLoading, processed]);

    const getClassname = (group: LoadingGroup) => {
        if (props.dataLoading === group.key)
            return 'isLoading';
        else if (prevLoading.current === group.key || processed.includes(group.key))
            return 'isProcessed';
        else
            return '';
    };

    return (
        <>
            <div className="splashScreen">
                <div className="spinnerContainer">
                    <div className="spinner"></div>
                    <div className="loader">
                        <p><FormattedMessage defaultMessage={'Loading'} description={'SplashScreen'} /></p>
                        <div className="words">
                            {
                                props.dataToLoad.map(data => <span key={data.key} className={`word ${getClassname(data)}`}>{data.label}</span>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

LoadingSplashScreen.displayName = 'loadingSplashScreen';


export default LoadingSplashScreen;