import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

interface IBasicTableColumn<T> {
    name: string,
    alignment?:
    Partial<ExcelJS.Alignment>;
    width?: number;
    font?: ExcelJS.Font;
    data: (user: T) => any;
    onRender?: (row: ExcelJS.Row, cell: ExcelJS.Cell, item: T) => void;
}

export type IBasicTableRow<T> = IBasicTableColumn<T>[];

class Excel {
    workbook: ExcelJS.Workbook;
    filename: string;

    constructor(filename: string) {
        this.workbook = new ExcelJS.Workbook();
        this.filename = filename;

        this.workbook.creator = "Sunkhronos";
        this.workbook.lastModifiedBy = "Sunkhronos";
        this.workbook.created = new Date();
        this.workbook.properties.date1904 = true;
        this.workbook.calcProperties.fullCalcOnLoad = true;

        this.workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 0, visibility: 'visible'
            }
        ];
    }

    // Yes this function does nothing... It's just for typing without having to know which type to import.
    static CreateRowTemplate = <T>(columns: IBasicTableRow<T>) => columns;

    createBasicSheetTab = <T>(name: string, columns: IBasicTableColumn<T>[], data: T[], options?: ExcelJS.AddWorksheetOptions) => {
        const formatColumns = () => {
            if (reportSheet) {
                columns.forEach((column, i) => {
                    const col = reportSheet.getColumn(i + 1);
                    col.font = column.font ?? { name: 'Calibri' };
                    col.width = column.width ?? 20;
                    col.alignment = column.alignment ?? { wrapText: true, vertical: 'middle', horizontal: 'left' };
                });
            }
        };

        const createHeaders = () => {
            if (reportSheet) {
                const header = reportSheet.addRow(columns.map(h => h.name));

                header.fill = {
                    fgColor: {
                        argb: 'bfbfbf'
                    },
                    pattern: 'solid',
                    type: 'pattern',
                };

                header.font = {
                    bold: true
                };
            }
        };

        const addRow = (item: T) => {
            if (reportSheet) {
                const row = reportSheet.addRow(columns.map(c => c.data(item)));
                columns.forEach((col, index) => {
                    if (col.onRender)
                        col.onRender(row, row.getCell(index + 1), item);
                });
            }
        };

        const reportSheet = this.workbook.addWorksheet(name, options);

        if (reportSheet !== undefined) {
            reportSheet.pageSetup = { orientation: 'landscape', fitToPage: true, fitToWidth: 1, fitToHeight: 0 };
            formatColumns();
            createHeaders();
            data.forEach(addRow);
        }
    };

    downloadSpreadsheet = () => {
        this.workbook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), this.filename))
            .catch(err => console.log('Error writing excel export', err));
    };
}

export default Excel;