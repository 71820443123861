import React, { CSSProperties, ReactNode } from 'react';
import CircleButton from './circleButton';

interface Props {
    icon: ReactNode;
    title?: string;
    openIcon?: ReactNode;
    buttons: ReactNode[];
    className?: string;
    disabled?: boolean;
    small?: boolean;
    style?: CSSProperties;
    open?: boolean;
}

interface State {
    open: boolean;
}

/**
 * Component that represent a speed dial
 */
class SpeedDial extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.open !== this.props.open && this.props.open !== undefined) {
            if (this.props.disabled === true) return;
            this.setState({ open: this.props.open });
        }
    }

    dismiss = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (this.props.disabled === true) return;
        this.setState((state) => ({ open: this.props.open !== undefined ? this.props.open : !state.open }));
    }

    render() {
        let style: CSSProperties = {};
        if (this.state.open) {
            style = {
                width: ((this.props.small ? 33 : 42) * this.props.buttons.length) + (5 * (this.props.buttons.length - 1)) + 10,
                transitionDuration: `${(this.props.buttons.length) * 0.05}s`,
                transitionDelay: '0.05s'
            }
        }

        return (
            <div style={{ position: 'relative' }} className={this.props.className}>
                {
                    <div style={{ ...style, ...this.props.style }} className={`speed-dial${this.state.open ? " __open" : ''}`}>
                        {
                            this.props.buttons.map((node: ReactNode, index: number) => (
                                <span
                                    key={`speed-dial-${index}`}
                                    className="speed-dial-button"
                                    onClick={this.dismiss}
                                    style={{ animationDelay: `${(this.props.buttons.length - index - 1) * 0.05}s` }}>
                                    {node}
                                </span>
                            ))
                        }
                    </div>
                }
                <CircleButton
                    disabled={this.props.disabled}
                    small={this.props.small}
                    icon={this.state.open && this.props.openIcon ? this.props.openIcon : this.props.icon}
                    title={this.props.title}
                    onClick={this.dismiss} />
            </div >
        );
    }
}

export default SpeedDial;