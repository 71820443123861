import { Alert, TimePicker as AntdTimePicker, Col, Row } from 'antd';
import React from 'react';
// import TimePicker from 'react-time-picker'
import moment, { Moment } from 'moment';
import { roundDecimals } from '../../../utils/utils';

import { SxProps } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import { TimeView } from '@mui/x-date-pickers/models';
import { defineMessage, defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import getFormat from '../../../utils/Lang';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { IntlProps } from '../../app/LanguageProvider';
import { TooLongInterface } from './dateTimePicker';

const enum TimePickerError {
    FROMAFTERTO = "fromafterto",
}

const ErrorMessages = defineMessages<TimePickerError>({
    [TimePickerError.FROMAFTERTO]: { defaultMessage: 'The start date is later than the end date' },
})

const TIME_PICKER_ERROR = {
    TOOLONG: defineMessage({ defaultMessage: 'The duration of the event is unusually long' }),
    TOOLONGVAL: 13,
}

const TimePickerStyle: SxProps = [
    {
        '.MuiFormControl-root': {
        }
    },
    {
        '.MuiInputBase-root': {
            borderRadius: '30px',
        }
    },
    {
        '.MuiInputBase-root:hover fieldset': {
            borderColor: '#c78034',
        }
    },
    {
        '.Mui-focused fieldset': {
            borderColor: '#c78034!important',
            borderWidth: '1px!important'
        }
    },
    {
        input: {
            padding: '0px 10px',
            height: '33px',
            color: '#373737',
            fontSize: '16px',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
        }
    },
    {
        fieldset: {
            borderColor: 'var(--border-color)',
        }
    },
    {
        width: '135px',
    }
];

export const enum TimePickerType {
    PICKER = 1,
    RANGE = 2,
}
export const enum TimePickerVersion {
    PICKER = 1,
    FIELD = 2,
}
type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends ReduxProps, IntlProps {
    valueFrom?: Moment;
    titleFrom?: string;
    placeHolderTimeFrom?: string;
    formatTimeFrom?: string;
    disableTimePickerFrom?: boolean;
    timeViewFrom?: TimeView[];
    styleFromTimePicker?: React.CSSProperties;
    onChangeFrom: (date: Moment | null) => void;
    onBlur?: () => void;

    disableTimeFrom?: (date: Moment | null) => any;//DisabledTimes;

    valueTo?: Moment;
    titleTo?: string;
    placeHolderTimeTo?: string;
    formatTimeTo?: string;
    disableTimePickerTo?: boolean;
    timeViewTo?: TimeView[];
    styleToTimePicker?: React.CSSProperties;
    onChangeTo?: (date: Moment | null) => void;
    disableTimeTo?: (date: Moment | null) => any;

    allowClear?: boolean;
    success?: boolean;
    type?: TimePickerType;
    version?: TimePickerVersion;
    setError?: (error: boolean) => void;
    tooLong?: TooLongInterface;
}

interface State {
    error: boolean;
    errorMessage: string;
    success: boolean;
    edited: boolean;
    tooLong: TooLongInterface
}

/**
 * Component that represent a delete circle button with pop confirm
 */
class TimePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: '',
            success: this.props.success ?? false,
            edited: false,
            tooLong: this.props.tooLong ? this.props.tooLong : {
                duration: TIME_PICKER_ERROR.TOOLONGVAL,
                errorStr: props.intl.formatMessage(TIME_PICKER_ERROR.TOOLONG)
            }
        }
    }

    __changeFrom = (date: Moment | null, onlyTyme = false) => {
        let valueFrom = this.props.valueFrom?.clone();

        if (date || this.props.allowClear) {
            if (date) {
                if (onlyTyme) {
                    if (valueFrom === undefined)
                        valueFrom = moment()
                    // if (this.props.onBlur)
                    //     this.props.onBlur();
                    valueFrom = valueFrom.hours(date.hours()).minutes(date.minutes()).seconds(date.seconds());
                } else {
                    valueFrom = date;
                }

                if (valueFrom?.isAfter(this.props.valueTo, "seconds")) {
                    this.setState({ error: true, errorMessage: this.props.intl.formatMessage(ErrorMessages[TimePickerError.FROMAFTERTO]) });
                    this.props.setError && this.props.setError(true);
                } else {
                    this.setState({ success: true, error: false, errorMessage: '' });
                    this.props.setError && this.props.setError(false);
                }
            }

            this.setState({ edited: true });
            this.props.onChangeFrom(valueFrom ?? null);
        }
    }



    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    __disableTimeFrom = (date: Moment | null) => {
        let disableTimeFrom = {};

        if (this.props.disableTimeFrom)
            disableTimeFrom = this.props.disableTimeFrom(date);

        //TODO internal disable time

        return disableTimeFrom;
    }
    __onBlur = () => {
        if (this.props.onBlur)
            this.props.onBlur();
    }

    __changeTo = (date: Moment | null, onlyTyme = false) => {
        let valueTo = this.props.valueTo?.clone();

        if (date || this.props.allowClear) {
            if (date) {
                if (onlyTyme) {
                    if (valueTo === undefined)
                        valueTo = moment()
                    // if (this.props.onBlur)
                    //     this.props.onBlur();
                    valueTo = valueTo.hours(date.hours()).minutes(date.minutes()).seconds(date.seconds());
                } else {
                    valueTo = date;
                }

                if (valueTo) {
                    if (valueTo?.isBefore(this.props.valueFrom, "seconds")) {
                        this.setState({ error: true, errorMessage: this.props.intl.formatMessage(ErrorMessages[TimePickerError.FROMAFTERTO]) });
                        this.props.setError && this.props.setError(true);
                    } else {
                        this.setState({ success: true, error: false, errorMessage: '' })
                        this.props.setError && this.props.setError(false);
                    }
                }
            }
            if (this.props.onChangeTo) {
                this.setState({ edited: true });
                this.props.onChangeTo(valueTo ?? null);
            }
        }

    }


    /**
     * Check if a time must be disabled
     * @param date the current date to verify
     * @returns an object containing the different disabled times
     */
    __disableTimeTo = (date: Moment | null) => {
        let disableTimeTo = {};

        if (this.props.disableTimeTo)
            disableTimeTo = this.props.disableTimeTo(date);

        //TODO internal disable time

        return disableTimeTo;
    }

    render() {
        const {
            valueFrom, titleFrom, placeHolderTimeFrom, formatTimeFrom, disableTimePickerFrom, timeViewFrom, styleFromTimePicker,
            valueTo, titleTo, placeHolderTimeTo, formatTimeTo, disableTimePickerTo, timeViewTo, styleToTimePicker, allowClear, type, version
        } = this.props;

        const { error, errorMessage, edited } = this.state;
        const CustomTimePicker = this.props.isSmartphone ? MobileTimePicker : MuiTimePicker;

        return (
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                            <p>{titleFrom ?? <FormattedMessage defaultMessage={'Start'} />}</p>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>

                                {

                                    version === TimePickerVersion.FIELD ?
                                        <div onBlur={this.__onBlur} style={{ display: 'flex' }}>
                                            <CustomTimePicker
                                                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                label={null}
                                                value={valueFrom}
                                                onAccept={this.__onBlur}
                                                onChange={e => this.__changeFrom(e, true)}
                                                format={formatTimeFrom ?? getFormat('TIME')}
                                                views={timeViewFrom ?? ['hours', 'minutes', 'seconds']}
                                                sx={TimePickerStyle}
                                                disabled={disableTimePickerFrom}
                                            />
                                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid var(--border-color)', borderLeft: 'none', paddingRight: '10px', borderRadius: '0 30px 30px 0' }}>
                                                <ClockCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                            </div> */}
                                        </div>
                                        :
                                        <AntdTimePicker
                                            placeholder={placeHolderTimeFrom}
                                            style={styleFromTimePicker ?? { width: '120px' }}
                                            format={formatTimeFrom ?? getFormat('TIME')}
                                            value={valueFrom}
                                            allowClear={allowClear}
                                            onBlur={this.__onBlur}
                                            onChange={e => this.__changeFrom(e, true)}
                                            disabledTime={this.__disableTimeFrom}
                                            status={error ? "error" : ""}
                                            disabled={disableTimePickerFrom}
                                        />
                                }
                            </div>
                        </div>
                    </Col>
                    {
                        type === TimePickerType.RANGE &&
                        <Col xs={{ span: 24 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', alignItems: 'center' }}>
                                <p>{titleTo ?? <FormattedMessage defaultMessage={'End'} />}</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
                                    {
                                        version === TimePickerVersion.FIELD ?
                                            <div onBlur={this.__onBlur} style={{ display: 'flex' }}>
                                                <CustomTimePicker

                                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                                    label={null}
                                                    value={valueTo}
                                                    onChange={e => this.__changeTo(e, true)}
                                                    format={formatTimeTo ?? getFormat('TIME')}
                                                    views={timeViewTo ?? ["hours", "minutes", "seconds"]}
                                                    sx={TimePickerStyle}
                                                    disabled={disableTimePickerTo}
                                                    onAccept={this.__onBlur}
                                                />
                                                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid var(--border-color)', borderLeft: 'none', paddingRight: '10px', borderRadius: '0 30px 30px 0' }}>
                                                    <ClockCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                                </div> */}
                                            </div>
                                            :
                                            <AntdTimePicker
                                                clearIcon={null}
                                                placeholder={placeHolderTimeTo}
                                                style={styleToTimePicker ?? { width: '120px' }}
                                                format={formatTimeTo ?? getFormat('TIME')}
                                                value={valueTo}
                                                allowClear={allowClear}
                                                onBlur={this.__onBlur}
                                                onChange={e => this.__changeTo(e, true)}
                                                disabledTime={this.__disableTimeTo}
                                                status={error ? "error" : ""}
                                                disabled={disableTimePickerTo}
                                            />
                                    }
                                </div>
                            </div>
                        </Col>
                    }
                    {
                        edited && valueFrom && valueTo && valueFrom.isDST() !== valueTo.isDST() &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="info" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={<FormattedMessage defaultMessage={'There is a scheduled time change ({value})'} values={{ value: valueFrom.isDST() ? "-1h" : "+1h" }} />} />
                        </Col>
                    }
                    {
                        edited && valueTo && valueTo.diff(valueFrom, 'hours', true) > this.state.tooLong.duration &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="warning" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={`${this.state.tooLong.errorStr} (${valueTo && roundDecimals(valueTo.diff(valueFrom, 'hours', true))}h).`} />
                        </Col>
                    }
                    {
                        edited && error &&
                        <Col xs={{ span: 24 }}>
                            <Alert type="error" showIcon
                                style={{ fontSize: "80%", fontStyle: 'italic' }}
                                message={errorMessage} />
                        </Col>
                    }
                </Row>
            </LocalizationProvider>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps);


export default connector(injectIntl(TimePicker));