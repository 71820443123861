import React from 'react';
import { browserName, browserVersion, isChrome, isFirefox, isEdgeChromium, isEdge, isIE } from "react-device-detect";
import { Layout } from 'antd';
import Logo from "../../images/logo/logo_sunkhronos.png";
import moment from 'moment';


interface Props {
    chrome: number;
    firefox: number;
}

const LINK_CHROME = "https://www.google.com/chrome/";
const LINK_FIREFOX = "https://www.mozilla.org/firefox/browsers/";
const LINK_EDGE = "https://www.microsoft.com/edge";
const LINK_GOOGLE = "https://google.com/"
const EDGE_CHROMIUM_VERSION = 79
const { Header, Footer, Content } = Layout;

/**
 * Component that displays if the users has an outdated browser version
 */
class WrongBrowser extends React.Component<Props> {
   
    render() {
        
        return (
            <Layout>
                <Header className='header-container'>
                    <img src={Logo} className="sunkhro-logo" alt="Sunkhronos logo" />
                </Header>
                <Content className='content-container'>
                    <h1 className='content-title'>La version de votre navigateur n'est pas supportée par ce site!</h1>
                    {isIE ? 
                        <div className='content-text'>
                            <p>Vous utilisez Internet Explorer. Ce navigateur n'est plus supporté et a été remplacé par Microsoft Edge</p>
                        </div>
                        : 
                        <div className='content-text'>
                            {isEdge || isFirefox || isChrome ? <p>Vous utilisez {browserName} version {browserVersion}, il faut au minimum la version: {isChrome? this.props.chrome: isFirefox ? this.props.firefox : EDGE_CHROMIUM_VERSION }</p>: ""}
                            <p>Utiliser d'anciennes versions du navigateur consititue une risque de sécurité, à la fois pour nous, et pour vous. Nous vous recommendons de passer à une version plus récente.</p>
                        </div> }
                   
                    <div className="content-link">
                        <p>vous pouvez télécharger la dernière version <a target="_blank" rel="noreferrer" href={isChrome? LINK_CHROME: isFirefox? LINK_FIREFOX: isEdgeChromium || isEdge || isIE ? LINK_EDGE : LINK_GOOGLE}>ici</a> </p>
                    </div>
                </Content>
                <Footer className='footer-container'>{`© ${moment().year()} - Sunkhronos - All rights reserved`}</Footer>
            </Layout>
        )
    }
}

export default WrongBrowser;