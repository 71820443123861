
import { Button, Col, notification, Row, Tooltip } from 'antd';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { BsArrowDownCircle } from 'react-icons/bs';
import { RxReader, RxUpdate } from 'react-icons/rx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeAppVersion } from '../../../store/actions/configurations';
import Network from '../../../utils/network';
import { AppVersion } from '../../../utils/types/generalTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { emptyCache, showNotif } from '../../../utils/utils';

interface IProps {
    tooltipTitle?: string;
    loadingButtonTitle?: string;
    updatingButtonTitle?: string;
    updatingButtonTooltip?: string;
    buttonStyle?: CSSProperties;
    loadingButtonStyle?: CSSProperties;
    updatingButtonStyle?: CSSProperties;
    onLoading?(): void;
    onUpdate?(): void;
    updateGoToReleaseNotes?: boolean;
}

type Props = IProps;

const CheckVersionButton = (props: Props) => {
    const [versionLoading, setVersionLoading] = useState<boolean>(false)
    const history = useHistory()
    // Redux dispatch
    const dispatch = useDispatch()
    const api = notification
    const { pathname } = useLocation()
    const permanentNotifApi = useSelector((state: ApplicationState) => state.configurations.permanentNotifApi)
    const appVersion = useSelector((state: ApplicationState) => state.configurations.appVersion)
    const [newVersion, setNewVersion] = useState<string | undefined>(undefined)

    const getLang = useCallback(() => {
        const splitted_pathname = pathname.split('/')
        return (['fr', 'en', 'de', 'it'].includes(splitted_pathname[1])) ? splitted_pathname[1] : 'fr'
    }, [pathname])

    const goTo = useCallback((link: string) => history.push(`/${getLang()}${link}`), [history, getLang])

    const localAppVersion = useRef<AppVersion | undefined>(undefined)

    const intl = useIntl();

    useEffect(() => {
        if (appVersion !== undefined) {
            const version: string | undefined = process.env.REACT_APP_VERSION;

            if (version !== undefined && version !== appVersion.data.version) {
                setNewVersion(appVersion.data.version)
            } else {
                setNewVersion(undefined)
            }
        } else {
            setNewVersion(undefined)
        }

    }, [appVersion])

    const refreshAppVersion = useCallback(() => {
        const appName: string | undefined | JSX.Element = process.env.REACT_APP_NAME;
        const version: string | undefined = process.env.REACT_APP_VERSION;
        if (appName === undefined) {
            showNotif(api, intl.formatMessage({ defaultMessage: 'Unable to check for a new version. Please correctly setup App Name' }), "error", undefined, intl)
            return
        }
        if (version === undefined) {
            showNotif(api, intl.formatMessage({ defaultMessage: 'Unable to check for a new version. Please correctly setup App Version' }), "error", undefined, intl)
            return
        }

        if (!versionLoading) {
            setVersionLoading(true)

            Network.getAppVersion(appName)
                .then(
                    (response: AppVersion) => {

                        if ((localAppVersion.current !== undefined && localAppVersion.current.version !== response.version) ||
                            (localAppVersion.current === undefined && version !== undefined && version !== response.version)) {
                            showNotif(
                                permanentNotifApi ? permanentNotifApi : api,
                                intl.formatMessage({ defaultMessage: 'An update is available' }),
                                "update",
                                <Row gutter={[10, 0]}>
                                    <Col xs={{ span: 10 }}>{intl.formatMessage({defaultMessage: 'From version'})}{': '}</Col>
                                    <Col xs={{ span: 14 }}>{version}</Col>
                                    <Col xs={{ span: 10 }}>{intl.formatMessage({defaultMessage: 'To version'})}{': '}</Col>
                                    <Col xs={{ span: 14 }}>{response.version}</Col>
                                    <Col xs={{ span: 24 }}><Button onClick={() => goTo('/help/version')} type='link'>{intl.formatMessage({defaultMessage: 'See changelogs'})}</Button></Col>
                                </Row>,
                                intl)
                        }
                        if (!isEqual(localAppVersion.current, response)) {
                            localAppVersion.current = response
                            dispatch(changeAppVersion(response))
                        }
                        setVersionLoading(false)
                        if (version !== undefined && version !== response.version) {
                            setNewVersion(response.version)
                        }
                    })
                .catch(() => {
                    setVersionLoading(false)
                    showNotif(api, intl.formatMessage({ defaultMessage: 'An error occurred while retrieving updates' }), "error")

                });
        }

    }, [versionLoading, api, intl, permanentNotifApi, goTo, dispatch])




    const updatingButtonStyle: CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
        ...props.buttonStyle,
        ...props.updatingButtonStyle
    }
    const loadingButtonStyle: CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
        ...props.buttonStyle,
        ...props.loadingButtonStyle
    }

    if (newVersion && props.updateGoToReleaseNotes) {

        return (
            <Tooltip title={`${props.updatingButtonTooltip ?? intl.formatMessage({ defaultMessage: 'See changelogs' })} ${newVersion}`}>
                <Button style={updatingButtonStyle} onClick={(e) => { props.onUpdate && props.onUpdate(); goTo('/help/version'); e.stopPropagation() }} type={'primary'}>
                    <span style={{ display: 'inline-flex', gap: 5 }} className='anticon'><RxReader className='anticon' />{props.updatingButtonTitle ?? <FormattedMessage defaultMessage={'Changelogs'} />}</span>
                </Button>
            </ Tooltip>
        )
    }
    if (newVersion) {
        return (
            <Tooltip title={`${props.updatingButtonTooltip ?? intl.formatMessage({ defaultMessage: 'Install update' })} ${newVersion}`}>
                <Button style={updatingButtonStyle} onClick={(e) => { props.onUpdate && props.onUpdate(); emptyCache(); e.stopPropagation() }} type={'primary'}>
                    <span style={{ display: 'inline-flex', gap: 5 }} className='anticon'><BsArrowDownCircle className='anticon' />{props.updatingButtonTitle ?? <FormattedMessage defaultMessage={'Install'} />}</span>
                </Button>
            </ Tooltip>

        )
    }
    return (
        <Tooltip title={props.tooltipTitle ?? intl.formatMessage({ defaultMessage: 'Search for new updates' })}>
            <Button style={loadingButtonStyle} loading={versionLoading} onClick={(e) => { props.onLoading && props.onLoading(); refreshAppVersion(); e.stopPropagation() }} type={'primary'}>
                <span style={{ display: 'inline-flex', gap: 5 }} className='anticon'><RxUpdate className='anticon' />{props.loadingButtonTitle ?? <FormattedMessage defaultMessage={'Changelogs'} />}</span>
            </Button>
        </ Tooltip>
    )

}

export default CheckVersionButton;
