import { CloseCircleOutlined, CloseOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SaveOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Popconfirm, Popover, Row, Select, Space, Spin, Tabs, Tooltip } from 'antd';
import React from 'react';
import { BiDirections, BiMap } from 'react-icons/bi';
import { ImMap2 } from 'react-icons/im';
import { connect } from 'react-redux';
import { Rules } from '../../rbacRules';
import { changeGroups, changeUsers } from '../../store/actions/teamManagement';
import '../../styles/courseManagement.css';
import Network from '../../utils/network';
import { Course, Group, POI, PoiWithUpdate, RouterProps, Sector, User } from '../../utils/types/generalTypes';
import { ApplicationState, LocationDispatchProps, StoreDispatch, TeamManagementDispatchProps } from '../../utils/types/storeTypes';
import CircleButton from '../common/fields/circleButton';
import Can from '../common/general/can';
import Card from '../common/general/card';
import MapLeaflet from '../common/general/map';
import Container from '../common/navigations/container';
// import Map from '../../images/valais4.webp';
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet/dist/leaflet.css";
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'react-fast-compare';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Prompt } from 'react-router';
import { changeCourses, changeOnlyBasePois, changeOnlyPois, changeSectors, loadPois } from '../../store/actions/location';
import { showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import PoiTab from './poi/poiTab';
import SectorTab from './sector/sectorTab';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

// const DEFAULT_IMAGE_X = 22;
// const DEFAULT_IMAGE_Y = 38;

interface IProps {
    users: User[];
    groups: Group[] | undefined;
    isSmartphone: boolean;
    courses?: Course[],
    sectors?: Sector[],
    pois?: PoiWithUpdate;
    basePois?: PoiWithUpdate;

}

type Props = IProps & TeamManagementDispatchProps & LocationDispatchProps & RouterProps & IntlProps;

interface State {
    searchedUsers: User[] | undefined;
    searchedGroups: Group[] | undefined;
    usersLoading: boolean;
    groupsLoading: boolean;
    selectedUsers: number[];
    isDrawerVisible: boolean;
    isEditMode: boolean;
    isEditPOI: boolean;
    poiSelected?: POI;
    activeDrags: number;
    latitude?: number;
    longitude?: number;
    pois?: PoiWithUpdate;
    basePois?: PoiWithUpdate;
    isBasePois: boolean;
    modifiedPois: POI[];
    // sectors: Sector[];
    // courses: Course[];
    loadingProgress: number;
    isLoading: boolean;
    modified: boolean;
    displayConfirmUpdate: boolean;
    loadingSaveAll: boolean;
    poisKey: number;
}

/**
 * Page with the users list
 */
class CourseManagement extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            poisKey: 0,
            searchedUsers: undefined,
            searchedGroups: undefined,
            usersLoading: false,
            groupsLoading: false,
            selectedUsers: [],
            isDrawerVisible: false,
            isEditMode: false,
            isEditPOI: false,
            activeDrags: 0,
            pois: undefined,
            basePois: undefined,
            isBasePois: false,
            modifiedPois: [],
            // sectors: [],
            // courses: [],
            loadingProgress: 0,
            isLoading: true,
            loadingSaveAll: false,
            modified: false,
            displayConfirmUpdate: false,
        };
    }

    componentDidMount() {
        this.props.loadPois!();
        this.refreshSectors();
        this.refreshCourses();
        this.props.pois && this.setState({ pois: this.props.pois ? this.props.pois : undefined, isLoading: false });
        this.props.basePois && this.setState({ basePois: this.props.basePois ? this.props.basePois : undefined, isLoading: false });

        // For image (not map)
        // let contextMenu = document.getElementById('map-context-menu');
        // contextMenu && contextMenu.addEventListener('contextmenu', this._handleContextMenu);
    }

    // For image (not map)
    // componentWillUnmount() {
    //     let contextMenu = document.getElementById('map-context-menu');
    //     contextMenu && contextMenu.removeEventListener('contextmenu', this._handleContextMenu);
    // }
    // _handleContextMenu = (e) => {
    //     e.preventDefault();
    //     this.setState({ isDrawerVisible: true, isEditMode: true, latitude: e.layerX - DEFAULT_IMAGE_X, longitude: e.layerY - DEFAULT_IMAGE_Y });
    // }
    // onStart = () => {
    //     this.setState({ activeDrags: this.state.activeDrags + 1 });
    // };
    // onStop = (e: any, data: any) => {
    //     // let latitude = e.layerX - DEFAULT_IMAGE_X;
    //     // latitude = latitude > 0 ? latitude : 0;
    //     // let longitude = e.layerY - DEFAULT_IMAGE_Y;
    //     // longitude = longitude > 0 ? longitude : 0;
    //     this.setState({ activeDrags: this.state.activeDrags - 1, isDrawerVisible: true });
    // };

    componentDidUpdate() {
        if (this.state.modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
        if (!isEqual(this.props.pois, this.state.pois)) {
            this.props.pois && this.setState({ pois: this.props.pois ? this.props.pois : undefined, isLoading: false });
        }
        if (!isEqual(this.props.basePois, this.state.basePois)) {
            this.props.basePois && this.setState({ basePois: this.props.basePois ? this.props.basePois : undefined, isLoading: false });
        }
    }

    refreshCourses = () => {
        Network.getCourse().then(
            (response: Course[]) => {
                const courses = response;
                this.props.changeCourses!(courses);
            }
        )
    }

    refreshSectors = () => {
        Network.getSector().then(
            (response: Sector[]) => {
                const sectors = response;
                this.props.changeSectors!(sectors);
            }
        )
    }

    /**
     * Change tab
     * @param tab the current tab key
     */
    onChangeTab = (tab: string): void => {
        // if (this.state.modified) {
        //     Modal.confirm({
        //         width: '500px',
        //         title: "Modifications non sauvegardées",
        //         content: (
        //             <p>Voulez-vous changer d'onglet sans sauvegarder les modifications ?</p>
        //         ),
        //         centered: true,
        //         cancelText: "Oui",
        //         okText: "Non",
        //         onCancel: () => {
        //             this.props.history.replace(`/${this.props.match.params.lang}/course-management/list/${tab}`);
        //             this.setState({ refreshMap: tab, modified: false });
        //         },
        //     });
        // } else {
        this.setState({ isEditMode: false, modified: false }, () => this.props.history.replace(`/${this.props.match.params.lang}/course-management/list/${tab}`));
        // }
    }

    markToOrNotUpdate = (toCancel: boolean, poiId?: number,) => {
        if (poiId !== undefined) {
            const { modifiedPois } = this.state;
            const poi = modifiedPois.find(mp => mp.id === poiId)
            poi && (poi.notToUpdate = (toCancel ? true : undefined));

            this.setState({ modifiedPois })
        }
    }
    changePois = (pois: POI[]) => {
        const state_pois = cloneDeep(this.state.pois)
        state_pois!.data = pois
        this.setState({ pois: state_pois, poisKey: (this.state.poisKey + 1) })

        console.log("CHANGEPOIS", pois)
        this.props.changeOnlyPois!(pois)

    }
    changeBasePois = (pois: POI[]) => {
        const state_basePois = cloneDeep(this.state.basePois)
        state_basePois!.data = pois
        this.setState({ basePois: state_basePois, poisKey: (this.state.poisKey + 1) })

        console.log("CHANGEBASEPOIS", pois)

        this.props.changeOnlyBasePois!(pois)

    }

    updatePois = () => {
        this.setState({ displayConfirmUpdate: true })
    }

    saveAllPoisModifications = () => {
        const { modifiedPois } = this.state;
        const { intl } = this.props;

        let poisToSave = modifiedPois.filter(mp => mp.notToUpdate !== true && (mp.id === undefined || mp.id < 0 || mp.toUpdate === true || mp.toDelete === true));
        poisToSave = poisToSave.map(p => {
            p.courses = p.courses?.map(c => {
                return { id: c.id };
            });
            p.groups = p.groups?.map(c => {
                return { id: c.id };
            });
            p.sectors = p.sectors?.map(c => {
                return { id: c.id };
            });
            p.oldData = undefined;
            return p;
        })
        this.setState({ loadingSaveAll: true })
        Network.saveAllPoisModifications(poisToSave).then(
            response => {
                if (response.status === "nothing change") {
                    showNotification(intl.formatMessage({ defaultMessage: 'No changes to be made' }), "warning");
                    this.setState({ loadingSaveAll: false });
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The changes successfully updated' }), "success");
                    this.props.loadPois!(true);
                    this.setState({ displayConfirmUpdate: false, modified: false, modifiedPois: [], loadingSaveAll: false });
                }
            },
            () => {
                this.setState({ loadingSaveAll: false });
                showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while saving the modifications' }), "error");
            },
        )
    }

    renderToUpdateContent = () => {
        const { modifiedPois } = this.state;
        const { intl } = this.props;
        const poisToAdd = modifiedPois.filter(mp => mp.id === undefined || mp.id < 0);
        const poisToModify = modifiedPois.filter(mp => mp.toUpdate === true);
        const poisToDelete = modifiedPois.filter(mp => mp.toDelete === true);
        return (
            <>
                <p style={{ marginBottom: '12px' }}><FormattedMessage defaultMessage={'Changes that will be applied during the update'} />{':'}</p>
                <p style={{ marginTop: '8px' }}><b><FormattedMessage defaultMessage={'Points of interest added'} />{':'}</b></p>
                <ul>
                    {
                        poisToAdd.length > 0 ?
                            poisToAdd.map(p => {
                                return (
                                    <li key={`poi-to-add-${p.id}`}>
                                        <span style={p.notToUpdate ? { textDecoration: 'line-through', opacity: 0.7 } : { color: 'green' }}>{p.title}</span>
                                        {
                                            !p.notToUpdate ?
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Cancel this addition' })}>
                                                    <CloseCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(true, p.id)} />
                                                </Tooltip>
                                                :
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Reinstate this addition' })}>
                                                    <PlusCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(false, p.id)} />
                                                </Tooltip>
                                        }
                                    </li>
                                )
                            })
                            :
                            <li style={{ opacity: 0.7 }}><FormattedMessage defaultMessage={'No points of interest were added'} /></li>
                    }
                </ul>
                <p style={{ marginTop: '8px' }}><b><FormattedMessage defaultMessage={'Modified points of interest'} />{':'}</b></p>
                <ul>
                    {
                        poisToModify.length > 0 ?
                            poisToModify.map(p => {
                                return (

                                    <li key={`poi-to-modify-${p.id}`}>
                                        <span style={p.notToUpdate ? { textDecoration: 'line-through', opacity: 0.7 } : { color: 'orange' }}>{p.title}</span>
                                        {
                                            !p.notToUpdate ?
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Cancel this change' })}>
                                                    <CloseCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(true, p.id)} />
                                                </Tooltip>
                                                :
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Reinstate this change' })}>
                                                    <PlusCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(false, p.id)} />
                                                </Tooltip>
                                        }
                                        <Popover
                                            placement={this.props.isSmartphone ? 'top' : "right"}
                                            overlayStyle={{
                                                width: "350px"
                                            }}
                                            title={
                                                <Row gutter={[10, 10]}>
                                                    <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                        <p><b><FormattedMessage defaultMessage={'Before'} /></b></p>
                                                    </Col>
                                                    <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                        <p><b><FormattedMessage defaultMessage={'After'} /></b></p>
                                                    </Col>
                                                </Row>
                                            }
                                            content={
                                                <>
                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.title}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.title}</p>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.km}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.km}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.latitude}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.latitude}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.longitude}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.longitude}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.sectors?.map((s, i) => s.title + (p.oldData?.sectors?.length === i + 1 ? '' : ', '))}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.sectors?.map((s, i) => s.title + (p.sectors?.length === i + 1 ? '' : ', '))}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[10, 10]}>
                                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }}>
                                                            <p>{p.oldData?.courses?.map((c, i) => c.title + (p.oldData?.courses?.length === i + 1 ? '' : ', '))}</p>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} xs={{ span: 12 }}>
                                                            <p>{p.courses?.map((c, i) => c.title + (p.courses?.length === i + 1 ? '' : ', '))}</p>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }>
                                            <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                                        </Popover>
                                    </li>
                                )
                            })
                            :
                            <li style={{ opacity: 0.7 }}><FormattedMessage defaultMessage={'No points of interest were changed'} /></li>
                    }
                </ul>
                <p style={{ marginTop: '8px' }}><b><FormattedMessage defaultMessage={'deleted points of interest'} />{':'}</b></p>
                <ul>
                    {
                        poisToDelete.length > 0 ?
                            poisToDelete.map(p => {
                                return (
                                    <li key={`poi-to-delete-${p.id}`}>
                                        <span style={p.notToUpdate ? { textDecoration: 'line-through', opacity: 0.7 } : { color: 'red' }}>{p.title}</span>
                                        {
                                            !p.notToUpdate ?
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Cancel this deletion' })}>
                                                    <CloseCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(true, p.id)} />
                                                </Tooltip>
                                                :
                                                <Tooltip title={intl.formatMessage({ defaultMessage: 'Reinstate this deletion' })}>
                                                    <PlusCircleOutlined style={{ marginLeft: '5px' }} onClick={() => this.markToOrNotUpdate(false, p.id)} />
                                                </Tooltip>
                                        }
                                    </li>
                                )
                            })
                            :
                            <li style={{ opacity: 0.7 }}><FormattedMessage defaultMessage={'No points of interest were deleted'} /></li>
                    }
                </ul>
            </>
        )
    }

    render() {
        const { isDrawerVisible, isEditMode, pois, basePois, isBasePois } = this.state;
        const { sectors, courses, intl } = this.props;
        return (
            <Can rule={Rules.CourseManagement.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: "Points d'intérêt", link: "/course-management/list/" }]}>
                    <Tabs animated onChange={this.onChangeTab} activeKey={this.props.match.params.tab ? this.props.match.params.tab : "map"} className="team-parent">
                        <Tabs.TabPane tab={
                            <span>
                                {/* <GiGps className="anticon"/> */}
                                <ImMap2 className="anticon" />
                                <FormattedMessage defaultMessage={'Map'} />
                            </span>
                        } key="map">
                            <Card
                                className="team-card"
                                icon={<ImMap2 className="anticon" />}
                                title={<FormattedMessage defaultMessage={'Map'} />}
                                headerElements={[
                                    <Space key="course-management-all-actions" direction="horizontal">
                                        {
                                            this.state.modified && !this.props.isSmartphone &&
                                            <p style={{ color: '#de7516' }}><WarningOutlined /> <FormattedMessage defaultMessage={'There is unsaved changes'} /></p>
                                        }
                                        <Select
                                            disabled={isEditMode}
                                            placeholder={<FormattedMessage defaultMessage={'Locations to show'} />}
                                            onChange={(e) => this.setState({ isBasePois: e?.toString() === "2" })}
                                            style={{ width: '100%' }}
                                            value={isBasePois ? "2" : "1"}
                                            showArrow
                                        >
                                            <Select.Option value="1"><FormattedMessage defaultMessage={'Workplaces'} /></Select.Option>
                                            <Select.Option value="2"><FormattedMessage defaultMessage={'Departures'} /></Select.Option>
                                        </Select>
                                        <CircleButton
                                            small
                                            disabled={!this.state.modified}
                                            onClick={() => this.updatePois()}
                                            icon={<SaveOutlined />}
                                            title={intl.formatMessage({ defaultMessage: 'Save' })}
                                            placement="topLeft"
                                            key="course-management-save-modifications" />
                                        {
                                            isEditMode === false ?
                                                <CircleButton
                                                    small
                                                    disabled={isDrawerVisible}
                                                    onClick={() => this.setState({ isEditMode: true })}
                                                    icon={<EditOutlined />}
                                                    title={intl.formatMessage({ defaultMessage: 'Enter edit mode' })}
                                                    placement="topLeft"
                                                    key="course-management-edit-poi" />
                                                :
                                                this.state.modified ?
                                                    <Popconfirm
                                                        title={<FormattedMessage defaultMessage={'Do you want to leave edit mode? All unsaved changes will be lost'} />}
                                                        onConfirm={() => {
                                                            this.setState({ isEditMode: false, modified: false, modifiedPois: [] });
                                                        }}
                                                        okText={<FormattedMessage defaultMessage={'Exit'} />}
                                                        cancelText={<FormattedMessage defaultMessage={'Cancel'} />}
                                                        placement="topRight"
                                                    >
                                                        <CircleButton
                                                            small
                                                            disabled={isDrawerVisible}
                                                            // onClick={() => this.setState({ isEditMode: false, modified: false, modifiedPois: [] });}
                                                            icon={<CloseOutlined />}
                                                            title={intl.formatMessage({ defaultMessage: 'Exit edit mode' })}
                                                            placement="topLeft"
                                                            key="course-management-show-poi" />
                                                    </Popconfirm>
                                                    :
                                                    <CircleButton
                                                        small
                                                        disabled={isDrawerVisible}
                                                        onClick={() => this.setState({ isEditMode: false, modified: false, modifiedPois: [] })}
                                                        icon={<CloseOutlined />}
                                                        title={intl.formatMessage({ defaultMessage: 'Exit edit mode' })}
                                                        placement="topLeft"
                                                        key="course-management-show-poi" />
                                        }
                                    </Space>
                                ]}>
                                <div className="__course-map-content">
                                    {/* <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{ height: '100%' }}>
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {
                                            pois.length > 0 &&
                                            pois.map(p => {
                                                if (p.longitude && p.latitude) {
                                                    return <Marker position={[p.longitude, p.latitude]}>
                                                        <Popup>
                                                           {p.title}
                                                        </Popup>
                                                    </Marker>
                                                } else { return null }
                                            })
                                        }
                                    </MapContainer> */}
                                    <Prompt
                                        when={this.state.modified}
                                        message={intl.formatMessage({ defaultMessage: "The changes you have made won't be saved. Do you want to continue?" })} />
                                    {
                                        this.state.isLoading ?
                                            <Spin
                                                style={{ marginTop: '20px' }}
                                                size="large"
                                                spinning={this.state.isLoading} />
                                            :
                                            <MapLeaflet isModify={(pois) => this.setState({ modified: true, modifiedPois: [...pois] })} key={`map-parent-${this.state.isEditMode}`} pois={isBasePois ? basePois?.data : pois?.data} sectors={sectors} courses={courses} isEditMode={this.state.isEditMode} />
                                    }
                                    {/* <img id="map-context-menu" src={Map} width="100%" style={{}} />
                                    {
                                        pois.length > 0 &&
                                        pois.map(p => {
                                            return <Popover
                                                content={
                                                    <>
                                                        <p>latitude: {p.latitude}</p>
                                                        <p>longitude: {p.longitude}</p>
                                                    </>
                                                }
                                                title={p.title}>
                                                <div style={{ position: "absolute", top: `${p.longitude}px`, left: `${p.latitude}px`, zIndex: 1000 }} >
                                                    <FaMapPin onClick={() => isEditMode === false && this.setState({ isDrawerVisible: true, poiSelected: p })} className="anticon __course-map-poi" style={{ opacity: isEditMode ? 0.60 : 1, cursor: isEditMode ? 'default' : 'pointer' }} />
                                                </div>
                                            </Popover>
                                        })
                                    }


                                    {
                                        isEditMode &&
                                        <Draggable bounds="parent" {...dragHandlers}>
                                            <div style={{ position: "absolute", top: this.state.longitude ? this.state.longitude : 0 + "px", left: this.state.latitude ? this.state.latitude : 0 + "px", zIndex: 1000 }} >
                                                <FaMapPin style={{ cursor: 'grab' }} className="anticon __course-map-poi-adding" />
                                            </div>
                                        </Draggable>
                                    } */}

                                </div>

                                <Modal
                                    centered={true}
                                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 190px)' }}
                                    className="configurations-modal"
                                    title={<FormattedMessage defaultMessage={'Update points of interest'} />}
                                    visible={Boolean(this.state.displayConfirmUpdate)}
                                    destroyOnClose={true}
                                    onCancel={() => this.setState({ displayConfirmUpdate: false })}
                                    footer={[
                                        <Button loading={this.state.loadingSaveAll} type="dashed" onClick={() => this.setState({ displayConfirmUpdate: false })} key="to-update-cancel">
                                            <FormattedMessage defaultMessage={'Cancel'} />
                                        </Button>,
                                        <Button loading={this.state.loadingSaveAll} type="primary" onClick={() => this.saveAllPoisModifications()} key='to-update-validate'>
                                            <FormattedMessage defaultMessage={'Save'} />
                                        </Button>
                                    ]}>
                                    {this.renderToUpdateContent()}
                                </Modal>
                            </Card>
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={this.state.modified} tab={
                            <span>
                                <MdOutlineMapsHomeWork className="anticon" />
                                <FormattedMessage defaultMessage={'Workplaces'} />
                            </span>
                        } key="poi">
                            <PoiTab pois={this.state.pois} basePois={this.state.basePois} changePois={this.changePois} changeBasePois={this.changeBasePois} key={this.state.poisKey} />
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={this.state.modified} tab={
                            <span>
                                <BiMap className="anticon" />
                                <FormattedMessage defaultMessage={'Depatures'} />
                            </span>
                        } key="base-poi">
                            <PoiTab pois={this.state.pois} basePois={this.state.basePois} changePois={this.changePois} changeBasePois={this.changeBasePois} key={this.state.poisKey} departurePoint={true} />
                        </Tabs.TabPane>
                        <Tabs.TabPane disabled={this.state.modified} tab={
                            <span>
                                <BiDirections className="anticon" />
                                <FormattedMessage defaultMessage={'Sectors'} />
                            </span>
                        } key="sector">
                            <SectorTab />
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane disabled={this.state.modified} tab={
                            <span>
                                <GiPathDistance className="anticon" />
                                {"Parcours"}
                            </span>
                        } key="course">
                            <CourseTab />
                        </Tabs.TabPane> */}
                    </Tabs>
                </Container>
            </Can >
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeCourses: (courses: Course[]) => dispatch(changeCourses(courses)),
    changeSectors: (sectors: Sector[]) => dispatch(changeSectors(sectors)),
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    changeOnlyPois: (pois: POI[]) => dispatch(changeOnlyPois(pois)),
    changeOnlyBasePois: (pois: POI[]) => dispatch(changeOnlyBasePois(pois)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    courses: state.location.courses,
    sectors: state.location.sectors,
    pois: state.location.pois,
    basePois: state.location.basePois,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CourseManagement));