import { Group, User, UserSummary } from "../../utils/types/generalTypes";
import { StoreAction } from "../../utils/types/storeTypes";
import { CHANGE_GROUP, CHANGE_GROUPS, CHANGE_GROUPS_USERS, CHANGE_USER, CHANGE_USERS, CHANGE_USERS_GROUPS, RESET, RESET_ALL_APPART_GROUPS, TOGGLE_USERS_LOADING } from "../reducer/teamManagement";

/**
 * Change stored user
 * @param user the new user to store
 * @return a store action
 */
export const changeUser = (user: User | undefined): StoreAction => ({type: CHANGE_USER, data: user});

/**
 * Change stored users
 * @param users the new users to store
 * @return a store action
 */
export const changeUsers = (users: User[]): StoreAction => ({type: CHANGE_USERS, data: users});

/**
 * Change stored user's groups
 * @param groups the new stored user's groups
 * @return a store action
 */
export const changeUsersGroups = (groups: Group[] | undefined ) => ({type: CHANGE_USERS_GROUPS, data: groups});

/**
 * Change stored group
 * @param group the new group to store
 * @return a store action
 */
export const changeGroup = (group: Group | undefined): StoreAction => ({type: CHANGE_GROUP, data: group});

/**
 * Change stored groups
 * @param groups the new groups to store
 * @return a store action
 */
export const changeGroups = (groups: Group[]): StoreAction => ({type: CHANGE_GROUPS, data: groups});

/**
 * Change stored group's users
 * @param users the new stored group's users
 * @return a store action
 */
export const changeGroupsUsers = (users: UserSummary[] | undefined ) => ({type: CHANGE_GROUPS_USERS, data: users});


/**
 * Change stored users loading
 * @param users the new stored users loading
 * @return a store action
 */
export const toggleUsersLoading = (bool: boolean) => ({type: TOGGLE_USERS_LOADING, data: bool});

/**
 * Reset stored values
 */
export const resetAllAppartGroups = () => ({ type: RESET_ALL_APPART_GROUPS });
export const reset = () => ({ type: RESET });