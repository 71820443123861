import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useCallback } from "react";
import { useAppSelector } from "../../../../store/store";

interface DPProps {
    className?: string;
    displayMode: 'monthly' | 'weekly';
    onChangeDate: (date: Moment | null) => void;
    date: Moment;
    loading: boolean;
}

export const CustomDatePicker = (props: DPProps) => {
    const isSmartphone = useAppSelector((state) => state.window.isSmartphone);
    const { displayMode, date, onChangeDate, loading } = props;

    const customWeekStartEndFormat = useCallback((value: any) => {
        return `${moment(value).startOf('week').format('DD MMM')} - ${moment(value).endOf('week').format('DD MMM')}`;
    }, []);

    return (
        <div key={`custom-date-picker-div-${date.format('YYYYMMDD')}`} className={props.className} style={{ textAlign: isSmartphone ? 'left' : 'center' }}>
            {displayMode === 'monthly' ?
                <DatePicker.MonthPicker
                    key={`month-picker-${date.format("MM")}`}
                    suffixIcon={null}
                    className={`__monthly-planning-month-picker ${props.className}-picker`}
                    value={date}
                    onChange={onChangeDate}
                    placement={'bottomLeft'}
                    popupClassName={`${props.className}-picker-popup`}
                    //picker="month"
                    //mode="month"
                    size='large'
                    bordered={false}
                    inputReadOnly
                    format={isSmartphone ? "MMM YY" : "MMM YYYY"}
                    disabled={loading}
                    allowClear={false}
                />
                :
                displayMode === 'weekly' ?
                    <DatePicker.WeekPicker
                        className={`__weekly-planning-month-picker ${props.className}-picker`}
                        key={`week-picker-${date.format("YY")}-${date.week()}`}
                        //picker='week'
                        suffixIcon={null}
                        placement={'bottomLeft'}
                        size='large'
                        bordered={false}
                        format={customWeekStartEndFormat}
                        value={date}
                        onChange={onChangeDate}
                        allowClear={false}
                        inputReadOnly
                        disabled={loading}
                    />
                    :
                    <DatePicker
                        key={`day-picker-${date.format("DDMMYYYY")}`}
                        suffixIcon={null}
                        className={`__monthly-planning-month-picker ${props.className}-picker`}
                        value={date}
                        onChange={onChangeDate}
                        placement={'bottomLeft'}
                        picker="date"
                        size='large'
                        bordered={false}
                        inputReadOnly
                        format={isSmartphone ? "MMM YY" : "MMM YYYY"}
                        disabled={loading}
                        allowClear={false}
                    />
            }
        </div>

    );
};