import { CloseOutlined, CloudDownloadOutlined, EditOutlined, InfoCircleOutlined, PushpinOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, DatePicker, Divider, Empty, InputNumber, Row, Space, Switch, Tooltip } from 'antd';

import cloneDeep from 'lodash/cloneDeep';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeTypesOfDay } from '../../../store/actions/configurations';
import Network from '../../../utils/network';
import { MonthlyHoursOfTheYear, SimpleSettings, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import Card from '../../common/general/card';

interface IProps {
    // typesOfDay: TypeOfDay[];
}

type Props = IProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    year: number;
    settings?: SimpleSettings;
    modifySettings?: SimpleSettings;
    monthlyHours?: MonthlyHoursOfTheYear;
    modifyMonthlyHours?: MonthlyHoursOfTheYear;
    // createTypeOfDay: TypeOfDay | undefined;
}

/**
 * Component for the types of day tab in the configurations page
 */
class GeneralTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        Network.getMonthlyHours(this.state.year).then(
            response => this.setState({ monthlyHours: response }),
            (error) => {
                if (error.message.includes("No monthly hours")) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while loading the hours by year' }), "error");
                }
            },
        );


        Network.getSimpleSetting().then(
            response => this.setState({ settings: response }),
            (error) => {
                if (error.message.includes("No settings")) {
                    this.setState({ settings: undefined });
                } else {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while loading the settings' }), "error");
                }
            },
        );
    }

    /**
     * Change the current year
     * @param date the moment date 
     */
    changeYear = (date: Moment | null | undefined) => {
        if (!date) return;
        const year = date.year();
        this.setState({ year }, () => this.refresh());
    }

    updateSimpleSettings = () => {
        this.state.modifySettings !== undefined && Network.updateSimpleSetting(this.state.modifySettings).then(
            () => {
                this.setState({ modifySettings: undefined })
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The settings have been successfully updated' }), "success");
                this.refresh();
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while updating the settings' }), "error");
                this.refresh();
            },
        )
    }

    updateMonthlyHours = () => {
        this.state.modifyMonthlyHours !== undefined && Network.updateMonthlyHours(this.state.modifyMonthlyHours).then(
            () => {
                this.setState({ modifyMonthlyHours: undefined })
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The monthly hours have been successfully updated' }), "success");
                this.refresh();
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while updating the monthly hours' }), "error");
                this.refresh();
            },
        );
    }

    genHoursByYear = () => {
        const { modifyMonthlyHours, year, } = this.state;
        if (!modifyMonthlyHours || !modifyMonthlyHours.defaultWeeklyHours) return;
        Network.genHoursByYears(year, modifyMonthlyHours.defaultWeeklyHours).then(
            response => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The monthly hours have been successfully generated' }), "success");
                this.setState({ monthlyHours: response.message, modifyMonthlyHours: undefined });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occured while generating the monthly hours' }), "error");

            },
        );
    }

    render() {
        const { settings, monthlyHours, year, modifySettings, modifyMonthlyHours } = this.state;
        const { intl } = this.props;
        return (
            <Row gutter={[20, 20]}>
                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                    <Card icon={<PushpinOutlined />} title={<><FormattedMessage defaultMessage={'Monthly hours'} /> <Tooltip title={intl.formatMessage({ defaultMessage: 'Numbers of hours to be completed monthly (report).' })}><InfoCircleOutlined className="tab-tooltip-info" /></Tooltip></>} headerElements={[
                        <Space key={`generalTab-monthly-hours-card-header`}>
                            <DatePicker
                                disabled={modifyMonthlyHours !== undefined}
                                // disabled={newUserYearlyParams !== undefined}
                                picker="year"
                                placeholder={intl.formatMessage({ defaultMessage: 'Year' })}
                                value={moment().year(year)}
                                onChange={this.changeYear}
                                allowClear={false} />

                            {
                                modifyMonthlyHours === undefined ?
                                    <CircleButton
                                        small
                                        icon={<EditOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                        placement="top"
                                        onClick={() => this.setState({ modifyMonthlyHours: monthlyHours ? monthlyHours : { year: year } })}
                                    />
                                    :
                                    <CircleButton
                                        small
                                        icon={<CloseOutlined />}
                                        title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                        placement="top"
                                        onClick={() => this.setState({ modifyMonthlyHours: undefined })}
                                    />
                            }
                        </Space>
                    ]}>
                        {
                            modifyMonthlyHours !== undefined ?
                                <Collapse accordion={true} defaultActiveKey='modifyMonthlyHours-manual'>
                                    <Collapse.Panel header={<FormattedMessage defaultMessage={'Edit manually'} />} key={`modifyMonthlyHours-manual`}>
                                        <Row gutter={[20, 10]}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'January'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Janvier"}
                                                        value={modifyMonthlyHours.janHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.janHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'February'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Février"}
                                                        value={modifyMonthlyHours.febHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.febHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'March'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Mars"}
                                                        value={modifyMonthlyHours.marHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.marHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'April'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Avril"}
                                                        value={modifyMonthlyHours.aprHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.aprHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'May'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Mai"}
                                                        value={modifyMonthlyHours.mayHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.mayHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'June'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Juin"}
                                                        value={modifyMonthlyHours.junHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.junHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'July'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Juillet"}
                                                        value={modifyMonthlyHours.julHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.julHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'August'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Août"}
                                                        value={modifyMonthlyHours.augHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.augHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'September'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Septembre"}
                                                        value={modifyMonthlyHours.sepHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.sepHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'October'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Octobre"}
                                                        value={modifyMonthlyHours.octHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.octHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'November'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Novembre"}
                                                        value={modifyMonthlyHours.novHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.novHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p><FormattedMessage defaultMessage={'December'} /></p>
                                                    <InputNumber
                                                        className="__monthly-hours-edit-input "
                                                        min={-1}
                                                        max={744}
                                                        // placeholder={"Décembre"}
                                                        value={modifyMonthlyHours.decHours}
                                                        onChange={n => {
                                                            modifyMonthlyHours.decHours = toFinite(n)
                                                            this.setState({ modifyMonthlyHours });
                                                        }}
                                                    />
                                                </span>
                                            </Col>
                                            <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                                <Space>
                                                    <Button
                                                        icon={<CloseOutlined />}
                                                        title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                                        onClick={() => this.setState({ modifyMonthlyHours: undefined })} >
                                                        <FormattedMessage defaultMessage={'Cancel'} />
                                                    </Button>
                                                    <Button
                                                        onClick={this.updateMonthlyHours}
                                                        icon={<SaveOutlined />}
                                                        type="primary">
                                                        <FormattedMessage defaultMessage={'Save'} />
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                    <Collapse.Panel header={<FormattedMessage defaultMessage={'Generate automatically'} />} key={`modifyMonthlyHours-auto`}>
                                        <Space>
                                            <p><FormattedMessage defaultMessage={'Number of weekly hours:'} /></p>
                                            <InputNumber
                                                style={{ minWidth: '100px', width: "100px" }}
                                                value={modifyMonthlyHours?.defaultWeeklyHours}
                                                onChange={n => {
                                                    let { modifyMonthlyHours } = this.state;
                                                    if (modifyMonthlyHours) {
                                                        modifyMonthlyHours.defaultWeeklyHours = (n !== null ? n : undefined);
                                                    } else {
                                                        modifyMonthlyHours = {
                                                            defaultWeeklyHours: (n !== null ? n : undefined),
                                                        }
                                                    }
                                                    this.setState({ modifyMonthlyHours });
                                                }}
                                            />
                                            <CircleButton
                                                icon={<CloudDownloadOutlined />}
                                                title={intl.formatMessage({ defaultMessage: 'Generate' })}
                                                placement="left"
                                                onClick={() => this.genHoursByYear()}
                                                small
                                            />
                                        </Space>
                                    </Collapse.Panel>
                                </Collapse>
                                :
                                monthlyHours ?
                                    <Row gutter={[16, 16]}>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'January'} />{':'} </span>{monthlyHours && monthlyHours.janHours ? monthlyHours.janHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'February'} />{':'} </span>{monthlyHours && monthlyHours.febHours ? monthlyHours.febHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'March'} />{':'} </span>{monthlyHours && monthlyHours.marHours ? monthlyHours.marHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'April'} />{':'} </span>{monthlyHours && monthlyHours.aprHours ? monthlyHours.aprHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'May'} />{':'} </span>{monthlyHours && monthlyHours.mayHours ? monthlyHours.mayHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'June'} />{':'} </span>{monthlyHours && monthlyHours.junHours ? monthlyHours.junHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'July'} />{':'} </span>{monthlyHours && monthlyHours.julHours ? monthlyHours.julHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'August'} />{':'} </span>{monthlyHours && monthlyHours.augHours ? monthlyHours.augHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'September'} />{':'} </span>{monthlyHours && monthlyHours.sepHours ? monthlyHours.sepHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'October'} />{':'} </span>{monthlyHours && monthlyHours.octHours ? monthlyHours.octHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'November'} />{':'} </span>{monthlyHours && monthlyHours.novHours ? monthlyHours.novHours : "-"}
                                        </Col>
                                        <Col xs={{ span: 24 }} md={{ span: 8 }} xxl={{ span: 6 }}>
                                            <span style={{ fontWeight: 'bold' }}><FormattedMessage defaultMessage={'December'} />{':'} </span>{monthlyHours && monthlyHours.decHours ? monthlyHours.decHours : "-"}
                                        </Col>
                                    </Row>
                                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

                        }

                    </Card>
                </Col>
                <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                    <Card icon={<PushpinOutlined />} title={<FormattedMessage defaultMessage={'Display settings'} />}>
                        {settings &&
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, justifyContent: 'center', alignItems: 'center' }}>

                                    <Divider orientation={'left'} type={'horizontal'}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Switch checked={(modifySettings !== undefined || settings !== undefined) && (modifySettings !== undefined ? modifySettings.planningNbDisplayHours !== -1 || modifySettings.planningNbDisplayHoursPast !== -1 : settings.planningNbDisplayHours !== -1 || settings.planningNbDisplayHoursPast !== -1)} onChange={(checked) => {
                                                const newModifySettings = modifySettings ? modifySettings : cloneDeep(settings)
                                                newModifySettings.planningNbDisplayHoursPast = checked ? 168 : -1
                                                newModifySettings.planningNbDisplayHours = checked ? 48 : -1
                                                this.setState({ modifySettings: newModifySettings });
                                            }} />
                                            <span style={{ marginLeft: '.5rem' }}><FormattedMessage defaultMessage={'Restrict users'} /></span>
                                        </div>
                                    </Divider>
                                    {(modifySettings !== undefined || settings !== undefined) && (modifySettings !== undefined ? modifySettings.planningNbDisplayHours !== -1 || modifySettings.planningNbDisplayHoursPast !== -1 : settings.planningNbDisplayHours !== -1 || settings.planningNbDisplayHoursPast !== -1) ?
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 16, justifyContent: 'center', alignItems: 'center', maxWidth: 600, height: '35px' }}>
                                                <Switch checked={modifySettings ? modifySettings.planningNbDisplayHoursPast !== -1 : settings?.planningNbDisplayHoursPast !== -1} onChange={(checked) => {
                                                    const newModifySettings = modifySettings ? modifySettings : cloneDeep(settings)
                                                    newModifySettings.planningNbDisplayHoursPast = checked ? 168 : -1
                                                    this.setState({ modifySettings: newModifySettings });
                                                }} />
                                                {(modifySettings !== undefined || settings !== undefined) && (modifySettings ? modifySettings.planningNbDisplayHoursPast !== -1 : settings?.planningNbDisplayHoursPast !== -1) ? <>

                                                    <span><FormattedMessage defaultMessage={'Visibility of planning during'} /></span>
                                                    <InputNumber
                                                        className="team-create-user-inputs-job"
                                                        min={0}
                                                        placeholder={intl.formatMessage({ defaultMessage: 'Hours to show' })}
                                                        value={modifySettings ? modifySettings.planningNbDisplayHoursPast === -1 ? 168 : modifySettings.planningNbDisplayHoursPast : settings.planningNbDisplayHoursPast === -1 ? 168 : settings.planningNbDisplayHoursPast}
                                                        style={{
                                                            width: 60,
                                                            minWidth: 60
                                                        }}
                                                        onChange={n => {
                                                            const newModifySettings = modifySettings ? modifySettings : cloneDeep(settings)
                                                            newModifySettings.planningNbDisplayHoursPast = toFinite(n)
                                                            this.setState({ modifySettings: newModifySettings });
                                                        }}
                                                    />
                                                    <span><FormattedMessage defaultMessage={'Hours in the past.'} /></span>
                                                </> : <span><FormattedMessage defaultMessage={'There is no limit to displaying the planning in the past'} /></span>}

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 16, justifyContent: 'start', alignItems: 'center', maxWidth: 600, height: '35px' }}>
                                                <Switch checked={modifySettings ? modifySettings.planningNbDisplayHours !== -1 : settings?.planningNbDisplayHours !== -1} onChange={(checked) => {
                                                    const newModifySettings = modifySettings ? modifySettings : cloneDeep(settings)
                                                    newModifySettings.planningNbDisplayHours = checked ? 48 : -1
                                                    this.setState({ modifySettings: newModifySettings });
                                                }} />
                                                {(modifySettings !== undefined || settings !== undefined) && (modifySettings ? modifySettings.planningNbDisplayHours !== -1 : settings?.planningNbDisplayHours !== -1) ? <>
                                                    <span><FormattedMessage defaultMessage={'Planning visibility until'} /></span>
                                                    <InputNumber
                                                        className="team-create-user-inputs-job"
                                                        min={0}
                                                        placeholder={intl.formatMessage({ defaultMessage: 'Hours to show' })}
                                                        value={modifySettings ? modifySettings.planningNbDisplayHours === -1 ? 48 : modifySettings.planningNbDisplayHours : settings.planningNbDisplayHours === -1 ? 48 : settings.planningNbDisplayHours}
                                                        style={{
                                                            width: 60,
                                                            minWidth: 60
                                                        }}
                                                        onChange={n => {
                                                            const newModifySettings = modifySettings ? modifySettings : cloneDeep(settings)
                                                            newModifySettings.planningNbDisplayHours = toFinite(n)
                                                            this.setState({ modifySettings: newModifySettings });
                                                        }}
                                                    />
                                                    <span><FormattedMessage defaultMessage={'Hours in the future.'} /></span>
                                                </> : <span><FormattedMessage defaultMessage={'There is no limit to displaying the planning in the past'} /></span>}

                                            </div>

                                        </div>
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'start', alignItems: 'center', paddingLeft: 40 }}>
                                            <span><FormattedMessage defaultMessage={'There is no limit to displaying the planning in the past'} /></span>
                                        </div>

                                    }

                                    {modifySettings !== undefined &&
                                        <>
                                            <Divider type={'horizontal'} />
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 8 }}>

                                                <Alert type={'info'} style={{ maxWidth: 400, textAlign: 'center' }} message={<FormattedMessage defaultMessage={'These settings apply to both the mobile app and the Sunkhronos Admin planning.'} />} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                                <Button onClick={() => this.setState({ modifySettings: undefined })} type={'dashed'}><FormattedMessage defaultMessage={'Cancel'} /></Button>
                                                <Button onClick={this.updateSimpleSettings} type={'primary'}><FormattedMessage defaultMessage={'Save'} /></Button>
                                            </div>
                                        </>
                                    }
                                </div>


                            </>
                        }
                    </Card>
                </Col>
            </Row>

        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfDay: (t: TypeOfDay[]) => dispatch(changeTypesOfDay(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    typesOfDay: state.configurations.typesOfDay,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GeneralTab));