import { DeleteOutlined, InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Empty, message, Modal, Popconfirm, Row, Tooltip } from 'antd';
import toInteger from 'lodash/toInteger';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ByWeekday, Frequency } from 'rrule';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { CaseType, MOMENT_SHORT_TIME_FORMAT } from '../../utils/constants';
import { EventFrequences } from '../../utils/enumerations';
import Network from '../../utils/network';
import { Group, RouterProps, User } from '../../utils/types/generalTypes';
import { NetworkEventConfirmed } from '../../utils/types/networkTypes';
import { PlanningEvent, PlanningOvertime } from '../../utils/types/planningTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { alert, checkPrivilege, checkRBACRule, getCaseAndPlural } from '../../utils/utils';
import Can from '../common/general/can';
// import MapLeaflet from '../common/general/map';

type ReduxProps = ConnectedProps<typeof connector>

interface IProps {
    event: PlanningEvent | undefined;
    user: User | undefined;
    group: Group | undefined;
    onDeleteOvertime: (overtime: PlanningOvertime) => void;
    onCancel: () => void;
    onDelete: (event: PlanningEvent, editOccurence?: boolean, index?: number) => void;
    onEdit: (event: PlanningEvent, editOccurence?: boolean) => void;
    onCreateTemplate: () => void;
}

type Props = IProps & RouterProps & ReduxProps;

interface State {
    createTemplateLoading: boolean;
    confirmed: NetworkEventConfirmed[] | undefined;
    notConfirmed: NetworkEventConfirmed[] | undefined;
    key: string;
}

class EventDetailsModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            createTemplateLoading: false,
            confirmed: undefined,
            notConfirmed: undefined,
            key: 'summary',
        };
    }

    componentDidMount() {
        // get event confirmed state
        if (this.props.event) Network.eventConfirmed(this.props.event!.id!).then(
            (response: NetworkEventConfirmed[]) => {
                if (Array.isArray(response) && response.length > 0) {
                    const confirmed: NetworkEventConfirmed[] = response.filter(c => c.confirmed);
                    const notConfirmed: NetworkEventConfirmed[] = response.filter(c => !c.confirmed);
                    this.setState({ confirmed, notConfirmed });
                } else {
                    this.setState({ confirmed: [], notConfirmed: [] });
                }

            },
            () => alert("Un problème est survenu pendant le chargement de l'état de confirmation de l'événement", "warning"),
        );
    }

    /**
     * Get the text for the frequence interval
     * @param frequence the frequence
     * @param interval the interval
     * @return the formated text
     */
    getFrequenceIntervalText = (frequence: Frequency, interval: number) => `se produit chaque ${interval > 1 ? `${interval} ` : ''}${this.getFrequenceText(frequence)}`;

    /**
     * Get the text for the weekdays
     * @param weekdays a array of weekday
     * @return the formated text
     */
    getWeekdaysText = (weekdays: ByWeekday[]) => `se produit chaque ${weekdays.slice(1).reduce((a: string, v: ByWeekday) => a + ", " + this.getWeekdayText(v), this.getWeekdayText(weekdays[0]))}`;

    /**
     * Get the text for dtstart
     * @param start the dtstart
     * @return the formated text
     */
    getDtstartText = (start: Moment) => `commence à partir du ${start.format("dddd, DD MMMM YYYY")}`;

    /**
     * Get the text for the frequence interval
     * @param freq the frequence
     * @param interval the interval
     * @return the formated text
     */
    getUntilText = (until: Moment) => `jusqu'au ${until.format("dddd, DD MMMM YYYY")}`;

    /**
     * Get the text for the count
     * @param count the count
     * @return the formated text
     */
    getCountText = (count: number) => `se répète ${count} fois`;

    /**
     * Get the text for the frequence
     * @param frequence the frequence
     * @return the formated text
     */
    getFrequenceText = (frequence: Frequency) => {
        switch (frequence) {
            case EventFrequences.Hourly:
                return "heure";
            case EventFrequences.Daily:
                return "jour";
            case EventFrequences.Weekly:
                return "semaine";
            case EventFrequences.Monthly:
                return "mois";
            case EventFrequences.Yearly:
                return "année";
        }
    }

    /**
     * Get the text for one weekday
     * @param weekday the weekday
     * @return the formated text
     */
    getWeekdayText = (weekday: ByWeekday): string => {
        switch (weekday) {
            case 0:
                return "Lundi";
            case 1:
                return "Mardi";
            case 2:
                return "Mercredi";
            case 3:
                return "Jeudi";
            case 4:
                return "Vendredi";
            case 5:
                return "Samedi";
            default:
                return "Dimanche";
        }
    }

    /**
     * Get timer total hours
     */
    getTimerDuration = () => {
        const { event } = this.props;
        const diff = event!.timerStop!.diff(event!.timerStart, 'minutes');
        const hours = toInteger(diff / 60);
        const minutes = diff - (hours * 60);
        return `${hours ? (hours < 10 ? `0${hours} h ` : `${hours} h `) : ''}${minutes < 10 ? `0${minutes}` : minutes} minutes`;
    }

    /**
     * Create a template from the event
     */
    createTemplate = () => {
        const { event } = this.props;
        if (!event) return;
        this.setState({ createTemplateLoading: true });

        //create template object
        const template = {
            startDate: event!.startDate,
            endDate: event!.endDate,
            title: event!.title,
            color: event!.color,
            poi: event!.poi,
            vehicle: event!.vehicle,
            distance: event!.distance,
            basePoi: event!.basePoi
        };

        //create template
        Network.updateTemplate(template).then(
            () => {
                message.success("Le modèle à été crée.");
                this.setState({ createTemplateLoading: false });
                this.props.onCreateTemplate();
            },
            () => {
                message.error("Il y a eu un problème pendant la création du modèle.");
                this.setState({ createTemplateLoading: false });
            }
        );
    }



    calculateValidOvertime = () => {
        const event = this.props.event;
        if (event !== undefined && event.totalHoursOvertime !== undefined && this.props.event?.overtimes !== undefined) {
            let validOvertime = event.totalHoursOvertime



            for (const overtime of this.props.event.overtimes) {
                if (!overtime.isConfirmed) {
                    const time = moment.utc(moment(overtime.endDate).diff(moment(overtime.startDate))).format("HH:mm")
                    const hours = moment(time, MOMENT_SHORT_TIME_FORMAT).hours();
                    const minutes = moment(time, MOMENT_SHORT_TIME_FORMAT).minutes();
                    const minutesAsDecimal = Math.round((minutes / 60) * 100) / 100;
                    const timeAsDecimal = hours + minutesAsDecimal;
                    validOvertime = validOvertime - timeAsDecimal
                }
            }

            const validOvertimeAsTime = moment.utc(moment.duration(validOvertime, 'h').asMilliseconds()).format(MOMENT_SHORT_TIME_FORMAT)
            return <p>{validOvertimeAsTime}</p>;
        } else return <p>00:00</p>
    }

    getTabEventContent = () => {
        const { event, user, group } = this.props;
        const { Panel } = Collapse;
        if (!event) {
            return <></>;
        }
        switch (this.state.key) {
            case "summary":
                return (
                    <>
                        {event.isGlobal &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <p>{"Événement global."}</p>
                                </Col>
                            </Row>
                        }

                        {user &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <div>
                                        <p className="planning-details-group-name" onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${user!.id}`)}>{`Événement de  l'utilisateur ${user.last_name} ${user.first_name}`}</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {group &&
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <div>
                                        <p className="planning-details-group-name" onClick={() => this.props.history.push(`/${this.props.match.params.lang}/team-management/group-details/informations?id=${group!.id}`)}> Évenement du groupe {group.name}</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p>{"Début:"}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{this.props.isSmartphone ?
                                    moment(event.startDate).format("DD.MM.YYYY HH:mm")
                                    : moment(event.startDate).format("dddd, DD MMMM YYYY [à] HH:mm")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p>{"Fin:"}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{this.props.isSmartphone ?
                                    moment(event.endDate).format("DD.MM.YYYY HH:mm")
                                    : moment(event.endDate).format("dddd, DD MMMM YYYY [à] HH:mm")}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p>{"Durée de l'événement:"}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                <p>{moment.utc(moment.duration(event.endDate.diff(event.startDate, "hours", true), "hours").asMilliseconds()).add(30, 'seconds').startOf('minute').format(MOMENT_SHORT_TIME_FORMAT)}</p>
                            </Col>
                        </Row>
                        {
                            event.breakTimes && event.breakTimes.length > 0 && event.totalHoursBreakTime !== 0 ?

                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p>{"Durée des pauses:"}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <p>-{event.totalHoursBreakTime ? moment.utc(moment.duration(event.totalHoursBreakTime, 'hours').asMilliseconds()).add(30, 'seconds').startOf('minute').format("HH:mm") : "0"}</p>
                                    </Col>
                                </Row>
                                : ''
                        }
                        {
                            event.overtimes && event.overtimes.length > 0 ?
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p>{"Durée des heures supp:"}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        {this.calculateValidOvertime()}
                                    </Col>
                                </Row>
                                : ''
                        }
                        <Row>
                            <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                <p>{"Durée total:"}</p>
                            </Col>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                {
                                    <p>{event.totalHours ? moment.utc(moment.duration(event.totalHours, "hours").asMilliseconds()).add(30, 'seconds').startOf('minute').format("HH:mm") : "0h"}</p>
                                    // event.breakTimes && event.breakTimes.length > 0 ?
                                    //     <p>{parseFloat((event.endDate.diff(event.startDate, "hours", true)).toFixed(2)) - parseFloat(event.breakTimes.reduce((acc, val) => !val.isPaid ? acc + val.totalHours : acc, 0.0).toFixed(2))} heures</p>
                                    //     :
                                    //     <p>{event.endDate.diff(event.startDate, "hours", true).toFixed(2)} heures</p>
                                }
                            </Col>
                        </Row>
                        {event.typeOfDay &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Type de jour:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.typeOfDay?.title}
                                </Col>
                            </Row>
                        }
                        {event.typeOfDayOff &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Type de jour de repos:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.typeOfDayOff?.title}
                                </Col>
                            </Row>
                        }
                        {event.project &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{getCaseAndPlural(this.props.company?.projectDisplayText ? this.props.company?.projectDisplayText : 'Projet', false, CaseType.FIRST_LETTER_UPPERCASE)}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.project?.title}
                                </Col>
                            </Row>
                        }
                        {
                            event.staffType &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Aptitude nécessaire:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    {event.staffType.name}
                                </Col>
                            </Row>
                        }
                        {/* {
                            event.poi &&
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Emplacement:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                    <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.poi.latitude},${event.poi.longitude}`}>{event.poi?.title}</a>
                                </Col>
                                {/* <Col xs={{span : 24}} style={{ height: '200px'}}>
                                    <MapLeaflet key={`poi-display`} pois={[event.poi]} isEditMode={false} onlyDisplay={true} />
                                </Col> 
                            </Row>
                        } */}
                        {
                            event.color &&

                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                    <p>{"Couleur:"}</p>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                                    <p style={{ paddingTop: '', margin: '5px 6px 0 0', display: 'inline-flex', borderRadius: '50%', height: '16px', width: '16px', backgroundColor: event.color.color }}> </p>
                                    <p>{event.color?.title}</p>
                                    {/* <div style={{ borderRadius: '50%', width: '30px', height: '20px', backgroundColor: event.color.color }} /> */}
                                </Col>
                            </Row>
                        }
                    </>
                );
            case "break":
                return (
                    <>
                        {
                            event.breakTimes && event.breakTimes.length > 0 ?
                                <>
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
                                                {
                                                    event.breakTimes.map((bt, index) => {
                                                        return (
                                                            <Panel header={bt.title} key={index}
                                                            // extra={
                                                            //     <DeleteOutlined onClick={e => {
                                                            //         e.stopPropagation();
                                                            //     }} />
                                                            // }
                                                            >
                                                                <p><b>Date de début: </b>{bt.startDate.format("DD/MM/YYYY HH:mm")}</p>
                                                                <p><b>Date de fin: </b>{bt.endDate.format("DD/MM/YYYY HH:mm")}</p>
                                                                <p><b>Durée: </b>{moment.utc(moment.duration(bt.totalHours, 'hours').asMilliseconds()).add(30, 'seconds').startOf('minute').format("HH:mm")}</p>
                                                                <p>{bt.isPaid ? "À la charge de l'employeur." : "À la charge de l'employé."}</p>
                                                            </Panel>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                );
            case "overtime":
                return (
                    <>
                        {
                            event.overtimes && event.overtimes.length > 0 && event.overtimes.filter(o => o.isConfirmed === true).length > 0 ?
                                <>
                                    <Row>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                            <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
                                                {
                                                    event.overtimes.filter(o => o.isConfirmed === true).map((o, index) => {
                                                        return (
                                                            <Panel header={o.title} key={index} extra={
                                                                <Popconfirm
                                                                    title="Voulez-vous supprimer cette heure supplémentaire ?"
                                                                    onConfirm={() => {
                                                                        this.props.onDeleteOvertime(o);
                                                                    }}
                                                                    // onCancel={cancel}
                                                                    okText="Oui"
                                                                    cancelText="Non"
                                                                >
                                                                    <DeleteOutlined onClick={e => e.stopPropagation()} />
                                                                </Popconfirm>

                                                            }>
                                                                <p><b>Date de début: </b>{moment(o.startDate).format("DD/MM/YYYY HH:mm")}</p>
                                                                <p><b>Date de fin: </b>{moment(o.endDate).format("DD/MM/YYYY HH:mm")}</p>
                                                                {/* <p><b>Durée: </b>{moment.utc(moment.duration(o., 'hours').asMilliseconds()).format("HH:mm")}</p> */}
                                                                <p>{o.isNegative ? "Ceci est une heure négative." : ""}</p>
                                                            </Panel>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                );
            case "journey":
                return (
                    <>
                        {event.basePoi || event.poi || event.vehicle || event.distance ?
                            <>
                                {
                                    event.basePoi &&
                                    <Row>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                            <p>{"Lieu de départ:"}</p>
                                        </Col>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                            <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.basePoi.latitude},${event.basePoi.longitude}`}>{event.basePoi?.title}</a>
                                        </Col>
                                    </Row>
                                }
                                {
                                    event.poi &&
                                    <Row>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                            <p>{"Lieu de travail:"}</p>
                                        </Col>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                            <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.poi.latitude},${event.poi.longitude}`}>{event.poi?.title}</a>
                                        </Col>
                                        {/* <Col xs={{span : 24}} style={{ height: '200px'}}>
                                        <MapLeaflet key={`poi-display`} pois={[event.poi]} isEditMode={false} onlyDisplay={true} />
                                    </Col> */}
                                    </Row>
                                }
                                {/*
                                // Lieu d'engagement
                                event.basePoi &&
                                <Row>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                        <p>{"Lieu d'engagement:"}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${event.basePoi?.latitude},${event.basePoi?.longitude}`}>{event.basePoi?.title}</a>
                                    </Col>
                                </Row>
                            */}
                                {
                                    // Distance
                                    event.distance &&
                                    <Row>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                            <p>{"Distance (aller):"}</p>
                                        </Col>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                            <p>{event.distance?.toString()} km</p>
                                        </Col>
                                    </Row>
                                }
                                {
                                    // Vehicle
                                    event.vehicle &&
                                    <Row>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }}>
                                            <p>{"Véhicule:"}</p>
                                        </Col>
                                        <Col xs={{ span: 12 }} md={{ span: 12 }} style={{ textAlign: 'right' }}>
                                            <p>{event.vehicle?.name}</p>
                                        </Col>
                                    </Row>
                                }
                            </>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                );
            default:
                return <p>Error</p>
        }
    }

    render() {
        const { event } = this.props;

        if (event === undefined) return null;

        const creator = this.props.users?.find(u => u.id === event?.creatorId);
        const modifier = this.props.users?.find(u => u.id === event?.modifierId);

        const tabList = [
            {
                key: 'summary',
                tab: 'Résumé',
            },
            {
                key: 'break',
                tab: 'Pauses',
            },
            {
                key: 'overtime',
                tab: 'Heures supplémentaires',
            },
            {
                key: 'journey',
                tab: 'Trajet'
            }
        ];
        return (
            <Modal
                width="750px"
                className="planning-modal"
                title={event.title}
                visible={Boolean(event)}
                onCancel={() => {
                    this.setState({ key: "summary" });
                    this.props.onCancel();
                }}
                destroyOnClose={true}
                footer={[
                    <Button
                        key="details-modal-cancel"
                        type="dashed"
                        onClick={this.props.onCancel}>
                        {"Fermer"}
                    </Button>,
                    <Can
                        rule={Rules.Planning.Management}
                        // privilege={Privileges.Planning.Edition}
                        // privilegeCondition={event.userId !== undefined && user?.id === event.userId}
                        key={"planning-details-modal-parent-can"}>
                        <div key="planning-details-modal-parent">
                            <Popconfirm
                                title={"Etes-vous sûr de vouloir supprimer cet événement?"}
                                key="details-modal-delete"
                                onConfirm={() => { this.props.onCancel(); this.props.onDelete(event!); }}
                                icon={<QuestionCircleOutlined style={{ color: '#ff2626' }} />}
                                okText="Oui"
                                okButtonProps={{ danger: true, type: "link" }}
                                cancelText="Non"
                                cancelButtonProps={{ type: 'link' }}>
                                <Button danger>
                                    {"Supprimer"}
                                </Button>
                            </Popconfirm>
                            <Button
                                key="details-modal-edit"
                                type="primary"
                                onClick={() => { this.props.onCancel(); this.props.onEdit(event!) }}>
                                {"Modifier"}
                            </Button>
                        </div>
                    </Can>
                ]}>

                <Card
                    tabList={tabList}
                    activeTabKey={this.state.key}
                    className={this.state.key === "break" || this.state.key === "overtime" || (this.state.key === 'journey' || (this.state.key === 'timeclock') && !(event.basePoi || event.poi || event.vehicle || event.distance)) ? "__event-tab-without-padding" : ""}
                    onTabChange={key => this.setState({ key })}
                >
                    {
                        this.getTabEventContent()
                    }
                </Card>

                <div className="planning-details-modal-parent">
                    <p className="planning-details-modal-title">{"Général"}</p>
                    {
                        creator &&
                        <p className="planning-details-modal-element">{`Créé par ${creator.last_name} ${creator.first_name}, le ${event.created?.format("DD MMM YYYY")} à ${event.created?.format("HH:mm")}`}</p>
                    }
                    {
                        modifier &&
                        <p className="planning-details-modal-element">{`Modifié par ${modifier.last_name} ${modifier.first_name}, le ${event.modified?.format("DD MMM YYYY")} à ${event.modified?.format("HH:mm")}`}</p>
                    }
                    {
                        event.description &&
                        <span>
                            <p className="planning-details-modal-title">{"Description"}</p>
                            <p>{event.description}</p>
                        </span>
                    }
                    {
                        event.userNote &&
                        <span>
                            <p className="planning-details-modal-title">{"Commentaire de l'utilisateur"}</p>
                            <p>{event.userNote}</p>
                        </span>
                    }
                    {
                        event.timerStart &&
                        <span>
                            <p className="planning-details-modal-title">{"Chronomètre"}</p>
                            <p className="planning-details-modal-element">{`Commencé le ${event.timerStart.format("DD MMM YYYY [à] HH:mm")}`}</p>
                            {event.timerStop && <p className="planning-details-modal-element">{`Terminer le ${event.timerStop.format("DD MMM YYYY [à] HH:mm")}`}</p>}
                            {event.timerStop && <p className="planning-details-modal-element">{`Durée: ${this.getTimerDuration()}`}</p>}
                        </span>
                    }
                    <span>
                        <p className="planning-details-modal-title">{"Règle"}</p>
                        <span className="planning-details-modal-rrule">
                            <p className="planning-details-modal-rrule-title">{"Cet événement:"}</p>
                            <ul>
                                {event.isDraggable !== undefined ? <li className="planning-details-modal-rrule-element">{`est ${event.isDraggable ? 'déplacable' : 'immobile'}`}</li> : null}
                            </ul>
                        </span>
                    </span>
                    <div>
                        <p className="planning-details-modal-title">{"Confirmation"}</p>
                        <div className="planning-details-modal-element">
                            {
                                this.state.confirmed && this.state.confirmed.length > 0 &&
                                <div className="planning-details-modal-confirmed">
                                    <p>{"Utilisateurs confirmés:"}</p>
                                    <ul className="planning-details-modal-confirmed-list">
                                        {
                                            this.state.confirmed?.map(c => (
                                                <li key={`planning-details-confirmed-${c.user.id}`}>{`${c.user.last_name} ${c.user.first_name} à ${moment(c.date).format("HH:mm")}, le ${moment(c.date).format("DD-MM-YYYY")}`}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                this.state.notConfirmed && this.state.notConfirmed.length > 0 &&
                                <div className="planning-details-modal-not-confirmed">
                                    <p>{"Utilisateurs non confirmés:"}</p>
                                    <ul className="planning-details-modal-confirmed-list">
                                        {
                                            this.state.notConfirmed?.map(c => (
                                                <li key={`planning-details-not-confirmed-${c.user.id}`}>{`${c.user.last_name} ${c.user.first_name}`}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <span>
                        <p className="planning-details-modal-title">{"Options"}</p>
                        <div className="flex">
                            {
                                (checkRBACRule(Rules.Planning.Management, this.props.currentUser?.role) || checkPrivilege(Privileges.Planning.Edition, this.props.currentUser)) &&
                                <Fragment>
                                    <Button onClick={this.createTemplate} loading={this.state.createTemplateLoading}>
                                        {"Créer un modèle"}
                                    </Button>
                                    <Tooltip title={"Un modèle sera crée par rapport à la configuration actuelle de l'événement."} placement="top">
                                        <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: '10px' }} />
                                    </Tooltip>
                                </Fragment>
                            }
                        </div>
                    </span>


                </div>

            </Modal>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    company: state.user.company,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
});
const connector = connect(mapStateToProps)

export default connector(withRouter(EventDetailsModal));