import React, { CSSProperties } from 'react';
interface IProps {
    children: React.ReactNode;
    /** Direction du conteneur ('vertical' ou 'horizontal'). Par défaut, 'horizontal'. */
    direction?: 'vertical' | 'horizontal';
    align?: 'flex-start' | 'center' | 'flex-end';
    /** Espace entre les éléments enfants. Par défaut, 5px. */
    space?: number;
    className?: string;
    style?: CSSProperties;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type Props = IProps;

interface State { }

/**
 * Composant SpaceContent.
 * 
 * Ce composant crée un conteneur flexible pour les éléments enfants avec un espace spécifié entre eux.
 * 
 * @param {('vertical' | 'horizontal')} [direction='horizontal'] - La direction du conteneur ('vertical' ou 'horizontal').
 * @param {number} [space=5] - L'espace entre les éléments enfants, en pixels.
 * 
 * @returns {React.ReactElement} Un composant React représentant le conteneur flexible.
 */
class SpaceContent extends React.Component<Props, State> {
    render() {
        const { children, direction, space, className, style, align, onClick } = this.props;
        return (
            <div className={className} onClick={onClick} style={{ display: 'flex', flexDirection: direction == 'vertical' ? 'column' : 'row', justifyItems: 'center', justifyContent: align ?? undefined, alignItems: align ?? 'center', gap: space !== undefined ? `${space}px` : '5px', ...style }}>{children}</div>
        );
    }
}

export default SpaceContent;