import { Table, Transfer } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import difference from 'lodash/difference';
import React, { ReactNode } from 'react';
import { injectIntl } from 'react-intl';
import { IntlProps } from '../../app/LanguageProvider';

interface Props extends IntlProps {
    //for transfer
    dataSource: any[];
    targetKeys: any[];
    titles?: ReactNode[];
    onChange: (keys: any[]) => void;
    filterOption?: (search: string, item: any) => boolean;

    //for table
    scroll?: any;
    rowKey: (item: any) => string;
    columns: any;
    pagination?: false | TablePaginationConfig | undefined;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    hideSearch?: boolean
    locale?: {
        itemUnit: string,
        itemsUnit: string,
        notFoundContent: string,
        searchPlaceholder: string
    }
}
interface State {
    locale: {
        itemUnit: string,
        itemsUnit: string,
        notFoundContent: string,
        searchPlaceholder: string
    }
}
/**
 * Component that represent a table transfer
 */
class TableTransfer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            locale: this.props.locale ?? {
                itemUnit: this.props.intl.formatMessage({ defaultMessage: 'User' }),
                itemsUnit: this.props.intl.formatMessage({ defaultMessage: 'Users' }),
                notFoundContent: this.props.intl.formatMessage({ defaultMessage: 'No user' }),
                searchPlaceholder: this.props.intl.formatMessage({ defaultMessage: 'Search for a user' })
            }
        }
    }
    render() {
        const { dataSource, targetKeys, onChange, filterOption, rowKey, columns, pagination, scroll, disabled, titles, className, loading } = this.props;
        return (
            <Transfer
                titles={titles}
                disabled={disabled}
                className={`tabletransfer-parent ${className ? className : ""}`}
                dataSource={dataSource}
                targetKeys={targetKeys}
                onChange={onChange}
                filterOption={filterOption}
                rowKey={rowKey}
                showSelectAll={false}
                locale={this.state.locale}
                showSearch={this.props.hideSearch ? false : true}>
                {({
                    filteredItems,
                    onItemSelectAll,
                    onItemSelect,
                    selectedKeys: listSelectedKeys,
                    direction,
                    disabled: listDisabled,
                }) => {
                    const rowSelection = {
                        getCheckboxProps: () => ({ disabled: listDisabled }),
                        onSelectAll: (selected: any, selectedRows: any) => {
                            const treeSelectedKeys = selectedRows
                                .filter((item: any) => !item.disabled)
                                .map(({ key }: any) => key);
                            const diffKeys = selected
                                ? difference(treeSelectedKeys, listSelectedKeys)
                                : difference(listSelectedKeys, treeSelectedKeys);
                            onItemSelectAll(diffKeys, selected);
                        },
                        onSelect: ({ key }: any, selected: any) => onItemSelect(key, selected),
                        selectedRowKeys: listSelectedKeys,
                    };

                    // const columns = direction === 'left' ? leftColumns : rightColumns;

                    return (
                        <Table
                            loading={loading}
                            className={direction === "right" ? "transfer-table-right" : "transfer-table-left"}
                            style={{ pointerEvents: listDisabled ? 'none' : 'auto' }}
                            pagination={pagination}
                            columns={columns}
                            rowKey={rowKey}
                            rowSelection={rowSelection}
                            dataSource={filteredItems}
                            size="small"
                            scroll={scroll}
                            onRow={({ key, disabled: itemDisabled }) => ({
                                onClick: () => {
                                    if (itemDisabled || listDisabled) return;
                                    onItemSelect(key, !listSelectedKeys.includes(key))
                                },
                            })}
                        />
                    );
                }}
            </Transfer>
        );
    }
}

export default injectIntl(TableTransfer);