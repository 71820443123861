import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Select, Steps, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { ListingCategory, ListingDirectory, } from '../../../../utils/types/generalTypes';
import { ListingDirectoryBody } from '../../../../utils/types/networkTypes';
import { alert, displayErrorMessage } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import FileUploader from '../../../common/fields/fileUploader';
import ImageUploader from '../../../common/fields/imageUploader';
import InputField, { InputFieldOnChangeEvent } from '../../../common/fields/inputField';

//different data type of a listing directory body
enum DataType {
    Category, SubCategory, Name, Subtitle, Description, Email, Phone, Address, Link, Image, File, OpeningHours, StartTime, EndTime
}

interface Props extends IntlProps {
    visible: boolean;
    categories: ListingCategory[];
    editDirectory: ListingDirectory | undefined;
    initialCategoryId?: number;
    initialSubCategoryId?: number;
    onClose: () => void;
    onDone: () => void;
}

interface State {
    directory: ListingDirectoryBody;
    currentStep: number;
    loading: boolean;
}

/**
 * Page for the creation and edition of a directory
 */
class CreateEditDirectoryModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        // get initial category and sub category
        const sub_category_id = this.props.initialSubCategoryId;
        let category_id = undefined;

        if (sub_category_id !== undefined) {
            for (const c of this.props.categories!) {
                for (const s of c.subcategory) {
                    if (s.id === sub_category_id) {
                        category_id = c.id;
                        break;
                    }
                }
                if (category_id) break;
            }
        } else {
            // check that the category has subcategories
            const subcategories = this.props.categories.find(c => c.id === this.props.initialCategoryId)?.subcategory
            if (subcategories && subcategories.length > 0) {
                category_id = this.props.initialCategoryId;
            }
        }

        this.state = {
            directory: {
                category_id,
                sub_category_id,
                link: "http://",
            },
            currentStep: 0,
            loading: false,
        };
    }

    componentDidMount() {
        const { editDirectory } = this.props;
        //if it is editing, copy the list directory to state
        if (editDirectory) {
            const openingHours = editDirectory.list_data.find(d => d.type === "opening_hours")?.data?.split("-");
            const directory = {
                directory_id: editDirectory.id,
                category_id: this.props.categories.find(c => c.subcategory.find(s => s.id === editDirectory.subcategory))?.id,
                sub_category_id: editDirectory.subcategory,
                name: editDirectory.name,
                subtitle: editDirectory.subtitle,
                desc: editDirectory.desc,
                email: editDirectory.list_data.find(d => d.type === "email")?.data,
                phone: editDirectory.list_data.find(d => d.type === "phone")?.data,
                address: editDirectory.list_data.find(d => d.type === "address")?.data,
                link: editDirectory.list_data.find(d => d.type === "link")?.data,
                file: editDirectory.list_data.find(d => d.type === "file")?.data,
                image: editDirectory.list_data.find(d => d.type === "image")?.data,
                opening_hours: openingHours ? {
                    start_time: openingHours[0],
                    end_time: openingHours[1],
                } : undefined,
                extra_payload: editDirectory,
            };

            if (!directory.link) directory.link = "http://";

            this.setState({ directory });
        }
    }

    dataChange = (value: any, type: DataType) => {
        const { directory } = this.state;

        switch (type) {
            case DataType.Category:
                directory.category_id = value;
                break;
            case DataType.SubCategory:
                directory.sub_category_id = value;
                break;
            case DataType.Name:
                directory.name = value.target.value;
                break;
            case DataType.Subtitle:
                directory.subtitle = value.target.value;
                break;
            case DataType.Description:
                directory.desc = value.target.value;
                break;
            case DataType.Email:
                directory.email = value.target.value;
                break;
            case DataType.Phone:
                directory.phone = value.target.value;
                break;
            case DataType.Address:
                directory.address = value.target.value;
                break;
            case DataType.Link:
                directory.link = value.target.value;
                break;
            case DataType.Image:
                directory.image = value;
                break;
            case DataType.File:
                directory.file = value;
                break;
            case DataType.OpeningHours:
                directory.opening_hours = value.target.checked ? {} : undefined;
                break;
            case DataType.StartTime:
                directory.opening_hours!.start_time = value.format("HH:mm");
                break;
            case DataType.EndTime:
                directory.opening_hours!.end_time = value.format("HH:mm");
                break;
        }

        this.setState({ directory });
    }

    /**
     * Called when the user click on the OK button
     */
    onDone = () => {
        const directory = Object.assign({}, this.state.directory);
        const { intl } = this.props;

        //check the user inputs
        if (!directory.sub_category_id) displayErrorMessage(intl.formatMessage({ defaultMessage: 'Please select a subcategory' }), "listing-modal-ok-button");
        else if (!directory.name) displayErrorMessage(intl.formatMessage({ defaultMessage: 'Please give the element a name' }), "listing-modal-ok-button");
        else {
            //delete link, if not changed
            if (directory.link === "http://") directory.link = undefined;

            //add category and subcategory indexes
            const category = this.props.categories.find(c => c.id === directory.category_id);
            directory.cat_index = this.props.categories.findIndex(c => c.id === directory.category_id);
            directory.subcat_index = category?.subcategory.findIndex(s => s.id === directory.sub_category_id);
            directory.category_indexes = {
                ci: directory.cat_index,
                sci: directory.subcat_index,
            };

            // update the directory
            this.setState({ loading: true });
            Network.createListingDirectory(directory).then(
                () => {
                    // ATTENTION ADD
                    // Temporary remove for display pdf and image, upload not wait before refresh
                    // this.setState({ loading: false });
                    this.props.onDone();
                },
                () => {
                    this.setState({ loading: false });
                    alert(intl.formatMessage({ defaultMessage: 'An error occurred while creating the element.' }), "warning");
                }
            );
        }
    }

    /**
     * Render the content of the modal
     * @returns the content to render
     */
    renderContent = (): React.ReactNode => {
        const { directory } = this.state;
        const { categories, intl } = this.props;

        //create options for category select
        const categoryOptions = categories.map(c => <Select.Option title={c.subcategory.length === 0 ? <FormattedMessage defaultMessage={'Has no subcategory'} /> : c.name} disabled={c.subcategory.length === 0} value={c.id} key={`listing-modal-categories-${c.id}`}>{c.name}</Select.Option>)
        // categoryOptions.unshift(<Select.Option value={-1} disabled key="listing-modal-categories-disabled">{"Choisir une catégorie"}</Select.Option>);

        //create options for sub category select
        const subCategoryOptions = categories.find(c => c.id === directory.category_id)?.subcategory.map(s => <Select.Option key={`listing-modal-subcategories-${s.id}`} value={s.id}>{s.name}</Select.Option>)
        // if (subCategoryOptions === undefined) {
        //     subCategoryOptions = [<Select.Option value={-1} disabled key="listing-modal-subcategories-disabled">{"Choisir une sous-catégorie"}</Select.Option>]
        // } else {
        //     subCategoryOptions.unshift(<Select.Option value={-1} disabled key="listing-modal-subcategories-disabled">{"Choisir une sous-catégorie"}</Select.Option>);
        // }

        switch (this.state.currentStep) {
            case 0:
                return (
                    <div>
                        <div className="flex-sp">
                            <p style={{ width: '151px' }}><FormattedMessage defaultMessage={'Category'} /></p>
                            <Select
                                placeholder={<FormattedMessage defaultMessage={'Select a category'} />}
                                className="listing-modal-input"
                                value={directory.category_id ? directory.category_id : undefined}
                                style={{ marginRight: '10px' }}
                                onChange={e => this.dataChange(e, DataType.Category)}>
                                {categoryOptions}
                            </Select>
                            <Select
                                placeholder={<FormattedMessage defaultMessage={'Select a subcategory'} />}
                                disabled={directory.category_id === -1 || directory.category_id === undefined}
                                className="listing-modal-input"
                                value={directory.sub_category_id ? directory.sub_category_id : undefined}
                                onChange={e => this.dataChange(e, DataType.SubCategory)}>
                                {subCategoryOptions}
                            </Select>
                        </div>
                        <div>
                            <span className="listing-modal-section">
                                <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Name'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    value={directory.name}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Name' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Name)} />
                            </span>
                            <span className="listing-modal-section">
                                <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Subtitle'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    value={directory.subtitle}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Subtitle' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Subtitle)} />
                            </span>
                            <span className="listing-modal-section">
                                <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Description'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    type="textarea"
                                    value={directory.desc}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Description' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Description)} />
                            </span>
                            <span className="listing-modal-section">
                                <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Email'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    value={directory.email}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Email' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Email)} />
                            </span>
                            <span className="listing-modal-section">
                                <p><FormattedMessage defaultMessage={'Phone'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    value={directory.phone}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Phone' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Phone)} />
                            </span>
                            <span className="listing-modal-section">
                                <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Address'} /></p>
                                <InputField
                                    className="listing-modal-input"
                                    value={directory.address}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Address' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Address)} />
                            </span>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <span className="listing-modal-section">
                            <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Address'} /></p>
                            <InputField
                                className="listing-modal-input"
                                value={directory.link}
                                placeholder={intl.formatMessage({ defaultMessage: 'Link' })}
                                onChange={(e: InputFieldOnChangeEvent) => this.dataChange(e, DataType.Link)} />
                        </span>
                        <span className="listing-modal-section">
                            <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Add an image'} /></p>
                            <ImageUploader
                                className="listing-modal-input"
                                image={directory.image}
                                onUploaded={(i: any) => this.dataChange(i, DataType.Image)} />
                        </span>
                        <span className="listing-modal-section">
                            <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Add a file'} /></p>
                            <FileUploader
                                className="listing-modal-input"
                                file={directory.file}
                                onUploaded={(f: any) => this.dataChange(f, DataType.File)} />
                        </span>
                        <div>
                            <Checkbox
                                checked={Boolean(directory.opening_hours)}
                                onChange={e => this.dataChange(e, DataType.OpeningHours)}>
                                <FormattedMessage defaultMessage={'Opening hours'} />
                            </Checkbox>
                            {
                                directory.opening_hours &&
                                <div className="expand-appear-animation">
                                    <span className="listing-modal-section">
                                        <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Opening hours'} /></p>
                                        <TimePicker
                                            className="listing-modal-input"
                                            value={directory.opening_hours?.start_time ? moment(directory.opening_hours.start_time, "HH:mm") : undefined}
                                            placeholder={intl.formatMessage({ defaultMessage: 'Opening hours' })}
                                            format={getFormat('TIME_SHORT')}
                                            onChange={d => this.dataChange(d, DataType.StartTime)} />
                                    </span>
                                    <span className="listing-modal-section">
                                        <p className="listing-modal-section-title"><FormattedMessage defaultMessage={'Closing hours'} /></p>
                                        <TimePicker
                                            className="listing-modal-input"
                                            value={directory.opening_hours?.end_time ? moment(directory.opening_hours.end_time, "HH:mm") : undefined}
                                            placeholder={intl.formatMessage({ defaultMessage: 'Closing hours' })}
                                            format={getFormat('TIME_SHORT')}
                                            onChange={d => this.dataChange(d, DataType.EndTime)} />
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                );
        }
    }

    render() {
        const { editDirectory } = this.props;
        return (
            <Modal
                visible={this.props.visible}
                className="listing-modal"
                title={editDirectory ? editDirectory.name : <FormattedMessage defaultMessage={'Add a directory'} />}
                onCancel={this.props.onClose}
                footer={[
                    <Button type="dashed" onClick={this.props.onClose} key="listing-modal-button-cancel">{"Annuler"}</Button>,
                    <div key="listing-modal-button-next-previous">
                        <Button disabled={this.state.currentStep === 0} onClick={() => this.setState({ currentStep: this.state.currentStep - 1 })}>
                            <LeftOutlined />
                            <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Previous'} /></span>
                        </Button>
                        <Button disabled={this.state.currentStep === 1} onClick={() => this.setState({ currentStep: this.state.currentStep + 1 })}>
                            <span className="modal-button-next-previous-title"><FormattedMessage defaultMessage={'Next'} /></span>
                            <RightOutlined />
                        </Button>
                    </div>,
                    <Button
                        id="listing-modal-ok-button"
                        key="listing-modal-button-save"
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.onDone}>{editDirectory ? <FormattedMessage defaultMessage={'Save'} /> : <FormattedMessage defaultMessage={'Create'} />}</Button>
                ]}>
                <Steps
                    type="navigation"
                    size="small"
                    current={this.state.currentStep}
                    onChange={(current) => this.setState({ currentStep: current })}
                    style={{ marginBottom: '20px', boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}>
                    <Steps.Step title={<FormattedMessage defaultMessage={'General'} />} />
                    <Steps.Step title={<FormattedMessage defaultMessage={'Annexes'} />} />
                </Steps>
                {this.renderContent()}
            </Modal>
        );
    }
}

export default injectIntl(CreateEditDirectoryModal);