
import { faBoxOpen, faCalendar, faCalendarWeek, faChartMixed, faGaugeMax, faGear, faLocationDot, faMessages, faMobile, faNewspaper, faPalletBoxes, faStopwatch, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { IconPacks } from './IconPacks';
import FA from './fontawesome';

/**
 * Pack used for the sunkhronos library
 */
export const navbarPack = FA.createIconPack(
    IconPacks.Navbar,
    faGaugeMax,
    faNewspaper,
    faUsers,
    faGear,
    faLocationDot,
    faMessages,
    faMobile,
    faCalendar,
    faCalendarWeek,
    faStopwatch,
    faBoxOpen,
    faPalletBoxes,
    faChartMixed
);