import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, List, Row, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeTypesOfVehicles } from '../../../store/actions/configurations';
import { VehicleTypesDescription } from '../../../utils/constants';
import Network from '../../../utils/network';
import { TypeOfVehicle, TypeOfVehicleWithUpdate } from '../../../utils/types/generalTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

interface iProps {
    vehicles: TypeOfVehicleWithUpdate;
    vehicle: TypeOfVehicle;
    stopEdition: () => void;
}

type Props = iProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    vehicle: TypeOfVehicle | undefined;
}

/**
 * Component that represent a list item for the types Of Contract list
 */
class VehicleItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            vehicle: undefined,
        };
    }

    componentDidMount() {
        // if it is a new type of day, set state's type Of Contract
        if (this.props.vehicle.id === undefined) {
            this.setState({ vehicle: { ...this.props.vehicle } });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // when add or edit a type Of Contract, focus the input field-
        if (this.state.vehicle !== undefined && prevState.vehicle === undefined) {
            const input = document.getElementById(`tod-edit-input-${this.props.vehicle.id ? this.props.vehicle.id : '0'}`);
            if (input) input.focus();
        }
    }

    /**
     * Change a vehicle's name
     * @param event the triggered event
     */
    onChangeName = (event: InputFieldOnChangeEvent) => {
        const { vehicle } = this.state;
        if (vehicle) vehicle.name = event.target.value;
        this.setState({ vehicle });
    }

    /**
    * Change a vehicle's name
    * @param event the triggered event
    */
    onChangeType = (value: number) => {
        const { vehicle } = this.state;
        if (vehicle) vehicle.type = value;
        this.setState({ vehicle });
    }

    /**
    * Change a vehicle's name
    * @param event the triggered event
    */
    onChangeDescription = (event: InputFieldOnChangeEvent) => {
        const { vehicle } = this.state;
        if (vehicle) vehicle.description = event.target.value;
        this.setState({ vehicle });
    }

    /**
     * Create or edit the type Of Contract
     */
    onUpdateVehicle = (): void => {
        const { vehicle } = this.state;
        const { intl } = this.props;

        if (vehicle?.name === undefined || vehicle?.name.length < 3 || vehicle?.type === undefined) {
            showNotification(intl.formatMessage({ defaultMessage: 'Data missing in one or more fields' }), "warning");
            return;
        }

        if (vehicle && vehicle.name) {
            Network.updateTypeOfVehicles({
                id: vehicle.id,
                name: vehicle.name,
                type: vehicle.type,
                description: vehicle.description
            }).then(
                (response) => {
                    const { changeTypesOfVehicles } = this.props;
                    let typesOfVehiclesData = cloneDeep(this.props.vehicles.data);

                    if (typesOfVehiclesData) {
                        if (vehicle.id === undefined) {
                            typesOfVehiclesData.push(response);
                        } else {
                            typesOfVehiclesData = typesOfVehiclesData.map(tov => {
                                if (tov.id === response.id) {
                                    return response;
                                } else {
                                    return tov;
                                }
                            })
                        }
                    } else {
                        typesOfVehiclesData = [response];
                    }
                    changeTypesOfVehicles!(typesOfVehiclesData);
                    showNotification(intl.formatMessage({ defaultMessage: 'The types of vehicle have been successfully updated' }), "success");
                    this.props.stopEdition();
                    this.setState({ vehicle: undefined });
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the type of vehicle' }), "warning"),
            )
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'Type of vehicle missing' }), "error")
        }
    }

    /**
     * Delete a type Of Vehicle
     */
    onDeleteVehicle = () => {
        Network.deleteTypeOfVehicle(this.props.vehicle.id!).then(
            () => {
                const { changeTypesOfVehicles } = this.props;
                const vehicles = cloneDeep(this.props.vehicles);

                vehicles.data = vehicles.data?.filter(veh => veh.id !== this.props.vehicle.id);
                if (vehicles.data !== undefined) {
                    changeTypesOfVehicles!(vehicles.data);
                }
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'The types of vehicle have been successfully deleted' }), "success");
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while deleting the vehicle type' }), "warning"),
        );
    }

    render() {
        const { vehicle } = this.state;
        const { intl } = this.props;

        const vehicleTypeName = VehicleTypesDescription.find((el) => el.id === this.props.vehicle.type)?.name

        return (
            <List.Item
                title={intl.formatMessage({ defaultMessage: 'Type of day disabled' })} //style={archived ? { backgroundColor: '#e0e0e0', paddingLeft: '5px', color: '#9e9e9e' } : {paddingLeft: '5px'}}
                actions={
                    vehicle ?
                        [
                            <CircleButton
                                key={`vehicleItem-type-of-day-disable-${vehicle.id}-save`}
                                icon={<SaveOutlined />}
                                title={intl.formatMessage({ defaultMessage: 'Save' })}
                                placement="left"
                                disabled={this.state.vehicle?.name === '' ? true : false}
                                onClick={this.onUpdateVehicle} />,
                            <CircleButton
                                key={`vehicleItem-type-of-day-disable-${vehicle.id}-cancel`}
                                icon={<CloseOutlined />}
                                title={intl.formatMessage({ defaultMessage: 'Cancel' })}
                                placement="left"
                                onClick={vehicle.id ? () => this.setState({ vehicle: undefined }) : () => this.props.stopEdition()} />

                        ]
                        :
                        [
                            <CircleButton
                                icon={<EditOutlined />}
                                key={`vehicleItem-type-of-day-disable-modify`}
                                title={intl.formatMessage({ defaultMessage: 'Edit' })}
                                placement="left"
                                onClick={() => this.setState({ vehicle: { ...this.props.vehicle } })} />,
                            <DeleteButton
                                text={<><p><FormattedMessage defaultMessage={'Do you want to delete this vehicle?'} /></p><p><FormattedMessage defaultMessage={'The related events may not display correctly in the reports'} /></p></>}
                                key={`vehicleItem-type-of-day-disable-delete`}
                                onConfirm={this.onDeleteVehicle}
                                placement="topRight"
                                buttonPlacement="right" />
                        ]
                }>
                {
                    vehicle ?
                        <div style={{ width: "100%" }} className="vehicle-content">
                            <Row gutter={[10, 10]}>
                                <Col xs={{ span: 6 }}>
                                    <InputField
                                        style={{ minWidth: '150px', maxWidth: '200px' }}
                                        id={`tod-edit-input-${this.props.vehicle.id ? this.props.vehicle.id : '0'}`}
                                        className="configurations-section-field asterisk_input"
                                        value={vehicle!.name}
                                        placeholder={`${intl.formatMessage({ defaultMessage: 'Name' })}*`}
                                        title={intl.formatMessage({ defaultMessage: 'Name' })}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeName(e)} />
                                </Col>
                                <Col xs={{ span: 9 }}>
                                    <InputField
                                        style={{ minWidth: '150px', maxWidth: '200px' }}
                                        id={`tod-edit-input-${this.props.vehicle.id ? this.props.vehicle.id : '0'}`}
                                        className="configurations-section-field asterisk_input"
                                        value={vehicle!.description}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Description' })}
                                        title={intl.formatMessage({ defaultMessage: 'Description' })}
                                        onChange={(e: InputFieldOnChangeEvent) => this.onChangeDescription(e)} />
                                </Col>
                                <Col xs={{ span: 9 }}>
                                    <Select

                                        className="configurations-section-field-select"
                                        placeholder={`${intl.formatMessage({ defaultMessage: 'Type of vehicle' })} *`}
                                        onChange={(value) => this.onChangeType(value)}
                                        value={vehicle.type}
                                        filterOption={true}
                                        style={{ minWidth: '150px', maxWidth: '200px' }}
                                        optionFilterProp="label">
                                        {VehicleTypesDescription.map(t => <Select.Option label={intl.formatMessage(t.name)} key={`type-of-vehicle-select-value-${t.id}`} value={t.id}>{intl.formatMessage(t.name)}</Select.Option>)}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div style={{ width: "100%" }} className="vehicle-content">
                            <Row gutter={[10, 10]}>
                                <Col xs={{ span: 6 }}>
                                    <p>{this.props.vehicle.name}</p>
                                </Col>
                                <Col xs={{ span: 9 }}>
                                    <p>{this.props.vehicle.description ? this.props.vehicle.description : '-'}</p>
                                </Col>
                                <Col xs={{ span: 9 }}>
                                    <p>{vehicleTypeName ? intl.formatMessage(vehicleTypeName) : ''}</p>
                                </Col>
                            </Row>
                        </div>
                }
            </List.Item >
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeTypesOfVehicles: (c: TypeOfVehicle[]) => dispatch(changeTypesOfVehicles(c)),
});

const mapStateToProps = (state: ApplicationState) => ({
    vehicles: state.configurations.vehicles,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VehicleItem));