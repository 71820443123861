import { NotificationOutlined, ShopOutlined, TagsOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Privileges } from '../../privileges';
import { changeCompanyDetails, changeCountries, changeLanguages, changeMedals, reset } from '../../store/actions/crm';
import Network from '../../utils/network';
import { Company, Country, FullSMSAccount, Medal, RouterProps, User } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { alert } from '../../utils/utils';
import Can from '../common/general/can';
import Container from '../common/navigations/container';
import ClientsTab from './clientsTab';
import MessagesTab from './messagesTab';
import PromotionsTab from './promotions/promotionsTab';
import SignatureTab from './signatureTab';
// import ClickParam from 'antd/lib/menu';
import isEqual from 'react-fast-compare';
import { FiEdit } from 'react-icons/fi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAllLevels, fetchAllProducts } from '../../actions/products';
import { IntlProps } from '../app/LanguageProvider';
import CodeDiscountTab from './codeDiscount/codeDiscountTab';
import ProductsTab from './productsTab';

//Keys for the different tabs
enum TabKeys {
    Products = "pr",
    Clients = "c",
    Promotions = "p",
    PromotionsGeneral = "pg",
    PromotionsBirthday = "pb",
    PromotionsLibrairy = "pl",
    Signature = "s",
    Voucher = "v",
    Messages = "m"
}

//Links for the different tabs
enum TabLink {
    Products = "products",
    Clients = "clients",
    Promotions = "promotions",
    PromotionsGeneral = "general",
    PromotionsBirthday = "birthday",
    PromotionsLibrairy = "librairy",
    Signature = "signature",
    Voucher = "voucher",
    Messages = "messages"
}

type ReduxProps = ConnectedProps<typeof connector>


interface IProps {
    user: User | undefined;
    companyDetails: Company | undefined;
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & ReduxProps & IntlProps;

interface State {
    sms?: FullSMSAccount;
    smsLoading: boolean
}

/**
 * Component for the CRM management page
 */
class CRM extends React.Component<Props, State> {

    componentDidMount() {
        const { intl } = this.props;
        // get company details

        Network.getCompanyDetails().then(
            response => !isEqual(this.props.companyDetails, response) && this.props.changeCompanyDetails!(response),
            () => alert(intl.formatMessage({ defaultMessage: "An error occurred while loading the school's information" }), "warning"),
        );
        if (this.props.companyDetails !== undefined) {
            this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
            this.props.dispatch(fetchAllLevels({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
        }
        // Network.getCompanyDetails().then(
        //     response => this.props.changeCompanyDetails!(response),
        //     () => alert("Un problème est survenu pendant le chargement des informations de l'école.", "warning"),
        // );

        // get all school's medals
        Network.getBCMedals().then(
            response => this.props.changeMedals!(response),
            () => alert(intl.formatMessage({ defaultMessage: 'An error occurred while loading the medals' }), "warning"),
        );

        // get all languages
        Network.getBCCountries().then(
            response => this.props.changeCountries!(response),
            () => alert(intl.formatMessage({ defaultMessage: 'An error occurred while loading the countries.' }), "warning"),
        );

        // get all languages
        Network.getBCLanguages().then(
            response => this.props.changeLanguages!(response),
            () => alert(intl.formatMessage({ defaultMessage: 'An error occurred while loading the languages' }), "warning"),
        );
    }

    componentWillUnmount() {
        // this.props.reset!();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.companyDetails && !prevProps.companyDetails) {
            this.props.dispatch(fetchAllProducts({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
            this.props.dispatch(fetchAllLevels({ school__external_id: this.props.companyDetails.int_id, school__version: this.props.companyDetails.version }, "fr"));
        }
    }

    /**
     * Get the current tab
     */
    getCurrentTab = (): [string, any] => {
        switch (this.props.match.params.tab) {
            // case TabLink.Promotions:
            //     switch (this.props.match.params.subtab) {
            //         case TabLink.PromotionsBirthday:
            //             return [TabKeys.PromotionsBirthday, BirthdayPromotionsTab];
            //         case TabLink.PromotionsLibrairy:
            //             return [TabKeys.PromotionsLibrairy, LibrairyPromotionsTab];
            //         default:
            //             return [TabKeys.PromotionsGeneral, PromotionsTab];
            //     }
            case TabLink.Promotions:
                return [TabKeys.Promotions, PromotionsTab];
            case TabLink.Voucher:
                return [TabKeys.Voucher, CodeDiscountTab];
            case TabLink.Signature:
                return [TabKeys.Signature, SignatureTab];
            case TabLink.Products:
                return [TabKeys.Products, ProductsTab];
            case TabLink.Messages:
                return [TabKeys.Messages, MessagesTab]
            default:
                return [TabKeys.Clients, ClientsTab];
        }
    }

    // ATTENTION , after update ClickParam to any (correction need)
    /**
     * Called when a tab is changed
     */
    onChangeTab = (param: any) => {
        let link = "";

        switch (param.keyPath.length > 1 ? param.keyPath[1] : param.key) {
            // case TabKeys.Promotions:
            //     link = TabLink.Promotions;
            //     switch (param.key) {
            //         case TabKeys.PromotionsBirthday:
            //             link += `/${TabLink.PromotionsBirthday}`
            //             break;
            //         case TabKeys.PromotionsLibrairy:
            //             link += `/${TabLink.PromotionsLibrairy}`
            //             break;
            //         case TabKeys.PromotionsGeneral:
            //             link += `/${TabLink.PromotionsGeneral}`
            //             break;
            //     }
            //     break;
            case TabKeys.Promotions:
                link = TabLink.Promotions;
                break;
            case TabKeys.Voucher:
                link = TabLink.Voucher;
                break;
            case TabKeys.Signature:
                link = TabLink.Signature;
                break;
            case TabKeys.Products:
                link = TabLink.Products;
                break;
            case TabKeys.Messages:
                link = TabLink.Messages;
                break;
            default:
                link = TabLink.Clients;
                break;
        }
        this.props.history.push(`/${this.props.match.params.lang}/customer-relationship-management/${link}`);
    }

    render() {
        const { intl } = this.props;
        const [currentTab, Content] = this.getCurrentTab();

        return (
            <Can privilege={Privileges.CRM.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Customer relationship management {school}' }, { school: this.props.companyDetails ? ` - ${this.props.companyDetails.name}` : '' }), link: "/customer-relationship-management/products" }]}>
                    <Menu mode="horizontal" selectedKeys={[currentTab]} onClick={this.onChangeTab}>
                        <Menu.Item key={TabKeys.Products}>
                            <FiEdit className="anticon" /><span><FormattedMessage defaultMessage={'Products'} /></span>
                        </Menu.Item>
                        <Menu.Item key={TabKeys.Clients}>
                            <ShopOutlined /><span><FormattedMessage defaultMessage={'Online customers'} /></span>
                        </Menu.Item>
                        <Menu.Item key={TabKeys.Messages}>
                            <ShopOutlined /><span><FormattedMessage defaultMessage={'External communications'} /></span>
                        </Menu.Item>
                        <Menu.Item key={TabKeys.Voucher}>
                            <TagsOutlined /><span><FormattedMessage defaultMessage={'Discount vouchers'} /></span>
                        </Menu.Item>
                        <Menu.Item key={TabKeys.Promotions}>
                            <NotificationOutlined /><span><FormattedMessage defaultMessage={'Promotions'} /></span>
                        </Menu.Item>
                        {/* <Menu.SubMenu icon={<NotificationOutlined />} title={"Promotions"} key={TabKeys.Promotions}>
                            <Menu.Item key={TabKeys.PromotionsGeneral}>
                                <NotificationOutlined /><span>{"Général"}</span>
                            </Menu.Item>
                            <Menu.Item key={TabKeys.PromotionsBirthday}>
                                <GiftOutlined /><span>{"Anniversaire"}</span>
                            </Menu.Item>
                            <Menu.Item key={TabKeys.PromotionsLibrairy}>
                                <CameraOutlined /><span>{"Librairie photos"}</span>
                            </Menu.Item>
                        </Menu.SubMenu> */}
                        {/* <Menu.Item key={TabKeys.Signature}>
                            <span className="crm-tab-signature">
                                <FaSignature className="anticon" /><span>{"Signature"}</span>
                            </span>
                        </Menu.Item> */}
                    </Menu>
                    <br />
                    <Content />
                </Container>
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    companyDetails: state.crm.companyDetails,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeCompanyDetails: (c: Company) => dispatch(changeCompanyDetails(c)),
    changeMedals: (m: Medal[]) => dispatch(changeMedals(m)),
    changeCountries: (c: Country[]) => dispatch(changeCountries(c)),
    changeLanguages: (c: Country[]) => dispatch(changeLanguages(c)),
    reset: () => dispatch(reset()),
    dispatch
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(CRM));