import React from 'react';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { RouterProps, User } from '../../../utils/types/generalTypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkRBACRule, checkPrivilege } from '../../../utils/utils';

interface IProps {
    children: React.ReactNode;
    user: User | undefined;
    rule?: string;
    privilege?: string;
    privilegeCondition?: boolean; //an additionnal AND condition for the privilege
    condition?: boolean; //an additionnal AND general condition
    redirect?: string;
    otherwise?: React.ReactNode;
}

type Props = IProps & RouterProps;

interface State {

}

/***
 * Call than verify the Role Based Access Control rules
 */
class Can extends React.Component<Props, State> {

    /**
     * Check the rules
     * @returns true if the rule is checked
     */
    checkRules = (): boolean => {
        return checkRBACRule(this.props.rule!, this.props.user?.role, this.props.user?.company_id, this.props.user?.groupsAdmin?.length);
    }

    /**
     * Check the privileges
     * @returns true if the privilege is checked
     */
    checkPrivileges = (): boolean => {
        let privilegeChecked = checkPrivilege(this.props.privilege!, this.props.user);
        if (this.props.privilegeCondition !== undefined) privilegeChecked = privilegeChecked && this.props.privilegeCondition
        return privilegeChecked;
    }

    render() {
        let checked = true;
        if (this.props.rule !== undefined) checked = checked && this.checkRules();
        if (this.props.privilege !== undefined) checked = checked && this.checkPrivileges();
        if (this.props.condition !== undefined) checked = checked && this.props.condition;

        // redirect if needed
        if (!checked && this.props.redirect) {
            this.props.history.replace(`/${this.props.match.params.lang}${this.props.redirect}`);
            return null;
        } else {
            return checked ? this.props.children : (this.props.otherwise  ? this.props.otherwise : null);
        }
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(Can));