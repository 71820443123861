import { CRMState, StoreAction } from '../../utils/types/storeTypes';

export const CRM_INIT_STATE: CRMState = {
    companyDetails: undefined,
    companyLevels: [],
    loadingCompanyLevels: false,
    companyProducts: [],
    loadingCompanyProducts: false,
    companyCodeDiscounts: undefined,
    loadingCompanyCodeDiscounts: false,
    companyEditProduct: undefined,
    signature: '',
    medals: [],
    countries: [],
    languages: undefined
};

export const CHANGE_COMPANY_DETAILS = ('CHANGE_COMPANY_DETAILS');
export const CHANGE_COMPANY_PRODUCTS = ('CHANGE_COMPANY_PRODUCTS');
export const LOADING_COMPANY_PRODUCTS = ('LOADING_COMPANY_PRODUCTS');
export const FAILURE_COMPANY_PRODUCTS = ('FAILURE_COMPANY_PRODUCTS');
export const CHANGE_COMPANY_LEVELS = ('CHANGE_COMPANY_LEVELS');
export const LOADING_COMPANY_LEVELS = ('LOADING_COMPANY_LEVELS');
export const FAILURE_COMPANY_LEVELS = ('FAILURE_COMPANY_LEVELS');
export const CHANGE_COMPANY_CODES_DISCOUNT = ('CHANGE_COMPANY_CODES_DISCOUNT');
export const LOADING_COMPANY_CODES_DISCOUNT = ('LOADING_COMPANY_CODES_DISCOUNT');
export const FAILURE_COMPANY_CODES_DISCOUNT = ('FAILURE_COMPANY_CODES_DISCOUNT');
export const CHANGE_COMPANY_EDIT_PRODUCT = ('CHANGE_COMPANY_EDIT_PRODUCT');
export const CHANGE_SIGNATURE = ('CHANGE_SIGNATURE');
export const CHANGE_MEDALS = ('CHANGE_MEDALS');
export const CHANGE_COUNTRIES = ('CHANGE_COUNTRIES');
export const CHANGE_LANGUAGES = ('CHANGE_LANGUAGES');
export const RESET = ('RESET_CRM')

const swisskischool = (state: CRMState = CRM_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_COMPANY_DETAILS:
            return {
                ...state,
                companyDetails: action.data,
            };
        case CHANGE_COMPANY_PRODUCTS:
            return {
                ...state,
                companyProducts: action.data,
            };
        case LOADING_COMPANY_PRODUCTS:
            return {
                ...state,
                loadingCompanyProducts: action.data,
            };
        case CHANGE_COMPANY_LEVELS:
            return {
                ...state,
                companyLevels: action.data,
            };
        case LOADING_COMPANY_LEVELS:
            return {
                ...state,
                loadingCompanyLevels: action.data,
            };
        case CHANGE_COMPANY_CODES_DISCOUNT:
            return {
                ...state,
                companyCodeDiscounts: action.data,
            };
        case LOADING_COMPANY_CODES_DISCOUNT:
            return {
                ...state,
                loadingCompanyCodeDiscounts: action.data,
            };
        case CHANGE_COMPANY_EDIT_PRODUCT:
            return {
                ...state,
                companyEditProduct: action.data,
            };
        case CHANGE_SIGNATURE:
            return {
                ...state,
                signature: action.data,
            };
        case CHANGE_MEDALS:
            return {
                ...state,
                medals: action.data,
            };
        case CHANGE_COUNTRIES:
            return {
                ...state,
                countries: action.data,
            };
        case CHANGE_LANGUAGES:
            return {
                ...state,
                languages: action.data,
            };
        case RESET:
            return CRM_INIT_STATE;
        default:
            return state;
    }
};

export default swisskischool;