import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Col, DatePicker, InputNumber, Modal, Row, Select, Tag, TimePicker, Tooltip } from 'antd';
import range from 'lodash/range';
import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RRule } from 'rrule';
import { loadDepartments } from '../../../store/actions/configurations';
import { loadPois } from '../../../store/actions/location';
import { MOMENT_TIME_FORMAT } from '../../../utils/constants';
import { EventWeekdays, OccupancyRateFrequency } from '../../../utils/enumerations';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { DepartmentWithUpdate, PoiWithUpdate, StaffType } from '../../../utils/types/generalTypes';
import { OccupancyRateBodyRequest } from '../../../utils/types/networkTypes';
import { PlanningOccupancyRate, PlanningSettings, TypeOfDay } from '../../../utils/types/planningTypes';
import { ApplicationState, ConfigurationsDispatchProps, LocationDispatchProps, PlanningDispatchProps, StoreDispatch } from '../../../utils/types/storeTypes';
import { cloneOccupancyRate, displayErrorMessage, getOccupancyRateFrequencyText, getOccupancyRateSummaryText, getWeekdayText, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';

//different data type of a listing directory body
enum DataType {
    Title, TypesOfDay, StaffType, Poi, RangeDates, RangeHours, Quantity, Frequency, Weekdays, DuringHolidays, DuringVacations, Departments
}

interface IProps {
    occupancyRates: PlanningOccupancyRate[];
    typesOfDay: TypeOfDay[];
    pois?: PoiWithUpdate;
    occupancyRate: PlanningOccupancyRate;
    settings: PlanningSettings;
    loading: boolean;
    onCancel: () => void;
    onDone: (occupancyRate: OccupancyRateBodyRequest) => void;

    departments: DepartmentWithUpdate;
}

type Props = IProps & PlanningDispatchProps & LocationDispatchProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    currentStep: number;
    occupancyRate: PlanningOccupancyRate;
    staffTypes?: StaffType[];
}

/**
 * Modal to create and edit occupancy rates
 */
class CreateEditModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            occupancyRate: cloneOccupancyRate(this.props.occupancyRate),
            currentStep: 0,
        };
    }

    componentDidMount() {
        this.props.loadPois!();
        Network.getStaffType().then(
            response => {
                this.setState({ staffTypes: response })
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the skills' }), "error")
        );

        this.props.loadDepartments!();
    }

    /**
     * Convert to input occupancy rate into a occupancy rate body request
     * @returns the converted occupancy rate body request
     */
    convertOccupancyRateToBodyRequest = (): OccupancyRateBodyRequest => {
        const { occupancyRate } = this.state;

        // delete all undefined attribute
        if (occupancyRate.rrule) {
            occupancyRate.rrule["freq"] = RRule.YEARLY; //freq is mandatory, otherwise rrule convertion in backend fail
            Object.keys(occupancyRate.rrule!).forEach((k: string) => {
                if (occupancyRate.rrule![k] === undefined) delete occupancyRate.rrule![k];
            });
        }

        return {
            id: occupancyRate.id,
            typeOfDay: occupancyRate.typeOfDay?.id,
            staffType: occupancyRate.staffType?.id,
            poi: occupancyRate.poi?.id,
            department: occupancyRate.department?.id,
            title: occupancyRate.title,
            quantity: occupancyRate.quantity,
            frequency: occupancyRate.frequency,
            rrule: occupancyRate.rrule && Object.values(occupancyRate.rrule).filter(v => v).length > 0 ? new RRule(occupancyRate.rrule).toString() : undefined,
            startDate: occupancyRate.startDate ? occupancyRate.startDate.format("YYYY-MM-DD") : "0001-01-01",
            endDate: occupancyRate.endDate ? occupancyRate.endDate.format("YYYY-MM-DD") : "0001-01-01",
            startHour: occupancyRate.startHour ? occupancyRate.startHour.format(MOMENT_TIME_FORMAT) : undefined,
            endHour: occupancyRate.endHour ? occupancyRate.endHour.format(MOMENT_TIME_FORMAT) : undefined,
            duringHolidays: occupancyRate.duringHolidays,
            duringVacations: occupancyRate.duringVacations,
        }
    }

    /**
     * Timepicker disabled hours
     */
    disabledHours = (): number[] => {
        if (this.props.settings.startHourOfDay && this.props.settings.endHourOfDay) {
            return range(0, (this.props.settings?.startHourOfDay as Moment).hours()).concat(range((this.props.settings?.endHourOfDay as Moment).hours() + 1, 24));
        } else {
            return [];
        }
    }

    /**
     * Timepicker disabled minutes
     * @param hours the current selected hours
     */
    disabledMinutes = (hours: number): number[] => {
        if (this.props.settings.endHourOfDay && hours === this.props.settings.endHourOfDay.hours()) {
            return range(this.props.settings.endHourOfDay.minutes() + 1, 59);
        } else {
            return [];
        }
    }

    hasNotAtleastOneCondition = () => {
        const { occupancyRate } = this.state;
        return (!occupancyRate.typeOfDay || (occupancyRate.typeOfDay && Object.keys(occupancyRate.typeOfDay).length === 0 && occupancyRate.typeOfDay.constructor === Object)) &&
            (!occupancyRate.staffType || (occupancyRate.staffType && Object.keys(occupancyRate.staffType).length === 0 && occupancyRate.staffType.constructor === Object)) &&
            (!occupancyRate.poi || (occupancyRate.poi && Object.keys(occupancyRate.poi).length === 0 && occupancyRate.poi.constructor === Object));
    }

    /**
     * Called when the user is done editing/ creating
     */
    onDone = () => {
        const { occupancyRate } = this.state;
        if (!occupancyRate.title) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please provide a title' }), "occupancy-rate-modal-ok-button");
        else if (this.hasNotAtleastOneCondition()) displayErrorMessage(this.props.intl.formatMessage({ defaultMessage: 'Please select at least one condition' }), "occupancy-rate-modal-ok-button");
        // else if (!occupancyRate.typeOfDay) displayErrorMessage("Veuillez choisir un type de jour.", "occupancy-rate-modal-ok-button");
        else this.props.onDone(this.convertOccupancyRateToBodyRequest());
    }

    /**
     * Called when the user changed a value
     * @param type the type of data that have been changed
     * @param value the new value
     */
    dataChanged = (type: DataType, value: any) => {
        const { occupancyRate } = this.state;

        switch (type) {
            case DataType.Title:
                occupancyRate.title = value.target.value;
                break;
            case DataType.TypesOfDay:
                occupancyRate.typeOfDay = this.props.typesOfDay.find(t => t.id === value);
                break;
            case DataType.StaffType:
                occupancyRate.staffType = this.state.staffTypes?.find(t => t.id === value);
                break;
            case DataType.Poi:
                occupancyRate.poi = this.props.pois?.data?.find(t => t.id === value);
                break;
            case DataType.Departments:
                occupancyRate.department = this.props.departments.data.find(department => department.id === value);
                break;
            case DataType.RangeDates:
                occupancyRate.startDate = value ? value[0] : undefined;
                occupancyRate.endDate = value ? value[1] : undefined;
                break;
            case DataType.RangeHours:
                occupancyRate.startHour = value ? value[0] : undefined;
                occupancyRate.endHour = value ? value[1] : undefined;
                break;
            case DataType.Quantity:
                occupancyRate.quantity = value;
                break;
            case DataType.Frequency:
                occupancyRate.frequency = value;
                if (occupancyRate.frequency !== OccupancyRateFrequency.Daily) occupancyRate.rrule!["byweekday"] = undefined;
                break;
            case DataType.Weekdays:
                occupancyRate.rrule!["byweekday"] = value.sort();
                break;
            case DataType.DuringHolidays:
                occupancyRate.duringHolidays = value.target ? value.target.checked : value;
                break;
            case DataType.DuringVacations:
                occupancyRate.duringVacations = value.target ? value.target.checked : value;
                break;

        }

        this.setState({ occupancyRate });
    }

    /**
     * Render method for the weekday select
     * @param props the props of the select option
     * @returns the react element to render
     */
    renderWeekdaySelect = (props: any): React.ReactElement<number> => (
        <Tag className="select-tags" closable={props.closable} onClose={props.onClose}>
            {getWeekdayText(props.value)}
        </Tag>
    );

    /**
     * Render the modal content
     * @returns the content to render
     */
    renderModalContent = (): React.ReactNode => {
        const { occupancyRate } = this.state;
        const { intl } = this.props;
        // switch (this.state.currentStep) {
        //     case 0:
        const frequencies = Object.keys(OccupancyRateFrequency).filter(k => !isNaN(Number(k))).map(k => Number(k));
        const daysOfWeek = Object.keys(EventWeekdays).filter(key => !occupancyRate.rrule?.byweekday?.includes(EventWeekdays[key]))
        return (
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card size="small" title={<FormattedMessage defaultMessage={'Event'} />}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p>{<FormattedMessage defaultMessage={'Title'} />}{'*'}</p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <InputField
                                    value={occupancyRate.title}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                    onChange={(e: InputFieldOnChangeEvent) => this.dataChanged(DataType.Title, e)} />
                            </Col>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p>{<FormattedMessage defaultMessage={'Quantity'} />}{'*'}</p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <InputNumber
                                    style={{ width: '100%', minWidth: '100%' }}
                                    min={1}
                                    value={occupancyRate.quantity}
                                    onChange={n => this.dataChanged(DataType.Quantity, n)} />

                            </Col>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p><FormattedMessage defaultMessage={'Department'} /></p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <Select
                                    style={{ width: '100%', minWidth: '100%' }}
                                    showSearch
                                    allowClear
                                    onChange={(e: number) => this.dataChanged(DataType.Departments, e)}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Department' })}
                                    value={occupancyRate.department?.id}
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.departments.data.map(t => <Select.Option label={t.name} value={t.id!} key={`planning-department-${t.id}`}>{t.name}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <div className="occupancy-rate-section">
                                    <p><FormattedMessage defaultMessage={'Hours'} /></p>
                                    <TimePicker.RangePicker
                                        order={false}
                                        format={getFormat('TIME')}
                                        className="configurations-section-field"
                                        onChange={(d) => this.dataChanged(DataType.RangeHours, d)}
                                        placeholder={[intl.formatMessage({ defaultMessage: 'Start hour' }), intl.formatMessage({ defaultMessage: 'End hour' })]}
                                        allowClear
                                        disabledHours={this.disabledHours}
                                        disabledMinutes={this.disabledMinutes}
                                        value={[occupancyRate.startHour ? occupancyRate.startHour : undefined, occupancyRate.endHour ? occupancyRate.endHour : undefined] as any} />
                                </div>
                            </Col>
                        </Row>
                        <Col xs={{ span: 10 }} md={{ span: 10 }}>

                        </Col>
                        <Col xs={{ span: 14 }} md={{ span: 14 }}>

                        </Col>
                    </Card>
                    <Card style={{ marginTop: '16px' }} size="small"
                        title={
                            <>
                                <FormattedMessage defaultMessage={'Conditions for requirement control'} />
                                <Tooltip placement="right" title={intl.formatMessage({ defaultMessage: 'Please select at least one condition' })}>
                                    <InfoCircleOutlined className="tab-tooltip-info" />
                                </Tooltip>
                            </>
                        }>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p><FormattedMessage defaultMessage={'Type of day'} /></p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <Select
                                    className="configurations-width-100"
                                    value={occupancyRate.typeOfDay?.id}
                                    onChange={(t) => this.dataChanged(DataType.TypesOfDay, t)}
                                    allowClear
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.typesOfDay.map(t => <Select.Option label={t.title} value={t.id!} key={`configurations-select-tod-${t.id}`}>{t.title}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p><FormattedMessage defaultMessage={'Skill'} /></p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <Select
                                    className="configurations-width-100"
                                    value={occupancyRate.staffType?.id}
                                    onChange={(t) => this.dataChanged(DataType.StaffType, t)}
                                    allowClear
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.state.staffTypes?.map(t => <Select.Option label={t.name} value={t.id!} key={`configurations-select-stafftype-${t.id}`}>{t.name}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p><FormattedMessage defaultMessage={'Point of interest'} /></p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <Select
                                    className="configurations-width-100"
                                    value={occupancyRate.poi?.id}
                                    onChange={(t) => this.dataChanged(DataType.Poi, t)}
                                    allowClear
                                    showSearch
                                    filterOption={true}
                                    optionFilterProp="label">
                                    {this.props.pois?.data?.map(t => <Select.Option label={t.title} value={t.id!} key={`configurations-select-poi-${t.id}`}>{t.title}</Select.Option>)}
                                </Select>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card size="small" title={<FormattedMessage defaultMessage={'Rules'} />}>
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                <p>{<FormattedMessage defaultMessage={'Frequency (each)'} />}{'*'}</p>
                            </Col>
                            <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                <Select
                                    className="configurations-width-100"
                                    value={occupancyRate.frequency}
                                    onChange={n => this.dataChanged(DataType.Frequency, n)}>
                                    {frequencies.map(f => <Select.Option value={f} key={`occupancy-rate-frequency-${f}`}>{intl.formatMessage(getOccupancyRateFrequencyText(f))}</Select.Option>)}
                                </Select>
                            </Col>
                            {
                                occupancyRate.frequency === OccupancyRateFrequency.Daily &&
                                <>
                                    <Col xs={{ span: 10 }} md={{ span: 10 }}>
                                        <p>{<FormattedMessage defaultMessage={'Day of the week'} />}</p>
                                    </Col>
                                    <Col xs={{ span: 14 }} md={{ span: 14 }}>
                                        <Select
                                            className="configurations-width-100"
                                            mode="multiple"
                                            value={occupancyRate.rrule?.byweekday}
                                            tagRender={this.renderWeekdaySelect}
                                            onChange={(e) => this.dataChanged(DataType.Weekdays, e)}
                                            allowClear
                                            filterOption={true}
                                            maxTagCount="responsive"
                                            optionFilterProp="label">
                                            {daysOfWeek.map(key => <Select.Option label={getWeekdayText(EventWeekdays[key])} key={`occupancy-rate-weekdays-${key}`} value={EventWeekdays[key] as number}>{getWeekdayText(EventWeekdays[key])}</Select.Option>)}
                                        </Select>
                                    </Col>
                                </>
                            }
                            <Col xs={{ span: 24 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p style={{ cursor: 'pointer' }} onClick={() => this.dataChanged(DataType.DuringHolidays, !occupancyRate.duringHolidays)}><FormattedMessage defaultMessage={'Apply the rule even on public holidays'} /></p>
                                <Checkbox
                                    checked={occupancyRate.duringHolidays}
                                    onChange={(c) => this.dataChanged(DataType.DuringHolidays, c)} />
                            </Col>
                            <Col xs={{ span: 24 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p style={{ cursor: 'pointer' }} onClick={() => this.dataChanged(DataType.DuringVacations, !occupancyRate.duringVacations)}><FormattedMessage defaultMessage={'Apply the rule even on days off'} /></p>
                                <Checkbox
                                    checked={occupancyRate.duringVacations}
                                    onChange={(c) => this.dataChanged(DataType.DuringVacations, c)} />
                            </Col>
                        </Row>
                        {
                            (occupancyRate.frequency === OccupancyRateFrequency.Daily && (occupancyRate.rrule?.byweekday == null || occupancyRate.rrule?.byweekday.length === 0)) ?
                                <Col style={{ marginTop: '10px' }} xs={{ span: 24 }}>
                                    <Alert type="info" showIcon
                                        style={{ fontSize: "80%", fontStyle: 'italic' }}
                                        message={<FormattedMessage defaultMessage={'For daily frequency, if no day is selected, this requirement will not appear.'} />} />
                                </Col>
                                : null
                        }
                    </Card>
                    <Card style={{ marginTop: '16px' }} size="small" title={<FormattedMessage defaultMessage={'Deadlines'} />}>
                        <div className="occupancy-rate-section">
                            <p><FormattedMessage defaultMessage={'Dates'} /></p>
                            <DatePicker.RangePicker
                                format={getFormat('DATE')}
                                className="configurations-section-field"
                                onChange={(d) => this.dataChanged(DataType.RangeDates, d)}
                                placeholder={[intl.formatMessage({ defaultMessage: 'Start date' }), intl.formatMessage({ defaultMessage: 'End date' })]}
                                allowClear
                                value={[occupancyRate.startDate ? occupancyRate.startDate : undefined, occupancyRate.endDate ? occupancyRate.endDate : undefined] as any} />
                        </div>
                    </Card>
                </Col>
                {!this.hasNotAtleastOneCondition() &&
                    <Col xs={{ span: 24 }}>
                        <div className="occupancy-rate-section">
                            <p>{getOccupancyRateSummaryText(intl, occupancyRate)}</p>
                        </div>
                    </Col>
                }
            </Row>
        );
    }

    render() {
        return (
            <Modal
                width="880px"
                className="configurations-modal"
                title={this.props.occupancyRate.title !== "" ? this.props.occupancyRate.title : <FormattedMessage defaultMessage={'Add a requirement'} />}
                visible={Boolean(this.props.occupancyRate)}
                destroyOnClose={true}
                onCancel={this.props.onCancel}
                footer={[
                    <Button type="dashed" onClick={this.props.onCancel} key="occupancy-rate-modal-button-cancel">
                        <FormattedMessage defaultMessage={'Cancel'} />
                    </Button>,
                    <Button id="occupancy-rate-modal-ok-button" type="primary" onClick={this.onDone} loading={this.props.loading} key="occupancy-rate-modal-button-ok">
                        {
                            this.props.occupancyRate.id ?
                                <FormattedMessage defaultMessage={'Save'} />
                                :
                                <FormattedMessage defaultMessage={'Add'} />
                        }
                    </Button>
                ]}>
                {this.renderModalContent()}
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    loadPois: (fr?: boolean) => dispatch(loadPois(fr)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
});

const mapStateToProps = (state: ApplicationState) => ({
    occupancyRates: state.configurations.occupancyRates,
    typesOfDay: state.configurations.typesOfDay,
    settings: state.planning.settings,
    pois: state.location.pois,
    departments: state.configurations.departments
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateEditModal));