import { Button, Popover } from "antd";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { IconPacks } from "../../../../utils/fontawesome/IconPacks";
import { default as FA, IconPack } from "../../../../utils/fontawesome/fontawesome";
import FAIcon from "../../FAIcon";
import './FontAwesomePicker.css';
import FontAwesomePickerIcon from "./FontAwesomePickerIcon";

interface Props {
    selectedIcon: string;
    setSelectedIcon: (icon: string) => void;
    pack: IconPacks;
    mode?: 'POPOVER' | 'FIXED';
    disabled?: boolean;
    iconsPerRow?: number;
}

interface State {
    opened: boolean;
}

const ICONS_PER_ROW = 8;

class FontAwesomePicker extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            opened: false
        };
    }

    handleIconClick = (icon: string) => this.props.setSelectedIcon(icon);

    handleOpenCloseMenu = () => this.setState({ opened: !this.state.opened });

    renderIconList = (iconPack: IconPack) => {
        return (
            <div className="__font-aweseome-icon-picker-grid" style={{ gridTemplateColumns: `repeat(${this.props.iconsPerRow ?? ICONS_PER_ROW}, 1fr)` }}>
                {iconPack.icons.map(icon => (
                    <>
                        <FontAwesomePickerIcon
                            key={`${iconPack.title}_${icon.getIdentifier()}`}
                            icon={icon.definition}
                            onClick={() => this.handleIconClick(icon.getIdentifier())}
                            selected={icon.getIdentifier() === this.props.selectedIcon}
                        />
                    </>
                ))}
            </div>
        );
    };

    render() {
        const iconPack = FA.GetPack(this.props.pack);
        const mode = this.props.mode ?? 'POPOVER';
        const noneIcon = FA.GetNoneIcon();

        if (mode === 'POPOVER') {
            const iconInfo = FA.ConvertDBFieldToIcon(this.props.selectedIcon);
            const icon = FA.GetIcon(iconInfo.prefix, iconInfo.iconName);
            const modalOpenButton = (<div className='__fa-picker-modal-opener'>
                {icon
                    ? <FAIcon prefix={iconInfo.prefix} name={iconInfo.iconName} />
                    : <FAIcon prefix={noneIcon.prefix} name={noneIcon.iconName} />
                }
            </div>);


            return (
                <div style={{ flexShrink: 1 }}>
                    {!this.props.disabled
                        ? <Popover
                            arrowPointAtCenter
                            key={`color-popover-primary`}
                            title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div><FormattedMessage defaultMessage={'Select an icon'} /></div>
                                <div>
                                    <Button onClick={() => this.props.setSelectedIcon("")}><FormattedMessage defaultMessage={'Clear'} /></Button>
                                </div>
                            </div>}
                            trigger={'click'}
                            placement='bottomLeft'
                            content={
                                this.renderIconList(iconPack)
                            }
                        >
                            {modalOpenButton}
                        </Popover>
                        : <>
                            {modalOpenButton}
                        </>
                    }
                </div>
            );
        }

        if (mode === 'FIXED') {
            return this.renderIconList(iconPack);
        }
    }
}

export default FontAwesomePicker;