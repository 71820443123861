import { CalendarOutlined, InboxOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React from 'react';
import Network from '../../utils/network';
import { RouterProps, StatsOnlyPlanning } from '../../utils/types/generalTypes';
import { StatsPlanningEvent } from '../../utils/types/planningTypes';
import { alert } from '../../utils/utils';
import CircleButton from '../common/fields/circleButton';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import Card from '../common/general/card';

interface IProps { }

type Props = IProps & RouterProps;

interface State {
    statistics?: StatsOnlyPlanning;
    loading: boolean;
    day: Moment;
    companyId?: string;
    search?: string;
    sortBy: string;

}

class PlanningStatistics extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            statistics: undefined,
            loading: true,
            day: moment(),
            sortBy: "1",
        };
    }

    componentDidMount() {
        const search = this.props.location.search;
        const companyId = qs.parse(search, {
            ignoreQueryPrefix: true,
            comma: true
        }).companyId;
        // get all users
        if (typeof companyId === "string") {
            this.setState({ companyId }, () => this.loadPlanningStatistics());

        }
    }

    loadPlanningStatistics = () => {
        const { companyId, day } = this.state;
        if (companyId === undefined) return;
        this.setState({ loading: true });
        Network.getPlanningStatistics(parseInt(companyId), day.format("YYYY/MM/DD")).then(
            response => this.setState({ statistics: response.planning, loading: false }),
            () => alert("Un problème est survenu pendant le chargement des utilisateurs", "warning"),
        );

    }

    /**
     * Get the text for an eventy entry for the planning card
     * @param event the current event
     * @returns the text to display
     */
    getEventText = (event: StatsPlanningEvent): string => {
        if (this.state.sortBy === "2") {
            return `${event.userName} - ${event.title}`;
        } else {
            return `${event.title} - ${event.userName}`;
        }
    }

    previousDay = () => {
        let { day } = this.state;
        day = moment(day).add(-1, "day");
        this.setState({ day }, () => this.loadPlanningStatistics());
    }

    nextDay = () => {
        let { day } = this.state;
        day = moment(day).add(1, "day");
        this.setState({ day }, () => this.loadPlanningStatistics());
    }

    onChangeMonth = (date: Moment | null) => {
        if (date) {
            this.setState({ day: date }, () => this.loadPlanningStatistics());
        }
    }

    handleSearch = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        this.setState({ search: search.length === 0 ? undefined : search });
    }

    onChangeSort = (value: SelectValue) => {
        this.setState({ sortBy: value ? value.toString() : "1" });
    }

    render() {
        const { loading, day, search } = this.state;
        const planning = this.state.statistics;
        let todayEvents = cloneDeep(planning?.todayEvents);
        todayEvents = todayEvents?.sort((a, b) => this.getEventText(a).localeCompare(this.getEventText(b)));
        if (search) {
            todayEvents = todayEvents?.filter(e => e.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || e.userName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        }

        // todayEvents = todayEvents ? [...new Map(todayEvents.map(item => [JSON.stringify(item), item])).values()] : [];

        return (
            <Row style={{ padding: '10px' }}>
                <Col xs={{ span: 24 }} xl={{ span: 12, offset: 6 }}>
                    <Card
                        className="planning-statistics-card"
                        title={"Planification journalière"}
                        icon={<CalendarOutlined />}>
                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <InputField
                                    type="search"
                                    onChange={this.handleSearch}
                                    style={{ width: '100%' }} />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Select
                                    defaultValue={"1"}
                                    placeholder="Trier par"
                                    onChange={this.onChangeSort}
                                    style={{ width: '100%' }}
                                    showArrow
                                >
                                    <Select.Option value="1">Trier par événements</Select.Option>
                                    <Select.Option value="2">Trier par utilisateurs</Select.Option>
                                </Select>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircleButton
                                    small
                                    icon={<LeftOutlined />}
                                    className="planning-week-button-left"
                                    disabled={loading}
                                    onClick={this.previousDay} />
                                <DatePicker
                                    className={"planning-week-datepicker"}
                                    disabled={loading}
                                    style={{ backgroundColor: day.isSame(moment(), "day") ? "var(--light-primary-color)" : undefined }}
                                    onChange={this.onChangeMonth}
                                    format={"DD MMM YYYY"}
                                    placeholder={"Choisir un mois"}
                                    allowClear={false}
                                    value={this.state.day} />
                                <CircleButton
                                    small
                                    icon={<RightOutlined />}
                                    className="planning-week-button-right"
                                    disabled={loading}
                                    onClick={this.nextDay} />
                            </Col>
                        </Row>
                        {
                            loading ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Spin size='large' style={{ marginTop: '15px' }} indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />} />
                                </div>
                                :
                                planning && planning.todayEvents.length > 0 ?
                                    <div className="dashboard-section">
                                        <div className="dashboard-events">
                                            {todayEvents?.map(e => (
                                                <span className="dashboard-event" key={`dashboard-today-event-${e.id}`}>
                                                    <p className="dashboard-event-dot" style={{ color: e.color?.color ?? 'var(--primary-color)' }}>•</p>
                                                    <p>{this.getEventText(e)}</p>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <span className="dashboard-empty">
                                        <InboxOutlined />
                                        <p>{"Aucun événement n'est prévu aujourd'hui"}</p>
                                    </span>
                        }
                    </Card>
                </Col>

            </Row>
        );
    }
}

export default PlanningStatistics;

