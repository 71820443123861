interface Props {
    countryCode: string;
    style?: React.CSSProperties;
}

const Flag = (props: Props) => {
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <img src={`https://flagsapi.com/${props.countryCode}/flat/32.png`} alt={`flag-${props.countryCode}`} style={{ margin: 'auto' }} />
        </div>
    );
};

export default Flag;