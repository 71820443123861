import { StoreAction, TeamManagementState } from '../../utils/types/storeTypes';

export const TEAM_INIT_STATE: TeamManagementState = {
    user: undefined,
    users: [],
    usersGroups: undefined,
    group: undefined,
    groups: undefined,
    groupsUsers: undefined,
    usersLoading: false,
};

export const CHANGE_USER = ('CHANGE_USER');
export const CHANGE_USERS = ('CHANGE_USERS');
export const CHANGE_USERS_GROUPS = ('CHANGE_USERS_GROUPS');
export const CHANGE_GROUP = ('CHANGE_GROUP');
export const CHANGE_GROUPS = ('CHANGE_GROUPS');
export const CHANGE_GROUPS_USERS = ('CHANGE_GROUPS_USERS');
export const TOGGLE_USERS_LOADING = ('TOGGLE_USERS_LOADING');
export const RESET = ('RESET_TEAM_MANAGEMENT');
export const RESET_ALL_APPART_GROUPS = ('RESET_ALL_APPART_GROUPS');

const teamManagement = (state: TeamManagementState = TEAM_INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case CHANGE_USER:
            return {
                ...state,
                user: action.data,
            }
        case CHANGE_USERS:
            return {
                ...state,
                users: action.data,
                usersLoading: false,
            }
        case CHANGE_USERS_GROUPS:
            return {
                ...state,
                usersGroups: action.data,
            }
        case CHANGE_GROUP:
            return {
                ...state,
                group: action.data,
            }
        case CHANGE_GROUPS:
            return {
                ...state,
                groups: action.data,
            }
        case CHANGE_GROUPS_USERS:
            return {
                ...state,
                groupsUsers: action.data,
            }
        case TOGGLE_USERS_LOADING:
            return {
                ...state,
                usersLoading: action.data,
            };
        case RESET_ALL_APPART_GROUPS:
            return {
                ...TEAM_INIT_STATE,
                groups: state.groups,
            }
        case RESET:
            return {
                ...TEAM_INIT_STATE,
            };
        default:
            return state;
    }
};

export default teamManagement;