import React, { CSSProperties, ReactNode } from 'react';

interface Props {
    icon: ReactNode,
    title?: string,
    style?: CSSProperties;
}

/**
 * Component that represent the square header of a card
 */
class Header extends React.Component<Props> {
    render() {
        return (
            <div className="header-parent" style={this.props.style}>
                {this.props.icon}
                {
                    this.props.title ?
                        <p>{this.props.title}</p>
                        : null
                }
            </div>
        );
    }
}

export default Header;