import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeFullScreen } from "../../../store/actions/window";

interface Props{
    children: any,
}

export const FullScreen = (props: Props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const onFullscreenChange = () => {
            dispatch(changeFullScreen(Boolean(window.document.fullscreenElement)))
        }
              
        window.document.addEventListener('fullscreenchange', onFullscreenChange);
      
        return () => window.document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, [dispatch]);


    return props.children
    
}
export default FullScreen