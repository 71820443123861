import range from 'lodash/range';
import React from 'react';
import '../../../styles/skeleton.css';

interface Props {
    className?: string,
    repeat?: number,
    avatar?: boolean,
    loading?: boolean,
    title?: boolean,
    block?: {
        height: string,
        width: string,
    }
    paragraph?: {
        rows: number,
    },
}

interface State {

}

/**
 * Component that represent a Skeleton (placeholder component when loading element)
 */
class Skeleton extends React.Component<Props, State> {
    render() {
        const { className, repeat, avatar, loading, title, paragraph, block } = this.props;

        return loading && range(repeat ? repeat : 1).map((index) => (
            <div className={`skeleton-parent ${className ? className : ''}`} key={`skeleton-${index}`}>
                {avatar ? <div className="skeleton-avatar skeleton-animation" /> : null}
                <div className="skeleton-content">
                    {title ? <div className="skeleton-title skeleton-animation" /> : null}
                    {
                        paragraph ?
                            <div className="skeleton-paragraph-container">
                                {range(paragraph.rows).map((index) => <div className="skeleton-paragraph skeleton-animation" key={`skeleton-${index}`} />)}
                            </div>
                            : null
                    }
                    {
                        block ?
                            <div className="skeleton-animation" style={{ height: block.height, width: block.width }} />
                            : null
                    }
                </div>
            </div>
        ));
    }
}

export default Skeleton;