import React, { Component } from 'react'
import { CellEvent, PlanningCellDay, Project } from '../../utils/types/planningTypes';
import moment, { Moment } from 'moment';
import Event from './event';
import Can from '../common/general/can';
import { Rules } from '../../rbacRules';
import { Privileges } from '../../privileges';
import { ApplicationState } from '../../utils/types/storeTypes';
import { connect } from 'react-redux';
import Overtime from './overtime';
import { convertNetworkEventToPlanningEvent } from '../../utils/utils';

interface Props {
    day: PlanningCellDay;
    currentProjects: Project[] | undefined;
    index: number;
    userId?: number;
    isPeriod: boolean;
    isVacations: boolean;
    isUserVacations: boolean;
    canDrop: boolean;
    onDrop: (event: CellEvent, date: Moment) => void;
    onClick: (date: Moment) => void;
    //Redux
    cellsPerRow?: number;
    startHourOfDay: Moment | undefined;
    displayWorkingTime: boolean;
    windowWidth: number;
    draggedUserEventUserId?: number;
}

interface State {
    isOver: boolean;
}

/**
 * Component that represent a cell of the scheduler's calendar
 * This component is as a function because it needs Hooks to work with DnD
 */
class Cell extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOver: false,
        };
    }

    /**
      * Convert the cell index into it's corresponding hours/ minutes
      * @returns an array containing the hours and the minutes
      */
    convertCellIndexIntoHours = (): [number, number] => {
        let hours = (this.props.index - (this.props.cellsPerRow! * Math.floor(this.props.index / this.props.cellsPerRow!))) / 2;

        //add the debut of day if displayWorkingTime is true
        if (this.props.startHourOfDay && this.props.displayWorkingTime) {
            const debut = this.props.startHourOfDay.hours() + this.props.startHourOfDay.minutes() / 60;
            hours += debut;
        }

        return [Math.floor(hours), Math.ceil(hours % 1) * 30];
    }

    /**
      * Called when the user click inside the cell
      */
    onClick = () => {
        const hours = this.convertCellIndexIntoHours();
        this.props.onClick(moment(this.props.day.date).set('hours', hours[0]).set('minutes', hours[1]));
    }

    /**
     * Called when a draggable is dropped inside the cell
     * @param e the triggered drag event
     */
    onDrop = (e: React.DragEvent) => {
        e.preventDefault();
        this.setState({ isOver: false });

        if (!this.props.canDrop) return;

        // get cell event and convert start and end date to moment
        const cellEvent: CellEvent = JSON.parse(e.dataTransfer.getData('cellEvent'));
        cellEvent.event = convertNetworkEventToPlanningEvent(cellEvent.event as any);

        // if the dropped cell is the same as the event's cell, cancel drop
        if (cellEvent.startCell === this.props.index && this.props.draggedUserEventUserId === this.props.userId) return;

        // convert cell to date
        const hours = this.convertCellIndexIntoHours();
        const date = moment(this.props.day.date);
        date.set('hours', hours[0]).set('minutes', hours[1]);

        // chain method
        this.props.onDrop(cellEvent, date);

    }

    /**
     * Called when a draggable enter over the cell
     * @param e the drag event
     */
    onDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        this.setState({ isOver: true });
    }

    /**
     * Called when a draggable leave over the cell
     * @param e the drag event
     */
    onDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        this.setState({ isOver: false });
    }

    /**
     * Get the cell background color
     * @returns the color
     */
    getBackgroundColor = (): string => {
        if (this.state.isOver) {
            return (this.props.canDrop ? 'rgba(187, 99, 19, 0.5)' : 'var(--planning-outside-period)');
        } else if (this.props.isUserVacations) {
            return "var(--planning-user-vacations)";
        } else if (this.props.isPeriod) {
            return this.props.isVacations ? "var(--planning-vacations)" : "var(--planning-is-period)";
        } else {
            return "var(--planning-outside-period)";
        }
    }

    render() {
        const { isOver } = this.state;
        const { day, isPeriod, canDrop, isUserVacations } = this.props;
        const backgroundColor = this.getBackgroundColor();
        const width = this.props.windowWidth > 2000 ? '65px' : (this.props.windowWidth > 1000 ? '55px' : '45px');
        const height = `${(day.overlap ? (day.overlap + 1) * 43 : 50) + (isOver && canDrop ? 30 : 0)}px`;

        // create events
        const events = (
            day.cellEvents.map(cell =>
                cell.startCell === this.props.index &&  
                <Event                    
                    currentProjects={this.props.currentProjects}
                    userId={this.props.userId}
                    cellEvent={cell}
                    key={`planning-event-${cell.event.id}`} />
            )
        );

        const overtimes = (
            day.cellOvertimes.map(cell => 
                cell.startCell === this.props.index &&
                <Overtime
                    cellOvertime={cell}
                    key={`planning-overtime-${cell.overtime.id}`} />
            )
        );

        return (
            <Can rule={Rules.Planning.Management} privilege={Privileges.Planning.Edition} otherwise={
                <td
                    className={isPeriod ? undefined : 'planning-cell-not-period'}
                    style={{ cursor: 'auto', height, width, minWidth: width, backgroundColor, position: 'relative' }}>
                    {events}
                    {overtimes}
                </td>
            }>
                <td
                    onDrop={canDrop ? this.onDrop : undefined}
                    onDragEnter={canDrop ? this.onDragEnter : undefined}
                    onDragLeave={canDrop ? this.onDragLeave : undefined}
                    onDragOver={canDrop ? e => e.preventDefault() : undefined}
                    onClick={isPeriod && !isUserVacations ? this.onClick : undefined}
                    className={isPeriod && !isUserVacations ? 'planning-cell-is-period' : 'planning-cell-not-period'}
                    style={{ height, width, minWidth: width, backgroundColor }}>
                    {events}
                    {overtimes}
                </td>
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    windowWidth: state.window.width,
    startHourOfDay: state.planning.settings.startHourOfDay,
    displayWorkingTime: state.planning.displayWorkingTime,
    draggedUserEventUserId: state.planning.draggedUserEventUserId,
    cellsPerRow: state.planning.cellsPerRow,
});

export default connect(mapStateToProps)(Cell);
